import React, { useState, useEffect } from 'react';
import { TableDetail } from '../ui/TableDetail';
import { Link } from 'react-router-dom';
import { PlanTag, RegionTag } from '../ui/Tags';
import { getCrmApplicationByOrganizationRequest } from '../../api/organization';
import { ColumnProps } from 'antd/lib/table';
import {Button, Tooltip, Typography} from "antd";
import copy from "copy-to-clipboard";
import {CopyOutlined} from "@ant-design/icons";

const alignRight = 'right' as ColumnProps<any>['align'];

export const OrganizationApplications = ({ crmOrganizationId }) => {
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [crmApplications, setCrmApplications] = useState([] as Array<CrmApplication>);
  const [loadingApplication, setLoadingApplication] = useState(false);

  const applicationsColumns = [
    {
      title: 'App ID',
      dataIndex: 'appId',
      key: 'appId',
      render: (appId: string) => <span>
          <Link to={`/applications/${appId}/detail/`}>{appId.split('-')[0]}...</Link>
          <Tooltip title="Copy">
            <Button size="small" type="text" icon={<CopyOutlined />} onClick={() => copy(appId)} style={{ cursor: 'pointer' }}/>
          </Tooltip>
        </span>,
    },
    {
      title: 'Name',
      dataIndex: 'appName',
      key: 'appName',
      render: (appName: string) => (
        <span style={{ fontWeight: 500 }}><Typography.Text copyable={true}>{appName === '' || !appName ? '-' : appName}</Typography.Text></span>
      ),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan, crmApplication) => {
        if (crmApplication.crmOrganization && crmApplication.crmOrganization.isSelfServe) {
          return '-';
        } else if (crmApplication.crmOrganization) {
          return <PlanTag plan={plan} />;
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      render: (region: string) => (region === '' || !region ? '-' : <RegionTag region={region} />),
    },
    {
      title: (
        <span>
          Average DAU <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysAverageDau',
      key: 'lastSevenDaysAverageDau',
      align: alignRight,
      render: (lastSevenDaysAverageDau: number) => lastSevenDaysAverageDau.toLocaleString(),
    },
    {
      title: (
        <span>
          Peak Connection <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysPeakConnection',
      key: 'lastSevenDaysPeakConnection',
      align: alignRight,
      render: (lastSevenDaysPeakConnection: number) => lastSevenDaysPeakConnection.toLocaleString(),
    },
    {
      title: (
        <span>
          Average Messages <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysAverageMessageCount',
      key: 'lastSevenDaysAverageMessageCount',
      align: alignRight,
      render: (lastSevenDaysAverageMessageCount: number) => lastSevenDaysAverageMessageCount.toLocaleString(),
    },
  ];

  const getCrmApplicationByOrganization = ({ offset = 0, limit = 10 }) => {
    setLoadingApplication(true);
    getCrmApplicationByOrganizationRequest({
      crmOrganizationId,
      offset,
      limit,
    })
      .then((response) => {
        setCrmApplications(response.data.results);
        setApplicationsCount(response.data.count);
        setLoadingApplication(false);
      })
      .catch((error) => {
        setLoadingApplication(false);
        console.error(error);
      });
  };
  useEffect(() => {
    getCrmApplicationByOrganization({
      offset: 0,
      limit: 10,
    });
  }, []);
  return (
    <TableDetail
      title="Applications"
      count={applicationsCount}
      loading={loadingApplication}
      columns={applicationsColumns}
      dataSource={crmApplications}
      onPageChange={getCrmApplicationByOrganization}
      onRow={(crmApplication: CrmApplication) => ({
        style: {
          textDecoration: crmApplication.removed ? 'line-through' : '',
          opacity: crmApplication.removed ? 0.5 : 1,
        },
      })}
    />
  );
};
