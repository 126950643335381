import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Input, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getUserRequestActivityLogsRequest } from '../../api/userRequestActivityLog';
import styled from 'styled-components';

const { Title, Paragraph } = Typography;
const { Search } = Input;

const Body = styled.p`
  word-break: break-word;
`;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: UserRequestActivityLog['id']) => (
      <Link to={`/user_request_activity_logs/${id}/`}>{id}</Link>
    ),
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Request User',
    dataIndex: 'requestUser',
    key: 'requestUser',
    render: (user: UserRequestActivityLog['requestUser']) =>
      user ? <Link to={`/users/${user.id}`}>{user.email}</Link> : '-',
  },
  {
    title: 'Request URI',
    dataIndex: 'requestUri',
    key: 'requestUri',
  },
  {
    title: 'Method',
    dataIndex: 'requestMethod',
    key: 'requestMethod',
  },
  {
    title: 'Request Body',
    dataIndex: 'requestBody',
    key: 'requestBody',
    render: (requestBody) => <Body>{requestBody}</Body>,
  },
  {
    title: 'Response Code',
    dataIndex: 'responseCode',
    key: 'responseCode',
  },
];

type Props = RouteComponentProps;

export const UserRequestActivityLogs = withRouter<Props, any>(
  ({ location, history }) => {
    const [userRequestActivityLogs, setUserRequestActivityLogs] = useState<
      Array<UserRequestActivityLog>
    >([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [
      userRequestActivityLogsCount,
      setUserRequestActivityLogsCount,
    ] = useState(0);

    useEffect(() => {
      const q = location.search.split('q=')[1]
        ? location.search.split('q=')[1]
        : '';
      setSearchQuery(q);
      getUserRequestActivityLogs(0, 30, q);
    }, [location]);

    const getUserRequestActivityLogs = (offset = 0, limit = 30, q = '') => {
      const query = q ? q : searchQuery;

      setLoading(true);
      getUserRequestActivityLogsRequest({ offset, limit, query })
        .then((response) => {
          setUserRequestActivityLogs(response.data.results);
          setLoading(false);
          setUserRequestActivityLogsCount(response.data.count);
        })
        .catch((error) => {
          setLoading(false);
          console.error('ERROR', error && error.response);
        });
    };

    const onSearch = (value) => {
      setSearchQuery(value);
      history.replace({
        pathname: '/user_request_activity_logs/',
        search: `?q=${value}`,
      });
    };

    const onSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };

    const onPageChange = (page, pageSize) => {
      getUserRequestActivityLogs((page - 1) * pageSize, pageSize);
    };

    return (
      <React.Fragment>
        <Row style={{ marginBottom: '8px' }}>
          <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
            <Title level={3}>User Request Activity Logs</Title>
          </Col>
          <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
            <Search
              value={searchQuery}
              placeholder="Search log"
              enterButton={true}
              onSearch={onSearch}
              onChange={onSearchChange}
            />
          </Col>
        </Row>
        <Paragraph>
          <ul>
            <li>
              We are collecting any requests of following methods: POST, PATCH,
              PUT, DELETE
            </li>
          </ul>
        </Paragraph>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={userRequestActivityLogs}
          pagination={{
            total: userRequestActivityLogsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
        />
      </React.Fragment>
    );
  },
);
