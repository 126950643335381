import * as React from 'react';
import { Card, Col, List, Row, Tag, Typography } from 'antd';

const { Text } = Typography;

type Props = {
  channel: ApplicationChannel;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const ChannelInfo: React.FC<Props> = ({ channel }) => {
  const titles = ['Detail', 'Application'];
  const detailBasic = [
    {
      key: 'id',
      value: channel.id,
    },
    {
      key: 'Name',
      value: channel.name,
    },
    {
      key: 'Channel Type',
      value: channel.channelType,
    },
    {
      key: 'Channel Url',
      value: (
        <Text copyable={true} style={{ wordBreak: 'break-word' }}>
          {channel.channelUrl}
        </Text>
      ),
    },
    {
      key: 'Message Count',
      value: channel.messageCount? channel.messageCount : 0,
    },
    {
      key: 'Removed',
      value: (
        <Tag color={channel.removed ? 'green' : 'red'} style={{ margin: 0 }}>
          {channel.removed ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      key: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: channel.createdAt,
    },
  ];

  const application = [
    // {
    //   key: 'App ID',
    //   value: channel.application ? (
    //     <Link to={`/applications/${channel.application.appId}/detail/`}>
    //       {channel.application.appId}
    //     </Link>
    //   ) : (
    //     '-'
    //   ),
    // },
    // {
    //   key: 'App Name',
    //   value: channel.application ? channel.application.appName : '-',
    // },
  ];

  const dataSources = [detailBasic, application];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col
          key={`Channel-info-${index}`}
          span={24}
          md={{ span: 12 }}
          style={{ paddingBottom: '16px' }}
        >
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '280px',
            }}
          >
            <List
              size="small"
              dataSource={dataSource}
              renderItem={renderInfoItem}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
