import * as React from 'react';
import { Row, Col, Typography, Layout } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { PageTitle } from '../../components/ui/PageTitle';

const { Title } = Typography;

type Props = RouteComponentProps;

export const Laboratory = withRouter<Props, any>(({ location, history }) => {
  return (
    <Layout>
      <PageTitle title="Miscellaneous" />
      <Layout.Content>
        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>OTPs</Title>
              <ul>
                <li>
                  <Link to="/otp/dashboard_otps/">Dashboard OTP</Link>
                </li>
                <li>
                  <Link to="/otp/database_otps/">Database OTP</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Data Export</Title>
              <ul>
                <li>
                  <Link to="/usage_data_exports">Usage Data Exports</Link>
                </li>
                <li>
                  <Link to="/billing_data_exports">Billing Data Exports</Link>
                </li>
                <li>
                  <Link to="/user_request_activity_log_data_exports">User Request Activity Log Data Exports</Link>
                </li>
                <li>
                  <Link to="/otp_data_exports">OTP Data Exports</Link>
                </li>
                <li>
                  <Link to="/laboratory/crm_payment_status_by_application/">Payment Status</Link>
                </li>
                {/*<li><Link to="/corporate_dashboard_data_exports">Corporate Dashboard Data Exports</Link></li>*/}
              </ul>
            </section>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Roles and Permissions</Title>
              <ul>
                <li>
                  <Link to="/roles/">List of all Roles</Link>
                </li>
                <li>
                  <Link to="/permissions/">List of all Permissions</Link>
                </li>
                <li>
                  <Link to="/user_permissions/">List of all Permissions given to Users</Link>
                </li>
                <li>
                  <Link to="/role_permissions/">List of all Permissions given to Roles</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Logs</Title>
              <ul>
                <li>
                  <Link to="/event_logs/">Event Logs</Link>
                </li>
                <li>
                  <Link to="/user_request_activity_logs/">User Request Activity Logs</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Metrics</Title>
              <ul>
                <li>
                  <Link to="/laboratory/region_insight/">Region MAU/DAU</Link>
                </li>
                <li>
                  <Link to="/billing_metrics/self_service/">Billing Metric self-service</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Desk</Title>
              <ul>
                <li>
                  <Link to="/desk_metrics/summary">Metric summary</Link>
                </li>
                <li>
                  <Link to="/desk_metrics/projects">Project list</Link>
                </li>
                <li>
                  <Link to="/desk_metrics/tickets">Ticket list</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>Other Functions</Title>
              <ul>
                <li>
                  <Link to="/laboratory/region_insight/">Region Insight</Link>
                </li>
                <li>
                  <Link to="/laboratory/deficit_apps/">Deficit Apps</Link>
                </li>
                <li>
                  <Link to="/application_plan_requests/">Application Plan Requests</Link>
                </li>
                <li>
                  <Link to="/user_migration_requests/">User Migration Requests</Link>
                </li>
                <li>
                  <Link to="/status_page_incidents/">Status Page Incidents</Link>
                </li>
                <li>
                  <Link to="/organization_subscription_requests/">Subcription Requests</Link>
                </li>
                <li>
                  <Link to="/billing_vouchers/">Voucher List</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <section>
              <Title level={4}>To be deprecated</Title>
              <ul>
                <li>
                  <Link to="/daily_insight/">Daily Insight</Link>
                </li>
                <li>
                  <Link to="/billing/">Billing</Link>
                </li>
                <li>
                  <Link to="/subscribed_applications/">Subscribed Applications</Link>
                </li>
                <li>
                  <Link to="/dashboard/usage/">Usage</Link>
                </li>
                <li>
                  <Link to="/dashboard/growth/">Growth</Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
});
