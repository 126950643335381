import * as React from 'react';
import { Card, Col, List, Row } from 'antd';
import { TaskStatusTag } from './ui/Tags';

type Props = {
  celeryTask: CeleryTask;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const CeleryTaskInfo: React.FC<Props> = ({ celeryTask }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'Task ID',
      value: <React.Fragment>{celeryTask.taskId}</React.Fragment>,
    },
    {
      key: 'Name',
      value: celeryTask.name,
    },
    {
      key: 'Status',
      value: <TaskStatusTag status={celeryTask.status} />,
    },
    {
      key: 'Args',
      value: celeryTask.args,
    },
  ];
  const detailAdvanced = [
    {
      key: 'Task Start Date',
      value: celeryTask.dateStart,
    },
    {
      key: 'Task End Date',
      value: celeryTask.dateDone,
    },
    {
      key: 'Execution Date',
      value: <TaskStatusTag status={`${celeryTask.executionTime} seconds`} />,
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col
          key={`task-info-${index}`}
          span={24}
          md={{ span: 12 }}
          style={{ paddingBottom: '16px' }}
        >
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '280px',
            }}
          >
            <List
              size="small"
              dataSource={dataSource}
              renderItem={renderInfoItem}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
