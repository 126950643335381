import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ReactJson from 'react-json-view';
import { ApplicationAttributesRequestDialog } from './ApplicationAttributesRequestDialog';
import { getApplicationAttributesRequest } from '../api/application';

const { Title } = Typography;

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const ApplicationAttributes = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { appId } = match.params;
    const [applicationAttributes, setApplicationAttributes] = useState({});

    // TODO : For localhost test, below line will be deleted
    // const [applicationAttributes, setApplicationAttributes] = useState(JSON.parse('{"auto_event_message": {"USER_JOIN": {"USER_JOIN": false}, "CHANNEL_CHANGE": {"CHANNEL_CHANGE": false}, "CHANNEL_CREATE": {"PUBLIC_CHANNEL": false, "PRIVATE_CHANNEL": false}, "USER_LEAVE": {"LEFT": false, "DELETED": false, "DEACTIVATED": false}, "whitelist_customtypes": [], "message_templates": {"user_joined_messenger": "{sender_name} has joined the messenger."}}, "webhook": {"enabled": false, "enabled_categories": ["*"], "url": "", "include_members": false, "signature_secret": "", "is_bytes_signature_secret": false}}'));
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    let originalApplicationAttributes = JSON.parse(
      JSON.stringify(applicationAttributes),
    );

    const getApplicationAttributes = () => {
      setLoading(true);
      getApplicationAttributesRequest({ appId })
        .then((response) => {
          setLoading(false);
          setApplicationAttributes(response.data);
          console.log(applicationAttributes);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    };

    function initApplicationAttributes() {
      setApplicationAttributes(
        JSON.parse(JSON.stringify(originalApplicationAttributes)),
      );
    }

    const onChangeIsEdit = (e) => {
      if (e.target.checked) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
        initApplicationAttributes();
      }
    };

    useEffect(() => {
      getApplicationAttributes();
    }, []);

    return (
      <div style={{ padding: '24px 0' }}>
        <Title level={4}>Application Attributes</Title>

        {localStorage.getItem('username') === 'glen.kim' ? (
          <Checkbox
            style={{ height: '55px', padding: '10px' }}
            onChange={onChangeIsEdit}
          >
            Edit Mode
          </Checkbox>
        ) : (
          ''
        )}

        {isEdit ? (
          <React.Fragment>
            <Button
              type="default"
              loading={loading}
              onClick={initApplicationAttributes}
              style={{
                marginBottom: '1em',
              }}
            >
              Cancel
            </Button>
            <span style={{ paddingRight: '5px' }}></span>
            <ApplicationAttributesRequestDialog />
            <ReactJson src={applicationAttributes} onEdit={(edit) => {}} />
          </React.Fragment>
        ) : (
          <ReactJson src={applicationAttributes} />
        )}
      </div>
    );
  }),
);
