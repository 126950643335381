import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getBillingEventLogsRequest({ offset, limit, query }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/event_logs/?limit=${limit}&offset=${offset}&query=${query}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}


export function getBillingEventLogRequest({ billingEventLogId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/event_logs/${billingEventLogId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
