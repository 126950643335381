import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Tag, Layout } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCrmPaymentRequestsRequest } from '../../api/paymentRequests';
import { PageTitle } from '../../components/ui/PageTitle';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'App ID',
    dataIndex: 'crmPayment',
    key: 'appId',
    render: (crmPayment: CrmPayment) =>
      !crmPayment ? (
        ''
      ) : (
        <Link to={`/applications/${crmPayment.crmApplication.appId}/detail/`}>
          {crmPayment.crmApplication.appId.split('-')[0]}...
        </Link>
      ),
  },
  {
    title: 'Payment',
    dataIndex: 'crmPayment',
    key: 'payment',
    render: (crmPayment: CrmPayment) => (
      <Link to={`/crm_payments/${crmPayment.id}/`}>{crmPayment.id}</Link>
    ),
  },
  {
    title: 'Stripe Invoice',
    dataIndex: 'crmPayment',
    key: 'invoice',
    render: (crmPayment: CrmPayment) => (
      <a
        href={`https://dashboard.stripe.com/invoices/${crmPayment.stripeInvoiceId}`}
        target="_blank"
      >
        {crmPayment.stripeInvoiceId}
      </a>
    ),
  },
  {
    title: 'Stripe Charge',
    dataIndex: 'stripeChargeId',
    key: 'stripeChargeId',
    render: (stripeChargeId: CrmPaymentRequest['stripeChargeId']) => (
      <a
        href={`https://dashboard.stripe.com/charges/${stripeChargeId}`}
        target="_blank"
      >
        {stripeChargeId}
      </a>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: (status: CrmPaymentRequest) =>
      status.status.split('_').pop() === 'SUCCESS' ? (
        <Tag color="green">Success</Tag>
      ) : status.status.split('_').pop() === 'INPROGRESS' ? (
        <Tag color="blue">INPROGRESS</Tag>
      ) : (
        <React.Fragment>
          <Tag color="red">FAILED</Tag>
          <small>
            {' '}
            ({status.resultData.split(': ')[1] || status.resultData})
          </small>
        </React.Fragment>
      ),
  },
  {
    title: (
      <span>
        CreatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdDt',
    key: 'createdDt',
  },
  {
    title: (
      <span>
        RequestDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'paymentRequestDt',
    key: 'paymentRequestDt',
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'requester',
    render: (requester: CrmPaymentRequest['requester']) =>
      !(requester && requester.username) ? (
        ''
      ) : (
        <span>{requester.username}</span>
      ),
  },
];

type Props = RouteComponentProps;

export const CrmPaymentRequests = withRouter<Props, any>(({ location }) => {
  const [crmPaymentRequests, setCrmPaymentRequests] = useState<
    Array<CrmPaymentRequest>
  >([]);
  const [loading, setLoading] = useState(false);
  const [crmPaymentRequetsCount, setCrmPaymentRequestsCount] = useState(0);

  useEffect(() => {
    getCrmPaymentRequests(0, 30);
  }, [location]);

  const getCrmPaymentRequests = (offset = 0, limit = 0) => {
    setLoading(true);
    getCrmPaymentRequestsRequest({ offset, limit })
      .then((response) => {
        setCrmPaymentRequests(response.data.results);
        setLoading(false);
        setCrmPaymentRequestsCount(response.data.count);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onPageChange = (page, pageSize) => {
    getCrmPaymentRequests((page - 1) * pageSize, pageSize);
  };

  return (
    <Layout>
      <PageTitle title="Payment Requests" />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmPaymentRequests}
          pagination={{
            total: crmPaymentRequetsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          scroll={{ x: '100%' }}
        />
      </Layout.Content>
    </Layout>
  );
});
