import React, { useRef, useState } from 'react';
import { Button, DatePicker, Modal, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { createBillingSubscriptionFreeTrialRequest } from '../../api/billing';
import { dateTimePattern, getBrowserTimeZoneString } from '../../utils/date';
import { DynamicForm } from '../ui/DynamicForm';
import { FormInstance } from 'antd/lib/form';

type Props = {
  organizationUid: string;
  successCallback: any;
  isDeveloperPlanEnabled: boolean;
};

interface GetField {
  ({}: {}): Array<any>;
}

export const CreateFreeTrialSubscriptonDialog: React.FC<Props> = ({
  organizationUid,
  successCallback,
  isDeveloperPlanEnabled,
}) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [onChangeStartDateString, setOnChangeStartDateString] = useState(moment().format(dateTimePattern.date));

  const disabledDate = (current) => {
    if (!current) {
      return true;
    }

    return current < moment().subtract(1, 'days').endOf('day') || current > moment().add(15, 'days').endOf('day');
  };

  const getEndDateFromStartDate = (startDate) => {
    // startDate: <moment instance>
    return startDate.add(29, 'days');
  };

  const onChangeStartDate = (_oldStartDate, _onChangeStartDateString) => {
    setOnChangeStartDateString(_onChangeStartDateString);
  };

  const getFields: GetField = () => {
    return [
      {
        name: 'startDate',
        label: (
          <Tooltip title="The Start Date can be adjustable to 15 days from today">
            <span>
              Start Date <small style={{ opacity: 0.5 }}>(UTC)</small>
              <span> </span>
              <span>
                <QuestionCircleOutlined />
              </span>
            </span>
          </Tooltip>
        ),
        formItemProps: {
          initialValue: moment.tz(onChangeStartDateString, getBrowserTimeZoneString()),
          rules: [
            {
              validator: (_, value, callback) => {
                if (value) {
                  formRef.current?.setFieldsValue({
                    endDate: getEndDateFromStartDate(moment(value)),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: (
          <DatePicker
            size="large"
            disabledDate={disabledDate}
            showToday={false}
            onChange={onChangeStartDate}
            format={dateTimePattern.date}
          />
        ),
      },
      {
        name: 'endDate',
        value: 'endDate',
        label: 'End Date',
        formItemProps: {
          initialValue: getEndDateFromStartDate(moment()),
        },
        span: 12,
        component: <DatePicker size="large" disabled={true} format={dateTimePattern.date} />,
      },
    ];
  };

  function setForm(ref) {
    formRef.current = ref;
  }

  const getModalDescription = () => {
    let _startDate = moment(onChangeStartDateString);
    return (
      `* Free trial will be started from ${_startDate.format('MMMM Do YYYY')}` +
      ` and will be stopped at ${getEndDateFromStartDate(_startDate)
        .add(1, 'days')
        .format('MMMM Do YYYY')} 00:00:00 UTC`
    );
  };

  function showModal() {
    setVisible(true);
    formRef.current?.resetFields();
    setOnChangeStartDateString(onChangeStartDateString);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    formRef.current?.resetFields();
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { startDate } = values;

          createBillingSubscriptionFreeTrialRequest({
            organizationUid,
            startDate: startDate.format('YYYY-MM-DD'),
          })
            .then((response) => {
              handleCancel();

              successCallback &&
                successCallback({
                  callback: console.log('createBillingSubscriptionFreeTrialRequest', response.data),
                });
            })
            .catch((error) => {
              console.error('Error in : createBillingSubscriptionFreeTrialRequest', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields({});
  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        size="small"
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        {isDeveloperPlanEnabled ? 'Stop Developer Plan and Create Free Trial' : 'Create Free Trial'}
      </Button>
      <Modal
        open={visible}
        title={'Create billing subscription free trial'}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        {organizationUid && <DynamicForm fields={fields} setForm={setForm} />}
        <>{getModalDescription()}</>
      </Modal>
    </>
  );
};
