import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import lodash, { isEmpty } from 'lodash';
import numbro from 'numbro';

import { getOrganizationSubscriptionsRequest, getStripeCustomerRequest } from '../../api/billing';

import { OrganizationSubscriptionInfo } from '../../components/OrganizationSubscriptionInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { EnabledDisabledTag } from '../../components/ui/Tags';
import { Spinner } from '../../components/ui/Spinner';
import { OrganizationInvoices } from '../../components/organization/OrganizationInvoices';

interface MatchParams {
  organizationSubscriptionUid: string;
}

type Props = RouteComponentProps<MatchParams>;

const convertToEmptyValueFeatures = [
  'webhook',
  'messageRetrieval',
  'moderationTools',
  'dataExport',
  'desk',
  'advancedAnalytics',
  'reactions',
  'deliveryReceipt'
];
const GBUnitFeatures = ['uploadTraffic', 'fileStorage'];
const GBUnitFields = ['purchasedUnits', 'hardLimit'];

const planFeatureColumn = [
  {
    title: 'Feature Name',
    dataIndex: 'name',
    key: 'name',
    render: (name) => <span style={{ fontWeight: 500 }}>{lodash.startCase(name)}</span>,
  },
  {
    title: 'Status',
    dataIndex: 'property-status',
    key: 'property-status',
    render: (_, planFeature) => <EnabledDisabledTag enabled={planFeature.property.enabled} />,
  },
  {
    title: 'Quota (Purchased Units)',
    dataIndex: 'property-purchased-units',
    key: 'property-purchased-units',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {planFeature.property.purchasedUnits
          ? isNaN(planFeature.property.purchasedUnits)
            ? planFeature.property.purchasedUnits
            : `${numbro(planFeature.property.purchasedUnits).format({
                thousandSeparated: true,
              })}`
          : '-'}
      </span>
    ),
  },
  {
    title: 'Cost Per Unit',
    dataIndex: 'property-cost-per-unit',
    key: 'property-cost-per-unit',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {isNaN(planFeature.property.costPerUnit)
          ? '-'
          : `$${numbro(planFeature.property.costPerUnit / 100).format({ thousandSeparated: true })}`}
      </span>
    ),
  },
  {
    title: 'Bracket',
    dataIndex: 'property-bracket',
    key: 'property-bracket',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {!planFeature.property.bracket
          ? '-'
          : `${planFeature.property.bracket}`}
      </span>
    ),
  },
  {
    title: 'Hard Limit',
    dataIndex: 'property-hard-limit',
    key: 'property-hard-limit',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {planFeature.property.hardLimit
          ? isNaN(planFeature.property.hardLimit)
            ? planFeature.property.hardLimit
            : `${numbro(planFeature.property.hardLimit).format({
                thousandSeparated: true,
              })}`
          : '-'}
      </span>
    ),
  },
  {
    title: 'Tier',
    dataIndex: 'property-tier',
    key: 'property-tier',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {planFeature.property.tier
          ? `${planFeature.property.tier}`
          : '-'}
      </span>
    ),
  },
  {
    title: 'Time-zone(Support)',
    dataIndex: 'property-time-zone',
    key: 'property-time-zone',
    render: (_, planFeature) => (
      <span style={{ fontWeight: 500 }}>
        {!planFeature.property.timeZone
          ? '-'
          : `${planFeature.property.timeZone}`}
      </span>
    ),
  },
];

export const OrganizationSubscription = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { organizationSubscriptionUid } = match.params;
    const [loadingOrganizationSubscription, setLoadingOrganizationSubscription] = useState(false);
    const [organizationSubscription, setOrganizationSubscription] = useState({} as OrganizationSubscription);

    const [planFeatureCount, setPlanFeatureCount] = useState(0);
    const [planFeature, setPlanFeature] = useState([] as Array<OrganizationSubscriptionPlanFeature>);

    const [stripeCustomer, setStripeCustomer] = useState({} as StripeCustomer);

    function getStripeCustomer({ organizationUid }) {
      getStripeCustomerRequest({ crmOrganizationUid: organizationUid })
        .then((response) => {
          setStripeCustomer(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const getOrganizationSubscription = ({ callback }) => {
      setLoadingOrganizationSubscription(true);
      getOrganizationSubscriptionsRequest({ uid: organizationSubscriptionUid })
        .then((response) => {
          if (response.data.length === 1) {
            setOrganizationSubscription(response.data[0]);
            if (response.data[0].plan) {
              const plan = response.data[0].plan;
              const aiChatbotPlan = response.data[0].aiChatbotPlan;
              setPlanFeatureCount(Object.keys(plan).length + Object.keys(aiChatbotPlan).length);
              let planFeatureArray = [] as Array<OrganizationSubscriptionPlanFeature>;
              for (let [key, value] of Object.entries(plan)) {
                planFeatureArray = [
                  ...planFeatureArray,
                  {
                    name: key,
                    property: value,
                  },
                ];
              }
              for (let [key, value] of Object.entries(aiChatbotPlan)) {
                planFeatureArray = [
                  ...planFeatureArray,
                  {
                    name: key,
                    property: value,
                  },
                ];
              }
              planFeatureArray.sort((a, b) => {
                const enabledCompare = b.property.enabled - a.property.enabled;
                if (enabledCompare !== 0) {
                  return enabledCompare;
                }
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              });
              planFeatureArray.forEach((item) => {
                if (!item.property.enabled || convertToEmptyValueFeatures.includes(item.name)) {
                  for (const k of Object.keys(item.property)) {
                    if (k !== 'enabled') {
                      item.property[k] = '-';
                    }
                  }
                } else if (GBUnitFeatures.includes(item.name)) {
                  for (const k of Object.keys(item.property)) {
                    if (GBUnitFields.includes(k)) {
                      item.property[k] = `${item.property[k]}GB`;
                    }
                    if (k === 'costPerUnit') {
                      item.property[k] = (item.property[k])
                        .toFixed(20)
                        .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
                    }
                  }
                } else {
                  for (const k of Object.keys(item.property)) {
                    if (typeof item.property[k] === 'number') {
                      item.property[k] = numbro(item.property[k]).format({
                        thousandSeparated: true,
                      });
                    }
                  }
                }
              });
              setPlanFeature(planFeatureArray);
            }
            setLoadingOrganizationSubscription(false);
            callback && callback(response.data[0].organization.uid);
          } else {
            setLoadingOrganizationSubscription(false);
            throw new Error('Invalid response');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getOrganizationSubscriptionAll = () => {
      getOrganizationSubscription({ callback: () => {} });
    };

    useEffect(() => {
      getOrganizationSubscription({
        callback: (organizationUid) => {
          getStripeCustomer({ organizationUid });
        },
      });
    }, []);

    return isEmpty(organizationSubscription) || loadingOrganizationSubscription ? (
      <Spinner />
    ) : (
      <>
        <PageTitle
          title="Organization Subscription Detail"
          subTitle={`#${(organizationSubscription.id && organizationSubscription.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <OrganizationSubscriptionInfo
            organizationSubscription={organizationSubscription}
            successCallback={getOrganizationSubscriptionAll}
            stripeCustomer={stripeCustomer}
          />
          <OrganizationInvoices
            crmOrganizationUid={organizationSubscription.organization.uid}
            subscriptionUid={organizationSubscription.uid} />
          <TableDetail
            title="Subscription Detail"
            count={planFeatureCount}
            loading={loadingOrganizationSubscription}
            columns={planFeatureColumn}
            dataSource={planFeature}
            pageSizeOptions={['30']}
          />
        </Layout.Content>
      </>
    );
  }),
);
