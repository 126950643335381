import React, { useMemo, useState } from 'react';
import { Button, DatePicker, Input, Modal, Space, Typography } from 'antd';

import moment from 'moment';

import { updateCallVoucherExpireDtRequest } from '../../api/billing';

const { Text, Title } = Typography;

type Props = {
  voucher: any;
};

export const VoucherExpireDateChangeDialog: React.FC<Props> = ({ voucher }) => {
  const [visible, setVisible] = useState(false);
  const [expireDt, setExpireDt] = useState(voucher.expireDt);
  const [expireReason, setExpireReason] = useState('');

  function onClick() {
    setVisible(true);
  }

  function handleExpireReasonChange(e) {
    setExpireReason(e.target.value);
  }

  function onOk() {
    console.log(moment(expireDt).utc().format());
    console.log(expireReason);
    if (!expireReason) {
      return;
    }

    updateCallVoucherExpireDtRequest({
      voucherUid: voucher.uid,
      expireDt: moment(expireDt).utc().format(),
      expireReason: expireReason,
    })
      .then((response) => {
        setVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onCancel() {
    setVisible(false);
  }

  const buttonStyle = {
    marginLeft: '0.5em',
    verticalAlign: 'middle',
  };

  return (
    <>
      <Button type="primary" size="small" onClick={onClick} style={buttonStyle}>
        Change
      </Button>
      <Modal title="Change Expire Date" open={visible} onOk={onOk} onCancel={onCancel}>
        <Space direction="vertical" size={4}>
          <Title level={4}>Expire Date</Title>
          {useMemo(() => {
            const momentObj = expireDt ? moment(expireDt).utc() : null;
            return (
              <DatePicker
                id="filter-date"
                allowClear={false}
                value={momentObj}
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD');
                  return current && current < moment(customDate, 'YYYY-MM-DD');
                }}
                onChange={(expireDt, dateString) => {
                  if (dateString) {
                    const dateStringMoment = moment(dateString);
                    const convertedDate = moment([
                      dateStringMoment.year(),
                      dateStringMoment.month(),
                      dateStringMoment.date(),
                      0,
                      0,
                      0,
                    ]).utc(true);
                    setExpireDt(convertedDate);
                  }
                }}
              />
            );
          }, [expireDt])}
        </Space>
        <br />
        <br />
        <Title level={4}>Reason</Title>
        <Input
          size="large"
          onChange={handleExpireReasonChange}
          placeholder="Please input the reason of changing expiration date of the Voucher or jira link"
        />
      </Modal>
    </>
  );
};
