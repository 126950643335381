import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { Button, Col, List, Modal, Row, Tag, Typography } from 'antd';
import moment from 'moment';

import { useCheckMobile } from '../../utils/screen';
import {
  getStripeCustomerRequest,
  // updateBillingVoucherDenyRequest,
  updateBillingCallVoucherApproveRequest,
  updateBillingCallVoucherDenyRequest,
} from '../../api/billing';
import { ResponsiveTable } from '../ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import {
  BillingCallVoucherRequestRequestedActionTag,
  BillingCallVoucherRequestStatusTag,
  CrmOrganizationVersionTypeTag,
} from '../ui/Tags';
import { dateTimePattern } from '../../utils/date';
import { StripeCardPanels } from '../StripeCardPanels';

const { Title, Text } = Typography;

type Props = {
  crmOrganizationId: CrmOrganization['id'];
  billingCallVoucherRequest: any;
  isReviewer: boolean;
  successCallback: (payload?: any) => void;
};

type FormTitleProps = {
  title: string;
};

function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

function isCustomerCardsValid(stripeCustomer) {
  let isValid = false;

  if (isEmptyObject(stripeCustomer)) {
    return isValid;
  }

  if (stripeCustomer.paymentMethods.length > 0) {
    const today = new Date();
    const paymentMethods = stripeCustomer.paymentMethods;
    const validPayments = paymentMethods.filter((paymentMethod) => {
      const expirationDate = new Date(paymentMethod.card.expYear, paymentMethod.card.expMonth, 0);
      if (expirationDate > today) {
        return paymentMethod;
      }
    });

    if (validPayments.length > 0) {
      isValid = true;
    }
  }
  return isValid;
}

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {item[0]}
        </Col>
        <Col span={12} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item[1] ? item[1] : '-'}
        </Col>
      </Row>
    </List.Item>
  );
};

const FormTitle: React.FC<FormTitleProps> = ({ title }) => (
  <Title
    level={4}
    style={{
      margin: 0,
      marginTop: '16px',
      marginBottom: '8px',
      paddingBottom: '6px',
      fontSize: '16px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    }}
  >
    {title}
  </Title>
);

const alignRight = 'right' as ColumnProps<any>['align'];
const getSubscriptionRequestColumns = () => {
  const billingCallVoucherRequestColumns: Array<ColumnProps<any>> = [
    {
      title: 'Field',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Data',
      dataIndex: 'value',
      key: 'value',
      align: alignRight,
    },
  ];

  return billingCallVoucherRequestColumns;
};

export const VoucherRequestViewDialog: React.FC<Props> = React.memo(
  ({ crmOrganizationId, billingCallVoucherRequest, isReviewer, successCallback }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [stripeCustomer, setStripeCustomer] = useState({} as StripeCustomer);

    const getStripeCustomer = useCallback(async () => {
      getStripeCustomerRequest({ crmOrganizationId })
        .then((response) => {
          setStripeCustomer(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

    const requestedActionAsNoun =
      billingCallVoucherRequest.requestedAction && billingCallVoucherRequest.requestedAction === 'CREATE'
        ? 'Creation'
        : 'Deletion';

    function convertDataSourceDetail(_billingCallVoucherRequest) {
      return [
        ['#ID', _billingCallVoucherRequest.id],
        [
          'Requester',
          <Link to={`/users/${_billingCallVoucherRequest.requester.id}/`}>
            {_billingCallVoucherRequest.requester.email.split('@')[0]}
          </Link>,
        ],
        [
          <span>
            Requested At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          `${moment(_billingCallVoucherRequest.createdAt).utc().format(dateTimePattern.default)}`,
        ],
        [
          'Reviewer',
          (() => {
            if (_billingCallVoucherRequest.reviewer) {
              return (
                <Link to={`/users/${_billingCallVoucherRequest.reviewer.id}/`}>
                  {_billingCallVoucherRequest.reviewer.email.split('@')[0]}
                </Link>
              );
            } else {
              return <span>N/A</span>;
            }
          })(),
        ],
        [
          <span>
            Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          _billingCallVoucherRequest.reviewedAt
            ? `${moment(_billingCallVoucherRequest.reviewedAt).utc().format(dateTimePattern.default)}`
            : '-',
        ],
        [
          'Requested Action',
          <BillingCallVoucherRequestRequestedActionTag requestedAction={_billingCallVoucherRequest.requestedAction} />,
        ],
        ['Status', <BillingCallVoucherRequestStatusTag status={_billingCallVoucherRequest.status} />],
        [
          'Organization',
          <Link
            target="_blank"
            to={
              _billingCallVoucherRequest.crmOrganization === null
                ? '/crm_organizations/'
                : `/crm_organizations/${_billingCallVoucherRequest.crmOrganization.id}/`
            }
          >
            {_billingCallVoucherRequest.crmOrganization === null
              ? 'None'
              : `${_billingCallVoucherRequest.crmOrganization.id} (${_billingCallVoucherRequest.crmOrganization.name})`}
          </Link>,
        ],
        [
          'Organization version',
          _billingCallVoucherRequest.crmOrganization ? (
            <CrmOrganizationVersionTypeTag isSelfServe={_billingCallVoucherRequest.crmOrganization.isSelfServe} />
          ) : (
            '-'
          ),
        ],
        ['Reason', _billingCallVoucherRequest.reason],
      ];
    }

    function convertDataSourceSubscriptionRequestRequestBody(_billingCallVoucherRequest) {
      const subRequestResponseBody = JSON.parse(_billingCallVoucherRequest.requestBody);

      let _dataSource: any = [];

      _dataSource = _dataSource.concat([
        {
          key: 'Duration Days',
          value: <Tag>{subRequestResponseBody['duration_days']} days</Tag>,
        },
        {
          key: 'Fee($)',
          value: <Tag>${subRequestResponseBody['paid_amount'] / 100}</Tag>,
        },
        {
          key: 'Credit',
          value: <Tag>{subRequestResponseBody['credit']}</Tag>,
        },
        {
          key: 'Payment Method',
          value:
            !isEmptyObject(stripeCustomer) && stripeCustomer.paymentMethods.length > 0
              ? stripeCustomer.paymentMethods[0].type
              : 'N/A',
        },
        {
          key: 'Stripe Cards',
          value:
            !isEmptyObject(stripeCustomer) && stripeCustomer.paymentMethods.length > 0 ? (
              isCustomerCardsValid(stripeCustomer) ? (
                <StripeCardPanels
                  stripeCustomer={stripeCustomer}
                  paymentMethod={_billingCallVoucherRequest.crmOrganization.paymentMethod}
                />
              ) : (
                <Text type="danger">Card Expired</Text>
              )
            ) : (
              'N/A'
            ),
        },
      ]);

      return _dataSource;
    }

    function showModal() {
      setVisible(true);
    }

    function handleCancel() {
      setLoading(false);
      setVisible(false);
    }

    function handleApprove(isPayNow = false) {
      setLoading(true);
      updateBillingCallVoucherApproveRequest(billingCallVoucherRequest.id, isPayNow)
        .then((response) => {
          setLoading(false);
        })
        .then(() => {
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handleDeny() {
      setLoading(true);
      updateBillingCallVoucherDenyRequest(billingCallVoucherRequest.id)
        .then((response) => {
          setLoading(false);
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    useEffect(function () {
      getStripeCustomer();
    }, []);

    return (
      <React.Fragment>
        <Button type="default" onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
          View
        </Button>
        <Modal
          open={visible}
          title={`Billing Call Voucher ${requestedActionAsNoun} Request View #${billingCallVoucherRequest.id}`}
          width={useCheckMobile() ? '' : '650px'}
          onOk={() => {
            handleApprove(false);
          }}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            isReviewer && billingCallVoucherRequest.status === 'REQUESTED' ? (
              <Button
                key="submitDeny"
                danger={true}
                disabled={billingCallVoucherRequest.status !== 'REQUESTED'}
                onClick={handleDeny}
              >
                Deny
              </Button>
            ) : (
              ''
            ),
            isReviewer && billingCallVoucherRequest.status === 'REQUESTED' ? (
              <Button
                key="submitApprove"
                type="primary"
                danger={!isEmptyObject(stripeCustomer) && isCustomerCardsValid(stripeCustomer) ? false : true}
                disabled={billingCallVoucherRequest.status !== 'REQUESTED'}
                loading={loading}
                onClick={() => {
                  handleApprove(false);
                }}
              >
                {isCustomerCardsValid(stripeCustomer) ? 'Approve' : 'Approve with No Credit Card'}
              </Button>
            ) : (
              ''
            ),
            isReviewer && billingCallVoucherRequest.status === 'REQUESTED' && isCustomerCardsValid(stripeCustomer) ? (
              <Button
                key="submitApprovePayNow"
                type="primary"
                danger={false}
                disabled={billingCallVoucherRequest.status !== 'REQUESTED'}
                loading={loading}
                onClick={() => {
                  handleApprove(true);
                }}
              >
                Approve and pay
              </Button>
            ) : (
              ''
            ),
          ]}
        >
          <FormTitle title="Detail" />
          <List
            size="small"
            dataSource={convertDataSourceDetail(billingCallVoucherRequest)}
            renderItem={renderInfoItem}
          />
          <hr />
          <FormTitle title={`Requested call voucher request body`} />
          <ResponsiveTable
            bordered={true}
            columns={getSubscriptionRequestColumns()}
            dataSource={convertDataSourceSubscriptionRequestRequestBody(billingCallVoucherRequest)}
            pagination={false}
          />
        </Modal>
      </React.Fragment>
    );
  },
);
