import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getApplicationPlanRequestRequest(id) {
  return insightAxios.get(
    `${API_DOMAIN}/api/application_plan_requests/${id}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getIsReviewerRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/application_plan_requests/is_reviewer/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getApplicationPlanRequestsRequest(limit, offset, crmApplicationId: number | string = '', status: string = '') {
  return insightAxios.get(
    `${API_DOMAIN}/api/application_plan_requests/?limit=${limit}&offset=${offset}&crm_application_id=${crmApplicationId || ''}&status=${status}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

// Deprecated 2024-01-24 @mike.oh
// export function updateApplicationPlanRequestDenyRequest(id) {
//   return insightAxios.patch(
//     `${API_DOMAIN}/api/application_plan_requests/${id}/deny/`,
//     {},
//     {
//       headers: {
//         Authorization: getAuthToken()
//       }
//     }
//   );
// }
//
// export function updateApplicationPlanRequestApproveRequest(id) {
//   return insightAxios.patch(
//     `${API_DOMAIN}/api/application_plan_requests/${id}/approve/`,
//     {},
//     {
//       headers: {
//         Authorization: getAuthToken()
//       }
//     }
//   );
// }
//
// export function createApplicationPlanRequestRequest({
//   crmApplicationId,
//   requestBody,
//   requestedAction = 'CREATE',
//   reason = '',
// }) {
//   return insightAxios.post(
//     `${API_DOMAIN}/api/application_plan_requests/`,
//     {
//       requestBody: requestBody,
//       crmApplicationId: crmApplicationId,
//       requestedAction: requestedAction,
//       reason: reason,
//     },
//     {
//       headers: {
//         Authorization: getAuthToken(),
//         'Content-Type': 'application/json',
//       },
//     },
//   );
// }
