import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmSubscriptionsRequest({ limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getExpiringSubscriptionsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/expiring_subscriptions/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getApplicationSubscriptionsRequest({ appId, offset, limit }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/crm_subscriptions/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getOrganizationSubscriptionsRequest({ organizationId, offset, limit }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${organizationId}/crm_subscriptions/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmSubscriptionRequest({ crmSubscriptionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/${crmSubscriptionId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function createSubscriptionRequest({
  appId,
  organizationId,
  subscriptionStartDt,
  subscriptionEndDt,
  productFee,
  serviceFee,
  billingCycle,
  paymentMethod,
  productFeeDescription,
  serviceFeeDescription,
  paymentTerm,
  memo,
  autoRenewal,
  autoRenewalPeriod,
  type
}) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_subscriptions/`,
    {
      appId,
      organizationId,
      subscriptionStartDt,
      subscriptionEndDt,
      productFee,
      serviceFee,
      billingCycle,
      paymentMethod,
      productFeeDescription,
      serviceFeeDescription,
      paymentTerm,
      memo,
      autoRenewal,
      autoRenewalPeriod,
      type
    },
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function unsubscribeRequest({ subscriptionId }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_subscriptions/${subscriptionId}/unsubscribe/`,
    {},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmPaymentsRequest({ limit, offset, crmSubscriptionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/${crmSubscriptionId}/payments/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function updateSubscriptionRequest({
  subscriptionId, subscriptionStartDt, subscriptionPeriodDurationMonths, subscriptionEndDt, billingCycle, paymentMethod,
  productFee, serviceFee, productFeeDescription, serviceFeeDescription, memo, autoRenewal, autoRenewalPeriod,
}) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_subscriptions/${subscriptionId}/`,
    {
      subscriptionStartDt, subscriptionPeriodDurationMonths, subscriptionEndDt, billingCycle, paymentMethod,
      productFee, serviceFee, productFeeDescription, serviceFeeDescription, memo, autoRenewal, autoRenewalPeriod,
    },
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmSubscriptionDailyRecordsDailyCountsRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscription_daily_records/daily_counts/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmSubscriptionDailyRecordsMonthlyCountsRequest({ startMonth, endMonth }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscription_daily_records/monthly_counts/`,
    {
      params: {
        start_date: startMonth,
        end_date: endMonth
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmSubscriptionAppDifferenceRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscription_daily_records/differences/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getEventLogsRequest({ limit, offset, crmSubscriptionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/${crmSubscriptionId}/event_logs/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
