import React, { useState, useRef } from 'react';
import { Button, Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { DynamicForm } from './ui/DynamicForm';

import { updateApplicationModeratorRequest } from '../api/application';

const getFields = (previous) => {
  return [
    {
      name: 'allowedChannelCustomTypes',
      label: 'Allowed Channel Custom Types (comma separated)',
      component: <Input size="large" defaultValue={previous} />,
    },
  ];
};

type Props = {
  id: string;
  appId: string;
  previous: string;
  successCallback: any;
};

export const EditAllowedChannelCustomTypesDialog: React.FC<Props> = ({ id, appId, previous, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);

    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { allowedChannelCustomTypes } = values;

          updateApplicationModeratorRequest({
            id: id,
            appId: appId,
            allowedChannelCustomTypes: allowedChannelCustomTypes.split(',').map((x: string) => x.trim()),
          })
            .then((response) => {
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback();
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields(previous);

  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          margin: '0.25em',
          marginLeft: 0,
          verticalAlign: 'middle',
        }}
      >
        Edit
      </Button>
      <Modal
        open={visible}
        title={`Edit Allowed Channel Types`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Edit
          </Button>,
        ]}
      >
        <DynamicForm fields={fields as any} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
