import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getDailyMessageCountsRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/daily_message_counts/?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getMonthlyMessageCountsRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/monthly_message_counts/?start_month=${startDate}&end_month=${endDate}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getRegionDailyMessageCountsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/region_daily_message_counts/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
