import React, {useEffect, useState} from 'react';
import {Col, Collapse, List, Row, Space, Switch, Tag, Tooltip, Typography} from 'antd';
import ReactJson from 'react-json-view';

import { SetAllowRegionsDialog } from './SetAllowRegionsDialog';
import { InfoCards } from './InfoCard';
import { IsJsonString } from '../utils/data';
import { CrmOrganizationVersionTypeTag } from './ui/Tags';
import { OrganizationPlanAuthorizedOrganizations } from '../utils/constants/authorization';
import { SetGateMaxAppCountDialog } from './organization/SetGateMaxAppCountDialog';
import { ActivateOrganizationDialog } from './organization/ActivateOrganizationDialog';
import { DeactivateOrganizationDialog } from './organization/DeactivateOrganizationDialog';
import { StripeCardPanels } from './StripeCardPanels';
import { OrganizationPaymentMethodChangeDialog } from './OrganizationPaymentMethodChangeDialog';
import { OrganizationVoucherTypeChangeDialog } from './OrganizationVoucherTypeChangeDialog';
import { OrganizationVoucherSubscriptionChangeDialog } from './OrganizationVoucherSubscriptionChangeDialog';
import { hasPermission, Permissions } from '../utils/permission';
import {updateBillingOrganizationRequest} from '../api/billing';

const { Text } = Typography;
const Panel = Collapse.Panel;

type Props = {
  crmOrganization: CrmOrganization;
  salesforceAccounts: any;
  stripeCustomer: StripeCustomer;
  successUpdateCallback: ({ callback }: { callback: any }) => void;
  voucherSubscription?: any;
  billingOrganization: BillingOrganization
  organizationAttributes: any;
  organizationAttributesUpdateCallback: any;
};

export const CrmOrganizationInfo: React.FC<Props> = ({
  crmOrganization,
  salesforceAccounts,
  stripeCustomer,
  successUpdateCallback,
  voucherSubscription,
  billingOrganization,
  organizationAttributes,
  organizationAttributesUpdateCallback,
}) => {
  const isOrganizationPlanAuthorized = crmOrganization.id
    ? OrganizationPlanAuthorizedOrganizations.includes(crmOrganization.id.toString())
    : false;
  const [isSendInvoice, setIsSendInvoice] = useState(false);
  const [loadingIsSendInvoiceEnabled, setLoadingIsSendInvoiceEnabled] = useState(false);

  useEffect(function () {
    setIsSendInvoice(billingOrganization.isSendInvoice)
  }, [billingOrganization])

  function onSendInvoiceToggleChange(value) {
      setLoadingIsSendInvoiceEnabled(true);
      updateBillingOrganizationRequest({
        uid: crmOrganization.uid,
        isSendInvoice: value,
      })
        .then(() => {
          setIsSendInvoice(value);
          setLoadingIsSendInvoiceEnabled(false);
        })
        .catch((e) => {
          console.error(e);
          setLoadingIsSendInvoiceEnabled(false);
        });
  }



  const detailBasic = [
    {
      key: 'Crm ID',
      value: (
        <React.Fragment>
          {crmOrganization.id} <small>(Gate ID: {crmOrganization.gateOrganizationId})</small>
        </React.Fragment>
      ),
    },
    {
      key: 'UID',
      value: (
        <Text
          copyable={true}
          style={{
            margin: 0,
            wordBreak: 'break-word',
          }}
        >
          {crmOrganization.uid}
        </Text>
      ),
    },
    {
      key: 'Name',
      value: crmOrganization.name,
    },
    {
      key: 'Type',
      value: <CrmOrganizationVersionTypeTag isSelfServe={crmOrganization.isSelfServe} />,
    },
    {
      key: 'Slug Name',
      value: crmOrganization.slugName ? crmOrganization.slugName : '-',
    },
    {
      key: 'Contact',
      value: crmOrganization.contact ? crmOrganization.contact : '-',
    },
    {
      key: 'Billing Email',
      value:
        crmOrganization.billingEmail && crmOrganization.billingEmail.indexOf('[') > -1
          ? JSON.parse(crmOrganization.billingEmail).map((email) => <Text copyable={true}>{email}</Text>)
          : '-',
    },
    {
      key: 'First Conversion',
      value: crmOrganization.firstConversion ? crmOrganization.firstConversion : '-',
    },
    {
      key: (
        <span>
          First Conversion Dt <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmOrganization.firstConversionDt,
    },
    {
      key: 'Stripe Customer',
      value: crmOrganization.stripeCustomerId ? (
        <>
          <a
            href={`https://dashboard.stripe.com/customers/${crmOrganization.stripeCustomerId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {stripeCustomer.email} ({crmOrganization.stripeCustomerId})
          </a>
          <br />
          <span>Created At: {stripeCustomer.created}</span>
        </>
      ) : (
        '-'
      ),
    },
    {
      key: 'Stripe Customer Status',
      value: crmOrganization.stripeCustomerId
        ? `${stripeCustomer.livemode ? 'Live ' : 'Test'} / ${stripeCustomer.delinquent ? 'Delinquent' : 'Clean'}`
        : '-',
    },
    {
      key: 'Stripe Cards',
      value: crmOrganization.stripeCustomerId ? (
        <StripeCardPanels stripeCustomer={stripeCustomer} paymentMethod={crmOrganization.paymentMethod} />
      ) : (
        '-'
      ),
    },
    {
      key: 'Payment Method',
      value: (
        <Space direction="vertical">
          <div>
              {crmOrganization.isSelfServe && crmOrganization.paymentMethod ? crmOrganization.paymentMethod : '-'}
              {crmOrganization.isSelfServe ? (
                <OrganizationPaymentMethodChangeDialog
                  crmOrganization={crmOrganization}
                  successCallback={successUpdateCallback}
                />
              ) : (
                <></>
              )}
          </div>
          {(crmOrganization.isSelfServe && crmOrganization.paymentMethod && crmOrganization.paymentMethod == 'CARD') ?
            <Tooltip title={<div><p>ON: Send invoice to organization other than charge card automatically</p> <p>OFF: Charge card automatically</p></div>}>
              <div>
                <Typography.Text style={{marginRight: '5px'}} >Force Send Invoice</Typography.Text>
                <Switch size="small" loading={loadingIsSendInvoiceEnabled} checked={isSendInvoice}
                        onChange={onSendInvoiceToggleChange} disabled={!hasPermission(Permissions.MANAGE_PAYMENT)}/>
              </div>
            </Tooltip>
            : ''}
        </Space>
      ),
    },
  ];

  const attributes = [
    {
      key: 'Chat Salesforce enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isChatIntegrationSalesforceShow} onChange={organizationAttributesUpdateCallback('isChatIntegrationSalesforceShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Chat Clevertap enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isChatIntegrationsClevertapShow} onChange={organizationAttributesUpdateCallback('isChatIntegrationsClevertapShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Chat Hive enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isChatIntegrationsHiveShow} onChange={organizationAttributesUpdateCallback('isChatIntegrationsHiveShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Chat Braze enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isChatIntegrationsBrazeShow} onChange={organizationAttributesUpdateCallback('isChatIntegrationsBrazeShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Desk Facebook enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isDeskIntegrationsFacebookShow} onChange={organizationAttributesUpdateCallback('isDeskIntegrationsFacebookShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Desk Instagram enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small" checked={!!organizationAttributes?.isDeskIntegrationsInstagramShow} onChange={organizationAttributesUpdateCallback('isDeskIntegrationsInstagramShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Desk Whatsapp enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small" checked={!!organizationAttributes?.isDeskIntegrationsWhatsappShow} onChange={organizationAttributesUpdateCallback('isDeskIntegrationsWhatsappShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Desk Iframe enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isDeskIntegrationsIframeShow} onChange={organizationAttributesUpdateCallback('isDeskIntegrationsIframeShow')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Desk Korean locale enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.deskSettingsLocaleSet &&  organizationAttributes.deskSettingsLocaleSet === 'ko_KR'} onChange={organizationAttributesUpdateCallback('deskSettingsLocaleSet', 'ko_KR')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES) || !!organizationAttributes?.deskSettingsLocaleSet }/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'Chat Advanced Moderation enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isChatAdvancedModerationEnabled} onChange={organizationAttributesUpdateCallback('isChatAdvancedModerationEnabled')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'SMS integrations enabled',
      value: (
        <React.Fragment>
          <Tooltip title={<div><p>You need an organization attributes manage permission</p></div>}>
            <Switch size="small"  checked={!!organizationAttributes?.isSMSIntegrationEnabled} onChange={organizationAttributesUpdateCallback('isSMSIntegrationEnabled')} disabled={!hasPermission(Permissions.MANAGE_ORGANIZATION_ATTRIBUTES)}/>
          </Tooltip>
        </React.Fragment>
      ),
    },
  ]

  const detailStatus = [
    {
      key: 'Allowed Regions',
      value: (
        <React.Fragment>
          {crmOrganization &&
            crmOrganization.allowRegions &&
            JSON.parse(crmOrganization.allowRegions).map((region) => (
              <Tag key={`region-${region}`} style={{ borderRadius: '16px' }}>
                {region.trim()}
              </Tag>
            ))}
          {crmOrganization.id ? (
            <SetAllowRegionsDialog crmOrganization={crmOrganization} successCallback={successUpdateCallback} />
          ) : (
            '-'
          )}
        </React.Fragment>
      ),
    },
    {
      key: 'Status',
      value: (
        <>
          <Tag color={crmOrganization.status === 'ACTIVE' ? 'green' : 'red'} style={{ margin: 0 }}>
            {crmOrganization.status}
          </Tag>
          {crmOrganization.status === 'INACTIVE' && crmOrganization.isSelfServe ? (
            <ActivateOrganizationDialog
              crmOrganizationUid={crmOrganization.uid}
              successCallback={successUpdateCallback}
            />
          ) : (
            ''
          )}
          {crmOrganization.status === 'ACTIVE' ? (
            <DeactivateOrganizationDialog
              crmOrganizationUid={crmOrganization.uid}
              successCallback={successUpdateCallback}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      key: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmOrganization.createdAt,
    },
    {
      key: (
        <span>
          Updated At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmOrganization.updatedAt,
    },
    {
      key: 'Max Application Count',
      value: (
        <React.Fragment>
          {
            <>
              {crmOrganization && crmOrganization.maxApplicationCount ? crmOrganization.maxApplicationCount : '-'}
              {crmOrganization && crmOrganization.id ? (
                <SetGateMaxAppCountDialog crmOrganization={crmOrganization} successCallback={successUpdateCallback} />
              ) : (
                ''
              )}
            </>
          }
        </React.Fragment>
      ),
    },
    {
      key: 'Login CIDR filters',
      value: crmOrganization.loginCidrList,
    },
    {
      key: 'Ignore CIDR filter on 2FA',
      value: crmOrganization.ignoreCidrOnTwoFactorAuthentication ? (
        <Tag color="green" style={{ margin: 0 }}>
          TRUE
        </Tag>
      ) : (
        <Tag style={{ margin: 0 }}>FALSE</Tag>
      ),
    },
    {
      key: 'Salesforce Accounts',
      value: (
        <React.Fragment>
          {salesforceAccounts &&
            salesforceAccounts.map &&
            typeof salesforceAccounts.map === 'function' &&
            salesforceAccounts.map((salesforceAccount) => {
              return (
                <a href={`https://sendbird.lightning.force.com/lightning/r/${salesforceAccount.id}`}>
                  {salesforceAccount.name}
                </a>
              );
            })}
        </React.Fragment>
      ),
    },
    {
      key: 'Organization Plan',
      value:
        isOrganizationPlanAuthorized &&
        crmOrganization.organizationPlan &&
        IsJsonString(crmOrganization.organizationPlan) ? (
          <Collapse>
            <Panel key={`organization-plan-${crmOrganization.id}`} header={`Organization Plan`}>
              <List
                size="small"
                dataSource={[JSON.parse(crmOrganization.organizationPlan)]}
                renderItem={(item) => <ReactJson style={{ overflowX: 'auto', textAlign: 'left' }} src={item} />}
              />
            </Panel>
          </Collapse>
        ) : (
          ''
        ),
    },

    {
      key: 'Voucher Type',
      value: (
        <>
          {crmOrganization.voucherType || '-'}
          {hasPermission(Permissions.MANAGE_ORGANIZATION_VOUCHER_TYPE) ? (
            <OrganizationVoucherTypeChangeDialog
              crmOrganization={crmOrganization}
              successCallback={successUpdateCallback}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: 'Voucher Subscription Fee($)',
      value: (
        <>
          {voucherSubscription.voucherSubscriptionPaymentAmount ? (
            <Tag>${voucherSubscription.voucherSubscriptionPaymentAmount / 100}</Tag>
          ) : (
            '-'
          )}
          {hasPermission(Permissions.MANAGE_ORGANIZATION_VOUCHER_TYPE) ? (
            <OrganizationVoucherSubscriptionChangeDialog
              crmOrganization={crmOrganization}
              voucherSubscription={voucherSubscription}
              successCallback={successUpdateCallback}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: 'Voucher Subscription Credit',
      value: (
        <>
          {voucherSubscription.voucherSubscriptionCredit ? (
            <Tag>{voucherSubscription.voucherSubscriptionCredit}</Tag>
          ) : (
            '-'
          )}
          {hasPermission(Permissions.MANAGE_ORGANIZATION_VOUCHER_TYPE) ? (
            <OrganizationVoucherSubscriptionChangeDialog
              crmOrganization={crmOrganization}
              voucherSubscription={voucherSubscription}
              successCallback={successUpdateCallback}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: 'Voucher Subscription Duration',
      value: (
        <>
          {voucherSubscription.voucherSubscriptionDurationDays ? (
            <Tag>{voucherSubscription.voucherSubscriptionDurationDays} days</Tag>
          ) : (
            '-'
          )}
          {hasPermission(Permissions.MANAGE_ORGANIZATION_VOUCHER_TYPE) ? (
            <OrganizationVoucherSubscriptionChangeDialog
              crmOrganization={crmOrganization}
              voucherSubscription={voucherSubscription}
              successCallback={successUpdateCallback}
            />
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const dataSources = [detailBasic, detailStatus, attributes];

  return (
    <Row>
      <Col span={24}>
        <InfoCards dataSources={dataSources} minHeight="280px" />
      </Col>
    </Row>
  );
};
