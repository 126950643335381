import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Card, Row, Col, Typography, DatePicker } from 'antd';

import { getCrmSubscriptionAppDifferenceRequest } from '../api/subscription';
import { ResponsiveTable } from '../components/ui/ResponsiveTable';

import {
  BillingCycleTag,
  AutoRenewalTag,
  PaymentMethodTag,
} from '../components/ui/Tags';
import { getSearchDate, dateTimePattern, disabledDate } from '../utils/date';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const columns = [
  {
    title: '#ID',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: CrmSubscription['crmApplication']) =>
      crmApplication ? crmApplication.id : '-',
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    key: 'appId',
    render: (appId: CrmSubscription['appId']) => (
      <Link to={`/applications/${appId}/detail/`}>
        {appId.split('-')[0]}...
      </Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: CrmSubscription['crmApplication']) =>
      crmApplication ? crmApplication.appName : '-',
  },
  {
    title: 'Organization',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: CrmSubscription['crmApplication']) =>
      crmApplication && crmApplication.crmOrganization ? (
        <Link to={`/crm_organizations/${crmApplication.crmOrganization.id}/`}>
          {crmApplication.crmOrganization.id} (
          {crmApplication.crmOrganization.name})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Fee (Product/Service)',
    dataIndex: 'productFee',
    key: 'productFee',
    render: (
      productFee: CrmSubscription['productFee'],
      crmSubscription: CrmSubscription,
    ) => (
      <span>
        ${productFee.toLocaleString()} / $
        {crmSubscription.serviceFee.toLocaleString()}
      </span>
    ),
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (paymentMethod: CrmSubscription['paymentMethod']) => {
      return <PaymentMethodTag paymentMethod={paymentMethod} />;
    },
  },
  {
    title: 'Cycle',
    dataIndex: 'billingCycle',
    key: 'billingCycle',
    render: (billingCycle: CrmSubscription['billingCycle']) => {
      return billingCycle ? (
        <BillingCycleTag billingCycle={billingCycle} />
      ) : (
        '-'
      );
    },
  },
  {
    title: 'Auto Renewal',
    dataIndex: 'autoRenewal',
    key: 'autoRenewal',
    render: (
      autoRenewal: CrmSubscription['autoRenewal'],
      crmSubscription: CrmSubscription,
    ) => (
      <AutoRenewalTag
        autoRenewal={autoRenewal}
        autoRenewalPeriod={crmSubscription.autoRenewalPeriod}
      />
    ),
  },
];

type Props = {};

export const DashboardSubscribedDifference: React.FC<Props> = React.memo(() => {
  const { startDate, endDate } = getSearchDate({ days: 31 });
  const [newSubscription, setNewSubscription] = useState<
    Array<CrmSubscription>
  >([]);
  const [expiredSubscription, setExpiredSubscription] = useState<
    Array<CrmSubscription>
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(function() {
    getCrmSubscriptionAppDifferences({ startDate, endDate });
  }, []);

  function getCrmSubscriptionAppDifferences({ startDate, endDate }) {
    setLoading(true);
    getCrmSubscriptionAppDifferenceRequest({ startDate, endDate })
      .then(response => {
        setNewSubscription(response.data.newCrmSubscriptions);
        setExpiredSubscription(response.data.deletedCrmSubscriptions);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }

  function handleCalendarChange(_, dateStrings) {
    const [startDateString, endDateString] = dateStrings;
    getCrmSubscriptionAppDifferences({
      startDate: startDateString,
      endDate: endDateString,
    });
  }

  function handleOnRow(subscritpion: CrmSubscription) {
    return {
      style: {
        textDecoration: subscritpion.crmApplication.removed
          ? 'line-through'
          : '',
        opacity: subscritpion.crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col style={{ marginBottom: '16px' }}>
        <Card
          title="Differences of Subscribed Applications"
          extra={
            <RangePicker
              defaultValue={[moment(startDate), moment(endDate)]}
              allowClear={false}
              format={dateTimePattern.date}
              disabledDate={disabledDate}
              onChange={handleCalendarChange}
            />
          }
        >
          <Title level={4}>New Subscribed Applications</Title>
          <ResponsiveTable
            bordered={true}
            loading={loading}
            columns={columns}
            dataSource={newSubscription}
            pagination={false}
            onRow={handleOnRow}
          />
          <Title level={4} style={{ marginTop: 32 }}>
            Expired Subscribed Applications
          </Title>
          <ResponsiveTable
            bordered={true}
            loading={loading}
            columns={columns}
            dataSource={expiredSubscription}
            pagination={false}
          />
        </Card>
      </Col>
    </Row>
  );
});
