import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Card, Button, DatePicker, Input, Layout } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { dateTimePattern, disabledDate } from '../../utils/date';
import {
  getDownloadChargedPaymentsRequest,
  getDownloadOutstandingPaymentsRequest,
  getDownloadAnnouncementsRequest,
  getDownloadCrmApplicationDailyRecordsRequest,
  getDownloadOrganizationsRequest,
  getDownloadSubscriptionsRequest,
  getDownloadCrmApplicationsWithDelinquentSubscriptionFeeInvoicesRequest,
} from '../../api/export';
import { DynamicForm } from '../../components/ui/DynamicForm';
import { PageTitle } from '../../components/ui/PageTitle';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

enum ExportEnum {
  PAYMENTS = 'PAYMENTS',
  OUTSTANDING = 'OUTSTANDING',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  APP_DAILY = 'APP_DAILY',
  ORGANIZATIONS = 'ORGANIZATIONS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES = 'CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES',
}

const initStart = moment().subtract(1, 'month').add(1, 'day');
const initEnd = moment();

const fields = {
  PAYMENTS: [
    {
      name: 'dates',
      label: 'Period',
      formItemProps: {
        initialValue: [initStart, initEnd],
        rules: [
          {
            type: 'array',
            required: true,
            message: 'Please enter the valid period start date',
          },
        ],
      },
      component: <RangePicker size="large" format={dateTimePattern.date} disabledDate={disabledDate} />,
    },
  ],
  ANNOUNCEMENTS: [
    {
      name: 'appId',
      label: 'App ID',
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please enter the app ID',
          },
        ],
      },
      component: <Input size="large" placeholder="App ID" />,
    },
    {
      name: 'dates',
      label: 'Period',
      formItemProps: {
        initialValue: [initStart, initEnd],
        rules: [
          {
            type: 'array',
            required: true,
            message: 'Please enter the valid period start date',
          },
        ],
      },
      component: <RangePicker size="large" format={dateTimePattern.date} disabledDate={disabledDate} />,
    },
  ],
  APP_DAILY: [
    {
      name: 'appId',
      label: 'App ID',
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please enter the app ID',
          },
        ],
      },
      component: <Input size="large" placeholder="App ID" />,
    },
    {
      name: 'dates',
      label: 'Period',
      formItemProps: {
        initialValue: [initStart, initEnd],
        rules: [
          {
            type: 'array',
            required: true,
            message: 'Please enter the valid period start date',
          },
        ],
      },
      component: <RangePicker size="large" format={dateTimePattern.date} disabledDate={disabledDate} />,
    },
  ],
};

const getDateSources = ({ setForms }) => [
  {
    key: ExportEnum.PAYMENTS,
    title: (
      <>
        Payments <small>(Charged)</small>
      </>
    ),
    content: (
      <>
        <Paragraph>The application list with upcoming subscription end.</Paragraph>
        <DynamicForm fields={fields[ExportEnum.PAYMENTS] as any} setForm={setForms({ key: ExportEnum.PAYMENTS })} />
      </>
    ),
  },
  {
    key: ExportEnum.OUTSTANDING,
    title: 'Outstanding payments',
    content: <Paragraph>Download most outstanding payments</Paragraph>,
  },
  {
    key: ExportEnum.ORGANIZATIONS,
    title: (
      <>
        Organizations <small>(Stripe Account only)</small>
      </>
    ),
    content: <Paragraph>Download the organization list with a stripe account.</Paragraph>,
  },
  {
    key: ExportEnum.SUBSCRIPTIONS,
    title: 'Subscriptions',
    content: <Paragraph>Download subscriptions </Paragraph>,
  },
  {
    key: ExportEnum.CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES,
    title: 'Delinquent subscription fee invoices',
    content: <Paragraph>Download CrmApplications with delinquent subscription fee invoices</Paragraph>,
  },
];

type CardBoxProps = {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
  loading: boolean;
  handleDownload: () => void;
};

type Props = {};

const CardBox: React.FC<CardBoxProps> = React.memo(({ id, title, content, loading, handleDownload }) => (
  <Col span={24} md={12} lg={12}>
    <Card
      title={title}
      actions={[
        <Button
          key={`${id}-download`}
          type="primary"
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={handleDownload}
        >
          Download
        </Button>,
      ]}
      bodyStyle={{ minHeight: '252px' }}
      style={{ marginBottom: '16px' }}
    >
      {content}
    </Card>
  </Col>
));

export const BillingDataExports: React.FC<Props> = React.memo(() => {
  const forms = {
    PAYMENTS: {} as FormInstance,
    OUTSTANDING: {} as FormInstance,
    ANNOUNCEMENTS: {} as FormInstance,
    APP_DAILY: {} as FormInstance,
    ORGANIZATIONS: {} as FormInstance,
    SUBSCRIPTIONS: {} as FormInstance,
    CRM_APPLICATIONS_WITH_DELINQUENT_BILLGS: {} as FormInstance,
  };

  const [loadings, setLoadings] = useState({
    PAYMENTS: false,
    OUTSTANDING: false,
    ANNOUNCEMENTS: false,
    APP_DAILY: false,
    ORGANIZATIONS: false,
    SUBSCRIPTIONS: false,
    CRM_APPLICATIONS_WITH_DELINQUENT_BILLGS: false,
  });

  function setLoading({ key, value }) {
    const nextLoading = loadings;
    nextLoading[`${key}`] = value;
    setLoadings(nextLoading);
  }

  const setForms = ({ key }) => (ref) => {
    forms[key] = ref;
  };

  function download({ response, filename, dates = [] as Array<Moment> }) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset="utf-8"' }));
    const link = document.createElement('a');
    const dateRange =
      dates.length > 0 ? `_${dates[0].format(dateTimePattern.date)}_${dates[1].format(dateTimePattern.date)}` : '';
    link.href = url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${filename}${dateRange}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const handleDownloads = {
    PAYMENTS: function () {
      setLoading({ key: ExportEnum.PAYMENTS, value: true });
      forms[ExportEnum.PAYMENTS]
        .validateFields()
        .then(async (values) => {
          try {
            const { dates } = values;
            const response = await getDownloadChargedPaymentsRequest({
              startDate: dates[0].format(dateTimePattern.date),
              endDate: dates[1].format(dateTimePattern.date),
            });
            download({ response, filename: 'charged_payments', dates });
            setLoading({ key: ExportEnum.PAYMENTS, value: false });
          } catch (error) {
            setLoading({ key: ExportEnum.PAYMENTS, value: false });
            error && error.response && console.error(error.response);
          }
        })
        .then(() => {
          setLoading({ key: ExportEnum.PAYMENTS, value: false });
        });
    },
    OUTSTANDING: async function () {
      setLoading({ key: ExportEnum.OUTSTANDING, value: true });
      try {
        const response = await getDownloadOutstandingPaymentsRequest();
        download({ response, filename: 'outstanding_payments' });
        setLoading({ key: ExportEnum.OUTSTANDING, value: false });
      } catch (error) {
        setLoading({ key: ExportEnum.OUTSTANDING, value: false });
        error && error.response && console.error(error.response);
      }
    },
    ANNOUNCEMENTS: function () {
      setLoading({ key: ExportEnum.ANNOUNCEMENTS, value: true });
      forms[ExportEnum.ANNOUNCEMENTS]
        .validateFields()
        .then(async (values) => {
          try {
            const { appId, dates } = values;
            const response = await getDownloadAnnouncementsRequest({
              appId,
              startDate: dates[0].format(dateTimePattern.date),
              endDate: dates[1].format(dateTimePattern.date),
            });
            download({ response, filename: `announcements_${appId}`, dates });
            setLoading({ key: ExportEnum.ANNOUNCEMENTS, value: false });
          } catch (error) {
            setLoading({ key: ExportEnum.ANNOUNCEMENTS, value: false });
            error && error.response && console.error(error.response);
          }
        })
        .catch(() => {
          setLoading({ key: ExportEnum.ANNOUNCEMENTS, value: false });
        });
    },
    APP_DAILY: function () {
      setLoading({ key: ExportEnum.APP_DAILY, value: true });
      forms[ExportEnum.APP_DAILY]
        .validateFields()
        .then(async (values) => {
          try {
            const { appId, dates } = values;
            const response = await getDownloadCrmApplicationDailyRecordsRequest({
              appId,
              startDate: dates[0].format(dateTimePattern.date),
              endDate: dates[1].format(dateTimePattern.date),
            });
            download({
              response,
              filename: 'crm_application_daily_records',
              dates,
            });
            setLoading({ key: ExportEnum.APP_DAILY, value: false });
          } catch (error) {
            setLoading({ key: ExportEnum.APP_DAILY, value: false });
            error && error.response && console.error(error.response);
          }
        })
        .catch(() => {
          setLoading({ key: ExportEnum.APP_DAILY, value: false });
        });
    },
    ORGANIZATIONS: async function () {
      setLoading({ key: ExportEnum.ORGANIZATIONS, value: true });
      try {
        const response = await getDownloadOrganizationsRequest();
        download({ response, filename: 'crm_organizations' });
        setLoading({ key: ExportEnum.ORGANIZATIONS, value: false });
      } catch (error) {
        setLoading({ key: ExportEnum.ORGANIZATIONS, value: false });
        error && error.response && console.error(error.response);
      }
    },
    SUBSCRIPTIONS: async function () {
      setLoading({ key: ExportEnum.SUBSCRIPTIONS, value: true });
      try {
        const response = await getDownloadSubscriptionsRequest();
        download({ response, filename: 'subscriptions' });
        setLoading({ key: ExportEnum.SUBSCRIPTIONS, value: false });
      } catch (error) {
        setLoading({ key: ExportEnum.SUBSCRIPTIONS, value: false });
        error && error.response && console.error(error.response);
      }
    },
    CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES: async function () {
      setLoading({
        key: ExportEnum.CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES,
        value: true,
      });
      try {
        const response = await getDownloadCrmApplicationsWithDelinquentSubscriptionFeeInvoicesRequest();
        download({
          response,
          filename: `crm_applications_with_delinquent_subscription_fee_invoices (${new Date()
            .toISOString()
            .slice(0, 10)})`,
        });
        setLoading({
          key: ExportEnum.CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES,
          value: false,
        });
      } catch (error) {
        setLoading({
          key: ExportEnum.CRM_APPLICATIONS_WITH_DELINQUENT_SUBSCRIPTION_FEE_INVOICES,
          value: false,
        });
        error && error.response && console.error(error.response);
      }
    },
  };

  return (
    <Layout>
      <PageTitle title="Billing Data Exports" subTitle="requires EXPORT_BILLING_DATA permission." />
      <Layout.Content>
        <Row gutter={16}>
          {getDateSources({ setForms }).map(({ key, title, content }, index) => (
            <CardBox
              key={`${key}=${index}`}
              id={key}
              title={title}
              content={content}
              loading={loadings[key]}
              handleDownload={handleDownloads[key]}
            />
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
});
