import React, {useContext} from 'react';
import {Link, Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {GoogleOutlined} from '@ant-design/icons';
import {Button, Carousel, Col, Divider, Row} from 'antd';
import logoSendBird from '../images/Sendbird_Logo_RGB.svg';
import {AuthenticationContext} from '../containers/AuthenticationProvider';
import {media} from '../utils/media';

const Logo = styled.div`
  ${media.MOBILE_LARGE`
    flex: 1;
  `}
`;

const LogoImage = styled.img`
  max-width: 300px;
  width: 100%;
`;

const CoreValues = styled.div`
  margin: -16px 0 16px;

  .ant-carousel .slick-slide {
    text-align: center;
    line-height: 40px;
    overflow: hidden;
  }

  .ant-carousel .slick-slide h3 {
    font-size: 12px;
    color: #747cad;
  }

  .ant-carousel .slick-dots {
    bottom: 0;

    li {
      button {
        width: 6px;
        height: 2px;
        background: #747cad;
      }

      &.slick-active {
        button {
          width: 14px;
        }
      }
    }
  }
`;

export const SignIn = withRouter<RouteComponentProps, any>(({ location }) => {
  const { authenticated } = useContext(AuthenticationContext);
  const { from } = location.state || { from: { pathname: '/users' } };

  if (authenticated) {
    return <Redirect to={from} />;
  }

  return (
    <Row justify="space-around" align="middle" style={{ height: '60vh' }}>
      <Col span={24} md={{ span: 6 }} style={{ maxWidth: 300, textAlign: 'center' }}>
        <Logo>
          <LogoImage src={logoSendBird} />
        </Logo>
        <Divider
          style={{
            marginTop: '24px',
            fontWeight: 500,
            color: '#353761',
          }}
        >
          INSIGHT
        </Divider>
        <CoreValues>
          <Carousel autoplay={true}>
            <div>
              <h3>Endless Tenacity for Customers</h3>
            </div>
            <div>
              <h3>Better than the Best</h3>
            </div>
            <div>
              <h3>Work to Completion</h3>
            </div>
            <div>
              <h3>The Buck Stops Here</h3>
            </div>
            <div>
              <h3>Already on It</h3>
            </div>
            <div>
              <h3>Highest Corporate Integrity</h3>
            </div>
            <div>
              <h3>Global Citizenship</h3>
            </div>
          </Carousel>
        </CoreValues>
        {process.env.NODE_ENV == 'development' && (
          <Link to="/auth/oauth2/authenticate">
            <Button
              size="large"
              type="primary"
              icon={<GoogleOutlined />}
              style={{
                maxWidth: 300,
                width: '100%',
                background: '#353761',
                borderColor: '#353761',
              }}
            >
              Sign in as Google
            </Button>
          </Link>
        )}
        <Link to="/auth/oauth2_okta/authenticate">
          <Button
            size="large"
            type="primary"
            style={{
              marginTop: '10px',
              maxWidth: 300,
              width: '100%',
              background: '#353761',
              borderColor: '#353761',
            }}
          >
            Sign in with Okta
          </Button>
        </Link>
      </Col>
    </Row>
  );
});
