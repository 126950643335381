export enum AccountClassificationEnum {
  SMB = 'SMB',
  MID_MARKET = 'MID-MARKET',
  ENTERPRISE = 'ENTERPRISE'
}

export enum ChurnReasonEnum {
  SERVICE_CHURN = 'SERVICE_CHURN',
  COMPANY_CHURN = 'COMPANY_CHURN',
  REPLACED_IN = 'REPLACED_IN',
  REPLACED_EX = 'REPLACED_EX',
  REMOVED = 'REMOVED',
  NO_REVENUE = 'NO_REVENUE'
}

export enum TimeunitEnum {
  DAY = 'DAY',
  MINUTE = 'MINUTE'
}

export const accountClassificationOptions = [
  { value: '', label: 'Select' },
  { value: AccountClassificationEnum.SMB, label: 'SMB' },
  { value: AccountClassificationEnum.MID_MARKET, label: 'Mid-market' },
  { value: AccountClassificationEnum.ENTERPRISE, label: 'Enterprise' }
];

export const churnReasonOptions = [
  { value: '', label: 'Select' },
  { value: ChurnReasonEnum.SERVICE_CHURN, label: 'Service churn' },
  { value: ChurnReasonEnum.COMPANY_CHURN, label: 'Company churn' },
  { value: ChurnReasonEnum.REPLACED_IN, label: 'Replaced in' },
  { value: ChurnReasonEnum.REPLACED_EX, label: 'Replaced ex' },
  { value: ChurnReasonEnum.REMOVED, label: 'Removed' },
  { value: ChurnReasonEnum.NO_REVENUE, label: 'No revenue' }
];
