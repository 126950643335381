import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { PermissionInfo } from '../../components/PermissionInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { PermissionStatusTag, StatusTag } from '../../components/ui/Tags';

import { getRolePermissionsRequest } from '../../api/roles';
import { getPermissionRequest, getUserPermissionsRequest } from '../../api/permissions';
import { Layout } from 'antd';

const userPermissionsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/user_permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => <Link to={`/users/${user.id}`}>{user.username}</Link>,
  },
  {
    title: 'Granted At',
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: 'Expired At',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserPermission['status']) => <PermissionStatusTag status={status} />,
  },
];

const rolePermissionsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/role_permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role) => <Link to={`/roles/${role.id}`}>{role.name}</Link>,
  },
  {
    title: 'Granted At',
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: 'Expired At',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserRole['status']) => <StatusTag status={status} />,
  },
];

interface MatchParams {
  permissionId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const Permission = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { permissionId } = match.params;
    const [permission, setPermission] = useState<Permission>({} as Permission);

    const [userPermissions, setUserPermissions] = useState<Array<UserPermission>>([]);

    const [loadingUserPermissions, setLoadingUserPermissions] = useState<boolean>(false);
    const [userPermissionsCount, setUserPermissionsCount] = useState<number>(0);

    const [rolePermissions, setRolePermissions] = useState<Array<UserRole>>([]);
    const [loadingRolePermissions, setLoadingRolePermissions] = useState<boolean>(false);
    const [rolePermissionsCount, setRolePermissionsCount] = useState<number>(0);

    function getPermission() {
      getPermissionRequest({ permissionId })
        .then((response) => {
          setPermission(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getUserPermissions({ offset = 0, limit = 10 }) {
      setLoadingUserPermissions(true);
      getUserPermissionsRequest({ offset, limit, userId: '', permissionId })
        .then((response) => {
          setUserPermissions(response.data.results);
          setLoadingUserPermissions(false);
          setUserPermissionsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingUserPermissions(false);
        });
    }

    function getRolePermissions({ offset = 0, limit = 10 }) {
      setLoadingRolePermissions(true);
      getRolePermissionsRequest({
        offset,
        limit,
        role_id: '',
        permission_id: permissionId,
      })
        .then((response) => {
          setRolePermissions(response.data.results);
          setLoadingRolePermissions(false);
          setRolePermissionsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingRolePermissions(false);
        });
    }

    useEffect(() => {
      getPermission();
      getUserPermissions({});
      getRolePermissions({});
    }, []);

    return (
      <Layout>
        <PageTitle title="Permission" subTitle={`#${permission.id || ''}`} showBack={true} />
        <Layout.Content>
          <PermissionInfo permission={permission} />
          <TableDetail
            title="Users with this permission"
            count={userPermissionsCount}
            loading={loadingUserPermissions}
            columns={userPermissionsColumns}
            dataSource={userPermissions}
            onPageChange={getUserPermissions}
          />
          <TableDetail
            title="Roles with this permission"
            count={rolePermissionsCount}
            loading={loadingRolePermissions}
            columns={rolePermissionsColumns}
            dataSource={rolePermissions}
            onPageChange={getRolePermissions}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
