import React from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';
import {Loading3QuartersOutlined} from '@ant-design/icons';

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Spinner = () => {
  return (
    <SpinWrapper>
      <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 48 }} spin={true} />} />
    </SpinWrapper>
  );
};

export const InnerSpinner = () => {
  return <Loading3QuartersOutlined spin={true} />;
};
