import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { PageTitle } from '../../components/ui/PageTitle';

import { getRolePermissionsRequest } from '../../api/roles';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/role_permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role: RolePermission['role']) => (role ? <Link to={`/roles/${role.id}`}>{role.name}</Link> : '-'),
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    key: 'permission',
    render: (permission: RolePermission['permission']) =>
      permission ? <Link to={`/permissions/${permission.id}`}>{permission.name}</Link> : '-',
  },
  {
    title: (
      <span>
        Granted At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: (
      <span>
        Expired At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt: UserPermission['expiredAt']) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

type Props = RouteComponentProps;

export const RolePermissions = withRouter<Props, any>(({ location }) => {
  const [rolePermissions, setRolePermissions] = useState<Array<RolePermission>>([]);
  const [loading, setLoading] = useState(false);
  const [rolePermissionsCount, setRolePermissionsCount] = useState(0);

  async function getUserRoles(limit, offset) {
    setLoading(true);
    try {
      const response = await getRolePermissionsRequest({
        limit,
        offset,
        role_id: '',
        permission_id: '',
      });
      setRolePermissions(response.data.results);
      setLoading(false);
      setRolePermissionsCount(response.data.count);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPageChange(page, perPage) {
    const offset = (page - 1) * perPage;
    const limit = perPage;
    getUserRoles(offset, limit);
  }

  function handleOnRow(rolePermission: RolePermission) {
    return {
      style: {
        textDecoration: rolePermission.status === 'EXPIRED' ? 'line-through' : '',
        opacity: rolePermission.status === 'EXPIRED' ? 0.5 : 1,
      },
    };
  }

  useEffect(() => {
    getUserRoles(30, 0);
  }, []);

  return (
    <Layout>
      <PageTitle title="Roles to Permissions" subTitle="List of all 1:1 entries of role to its permissions" />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={rolePermissions}
          pagination={{
            total: rolePermissionsCount,
            pageSizeOptions: ['30', '60', '90'],
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
