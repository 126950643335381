import React, { useState, useRef } from 'react';
import moment from 'moment-timezone';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, DatePicker, Switch } from 'antd';

import { DynamicForm } from './ui/DynamicForm';
import { getBrowserTodayMoment, dateTimePattern, getBrowserTimeZoneString } from '../utils/date';
import { paymentMethodOptions, billingCycleOptions } from '../utils/constants/billing';

import { createSubscriptionRequest } from '../api/subscription';
import { FormInstance } from 'antd/lib/form';
// import { numberFormatter, numberParser } from '../utils/form';

// TODO: check setFields

const { TextArea } = Input;
const { Option } = Select;

function useValidation() {
  const [endDateDisabled, setEndDateDisabled] = useState(true);
  const [autoRenewalPeriodDisabled, setAutoRenewalPeriodDisabled] = useState(false);
  return {
    endDateDisabled,
    setEndDateDisabled,
    autoRenewalPeriodDisabled,
    setAutoRenewalPeriodDisabled,
  };
}

const today = getBrowserTodayMoment();
const nextYear = today.clone().add(12, 'months').subtract(1, 'days');

type Props = {
  crmApplication: CrmApplication;
  plan?: ApplicationPlan;
  successCallback: any;
};

export const SubscriptionCreateDialog: React.FC<Props> = ({ crmApplication, plan, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const valid = useValidation();

  const getFields: any = ({ valid }) => {
    return [
      {
        name: 'subscriptionStartDt',
        label: 'Period start',
        formItemProps: {
          initialValue: moment.tz(today, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const subscriptionPeriodDurationMonths = formRef.current?.getFieldValue(
                  'subscriptionPeriodDurationMonths',
                );
                if (value && subscriptionPeriodDurationMonths) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(value)
                      .add(subscriptionPeriodDurationMonths, 'months')
                      .subtract(1, 'days'),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <DatePicker size="large" format={dateTimePattern.date} />,
      },
      {
        name: 'subscriptionPeriodDurationMonths',
        label: 'Period Duration (Months)',
        formItemProps: {
          initialValue: 12,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const start = formRef.current?.getFieldValue('subscriptionStartDt');
                if (valid.endDateDisabled && value && start) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(start).add(value, 'months').subtract(1, 'days'),
                  });
                }

                const reg = /^-?([1-9][0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonAfter="Months" disabled={!valid.endDateDisabled} />,
      },
      {
        name: 'subscriptionEndDt',
        label: 'Period End',
        span: 24,
        formItemProps: {
          initialValue: moment.tz(nextYear, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                formRef.current?.setFieldsValue({
                  subscriptionEndDt: value,
                });
                callback();
              },
            },
          ],
        },
        component: <DatePicker size="large" format={dateTimePattern.date} disabled={valid.endDateDisabled} />,
      },
      {
        name: 'billingCycle',
        label: 'Billing Cycle',
        formItemProps: {
          initialValue: 'MONTHLY',
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {billingCycleOptions.map(({ value, label }) => (
              <Option key={`subscription-billing-cycle-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'paymentMethod',
        label: 'Payment method',
        formItemProps: {
          initialValue: 'CARD',
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {paymentMethodOptions.map(({ value, label }) => (
              <Option key={`subscription-payment-method-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'productFee',
        label: 'Product fee($)',
        formItemProps: {
          initialValue: 30,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'serviceFee',
        label: 'Service fee($)',
        formItemProps: {
          initialValue: 0,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'productFeeDescription',
        label: 'Product fee description',
        formItemProps: {},
        span: 12,
        component: <Input size="large" />,
      },
      {
        name: 'serviceFeeDescription',
        label: 'Service fee description',
        formItemProps: {},
        span: 12,
        component: <Input size="large" />,
      },
      {
        name: 'autoRenewal',
        label: 'Auto renewal',
        formItemProps: {
          valuePropName: 'checked',
          initialValue: true,
          rules: [
            {
              validator: (_, value, callback) => {
                if (value) {
                  valid.setAutoRenewalPeriodDisabled(false);
                } else {
                  valid.setAutoRenewalPeriodDisabled(true);
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Switch />,
      },
      {
        name: 'autoRenewalPeriod',
        label: 'Auto renewal period (Months)',
        formItemProps: {
          initialValue: 12,
          rules: [
            {
              validator: (_, value, callback) => {
                if (valid.autoRenewalPeriodDisabled) {
                  formRef.current?.setFieldsValue({ autoRenewalPeriod: 12 });
                  callback();
                  return;
                }

                const reg = /^-?([1-9][0-9]*)?$/;

                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonAfter="Months" disabled={valid.autoRenewalPeriodDisabled} />,
      },
      {
        name: 'memo ',
        label: 'Memo',
        formItemProps: {},
        component: <TextArea rows={4} />,
      },
    ];
  };

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const {
            subscriptionStartDt,
            subscriptionEndDt,
            billingCycle,
            paymentMethod,
            productFee,
            productFeeDescription,
            serviceFee,
            serviceFeeDescription,
            memo,
            autoRenewal,
            autoRenewalPeriod,
          } = values;

          createSubscriptionRequest({
            appId: crmApplication.appId,
            organizationId: null,
            subscriptionStartDt: subscriptionStartDt.format(dateTimePattern.date),
            subscriptionEndDt: subscriptionEndDt.format(dateTimePattern.date),
            productFee: parseFloat(productFee),
            serviceFee: parseFloat(serviceFee),
            billingCycle,
            paymentMethod,
            productFeeDescription,
            serviceFeeDescription,
            paymentTerm: 15,
            memo: memo ? memo : '',
            autoRenewal,
            autoRenewalPeriod,
            type: 'TYPE_APPLICATION', // Currently 'TYPE_APPLICATION' is supported only.
          })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              valid.setAutoRenewalPeriodDisabled(false);
              valid.setEndDateDisabled(true);
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields({ valid });
  if (plan)
    fields.unshift({
      name: 'appId',
      label: 'App ID',
      formItemProps: {
        initialValue: crmApplication.appId,
      },
      component: <Input size="large" readOnly={true} />,
    });

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create a subscription"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};

// class SubscriptionCreateDialog extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       isOpen: false,
//       apiErrorMessage: '',
//       // period
//       subscriptionStartDt: '',
//       subscriptionEndDt: '',
//       subscriptionPeriodDurationMonths: 12,
//       productFee: 30,
//       serviceFee: 0,
//       productFeeDescription: 'Product Fee',
//       serviceFeeDescription: 'Service Fee',
//       billingCycle: 'MONTHLY',
//       paymentMethod: 'CARD',
//       paymentTerm: 15,
//       memo: '',
//       autoRenewal: true,
//       autoRenewalPeriod: 12,
//       apiLoading: false
//     }

//     this.state = Object.assign({}, this.state)
//     this.openDialog = this.openDialog.bind(this)
//     this.handleSubmit = this.handleSubmit.bind(this)
//   }

//   openDialog() {
//     const today = new Date();
//     const today_date = today.toISOString().split('T')[0];

//     let targetday = new Date();
//     targetday.setFullYear(targetday.getFullYear() + 1);
//     targetday.setDate(targetday.getDate() - 1);
//     const targetday_date = targetday.toISOString().split('T')[0];

//     const tempOpenState = Object.assign({}, this.state, {
//       ...this.state,
//       isOpen: true,
//       subscriptionStartDt: today_date,
//       subscriptionEndDt: targetday_date,
//       subscriptionPeriodDurationMonths: 12,
//     })
//     this.setState(tempOpenState)
//   }

//   handleSubmit() {
//     this.setState({apiLoading: true})

//     insightAxios.post(`${API_DOMAIN}/api/crm_subscriptions/`, {
//       appId: this.props.crmApplication.appId,
//       subscriptionStartDt: this.state.subscriptionStartDt,
//       subscriptionEndDt: this.state.subscriptionEndDt,
//       subscriptionPeriodDurationMonths: this.state.subscriptionPeriodDurationMonths,
//       productFee: this.state.productFee,
//       serviceFee: this.state.serviceFee,
//       billingCycle: this.state.billingCycle,
//       paymentMethod: this.state.paymentMethod,
//       productFeeDescription: this.state.productFeeDescription,
//       serviceFeeDescription: this.state.serviceFeeDescription,
//       paymentTerm: this.state.paymentTerm ? this.state.paymentTerm : 15,
//       memo: this.state.memo,
//       autoRenewal: this.state.autoRenewal,
//       autoRenewalPeriod: this.state.autoRenewalPeriod,
//       type: 'TYPE_APPLICATION',  // Currently 'TYPE_APPLICATION' is supported only.
//     }, {
//       headers: {
//         Authorization: `Token ${localStorage.getItem('token')}`,
//       }
//     }).then((response) => {
//       this.props.successCallBack(response.data)
//       this.setState({isOpen: false, apiLoading: false})
//     }).catch((error) => {
//       this.setState({apiErrorMessage: error.response.data.detail, apiLoading: false})
//     })
//   }

//   updateSubscriptionEndDate(startDateStrnig, months) {
//     let startDate = moment(startDateStrnig, 'YYYY-MM-DD')
//     let endDate = moment(startDateStrnig, 'YYYY-MM-DD').add(months, 'months')
//     if (endDate.format('D') > startDate.format('D') - 1) {
//       endDate.subtract(1, 'days')
//     }
//     let endDateString = endDate.format('YYYY-MM-DD')
//     this.setState({subscriptionEndDt: endDateString})
//   }

//   render() {
//     return (
//       <div>
//         <Button onClick={this.openDialog} variant="outlined">Create Subscription</Button>
//         <Dialog
//           open={this.state.isOpen}
//           onClose={this.toggleDialog}
//           title="Create subscription"
//         >
//           <DialogTitle>
//             Create subscription
//           </DialogTitle>
//           <DialogContent>
//             <TextField type="date" defaultValue={this.state.subscriptionStartDt} label="Period start"
//                        onChange={e => {
//                            this.setState({subscriptionStartDt: e.target.value})
//                            this.updateSubscriptionEndDate(
//                              e.target.value, this.state.subscriptionPeriodDurationMonths)
//                        }}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="number" defaultValue={this.state.subscriptionPeriodDurationMonths} label="Period Duration (months)"
//                        onChange={e => {
//                            this.setState({subscriptionPeriodDurationMonths: e.target.value})
//                            this.updateSubscriptionEndDate(this.state.subscriptionStartDt, e.target.value)
//                        }}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="date" defaultValue={this.state.subscriptionEndDt} label="Period end"
//                        onChange={(e) => this.setState({subscriptionEndDt: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth disabled/>

//             <FormControl margin="normal"  fullWidth>
//               <InputLabel htmlFor="payment-method-simple">Billing Cycle</InputLabel>
//               <Select
//                 value={this.state.billingCycle}
//                 onChange={e => this.setState({ billingCycle: e.target.value }, () => {console.log(this.state)})}
//                 input={<Input id="payment-method-simple" />}
//               >
//                 <MenuItem value="MONTHLY" >MONTHLY</MenuItem>
//                 <MenuItem value="ANNUALLY" >ANNUALLY</MenuItem>
//               </Select>
//             </FormControl>

//             <FormControl margin="normal"  fullWidth>
//               <InputLabel htmlFor="payment-method-simple">Payment Method</InputLabel>
//               <Select
//                 value={this.state.paymentMethod}
//                 onChange={e => this.setState({ paymentMethod: e.target.value }, () => {console.log(this.state)})}
//                 input={<Input id="payment-method-simple" />}
//               >
//                 <MenuItem value="CARD" >CARD</MenuItem>
//                 <MenuItem value="WIRE" >WIRE</MenuItem>
//               </Select>
//             </FormControl>

//             <TextField type="number" defaultValue={this.state.productFee} label="Product Fee ($)"
//                        onChange={(e) => this.setState({productFee: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="text" defaultValue={this.state.productFeeDescription} label="Product Fee Description"
//                        onChange={(e) => this.setState({productFeeDescription: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="number" defaultValue={this.state.serviceFee} label="Service Fee ($)"
//                        onChange={(e) => this.setState({serviceFee: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="text" defaultValue={this.state.serviceFeeDescription} label="Service Fee Description"
//                        onChange={(e) => this.setState({serviceFeeDescription: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="number" defaultValue={this.state.paymentTerm} label="Payment Term"
//                        min="1" max="365" placeholder="15"
//                        onChange={(e) => this.setState({paymentTerm: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />
//             <TextField type="text" defaultValue={this.state.memo} label="Memo" rows="4"
//                        onChange={e => this.setState({memo: e.target.value})}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth multiline/>
//             <FormControlLabel control={
//               <Switch checked={this.state.autoRenewal} color="primary"
//                       onChange={() => this.setState({autoRenewal: !this.state.autoRenewal})} />
//             } label="Auto Renewal"/>
//             <TextField type="number" defaultValue={this.state.autoRenewal ? this.state.autoRenewalPeriod : 12}
//                        label="Auto Renewal Period (months)" max={12} min={1}
//                        onChange={(e) => this.setState({autoRenewalPeriod: e.target.value})}
//                        disabled={!this.state.autoRenewal}
//                        margin="normal" InputLabelProps={{ shrink: true }} fullWidth />

//             {this.state.apiErrorMessage ?
//               <div className="pt-callout pt-intent-danger">
//                 <h5>Error</h5>
//                 <p>{this.state.apiErrorMessage}</p>
//               </div> : ''
//             }

//           </DialogContent>
//           <DialogActions>
//             <Button
//               text="Close"
//               onClick={this.toggleDialog}
//             >
//               Close
//             </Button>
//             <Button
//               onClick={this.handleSubmit}
//               loading={this.state.apiLoading}
//               text="Submit"
//               color="primary"
//             >
//               Submit
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </div>
//     );
//   }

//   toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });
// }
