import React from 'react';
import { Card, Col, List, Row } from 'antd';

type Props = {
  permission: Permission;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={6}>{item.key}</Col>
        <Col span={18} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const PermissionInfo: React.FC<Props> = ({ permission }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'ID',
      value: permission.id,
    },
    {
      key: 'Name',
      value: permission.name, //(<PermissionNameTag name={permission.name} />)
    },
  ];
  const detailAdvanced = [
    {
      key: 'Description',
      value: permission.description,
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '24px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col key={`permission-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '280px',
            }}
          >
            <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
