import * as React from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';

import { Col, Row, Select, Typography, Button } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { getCrmPaymentStatusByApplicationRequest } from '../../api/payment';
import { ColumnProps } from 'antd/lib/table';
import { dateTimePattern } from '../../utils/date';
import { useCheckMobile } from '../../utils/screen';
import { TableFilter } from '../../components/ui/TableFilter';
import queryString from 'query-string';
import { getDownloadCrmApplicationPaymentStatusRequest } from '../../api/export';

const { Title, Text } = Typography;
const { Option } = Select;

const alignRight = 'right' as ColumnProps<any>['align'];

const unpaidFilters = [
  { key: 'Yes', value: 'true' },
  { key: 'All', value: 'false' },
];

function createColumns({ dataSources, title, isMobile }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: 'Org ID',
      dataIndex: 'crmOrganizationId',
      key: 'crmOrganizationId',
      fixed: isMobile ? false : 'left',
      width: '100px',
      render: (text: string) => <Link to={`/crm_organizations/${text}/`}>{text}</Link>,
    },
    {
      title: 'App ID',
      dataIndex: 'title',
      key: 'title',
      fixed: isMobile ? false : 'left',
      width: '100px',
      render: (text: string, row) => (
        <Link to={`/applications/${text}/detail/`}>
          {text} - ({row['appName']})
        </Link>
      ),
    },
  ];

  dataSources.forEach((data, columnIndex) => {
    columns.push({
      title: `${data[title]} (paid /unpaid)`,
      dataIndex: `paidCost-${columnIndex}`,
      key: `cost-${columnIndex}`,
      align: alignRight,
      render: (paidCost: number, row) => {
        const unpaidCost: number = row[`unpaidCost-${columnIndex}`];
        return (
          <>
            <Text>{parseInt(paidCost.toFixed(0)).toLocaleString()}</Text> /{' '}
            <Text type={unpaidCost > 0 ? 'danger' : undefined}>{parseInt(unpaidCost.toFixed(0)).toLocaleString()}</Text>
          </>
        );
      },
    });
  });

  return columns;
}

function convertStackedDataSource({ dataSource }) {
  let source = [] as any;

  dataSource.map((data) => {
    const row = {};
    row['key'] = data.appId;
    row['title'] = data.appId;
    row['crmOrganizationId'] = data.crmOrganizationId;
    row['appName'] = data.appName;
    data.results.map((data, index) => {
      row[`paidCost-${index}`] = data['paidCost'];
      row[`unpaidCost-${index}`] = data['unpaidCost'];
    });
    source.push(row);
  });

  return source;
}

type Props = RouteComponentProps;

export const PaymentStatusByApplication = withRouter<Props, any>(({ location, history }) => {
  const isMobile = useCheckMobile();
  const [paymentStatusByApplication, setPaymentStatusByApplication] = useState<
    Array<{ appId: string; results: Array<any> }>
  >([]);
  const [loading, setLoading] = useState(false);
  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    isUnpaid: qs.isUnpaid ? (qs.isUnpaid as string) : 'false',
  });

  function getCrmPaymentStatusByApplication() {
    setLoading(true);
    getCrmPaymentStatusByApplicationRequest({ ...query })
      .then((response) => {
        setPaymentStatusByApplication(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getCrmPaymentStatusByApplication();
  }, [query]);

  const onIsUnpaidSelect = (value) => {
    setQuery({ ...query, isUnpaid: value });
  };

  const dataSources = convertStackedDataSource({
    dataSource: paymentStatusByApplication,
  });

  const columns = createColumns({
    dataSources: paymentStatusByApplication[0] ? paymentStatusByApplication[0].results : [],
    title: 'month',
    isMobile,
  });

  function download({ response, filename, date }) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset="utf-8"' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${filename}_${date}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  async function handleDownloads() {
    setLoading(true);
    try {
      const response = await getDownloadCrmApplicationPaymentStatusRequest({
        ...query,
      });
      download({
        response,
        filename: 'payment_status',
        date: moment().format(dateTimePattern.date),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error && error.response && console.error(error.response);
    }
  }

  return (
    <React.Fragment>
      <Row style={{ marginBottom: '8px' }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
          <Title level={3}>PaymentStatus By Application</Title>
        </Col>
      </Row>
      <TableFilter
        selects={[
          {
            label: 'UnPaid',
            key: 'unpaid',
            node: (
              <Select value={query.isUnpaid} onSelect={onIsUnpaidSelect} style={{ width: '100%' }}>
                {unpaidFilters.map((unpaid) => (
                  <Option key={unpaid.key} value={unpaid.value}>
                    {unpaid.key}
                  </Option>
                ))}
              </Select>
            ),
          },
        ]}
      />
      <Button type="primary" icon={<DownloadOutlined />} loading={loading} onClick={handleDownloads}>
        Download
      </Button>
      <ResponsiveTable loading={loading} columns={columns} dataSource={dataSources} />
    </React.Fragment>
  );
});
