import * as React from 'react';
import { useState } from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { generateDatabaseOtp } from '../api/otp';

const { Title, Text } = Typography;

type Props = {
  databaseOtp: DatabaseOtp;
  successCallback: (payload?: any) => void;
};

export const DatabaseOtpGenerateDialog: React.FC<Props> = React.memo(({ databaseOtp, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    successCallback();
  }

  function handleSubmit() {
    setLoading(true);
    generateDatabaseOtp({ databaseOtpId: databaseOtp.id })
      .then((response) => {
        databaseOtp.password = response.data.password;
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button type="primary" icon={<UnlockOutlined />} onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Generate
      </Button>
      <Modal
        open={visible}
        title={`Generate #${databaseOtp.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          !databaseOtp.password ? (
            <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            ''
          ),
        ]}
      >
        <Title level={4}>Onetime Password ID</Title>
        <Text>{databaseOtp.id}</Text>
        <Title level={4}>Requester</Title>
        <Text>{databaseOtp.requester ? databaseOtp.requester.email : '-'}</Text>
        <Title level={4}>DB Name</Title>
        <Text>{databaseOtp.dbName}</Text>
        <Title level={4}>Created At</Title>
        <Text>{databaseOtp.createdAt}</Text>
        <Title level={4}>Status</Title>
        <Text>{databaseOtp.status}</Text>
        <Title level={4}>Approved At/By</Title>
        <Text>
          {databaseOtp.approvedAt} {databaseOtp.approver ? `(${databaseOtp.approver.username})` : ''}
        </Text>
        <Title level={4}>Password</Title>
        <Text copyable={true}>{databaseOtp.password}</Text>
      </Modal>
    </React.Fragment>
  );
});
