export const fixedEncodeURIComponent = (str: string): string => {
  /**
   * This function makes a string portable, so it can be transmitted across any network to any computer that supports ASCII characters.
   * @param char !, ', (, ), *
   */
  const encode = function encodeSpecialCharacters(char: string): string {
    return `%${char.charCodeAt(0).toString(16)}`.toUpperCase();
  };

  return encodeURIComponent(str).replace(/[!'()*]/g, encode);
};
