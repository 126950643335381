import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import upperfirst from 'lodash.upperfirst';
import { Tag, Layout } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { REGIONS } from '../../App';
import { getChurnApplicationsRequest } from '../../api/billing';
import { PageTitle } from '../../components/ui/PageTitle';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    key: 'appId',
    render: (appId: CrmApplication['appId']) => (
      <Link to={`/applications/${appId}/detail/`}>
        {appId.split('-')[0]}...
      </Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'appName',
    key: 'appName',
  },
  {
    title: 'Account Classification',
    dataIndex: 'noteAccountClassification',
    key: 'noteAccountClassification',
    render: (
      noteAccountClassification: CrmApplication['noteAccountClassification'],
    ) =>
      noteAccountClassification === '' || !noteAccountClassification ? (
        '-'
      ) : (
        <Tag>{noteAccountClassification}</Tag>
      ),
  },
  {
    title: 'Account Lead',
    dataIndex: 'noteAccountLead',
    key: 'noteAccountLead',
    render: (noteAccountLead: CrmApplication['noteAccountLead']) =>
      noteAccountLead ? noteAccountLead.username : '-',
  },
  {
    title: 'Churn Reason',
    dataIndex: 'noteChurn',
    key: 'noteChurn',
  },
  {
    title: 'Churn Date',
    dataIndex: 'noteChurnDate',
    key: 'noteChurnDate',
  },
  {
    title: 'Organization',
    dataIndex: 'crmOrganization',
    key: 'crmOrganization',
    render: (crmOrganization: CrmApplication['crmOrganization']) =>
      crmOrganization ? (
        <Link to={`/crm_organizations/${crmOrganization.id}/`}>
          {crmOrganization.id} ({crmOrganization.name})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    render: (region: CrmApplication['region']) => (
      <Tag>
        {REGIONS[region]['name']} ({region})
      </Tag>
    ),
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (plan: CrmApplication['plan']) => (
      <Tag color={plan === 'enterprise' ? 'purple' : 'blue'}>
        {upperfirst(plan)}
      </Tag>
    ),
  },
];

type Props = RouteComponentProps;

export const ChurnApplications = withRouter<Props, any>(({ location }) => {
  const [applications, setApplications] = useState<Array<CrmApplication>>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  function getChurnApplications(offset = 0, limit = 30) {
    setLoading(true);
    getChurnApplicationsRequest({ offset, limit })
      .then((response) => {
        setApplications(response.data.results);
        setLoading(false);
        setCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  useEffect(() => {
    getChurnApplications(0, 30);
  }, [location]);

  function handleOnRow(crmApplication: CrmApplication) {
    return {
      style: {
        textDecoration: crmApplication.removed ? 'line-through' : '',
        opacity: crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  const onPageChange = (page, pageSize) => {
    getChurnApplications((page - 1) * pageSize, pageSize);
  };

  return (
    <Layout>
      <PageTitle
        title="Churn Applications"
        subTitle={
          <ul>
            <li>
              <Tag>@sendbird.com</Tag>email owner applications excluded.
            </li>
            <li>Ordered by owner email.</li>
          </ul>
        }
      />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={applications}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
