import React from 'react';
import { Redirect } from 'react-router-dom';

import { OKTA_CLIENT_ID } from '../utils/constants/apikey'
import { WEB_DOMAIN } from '../App';

class AuthByOauth2Okta extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToReferrer: false,
    }

    this.oauth2AuthUrl =
      'https://sendbird.okta.com/oauth2/v1/authorize?' +
      'redirect_uri=' +
      WEB_DOMAIN +
      '/auth/oauth2_okta/callback' +
      '&client_id=' +
      OKTA_CLIENT_ID +
      '&scope=openid%20profile%20email' +
      '&response_type=code&state=state';
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/users' } }

    if (localStorage.getItem('token')) {
      return (
        <Redirect to={from}/>
      )
    } else {
      window.location.href = this.oauth2AuthUrl
    }

    return null
  }
}

export default AuthByOauth2Okta;
