import { Badge, Tooltip } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { PresetColorTypes } from 'antd/lib/_util/colors';
import React from 'react';
import { regionNames } from '../../../utils/constants';

import './RegionBadge.css';

const getRegionColor = (region: string) =>
  PresetColorTypes[
    Object.values(regionNames)
      .map(({ value }) => value)
      .indexOf(region) % PresetColorTypes.length
  ];

export const RegionBadge = ({ region, ...badgeProps }: { region: string } & BadgeProps) => {
  const regionLabel = regionNames.find((item) => item.value === region)?.key ?? region;
  const color = getRegionColor(region);
  return (
    <Tooltip title={regionLabel} color={color}>
      <Badge color={color} className="RegionBadge" {...badgeProps} />
    </Tooltip>
  );
};
