import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { PageTitle } from '../../components/ui/PageTitle';

import { getPermissionsRequest } from '../../api/permissions';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

type Props = RouteComponentProps;

export const Permissions = withRouter<Props, any>(({ location }) => {
  const [permissions, setPermissions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [permissionsCount, setPermissionsCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 30,
  });

  async function getPermissions(offset: number = 0, limit: number = 0, query: string = '') {
    if (query === '') {
      query = searchQuery;
    }
    setLoading(true);
    try {
      const response = await getPermissionsRequest({ offset, limit });
      setPermissions(response.data.results);
      setLoading(false);
      setPermissionsCount(response.data.count);
      setPagination({
        offset,
        limit,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPageChange(page, limit) {
    const offset = (page - 1) * limit;
    setPagination({
      offset,
      limit,
    });
    getPermissions(offset, limit);
  }

  useEffect(() => {
    const query = location.search.split('q=')[1] ? location.search.split('q=')[1] : '';
    setSearchQuery(query);
    getPermissions(pagination.offset, pagination.limit);
  }, []);

  return (
    <Layout>
      <PageTitle title="Permissions" />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={permissions}
          pagination={{
            total: permissionsCount,
            pageSizeOptions: ['30', '60', '90'],
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
