import * as React from 'react';
import {Card, Col, List, Row,} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

type Props = {
  ticket: Ticket;
  region: string | string[];
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const TicketDetailInfo: React.FC<Props> = ({
  ticket,
  region,
}) => {

  const detailBasic = [
    {
      key: 'id',
      value: ticket.id,
    },
    {
      key: 'project',
      value: ticket.project.pid ? <Link to={`/desk_metrics/projects/${ticket.project.pid}/?region=${region}`}>{ticket.project.name}</Link> : '-',
    },
    {
      key: 'status',
      value: ticket.status2,
    },
    {
      key: 'channelName',
      value: ticket.channelName,
    },
    {
      key: 'channelType',
      value: ticket.channelType,
    },
    {
      key: 'channelUrl',
      value: ticket.channelUrl,
    },
    {
      key: 'closeComment',
      value: ticket.closeComment,
    },
    {
      key: 'closeStatus',
      value: ticket.closeStatus,
    },
    {
      key: 'customerId',
      value: ticket.customerId,
    },
    {
      key: 'customerSatisfactionComment',
      value: ticket.customerSatisfactionComment,
    },
    {
      key: 'customerSatisfactionScore',
      value: ticket.customerSatisfactionScore,
    },
    {
      key: 'durationTime',
      value: ticket.durationTime,
    },
    {
      key: 'firstAssignmentToCloseTime',
      value: ticket.firstAssignmentToCloseTime,
    },
    {
      key: 'firstResponseTime',
      value: ticket.firstResponseTime,
    },
    {
      key: 'groupId',
      value: ticket.groupId,
    },
    {
      key: 'facebookPageId',
      value: ticket.facebookPageId,
    },
    {
      key: 'instagramUserId',
      value: ticket.instagramUserId,
    },
    {
      key: 'twitterUserId',
      value: ticket.twitterUserId,
    },
    {
      key: 'lastMessageAt',
      value: ticket.lastMessageAt,
    },
    // { // TODO: error handling
    //   key: 'lastMessageMembers',
    //   value: ticket.lastMessageMembers,
    // },
  ];
  const detailAdvanced = [
    {
      key: 'createdAt',
      value: ticket.createdAt,
    },
    {
      key: 'issuedAt',
      value: ticket.issuedAt,
    },
    {
      key: 'closedAt',
      value: ticket.closedAt,
    },
    // { // TODO: error handling
    //   key: 'info',
    //   value: ticket.info,
    // },
    // { // TODO: error handling
    //   key: 'data',
    //   value: ticket.data,
    // },
    // { // TODO: error handling
    //   key: 'customFields',
    //   value: ticket.customFields,
    // },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Wrapper>
      <Row
        gutter={16}
        style={{
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '16px 8px 0',
          background: '#f0f2f5',
        }}
      >
        {dataSources.map((dataSource, index) => (
          <Col key={`-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
            <Card
              size="small"
              style={{
                minHeight: '370px',
              }}
            >
              <List size="small" dataSource={dataSource} renderItem={renderInfoItem} style={{overflow: 'auto'}} />
            </Card>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};
