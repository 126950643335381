import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BarChartOutlined } from '@ant-design/icons';
import { Select, Tabs, Button, Modal, Switch, Layout, Typography } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

import { PrivateRoute } from '../../routes/PrivateRoute';
import { ApplicationDetail } from '../../components/ApplicationDetail';
import { DailyRecords } from '../../components/DailyRecords';
import { ApplicationMonthlyFeatureUsages } from '../../components/ApplicationMonthlyFeatureUsages';
import { ApplicationDailyFeatureUsages } from '../../components/ApplicationDailyFeatureUsages';

import { ActiveRecordPricings } from '../../components/ActiveRecordPricings';
import { ActiveRecords } from '../../components/ActiveRecords';
import { Channels } from '../../components/Channels';
import { ApplicationAnnouncements } from '../../components/ApplicationAnnouncements';
import { PageTitle } from '../../components/ui/PageTitle';
import { ButtonFavorite } from '../../components/ButtonFavorite';
import { PlanTag, RegionTag } from '../../components/ui/Tags';

import {
  getCrmApplicationRequest,
  getApplicationAttributesRequest,
  changeApplicationCallsEnabledRequest,
  changeApplicationDeskEnabledRequest,
} from '../../api/application';
import { media } from '../../utils/media';
import { ApplicationAttributes } from '../../components/ApplicationAttributes';
import { ApplicationModerators } from '../../components/ApplicationModerators';
import { useCheckMobile } from '../../utils/screen';
import { getOrganizationSubscriptionsRequest } from '../../api/billing';

const { TabPane } = Tabs;
const { Option } = Select;

const BreakSpan = styled.span`
  display: block;

  ${media.MOBILE_LARGE`
    display: inline-block;
  `}
`;

const urls = ({ appId, region }: { appId: CrmApplication['appId']; region: CrmApplication['region'] }) => [
  `/applications/${appId}/detail/`,
  `/applications/${appId}/daily_records/`,
  `/applications/${appId}/daily_feature_usages/`,
  `/applications/${appId}/monthly_feature_usages/`,
  // Deprecated 2024-01-24 @mike.oh
  // `/applications/${appId}/active_user_records/`,
  // `/applications/${appId}/active_user_record_pricings/`,
  `/applications/${appId}/channels/`,
  `/applications/${appId}/announcements/`,
  `/applications/${appId}/attributes/`,
  `/applications/${appId}/moderators/`,
];

const getRoutes = ({
  appId,
  crmApplication,
  getCrmApplicationRequest,
}: {
  appId: CrmApplication['appId'];
  crmApplication: CrmApplication;
  getCrmApplicationRequest: PrivateRouteProps['getCrmApplicationRequest'];
}) => [
  {
    label: 'Detail',
    url: urls({ appId, region: crmApplication.region })[0],
    value: 1,
    route: (
      <PrivateRoute
        exact={true}
        path="/applications/:appId/detail/"
        crmApplication={crmApplication}
        getCrmApplicationRequest={getCrmApplicationRequest}
        component={ApplicationDetail}
      />
    ),
  },
  {
    label: 'Daily Record',
    url: urls({ appId, region: crmApplication.region })[1],
    value: 2,
    route: <PrivateRoute path="/applications/:appId/daily_records/" component={DailyRecords} />,
  },
  {
    label: (
      <span>
        Daily<BreakSpan>Feature</BreakSpan>
        <BreakSpan>Usages</BreakSpan>
      </span>
    ),
    url: urls({ appId, region: crmApplication.region })[2],
    value: 3,
    route: (
      <PrivateRoute
        exact={true}
        path="/applications/:appId/daily_feature_usages/"
        component={ApplicationDailyFeatureUsages}
      />
    ),
  },
  {
    label: (
      <span>
        Monthly<BreakSpan>Feature</BreakSpan>
        <BreakSpan>Usages</BreakSpan>
      </span>
    ),
    url: urls({ appId, region: crmApplication.region })[3],
    value: 4,
    route: (
      <PrivateRoute
        exact={true}
        path="/applications/:appId/monthly_feature_usages/"
        component={ApplicationMonthlyFeatureUsages}
      />
    ),
  },
  // Deprecated 2024-01-24 @mike.oh
  // {
  //   label: (
  //     <span>
  //       DAU/MAU <BreakSpan>Records</BreakSpan>
  //     </span>
  //   ),
  //   url: urls({ appId, region: crmApplication.region })[4],
  //   value: 5,
  //   route: <PrivateRoute exact={true} path="/applications/:appId/active_user_records/" component={ActiveRecords} />,
  // },
  // Deprecated 2024-01-24 @mike.oh
  // {
  //   label: (
  //     <span>
  //       DAU/MAU <BreakSpan>Records</BreakSpan>
  //       <small>(Billing)</small>
  //     </span>
  //   ),
  //   url: urls({ appId, region: crmApplication.region })[5],
  //   value: 6,
  //   route: (
  //     <PrivateRoute
  //       exact={true}
  //       path="/applications/:appId/active_user_record_pricings/"
  //       component={ActiveRecordPricings}
  //     />
  //   ),
  // },
  {
    label: 'Channels',
    url: urls({ appId, region: crmApplication.region })[6],
    value: 5,
    route: <PrivateRoute exact={true} path="/applications/:appId/channels/" component={Channels} />,
  },
  {
    label: 'Announcements',
    url: urls({ appId, region: crmApplication.region })[7],
    value: 6,
    route: (
      <PrivateRoute exact={true} path="/applications/:appId/announcements/" component={ApplicationAnnouncements} />
    ),
  },
  {
    label: 'Attributes',
    url: urls({ appId, region: crmApplication.region })[8],
    value: 7,
    route: <PrivateRoute exact={true} path="/applications/:appId/attributes/" component={ApplicationAttributes} />,
  },
  {
    label: 'Moderators',
    url: urls({ appId, region: crmApplication.region })[9],
    value: 8,
    route: <PrivateRoute exact={true} path="/applications/:appId/moderators/" component={ApplicationModerators} />,
  },
];

const DetailInfo = styled.div`
  position: relative;
`;

interface MatchParams {
  appId: string;
}

interface RoutesProps {
  label: React.ReactNode;
  url: string;
  value: number;
  route: React.ReactNode;
}

type SubtitleProps = {
  appId: CrmApplication['appId'];
  appName: CrmApplication['appName'];
  region: CrmApplication['region'];
  plan: CrmApplication['plan'];
};

type NavProps = {
  tabKey: TabsProps['activeKey'];
  handleTabChange: TabsProps['onChange'];
  routes: Array<RoutesProps>;
};

type Props = RouteComponentProps<MatchParams>;

const Subtitle: React.FC<SubtitleProps> = ({ appId, appName, region, plan }) => (
  <DetailInfo>
    <Typography.Text copyable={true}>{appId + ' (' + appName + ')'}</Typography.Text> /{' '}
    {region ? <RegionTag region={region} /> : '-'} / {plan === '' || !plan ? '-' : <PlanTag plan={plan} />}/{' '}
    <Link to={`/metrics/applications/${appId}`}>
      <Button style={{ borderRadius: '16px', fontSize: '12px' }} icon={<BarChartOutlined />}>
        Metrics
      </Button>
    </Link>
  </DetailInfo>
);

const DesktopNav: React.FC<NavProps> = React.memo(({ tabKey, handleTabChange, routes }) => (
  <Tabs activeKey={tabKey} onChange={handleTabChange} tabPosition="left" style={{ overflow: 'initial' }}>
    {routes.map(({ label, value, route }) => {
      return (
        <TabPane key={value.toString()} tab={label}>
          {route}
        </TabPane>
      );
    })}
  </Tabs>
));

const MobileNav: React.FC<NavProps> = React.memo(({ tabKey, handleTabChange, routes }) => (
  <React.Fragment>
    <Select value={tabKey} size="large" onChange={handleTabChange} style={{ width: '100%' }}>
      {routes.map(({ label, value }) => {
        return (
          <Option key={`application-tab-${value}`} value={value.toString()}>
            {label}
          </Option>
        );
      })}
    </Select>
    {routes.map(({ route }) => route)}
  </React.Fragment>
));

export const ApplicationContainer = withRouter<Props, any>(
  React.memo<Props>(({ match, location, history }) => {
    const isMobile = useCheckMobile();
    const [crmApplication, setCrmApplication] = useState<CrmApplication>({} as CrmApplication);
    const [tabKey, setTabKey] = useState<TabsProps['activeKey']>('1');
    const { appId } = match.params;

    const [controlModalVisible, setControlModalVisible] = useState(false);
    const [applicationCallsEnabled, setApplicationCallsEnabled] = useState(false);
    const [loadingApplicationCallsEnabled, setLoadingApplicationCallsEnabled] = useState(false);
    const [applicationDeskEnabled, setApplicationDeskEnabled] = useState(false);
    const [loadingApplicationDeskEnabled, setLoadingApplicationDeskEnabled] = useState(false);
    const [applicationControlButtonVisible, setApplicationControlButtonVisible] = useState(false);

    function setCurrentTabKey({ appId, region }) {
      const baseUrl = `${location.pathname}${location.search}`;
      let index = urls({ appId, region }).findIndex((url) => baseUrl.includes(url));
      setTabKey((index + 1).toString());
    }

    function getCrmApplication() {
      return getCrmApplicationRequest({ appId })
        .then((response) => {
          setCrmApplication(response.data);
          setCurrentTabKey({
            appId: response.data.appId,
            region: response.data.region,
          });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject();
        });
    }

    function handleTabChange(value) {
      setTabKey(value);
      history.replace(urls({ appId, region: crmApplication.region })[parseInt(value) - 1]);
    }

    useEffect(function () {
      getCrmApplication().then((crmApplication) => {
        checkApplicationControlButtonVisible(crmApplication);
      });
    }, []);

    const routes = getRoutes({
      appId,
      crmApplication,
      getCrmApplicationRequest: getCrmApplication,
    });

    const showModal = () => {
      setControlModalVisible(true);
      getApplicationAttributesRequest({ appId })
        .then((response) => {
          setApplicationCallsEnabled(JSON.parse(response.data.sendbirdCalls.enabled));
          setApplicationDeskEnabled(JSON.parse(response.data.deskWebhook.enabled));
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const changeApplicationCallsEnabled = (checked) => {
      setLoadingApplicationCallsEnabled(true);
      changeApplicationCallsEnabledRequest({
        appId: crmApplication.appId,
        enabled: checked,
      })
        .then(() => {
          setApplicationCallsEnabled(checked);
          setLoadingApplicationCallsEnabled(false);
        })
        .catch((e) => {
          console.error(e);
          setLoadingApplicationCallsEnabled(false);
        });
    };

    const changeApplicationDeskEnabled = (checked) => {
      setLoadingApplicationDeskEnabled(true);
      changeApplicationDeskEnabledRequest({
        appId: crmApplication.appId,
        enabled: checked,
      })
        .then(() => {
          setApplicationDeskEnabled(checked);
          setLoadingApplicationDeskEnabled(false);
        })
        .catch((e) => {
          console.error(e);
          setLoadingApplicationDeskEnabled(false);
        });
    };

    const handleOk = () => {
      setControlModalVisible(false);
    };

    const handleCancel = () => {
      setControlModalVisible(false);
    };

    function getOrganizationSubscriptions(crmOrganization): Promise<any> {
      return getOrganizationSubscriptionsRequest({
        organizationUid: crmOrganization.uid,
      })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject();
        });
    }

    function checkApplicationControlButtonVisible(crmApplication) {
      const org = crmApplication.crmOrganization;
      if (org) {
        getOrganizationSubscriptions(org).then((data) => {
          const currentSubscriptions = data.filter((sub) => sub.current && sub.product === 'CHAT');
          if (org.isSelfServe && currentSubscriptions.length === 1) {
            setApplicationControlButtonVisible(true);
          }
        });
      }
    }

    const button = (
      <>
        <Button type="primary" onClick={showModal}>
          Show application control
        </Button>
        <Modal
          title="Application control"
          open={controlModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              Close
            </Button>,
          ]}
        >
          <div>
            <p style={{ marginTop: '-10px', fontWeight: 500, fontSize: '16px' }}>
              This action will apply immediately and irrevocable
            </p>
            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
              <span style={{ fontWeight: 500 }}>
                Calls
                <small style={{ opacity: 0.5 }}> &nbsp;(Only available at ap-1, ap-2, ap-5, us-1, us-2, eu-1)</small>
              </span>
              <Switch
                style={{ float: 'right' }}
                checked={applicationCallsEnabled}
                disabled={applicationCallsEnabled ? true : false}
                loading={loadingApplicationCallsEnabled}
                onChange={changeApplicationCallsEnabled}
              />
            </div>
            <div>
              <span style={{ fontWeight: 500 }}>
                Desk
                <small style={{ opacity: 0.5 }}>
                  {' '}
                  &nbsp;(Only available at ap-1, ap-2, ap-5, ap-8, us-1, us-2, us-3, ca-1, eu-1, vmuae, woowa, woowatest)
                </small>
              </span>
              <Switch
                style={{ float: 'right' }}
                checked={applicationDeskEnabled}
                loading={loadingApplicationDeskEnabled}
                onChange={changeApplicationDeskEnabled}
              />
            </div>
          </div>
        </Modal>
      </>
    );

    return (
      <Layout>
        <PageTitle
          title="Application"
          subTitle={<ButtonFavorite appId={appId} />}
          showBack={true}
          extra={applicationControlButtonVisible ? [button] : []}
        >
          <Subtitle
            appId={crmApplication.appId}
            region={crmApplication.region}
            appName={crmApplication.appName}
            plan={crmApplication.plan}
          />
        </PageTitle>
        <Layout.Content style={{ padding: 0 }}>
          {isMobile ? (
            <MobileNav tabKey={tabKey} handleTabChange={handleTabChange} routes={routes} />
          ) : (
            <DesktopNav tabKey={tabKey} handleTabChange={handleTabChange} routes={routes} />
          )}
        </Layout.Content>
      </Layout>
    );
  }),
);
