import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Layout } from 'antd';

import { getCrmSubscriptionsRequest } from '../../api/subscription';
import {
  BillingCycleTag,
  AutoRenewalTag,
  PaymentMethodTag,
} from '../../components/ui/Tags';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { PageTitle } from '../../components/ui/PageTitle';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    render: (id: CrmSubscription['id']) => (
      <Link to={`/crm_subscriptions/${id}/`}>{id}</Link>
    ),
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    render: (appId: CrmSubscription['appId']) => (
      <Link to={`/applications/${appId}/detail/`}>
        {`${appId.split('-')[0]}...`}
      </Link>
    ),
  },
  {
    title: 'Organization',
    dataIndex: 'crmApplication',
    render: (crmApplication: CrmApplication) =>
      crmApplication && crmApplication.crmOrganization ? (
        <Link to={`/crm_organizations/${crmApplication.crmOrganization.id}/`}>
          {crmApplication.crmOrganization.id} (
          {crmApplication.crmOrganization.name})
        </Link>
      ) : (
        ''
      ),
  },
  {
    title: 'Product Fee',
    dataIndex: 'productFee',
    render: (productFee: CrmSubscription['productFee']) =>
      `$${productFee.toLocaleString()}`,
  },
  {
    title: 'Service Fee',
    dataIndex: 'serviceFee',
    render: (serviceFee: CrmSubscription['serviceFee']) =>
      `$${serviceFee.toLocaleString()}`,
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    render: (paymentMethod: CrmSubscription['paymentMethod']) => (
      <PaymentMethodTag paymentMethod={paymentMethod} />
    ),
  },
  {
    title: 'Cycle',
    dataIndex: 'billingCycle',
    key: 'billingCycle',
    render: (billingCycle: CrmSubscription['billingCycle']) => (
      <BillingCycleTag billingCycle={billingCycle} />
    ),
  },
  {
    title: 'Auto Renewal',
    dataIndex: 'autoRenewal',
    render: (
      autoRenewal: CrmSubscription['autoRenewal'],
      crmSubscription: CrmSubscription,
    ) => (
      <AutoRenewalTag
        autoRenewal={autoRenewal}
        autoRenewalPeriod={crmSubscription.autoRenewalPeriod}
      />
    ),
  },
  {
    title: 'Start Date',
    dataIndex: 'subscriptionStartDt',
  },
  {
    title: 'End Date',
    dataIndex: 'subscriptionEndDt',
  },
];

type Props = RouteComponentProps;

export const CrmSubscriptions = withRouter<Props, any>(({ location }) => {
  const [crmSubscriptions, setCrmSubscription] = useState<
    Array<CrmSubscription>
  >([]);
  const [loading, setLoading] = useState(false);
  const [crmSubscriptionsCount, setCrmSubscriptionsCount] = useState(0);

  useEffect(() => {
    getCrmSubscriptions(0, 30);
  }, [location]);

  const getCrmSubscriptions = (offset = 0, limit = 0) => {
    setLoading(true);
    getCrmSubscriptionsRequest({ offset, limit })
      .then((response) => {
        setCrmSubscription(response.data.results);
        setLoading(false);
        setCrmSubscriptionsCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onPageChange = (page, pageSize) => {
    getCrmSubscriptions((page - 1) * pageSize, pageSize);
  };

  function handleOnRow(crmSubscription: CrmSubscription) {
    return {
      style: {
        textDecoration: crmSubscription.isActive ? '' : 'line-through',
        opacity: crmSubscription.isActive ? 1 : 0.5,
      },
    };
  }

  return (
    <Layout>
      <PageTitle title="Subscriptions (Ver 1)" />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmSubscriptions}
          pagination={{
            total: crmSubscriptionsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
