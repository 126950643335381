import React, { useRef } from 'react';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, DatePicker } from 'antd';
import moment from 'moment-timezone';

import { DynamicForm } from './ui/DynamicForm';
import { paymentMethodOptions, paymentTypeOptions } from '../utils/constants/billing';
import { createPaymentRequest } from '../api/billing';
import { dateTimePattern } from '../utils/date';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  crmApplication: CrmApplication;
  plan?: ApplicationPlan;
  successCallback: any;
};

export const PaymentCreateDialog: React.FC<Props> = ({ crmApplication, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fields = [
    {
      name: 'paymentType',
      label: 'Payment type',
      formItemProps: {
        initialValue: 'ONETIME',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      span: 8,
      component: (
        <Select size="large" style={{ width: '100%' }}>
          {paymentTypeOptions.map(({ value, label }) => (
            <Option key={`payment-type-${value}}`} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'subscriptionId',
      label: 'Subscription ID',
      formItemProps: {},
      span: 8,
      component: <Input size="large" />,
    },
    {
      name: 'paymentMethod',
      label: 'Payment method',
      formItemProps: {
        initialValue: 'CARD',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      span: 8,
      component: (
        <Select size="large" style={{ width: '100%' }}>
          {paymentMethodOptions.map(({ value, label }) => (
            <Option key={`payment-method-${value}}`} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'productFee',
      label: 'Product fee($)',
      formItemProps: {
        initialValue: 30,
        rules: [
          {
            required: true,
            message: 'Required',
          },
          {
            validator: (_, value, callback) => {
              const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
              if (value === '') {
                return callback && callback();
              } else if (!(!isNaN(value) && reg.test(value))) {
                return callback && callback('Please enter the valid value');
              }

              callback();
            },
          },
        ],
      },
      span: 12,
      component: <Input size="large" addonBefore="$" />,
    },
    {
      name: 'serviceFee',
      label: 'Service fee($)',
      formItemProps: {
        initialValue: 0,
        rules: [
          {
            required: true,
            message: 'Required',
          },
          {
            validator: (_, value, callback) => {
              const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
              if (value === '') {
                return callback && callback();
              } else if (!(!isNaN(value) && reg.test(value))) {
                return callback && callback('Please enter the valid value');
              }

              callback();
            },
          },
        ],
      },
      span: 12,
      component: <Input size="large" addonBefore="$" />,
    },
    {
      name: 'productFeeDescription',
      label: 'Product fee Description',
      formItemProps: {
        initialValue: 'Product Fee',
      },
      span: 12,
      component: <TextArea rows={2} />,
    },
    {
      name: 'serviceFeeDescription',
      label: 'Service fee Description',
      formItemProps: {
        initialValue: 'Service Fee',
      },
      span: 12,
      component: <TextArea rows={2} />,
    },
    {
      name: 'serviceStartDt',
      label: 'Period start',
      formItemProps: {},
      span: 12,
      component: <DatePicker size="large" format={dateTimePattern.date} />,
    },
    {
      name: 'serviceEndDt',
      label: 'Period end',
      formItemProps: {},
      span: 12,
      component: <DatePicker size="large" format={dateTimePattern.date} />,
    },
    {
      name: 'memo',
      label: 'Memo',
      formItemProps: {},
      component: <TextArea rows={4} />,
    },
  ];

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const {
            productFee,
            serviceFee,
            paymentMethod,
            productFeeDescription,
            serviceFeeDescription,
            memo,
            paymentType,
            subscriptionId,
            serviceStartDt,
            serviceEndDt,
          } = values;

          createPaymentRequest({
            appId: crmApplication.appId,
            organizationId: null,
            productFee: parseFloat(productFee),
            serviceFee: parseFloat(serviceFee),
            paymentMethod,
            productFeeDescription,
            serviceFeeDescription,
            memo,
            paymentType,
            subscriptionId: parseInt(subscriptionId),
            serviceStartDt: moment(serviceStartDt).format('YYYY-MM-DD'),
            serviceEndDt: moment(serviceEndDt).format('YYYY-MM-DD'),
          })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create an onetime payment"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields as any} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
