import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const TableStatusRow = ({text}) => {
  return (
    <TableRow>
      <TableCell colSpan="100">{text}</TableCell>
    </TableRow>
  )
}

export default TableStatusRow
