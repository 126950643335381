import * as React from 'react';
import { useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { updateApplicationPlanRequest } from '../api/application';

type Props = {
  appId: CrmApplication['appId'];
  plan: CrmApplication['plan'];
  successCallback: PrivateRouteProps['getCrmApplicationRequest'];
};

export const SetApplicationPlanTypeDialog: React.FC<Props> = ({ appId, plan, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const isEnterprise = plan === 'custom' || plan === 'enterprise';

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    const nextPlan = isEnterprise ? 'free' : 'custom';

    setLoading(true);

    updateApplicationPlanRequest({ appId, plan: nextPlan })
      .then((response) => {
        console.log('response', response);
        successCallback && successCallback();
        handleCancel();
      })
      .catch((error) => {
        console.error('error', error);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        type={isEnterprise ? 'dashed' : 'primary'}
        icon={<SwapOutlined />}
        size="small"
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          verticalAlign: 'middle',
          background: isEnterprise ? '' : '#825EEB',
          borderColor: isEnterprise ? '' : '#825EEB',
        }}
      >
        {isEnterprise ? 'Downgrade' : 'Upgrade'}
      </Button>
      <Modal
        open={visible}
        title="Change application plan"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Update
          </Button>,
        ]}
      >
        Are you sure to change the plan to <b>{isEnterprise ? 'Free Plan' : 'Enterprise Plan'}</b>?
      </Modal>
    </>
  );
};
