import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, DatePicker, Row, Typography, Select } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getAwsUsageWithCostsRequest, getAwsUsageWithCostsFilterRequest } from '../../api/awsUsageWithCost';
import queryString from 'query-string';
import { TableFilter } from '../../components/ui/TableFilter';
import { dateTimePattern } from '../../utils/date';

import moment from 'moment-timezone';
import { ColumnProps } from 'antd/lib/table';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const alignRight = 'right' as ColumnProps<any>['align'];

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
  },
  {
    title: 'AWS Resion',
    dataIndex: 'awsRegion',
    key: 'awsRegion',
    render: (awsRegion: AwsUsageWithCost['awsRegion']) => (awsRegion ? awsRegion : '-'),
    width: 150,
  },
  {
    title: 'Sendbird Region',
    dataIndex: 'sendbirdRegion',
    key: 'sendbirdRegion',
    render: (sendbirdRegion: AwsUsageWithCost['sendbirdRegion']) => (sendbirdRegion ? sendbirdRegion : '-'),
    width: 150,
  },
  {
    title: 'Amortized Cost',
    dataIndex: 'amortizedCost',
    key: 'amortizedCost',
    render: (amortizedCost: AwsUsageWithCost['amortizedCost']) =>
      amortizedCost
        ? `$ ${amortizedCost.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}`
        : '-',
    width: 100,
    align: alignRight,
  },
  {
    title: 'Blended Cost',
    dataIndex: 'blendedCost',
    key: 'blendedCost',
    render: (blendedCost: AwsUsageWithCost['blendedCost']) =>
      blendedCost
        ? `$ ${blendedCost.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}`
        : '-',
    width: 100,
    align: alignRight,
  },
  {
    title: 'Unblended Cost',
    dataIndex: 'unblendedCost',
    key: 'unblendedCost',
    render: (unblendedCost: AwsUsageWithCost['unblendedCost']) =>
      unblendedCost
        ? `$ ${unblendedCost.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}`
        : '-',
    width: 100,
    align: alignRight,
  },
  {
    title: 'Net Amortized Cost',
    dataIndex: 'netAmortizedCost',
    key: 'netAmortizedCost',
    render: (netAmortizedCost: AwsUsageWithCost['netAmortizedCost']) =>
      netAmortizedCost
        ? `$ ${netAmortizedCost.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}`
        : '-',
    width: 150,
    align: alignRight,
  },
  {
    title: 'Net Unblended Cost',
    dataIndex: 'netUnblendedCost',
    key: 'netUnblendedCost',
    render: (netUnblendedCost: AwsUsageWithCost['netUnblendedCost']) =>
      netUnblendedCost
        ? `$ ${netUnblendedCost.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}`
        : '-',
    width: 150,
    align: alignRight,
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 150,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 150,
  },
];

type Props = RouteComponentProps;

export const AwsUsageWithCosts = withRouter<Props, any>(({ location, history }) => {
  const [awsUsageWithCost, setAwsUsageWithCost] = useState<Array<AwsUsageWithCost>>([]);
  const [loading, setLoading] = useState(false);
  const [awsUsageWithCostCount, setAwsUsageWithCostCount] = useState(0);

  const [awsRegions, setAwsRegions] = useState<any>([]);
  const [sendBirdRegions, setSendBirdRegions] = useState<any>([]);

  const qs = queryString.parse(location.search);
  let aws_region = qs.aws_region ? (qs.aws_region as string) : '';
  let sendbird_region = qs.sendbird_region ? (qs.sendbird_region as string) : '';
  let limit = parseInt(qs.limit ? (qs.limit as string) : '30');
  let offset = parseInt(qs.offset ? (qs.offset as string) : '0');
  let start_date = qs.start_date
    ? (qs.start_date as string)
    : moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD');
  let end_date = qs.end_date ? (qs.end_date as string) : moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD');

  useEffect(() => {
    getAwsUsageWithCosts();
  }, [location]);

  useEffect(() => {
    getAWsUsageWithCostsFilter();
  }, []);

  const getAwsUsageWithCosts = () => {
    setLoading(true);
    getAwsUsageWithCostsRequest({
      offset,
      limit,
      aws_region,
      sendbird_region,
      start_date,
      end_date,
    })
      .then((response) => {
        setAwsUsageWithCost(response.data.results);
        setLoading(false);
        setAwsUsageWithCostCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAWsUsageWithCostsFilter = () => {
    getAwsUsageWithCostsFilterRequest()
      .then((response) => {
        setAwsRegions(['All'].concat(response.data['awsRegions']));
        setSendBirdRegions(['All'].concat(response.data['sendbirdRegions']));
      })
      .catch((error) => {});
  };

  const onPageChange = (page, pageSize) => {
    limit = pageSize;
    offset = (page - 1) * pageSize;
    getAwsUsageWithCosts();
  };

  const onAwsRegionSelect = (value) => {
    if (value === 'All') value = '';
    aws_region = value;
    offset = 0;
    replacePath();
  };

  const onSendBirdRegionSelect = (value) => {
    if (value === 'All') value = '';
    sendbird_region = value;
    offset = 0;
    replacePath();
  };

  const onRangeChange = (value) => {
    start_date = value[0].format('YYYY-MM-DD');
    end_date = value[1].format('YYYY-MM-DD');
    replacePath();
  };

  function replacePath() {
    history.replace({
      pathname: '/aws_usage_with_cost/',
      search:
        `?limit=${limit}&offset=${offset}&aws_region=${aws_region}&sendbird_region=${sendbird_region}` +
        `&start_date=${start_date}&end_date=${end_date}`,
    });
  }

  return (
    <React.Fragment>
      <Row gutter={16} style={{ marginBottom: '8px' }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
          <Title level={3}>AWS Usage with Cost</Title>
          <Paragraph>
            <ul>
              <li>Data source: Insight</li>
              <li>Data for current month is not available.</li>
            </ul>
          </Paragraph>
        </Col>
      </Row>

      <TableFilter
        selects={[
          {
            label: 'Range',
            key: 'range',
            node: (
              <RangePicker
                defaultValue={[moment(start_date), moment(end_date)]}
                allowClear={false}
                format={dateTimePattern.date}
                onChange={onRangeChange}
              />
            ),
          },
          {
            label: 'AWS Region',
            key: 'aws-region',
            node: (
              <Select
                defaultValue={'All'}
                onSelect={onAwsRegionSelect}
                placeholder="AWS Region"
                style={{ width: '100%' }}
              >
                {awsRegions.map((region) => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            label: 'Sendbird Region',
            key: 'sendbird-region',
            node: (
              <Select
                defaultValue={'All'}
                onSelect={onSendBirdRegionSelect}
                placeholder="Sendbird Region"
                style={{ width: '100%' }}
              >
                {sendBirdRegions.map((region) => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>
            ),
          },
        ]}
      />

      <ResponsiveTable
        scroll={{ x: 1770 }}
        loading={loading}
        columns={columns}
        dataSource={awsUsageWithCost}
        pagination={{
          total: awsUsageWithCostCount,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
        }}
      />
    </React.Fragment>
  );
});
