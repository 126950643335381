import React, { useState, useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Switch, DatePicker, Select } from 'antd';
import { updateSubscriptionRequest } from '../api/subscription';
import { DynamicForm } from './ui/DynamicForm';

import moment from 'moment-timezone';
import { dateTimePattern, getBrowserTimeZoneString } from '../utils/date';
import {
  _subscriptionPaymentMethodOptions,
  billingCycleOptions,
  paymentMethodOptions,
} from '../utils/constants/billing';
import { hasPermission } from '../utils/permission';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input;
const { Option } = Select;

function useValidation() {
  const [autoRenewalPeriodDisabled, setAutoRenewalPeriodDisabled] = useState(false);
  return {
    autoRenewalPeriodDisabled,
    setAutoRenewalPeriodDisabled,
  };
}

type Props = {
  crmSubscription: CrmSubscription;
  successCallback: (payload?: any) => void;
};

export const SubscriptionUpdateDialog: React.FC<Props> = ({ crmSubscription, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const valid = useValidation();

  const getFields: any = ({ crmSubscription, valid }) => {
    const privilegedFields = [
      {
        name: 'subscriptionStartDt',
        label: 'Period start',
        formItemProps: {
          initialValue: moment.tz(crmSubscription.subscriptionStartDt, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Please enter the valid period start date',
            },
            {
              validator: (_, value, callback) => {
                const subscriptionPeriodDurationMonths = formRef.current?.getFieldValue(
                  'subscriptionPeriodDurationMonths',
                );
                if (value && subscriptionPeriodDurationMonths) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(value)
                      .add(subscriptionPeriodDurationMonths, 'months')
                      .subtract(1, 'days'),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <DatePicker size="large" format={dateTimePattern.date} />,
      },
      {
        name: 'subscriptionPeriodDurationMonths',
        label: 'Period Duration (Months)',
        formItemProps: {
          initialValue: 12,
          rules: [
            {
              required: true,
              message: 'Please enter the valid period duration',
            },
            {
              validator: (_, value, callback) => {
                const start = formRef.current?.getFieldValue('subscriptionStartDt');
                if (value && start) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(start).add(value, 'months').subtract(1, 'days'),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonAfter="Months" disabled={false} />,
      },
      {
        name: 'subscriptionEndDt',
        label: 'Period End',
        span: 24,
        formItemProps: {
          initialValue: moment.tz(crmSubscription.subscriptionEndDt, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Please enter the valid period duration',
            },
            {
              validator: (_, value, callback) => {
                formRef.current?.setFieldsValue({
                  subscriptionEndDt: value,
                });
                callback();
              },
            },
          ],
        },
        component: <DatePicker size="large" format={dateTimePattern.date} disabled={true} />,
      },
      {
        name: 'billingCycle',
        label: 'Billing Cycle',
        formItemProps: {
          initialValue: crmSubscription.billingCycle ? crmSubscription.billingCycle : '',
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {billingCycleOptions.map(({ value, label }) => (
              <Option key={`subscription-billing-cycle-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'paymentMethod',
        label: 'Payment method',
        formItemProps: {
          initialValue: _subscriptionPaymentMethodOptions[crmSubscription.paymentMethod],
          rules: [
            {
              required: true,
              message: 'Please enter the valid payment method',
            },
          ],
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {paymentMethodOptions.map(({ value, label }) => (
              <Option key={`subscription-payment-method-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'productFee',
        label: 'Product fee($)',
        formItemProps: {
          initialValue: crmSubscription.productFee,
          rules: [
            {
              required: true,
              message: 'Please enter the valid product fee',
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'serviceFee',
        label: 'Service fee($)',
        formItemProps: {
          initialValue: crmSubscription.serviceFee,
          rules: [
            {
              required: true,
              message: 'Please enter the valid product fee',
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'productFeeDescription',
        label: 'Product fee description',
        formItemProps: { initialValue: crmSubscription.productFeeDescription },
        span: 12,
        component: <Input size="large" />,
      },
      {
        name: 'serviceFeeDescription',
        label: 'Service fee description',
        formItemProps: { initialValue: crmSubscription.serviceFeeDescription },
        span: 12,
        component: <Input size="large" />,
      },
    ];
    const fields = [
      {
        name: 'autoRenewal',
        label: 'Auto Renewal',
        formItemProps: {
          valuePropName: 'checked',
          initialValue: crmSubscription ? crmSubscription.autoRenewal : false,
          rules: [
            {
              validator: (_, value, callback) => {
                if (value) {
                  valid.setAutoRenewalPeriodDisabled(false);
                } else {
                  valid.setAutoRenewalPeriodDisabled(true);
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Switch disabled={!crmSubscription.isActive} />,
      },
      {
        name: 'autoRenewalPeriod',
        label: 'Auto Renewal Period (Months)',
        formItemProps: {
          initialValue: crmSubscription ? crmSubscription.autoRenewalPeriod : 3,
        },
        span: 12,
        component: (
          <Input
            size="large"
            addonAfter="Months"
            disabled={valid.autoRenewalPeriodDisabled || !crmSubscription.isActive}
          />
        ),
      },
      {
        name: 'memo',
        label: 'Memo',
        formItemProps: { initialValue: crmSubscription.memo },
        component: <TextArea rows={4} />,
      },
    ];

    if (hasPermission('MANAGE_SUBSCRIPTION')) {
      return privilegedFields.concat(fields as any);
    }
    return fields;
  };

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);

    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const {
            subscriptionStartDt,
            subscriptionPeriodDurationMonths,
            subscriptionEndDt,
            billingCycle,
            paymentMethod,
            productFee,
            serviceFee,
            productFeeDescription,
            serviceFeeDescription,
            memo,
            autoRenewal,
            autoRenewalPeriod,
          } = values;
          updateSubscriptionRequest({
            subscriptionId: crmSubscription.id,
            subscriptionStartDt: subscriptionStartDt.format('YYYY-MM-DD'),
            subscriptionPeriodDurationMonths,
            subscriptionEndDt: subscriptionEndDt.format('YYYY-MM-DD'),
            billingCycle,
            paymentMethod,
            productFee,
            serviceFee,
            productFeeDescription,
            serviceFeeDescription,
            memo,
            autoRenewal,
            autoRenewalPeriod,
          })
            .then((_) => {
              setLoading(false);
              setVisible(false);
              successCallback && successCallback();
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields({ crmSubscription, valid });

  return (
    <React.Fragment>
      <Button type="primary" icon={<EditOutlined />} onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Update
      </Button>
      <Modal
        open={visible}
        title={`Update Subscription #${crmSubscription.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Update
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
