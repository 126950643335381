import * as React from 'react';
import { useState, useRef } from 'react';
import moment from 'moment-timezone';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, DatePicker } from 'antd';

import { DynamicForm } from './ui/DynamicForm';

import { createUserRequest } from '../api/user';
import { userStateOptions, UserStateEnum, userDepartmentOptions, userTeamTypeOptions } from '../utils/constants/users';
import { dateTimePattern, getBrowserTimeZoneString, getBrowserTodayMoment } from '../utils/date';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;

const today = getBrowserTodayMoment();

const fields: any = [
  //Array<Field>
  {
    name: 'email',
    label: 'Email',
    formItemProps: {
      rules: [
        { type: 'email', message: 'The email you entered is invalid' },
        { required: true, message: 'Please enter the email' },
      ],
    },
    component: <Input size="large" />,
  },
  {
    name: 'firstName',
    label: 'First name',
    formItemProps: {
      rules: [{ required: true, message: 'Please enter the first name' }],
    },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'lastName',
    label: 'Last name',
    formItemProps: {
      rules: [{ required: true, message: 'Please enter the last name' }],
    },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'eid',
    label: 'EID',
    formItemProps: {
      rules: [{ required: true, message: 'Please enter the eid' }],
    },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'location',
    label: 'Location',
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please enter the location code. (cf. US, UK, KR, SG, IN ...)',
        },
        { len: 2 },
      ],
    },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'teamType',
    label: 'Team Type',
    formItemProps: {
      initialValue: '-',
      rules: [{ required: true, message: 'Please select a team type' }],
    },
    component: (
      <Select size="large">
        {userTeamTypeOptions.map(({ value, label }) => (
          <Option key={`teamType-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'department',
    label: 'Department',
    formItemProps: {
      initialValue: '-',
      rules: [{ required: true, message: 'Please select a department' }],
    },
    component: (
      <Select size="large">
        {userDepartmentOptions.map(({ value, label }) => (
          <Option key={`department-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'companyJoinedDate',
    label: 'Joined Date',
    formItemProps: {
      initialValue: moment.tz(today, getBrowserTimeZoneString()),
      rules: [
        {
          required: true,
          message: 'Please enter the valid period start date',
        },
        {
          validator: (_, value, callback) => {
            callback();
          },
        },
      ],
    },
    span: 12,
    component: <DatePicker size="large" />,
  },
  {
    name: 'state',
    label: 'State',
    formItemProps: {
      initialValue: UserStateEnum.ACTIVE,
      rules: [{ required: true, message: 'Please select the user state' }],
    },
    span: 12,
    component: (
      <Select size="large">
        {userStateOptions.map(({ value, label }) => (
          <Option key={`state-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
];

type Props = {
  successCallback: (payload?: any) => void;
};

export const UserCreateDialog: React.FC<Props> = ({ successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    formRef.current?.resetFields();
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          values['companyJoinedDate'] = values['companyJoinedDate'].format(dateTimePattern.date);

          createUserRequest(values as any)
            .then((response) => {
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create a member"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
