import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getStatusPageIncidentsRequest({ offset, limit, q, region, status, impact }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/status_page_incidents/`,
    {
      params: {
        offset,
        limit,
        q,
        region,
        status,
        impact
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getStatusPageIncidentsFiltersRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/status_page_incidents/filters/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getStatusPageIncidentRequest({ incidentId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/status_page_incidents/${incidentId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
