import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Tag, Select, Layout, DatePicker, Checkbox } from 'antd';
import queryString from 'query-string';
import moment from 'moment-timezone';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { TableFilter } from '../../components/ui/TableFilter';

import { getOrganizationSubscriptionsRequest } from '../../api/billing';
import { PageTitle } from '../../components/ui/PageTitle';
import { SetOrganizationSubscriptionEndDateDialog } from '../../components/organizationBilling/SetOrganizationSuscriptionEndDateDialog';
import { getSubscriptionNameListRequest } from '../../api/billingMetrics';
import { dateTimePattern } from '../../utils/date';

const { Option } = Select;
const { RangePicker } = DatePicker;

const productItems = [
  { key: 'All', value: '' },
  { key: 'Chat', value: 'CHAT' },
  { key: 'Support', value: 'SUPPORT' },
];

const statusItems = [
  {
    key: 'All',
    value: '',
  },
  {
    key: 'True',
    value: 'active',
  },
  {
    key: 'False',
    value: 'inactive',
  },
];

type Props = RouteComponentProps;

export const OrganizationSubscriptions = withRouter<Props, any>(({ location, history }) => {
  const [organizationSubscriptions, setOrganizationSubscriptions] = useState([] as Array<OrganizationSubscription>);
  const [loadingOrganizationSubscriptions, setLoadingOrganizationSubscriptions] = useState(false);
  const [organizationSubscriptionsCount, setOrganizationSubscriptionsCount] = useState(0);

  const [subscriptionNameList, setSubscriptionNameList] = useState([] as Array<BillingMetricSubscriptionNameList>);

  const isValidMomentValue = (value) => moment(value).format(dateTimePattern.date) !== 'Invalid date';

  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 30,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    product: qs.product ? (qs.product as string) : '',
    displayName: qs.displayName ? (qs.displayName as string) : '',
    current: qs.current ? (qs.current as string) : '',
    subscriptionName: qs.subscriptionName ? (qs.subscriptionName as string) : '',
    subscriptionType: qs.subscriptionType ? (qs.subscriptionType as string) : '',
    createdAtGte: qs.createdAtGte && isValidMomentValue(qs.createdAtGte) ? (qs.createdAtGte as string) : '',
    createdAtLte: qs.createdAtLte && isValidMomentValue(qs.createdAtLte) ? (qs.createdAtLte as string) : '',
    is_test_organization: qs.is_test_organization ? (qs.is_test_organization as string) : 'false',
  });

  const getOrganizationSubscriptions = () => {
    setLoadingOrganizationSubscriptions(true);
    getOrganizationSubscriptionsRequest({ ...query, current: query.current ? query.current === 'active' : '' })
      .then((response) => {
        setOrganizationSubscriptions(response.data.results);
        setOrganizationSubscriptionsCount(response.data.count);
        setLoadingOrganizationSubscriptions(false);
      })
      .catch((error) => {
        setLoadingOrganizationSubscriptions(false);
        console.error(error);
      });
  };

  const getSubscriptionNameList = () => {
    getSubscriptionNameListRequest()
      .then((response) => {
        setSubscriptionNameList(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    history.replace({
      pathname: '/organization_subscriptions/',
      search: queryString.stringify(query),
    });
    getOrganizationSubscriptions();
    getSubscriptionNameList();
  }, [query]);

  const organizationSubscriptionsColumns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string, organizationSubscription: OrganizationSubscription) =>
        id ? <Link to={`/organization_subscription/${organizationSubscription.uid}/`}>{id}</Link> : '-',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization: any) =>
        organization ? <Link to={`/crm_organizations/${organization.uid}/`}>{organization.name}</Link> : '-',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Subscription name',
      dataIndex: 'subscriptionName',
      render: (subscriptionName: OrganizationSubscription['subscriptionName']) => {
        const item = subscriptionNameList.find((s) => s.subscriptionName === subscriptionName);
        return (item && item.displayName) || subscriptionName;
      },
    },
    {
      title: 'Total',
      dataIndex: 'planValue',
      key: 'planValue',
      render: (planValue) => <Tag>{`$${Math.floor(planValue) / 100}`}</Tag>,
    },
    {
      title: 'Billing cycle',
      dataIndex: 'billingCycleMonths',
      key: 'billingCycleMonths',
      render: (billingCycleMonths) => `${billingCycleMonths} month(s)`,
    },
    {
      title: 'Subscription Type',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      render: (current: OrganizationSubscription['current']) => (
        <Tag color={current ? 'green' : 'red'} style={{ margin: 0 }}>
          {current ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      title: 'Applied',
      dataIndex: 'applied',
      key: 'applied',
      render: (applied: OrganizationSubscription['applied']) => (
        <Tag color={applied ? 'green' : 'red'} style={{ margin: 0 }}>
          {applied ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      title: (
        <span>
          Period <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: string, organizationSubscription: OrganizationSubscription) => (
        <>
          {startDate} ~ {organizationSubscription.endDate || ''}
          {organizationSubscription.current &&
          organizationSubscription.subscriptionName === 'free_trial' &&
          organizationSubscription.product === 'CHAT' &&
          organizationSubscription.uid ? (
            <SetOrganizationSubscriptionEndDateDialog
              organizationSubscription={organizationSubscription}
              successCallback={getOrganizationSubscriptions}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => <>{moment(createdAt).utc().format(dateTimePattern.default)}</>,
    },
  ];

  const onFilterSelect = (key) => (value) => {
    if (key === 'subscriptionName') {
      value = value.join(',');
    }
    setQuery({ ...query, limit: 30, offset: 0, [key]: value });
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  const onChangeCreatedAt = (data) => {
    setQuery({
      ...query,
      createdAtGte:
        moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '',
      createdAtLte:
        moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
          ? data[1].format(`${dateTimePattern.date}`)
          : '',
    });
  };

  const onTestOrganizationClick = (event) => {
    if (event.target.checked) {
      setQuery({...query, limit:30, offset:0, is_test_organization: 'true'})
    } else {
      setQuery({...query, limit:30, offset:0, is_test_organization: 'false'})
    }
  }

  let tableFilter = [
    {
      label: 'Product',
      key: 'product',
      node: (
        <Select value={query.product} onSelect={onFilterSelect('product')} style={{ width: '100%' }}>
          {productItems.map((names) => (
            <Option key={names.key} value={names.value}>
              {names.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Subscription Name',
      key: 'subscriptionName',
      node: (
        <Select mode="multiple" placeholder="Please select" defaultValue={query.subscriptionName} onChange={onFilterSelect('subscriptionName')}  style={{ width: '100%' }}>
          <Option key="Total" value="">
            Total
          </Option>
          {subscriptionNameList.map((item) => (
            <Option key={item.subscriptionName} value={item.subscriptionName}>
              {item.displayName}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Subscription Type',
      key: 'subscriptionType',
      node: (
        <Select value={query.subscriptionType} onSelect={onFilterSelect('subscriptionType')} style={{ width: '100%' }}>
          <Option key="All" value="">
            All
          </Option>
          <Option key="SELF_SERVE" value="SELF_SERVE">
            SELF_SERVE
          </Option>
          <Option key="SALES_CUSTOM" value="SALES_CUSTOM">
            SALES_CUSTOM
          </Option>
        </Select>
      ),
    },
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          defaultValue={
            isValidMomentValue(query.createdAtGte) && isValidMomentValue(query.createdAtLte)
              ? [moment(query.createdAtGte), moment(query.createdAtLte)]
              : null
          }
          onChange={onChangeCreatedAt}
          format={dateTimePattern.date}
        />
      ),
    },
    {
      label: 'Current',
      key: 'current',
      node: (
        <Select value={query.current} onSelect={onFilterSelect('current')} style={{ width: '100%' }}>
          {statusItems.map((names) => (
            <Option key={names.key} value={names.value}>
              {names.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Test Organization',
      key: 'is_test_organization',
      node: (
        <Checkbox
          checked={query.is_test_organization==='true'} value="true" onClick={onTestOrganizationClick} style={{ width: '100%' }}
        >
          Show Test Organization
        </Checkbox>
      ),
    },
  ];

  return (
    <Layout>
      <PageTitle title="Subscriptions" />
      <Layout.Content>
        <TableFilter selects={tableFilter} />
        <ResponsiveTable
          loading={loadingOrganizationSubscriptions}
          columns={organizationSubscriptionsColumns}
          dataSource={organizationSubscriptions}
          pagination={{
            total: organizationSubscriptionsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
