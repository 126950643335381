import * as React from 'react';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { useCheckMobile } from '../../utils/screen';
import { dateTimePattern } from '../../utils/date';
import {
  getSubscriptionNameListRequest,
  getNewInvoiceCount,
  getNewOrganizationCount,
  getNewSubscriptionCount,
  getTotalSubscriptionCountListBySubscriptionName,
} from '../../api/billingMetrics';
import { Link } from 'react-router-dom';
const { Title } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function convertDataSourceBillingMetrics({ dataSources }) {
  const source = {};
  dataSources.forEach((data, index) => {
    source[`count-${index}`] = data['value'];
    source['title'] = 'count';
  });

  return [source];
}

export const BillingMetricsSelfService = React.memo(() => {
  const isMobile = useCheckMobile();

  const [
    dataSourceTotalActiveSubscriptionExceptSupportCountList,
    setDataSourceTotalActiveSubscriptionExceptSupportCountList,
  ] = useState<Array<BillingMetric>>([]);
  const [dataSourceNewOrganizationCountDaily, setDataSourceNewOrganizationCountDaily] = useState<Array<BillingMetric>>(
    [],
  );
  const [dataSourceNewOrganizationCountMonthly, setDataSourceNewOrganizationCountMonthly] = useState<
    Array<BillingMetric>
  >([]);
  const [dataSourceNewSubscriptionCountDaily, setDataSourceNewSubscriptionCountDaily] = useState<Array<BillingMetric>>(
    [],
  );
  const [dataSourceNewSubscriptionCountMonthly, setDataSourceNewSubscriptionCountMonthly] = useState<
    Array<BillingMetric>
  >([]);
  const [dataSourceNewInvoiceCountDaily, setDataSourceNewInvoiceCountDaily] = useState<Array<BillingMetric>>([]);
  const [dataSourceNewInvoiceCountMonthly, setDataSourceNewInvoiceCountMonthly] = useState<Array<BillingMetric>>([]);
  const [
    totalActiveSubscriptionExceptSupportCountListLoading,
    setTotalActiveSubscriptionExceptSupportCountListLoading,
  ] = useState<boolean>(false);
  const [newOrganizationCountDailyLoading, setNewOrganizationCountDailyLoading] = useState<boolean>(false);
  const [newOrganizationCountMonthlyLoading, setNewOrganizationCountMonthlyLoading] = useState<boolean>(false);

  const [newSubscriptionCountDailyLoading, setNewSubscriptionCountDailyLoading] = useState<boolean>(false);
  const [newSubscriptionCountMonthlyLoading, setNewSubscriptionCountMonthlyLoading] = useState<boolean>(false);

  const [newInvoiceCountDailyLoading, setNewInvoiceCountDailyLoading] = useState<boolean>(false);
  const [newInvoiceCountMonthlyLoading, setNewInvoiceCountMonthlyLoading] = useState<boolean>(false);

  const [subscriptionNameList, setSubscriptionNameList] = useState([] as Array<BillingMetricSubscriptionNameList>);

  const createColumnsBillingMetricsSubscriptionsByQueryKey = ({ dataSources }) => {
    let columns: Array<ColumnProps<any>> = [
      {
        title: '#',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    dataSources.forEach((data, columnIndex) => {
      columns.push({
        title: data.name,
        dataIndex: `count-${columnIndex}`,
        key: `users-${columnIndex}`,
        align: alignRight,
        render: (count: number) => {
          // TODO: query param camel case?
          let queryParamSubscriptionName = '';

          if (data.queryName) {
            queryParamSubscriptionName = `&subscriptionName=${data.queryName}`;
          } else if (subscriptionNameList) {
            queryParamSubscriptionName = `&subscriptionName=${subscriptionNameList
              .filter(
                (x) =>
                  (!x.subscriptionName.includes(',') && x.subscriptionName.startsWith('plan')) ||
                  x.subscriptionName === 'free_trial' || x.subscriptionName === 'developer',
              )
              .map((x) => x.subscriptionName)
              .join(',')}`;
          }

          return (
            <Link
              to={`/organization_subscriptions/?displayName=&limit=30&offset=0&current=active${queryParamSubscriptionName}`}
            >
              {count && count.toLocaleString()}
            </Link>
          );
        },
      });
    });

    return columns;
  };

  const createColumnsBillingMetricsSubscriptionsByDate = ({ dataSources, dateUnit }) => {
    let columns: Array<ColumnProps<any>> = [
      {
        title: '#',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    dataSources.forEach((data, columnIndex) => {
      columns.push({
        title: data.name,
        dataIndex: `count-${columnIndex}`,
        key: `users-${columnIndex}`,
        align: alignRight,
        render: (count: number) => {
          let queryParamCreatedAt = '';
          if (dateUnit === 'day') {
            queryParamCreatedAt = data.name ? `createdAtGte=${data.name}&createdAtLte=${data.name}` : '';
          } else if (dateUnit === 'month') {
            queryParamCreatedAt = data.name
              ? `createdAtGte=${data.name}-01&createdAtLte=${moment(data.name)
                  .add(1, 'months')
                  .subtract(1, 'days')
                  .format(dateTimePattern.date)}`
              : '';
          }
          return data.name ? (
            <Link to={`/organization_subscriptions/?displayName=&limit=30&offset=0&current=&${queryParamCreatedAt}`}>
              {count && count.toLocaleString()}
            </Link>
          ) : (
            count && count.toLocaleString()
          );
        },
      });
    });

    return columns;
  };

  const createColumnsBillingMetricsOrganizations = ({ dataSources, dateUnit }) => {
    let columns: Array<ColumnProps<any>> = [
      {
        title: '#',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    dataSources.forEach((data, columnIndex) => {
      columns.push({
        title: data.name,
        dataIndex: `count-${columnIndex}`,
        key: `users-${columnIndex}`,
        align: alignRight,
        render: (count: number) => {
          let queryParamCreatedAt = '';
          if (dateUnit === 'day') {
            queryParamCreatedAt = data.name ? `createdAtGte=${data.name}&createdAtLte=${data.name}` : '';
          } else if (dateUnit === 'month') {
            queryParamCreatedAt = data.name
              ? `createdAtGte=${data.name}-01&createdAtLte=${moment(data.name)
                  .add(1, 'months')
                  .subtract(1, 'days')
                  .format(dateTimePattern.date)}`
              : '';
          }

          return (
            <Link to={`/crm_organizations/?limit=30&offset=0&type=self-service&${queryParamCreatedAt}`}>
              {count && count.toLocaleString()}
            </Link>
          );
        },
      });
    });

    return columns;
  };

  const createColumnsBillingMetricsInvoicesByDate = ({ dataSources, dateUnit }) => {
    let columns: Array<ColumnProps<any>> = [
      {
        title: '#',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    dataSources.forEach((data, columnIndex) => {
      columns.push({
        title: data.name,
        dataIndex: `count-${columnIndex}`,
        key: `users-${columnIndex}`,
        align: alignRight,
        render: (count: number) => {
          // let queryParamCreatedAt = '';
          // if (dateUnit === 'day') {
          //   queryParamCreatedAt = data.name ? `createdAtGte=${data.name}&createdAtLte=${data.name}` : '';
          // } else if (dateUnit === 'month') {
          //   queryParamCreatedAt = data.name
          //     ? `createdAtGte=${data.name}-01&createdAtLte=${moment(data.name)
          //         .add(1, 'months')
          //         .subtract(1, 'days')
          //         .format(dateTimePattern.date)}`
          //     : '';
          // }
          return count && count.toLocaleString();
          // return data.name ? (
          //   <Link
          //     to={`/organization_invoices/?limit=30&offset=0&${queryParamCreatedAt }`}
          //   >
          //     {count && count.toLocaleString()}
          //   </Link>
          // ) : (
          //   count && count.toLocaleString()
          // );
        },
      });
    });

    return columns;
  };

  const getNewInvoiceCountDailyFunction = () => {
    setNewInvoiceCountDailyLoading(true);
    getNewInvoiceCount({
      date_unit: 'day',
      range_start_date: moment().subtract(13, 'days').format(dateTimePattern.date),
      range_end_date: moment().format(dateTimePattern.date),
    })
      .then((response) => {
        console.log('getgetNewInvoiceCountDailyFunction: ', response.data);
        setDataSourceNewInvoiceCountDaily(response.data.results);
        setNewInvoiceCountDailyLoading(false);
      })
      .catch((error) => {
        setNewInvoiceCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewInvoiceCountMonthlyFunction = () => {
    setNewInvoiceCountMonthlyLoading(true);
    getNewInvoiceCount({
      date_unit: 'month',
      range_start_date: '2020-04', // TODO: Change it if fixed range is needed
      range_end_date: moment().format(dateTimePattern.month),
    })
      .then((response) => {
        console.log('getgetNewInvoiceCountMonthlyFunction: ', response.data);
        setDataSourceNewInvoiceCountMonthly(response.data.results);
        setNewInvoiceCountMonthlyLoading(false);
      })
      .catch((error) => {
        setNewInvoiceCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewSubscriptionCountDailyFunction = () => {
    setNewSubscriptionCountDailyLoading(true);
    getNewSubscriptionCount({
      date_unit: 'day',
      range_start_date: moment().subtract(13, 'days').format(dateTimePattern.date),
      range_end_date: moment().format(dateTimePattern.date),
    })
      .then((response) => {
        console.log('getgetNewSubscriptionCountDailyFunction: ', response.data);
        setDataSourceNewSubscriptionCountDaily(response.data.results);
        setNewSubscriptionCountDailyLoading(false);
      })
      .catch((error) => {
        setNewSubscriptionCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewSubscriptionCountMonthlyFunction = () => {
    setNewSubscriptionCountMonthlyLoading(true);
    getNewSubscriptionCount({
      date_unit: 'month',
      range_start_date: '2020-04', // TODO: Change it if fixed range is needed
      range_end_date: moment().format(dateTimePattern.month),
    })
      .then((response) => {
        console.log('getgetNewSubscriptionCountMonthlyFunction: ', response.data);
        setDataSourceNewSubscriptionCountMonthly(response.data.results);
        setNewSubscriptionCountMonthlyLoading(false);
      })
      .catch((error) => {
        setNewSubscriptionCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewOrganizationCountDailyFunction = () => {
    setNewOrganizationCountDailyLoading(true);
    getNewOrganizationCount({
      date_unit: 'day',
      is_self_serve: true,
      range_start_date: moment().subtract(13, 'days').format(dateTimePattern.date),
      range_end_date: moment().format(dateTimePattern.date),
    })
      .then((response) => {
        console.log('getgetNewOrganizationCountDailyFunction: ', response.data);
        setDataSourceNewOrganizationCountDaily(response.data.results);
        setNewOrganizationCountDailyLoading(false);
      })
      .catch((error) => {
        setNewOrganizationCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewOrganizationCountMonthlyFunction = () => {
    setNewOrganizationCountMonthlyLoading(true);
    getNewOrganizationCount({
      date_unit: 'month',
      is_self_serve: true,
      range_start_date: '2020-04', // TODO: Change it if fixed range is needed
      range_end_date: moment().format(dateTimePattern.month),
    })
      .then((response) => {
        console.log('getNewOrganizationCountMonthlyFunction: ', response.data);
        setDataSourceNewOrganizationCountMonthly(response.data.results);
        setNewOrganizationCountMonthlyLoading(false);
      })
      .catch((error) => {
        setNewOrganizationCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  const getTotalSubscriptionCountListExceptForSupportBySubscriptionNameFunction = (_subscriptionNameList) => {
    setTotalActiveSubscriptionExceptSupportCountListLoading(true);
    let subscriptionNameIn = _subscriptionNameList
      .filter(
        (x) =>
          (!x.subscriptionName.includes(',') && x.subscriptionName.startsWith('plan')) ||
          x.subscriptionName === 'free_trial' || x.subscriptionName === 'developer',
      )
      .map((x) => x.subscriptionName);

    getTotalSubscriptionCountListBySubscriptionName({ current: true, subscription_name__in: subscriptionNameIn })
      .then((response) => {
        console.log('getTotalSubscriptionCountListExceptForSupportBySubscriptionNameFunction: ', response.data);
        setDataSourceTotalActiveSubscriptionExceptSupportCountList(response.data.results);
        setTotalActiveSubscriptionExceptSupportCountListLoading(false);
      })
      .catch((error) => {
        setTotalActiveSubscriptionExceptSupportCountListLoading(false);
        error && console.error(error.response);
      });
  };

  const getSubscriptionNameList = (cb) => {
    getSubscriptionNameListRequest()
      .then((response) => {
        setSubscriptionNameList(response.data.results);
        cb && cb(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(function () {
    getSubscriptionNameList(getTotalSubscriptionCountListExceptForSupportBySubscriptionNameFunction);
    getNewOrganizationCountDailyFunction();
    getNewOrganizationCountMonthlyFunction();
    getNewSubscriptionCountDailyFunction();
    getNewSubscriptionCountMonthlyFunction();
    getNewInvoiceCountDailyFunction();
    getNewInvoiceCountMonthlyFunction();
  }, []);

  const totalActiveSubscriptionExceptSupportCountListColumns = createColumnsBillingMetricsSubscriptionsByQueryKey({
    dataSources: dataSourceTotalActiveSubscriptionExceptSupportCountList,
  });

  const newOrganizationCountDailyColumns = createColumnsBillingMetricsOrganizations({
    dataSources: dataSourceNewOrganizationCountDaily,
    dateUnit: 'day',
  });

  const newOrganizationCountMonthlyColumns = createColumnsBillingMetricsOrganizations({
    dataSources: dataSourceNewOrganizationCountMonthly,
    dateUnit: 'month',
  });

  const newSubscriptionCountDailyColumns = createColumnsBillingMetricsSubscriptionsByDate({
    dataSources: dataSourceNewSubscriptionCountDaily,
    dateUnit: 'day',
  });

  const newSubscriptionCountMonthlyColumns = createColumnsBillingMetricsSubscriptionsByDate({
    dataSources: dataSourceNewSubscriptionCountMonthly,
    dateUnit: 'month',
  });

  const newInvoiceCountDailyColumns = createColumnsBillingMetricsInvoicesByDate({
    dataSources: dataSourceNewInvoiceCountDaily,
    dateUnit: 'day',
  });

  const newInvoiceCountMonthlyColumns = createColumnsBillingMetricsInvoicesByDate({
    dataSources: dataSourceNewInvoiceCountMonthly,
    dateUnit: 'month',
  });

  const convertedDataSourceTotalActiveSubscriptionExceptSupportCountList = convertDataSourceBillingMetrics({
    dataSources: dataSourceTotalActiveSubscriptionExceptSupportCountList,
  });

  const convertedDataSourceNewOrganizationCountDaily = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewOrganizationCountDaily,
  });

  const convertedDataSourceNewOrganizationCountMonthly = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewOrganizationCountMonthly,
  });

  const convertedDataSourceNewSubscriptionCountDaily = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewSubscriptionCountDaily,
  });

  const convertedDataSourceNewSubscriptionCountMonthly = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewSubscriptionCountMonthly,
  });

  const convertedDataSourceNewInvoiceCountDaily = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewInvoiceCountDaily,
  });

  const convertedDataSourceNewInvoiceCountMonthly = convertDataSourceBillingMetrics({
    dataSources: dataSourceNewInvoiceCountMonthly,
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Billing metric > Self-service" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>Total active subscription count (free trial, starter, pro)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={totalActiveSubscriptionExceptSupportCountListLoading}
                  columns={totalActiveSubscriptionExceptSupportCountListColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceTotalActiveSubscriptionExceptSupportCountList}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New organization count (daily)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newOrganizationCountDailyLoading}
                  columns={newOrganizationCountDailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewOrganizationCountDaily}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New organization count (monthly)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newOrganizationCountMonthlyLoading}
                  columns={newOrganizationCountMonthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewOrganizationCountMonthly}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New subscription count (daily)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newSubscriptionCountDailyLoading}
                  columns={newSubscriptionCountDailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewSubscriptionCountDaily}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New subscription count (monthly)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newSubscriptionCountMonthlyLoading}
                  columns={newSubscriptionCountMonthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewSubscriptionCountMonthly}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New invoice count (daily)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newInvoiceCountDailyLoading}
                  columns={newInvoiceCountDailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewInvoiceCountDaily}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New invoice count (monthly)</Title>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={newInvoiceCountMonthlyLoading}
                  columns={newInvoiceCountMonthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={convertedDataSourceNewInvoiceCountMonthly}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
