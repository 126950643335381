import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from 'antd';

import { ApplicationDetailInfo as Info } from './ApplicationDetailInfo';
import { ApplicationDetailNote as Note } from './ApplicationDetailNote';
import { ApplicationDetailPlans as Plans } from './ApplicationDetailPlans';
import { ApplicationDetailSubscriptions as Subscriptions } from './ApplicationDetailSubscriptions';
import { ApplicationDetailPayments as Payments } from './ApplicationDetailPayments';
import { getSyncCrmApplicationRequest } from '../api/application';

type Props = {
  crmApplication: CrmApplication;
  getCrmApplicationRequest: PrivateRouteProps['getCrmApplicationRequest'];
} & RouteComponentProps<MatchParams>;

interface MatchParams {
  appId: CrmApplication['appId'];
}

function getSyncCrmApplication(appId) {
  getSyncCrmApplicationRequest(appId)
    .then((response) => {
      // console.log('response', response);
    })
    .catch((error) => {
      console.error('error', error);
    });
}

const ApplicationDetailContainer = styled.div<{ isRemoved: boolean }>`
  ${(props) => (props.isRemoved ? '* {\n    text-decoration: line-through;\n  }' : '')}
`;

export const ApplicationDetail: React.FC<Props> = ({ match, crmApplication, getCrmApplicationRequest }) => {
  const { appId } = match.params;

  useEffect(() => {
    getSyncCrmApplication(appId);
  }, []);

  const isV2 = crmApplication.crmOrganization ? crmApplication.crmOrganization.isSelfServe : false;

  return !crmApplication || !crmApplication.id ? (
    <></>
  ) : (
    <ApplicationDetailContainer isRemoved={crmApplication.removed}>
      <Info crmApplication={crmApplication} getCrmApplicationRequest={getCrmApplicationRequest} />
      <Note crmApplication={crmApplication} getCrmApplicationRequest={getCrmApplicationRequest} />
      <Plans appId={appId} crmApplication={crmApplication} />

      {isV2 && (
        <Alert
          message="This organization is Version 2. The Subscriptions and Payments below apply only to Version 1 applications."
          type="warning"
          showIcon={true}
          style={{ marginBottom: '24px' }}
        />
      )}
      <Subscriptions appId={appId} crmApplication={crmApplication} />
      <Payments appId={appId} crmApplication={crmApplication} />
    </ApplicationDetailContainer>
  );
};
