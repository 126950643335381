import * as React from 'react';
import { Card, Col, List, Row, Typography } from 'antd';
import { ActiveTag } from './ui/Tags';
import { CountryWithFlag } from './ui/DataText';
import { COUNTRY_CODE } from '../CountryCode';
const { Text } = Typography;

type Props = {
  crmUser: CrmUser;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const CrmUserInfo: React.FC<Props> = ({ crmUser }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'Crm ID',
      value: (
        <React.Fragment>
          {crmUser.id} <small>(Gate ID: {crmUser.gateUserId})</small>
        </React.Fragment>
      ),
    },
    {
      key: 'Email',
      value: <Text copyable={true}>{crmUser.email}</Text>,
    },
    {
      key: 'Nick Name',
      value: crmUser.nickname,
    },
    {
      key: 'First Name',
      value: crmUser.firstName,
    },
    {
      key: 'Last Name',
      value: crmUser.lastName,
    },
    {
      key: 'IsActive',
      value: <ActiveTag isActive={crmUser.isActive} />,
    },
  ];
  const detailAdvanced = [
    {
      key: 'Country',
      value:
        crmUser &&
        crmUser.countryCode &&
        COUNTRY_CODE[crmUser.countryCode.toLowerCase()] ? (
          <CountryWithFlag countryCode={crmUser.countryCode} />
        ) : (
          '-'
        ),
    },
    {
      key: (
        <span>
          Date Joined <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmUser.dateJoined,
    },
    {
      key: 'TwoFactor Authentication',
      value: <ActiveTag isActive={crmUser.useTwoFactorAuthentication} />,
    },
    {
      key: 'Stripe ID',
      value: crmUser.stripeId,
    },
    {
      key: 'Email Verified',
      value: <ActiveTag isActive={crmUser.emailVerified} />,
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col
          key={`organization-info-${index}`}
          span={24}
          md={{ span: 12 }}
          style={{ paddingBottom: '16px' }}
        >
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '280px',
            }}
          >
            <List
              size="small"
              dataSource={dataSource}
              renderItem={renderInfoItem}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
