import React, { createContext, useState } from 'react';
import { getSyncCrmOrganizationRequest, getCrmOrganizationRequest } from '../../api/organization';
import { useRouteMatch } from 'react-router-dom';

type OrganizationContextProps = {
  state: {
    isLoadingOrganization: boolean;
    crmOrganization: CrmOrganization;
  };
  actions: {
    fetchSyncCrmOrganization: ({ callback: any }) => void;
  };
};

export const OrganizationContext = createContext<OrganizationContextProps>({
  state: {
    isLoadingOrganization: false,
    crmOrganization: {} as CrmOrganization,
  },
  actions: {
    fetchSyncCrmOrganization: () => {},
  },
});

export const OrganizationContextProvider = ({ children }) => {
  const match = useRouteMatch<{ crmOrganizationId: string }>();
  const [isLoadingOrganization, setIsLoadingOrganization] = useState(false);
  const [crmOrganization, setCrmOrganization] = useState<CrmOrganization>({} as CrmOrganization);

  const { crmOrganizationId } = match.params;

  const fetchSyncCrmOrganization = ({ callback }) => {
    setIsLoadingOrganization(true);
    getSyncCrmOrganizationRequest({ crmOrganizationId })
      .then((response) => {
        setCrmOrganization(response.data);
        callback && callback(response);
      })
      .catch((error) => {
        console.error(error);

        getCrmOrganizationRequest({ crmOrganizationId })
          .then((response) => {
            setCrmOrganization(response.data);
            callback && callback(response);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoadingOrganization(false);
          });
      })
      .finally(() => {
        setIsLoadingOrganization(false);
      });
  };
  return (
    <OrganizationContext.Provider
      value={{
        state: { isLoadingOrganization, crmOrganization },
        actions: { fetchSyncCrmOrganization },
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
