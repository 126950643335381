import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getApplicationApplicationPlanRequest } from '../../api/application';
import { PageTitle } from '../../components/ui/PageTitle';
import { ApplicationPlanInfo } from '../../components/ApplicationPlanInfo';
import { Spinner } from '../../components/ui/Spinner';
import { Layout } from 'antd';

interface MatchParams {
  appId: string;
  applicationPlanId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const ApplicationPlan = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { appId, applicationPlanId } = match.params;
    const [applicationPlan, setApplicationPlan] = useState({} as ApplicationPlanDetail);

    function getApplicationPlan() {
      getApplicationApplicationPlanRequest(appId, applicationPlanId)
        .then((response) => {
          setApplicationPlan(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getApplicationPlan();
    }, []);

    return !applicationPlan || !applicationPlan.id ? (
      <Spinner />
    ) : (
      <Layout>
        <PageTitle
          title="Application Plan Detail"
          subTitle={`#${(applicationPlan.id && applicationPlan.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <ApplicationPlanInfo applicationPlan={applicationPlan} />
        </Layout.Content>
      </Layout>
    );
  }),
);
