import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getCrmSubscriptionRequest,
  getCrmPaymentsRequest,
  getEventLogsRequest,
} from '../../api/subscription';

import { CrmSubscriptionInfo } from '../../components/CrmSubscriptionInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import {
  ActiveTag,
  PaymentTypeTag,
  PaymentStatusTag,
} from '../../components/ui/Tags';
import { IsJsonString } from '../../utils/data';
import JSONPretify from 'react-json-pretty';
import styled from 'styled-components';

const CrmSubscriptionContainer = styled.div<{ isActive: boolean }>`
  ${(props) => (props.isActive ? '' : '* { text-decoration: line-through; }')}
`;

const paymentsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: CrmPayment['id']) => (
      <Link to={`/crm_payments/${id}/`}>{id}</Link>
    ),
  },
  {
    title: 'Active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive: CrmPayment['isActive']) => (
      <ActiveTag isActive={isActive} />
    ),
  },
  {
    title: 'App ID',
    key: 'appId',
    dataIndex: 'crmApplication',
    render: (application: CrmPayment['crmApplication']) => (
      (application && application.appId)? <Link to={`/applications/${application.appId}/detail/`}>
        {application.appId.split('-')[0]}...
      </Link> : ''
    ),
  },
  {
    title: 'User (Crm)',
    dataIndex: 'crmUser',
    key: 'user',
    render: (crmUser: CrmPayment['crmUser']) =>
      crmUser ? (
        <Link to={`/crm_users/${crmUser.id}/`}>
          {crmUser.id} ({crmUser.email})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
    render: (type: CrmPayment['type']) => <PaymentTypeTag type={type} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmPayment['status']) => (
      <PaymentStatusTag status={status} />
    ),
  },
  {
    title: 'Fee (Product/Service)',
    key: 'plan',
    render: (crmPayment: CrmPayment) => (
      <span>{`$${crmPayment.productFee.toLocaleString()} / $${crmPayment.serviceFee.toLocaleString()}`}</span>
    ),
  },
  {
    title: 'Start Date',
    dataIndex: 'serviceStartDt',
    key: 'serviceStartDt',
  },
  {
    title: 'End Date',
    dataIndex: 'serviceEndDt',
    key: 'serviceEndDt',
  },
];

const eventLogsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/event_logs/${id}/`}> {id} </Link>,
  },
  {
    title: 'Type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (eventType) => <span>{eventType}</span>,
  },
  {
    title: 'Previous Attributes',
    dataIndex: 'objectPreviousAttributes',
    key: 'objectPreviousAttributes',
    render: (objectPreviousAttributes) =>
      IsJsonString(objectPreviousAttributes) ? (
        <JSONPretify
          style={{ textAlign: 'left', overflowX: 'auto' }}
          json={JSON.parse(objectPreviousAttributes)}
        />
      ) : (
        ''
      ),
  },
  {
    title: 'Updated Attributes',
    dataIndex: 'objectData',
    key: 'objectData',
    render: (objectData, eventLog) =>
      IsJsonString(eventLog.objectPreviousAttributes) && (
        <JSONPretify
          style={{ textAlign: 'left', overflowX: 'auto' }}
          json={Object.keys(
            JSON.parse(eventLog.objectPreviousAttributes),
          ).reduce((obj, k, v) => {
            obj[k] = JSON.parse(objectData)[k];
            return obj;
          }, {})}
        />
      ),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <span>{createdAt}</span>,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'userName',
    render: (requester) => (requester ? <span>{requester.username}</span> : ''),
  },
];

interface MatchParams {
  crmSubscriptionId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const CrmSubscription = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { crmSubscriptionId } = match.params;
    const [crmSubscription, setCrmSubscription] = useState(
      {} as CrmSubscription,
    );

    const [crmPayments, setCrmPayments] = useState([] as Array<CrmPayment>);
    const [loadingPayments, setPayments] = useState(false);
    const [paymentsCount, setPaymentsCount] = useState(0);

    const [eventLogs, setEventLogs] = useState([]);
    const [loadingEventLogs, setLoadingEventLogs] = useState(false);
    const [eventLogsCount, setEventLogsCount] = useState(0);

    function getCrmSubscription({ callback }) {
      getCrmSubscriptionRequest({ crmSubscriptionId })
        .then((response) => {
          setCrmSubscription(response.data);
          callback && callback();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getCrmPayments({ offset = 0, limit = 0 }) {
      setPayments(true);
      getCrmPaymentsRequest({ limit, offset, crmSubscriptionId })
        .then((response) => {
          setCrmPayments(response.data.results);
          setPayments(false);
          setPaymentsCount(response.data.count);
        })
        .catch((error) => {
          setPayments(false);
        });
    }

    function getEventLogs({ offset = 0, limit = 0 }) {
      setLoadingEventLogs(true);
      getEventLogsRequest({ limit, offset, crmSubscriptionId })
        .then((response) => {
          setEventLogs(response.data.results);
          setLoadingEventLogs(false);
          setEventLogsCount(response.data.count);
        })
        .catch((error) => {
          setLoadingEventLogs(false);
        });
    }

    useEffect(() => {
      getCrmSubscription({
        callback: function () {
          getCrmPayments({
            offset: 0,
            limit: 10,
          });
          getEventLogs({
            offset: 0,
            limit: 10,
          });
        },
      });
    }, []);

    return (
      <CrmSubscriptionContainer isActive={crmSubscription.isActive}>
        <PageTitle
          title="Subscription Detail"
          subTitle={`#${
            (crmSubscription.id && crmSubscription.id.toString()) || ''
          }`}
          showBack={true}
        />
        <CrmSubscriptionInfo crmSubscription={crmSubscription} />
        <TableDetail
          title="Payments"
          count={paymentsCount}
          loading={loadingPayments}
          columns={paymentsColumns}
          dataSource={crmPayments}
          onPageChange={getCrmPayments}
        />
        <TableDetail
          title="Event Logs"
          count={eventLogsCount}
          loading={loadingEventLogs}
          columns={eventLogsColumns}
          dataSource={eventLogs}
          onPageChange={getEventLogs}
        />
      </CrmSubscriptionContainer>
    );
  }),
);
