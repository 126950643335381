import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, DatePicker } from 'antd';
import moment from 'moment-timezone';

import { DynamicForm } from './ui/DynamicForm';
import { updateApplicationNoteRequest } from '../api/application';
import { churnReasonOptions, accountClassificationOptions } from '../utils/constants/application';
import { getUsersRequest } from '../api/user';
import { dateTimePattern, getBrowserTimeZoneString, getBrowserTimezone, getBrowserTodayMoment } from '../utils/date';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input;
const { Option } = Select;

const today = getBrowserTodayMoment();

function searchAeSelectOption(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const fields = (users: Array<User>, crmApplication: CrmApplication): any => [
  //Array<Field>
  {
    name: 'noteCompanyName',
    label: 'Company Name',
    formItemProps: { initialValue: crmApplication.noteCompanyName },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'noteServiceName',
    label: 'Service name',
    formItemProps: { initialValue: crmApplication.noteServiceName },
    span: 12,
    component: <Input size="large" />,
  },
  {
    name: 'noteAccountClassification',
    label: 'Account Classification',
    formItemProps: {
      initialValue: crmApplication.noteAccountClassification ? crmApplication.noteAccountClassification : '',
    },
    component: (
      <Select size="large">
        {accountClassificationOptions.map(({ value, label }) => (
          <Option key={`account-classification-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'noteAccountLead',
    label: 'Account Lead',
    formItemProps: {
      initialValue: crmApplication.noteAccountLead ? crmApplication.noteAccountLead.id : '',
    },
    component: (
      <Select size="large" showSearch={true} filterOption={searchAeSelectOption}>
        <Option key="account-lead-default" value="">
          Select
        </Option>
        {users.map(({ id, username }) => (
          <Option key={`account-lead-${id}}`} value={id}>
            {username}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'noteHubspotLink',
    label: 'HubSpot Link',
    formItemProps: { initialValue: crmApplication.noteHubspotLink },
    component: <Input size="large" />,
  },
  {
    name: 'noteChurn',
    label: 'Churn Reason',
    formItemProps: {},
    span: 12,
    component: (
      <Select size="large">
        {churnReasonOptions.map(({ value, label }) => (
          <Option key={`churn-reason-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'noteChurnDate',
    label: (
      <span>
        Churn Date <small style={{ opacity: 0.5 }}>{getBrowserTimezone()}</small>
      </span>
    ),
    formItemProps: {
      initialValue: moment.tz(
        crmApplication.noteChurnDate ? crmApplication.noteChurnDate : today,
        getBrowserTimeZoneString(),
      ),
    },
    span: 12,
    component: <DatePicker size="large" format={dateTimePattern.date} style={{ width: '100%' }} />,
  },
  {
    name: 'noteMemo',
    label: 'Memo',
    formItemProps: { initialValue: crmApplication.noteMemo },
    component: <TextArea rows={4} />,
  },
];

type Props = {
  crmApplication: CrmApplication;
  successCallback?: (callback: any) => void;
};

export const ApplicationNoteDialog: React.FC<Props> = React.memo(({ crmApplication, successCallback }) => {
  const formRef = useRef<FormInstance>();

  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function getUsers() {
    // TODO: this isn't a place to use limit-offset. replace it with some other endpoint
    getUsersRequest({ limit: 500, offset: 0 })
      .then((response) => setUsers(response.data.results))
      .catch((error) => {
        console.error(error);
      });
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { noteChurnDate, ...rest } = values;
          updateApplicationNoteRequest({
            appId: crmApplication.appId,
            noteChurnDate: noteChurnDate && noteChurnDate.format(dateTimePattern.date),
            ...rest,
          } as any)
            .then((response) => {
              console.log('response', response);
              successCallback && successCallback(response.data);
              handleCancel();
              formRef.current?.resetFields();
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  useEffect(function () {
    getUsers();
  }, []);

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          verticalAlign: 'middle',
        }}
      >
        Update note
      </Button>
      <Modal
        open={visible}
        title="Update application note"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Update
          </Button>,
        ]}
      >
        <DynamicForm fields={fields(users, crmApplication)} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
});
