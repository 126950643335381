import * as React from 'react';
import { Card, Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import { IsJsonString } from '../utils/data';

import ReactJson from 'react-json-view';

type Props = {
  userRequestActivityLog: UserRequestActivityLog;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col
          span={15}
          style={{
            fontWeight: 500,
            textAlign: item.align ? item.align : 'right',
          }}
        >
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const UserRequestActivityLogInfo: React.FC<Props> = ({
  userRequestActivityLog,
}) => {
  const detailBasic = [
    {
      key: '#ID',
      value: userRequestActivityLog.id,
    },
    {
      key: 'Request User',
      value: (
        <React.Fragment>
          {userRequestActivityLog.requestUser ? (
            <Link to={`/users/${userRequestActivityLog.requestUser.id}/`}>
              {userRequestActivityLog.requestUser.email}
            </Link>
          ) : (
            '-'
          )}
        </React.Fragment>
      ),
    },
    {
      key: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: userRequestActivityLog.createdAt,
    },
    {
      key: 'Request Method',
      value: userRequestActivityLog.requestMethod,
    },
  ];

  const responseInfo = [
    {
      key: 'Response Code',
      value: userRequestActivityLog.responseCode,
    },
  ];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      <Col lg={24} xl={12}>
        <Row>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Basic & Request" size="small">
              <List
                size="small"
                dataSource={detailBasic}
                renderItem={renderInfoItem}
              />
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Request Header" size="small">
              {userRequestActivityLog.requestHeader ? (
                IsJsonString(userRequestActivityLog.requestHeader) ? (
                  <ReactJson
                    src={JSON.parse(userRequestActivityLog.requestHeader)}
                    style={{ overflowX: 'auto' }}
                  />
                ) : (
                  userRequestActivityLog.requestHeader
                )
              ) : (
                ''
              )}
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Request Body" size="small">
              {userRequestActivityLog.requestBody ? (
                IsJsonString(userRequestActivityLog.requestBody) ? (
                  <ReactJson
                    src={JSON.parse(userRequestActivityLog.requestBody)}
                    style={{ overflowX: 'auto' }}
                  />
                ) : (
                  userRequestActivityLog.requestBody
                )
              ) : (
                ''
              )}
            </Card>
          </Col>
        </Row>
      </Col>

      <Col lg={24} xl={12}>
        <Row>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Response" size="small">
              <List
                size="small"
                dataSource={responseInfo}
                renderItem={renderInfoItem}
              />
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Response Header" size="small">
              {userRequestActivityLog.responseHeader ? (
                IsJsonString(userRequestActivityLog.responseHeader) ? (
                  <ReactJson
                    src={JSON.parse(userRequestActivityLog.responseHeader)}
                    style={{ overflowX: 'auto' }}
                  />
                ) : (
                  userRequestActivityLog.responseHeader
                )
              ) : (
                ''
              )}
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Response Body" size="small">
              {userRequestActivityLog.responseBody ? (
                IsJsonString(userRequestActivityLog.responseBody) ? (
                  <ReactJson
                    src={JSON.parse(userRequestActivityLog.responseBody)}
                    style={{ overflowX: 'auto' }}
                  />
                ) : (
                  userRequestActivityLog.responseBody
                )
              ) : (
                ''
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
