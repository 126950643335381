import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal } from 'antd';

import axios from 'axios';

import { API_DOMAIN } from '../App';
import { AuthenticationContext } from '../containers/AuthenticationProvider';

const Oauth2Callback = () => {
  const [callbackErrorMsg, setCallbackErrorMsg] = useState('');
  const [callbackSuccess, setCallbackSuccess] = useState(false);

  const { setAuthenticated } = useContext(AuthenticationContext);

  useEffect(() => {
    axios
      .post(`${API_DOMAIN}/api/authentications/oauth2_google/`, {
        access_token: window.location.hash.split('#access_token=')[1].split('&')[0],
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.username);
        localStorage.setItem('userId', response.data.userId);
        setAuthenticated(true);
        setCallbackSuccess(true);
      })
      .catch((error) => {
        let errorDetail = error.response && error.response.data && error.response.data.detail;
        setCallbackErrorMsg(errorDetail);
      });
  }, []);

  if (callbackSuccess) {
    return <Redirect to="/" />;
  } else {
    return (
      <Modal title="Authentication failed" open={!!callbackErrorMsg}>
        <p>{callbackErrorMsg}</p>
      </Modal>
    );
  }
};

export default Oauth2Callback;
