import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Chart, { ChartData, ChartDataSets, ChartTitleOptions } from 'chart.js';
import styled from 'styled-components';
import { media } from '../utils/media';
// import { media } from '../utils/media';

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.unregister(ChartDataLabels);

const CanvasContainer = styled.div`
  height: 400px;

  ${media.MOBILE_LARGE`
    height: 300px;
  `}
`;

const Canvas = styled.canvas`
  height: 100%;
`;

type Props = {
  title?: ChartTitleOptions['text'];
  labels?: ChartData['labels'];
  data1?: ChartDataSets['data'];
  data2?: ChartDataSets['data'];
  label1?: ChartDataSets['label'];
  label2?: ChartDataSets['label'];
  backgroundColor?: ChartDataSets['backgroundColor'];
  height?: number;
  labelFormatter?: any;
  callbacksTooltipLabel?: any;
  callBackYAxesTicks?: any;
};

export const TwoBarChartComponent: React.FC<Props> = React.memo(
  ({
    title,
    data1,
    data2,
    labels,
    label1,
    label2,
    backgroundColor,
    height,
    labelFormatter,
    callbacksTooltipLabel,
    callBackYAxesTicks,
  }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart>();

    useEffect(
      function () {
        if (chart && chart.destroy) {
          chart.destroy();
        }
        drawChart();
      },
      [title, data1, data2, labels, label1, label2, backgroundColor],
    );

    function drawChart() {
      let context = ((chartRef.current && chartRef.current.getContext('2d')) as CanvasRenderingContext2D) || undefined;

      if (context) {
        context['height'] = 300;

        let chartData = {
          type: 'bar',

          plugins: [] as any,

          data: {
            labels: labels,
            datasets: [
              {
                label: label1,
                data: data1,
                backgroundColor: 'rgba(255, 166, 0, 0.2)',
                borderColor: 'rgba(255, 166, 0, 1)',
                borderWidth: 1,
              },
              {
                label: label2,
                data: data2,
                backgroundColor: 'rgba(0, 63, 92, 0.1)',
                borderColor: 'rgba(0, 63, 92, 0.4)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: title ? title : '',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                formatter: function (value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0;
                },
                font: {
                  weight: 500,
                },
              },
            },
          },
        };

        if (callBackYAxesTicks) {
          chartData.options.scales.yAxes[0].ticks['callback'] = callBackYAxesTicks;
        }

        if (labelFormatter) {
          chartData.plugins.push(ChartDataLabels);
          chartData.options.plugins.datalabels.formatter = labelFormatter;
        }

        if (callbacksTooltipLabel) {
          chartData.options['tooltips'] = {
            callbacks: {
              label: callbacksTooltipLabel,
            },
          };
        }

        const chart = new Chart(context, chartData);

        setChart(chart);
      }
    }

    return (
      <CanvasContainer>
        <Canvas ref={chartRef} height={height} />
      </CanvasContainer>
    );
  },
);
