import * as React from 'react';
import {Card, Col, List, Row} from 'antd';

type Props = {
  leftData: Array<DataItem>;
  rightData: Array<DataItem>;
};

interface DataItem {
  key: any;
  value: any;
}

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={7}>{item.key}</Col>
        <Col span={17} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const GeneralDetail: React.FC<Props> = ({ leftData, rightData }) => {
  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '24px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      <Col span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
        <Card title="Detail" size="small">
          <List size="small" dataSource={leftData} renderItem={renderInfoItem} />
        </Card>
      </Col>
      <Col span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
        <Card title=" " size="small">
          <List size="small" dataSource={rightData} renderItem={renderInfoItem} />
        </Card>
      </Col>
    </Row>
  );
};
