import * as React from 'react';
import { Card, Col, List, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  ActiveTag,
  BillingCycleTag,
  AutoRenewalTag,
  PaymentMethodTag,
} from './ui/Tags';

type Props = {
  crmSubscription: CrmSubscription;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const CrmSubscriptionInfo: React.FC<Props> = ({ crmSubscription }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'Id',
      value: crmSubscription.id,
    },
    {
      key: 'App ID',
      value: crmSubscription.appId ? (
        <Link to={`/applications/${crmSubscription.appId}/detail/`}>
          {crmSubscription.crmApplication.appId}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'isActive',
      value: <ActiveTag isActive={crmSubscription.isActive} />,
    },
    {
      key: 'Billing Cycle',
      value: crmSubscription.billingCycle ? (
        <BillingCycleTag billingCycle={crmSubscription.billingCycle} />
      ) : (
        '-'
      ),
    },
    {
      key: 'Auto Renewal',
      value: crmSubscription.autoRenewal ? (
        <AutoRenewalTag
          autoRenewal={crmSubscription.autoRenewal}
          autoRenewalPeriod={crmSubscription.autoRenewalPeriod}
        />
      ) : (
        '-'
      ),
    },
    {
      key: 'Payment Method',
      value: crmSubscription.paymentMethod ? (
        <PaymentMethodTag paymentMethod={crmSubscription.paymentMethod} />
      ) : (
        '-'
      ),
    },
  ];
  const detailAdvanced = [
    {
      key: 'Product Fee',
      value: crmSubscription.productFee ? (
        <Tag key={crmSubscription.productFee} style={{ margin: 0 }}>
          $ {crmSubscription.productFee.toLocaleString()}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Service Fee',
      value: crmSubscription.serviceFee ? (
        <Tag key={crmSubscription.serviceFee} style={{ margin: 0 }}>
          $ {crmSubscription.serviceFee.toLocaleString()}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Start Date',
      value: crmSubscription.subscriptionStartDt
        ? crmSubscription.subscriptionStartDt
        : '-',
    },
    {
      key: 'End Date',
      value: crmSubscription.subscriptionEndDt
        ? crmSubscription.subscriptionEndDt
        : '-',
    },
    {
      key: (
        <span>
          UpdatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmSubscription.updatedDt ? crmSubscription.updatedDt : '-',
    },
    {
      key: 'PaymentTerm',
      value: crmSubscription.paymentTerm ? crmSubscription.paymentTerm : '-',
    },
    {
      key: 'Memo',
      value: crmSubscription.memo ? crmSubscription.memo : '-',
    },
    {
      key: (
        <span>
          Info <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmSubscription.createUser ? (
        <span>
          created_by: {crmSubscription.createUser.username} / (
          {crmSubscription.createdDt})
        </span>
      ) : (
        '-'
      ),
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col
          key={`-info-${index}`}
          span={24}
          md={{ span: 12 }}
          style={{ paddingBottom: '16px' }}
        >
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '370px',
            }}
          >
            <List
              size="small"
              dataSource={dataSource}
              renderItem={renderInfoItem}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
