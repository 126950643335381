import * as React from 'react';

import { Card, Row, Col, Statistic } from 'antd';
import { CardProps } from 'antd/lib/card';
import { StatisticProps } from 'antd/lib/statistic/Statistic';

interface DataSource {
  key: StatisticProps['title'];
  value: StatisticProps['value'];
}

type Props = {
  title: CardProps['title'];
  dataSource: Array<DataSource>;
  rowStyle?: React.CSSProperties;
};

export const MetricCard: React.FC<Props> = React.memo(
  ({ title, dataSource, rowStyle = {} }) => {
    return (
      <Row style={rowStyle}>
        <Col span={24}>
          <Card title={title} size="small">
            <Row>
              {dataSource.map(({ key, value }, index) => (
                <Col key={`app-stats-${index}`} span={24} md={{ span: 8 }}>
                  <Statistic title={key} value={value} />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    );
  },
);
