import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getPermissionsRequest({
  offset,
  limit
}: {
  offset: number;
  limit: number;
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/permissions/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getUserPermissionsRequest({
  offset,
  limit,
  userId,
  permissionId,
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_permissions/?` +
    `limit=${limit}&offset=${offset}&user_id=${userId}&permission_id=${permissionId}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}


export function getPermissionRequest({ permissionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/permissions/${permissionId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getUserPermissionRequest ({ userPermissionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_permissions/${userPermissionId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
