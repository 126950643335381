import { Layout, Select, DatePicker, Space, Button, Card, Row, Col, Typography, Table, message, Tooltip } from 'antd';
import { ColumnType, TableProps } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageTitle } from '../../components/ui';
import { regionNames } from '../../utils/constants';
import { LineChart, ResizeColumnButton, RowChart, SortByButton, WithLabel } from './components';
import { green } from '@ant-design/colors';
import { red } from '@ant-design/colors';
import { CaretDownFilled, CaretUpFilled, CopyOutlined, StarOutlined } from '@ant-design/icons';

import { FetchRankingResult } from './types';
import { RegionBadge } from './components/RegionBadge';
import { useQuery } from 'react-query';
import { fetchRanking } from './api';

import './TrendsWithQueryClient.css';
import Modal from 'antd/lib/modal/Modal';
import copy from 'copy-to-clipboard';
import { WEB_DOMAIN } from '../../App';

const { Text, Paragraph } = Typography;
const { Option, OptGroup } = Select;
const PercentNumberFormat = new Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 2 });

const getRecordOfSelectedDate = (dailyRecords: FetchRankingResult['daily_records']) => dailyRecords[0];
const getRecordOfPreviousDay = (dailyRecords: FetchRankingResult['daily_records']) => dailyRecords[1];

type DailyRecordKey = keyof FetchRankingResult['daily_records'][number];

const getChartTitle = (key: DailyRecordKey) =>
  ({
    mau: 'MAU',
    dau: 'DAU',
    daily_message_count: 'Messages',
    daily_peak_connection: 'Peak connections',
    messaged_user_count: 'Messaged users',
  }[key] || key);

const getChartAxisTitle = (key: DailyRecordKey) =>
  ({
    mau: 'Users',
    dau: 'Users',
    daily_message_count: 'Messages',
    daily_peak_connection: 'Connections',
    messaged_user_count: 'Users',
  }[key] || '');

const getLastColumn = (key: DailyRecordKey): ColumnType<FetchRankingResult> => ({
  key: `${key}-last`,
  title: (
    <Space direction="vertical" size={0}>
      <span>Last</span>
      <span>Previous</span>
    </Space>
  ),
  align: 'right',
  render: (text, record) => {
    return (
      <Space direction="vertical" size={0}>
        <span>{getRecordOfSelectedDate(record.daily_records)[key].toLocaleString('en')}</span>
        <span>{getRecordOfPreviousDay(record.daily_records)[key].toLocaleString('en')}</span>
      </Space>
    );
  },
});

const getChangeIcon = (change: number) => {
  if (change > 0) {
    return <CaretUpFilled />;
  }
  if (change < 0) {
    return <CaretDownFilled />;
  }
  return null;
};

const getChangeColor = (change: number) => {
  if (change > 0) {
    return green.primary;
  }
  if (change < 0) {
    return red.primary;
  }
  return undefined;
};

const getChangeColumn = ({
  key,
  valueGetter,
}: {
  key: string;
  valueGetter: (record: FetchRankingResult) => { changePercent: number; change: number };
}): ColumnType<FetchRankingResult> => {
  return {
    key: `${key}-change`,
    title: (
      <Space direction="vertical" size={0}>
        <span>+/-</span>
        <span>%</span>
      </Space>
    ),
    align: 'right',
    render: (text, record) => {
      const { changePercent, change } = valueGetter(record);
      const color = getChangeColor(change);

      return (
        <Row style={{ color }}>
          <Col flex="none">{getChangeIcon(change)}</Col>
          <Col flex="auto">
            <Space direction="vertical" size={0}>
              <Text style={{ color: 'inherit' }}>{change.toLocaleString('en')}</Text>
              <Text style={{ color: 'inherit' }}>{PercentNumberFormat.format(changePercent)}</Text>
            </Space>
          </Col>
        </Row>
      );
    },
  };
};

const getWeekChangeColumn = ({
  valueGetter,
  key,
}: {
  key: string;
  valueGetter: (record: FetchRankingResult) => { changePercent: number; change: number };
}): ColumnType<FetchRankingResult> => {
  return {
    key: `${key}-weekchange`,
    title: (
      <Space direction="vertical" size={0}>
        <span>1 week</span>
        <span>+/-</span>
        <span>%</span>
      </Space>
    ),
    align: 'right',
    render: (text, record) => {
      const { changePercent, change } = valueGetter(record);

      const color = getChangeColor(change);

      return (
        <Row style={{ color }}>
          <Col flex="none">{getChangeIcon(change)}</Col>
          <Col flex="auto">
            <Space direction="vertical" size={0}>
              <Text style={{ color: 'inherit' }}>{change.toLocaleString('en')}</Text>
              <Text style={{ color: 'inherit' }}>{PercentNumberFormat.format(changePercent)}</Text>
            </Space>
          </Col>
        </Row>
      );
    },
  };
};

const getWeekChartColumn = ({
  key,
  onClick,
}: {
  key: DailyRecordKey;
  onClick: (record: FetchRankingResult) => void;
}): ColumnType<FetchRankingResult> => {
  return {
    key: `${key}-weekchart`,
    title: <span style={{ whiteSpace: 'pre-line' }}>{'1 week\nchart'}</span>,
    align: 'right',
    render: (text, record) => {
      const data = record.daily_records
        .slice(0, 7)
        .map((record) => ({ x: record.record_date, y: record[key] as number }));
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RowChart
            id={`row-chart-${key}-from-${data[0].x}`}
            data={data}
            style={{ display: 'inline-block', cursor: 'zoom-in' }}
            onClick={() => {
              onClick(record);
            }}
          />
        </div>
      );
    },
    width: 72,
  };
};

const getColumns = ({
  expandRow,
  copyAppInfo,
  offset,
  nameColumnWidth,
  setNameColumnWidth,
}: {
  expandRow: (record: FetchRankingResult) => void;
  copyAppInfo: (record: FetchRankingResult) => void;
  offset: number;
  nameColumnWidth: number;
  setNameColumnWidth: (value: number) => void;
}): NonNullable<TableProps<FetchRankingResult>['columns']> => [
  {
    key: 'index',
    title: '#',
    render: (text, record, index) => String(offset + index + 1),
    align: 'right',
    fixed: 'left',
    width: 70,
    ellipsis: true,
  },
  {
    key: 'name',
    title: (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Name
        <ResizeColumnButton
          key={nameColumnWidth}
          value={nameColumnWidth}
          onChange={(value) => {
            setNameColumnWidth(value);
          }}
        />
      </div>
    ),
    render: (text, record) => {
      const { crm_application } = record;
      const { crm_organization } = crm_application;
      return (
        <Space direction="vertical" size={0} className="Trends__name-column">
          <Link href={`/applications/${crm_application.app_id}/detail/`} ellipsis={true}>
            <RegionBadge region={crm_application.region} style={{ marginRight: 6 }} />
            {crm_application.app_name}
          </Link>
          <Link
            href={`/crm_organizations/${crm_organization.id}/`}
            type="secondary"
            style={{ textTransform: 'uppercase', fontSize: 12 }}
            ellipsis={true}
          >
            {crm_organization.name}
          </Link>
          <Tooltip title="Copy info">
            <Button
              icon={<CopyOutlined />}
              size="small"
              className="Trends__name-column__app-name__copy-button"
              onClick={() => {
                copyAppInfo(record);
              }}
            />
          </Tooltip>
        </Space>
      );
    },
    width: nameColumnWidth,
    fixed: 'left',
  },
  {
    key: 'mau',
    title: 'MAU',
    align: 'right',
    render: (text, record) => getRecordOfSelectedDate(record.daily_records).mau.toLocaleString('en'),
  },
  {
    title: 'DAU',
    children: [
      getLastColumn('dau'),
      getChangeColumn({
        valueGetter: (record) => {
          const changePercent = getRecordOfSelectedDate(record.daily_records).fluctuation_dau / 100;
          const change = getRecordOfSelectedDate(record.daily_records).diff_dau;
          return { changePercent, change };
        },
        key: 'dau',
      }),
      getWeekChangeColumn({
        valueGetter: (record) => {
          const changePercent = record.weekly_record.fluctuation_dau / 100;
          const change = record.weekly_record.diff_dau;
          return { changePercent, change };
        },
        key: 'dau',
      }),
      getWeekChartColumn({
        key: 'dau',
        onClick: (record) => {
          expandRow(record);
        },
      }),
    ],
  },
  {
    title: 'Messages',
    children: [
      getLastColumn('daily_message_count'),
      getChangeColumn({
        valueGetter: (record) => {
          const changePercent = getRecordOfSelectedDate(record.daily_records).fluctuation_daily_message_count / 100;
          const change = getRecordOfSelectedDate(record.daily_records).diff_daily_message_count;
          return { changePercent, change };
        },
        key: 'daily_message_count',
      }),
      getWeekChangeColumn({
        valueGetter: (record) => {
          const changePercent = record.weekly_record.fluctuation_daily_message_count / 100;
          const change = record.weekly_record.diff_daily_message_count;
          return { changePercent, change };
        },
        key: 'daily_message_count',
      }),
      getWeekChartColumn({
        key: 'daily_message_count',
        onClick: (record) => {
          expandRow(record);
        },
      }),
    ],
  },
  {
    title: 'Peak connections',
    children: [
      getLastColumn('daily_peak_connection'),
      getChangeColumn({
        valueGetter: (record) => {
          const changePercent = getRecordOfSelectedDate(record.daily_records).fluctuation_daily_peak_connection / 100;
          const change = getRecordOfSelectedDate(record.daily_records).diff_daily_peak_connection;
          return { changePercent, change };
        },
        key: 'daily_peak_connection',
      }),
      getWeekChangeColumn({
        valueGetter: (record) => {
          const changePercent = record.weekly_record.fluctuation_daily_peak_connection / 100;
          const change = record.weekly_record.diff_daily_peak_connection;
          return { changePercent, change };
        },
        key: 'daily_peak_connection',
      }),
      getWeekChartColumn({
        key: 'daily_peak_connection',
        onClick: (record) => {
          expandRow(record);
        },
      }),
    ],
  },
  {
    title: 'Messaged users',
    children: [
      getLastColumn('messaged_user_count'),
      getChangeColumn({
        valueGetter: (record) => {
          const changePercent = getRecordOfSelectedDate(record.daily_records).fluctuation_messaged_user_count / 100;
          const change = getRecordOfSelectedDate(record.daily_records).diff_messaged_user_count;
          return { changePercent, change };
        },
        key: 'messaged_user_count',
      }),
      getWeekChangeColumn({
        valueGetter: (record) => {
          const changePercent = record.weekly_record.fluctuation_messaged_user_count / 100;
          const change = record.weekly_record.diff_messaged_user_count;
          return { changePercent, change };
        },
        key: 'messaged_user_count',
      }),
      getWeekChartColumn({
        key: 'messaged_user_count',
        onClick: (record) => {
          expandRow(record);
        },
      }),
    ],
  },
];

const getRowKey = (record: FetchRankingResult) => record.crm_application.app_id;

const useURLSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};

const isSortQueryParamValid = (value: string) =>
  value.match(
    /^\-?((week_)?(diff|fluctuation)_)?(mau|dau|daily_message_count|daily_peak_connection|messaged_user_count)$/,
  );

const getTableScrollY = () => {
  const headerHeight = document.querySelector('header.ant-layout-header')?.clientHeight ?? 169;
  const tableHeaderHeight = document.querySelector('.ant-table-header')?.clientHeight ?? 122;
  const paginationHeight = (document.querySelector('.ant-table-pagination')?.clientHeight ?? 24) + 32;
  return window.innerHeight - headerHeight - tableHeaderHeight - paginationHeight - 48;
};

const getAppInfoText = (record: FetchRankingResult) => `[Application]
App ID: ${record.crm_application.app_id}
Name: ${record.crm_application.app_name}
Region: ${record.crm_application.region}
Insight URL: ${WEB_DOMAIN}/applications/${record.crm_application.app_id}/detail/

[Organization]
UID: ${record.crm_application.crm_organization.uid}
Name: ${record.crm_application.crm_organization.name}
Insight URL: ${WEB_DOMAIN}/crm_organizations/${record.crm_application.crm_organization.id}/`;

const getFilterFromSearchParams = (searchParams: URLSearchParams) => {
  const regionQueryParam = searchParams.get('region') || '';
  const starredQueryParam = searchParams.get('starred') || '';

  if (regionQueryParam) {
    return regionQueryParam;
  }
  if (starredQueryParam === '1') {
    return 'starred';
  }
  return '';
};

export const TrendsWithQueryClient = () => {
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const sortQueryParam = searchParams.get('sort') || '-mau';
  const regionQueryParam = searchParams.get('region') || '';
  const starredQueryParam = searchParams.get('starred') || '';
  const dateQueryParam = searchParams.get('date') || moment().utc().subtract(30, 'hour').format('YYYY-MM-DD');
  const pageParam = searchParams.get('page') || '1';
  const pageSizeParam = searchParams.get('pagesize') || '20';
  const [filter, setFilter] = useState(() => getFilterFromSearchParams(searchParams));
  const [date, setDate] = useState(dateQueryParam);
  const [copyTargetRecord, setCopyTargetRecord] = useState<FetchRankingResult | null>(null);
  const [nameColumnWidth, setNameColumnWidth] = useState(210);

  const page = Number.isInteger(+pageParam) ? +pageParam : 1;
  const pageSize = Number.isInteger(+pageSizeParam) ? +pageSizeParam : 20;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setDate(dateQueryParam);
    setFilter(getFilterFromSearchParams(searchParams));
  }, [regionQueryParam, starredQueryParam, dateQueryParam]);

  const fetchRankingParams: Parameters<typeof fetchRanking>[0] = {
    sort: sortQueryParam as any,
    region: regionQueryParam,
    date: dateQueryParam,
    limit: pageSize,
    offset: (page - 1) * pageSize,
    starred: starredQueryParam === '1',
  };

  const { status, data, error } = useQuery(['dailyRankings', fetchRankingParams], () =>
    fetchRanking(fetchRankingParams),
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [tableScrollY, setTableScrollY] = useState<number>(() => {
    return getTableScrollY();
  });

  useEffect(() => {
    // TODO: validate other params
    if (!isSortQueryParamValid(sortQueryParam)) {
      searchParams.delete('sort');
      history.replace(`?${searchParams.toString()}`);
    }
  }, [sortQueryParam]);

  useEffect(() => {
    const eventHandler = () => {
      setTableScrollY(getTableScrollY());
    };
    window.addEventListener('resize', eventHandler);
    return () => {
      window.removeEventListener('resize', eventHandler);
    };
  }, []);

  useEffect(() => {
    if (status === 'error' && error) {
      console.error();
      if (error && typeof error === 'object') {
        message.error(error['response']?.data?.detail ?? String(error));
      } else {
        message.error(String(error));
      }
    }
  }, [error, status]);

  return (
    <Layout>
      <PageTitle title="Trends">
        <Row align="middle">
          <Col flex="none">
            <Card size="small">
              <Space size="middle" align="end">
                <Space direction="vertical" size={4}>
                  <label htmlFor="filter-region">
                    <Text>Filter</Text>
                  </label>
                  <Select
                    id="filter-region"
                    showSearch={true}
                    value={filter}
                    style={{ minWidth: 120 }}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, option) => {
                      const includesInputCaseInsensitive = (text: string) =>
                        text.toLowerCase().includes(input.toLowerCase());

                      return (
                        includesInputCaseInsensitive(option?.value || '') ||
                        includesInputCaseInsensitive((option?.label as string) || '')
                      );
                    }}
                    onChange={(value) => {
                      setFilter(value);
                    }}
                  >
                    <Option value="starred" label="Starred">
                      <StarOutlined style={{ marginRight: 6 }} />
                      Starred
                    </Option>
                    <OptGroup label="Regions">
                      {regionNames.map(({ key: label, value }) => (
                        <Option key={value} value={value} label={label}>
                          {value && <RegionBadge region={value} style={{ marginRight: 6 }} />}
                          {label}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </Space>
                <Space direction="vertical" size={4}>
                  <label htmlFor="filter-date">
                    <Text>Date</Text>
                  </label>
                  {useMemo(() => {
                    const momentObj = moment(date);
                    return (
                      <DatePicker
                        id="filter-date"
                        allowClear={false}
                        value={momentObj}
                        onChange={(date, dateString) => {
                          if (dateString) {
                            setDate(dateString);
                          }
                        }}
                      />
                    );
                  }, [date])}
                </Space>
                <Button
                  type="primary"
                  onClick={() => {
                    if (filter === 'starred') {
                      searchParams.set('starred', '1');
                      searchParams.delete('region');
                    } else if (filter) {
                      searchParams.set('region', filter);
                      searchParams.delete('starred');
                    } else {
                      searchParams.delete('region');
                      searchParams.delete('starred');
                    }
                    searchParams.set('date', date);
                    searchParams.delete('page');
                    history.push(`?${searchParams.toString()}`);
                  }}
                >
                  Apply
                </Button>
              </Space>
            </Card>
          </Col>
          <Col flex="auto" />
          <Col flex="none">
            <WithLabel label="Sort by">
              <SortByButton
                key={sortQueryParam}
                order={sortQueryParam.startsWith('-') ? 'descending' : 'ascending'}
                column={sortQueryParam.replace(/^-/, '') as any}
                onApply={(options) => {
                  const queryParam = (options.order === 'ascending' ? '' : '-') + options.column;
                  searchParams.set('sort', queryParam);
                  searchParams.delete('page');
                  history.push(`?${searchParams.toString()}`);
                }}
              />
            </WithLabel>
          </Col>
        </Row>
      </PageTitle>
      <Layout.Content className="Trends__content">
        <Table<FetchRankingResult>
          loading={status === 'loading'}
          rowKey={getRowKey}
          scroll={{ x: 2000 + nameColumnWidth - 210, y: tableScrollY }}
          bordered={true}
          size="small"
          columns={getColumns({
            expandRow: (record) => {
              setExpandedRowKeys((keys) => {
                if (keys.includes(getRowKey(record))) {
                  return keys.filter((key) => key !== getRowKey(record));
                } else {
                  return [...keys, getRowKey(record)];
                }
              });
            },
            copyAppInfo: (record) => {
              setCopyTargetRecord(record);
            },
            offset: fetchRankingParams.offset,
            nameColumnWidth,
            setNameColumnWidth,
          })}
          dataSource={data?.results}
          expandable={{
            expandedRowRender: (record) => {
              const renderChart = (key: DailyRecordKey) => {
                const data = record.daily_records.map((record) => ({
                  x: record.record_date,
                  y: record[key] as number,
                }));
                return <LineChart title={getChartTitle(key)} axisTitle={getChartAxisTitle(key)} data={data} />;
              };

              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    height: 300,
                  }}
                >
                  {renderChart('mau')}
                  {renderChart('dau')}
                  {renderChart('daily_message_count')}
                  {renderChart('daily_peak_connection')}
                  {renderChart('messaged_user_count')}
                </div>
              );
            },
            expandedRowKeys,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRowKeys((keys) => [...keys, getRowKey(record)]);
              } else {
                setExpandedRowKeys((keys) => keys.filter((key) => key !== getRowKey(record)));
              }
            },
          }}
          pagination={{
            current: page,
            pageSize,
            total: data?.count,
            onChange: (page, pageSize) => {
              searchParams.set('page', String(page));
              searchParams.set('pagesize', String(pageSize));
              history.push(`?${searchParams.toString()}`);
            },
            onShowSizeChange: () => {},
          }}
        />
      </Layout.Content>
      <Modal
        title="Copy Application Info"
        open={!!copyTargetRecord}
        okText="Copy"
        onOk={() => {
          copyTargetRecord && copy(getAppInfoText(copyTargetRecord));
          setCopyTargetRecord(null);
          message.success('Copied.');
        }}
        onCancel={() => {
          setCopyTargetRecord(null);
        }}
        cancelButtonProps={{ hidden: true }}
      >
        {copyTargetRecord && (
          <Paragraph style={{ whiteSpace: 'pre-line' }}>{getAppInfoText(copyTargetRecord)}</Paragraph>
        )}
      </Modal>
    </Layout>
  );
};
