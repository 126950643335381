import React, { useRef } from 'react';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, DatePicker } from 'antd';

import { DynamicForm } from '../ui/DynamicForm';
import { createOnetimePaymentRequest } from '../../api/billing';
import { dateTimePattern } from '../../utils/date';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input;

type Props = {
  successCallback: any;
  crmOrganizationId: string;
  organizationSubscriptionUid?: string;
};

export const OnetimeInvoiceCreateDialog: React.FC<Props> = ({
  crmOrganizationId,
  organizationSubscriptionUid,
  successCallback,
}) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fields = [
    {
      name: 'amount',
      label: 'Amount($)',
      formItemProps: {
        initialValue: 0,
        rules: [
          {
            required: true,
            message: 'Required',
          },
          {
            validator: (_, value, callback) => {
              const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
              if (value === '') {
                return callback && callback();
              } else if (!(!isNaN(value) && reg.test(value))) {
                return callback && callback('Please enter the valid value');
              }

              callback();
            },
          },
        ],
      },
      span: 12,
      component: <Input size="large" addonBefore="$" />,
    },
    {
      name: 'subscription__uid',
      label: 'Subscription UID (optional)',
      formItemProps: { initialValue: organizationSubscriptionUid },
      span: 12,
      component: <Input size="large" />,
    },
    {
      name: 'start_date',
      label: 'Period start (optional)',
      formItemProps: {},
      span: 12,
      component: <DatePicker size="large" format={dateTimePattern.date} />,
    },
    {
      name: 'end_date',
      label: 'Period end (optional)',
      formItemProps: {},
      span: 12,
      component: <DatePicker size="large" format={dateTimePattern.date} />,
    },
    {
      name: 'description',
      label: 'Description',
      formItemProps: {},
      component: <TextArea rows={4} />,
    },
  ];

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          createOnetimePaymentRequest({ crmOrganizationId, values })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create an onetime payment"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields as any} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
