import * as React from 'react';
import { useState, useRef } from 'react';
import Chart, { ChartData, ChartDataSets, ChartTitleOptions } from 'chart.js';
import styled from 'styled-components';
import { media } from '../utils/media';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.unregister(ChartDataLabels);

const CanvasContainer = styled.div<{ height?: number }>`
  height: ${(props) => props.height || 400}px;

  ${media.MOBILE_LARGE`
    height: 300px;
  `}
`;

const Canvas = styled.canvas`
  height: 100%;
`;

type Props = {
  title?: ChartTitleOptions['text'];
  labels?: ChartData['labels'];
  data?: ChartDataSets['data'];
  backgroundColor?: ChartDataSets['backgroundColor'];
  height?: number;
  callbacksTooltipLabel?: any;
  labelFormatter?: any;
};

export const PieChartComponent: React.FC<Props> = React.memo(
  ({ title, data, labels, backgroundColor, height, callbacksTooltipLabel = null, labelFormatter = null }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart>();

    useDeepCompareEffect(
      function () {
        if (chart && chart.destroy) {
          chart.destroy();
        }
        drawChart();
      },
      [title, data, labels, backgroundColor],
    );

    function drawChart() {
      let context = ((chartRef.current && chartRef.current.getContext('2d')) as CanvasRenderingContext2D) || undefined;

      if (context) {
        if (height) {
          context['height'] = height;
        } else {
          context['height'] = 300;
        }
        let chartData = {
          type: 'pie',

          plugins: [] as any,

          data: {
            labels,
            datasets: [
              {
                data,
                backgroundColor,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: title ? title : '',
            },
            plugins: {
              datalabels: {
                color: 'white',
                formatter: function (value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0 && context.dataIndex < 5;
                },
                font: {
                  weight: 550,
                },
              },
            },
          },
        };

        if (labelFormatter) {
          chartData.plugins.push(ChartDataLabels);
          chartData.options.plugins.datalabels.formatter = labelFormatter;
        }

        if (callbacksTooltipLabel) {
          chartData.options['tooltips'] = {
            callbacks: {
              label: callbacksTooltipLabel,
            },
          };
        }

        const chart = new Chart(context, chartData);

        setChart(chart);
      }
    }

    return (
      <CanvasContainer height={height}>
        <Canvas ref={chartRef} height={height} />
      </CanvasContainer>
    );
  },
);
