import * as React from 'react';
import { DashboardSubscribedApplications } from '../../components/DashboardSubscribedAppliactions';
import { DashboardSubscribedDifference } from '../../components/DashboardSubscribedDifference';

export const DashboardSubscribed = React.memo(() => {
  return (
    <>
      <DashboardSubscribedApplications />
      <DashboardSubscribedDifference />
    </>
  );
});
