import * as React from 'react';
import { Row, Col, Card, List } from 'antd';

import { renderInfoItem } from '../components/InfoCard';
import { ApplicationNoteDialog } from './ApplicationNoteDialog';

const noteGeneral = ({
  noteCompanyName,
  noteServiceName,
  noteAccountClassification,
  noteAccountLead,
  noteHubspotLink,
}: CrmApplication) => [
  {
    key: 'Company Name',
    value: noteCompanyName,
  },
  {
    key: 'Service Name',
    value: noteServiceName,
  },
  {
    key: 'Account Classification',
    value: noteAccountClassification,
  },
  {
    key: 'Account Lead',
    value: noteAccountLead ? noteAccountLead.username : '',
  },
  {
    key: 'HubSpot',
    value: noteHubspotLink ? (
      <a href={`${noteHubspotLink}`} target="_blank" rel="noopener noreferrer">
        {noteHubspotLink}
      </a>
    ) : (
      ''
    ),
  },
];
const noteMore = ({ noteMemo, noteChurn, noteChurnDate }: CrmApplication) => [
  {
    key: 'Churn Reason',
    value: noteChurn,
  },
  {
    key: 'Churn Date',
    value: noteChurnDate,
  },
  {
    key: 'Memo',
    value: noteMemo,
  },
];

type Props = {
  crmApplication: CrmApplication;
  getCrmApplicationRequest: PrivateRouteProps['getCrmApplicationRequest'];
};

export const ApplicationDetailNote: React.FC<Props> = React.memo(
  ({ crmApplication, getCrmApplicationRequest }) => (
    <Row style={{ margin: '16px 0' }}>
      <Col span={24}>
        <Card
          title="Note"
          size="small"
          extra={
            <ApplicationNoteDialog
              crmApplication={crmApplication}
              successCallback={getCrmApplicationRequest}
            />
          }
          style={{
            background: 'whitesmoke',
          }}
        >
          <Row gutter={16}>
            <Col span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
              <List
                size="small"
                dataSource={noteGeneral(crmApplication)}
                renderItem={renderInfoItem}
              />
            </Col>
            <Col span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
              <List
                size="small"
                dataSource={noteMore(crmApplication)}
                renderItem={renderInfoItem}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  ),
);
