import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getBillingSubscriptionRequestRequest } from '../../api/billing';
import { TableDetail } from '../ui/TableDetail';
import {
  BillingSubscriptionRequestRequestedActionTag,
  BillingSubscriptionRequestStatusTag,
} from '../ui/Tags';
import { OrganizationBillingSubscriptionRequestViewDialog } from '../organizationBilling/OrganizationBillingSubscriptionRequestViewDialog';
import { dateTimePattern } from '../../utils/date';
import { hasPermission, Permissions } from '../../utils/permission';

export const OrganizationSubscriptionRequests = ({ crmOrganizationId }) => {
  const history = useHistory();
  const [loadingBillingSubscriptionRequests, setLoadingBillingSubscriptionRequests] = useState(false);
  const [billingSubscriptionRequestsCount, setBillingSubscriptionRequestsCount] = useState(0);
  const [billingSubscriptionRequests, setBillingSubscriptionRequests] = useState([] as Array<any>);

  const getBillingSubscriptionRequests = ({ offset = 0, limit = 0 }) => {
    setLoadingBillingSubscriptionRequests(true);
    getBillingSubscriptionRequestRequest({ limit, offset, crmOrganizationId })
      .then((response) => {
        setBillingSubscriptionRequests(response.data.results);
        setLoadingBillingSubscriptionRequests(false);
        setBillingSubscriptionRequestsCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
        setLoadingBillingSubscriptionRequests(false);
      });
  };

  const billingSubscriptionRequestsColumn = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Request',
      dataIndex: 'requestedAction',
      key: 'requestedAction',
      render: (requestedAction, request) => {
        const subRequestResponseBody = JSON.parse(request.requestBody);
        return (
          <>
            <BillingSubscriptionRequestRequestedActionTag requestedAction={requestedAction} />
            <span>
              &nbsp;{subRequestResponseBody['product']}'s {subRequestResponseBody['display_name']} plan
              <br />[ ${subRequestResponseBody['plan_value'] / 100} per {subRequestResponseBody['billing_cycle_months']}
              &nbsp; month(s) ]
            </span>
          </>
        );
      },
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
      render: (_, request) => {
        return <Link to={`/users/${request.requester.id}/`}>{request.requester.email.split('@')[0]}</Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BillingSubscriptionRequestStatusTag status={status} />,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => {
        return <div style={{ wordBreak: 'break-all' }}>{reason}</div>;
      },
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => `${moment(createdAt).utc().format(dateTimePattern.default)}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, billingSubscription) => (
        <OrganizationBillingSubscriptionRequestViewDialog
          crmOrganization={billingSubscription.crmOrganization}
          billingSubscriptionRequest={billingSubscription}
          isReviewer={hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION)}
          successCallback={() => {
            window.location.href = `/crm_organizations/${crmOrganizationId}/`;
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    getBillingSubscriptionRequests({
      limit: 20,
      offset: 0,
    });
  }, []);

  const handleCreateClick = () => {
    history.push(`/crm_organizations/${crmOrganizationId}/create_billing_subscription/`);
  };

  return (
    <TableDetail
      title="Subscription Requests"
      count={billingSubscriptionRequestsCount}
      loading={loadingBillingSubscriptionRequests}
      columns={billingSubscriptionRequestsColumn}
      dataSource={billingSubscriptionRequests}
      onPageChange={getBillingSubscriptionRequests}
      pageSizeOptions={['20', '40', '60']}
      rightColumnComponent={
        hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION_CREATION) ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateClick}
            style={{
              margin: '0.25em',
              marginLeft: 0,
              verticalAlign: 'middle',
            }}
          >
            Create
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};
