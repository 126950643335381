import React, { useRef } from 'react';
import moment from 'moment-timezone';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Select, DatePicker, Input, Switch } from 'antd';

import { DynamicForm } from '../components/ui/DynamicForm';

import { updatePaymentRequest } from '../api/billing';
import { getBrowserTodayMoment, getBrowserTimeZoneString, dateTimePattern } from '../utils/date';
import { BrowserTimeZoneText } from '../components/ui/DataText';
import {
  _paymentMethodOptions,
  _paymentTypeOptions,
  paymentMethodOptions,
  paymentTypeOptions,
  paymentStatusOptions,
  _paymentStatusOptions,
} from '../utils/constants/billing';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input;
const { Option } = Select;

const today = getBrowserTodayMoment();

const getFields: any = ({ crmPayment }) => [
  {
    name: 'type',
    label: 'Payment type',
    formItemProps: {
      initialValue: _paymentTypeOptions[crmPayment.type],
      rules: [
        {
          required: true,
          message: 'Please select the valid type',
        },
      ],
    },
    span: 8,
    component: (
      <Select size="large" style={{ width: '100%' }}>
        {paymentTypeOptions.map(({ value, label }) => (
          <Option key={`payment-type-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'subscriptionId',
    label: 'Subscription ID',
    formItemProps: {
      initialValue: crmPayment.subscriptionId,
    },
    span: 8,
    component: <Input size="large" />,
  },
  {
    name: 'paymentMethod',
    label: 'Payment method',
    formItemProps: {
      initialValue: _paymentMethodOptions[crmPayment.paymentMethod],
      rules: [
        {
          required: true,
          message: 'Please select the valid method',
        },
      ],
    },
    span: 8,
    component: (
      <Select size="large" style={{ width: '100%' }}>
        {paymentMethodOptions.map(({ value, label }) => (
          <Option key={`payment-method-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'productFee',
    label: 'Product fee($)',
    formItemProps: {
      initialValue: crmPayment.productFee,
      rules: [
        {
          required: true,
          message: 'Please enter the valid product fee',
        },
      ],
    },
    span: 12,
    component: <Input size="large" addonBefore="$" />,
  },
  {
    name: 'serviceFee',
    label: 'Service fee($)',
    formItemProps: {
      initialValue: crmPayment.serviceFee,
      rules: [
        {
          required: true,
          message: 'Please enter the valid service fee',
        },
      ],
    },
    span: 12,
    component: <Input size="large" addonBefore="$" />,
  },
  {
    name: 'productFeeDescription',
    label: 'Product fee Description',
    formItemProps: {
      initialValue: crmPayment.productFeeDescription,
    },
    span: 12,
    component: <TextArea rows={2} />,
  },
  {
    name: 'serviceFeeDescription',
    label: 'Service fee Description',
    formItemProps: {
      initialValue: crmPayment.serviceFeeDescription,
    },
    span: 12,
    component: <TextArea rows={2} />,
  },
  {
    name: 'serviceStartDt',
    label: 'Period start',
    formItemProps: {
      initialValue: moment(crmPayment.serviceStartDt),
    },
    span: 12,
    component: <DatePicker size="large" format={dateTimePattern.date} />,
  },
  {
    name: 'serviceEndDt',
    label: 'Period end',
    formItemProps: {
      initialValue: moment(crmPayment.serviceEndDt),
    },
    span: 12,
    component: <DatePicker size="large" format={dateTimePattern.date} />,
  },
  {
    name: 'invoiceDt',
    label: (
      <span>
        Invoice Date <BrowserTimeZoneText />
      </span>
    ),
    formItemProps: {
      initialValue: moment.tz(crmPayment.invoiceDt ? crmPayment.invoiceDt : today, getBrowserTimeZoneString()),
    },
    span: 12,
    component: <DatePicker size="large" format={dateTimePattern.date} />,
  },
  {
    name: 'chargedDt',
    label: (
      <span>
        Charged Date <BrowserTimeZoneText />
      </span>
    ),
    formItemProps: {
      initialValue: moment.tz(crmPayment.chargedDt ? crmPayment.chargedDt : today, getBrowserTimeZoneString()),
    },
    span: 12,
    component: <DatePicker size="large" format={dateTimePattern.date} />,
  },
  {
    name: 'status',
    label: 'Status',
    formItemProps: {
      initialValue: crmPayment ? _paymentStatusOptions[crmPayment.status] : paymentStatusOptions[0].value,
    },
    span: 12,
    component: (
      <Select size="large">
        {paymentStatusOptions.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'isActive',
    label: 'Active',
    formItemProps: {
      valuePropName: 'checked',
      initialValue: crmPayment.isActive,
    },
    span: 12,
    component: <Switch />,
  },
];

type Props = {
  crmPayment: CrmPayment;
  successCallback: (payload?: any) => void;
};

export const PaymentUpdateDialog: React.FC<Props> = ({ crmPayment, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const {
            status,
            invoiceDt,
            chargedDt,
            isActive,
            type,
            subscriptionId,
            paymentMethod,
            productFee,
            serviceFee,
            productFeeDescription,
            serviceFeeDescription,
            serviceStartDt,
            serviceEndDt,
          } = values;
          const invoice_date = invoiceDt && invoiceDt.format(dateTimePattern.date);
          const charge_date = chargedDt && chargedDt.format(dateTimePattern.date);

          updatePaymentRequest({
            crmPaymentId: crmPayment.id,
            status,
            invoiceDt: invoice_date,
            chargedDt: charge_date,
            isActive,
            type,
            subscriptionId: parseInt(subscriptionId),
            paymentMethod,
            productFee,
            serviceFee,
            productFeeDescription,
            serviceFeeDescription,
            serviceStartDt: serviceStartDt.format('YYYY-MM-DD'),
            serviceEndDt: serviceEndDt.format('YYYY-MM-DD'),
          })
            .then((_) => {
              setLoading(false);
              setVisible(false);
              successCallback && successCallback();
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button icon={<EditOutlined />} onClick={showModal}>
        Update
      </Button>
      <Modal
        open={visible}
        title={`Update payment #${crmPayment.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Update
          </Button>,
        ]}
      >
        <DynamicForm fields={getFields({ crmPayment })} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
