import React, { useState } from 'react';
import { Moment } from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography, Layout } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { dateTimePattern } from '../../utils/date';
import { getDownloadCorporateDashboardOpportunityListRequest } from '../../api/export';
import { PageTitle } from '../../components/ui/PageTitle';

const { Paragraph } = Typography;

enum ExportEnum {
  OPPORTUNITY_LIST = 'OPPORTUNITY_LIST',
}

const getDateSources = ({ setForms }) => [
  {
    key: ExportEnum.OPPORTUNITY_LIST,
    title: <>Opportunity List</>,
    content: (
      <>
        <Paragraph>
          The opportunity list.
          <br />
          - stage_name: Closed Won
          <br />- type: New, Upsell, Downsell, Churn
        </Paragraph>
      </>
    ),
  },
];

type CardBoxProps = {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
  loading: boolean;
  handleDownload: () => void;
};

type Props = {};

const CardBox: React.FC<CardBoxProps> = React.memo(({ id, title, content, loading, handleDownload }) => (
  <Col span={24} md={12} lg={12}>
    <Card
      title={title}
      actions={[
        <Button
          key={`${id}-download`}
          type="primary"
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={handleDownload}
        >
          Download
        </Button>,
      ]}
      bodyStyle={{ minHeight: '252px' }}
      style={{ marginBottom: '16px' }}
    >
      {content}
    </Card>
  </Col>
));

export const CorporateDashboardDataExports: React.FC<Props> = React.memo(() => {
  const forms = {
    OPPORTUNITY_LIST: {} as FormInstance,
  };

  const [loadings, setLoadings] = useState({
    OPPORTUNITY_LIST: false,
  });

  function setLoading({ key, value }) {
    const nextLoading = loadings;
    nextLoading[`${key}`] = value;
    setLoadings(nextLoading);
  }

  const setForms = ({ key }) => (ref) => {
    forms[key] = ref;
  };

  function download({ response, filename, dates = [] as Array<Moment> }) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset="utf-8"' }));
    const link = document.createElement('a');
    const dateRange =
      dates.length > 0 ? `_${dates[0].format(dateTimePattern.date)}_${dates[1].format(dateTimePattern.date)}` : '';
    link.href = url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${filename}${dateRange}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const handleDownloads = {
    OPPORTUNITY_LIST: async function () {
      setLoading({ key: ExportEnum.OPPORTUNITY_LIST, value: true });
      try {
        const response = await getDownloadCorporateDashboardOpportunityListRequest();
        download({ response, filename: 'opportunity_list' });
        setLoading({ key: ExportEnum.OPPORTUNITY_LIST, value: false });
      } catch (error) {
        setLoading({ key: ExportEnum.OPPORTUNITY_LIST, value: false });
        error && error.response && console.error(error.response);
      }
    },
  };

  return (
    <Layout>
      <PageTitle
        title="Corporate Dashboard Data exports"
        subTitle="Requires CORPORATE_DASHBOARD_DATA_EXPORT permission."
      />
      <Layout.Content>
        <Row gutter={16}>
          {getDateSources({ setForms }).map(({ key, title, content }, index) => (
            <CardBox
              key={`${key}=${index}`}
              id={key}
              title={title}
              content={content}
              loading={loadings[key]}
              handleDownload={handleDownloads[key]}
            />
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
});
