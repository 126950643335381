import React from 'react'
import axios from 'axios'
import {Link, withRouter} from 'react-router-dom'
import {API_DOMAIN, REGIONS} from '../App'
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import Input from "@material-ui/core/Input/Input";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

class Sync extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    // Feature : Sync
    this.setState({
      syncedApplication: null,
      syncedOrganization: null,
      syncedOrganizationMember: null,
    })
    this.handleSyncApplicataion = this.handleSyncApplicataion.bind(this)
    this.handleSyncOrganization = this.handleSyncOrganization.bind(this)
    this.handleSyncOrganizationMember = this.handleSyncOrganizationMember.bind(this)

    this.setState({
      syncedApplication: null,
      syncedOrganization: null,
      syncedOrganizationMember: null,
    })
  }

  // Feature : Sync
  handleSyncApplicataion(e) {
    e.preventDefault();

    axios.post(`${API_DOMAIN}/api/sync/application/`, {
      appId: e.target.appId.value,
      region: this.state.region,//e.target.region.value,
    }, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({syncedApplication : response.data})
    }).catch((error) => {
      console.log(error)
    })
  }

  // Feature : Sync
  handleSyncOrganization(e) {
    e.preventDefault();

    axios.post(`${API_DOMAIN}/api/sync/organization/`, {
      organizationId: e.target.organizationId.value,
    }, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({syncedOrganization: response.data})
    }).catch((error) => {
      console.log(error)
    })
  }

  // Feature : Sync
  handleSyncOrganizationMember(e) {
    e.preventDefault();

    axios.post(`${API_DOMAIN}/api/sync/organization_member/`, {
      organizationMemberId: e.target.organizationMemberId.value,
    }, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({syncedOrganizationMember: response.data})
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const {
      // Feature : Sync
      syncedApplication,
      syncedOrganization,
      syncedOrganizationMember,
    } = this.state

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid>
            <div style={{overflowX: 'auto'}}>
              <Card>
                <CardHeader title="Sync" />
                <CardContent>
                  <h4 className="card-subtitle mb-2 text-muted">Application</h4>
                  <form className="form-inline" onSubmit={this.handleSyncApplicataion}>
                    <TextField type="text" name="appId" label="App ID" style={{paddingRight: '0.5em'}}/>
                    <FormControl style={{paddingRight: '0.5em', minWidth:'8em'}}>
                      <InputLabel htmlFor="custom-select-id">Region</InputLabel>
                      <Select
                        defaultValue='ap-1'
                        value={String(this.state.region)}
                        onChange={e => this.setState({ region: e.target.value }, () => {console.log(this.state)})}
                        input={<Input id="custom-select-id" />}
                      >
                        { Object.keys(REGIONS).map(region => <MenuItem key={region} value={region} >{region}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <Button type="submit" value="syncApplication" className="btn btn-primary mb-2" color="primary" variant="outlined" >Sync</Button>
                    <span>{syncedApplication ? 'The application is synced at: ' + syncedApplication.syncedAt : ''}</span>
                  </form>

                  <h4 className="card-subtitle mb-2 text-muted">Organization</h4>
                  <form className="form-inline" onSubmit={this.handleSyncOrganization}>
                    <TextField type="text" name="organizationId" label="Organization ID" style={{paddingRight: '0.5em'}}/>
                    <Button type="submit" value="syncOrganization" className="btn btn-primary mb-2" color="primary" variant="outlined" >Sync</Button>
                    <span>{syncedOrganization ? 'The organization is synced at: ' + syncedOrganization.syncedAt : ''}</span>
                  </form>

                  <h4 className="card-subtitle mb-2 text-muted">Organization Member ID</h4>
                  <form className="form-inline" onSubmit={this.handleSyncOrganizationMember}>
                    <TextField type="text" name="organizationMemberId" label="Organization Member ID" style={{paddingRight: '0.5em', minWidth: '13em'}}/>
                    <Button type="submit" value="syncOrganizationMember" className="btn btn-primary mb-2" color="primary" variant="outlined" >Sync</Button>
                    <span>{syncedOrganizationMember ? 'The organization member is synced at: ' + syncedOrganizationMember.syncedAt : ''}</span>
                  </form>
                </CardContent>
              </Card>
              {/*Feature: Sync*/}
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Sync)
