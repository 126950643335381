import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getApplicationPlanRequestRequest, getIsReviewerRequest } from '../../api/applicationPlanRequest';
import { PageTitle } from '../../components/ui/PageTitle';
import { ApplicationPlanRequestInfo } from '../../components/ApplicationPlanRequestInfo';
import { Spinner } from '../../components/ui/Spinner';
import { Layout } from 'antd';

interface MatchParams {
  applicationPlanRequestId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const ApplicationPlanRequest = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { applicationPlanRequestId } = match.params;
    const [applicationPlanRequest, setApplicationPlanRequest] = useState({} as ApplicationPlanRequest);
    const [isReviewer, setIsReviewer] = useState(false);

    function getApplicationPlanRequest() {
      getApplicationPlanRequestRequest(applicationPlanRequestId)
        .then((response) => {
          setApplicationPlanRequest(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getIsReviewer() {
      getIsReviewerRequest()
        .then((response) => {
          setIsReviewer(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function successCallback(responseData) {
      setApplicationPlanRequest(responseData);
    }

    useEffect(() => {
      getApplicationPlanRequest();
      getIsReviewer();
    }, []);

    return !applicationPlanRequest || !applicationPlanRequest.id ? (
      <Spinner />
    ) : (
      <Layout>
        <PageTitle
          title="Application Plan Request Detail"
          subTitle={`#${(applicationPlanRequest.id && applicationPlanRequest.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <ApplicationPlanRequestInfo
            applicationPlanRequest={applicationPlanRequest}
            isReviewer={isReviewer}
            successCallback={successCallback}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
