import React, { useEffect, useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { getUsersRequest, createUserPermissionRequest } from '../api/user';
import { getPermissionsRequest } from '../api/permissions';
const { Option } = Select;

type Props = {
  user?: InsightMember;
  successCallback: (payload?: any) => void;
};

export const UserPermissionDialog: React.FC<Props> = ({ user, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userOptions, setUserOptions] = useState<Array<InsightMember>>([]);
  const [permissionOptions, setPermissionOptions] = useState<Array<Permission>>([]);
  const [userID, setUserID] = useState(-1);
  const [permissionName, setPermissionName] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchPermissions();
  }, [user]);

  const fetchUsers = (query?: string) => {
    let data = { limit: 200, offset: 0, q: query };
    getUsersRequest(data).then((response) => {
      setUserOptions(response.data.results);
      if (user) setUserID(user.id);
    });
  };

  const fetchPermissions = () => {
    getPermissionsRequest({ limit: 100, offset: 0 }).then((response) => {
      setPermissionOptions(response.data.results);
    });
  };

  function onChangeSelectUser(value) {
    setUserID(value);
  }

  function onChangeSelectPermission(value) {
    setPermissionName(value);
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleConfirm() {
    setLoading(true);
    createUserPermissionRequest({
      user_id: userID,
      permission_name: permissionName,
    })
      .then((response) => {
        setLoading(false);
        setVisible(false);
        successCallback(response.data);
        setUserID(-1);
        setPermissionName('');
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function userSelectFilterOption(input, option) {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }

  const defaultOption = user ? '<Option key="originalUserID" value={userID}>{user.username}</Option>' : '';

  return (
    <React.Fragment>
      <Button key="addPermission" type="default" onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Add Permission
      </Button>

      <Modal
        open={visible}
        title="Grant permission"
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="confirm" type="primary" loading={loading} onClick={handleConfirm}>
            Confirm
          </Button>,
        ]}
      >
        <b>User</b>
        <br />
        <Select
          showSearch={true}
          size="large"
          style={{ width: 400 }}
          onSearch={fetchUsers}
          onChange={onChangeSelectUser}
          defaultValue={userID}
          filterOption={userSelectFilterOption}
        >
          {defaultOption}
          {userOptions.map((userData) => (
            <Option key={userData.id} value={userData.id}>
              {userData.username}
            </Option>
          ))}
        </Select>

        <br />

        <b>Permission</b>
        <br />
        <Select
          showSearch={true}
          size="large"
          style={{ width: 400 }}
          onChange={onChangeSelectPermission}
          defaultValue={permissionName}
        >
          {permissionOptions.map((permission) => (
            <Option key={permission.id} value={permission.name}>
              {permission.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </React.Fragment>
  );
};
