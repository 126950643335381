import * as React from 'react';
import { Row, Col, Card, List, Skeleton } from 'antd';
import { ListProps } from 'antd/lib/list';
import { CardProps } from 'antd/lib/card';
import styled from 'styled-components';

type InfoCardProps = {
  title: CardProps['title'];
  dataSource: ListProps<any>['dataSource'];
  minHeight: string;
};

type Props = {
  titles?: Array<InfoCardProps['title']>;
  dataSources: Array<InfoCardProps['dataSource']>;
  minHeight: InfoCardProps['minHeight'];
  rowStyle?: React.CSSProperties;
};

const InfoCardsWrapper = styled.div`
  padding: 16px;
  background: #f0f2f5;
  margin-bottom: 24px;
`;

export const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value ? item.value : '-'}
        </Col>
      </Row>
    </List.Item>
  );
};

export const InfoCard: React.FC<InfoCardProps> = React.memo(({ title = '', dataSource, minHeight }) => (
  <Col span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
    <Card
      title={title || null}
      size="small"
      style={{
        minHeight,
      }}
    >
      <Skeleton loading={!dataSource || dataSource.length === 0} active={true}>
        <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
      </Skeleton>
    </Card>
  </Col>
));

export const InfoCards: React.FC<Props> = React.memo(
  ({ titles = [], dataSources, minHeight = '280px', rowStyle = {}, children }) => (
    <InfoCardsWrapper>
      <Row gutter={16} style={rowStyle}>
        {dataSources.map((dataSource, index) => (
          <InfoCard
            key={`${index}`}
            title={titles !== [] ? titles[index] : null}
            dataSource={dataSource}
            minHeight={minHeight}
          />
        ))}
        {children}
      </Row>
    </InfoCardsWrapper>
  ),
);
