import React, { useEffect, useRef, useState } from 'react';
import { Switch, Input, DatePicker, Button, Typography, Alert, Select, Popconfirm } from 'antd';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment-timezone';
import exactMath from 'exact-math';

import { useHistory } from 'react-router-dom';
import { DynamicForm } from '../ui/DynamicForm';
import { FormTitle } from '../../containers/billingV2/OrganizationBillingSubscriptionCreation';
import { dateTimePattern } from '../../utils/date';
import { createBillingSubscriptionRequestRequest, getBillingSubscriptionPredefinedPlans } from '../../api/billing';
import { keysToSnake } from '../../utils/data';

const { Option } = Select;
const { Text } = Typography;

interface Plan {
  key: string;
  value: string;
}

const planValueToPredefiendPlanNames = {
  free_trial: 'freeTrial',
  plan_a_5k: 'planA_5k',
  plan_a_10k: 'planA_10k',
  plan_a_25k: 'planA_25k',
  plan_a_50k: 'planA_50k',
  plan_a_100k: 'planA_100k',
  plan_b_5k: 'planB_5k',
  plan_b_10k: 'planB_10k',
  plan_b_25k: 'planB_25k',
  plan_b_50k: 'planB_50k',
  plan_b_100k: 'planB_100k',
  enterprise: 'enterprise',
  aws_marketplace: 'aws_marketplace',
  ai_chatbot_a: 'aiChatbotA',
  ai_chatbot_b: 'aiChatbotB',
  support_l0: 'supportL0',
  support_l1: 'supportL1',
  support_l2: 'supportL2',
  support_l3: 'supportL3',
  support_l4: 'supportL4',
  support_gold: 'supportGold',
  support_silver: 'supportSilver',
  support_platinum: 'supportPlatinum',
};

const uploadFileSizeLimitOptions = [
  { value: 25, label: '25 MB' },
  { value: 50, label: '50 MB' },
  { value: 100, label: '100 MB' },
  { value: 200, label: '200 MB' },
  { value: 256, label: '256 MB' },
];

export const ChatSubscriptionForm = ({
  crmOrganization,
  product,
  plan,
  subscriptionRequest,
}: {
  crmOrganization: CrmOrganization | undefined;
  product: string;
  plan: Plan;
  subscriptionRequest: SubscriptionRequest | undefined;
}) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [isFormValid, setIsFormValid] = useState(true);
  const [formErrorText, setFormErrorText] = useState('');
  const [changedFields, setChangedFields] = useState([] as Array<string>);
  const [fetchedPredefiendPlans, setFetchedPredefiendPlans] = useState(null);

  useEffect(() => {
    setChangedFields([]);
    if (subscriptionRequest) {
      formRef.current?.setFieldsValue(convertSubscriptionRequestBodyToFormValues(subscriptionRequest));
      setChangedFields(Object.keys(formRef.current?.getFieldsValue()));
    } else {
      getBillingSubscriptionPredefinedPlans().then((response) => {
        setFetchedPredefiendPlans(response.data);
      });
    }
  }, [product, plan, subscriptionRequest]);

  useEffect(() => {
    if (fetchedPredefiendPlans && !subscriptionRequest) {
      formRef.current?.setFieldsValue(
        convertSubscriptionPlanToFormValues(fetchedPredefiendPlans![planValueToPredefiendPlanNames[plan.value]], {}),
      );
    }
  }, [product, plan, fetchedPredefiendPlans]);

  const convertSubscriptionPlanToFormValues = (_subscriptionPlan, base = {}) => {
    const formValue = base;
    const subscriptionPlan = keysToSnake(_subscriptionPlan);

    if (subscriptionPlan) {
      const chat_subscription_plan = keysToSnake(subscriptionPlan.plan);
      formValue['plan_value'] = exactMath.div(chat_subscription_plan.base_fee.cost_per_unit, 100);
      delete chat_subscription_plan.base_fee;

      for (let [key, value] of Object.entries(chat_subscription_plan) as any) {
        if (key === 'supergroup' || key === 'speed_limit_tier' || key === 'poll') {
          formValue[`${key}_tier`] = value.tier;
        }
        formValue[`${key}_enabled`] = value.enabled;
        formValue[`${key}_cost_per_unit`] = exactMath.div(value.cost_per_unit, 100);
        formValue[`${key}_purchased_units`] = value.purchased_units;
        formValue[`${key}_hard_limit`] = value.hard_limit;

        if (
          formValue[`${key}_enabled`] === false &&
          formValue[`${key}_purchased_units`] === 0 &&
          formValue[`${key}_hard_limit`] === 0 &&
          [
            'announcement',
            'auto_thumbnail',
            'image_moderation',
            'translation_tools',
            'message_search_index',
            'message_search_query',
          ].includes(key)
        ) {
          formValue[`${key}_purchased_units`] = null;
          formValue[`${key}_hard_limit`] = null;
        }

        if (
          formValue[`${key}_enabled`] === false &&
          [
            'advanced_moderator_seat',
            'moderation_rule_triggered_event',
            'hive_integration',
            'moderator_messages',
            'moderation_rule_creation_limit',
          ].includes(key)
        ) {
          formValue[`${key}_purchased_units`] = null;
          formValue[`${key}_hard_limit`] = null;
        }
      }

      const ai_chatbot_subscription_plan = keysToSnake(subscriptionPlan.ai_chatbot_plan);
      for (let [key, value] of Object.entries(ai_chatbot_subscription_plan) as any) {
        formValue[`${key}_enabled`] = value.enabled;
        formValue[`${key}_cost_per_unit`] = exactMath.div(value.cost_per_unit, 100);
        formValue[`${key}_purchased_units`] = value.purchased_units;
        formValue[`${key}_hard_limit`] = value.hard_limit;
        if (key === 'ai_chatbot_model') {
          formValue[`${key}_tier`] = value.tier;
        }
      }
      setChangedFields(Object.keys(formRef.current?.getFieldsValue())); // To re-render the form
    }

    return formValue;
  };

  const convertSubscriptionRequestBodyToFormValues = (_subscriptionRequest) => {
    let formValue = {};
    const subscriptionRequest = JSON.parse(_subscriptionRequest.requestBody);

    formValue['subscription_billing_cycle_months'] = subscriptionRequest.billing_cycle_months;
    formValue['subscription_start_date'] = moment(subscriptionRequest.start_date);
    formValue['subscription_end_date'] = subscriptionRequest.end_date
      ? moment(subscriptionRequest.end_date)
      : undefined;
    formValue['subscription_auto_renewal'] = subscriptionRequest.auto_renewal;
    formValue['subscription_auto_renewal_months'] = subscriptionRequest.auto_renewal_months;

    return convertSubscriptionPlanToFormValues(subscriptionRequest, formValue);
  };

  const BillingRules: React.FC = () => {
    const subscriptionFutureText = (formValues) => {
      if (!formValues?.subscription_end_date) {
        return (
          <li>
            The subscription will run &nbsp;
            <Text code>perpetually</Text>
          </li>
        );
      } else if (
        formValues?.subscription_end_date &&
        !formValues?.subscription_auto_renewal &&
        !formValues?.subscription_auto_renewal_months
      ) {
        return (
          <li>
            The subscription will stop on &nbsp;
            <Text code>
              {formValues?.subscription_end_date
                ? `${moment(formValues?.subscription_end_date).utc().format(dateTimePattern.date)}`
                : 'N/A'}
            </Text>
          </li>
        );
      } else if (
        formValues?.subscription_end_date &&
        formValues?.subscription_auto_renewal &&
        formValues?.subscription_auto_renewal_months
      ) {
        return (
          <>
            <li>
              The subscription will stop on &nbsp;
              <Text code>
                {formValues?.subscription_end_date
                  ? `${moment(formValues?.subscription_end_date).utc().format(dateTimePattern.date)}`
                  : 'N/A'}
              </Text>
            </li>
          </>
        );
      } else {
        return <></>;
      }
    };

    const autoRenewalNotice = (formValues) => {
      if (
        formValues?.subscription_end_date &&
        formValues?.subscription_auto_renewal &&
        formValues?.subscription_auto_renewal_months
      ) {
        return (
          <>
            <li>
              Once the subscription ended, a new subscription (with exact features) will be created with a minimum
              contract period of <Text code>{`${formValues?.subscription_auto_renewal_months} Month`}</Text>
            </li>
            <li>
              Customer will have the option to stop the renewal if they informed us 15 days before the subscription end
              date
            </li>
          </>
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        <ul style={{ fontSize: '15px', lineHeight: '30px' }}>
          <li>
            The subscription will start on &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['subscription_start_date']
                ? `${moment(formRef.current?.getFieldsValue()['subscription_start_date'])
                    .utc()
                    .format(dateTimePattern.date)}`
                : 'N/A'}
            </Text>
          </li>
          {subscriptionFutureText(formRef.current?.getFieldsValue())}
          <li>
            The customer will be billed for &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['plan_value']
                ? `$${formRef.current?.getFieldsValue()['plan_value']}`
                : 'N/A'}
            </Text>
            &nbsp; every &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['subscription_billing_cycle_months']
                ? `${formRef.current?.getFieldsValue()['subscription_billing_cycle_months']} Month`
                : 'N/A'}
            </Text>
          </li>
          <li>
            The customer will be billed via &nbsp;
            <Text code>{crmOrganization?.paymentMethod ? crmOrganization?.paymentMethod : 'N/A'}</Text>
          </li>
          {autoRenewalNotice(formRef.current?.getFieldsValue())}
        </ul>
      </>
    );
  };

  const fields: Field[] = [
    {
      name: 'Billing',
      label: 'Billing',
      formItemProps: {},
      component: {},
      fields: [
        {
          name: 'Billing Payment',
          label: 'Billing Payment',
          description: 'Billing cycle indicates how often we charge the customer',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'plan_value',
              label: 'Plan Value',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'subscription_billing_cycle_months',
              label: 'Billing Cycle Months',
              formItemProps: {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 8,
              component: <Input size="large" addonAfter="Months" />,
            },
          ],
        },
        {
          name: 'Billing Period',
          label: 'Billing Period',
          description:
            'You can automatically setup a renewal when the subscription ends by enabling the "billing renewal" feature',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'subscription_start_date',
              label: 'Subscription Start Date (UTC, 00:00:00)',
              span: 8,
              formItemProps: {
                initialValue: moment().startOf('day').utc(true),
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              component: (
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  format={dateTimePattern.date}
                  onChange={(date) => {
                    if (date) {
                      const convertedDate = moment([date.year(), date.month(), date.date(), 0, 0, 0]).utc(true);
                      formRef.current?.setFieldsValue({ subscription_start_date: convertedDate });
                    }
                  }}
                />
              ),
            },
            {
              name: 'subscription_end_date',
              label: 'Subscription End Date (UTC, 00:00:00)',
              span: 8,
              formItemProps: {},
              component: (
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  format={dateTimePattern.date}
                  onChange={(date) => {
                    if (date) {
                      const convertedDate = moment([date.year(), date.month(), date.date(), 0, 0, 0]).utc(true);
                      formRef.current?.setFieldsValue({ subscription_end_date: convertedDate });
                    }
                  }}
                />
              ),
            },
          ],
        },
        {
          name: 'Auto Renewal',
          label: 'Auto Renewal',
          description: 'Auto renewal only available if the end date is stated',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'subscription_auto_renewal',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  defaultChecked={false}
                  disabled={
                    !formRef.current?.getFieldsValue().hasOwnProperty('subscription_end_date') &&
                    !formRef.current?.getFieldsValue()['subscription_end_date']
                  }
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'subscription_auto_renewal_months',
              label: 'Months',
              formItemProps: {
                initialValue: 0,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['subscription_auto_renewal'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('subscription_auto_renewal')
                      ? !formRef.current?.getFieldsValue()['subscription_auto_renewal']
                      : true
                  }
                  placeholder="0"
                  addonAfter="Months"
                />
              ),
            },
          ],
        },
        {
          name: 'Billing Rules',
          label: 'Billing Rules',
          description: '',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: '',
              label: '',
              formItemProps: {
                rules: [],
              },
              component: <BillingRules />,
            },
          ],
        },
      ],
    },
    {
      name: 'Core features',
      label: 'Core features',
      formItemProps: {},
      component: {},
      collapsible: true,
      isCollapsed: false,
      fields: [
        {
          name: 'MAU',
          label: 'MAU',
          formItemProps: {},
          component: (
            <FormTitle
              style={{
                fontSize: '14px',
                marginBottom: '2px',
                borderBottom: '0',
              }}
              title="MAU"
            />
          ),
          fields: [
            {
              name: 'mau_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'mau_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('MAU purchased units must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ mau_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'mau_hard_limit',
              label: 'Hard Limit (soft-capped)',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('MAU hard limit must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },

        {
          name: 'Peak Connection',
          label: 'Peak Connection',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'pc_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'pc_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('PC purchased units must be greater than 0');
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ pc_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'pc_hard_limit',
              label: 'Hard Limit (soft-capped)',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('PC hard limit must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },

        {
          name: 'File Storage',
          label: 'File Storage',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'file_storage_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'file_storage_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('File storage purchased units must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  addonAfter="GB"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ file_storage_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'file_storage_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('File storage hard limit must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonAfter="GB" disabled={false} />,
            },
          ],
        },
        {
          name: 'Upload Traffic',
          label: 'Upload Traffic',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'upload_traffic_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'upload_traffic_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('upload traffic purchased units must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  addonAfter="GB"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ upload_traffic_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'upload_traffic_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('upload traffic hard limit must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonAfter="GB" disabled={false} />,
            },
          ],
        },
        {
          name: 'File upload size limit',
          label: 'File upload size limit',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'file_upload_size_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      formRef.current?.setFieldsValue({
                        file_upload_size_limit_hard_limit: value,
                      });
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Select size="large">
                  {uploadFileSizeLimitOptions.map(({ value, label }) => (
                    <Option key={`upload-file-size-limit-${value}}`} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              name: 'file_upload_size_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonAfter="MB" disabled={true} />,
            },
          ],
        },
        {
          name: 'Bot Interface',
          label: 'Bot Interface',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'bot_interface_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('bot interface purchased units must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ bot_interface_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'bot_interface_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (!value || value <= 0) {
                        return callback('bot interface hard limit must be greater than 0');
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: 'Message Retention Period',
          label: 'Message Retention Period',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'message_retention_period_purchased_units',
              label: 'Months',
              formItemProps: {
                initialValue: 6,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
          ],
        },
        {
          name: 'Speed Limit',
          label: 'Speed Limit',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'speed_limit_tier_tier',
              label: 'tier',
              formItemProps: {
                initialValue: 'enterprise',
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" disabled={true} />,
            },
          ],
        },
      ],
    },
    {
      name: 'Premium features',
      label: 'Premium features',
      formItemProps: {},
      component: {},
      collapsible: true,
      isCollapsed: false,
      fields: [
        {
          name: 'Announcement',
          label: 'Announcement',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'announcement_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          announcement_cost_per_unit: 0.002,
                          announcement_purchased_units: null,
                          announcement_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('announcement_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'announcement_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.002,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['announcement_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('announcement_enabled')
                      ? !formRef.current?.getFieldsValue()['announcement_enabled']
                      : true
                  }
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'announcement_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['announcement_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('announcement_enabled')) {
                        if (!value || value <= 0) {
                          return callback('announcement purchased units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('announcement_enabled')
                      ? !formRef.current?.getFieldsValue()['announcement_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ announcement_hard_limit: inputValue * 5 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'announcement_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['announcement_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('announcement_enabled')) {
                        if (!value || value <= 0) {
                          return callback('announcement hard limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('announcement_enabled')
                      ? !formRef.current?.getFieldsValue()['announcement_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Auto Thumbnail',
          label: 'Auto Thumbnail',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'auto_thumbnail_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          auto_thumbnail_cost_per_unit: 0.001,
                          auto_thumbnail_purchased_units: null,
                          auto_thumbnail_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('auto_thumbnail_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'auto_thumbnail_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.001,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['auto_thumbnail_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('auto_thumbnail_enabled')
                      ? !formRef.current?.getFieldsValue()['auto_thumbnail_enabled']
                      : true
                  }
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'auto_thumbnail_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['auto_thumbnail_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('auto_thumbnail_enabled')) {
                        if (!value || value <= 0) {
                          return callback('auto_thumbnail_purchased_units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('auto_thumbnail_enabled')
                      ? !formRef.current?.getFieldsValue()['auto_thumbnail_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ auto_thumbnail_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'auto_thumbnail_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['auto_thumbnail_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('auto_thumbnail_enabled')) {
                        if (!value || value <= 0) {
                          return callback('auto_thumbnail_hard_limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('auto_thumbnail_enabled')
                      ? !formRef.current?.getFieldsValue()['auto_thumbnail_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Image Moderation',
          label: 'Image Moderation',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'image_moderation_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          image_moderation_cost_per_unit: 0.0015,
                          image_moderation_purchased_units: null,
                          image_moderation_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('image_moderation_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'image_moderation_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.0015,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['image_moderation_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('image_moderation_enabled')
                      ? !formRef.current?.getFieldsValue()['image_moderation_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'image_moderation_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['image_moderation_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('image_moderation_enabled')) {
                        if (!value || value <= 0) {
                          return callback('image_moderation_purchased_units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('image_moderation_enabled')
                      ? !formRef.current?.getFieldsValue()['image_moderation_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ image_moderation_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'image_moderation_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['image_moderation_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('image_moderation_enabled')) {
                        if (!value || value <= 0) {
                          return callback('image_moderation_hard_limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('image_moderation_enabled')
                      ? !formRef.current?.getFieldsValue()['image_moderation_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Translation Tools',
          label: 'Translation Tools',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'translation_tools_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          translation_tools_cost_per_unit: 0.00002,
                          translation_tools_purchased_units: null,
                          translation_tools_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('translation_tools_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'translation_tools_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.00002,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['translation_tools_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('translation_tools_enabled')
                      ? !formRef.current?.getFieldsValue()['translation_tools_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'translation_tools_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['translation_tools_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('translation_tools_enabled')) {
                        if (!value || value <= 0) {
                          return callback('translation_tools_purchased_units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('translation_tools_enabled')
                      ? !formRef.current?.getFieldsValue()['translation_tools_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ translation_tools_hard_limit: inputValue * 3 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'translation_tools_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['translation_tools_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('translation_tools_enabled')) {
                        if (!value || value <= 0) {
                          return callback('translation_tools_hard_limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('translation_tools_enabled')
                      ? !formRef.current?.getFieldsValue()['translation_tools_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Message Search Index',
          label: 'Message Search Index',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'message_search_index_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          message_search_index_cost_per_unit: 0.00005,
                          message_search_index_purchased_units: null,
                          message_search_index_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('message_search_index_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'message_search_index_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.00005,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_index_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_index_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_index_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'message_search_index_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_index_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('message_search_index_enabled')) {
                        if (!value || value <= 0) {
                          return callback('message_search_index_purchased_units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_index_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_index_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ message_search_index_hard_limit: inputValue * 5 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'message_search_index_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_index_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('message_search_index_enabled')) {
                        if (!value || value <= 0) {
                          return callback('message_search_index_hard_limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_index_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_index_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Message Search Query',
          label: 'Message Search Query',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'message_search_query_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          message_search_query_cost_per_unit: 0.0002,
                          message_search_query_purchased_units: null,
                          message_search_query_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('message_search_query_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'message_search_query_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: 0.0002,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_query_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_query_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_query_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'message_search_query_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_query_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('message_search_query_enabled')) {
                        if (!value || value <= 0) {
                          return callback('message_search_query_purchased_units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_query_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_query_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ message_search_query_hard_limit: inputValue * 5 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'message_search_query_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: null,
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['message_search_query_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('message_search_query_enabled')) {
                        if (!value || value <= 0) {
                          return callback('message_search_query_hard_limit must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('message_search_query_enabled')
                      ? !formRef.current?.getFieldsValue()['message_search_query_enabled']
                      : true
                  }
                  size="large"
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Supergroup',
          label: 'Supergroup',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'supergroup_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('supergroup_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'supergroup_tier',
              label: 'Tier',
              formItemProps: {
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['supergroup_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (() => {
                const tiers = [
                  { key: 'none', value: '0' },
                  { key: '2k', value: '2000' },
                  { key: '10k', value: '10000' },
                  { key: '20k', value: '20000' },
                ];
                return (
                  <Select
                    defaultValue={tiers[0].value}
                    size="large"
                    style={{ width: '100%', marginTop: '10px' }}
                    onChange={(value) => {
                      formRef.current?.setFieldsValue({ supergroup_tier: value });
                    }}
                    disabled={
                      formRef.current?.getFieldsValue().hasOwnProperty('supergroup_enabled')
                        ? !formRef.current?.getFieldsValue()['supergroup_enabled']
                        : true
                    }
                  >
                    {tiers.map((opt) => (
                      <Option key={opt.key} value={opt.value}>
                        {opt.key}
                      </Option>
                    ))}
                  </Select>
                );
              })(),
            },
          ],
        },
        {
          name: 'Domain Filter',
          label: 'Domain Filter',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'domain_filter_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('domain_filter_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Profanity Filter',
          label: 'Profanity Filter',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'profanity_filter_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('profanity_filter_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Moderation Tools',
          label: 'Moderation Tools',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'moderation_tools_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('moderation_tools_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Data export',
          label: 'Data export',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'data_export_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('data_export_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Desk',
          label: 'Desk',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'desk_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('desk_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Delivery Receipt',
          label: 'Delivery Receipt',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'delivery_receipt_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('delivery_receipt_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Advanced Analytics',
          label: 'Advanced Analytics',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'advanced_analytics_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('advanced_analytics_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Reactions',
          label: 'Reactions',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'reactions_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('reactions_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Poll',
          label: 'Poll',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'poll_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('poll_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'poll_tier',
              label: 'Poll Tier',
              formItemProps: {
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['poll_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (() => {
                const tiers = [
                  { key: 'starter', value: 'starter' },
                  { key: 'pro', value: 'pro' },
                ];
                return (
                  <Select
                    defaultValue={tiers[0].value}
                    size="large"
                    style={{ width: '100%', marginTop: '10px' }}
                    onChange={(value) => {
                      formRef.current?.setFieldsValue({ poll_tier: value });
                    }}
                    disabled={
                      formRef.current?.getFieldsValue().hasOwnProperty('poll_enabled')
                        ? !formRef.current?.getFieldsValue()['poll_enabled']
                        : true
                    }
                  >
                    {tiers.map((opt) => (
                      <Option key={opt.key} value={opt.value}>
                        {opt.key}
                      </Option>
                    ))}
                  </Select>
                );
              })(),
            },
          ],
        },
        {
          name: 'PHI Certification',
          label: 'PHI Certification',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'phi_certification_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('phi_certification_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Advanced Moderator Seat',
          label: 'Advanced Moderator Seat',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'advanced_moderator_seat_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          advanced_moderator_seat_cost_per_unit: formRef.current?.getFieldValue(
                            'advanced_moderator_seat_cost_per_unit',
                          ),
                          advanced_moderator_seat_purchased_units: null,
                          advanced_moderator_seat_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('advanced_moderator_seat_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'advanced_moderator_seat_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: formRef.current?.getFieldValue('advanced_moderator_seat_cost_per_unit'),
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('advanced_moderator_seat_enabled')
                      ? !formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled']
                      : true
                  }
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'advanced_moderator_seat_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: formRef.current?.getFieldValue('advanced_moderator_seat_purchased_units'),
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('advanced_moderator_seat_enabled')) {
                        if (!value || value <= 0) {
                          return callback('advanced_moderator_seat purchased units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('advanced_moderator_seat_enabled')
                      ? !formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
            {
              name: 'advanced_moderator_seat_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: formRef.current?.getFieldValue('advanced_moderator_seat_hard_limit'),
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('advanced_moderator_seat_enabled')
                      ? !formRef.current?.getFieldsValue()['advanced_moderator_seat_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Moderation Rule Triggered Event',
          label: 'Moderation Rule Triggered Event',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'moderation_rule_triggered_event_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          moderation_rule_triggered_event_cost_per_unit: formRef.current?.getFieldValue(
                            'moderation_rule_triggered_event_cost_per_unit',
                          ),
                          moderation_rule_triggered_event_purchased_units: null,
                          moderation_rule_triggered_event_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('moderation_rule_triggered_event_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'moderation_rule_triggered_event_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: formRef.current?.getFieldsValue()['moderation_rule_triggered_event_cost_per_unit'],
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_triggered_event_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled']
                      : true
                  }
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'moderation_rule_triggered_event_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: formRef.current?.getFieldsValue()['moderation_rule_triggered_event_purchased_units'],
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('moderation_rule_triggered_event_enabled')) {
                        if (!value || value <= 0) {
                          return callback('moderation_rule_triggered_event purchased units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_triggered_event_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
            {
              name: 'moderation_rule_triggered_event_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: formRef.current?.getFieldValue('moderation_rule_triggered_event_hard_limit'),
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_triggered_event_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_triggered_event_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
          ],
        },
        {
          name: 'Hive Integration',
          label: 'Hive Integration',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'hive_integration_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('hive_integration_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Moderator Messages',
          label: 'Moderator Messages',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'moderator_messages_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('moderator_messages_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Moderation Rule Creation Limit',
          label: 'Moderation Rule Creation Limit',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'moderation_rule_creation_limit_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (!value) {
                        formRef.current?.setFieldsValue({
                          moderation_rule_triggered_event_cost_per_unit: formRef.current?.getFieldValue(
                            'moderation_rule_creation_limit_cost_per_unit',
                          ),
                          moderation_rule_triggered_event_purchased_units: null,
                          moderation_rule_triggered_event_hard_limit: null,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('moderation_rule_creation_limit_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'moderation_rule_creation_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                initialValue: formRef.current?.getFieldsValue()['moderation_rule_creation_limit_cost_per_unit'],
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_creation_limit_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled']
                      : true
                  }
                  placeholder="0"
                  addonBefore="$"
                />
              ),
            },
            {
              name: 'moderation_rule_creation_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                initialValue: formRef.current?.getFieldsValue()['moderation_rule_creation_limit_purchased_units'],
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled'],
                    message: 'Required',
                  },
                  {
                    validator(_, value, callback) {
                      if (formRef.current?.getFieldValue('moderation_rule_creation_limit_enabled')) {
                        if (!value || value <= 0) {
                          return callback('moderation_rule_creation_limit purchased units must be greater than 0');
                        }
                      }
                      callback();
                    },
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_creation_limit_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
            {
              name: 'moderation_rule_creation_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                initialValue: formRef.current?.getFieldValue('moderation_rule_creation_limit_hard_limit'),
                rules: [
                  {
                    required: formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled'],
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('moderation_rule_creation_limit_enabled')
                      ? !formRef.current?.getFieldsValue()['moderation_rule_creation_limit_enabled']
                      : true
                  }
                  placeholder="0"
                />
              ),
            },
          ],
        },
      ],
    },
    {
      name: 'AI Chatbot features',
      label: 'AI Chatbot features',
      formItemProps: {},
      component: {},
      collapsible: true,
      isCollapsed: false,
      fields: [
        {
          name: 'Message Credit for AI Chatbot',
          label: 'Message Credit for AI Chatbot',
          formItemProps: {},
          component: (
            <FormTitle
              style={{
                fontSize: '14px',
                marginBottom: '2px',
                borderBottom: '0',
              }}
              title="Message Credit"
            />
          ),
          fields: [
            {
              name: 'ai_chatbot_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'ai_chatbot_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  placeholder="0"
                  onChange={(event) => {
                    const inputValue = parseFloat(event.target.value);
                    if (!isNaN(inputValue)) {
                      formRef.current?.setFieldsValue({ ai_chatbot_hard_limit: inputValue * 1.5 });
                    }
                  }}
                />
              ),
            },
            {
              name: 'ai_chatbot_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },

        {
          name: '# of AI Chatbots',
          label: '# of AI Chatbots',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'ai_chatbot_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'ai_chatbot_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          ai_chatbot_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          ai_chatbot_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'ai_chatbot_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: '# of Knowledge Ingestion',
          label: '# of Knowledge Ingestion',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'knowledge_ingestion_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'knowledge_ingestion_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          knowledge_ingestion_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          knowledge_ingestion_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'knowledge_ingestion_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: 'Knowledge Up-To-Date',
          label: 'Knowledge Up-To-Date',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'knowledge_uptodate_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('knowledge_uptodate_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Remove Powered By Sendbird',
          label: 'Remove Powered By Sendbird',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'remove_powered_by_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('remove_powered_by_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'UIKit/SDK access',
          label: 'UIKit/SDK access',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'chat_uikit_sdk_access_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: true,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Popconfirm
                  placement="topLeft"
                  title={
                    <>
                      <p>Are you sure to disable this feature?</p>It will block all the chat connections from the
                      UIKit/SDK.
                    </>
                  }
                  disabled={!formRef.current?.getFieldValue('chat_uikit_sdk_access_enabled')}
                  onConfirm={() => {
                    formRef.current?.setFieldValue('chat_uikit_sdk_access_enabled', false);
                    setChangedFields([...changedFields, 'chat_uikit_sdk_access_enabled']);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Switch
                    checked={formRef.current?.getFieldValue('chat_uikit_sdk_access_enabled')}
                    defaultChecked={true}
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      if (formRef.current?.getFieldValue('chat_uikit_sdk_access_enabled') === false) {
                        formRef.current?.setFieldsValue({ chat_uikit_sdk_access_enabled: true });
                        setChangedFields([...changedFields, 'chat_uikit_sdk_access_enabled']);
                      }
                    }}
                  />
                </Popconfirm>
              ),
            },
          ],
        },
        {
          name: 'AI Chatbot Data Export',
          label: 'AI Chatbot Data Export',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'ai_chatbot_data_export_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('ai_chatbot_data_export_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: '# of Workflow Builders',
          label: '# of Workflow Builders',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'workflow_builder_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'workflow_builder_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          workflow_builder_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          workflow_builder_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'workflow_builder_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: '# of Function Calls',
          label: '# of Function Calls',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'function_call_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'function_call_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          function_call_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          function_call_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'function_call_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: 'Selectable AI models',
          label: 'Selectable AI models',
          formItemProps: {},
          component: {},
          description: 'Starter tier only includes GPT model, Pro tier includes GPT and other LLMs we have',
          fields: [
            {
              name: 'ai_chatbot_model_tier',
              label: 'AI Models Tier',
              formItemProps: {
                initialValue: 'starter',
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (() => {
                const tiers = [
                  { key: 'starter', value: 'starter' },
                  { key: 'pro', value: 'pro' },
                ];
                return (
                  <Select
                    defaultValue={tiers[0].value}
                    size="large"
                    style={{ width: '100%', marginTop: '10px' }}
                    onChange={(value) => {
                      formRef.current?.setFieldsValue({ ai_chatbot_model_tier: value });
                    }}
                  >
                    {tiers.map((opt) => (
                      <Option key={opt.key} value={opt.value}>
                        {opt.key}
                      </Option>
                    ))}
                  </Select>
                );
              })(),
            },
          ],
        },
        {
          name: 'Widget Custom Icon',
          label: 'Widget Custom Icon',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'widget_custom_icon_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('widget_custom_icon_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Knowledge ingestion additional source',
          label: 'Knowledge ingestion additional source',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'knowledge_ingestion_additional_source_enabled',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: (
                <Switch
                  checked={formRef.current?.getFieldValue('knowledge_ingestion_additional_source_enabled')}
                  defaultChecked={false}
                  style={{ marginTop: '10px' }}
                />
              ),
            },
          ],
        },
        {
          name: 'Crawling Page Limit',
          label: 'Crawling Page Limit',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'crawling_page_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'crawling_page_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          crawling_page_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          crawling_page_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'crawling_page_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
        {
          name: 'Bot First Message Multi-locale Limit',
          label: 'Bot First Message Multi-locale Limit',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: 'bot_first_message_multi_locale_limit_cost_per_unit',
              label: 'Cost per Extra Unit',
              formItemProps: {
                rules: [
                  {
                    required: false,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" disabled={true} />,
            },
            {
              name: 'bot_first_message_multi_locale_limit_purchased_units',
              label: 'Quota',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                  () => ({
                    validator(_, value, callback) {
                      if (value) {
                        formRef.current?.setFieldsValue({
                          bot_first_message_multi_locale_limit_hard_limit: value,
                        });
                      } else {
                        formRef.current?.setFieldsValue({
                          bot_first_message_multi_locale_limit_hard_limit: 0,
                        });
                      }
                      callback();
                    },
                  }),
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" />,
            },
            {
              name: 'bot_first_message_multi_locale_limit_hard_limit',
              label: 'Hard Limit',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" disabled={false} />,
            },
          ],
        },
      ],
    },
    {
      name: 'Request Reason',
      label: 'Request Reason',
      formItemProps: {},
      component: {},
      fields: [
        {
          name: 'reason',
          label: '',
          formItemProps: {
            initialValue: '',
          },
          span: 24,
          component: <TextArea />,
        },
      ],
    },
  ];

  const sendRequest = (values) => {
    values['subscription_type'] = product;
    values['subscription_name'] = plan.value;
    values['display_name'] = plan.key;

    if (crmOrganization) {
      createBillingSubscriptionRequestRequest({
        crmOrganizationId: crmOrganization.id.toString(),
        values,
        requester: `${localStorage.getItem('username')}@sendbird.com`,
        requestedAction: 'CREATE',
      }).finally(() => {
        history.push(`/crm_organizations/${crmOrganization.id}/subscription_requests/`);
      });
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      if (
        formRef.current.getFieldValue('subscription_auto_renewal') &&
        formRef.current.getFieldValue('subscription_auto_renewal_months') <= 0
      ) {
        setIsFormValid(false);
        return setFormErrorText('Auto renewal months should be greater than 0.');
      }

      formRef.current
        .validateFields()
        .then(() => {
          sendRequest(formRef.current?.getFieldsValue());
        })
        .catch((error) => {
          // When form vaildation failed
          setIsFormValid(false);
          if (error && error.errorFields && error.errorFields.length > 0) {
            let newFormErrorText = '';
            for (const err of error.errorFields) {
              newFormErrorText += `Missing ${err.name[0]}\n`;
            }
            setFormErrorText(newFormErrorText);
          } else {
            setFormErrorText('Unknown Error');
          }
          console.log(error);
        });
    }
  };

  const setForm = (ref) => {
    formRef.current = ref;
  };

  return (
    <>
      <Alert
        message="Invalid form values"
        description={<div style={{ whiteSpace: 'pre' }}>{formErrorText}</div>}
        type="error"
        banner
        style={{ position: 'absolute', top: '20px', zIndex: 1000, display: isFormValid ? 'none' : 'block' }}
      />
      <DynamicForm
        fields={fields}
        setForm={setForm}
        formProps={{
          onValuesChange: (changedValues) => {
            setChangedFields(changedFields.concat(Object.keys(changedValues)));
            setIsFormValid(true);
          },
        }}
      />
      <Button
        key="submit"
        type="primary"
        size="large"
        style={{ marginTop: '30px', minHeight: '40px' }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );
};
