import React, { useState, useRef } from 'react';
import moment from 'moment-timezone';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, DatePicker, Switch } from 'antd';

import { DynamicForm } from '../ui/DynamicForm';
import { getBrowserTodayMoment, dateTimePattern, getBrowserTimeZoneString } from '../../utils/date';
import { paymentMethodOptions, billingCycleOptions } from '../../utils/constants/billing';

import { createSubscriptionRequest } from '../../api/subscription';
import { FormInstance } from 'antd/lib/form';
// import { numberFormatter, numberParser } from '../utils/form';

const { TextArea } = Input;
const { Option } = Select;

function useValidation() {
  const [endDateDisabled, setEndDateDisabled] = useState(true);
  const [autoRenewalPeriodDisabled, setAutoRenewalPeriodDisabled] = useState(false);
  return {
    endDateDisabled,
    setEndDateDisabled,
    autoRenewalPeriodDisabled,
    setAutoRenewalPeriodDisabled,
  };
}

const today = getBrowserTodayMoment();
const nextYear = today.clone().add(12, 'months').subtract(1, 'days');

type Props = {
  crmOrganization: CrmOrganization;
  successCallback: any;
};

export const SubscriptionCreateDialog: React.FC<Props> = ({ crmOrganization, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const valid = useValidation();

  const getFields: any = ({ valid }) => {
    return [
      {
        name: 'subscriptionStartDt',
        label: 'Period start',
        formItemProps: {
          initialValue: moment.tz(today, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const subscriptionPeriodDurationMonths = formRef.current?.getFieldValue(
                  'subscriptionPeriodDurationMonths',
                );
                if (value && subscriptionPeriodDurationMonths) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(value)
                      .add(subscriptionPeriodDurationMonths, 'months')
                      .subtract(1, 'days'),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <DatePicker size="large" format={dateTimePattern.date} />,
      },
      {
        name: 'subscriptionPeriodDurationMonths',
        label: 'Period Duration (Months)',
        formItemProps: {
          initialValue: 12,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const start = formRef.current?.getFieldValue('subscriptionStartDt');
                if (valid.endDateDisabled && value && start) {
                  formRef.current?.setFieldsValue({
                    subscriptionEndDt: moment(start).add(value, 'months').subtract(1, 'days'),
                  });
                }

                const reg = /^-?([1-9][0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonAfter="Months" disabled={!valid.endDateDisabled} />,
      },
      {
        name: 'subscriptionEndDt',
        label: 'Period End',
        span: 24,
        formItemProps: {
          initialValue: moment.tz(nextYear, getBrowserTimeZoneString()),
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                formRef.current?.setFieldsValue({
                  subscriptionEndDt: value,
                });
                callback();
              },
            },
          ],
        },
        component: <DatePicker size="large" format={dateTimePattern.date} disabled={valid.endDateDisabled} />,
      },
      {
        name: 'billingCycle',
        label: 'Billing Cycle',
        formItemProps: {
          initialValue: 'MONTHLY',
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {billingCycleOptions.map(({ value, label }) => (
              <Option key={`subscription-billing-cycle-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'paymentMethod',
        label: 'Payment method',
        formItemProps: {
          initialValue: 'CARD',
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        },
        span: 12,
        component: (
          <Select size="large" style={{ width: '100%' }}>
            {paymentMethodOptions.map(({ value, label }) => (
              <Option key={`subscription-payment-method-${value}}`} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        name: 'productFee',
        label: 'Product fee($)',
        formItemProps: {
          initialValue: 30,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'serviceFee',
        label: 'Service fee($)',
        formItemProps: {
          initialValue: 0,
          rules: [
            {
              required: true,
              message: 'Required',
            },
            {
              validator: (_, value, callback) => {
                const reg = /^-?(0|([1-9][0-9]*))(\.[0-9]*)?$/;
                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonBefore="$" />,
      },
      {
        name: 'productFeeDescription',
        label: 'Product fee description',
        formItemProps: {},
        span: 12,
        component: <Input size="large" />,
      },
      {
        name: 'serviceFeeDescription',
        label: 'Service fee description',
        formItemProps: {},
        span: 12,
        component: <Input size="large" />,
      },
      {
        name: 'autoRenewal',
        label: 'Auto renewal',
        formItemProps: {
          valuePropName: 'checked',
          initialValue: true,
          rules: [
            {
              validator: (_, value, callback) => {
                if (value) {
                  valid.setAutoRenewalPeriodDisabled(false);
                } else {
                  valid.setAutoRenewalPeriodDisabled(true);
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Switch />,
      },
      {
        name: 'autoRenewalPeriod',
        label: 'Auto renewal period (Months)',
        formItemProps: {
          initialValue: 12,
          rules: [
            {
              validator: (_, value, callback) => {
                if (valid.autoRenewalPeriodDisabled) {
                  formRef.current?.setFieldsValue({ autoRenewalPeriod: 12 });
                  callback();
                  return;
                }

                const reg = /^-?([1-9][0-9]*)?$/;

                if (value === '') {
                  return callback && callback();
                } else if (!(!isNaN(value) && reg.test(value))) {
                  return callback && callback('Please enter the valid value');
                }

                callback();
              },
            },
          ],
        },
        span: 12,
        component: <Input size="large" addonAfter="Months" disabled={valid.autoRenewalPeriodDisabled} />,
      },
      {
        name: 'memo ',
        label: 'Memo',
        formItemProps: {},
        component: <TextArea rows={4} />,
      },
    ];
  };

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);

    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const {
            subscriptionStartDt,
            subscriptionEndDt,
            billingCycle,
            paymentMethod,
            productFee,
            productFeeDescription,
            serviceFee,
            serviceFeeDescription,
            memo,
            autoRenewal,
            autoRenewalPeriod,
          } = values;

          createSubscriptionRequest({
            appId: null,
            organizationId: crmOrganization.id,
            subscriptionStartDt: subscriptionStartDt.format(dateTimePattern.date),
            subscriptionEndDt: subscriptionEndDt.format(dateTimePattern.date),
            productFee: parseFloat(productFee),
            serviceFee: parseFloat(serviceFee),
            billingCycle,
            paymentMethod,
            productFeeDescription,
            serviceFeeDescription,
            paymentTerm: 15,
            memo: memo ? memo : '',
            autoRenewal,
            autoRenewalPeriod,
            type: 'TYPE_ORGANIZATION', // Currently 'TYPE_APPLICATION' is supported only.
          })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              valid.setAutoRenewalPeriodDisabled(false);
              valid.setEndDateDisabled(true);
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields({ valid });

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create a subscription"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
