import * as React from 'react';
import { useState } from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { generateGateTeamAccountOtpRequest } from '../api/otp';

const { Title, Text } = Typography;

type Props = {
  dashboardOtp: DashboardOtp;
  successCallback: (payload?: any) => void;
};

export const DashboardOtpGenerateDialog: React.FC<Props> = React.memo(({ dashboardOtp, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    successCallback();
  }

  function handleSubmit() {
    setLoading(true);
    generateGateTeamAccountOtpRequest({ dashboardOtpId: dashboardOtp.id })
      .then((response) => {
        dashboardOtp.password = response.data.password;
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button type="primary" icon={<UnlockOutlined />} onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Generate
      </Button>
      <Modal
        open={visible}
        title={`Generate #${dashboardOtp.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={
          dashboardOtp.password
            ? [<Button key="back" onClick={handleCancel} children="Close" />]
            : [
                <Button key="back" onClick={handleCancel} children="Cancel" />,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                  children="Generate password"
                />,
              ]
        }
      >
        <Title level={4}>Onetime Password ID</Title>
        <Text>{dashboardOtp.id}</Text>

        <Title level={4}>Requester</Title>
        <Text>
          {dashboardOtp.teamAccount ? `${dashboardOtp.teamAccount.name} (${dashboardOtp.teamAccount.eid})` : '-'}
        </Text>

        <Title level={4}>Created At</Title>
        <Text>{dashboardOtp.createdAt}</Text>

        <Title level={4}>Target User</Title>
        <Text>{dashboardOtp.targetUser ? dashboardOtp.targetUser.email : '-'}</Text>

        <Title level={4}>Status</Title>
        <Text>{dashboardOtp.status}</Text>

        <Title level={4}>Approved At/By</Title>
        <Text>
          {dashboardOtp.approvedAt}{' '}
          {dashboardOtp.approvalTeamAccount ? `(${dashboardOtp.approvalTeamAccount.name})` : ''}
        </Text>

        <Title level={4}>Password</Title>
        {dashboardOtp.password ? (
          <Text copyable={true}>{dashboardOtp.password}</Text>
        ) : (
          <Text disabled={true}>(Password will show here)</Text>
        )}
        <Text style={{ marginLeft: 32 }}>
          {dashboardOtp.password ? (
            <a
              href={`https://dashboard${
                process.env.REACT_APP_ENV === 'production' ? '' : '-st'
              }.sendbird.com/auth/greenlantern/?eid=${
                dashboardOtp.teamAccount.eid
              }&target_user_email=${encodeURIComponent(dashboardOtp.targetUser.email)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to the login page.
            </a>
          ) : (
            ''
          )}
        </Text>
      </Modal>
    </React.Fragment>
  );
});
