import * as React from 'react';
import { useState } from 'react';
import { DollarOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';

import { payNowRequest } from '../api/billing';
const { Title, Text } = Typography;

type Props = {
  crmApplication: CrmApplication;
  crmPaymentId: CrmPayment['id'];
  successCallback: (payload?: any) => void;
};

export const PaymentPaynowDialog: React.FC<Props> = ({ crmApplication, crmPaymentId, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    payNowRequest({
      crmPaymentId,
    })
      .then((_) => {
        setLoading(false);
        setVisible(false);
        successCallback && successCallback();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button type="primary" icon={<DollarOutlined />} onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Pay now
      </Button>
      <Modal
        open={visible}
        title={`Pay now #${crmPaymentId}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Title level={3}>Do you want to process this payment?</Title>
        <Title level={4}>Application App ID</Title>
        <Text copyable={true}>{crmApplication.appId}</Text>
        <Title level={4}>Payment ID</Title>
        <Text copyable={true}>{crmPaymentId}</Text>
      </Modal>
    </React.Fragment>
  );
};
