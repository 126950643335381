import React, { useState, useEffect, useContext } from 'react';
import {
  getStripeCustomerRequest,
  getBillingVoucherSubscriptionRequest,
  getBillingOrganizationRequest
} from '../../api/billing';
import { CrmOrganizationInfo } from '../CrmOrganizationInfo';
import { getCrmOrganizationSalesforceAccountsRequest, getOrganizationAttributesRequest, updateOrganizationAttributesRequest } from '../../api/organization';
import { OrganizationContext } from '../../containers/organization/OrganizationProvider';


export const OrganizationDetail = ({ crmOrganizationId }) => {
  const {
    state: { crmOrganization },
    actions: { fetchSyncCrmOrganization },
  } = useContext(OrganizationContext);
  const [stripeCustomer, setStripeCustomer] = useState({} as StripeCustomer);
  const [voucherSubscription, setVoucherSubscription] = useState({} as any);
  const [salesforceAccounts, setSalesforceAccounts] = useState([] as Array<object>);
  const [billingOrganization, setBillingOrganization] = useState({} as BillingOrganization);
  const [organizationAttributes, setOrganizationAttributes] = useState({} as any);

  const getStripeCustomer = () => {
    getStripeCustomerRequest({ crmOrganizationId })
      .then((response) => {
        setStripeCustomer(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getVoucherSubscription = () => {
    getBillingVoucherSubscriptionRequest({ organization_uid: crmOrganization.uid })
      .then((response) => {
        setVoucherSubscription(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCrmOrganizationSalesforceAccounts = () => {
    getCrmOrganizationSalesforceAccountsRequest({ crmOrganizationId })
      .then((response) => {
        setSalesforceAccounts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBillingOrganization = () => {
    getBillingOrganizationRequest({uid: crmOrganization.uid}).then((response) => {
      setBillingOrganization(response.data);
    }).catch((err) => {
      console.error(err);
    })
  };

  const getOrganizationAttributes = () => {
    getOrganizationAttributesRequest({crmOrganizationId: crmOrganization.id}).then((response) => {
      setOrganizationAttributes(response.data.attributes);
    }).catch((err) => {
      console.error(err);
    })
  };

  const updateOrganizationAttributes = (attributeKey: string, attributeValue?: string) => {
    return inner

    function inner(value) {
      updateOrganizationAttributesRequest(crmOrganization.id, {[attributeKey]: attributeValue || value}).then((response) => {
        setOrganizationAttributes(response.data.attributes);
      }).catch((err) => {
        console.error(err);
      })
    }
  }


  useEffect(() => {
    if (crmOrganization.stripeCustomerId) {
      getStripeCustomer();
    }

    if (crmOrganization.uid && crmOrganization.isSelfServe && crmOrganization.status != 'DELETED') {
      getVoucherSubscription();
      getBillingOrganization();
    }

    getCrmOrganizationSalesforceAccounts();

    if(crmOrganization.id) {
      getOrganizationAttributes();
    }

  }, [crmOrganizationId, crmOrganization.stripeCustomerId]);

  return (
    <CrmOrganizationInfo
      crmOrganization={crmOrganization}
      salesforceAccounts={salesforceAccounts}
      stripeCustomer={stripeCustomer}
      successUpdateCallback={fetchSyncCrmOrganization}
      voucherSubscription={voucherSubscription}
      billingOrganization={billingOrganization}
      organizationAttributes={organizationAttributes}
      organizationAttributesUpdateCallback={updateOrganizationAttributes}

    />
  );
};
