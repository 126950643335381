import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getBillingCallVoucherRequestRequest } from '../../api/billing';
import { TableDetail } from '../ui/TableDetail';
import {
  BillingCallVoucherRequestRequestedActionTag,
  BillingCallVoucherRequestStatusTag,
} from '../ui/Tags';
import { VoucherRequestViewDialog } from '../organizationBilling/VoucherRequestViewDialog';
import { dateTimePattern } from '../../utils/date';
import { hasPermission, Permissions } from '../../utils/permission';
import { VoucherCreationRequestDialog } from '../organizationBilling/VoucherCreationRequestDialog';

export const OrganizationCallVoucherRequests = ({ crmOrganizationId }) => {
  const [loadingBillingCallVoucherRequests, setLoadingBillingCallVoucherRequests] = useState(false);
  const [billingCallVoucherRequestsCount, setBillingCallVoucherRequestsCount] = useState(0);
  const [billingCallVoucherRequests, setBillingCallVoucherRequests] = useState([] as Array<any>);

  const getBillingCallVoucherRequests = ({ offset = 0, limit = 20 }) => {
    setLoadingBillingCallVoucherRequests(true);
    getBillingCallVoucherRequestRequest({ limit, offset, crmOrganizationId })
      .then((response) => {
        setBillingCallVoucherRequests(response.data.results);
        setLoadingBillingCallVoucherRequests(false);
        setBillingCallVoucherRequestsCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
        setLoadingBillingCallVoucherRequests(false);
      });
  };

  const billingCallVoucherRequestsColumn = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Request',
      dataIndex: 'requestedAction',
      key: 'requestedAction',
      render: (requestedAction) => {
        return (
          <>
            <BillingCallVoucherRequestRequestedActionTag requestedAction={requestedAction} />
          </>
        );
      },
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
      render: (_, request) => {
        return <Link to={`/users/${request.requester.id}/`}>{request.requester.email.split('@')[0]}</Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BillingCallVoucherRequestStatusTag status={status} />,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => {
        return <div style={{ wordBreak: 'break-all' }}>{reason}</div>;
      },
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => `${moment(createdAt).utc().format(dateTimePattern.default)}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, billingCallVoucher) => (
        <VoucherRequestViewDialog
          crmOrganizationId={crmOrganizationId}
          billingCallVoucherRequest={billingCallVoucher}
          isReviewer={hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION)}
          successCallback={() => {
            window.location.href = `/crm_organizations/${crmOrganizationId}/voucher_requests/`;
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    getBillingCallVoucherRequests({
      limit: 20,
      offset: 0,
    });
  }, []);

  return (
    <TableDetail
      title="Call Voucher Requests"
      count={billingCallVoucherRequestsCount}
      loading={loadingBillingCallVoucherRequests}
      columns={billingCallVoucherRequestsColumn}
      dataSource={billingCallVoucherRequests}
      onPageChange={getBillingCallVoucherRequests}
      pageSizeOptions={['20', '40', '60']}
      rightColumnComponent={
        hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION_CREATION) ? (
          <VoucherCreationRequestDialog
            crmOrganizationId={crmOrganizationId}
            successCallback={getBillingCallVoucherRequests}
          />
        ) : (
          <></>
        )
      }
    />
  );
};
