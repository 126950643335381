import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, List, Modal, Row, Tag, Typography } from 'antd';
import numbro from 'numbro';
import moment from 'moment';
import lodash from 'lodash';

import { useCheckMobile } from '../../utils/screen';
import {
  updateBillingSubscriptionRequestDenyRequest,
  updateBillingSubscriptionRequestApproveRequest,
  updateBillingSubscriptionRequestApplyedRequest,
  getStripeCustomerRequest,
} from '../../api/billing';
import { ResponsiveTable } from '../ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import {
  AutoRenewalTag,
  EnabledDisabledTag,
  SubscriptionRequestRequestedActionTag,
  SubscriptionRequestStatusTag,
  CrmOrganizationVersionTypeTag,
} from '../ui/Tags';
import { dateTimePattern } from '../../utils/date';
import { StripeCardPanels } from '../StripeCardPanels';
import { hasPermission, Permissions } from '../../utils/permission';

const { Title, Text } = Typography;

type Props = {
  crmOrganization: CrmOrganization | undefined;
  billingSubscriptionRequest: any;
  isReviewer: boolean;
  successCallback: (payload?: any) => void;
};

type FormTitleProps = {
  title: string;
};

type BillingRulesProps = {
  billingSubscriptionRequest: any;
  stripeCustomer: StripeCustomer;
};

const convertToEmptyValueFeatures = [
  'webhook',
  'message_retrieval',
  'moderation_tools',
  'data_export',
  'desk',
  'advanced_analytics',
  'reactions',
  'delivery_receipt',
];
const GBUnitFeatures = ['upload_traffic', 'file_storage'];
const GBUnitFields = ['purchased_units', 'hard_limit'];

function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

function isCustomerCardsValid(stripeCustomer) {
  let isValid = false;

  if (isEmptyObject(stripeCustomer)) {
    return isValid;
  }

  if (stripeCustomer.paymentMethods.length > 0) {
    const today = new Date();
    const paymentMethods = stripeCustomer.paymentMethods;
    const validPayments = paymentMethods.filter((paymentMethod) => {
      const expirationDate = new Date(paymentMethod.card.expYear, paymentMethod.card.expMonth, 0);
      if (expirationDate > today) {
        return paymentMethod;
      }
    });

    if (validPayments.length > 0) {
      isValid = true;
    }
  }
  return isValid;
}

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {item[0]}
        </Col>
        <Col span={12} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item[1] ? item[1] : '-'}
        </Col>
      </Row>
    </List.Item>
  );
};

const FormTitle: React.FC<FormTitleProps> = ({ title }) => (
  <Title
    level={4}
    style={{
      margin: 0,
      marginTop: '16px',
      marginBottom: '8px',
      paddingBottom: '6px',
      fontSize: '16px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    }}
  >
    {title}
  </Title>
);

const alignRight = 'right' as ColumnProps<any>['align'];
const getSubscriptionRequestColumns = () => {
  const billingSubscriptionRequestColumns: Array<ColumnProps<any>> = [
    {
      title: 'Field',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Data',
      dataIndex: 'value',
      key: 'value',
      align: alignRight,
    },
  ];

  return billingSubscriptionRequestColumns;
};

const BillingRules: React.FC<BillingRulesProps> = ({ billingSubscriptionRequest }) => {
  const subRequestResponseBody = JSON.parse(billingSubscriptionRequest.requestBody);

  const subscriptionFutureText = (subRequestResponseBody) => {
    if (!subRequestResponseBody['end_date']) {
      return (
        <li>
          The subscription will run &nbsp;
          <Text code>perpetually</Text>
        </li>
      );
    } else if (
      subRequestResponseBody['end_date'] &&
      !subRequestResponseBody['auto_renewal'] &&
      !subRequestResponseBody['auto_renewal_months']
    ) {
      return (
        <li>
          The subscription will stop on &nbsp;
          <Text code>
            {subRequestResponseBody['end_date']
              ? `${moment(subRequestResponseBody['end_date']).utc().format(dateTimePattern.date)}`
              : 'N/A'}
          </Text>
        </li>
      );
    } else if (
      subRequestResponseBody['end_date'] &&
      subRequestResponseBody['auto_renewal'] &&
      subRequestResponseBody['auto_renewal_months']
    ) {
      return (
        <>
          <li>
            The subscription will stop on &nbsp;
            <Text code>
              {subRequestResponseBody['end_date']
                ? `${moment(subRequestResponseBody['end_date']).utc().format(dateTimePattern.date)}`
                : 'N/A'}
            </Text>
          </li>
        </>
      );
    } else {
      return <></>;
    }
  };

  const autoRenewalNotice = (subRequestResponseBody) => {
    if (
      subRequestResponseBody['end_date'] &&
      subRequestResponseBody['auto_renewal'] &&
      subRequestResponseBody['auto_renewal_months']
    ) {
      return (
        <>
          <li>
            Once the subscription ended, a new subscription (with exact features) will be created with a minimum
            contract period of <Text code>{`${subRequestResponseBody['auto_renewal_months']} Month`}</Text>
          </li>
          <li>Customer will have the option to stop the renewal if they informed us 15 days before the renewl date</li>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <ul style={{ fontSize: '15px', lineHeight: '30px' }}>
        <li>
          The subscription will start on &nbsp;
          <Text code>
            {subRequestResponseBody['start_date']
              ? `${moment(subRequestResponseBody['start_date']).utc().format(dateTimePattern.date)}`
              : 'N/A'}
          </Text>
        </li>
        {subscriptionFutureText(subRequestResponseBody)}
        <li>
          The customer will be billed for &nbsp;
          <Text code>
            {subRequestResponseBody['plan_value']
              ? `$${(subRequestResponseBody['plan_value'] / 100).toLocaleString()}`
              : 'N/A'}
          </Text>
          &nbsp; every &nbsp;
          <Text code>
            {subRequestResponseBody['billing_cycle_months']
              ? `${subRequestResponseBody['billing_cycle_months']} Month`
              : 'N/A'}
          </Text>
        </li>
        <li>
          The customer will be billed via &nbsp;
          <Text code>
            {billingSubscriptionRequest.crmOrganization?.paymentMethod
              ? billingSubscriptionRequest.crmOrganization?.paymentMethod
              : 'N/A'}
          </Text>
        </li>
        {autoRenewalNotice(subRequestResponseBody)}
      </ul>
    </>
  );
};

export const OrganizationBillingSubscriptionRequestViewDialog: React.FC<Props> = React.memo(
  ({ crmOrganization, billingSubscriptionRequest, isReviewer, successCallback }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [stripeCustomer, setStripeCustomer] = useState({} as StripeCustomer);

    const getStripeCustomer = useCallback(async () => {
      getStripeCustomerRequest({ crmOrganizationId: crmOrganization?.id.toString() })
        .then((response) => {
          setStripeCustomer(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }, [crmOrganization]);

    const planFeatureColumn = [
      {
        title: 'Feature Name',
        dataIndex: 'name',
        key: 'name',
        render: (name) => <span style={{ fontWeight: 500 }}>{lodash.startCase(name)}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'property-status',
        key: 'property-status',
        render: (_, planFeature) => <EnabledDisabledTag enabled={planFeature.property.enabled} />,
      },
      {
        title: 'Quota',
        dataIndex: 'property-purchased-units',
        key: 'property-purchased-units',
        render: (_, planFeature) => (
          <span style={{ fontWeight: 500 }}>
            {planFeature.property.purchased_units
              ? isNaN(planFeature.property.purchased_units)
                ? planFeature.property.purchased_units
                : `${numbro(planFeature.property.purchased_units).format({
                    thousandSeparated: true,
                  })}`
              : '-'}
          </span>
        ),
      },
      {
        title: 'Cost per Extra Unit',
        dataIndex: 'property-cost-per-unit',
        key: 'property-cost-per-unit',
        render: (_, planFeature) => (
          <span style={{ fontWeight: 500 }}>
            {isNaN(planFeature.property.cost_per_unit)
              ? '-'
              : `$${numbro(planFeature.property.cost_per_unit / 100).format({ thousandSeparated: true })}`}
          </span>
        ),
      },
      {
        title: 'Hard Limit',
        dataIndex: 'property-hard-limit',
        key: 'property-hard-limit',
        render: (_, planFeature) => (
          <span style={{ fontWeight: 500 }}>
            {planFeature.property.hard_limit
              ? isNaN(planFeature.property.hard_limit)
                ? planFeature.property.hard_limit
                : `${numbro(planFeature.property.hard_limit).format({
                    thousandSeparated: true,
                  })}`
              : '-'}
          </span>
        ),
      },
      {
        title: 'Tier',
        dataIndex: 'property-tier',
        key: 'property-tier',
        render: (_, planFeature) => (
          <span style={{ fontWeight: 500 }}>{planFeature.property.tier ? `${planFeature.property.tier}` : '-'}</span>
        ),
      },
    ];

    const convertDataSourceSubscriptionRequestRequestBodyPlan = (_billingSubscriptionRequest) => {
      const _getFormattedPlan = (plan, aiChatbotPlan) => {
        // subscriptionRequest.requestBody.plan
        let planFeatureArray = [] as Array<OrganizationSubscriptionPlanFeature>;
        
        for (let [key, value] of Object.entries(plan)) {
          if (key !== 'base_fee') {
            planFeatureArray = [
              ...planFeatureArray,
              {
                name: key,
                property: value,
              },
            ];
          }
        }

        if (aiChatbotPlan !== null && aiChatbotPlan !== undefined) {
          for (let [key, value] of Object.entries(aiChatbotPlan)) {
            if (key !== 'base_fee') {
              planFeatureArray = [
                ...planFeatureArray,
                {
                  name: key,
                  property: value,
                },
              ];
            }
          }
        }

        planFeatureArray.sort((a, b) => {
          const enabledCompare = b.property.enabled - a.property.enabled;
          if (enabledCompare !== 0) {
            return enabledCompare;
          }
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        planFeatureArray.forEach((item) => {
          if (!item.property.enabled || convertToEmptyValueFeatures.includes(item.name)) {
            for (const k of Object.keys(item.property)) {
              if (k !== 'enabled') {
                item.property[k] = '-';
              }
            }
          } else if (GBUnitFeatures.includes(item.name)) {
            for (const k of Object.keys(item.property)) {
              if (GBUnitFields.includes(k)) {
                item.property[k] = `${item.property[k]}GB`;
              }
              if (k === 'costPerUnit') {
                item.property[k] = item.property[k].toFixed(20).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
              }
            }
          }
        });
        return planFeatureArray;
      };

      return _getFormattedPlan(JSON.parse(_billingSubscriptionRequest.requestBody).plan, JSON.parse(_billingSubscriptionRequest.requestBody).ai_chatbot_plan);
    };

    const requestedActionAsNoun =
      billingSubscriptionRequest.requestedAction && billingSubscriptionRequest.requestedAction === 'CREATE'
        ? 'Creation'
        : 'Deletion';

    function convertDataSourceDetail(_billingSubscriptionRequest) {
      return [
        ['#ID', _billingSubscriptionRequest.id],
        [
          'Requester',
          <Link to={`/users/${_billingSubscriptionRequest.requester.id}/`}>
            {_billingSubscriptionRequest.requester.email.split('@')[0]}
          </Link>,
        ],
        [
          <span>
            Requested At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          `${moment(_billingSubscriptionRequest.createdAt).utc().format(dateTimePattern.default)}`,
        ],
        [
          'Reviewer',
          (() => {
            if (_billingSubscriptionRequest.reviewer) {
              return (
                <Link to={`/users/${_billingSubscriptionRequest.reviewer.id}/`}>
                  {_billingSubscriptionRequest.reviewer.email.split('@')[0]}
                </Link>
              );
            } else {
              return <span>N/A</span>;
            }
          })(),
        ],
        [
          <span>
            Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          _billingSubscriptionRequest.reviewedAt
            ? `${moment(_billingSubscriptionRequest.reviewedAt).utc().format(dateTimePattern.default)}`
            : '-',
        ],
        [
          'Requested Action',
          <SubscriptionRequestRequestedActionTag requestedAction={_billingSubscriptionRequest.requestedAction} />,
        ],
        ['Status', <SubscriptionRequestStatusTag status={_billingSubscriptionRequest.status} />],
        [
          'Organization',
          <Link
            target="_blank"
            to={
              _billingSubscriptionRequest.crmOrganization === null
                ? '/crm_organizations/'
                : `/crm_organizations/${_billingSubscriptionRequest.crmOrganization.id}/`
            }
          >
            {_billingSubscriptionRequest.crmOrganization === null
              ? 'None'
              : `${_billingSubscriptionRequest.crmOrganization.id} (${_billingSubscriptionRequest.crmOrganization.name})`}
          </Link>,
        ],
        [
          'Organization version',
          _billingSubscriptionRequest.crmOrganization ? (
            <CrmOrganizationVersionTypeTag isSelfServe={_billingSubscriptionRequest.crmOrganization.isSelfServe} />
          ) : (
            '-'
          ),
        ],
        ['Reason', _billingSubscriptionRequest.reason],
      ];
    }

    function convertDataSourceSubscriptionRequestRequestBody(_billingSubscriptionRequest) {
      const subRequestResponseBody = JSON.parse(_billingSubscriptionRequest.requestBody);
      const product = subRequestResponseBody['product'];
      const autoRenewal = subRequestResponseBody['auto_renewal'];
      const planValue = subRequestResponseBody['plan_value'];

      let _dataSource: any = [];

      _dataSource = _dataSource.concat([
        {
          key: 'Product',
          value: product,
        },
        {
          key: 'Subscription Name',
          value: subRequestResponseBody['subscription_name'],
        },
        {
          key: 'Display Name',
          value: subRequestResponseBody['display_name'],
        },
        {
          key: 'Plan Value',
          value:
            planValue === 0 ? (
              <Tag key="plan-value" style={{ margin: 0 }}>
                Free
              </Tag>
            ) : (
              <Tag key="plan-value" style={{ margin: 0 }}>
                {`$${(planValue / 100).toLocaleString()}`}
              </Tag>
            ),
        },
        {
          key: 'Billing Cycle (Months)',
          value: subRequestResponseBody['billing_cycle_months'],
        },
        {
          key: 'Subscription Start Date',
          value: subRequestResponseBody['start_date']
            ? `${moment(subRequestResponseBody['start_date']).utc().format(dateTimePattern.date)}`
            : 'N/A',
        },
        {
          key: 'Subscription End Date',
          value: subRequestResponseBody['end_date']
            ? `${moment(subRequestResponseBody['end_date']).utc().format(dateTimePattern.date)}`
            : 'N/A',
        },
        {
          key: 'Auto Renewal',
          value: <AutoRenewalTag autoRenewal={autoRenewal} />,
        },
        {
          key: 'Auto Renewal Months',
          value: subRequestResponseBody['auto_renewal_months'] ? (
            <Tag key="auto-renewal-months" style={{ margin: 0 }}>
              {`${subRequestResponseBody['auto_renewal_months']} Month`}
            </Tag>
          ) : (
            '-'
          ),
        },
        {
          key: 'Payment Method',
          value: crmOrganization?.paymentMethod ? crmOrganization?.paymentMethod : 'N/A',
        },
        {
          key: 'Stripe Cards',
          value:
            !isEmptyObject(stripeCustomer) && stripeCustomer.paymentMethods.length > 0 ? (
              isCustomerCardsValid(stripeCustomer) ? (
                <StripeCardPanels
                  stripeCustomer={stripeCustomer}
                  paymentMethod={_billingSubscriptionRequest.crmOrganization.paymentMethod}
                />
              ) : (
                <Text type="danger">Card Expired</Text>
              )
            ) : (
              'N/A'
            ),
        },
      ]);

      return _dataSource;
    }

    function showModal() {
      setVisible(true);
    }

    function handleCancel() {
      setLoading(false);
      setVisible(false);
    }

    function handleApprove() {
      setLoading(true);
      updateBillingSubscriptionRequestApproveRequest(billingSubscriptionRequest.id)
        .then((response) => {
          setLoading(false);
        })
        .then(() => {
          return updateBillingSubscriptionRequestApplyedRequest({
            crmOrganizationId: crmOrganization?.id.toString(),
          });
        })
        .then(() => {
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handleDeny() {
      setLoading(true);
      updateBillingSubscriptionRequestDenyRequest(billingSubscriptionRequest.id)
        .then(() => {
          setLoading(false);
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handleDuplicate() {
      setLoading(true);
      history.push(
        `/crm_organizations/${crmOrganization?.id}/create_billing_subscription/?subscription_request_id=${billingSubscriptionRequest.id}`,
      );
      setLoading(false);
    }

    useEffect(function () {
      getStripeCustomer();
    }, []);

    return (
      <React.Fragment>
        <Button type="default" onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
          View
        </Button>
        <Modal
          open={visible}
          title={`Billing Subscription ${requestedActionAsNoun} Request View #${billingSubscriptionRequest.id}`}
          width={useCheckMobile() ? '' : '650px'}
          onOk={handleApprove}
          onCancel={handleCancel}
          footer={[
            hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION_CREATION) ? (
              <Button key="duplicate" type="primary" loading={loading} onClick={handleDuplicate}>
                Duplicate
              </Button>
            ) : (
              ''
            ),
            isReviewer && billingSubscriptionRequest.status === 'REQUESTED' ? (
              <Button
                key="submitApprove"
                type="primary"
                danger={!isEmptyObject(stripeCustomer) && isCustomerCardsValid(stripeCustomer) ? false : true}
                disabled={billingSubscriptionRequest.status !== 'REQUESTED'}
                loading={loading}
                onClick={handleApprove}
              >
                {!isEmptyObject(stripeCustomer) &&
                (stripeCustomer.paymentMethods.length > 0 || stripeCustomer.sources.data.length > 0)
                  ? crmOrganization?.paymentMethod === 'CARD'
                    ? isCustomerCardsValid(stripeCustomer)
                      ? 'Approve with Card'
                      : 'Approve with Expired Credit Card'
                    : 'Approve with Wire'
                  : 'Approve without stripe info'}
              </Button>
            ) : (
              ''
            ),
            isReviewer && billingSubscriptionRequest.status === 'REQUESTED' ? (
              <Button
                key="submitDeny"
                danger={true}
                disabled={billingSubscriptionRequest.status !== 'REQUESTED'}
                onClick={handleDeny}
              >
                Deny
              </Button>
            ) : (
              ''
            ),
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <FormTitle title="Detail" />
          <List
            size="small"
            dataSource={convertDataSourceDetail(billingSubscriptionRequest)}
            renderItem={renderInfoItem}
          />
          <hr />
          <FormTitle title={`Requested subscription request body`} />
          <ResponsiveTable
            bordered={true}
            columns={getSubscriptionRequestColumns()}
            dataSource={convertDataSourceSubscriptionRequestRequestBody(billingSubscriptionRequest)}
            pagination={false}
          />
          <hr />
          <FormTitle title={`Billing Rules`} />
          <BillingRules billingSubscriptionRequest={billingSubscriptionRequest} stripeCustomer={stripeCustomer} />
          <hr />
          <FormTitle title={`Requested subscription request body plan`} />
          <ResponsiveTable
            bordered={true}
            columns={planFeatureColumn}
            dataSource={convertDataSourceSubscriptionRequestRequestBodyPlan(billingSubscriptionRequest)}
            pagination={false}
          />
        </Modal>
      </React.Fragment>
    );
  },
);
