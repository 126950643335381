import { Space, Typography } from 'antd';
import React, { ComponentProps, ReactNode } from 'react';

const { Text } = Typography;

export const WithLabel = ({
  label,
  children,
  htmlFor,
  ...props
}: { label: ReactNode; children: ReactNode; htmlFor?: string } & ComponentProps<typeof Space>) => {
  return (
    <Space direction="vertical" size={4} {...props}>
      {htmlFor ? (
        <label htmlFor={htmlFor}>
          <Text>{label}</Text>
        </label>
      ) : (
        <Text>{label}</Text>
      )}
      {children}
    </Space>
  );
};
