import React, { useState, useEffect, useMemo, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { OrganizationMembers } from '../../components/organization/OrganizationMembers';
import { PrivateRoute } from '../../routes/PrivateRoute';
import { Tabs, Button, Tooltip, Tag, Typography, Layout } from 'antd';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import { useOrganizationPlanAuthorized } from '../../hooks';
import { changeOrgToSelfServiceRequest, getCrmApplicationByOrganizationRequest } from '../../api/organization';
import { PageTitle } from '../../components/ui/PageTitle';
import { OrganizationDetail } from '../../components/organization/OrganizationDetail';
import { OrganizationApplications } from '../../components/organization/OrganizationApplications';
import { OrganizationDailyFeatureUsages } from '../../components/organization/OrganizationDailyFeatureUsages';
import { OrganizationMonthlyFeatureUsages } from '../../components/organization/OrganizationMonthlyFeatureUsages';
import { OrganizationPlans } from '../../components/organization/OrganizationPlans';
import { OrganizationSubscriptions } from '../../components/organization/OrganizationSubscriptions';
import { OrganizationInvoices } from '../../components/organization/OrganizationInvoices';
import { OrganizationRoles } from '../../components/organization/OrganizationRoles';
import { OrganizationInvitations } from '../../components/organization/OrganizationInvitations';
import { OrganizationContext } from './OrganizationProvider';
import { OrganizationSubscriptionRequests } from '../../components/organization/OrganizationSubscriptionRequests';
import { Spinner } from '../../components/ui/Spinner';
import { OrganizationCallVouchers } from '../../components/organization/OrganizationCallVouchers';
import { OrganizationCallVoucherRequests } from '../../components/organization/OrganizationCallVoucherRequests';

const OrganizationWrapper = styled(Layout)<{ status: string }>`
  ${(props) => ((props.status === 'INACTIVE' || props.status === 'DELETED') ? '* {\n    text-decoration: line-through; \n  }' : '')}
`;

const DetailInfo = styled.div`
  position: relative;
`;

export const OrganizationContainer = withRouter(({ match, history }) => {
  const {
    state: { isLoadingOrganization, crmOrganization },
    actions: { fetchSyncCrmOrganization },
  } = useContext(OrganizationContext);
  const [tabKey, setTabKey] = useState('1');
  const [crmApplications, setCrmApplications] = useState([] as Array<CrmApplication>);
  const [loadingApplication, setLoadingApplication] = useState(false);
  const { crmOrganizationId } = match.params;

  const isOrganizationPlanAuthorized = useOrganizationPlanAuthorized(crmOrganizationId);

  const routes = [
    {
      label: 'Detail',
      url: `/crm_organizations/${crmOrganizationId}`,
      key: '1',
      route: (
        <PrivateRoute
          exact={true}
          path="/crm_organizations/:crmOrganizationId/"
          crmOrganization={crmOrganization}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationDetail}
        />
      ),
    },
    {
      label: 'Applications',
      url: `/crm_organizations/${crmOrganizationId}/applications`,
      key: '2',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/applications/`}
          crmOrganization={crmOrganization}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationApplications}
        />
      ),
    },
    {
      label: 'Daily Feature Usages',
      url: `/crm_organizations/${crmOrganizationId}/daily_feature_usages`,
      key: '3',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/daily_feature_usages/`}
          crmOrganization={crmOrganization}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationDailyFeatureUsages}
        />
      ),
    },
    {
      label: 'Monthly Feature Usages',
      url: `/crm_organizations/${crmOrganizationId}/monthly_feature_usages`,
      key: '4',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/monthly_feature_usages/`}
          crmOrganization={crmOrganization}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationMonthlyFeatureUsages}
        />
      ),
    },
    ...(isOrganizationPlanAuthorized
      ? [
          {
            label: 'Organization Plans',
            url: `/crm_organizations/${crmOrganizationId}/organization_plans`,
            key: '5',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/organization_plans/`}
                crmOrganization={crmOrganization}
                crmOrganizationId={crmOrganizationId}
                component={OrganizationPlans}
              />
            ),
          },
        ]
      : []),
    ...(crmOrganization.isSelfServe
      ? [
          {
            label: 'Subscriptions',
            url: `/crm_organizations/${crmOrganizationId}/subscriptions/`,
            key: '6',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/subscriptions/`}
                crmOrganization={crmOrganization}
                crmOrganizationUid={crmOrganization.uid}
                component={OrganizationSubscriptions}
              />
            ),
          },
          {
            label: 'Subscription Requests',
            url: `/crm_organizations/${crmOrganizationId}/subscription_requests/`,
            key: '7',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/subscription_requests/`}
                crmOrganizationId={crmOrganizationId}
                component={OrganizationSubscriptionRequests}
              />
            ),
          },
          {
            label: 'Call Vouchers',
            url: `/crm_organizations/${crmOrganizationId}/vouchers/`,
            key: '8',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/vouchers/`}
                crmOrganizationUid={crmOrganization.uid}
                component={OrganizationCallVouchers}
              />
            ),
          },
          {
            label: 'Call Voucher Requests',
            url: `/crm_organizations/${crmOrganizationId}/voucher_requests/`,
            key: '9',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/voucher_requests/`}
                crmOrganizationId={crmOrganizationId}
                component={OrganizationCallVoucherRequests}
              />
            ),
          },
          {
            label: 'Invoices',
            url: `/crm_organizations/${crmOrganizationId}/invoices/`,
            key: '10',
            route: (
              <PrivateRoute
                exact={true}
                path={`${match.url}/invoices/`}
                crmOrganizationUid={crmOrganization.uid}
                component={OrganizationInvoices}
              />
            ),
          },
        ]
      : []),
    {
      label: 'Members',
      url: `/crm_organizations/${crmOrganizationId}/members/`,
      key: '11',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/members/`}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationMembers}
        />
      ),
    },
    {
      label: 'Roles',
      url: `/crm_organizations/${crmOrganizationId}/roles/`,
      key: '12',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/roles/`}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationRoles}
        />
      ),
    },
    {
      label: 'Invitations',
      url: `/crm_organizations/${crmOrganizationId}/invitations/`,
      key: '13',
      route: (
        <PrivateRoute
          exact={true}
          path={`${match.url}/invitations/`}
          crmOrganizationId={crmOrganizationId}
          component={OrganizationInvitations}
        />
      ),
    },
  ];

  useEffect(() => {
    const activeRoute = routes.find((route) => route.url === history.location.pathname);
    if (activeRoute) {
      setTabKey(activeRoute.key);
    }
  }, [crmOrganization, history.location]);

  const getCrmApplicationByOrganization = ({ offset = 0, limit = 10 }) => {
    setLoadingApplication(true);
    getCrmApplicationByOrganizationRequest({
      crmOrganizationId,
      offset,
      limit,
    })
      .then((response) => {
        setCrmApplications(response.data.results);
        setLoadingApplication(false);
      })
      .catch((error) => {
        setLoadingApplication(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchSyncCrmOrganization({
      callback: () => {
        getCrmApplicationByOrganization({
          offset: 0,
          limit: 10,
        });
      },
    });
  }, [crmOrganizationId]);

  const handleTabChange = (key) => {
    setTabKey(key);
    const matched = routes.find((route) => route.key === key);
    if (matched) {
      history.replace(matched.url);
    }
  };
  function checkOrgIsSelfServiceChangeable() {
    let flag = true;
    if (crmOrganization.isSelfServe) {
      flag = false;
    }
    for (let app of crmApplications) {
      if (app.plan === 'enterprise') {
        flag = false;
        break;
      }
    }
    return flag;
  }

  const changeOrgToSelfServiceButton = useMemo(() => {
    const disabled = !checkOrgIsSelfServiceChangeable();
    const button = (
      <Button
        type="primary"
        loading={loadingApplication}
        disabled={disabled}
        onClick={() => {
          changeOrgToSelfServiceRequest({
            crmOrganizationUid: crmOrganization.uid,
          }).then(() => {
            window.location.reload();
          });
        }}
      >
        Change to Version 2 Organization
      </Button>
    );

    if (disabled) {
      return (
        <Tooltip title="Only organization without Enterprise app is allowed to change to Version 2." placement="bottom">
          {button}
        </Tooltip>
      );
    }
    return button;
  }, [checkOrgIsSelfServiceChangeable, loadingApplication, changeOrgToSelfServiceRequest, crmOrganization.uid]);
  return isLoadingOrganization ? (
    <Spinner />
  ) : (
    <OrganizationWrapper status={crmOrganization.status}>
      <PageTitle
        title="Organization"
        subTitle={`#${(crmOrganization.id && crmOrganization.id.toString()) || ''} [${
          crmOrganization.isSelfServe ? 'Version 2' : 'Version 1'
        }]`}
        showBack={true}
        extra={[changeOrgToSelfServiceButton]}
      >
        <DetailInfo>
          <Typography.Text copyable={true}>{crmOrganization.uid}</Typography.Text> / {crmOrganization.name} /{' '}
          <Tooltip title="Copy - Slug name (SSO)">
            <Tag onClick={() => copy(crmOrganization.slugName)} style={{ cursor: 'pointer' }}>
              {crmOrganization.slugName}
            </Tag>
          </Tooltip>
        </DetailInfo>
      </PageTitle>
      <Layout.Content style={{ padding: 0 }}>
        <Tabs
          activeKey={tabKey}
          onChange={handleTabChange}
          tabPosition="left"
          style={{ overflow: 'initial' }}
          size="small"
        >
          {/* TODO: remove below filter hard coding. It is added from Mickey's request */}
          {routes.map(({ label, key, route }) => {
            return (
              <Tabs.TabPane key={key} tab={label}>
                {route}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Layout.Content>
    </OrganizationWrapper>
  );
});
