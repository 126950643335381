import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { getUserDailyCountsRequest, getUserMonthlyCountsRequest } from '../api/user';
import { ResponsiveTable } from './ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { LineChartComponent } from './LineChartComponent';
import { useCheckMobile } from '../utils/screen';
import { getSearchDate, getSearchMonth } from '../utils/date';
import { Link } from 'react-router-dom';
const { Title } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function createColumns({ dataSources }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
    },
  ];

  dataSources.forEach((data, columnIndex) => {
    columns.push({
      title: data.date,
      dataIndex: `count-${columnIndex}`,
      key: `users-${columnIndex}`,
      align: alignRight,
      render: (count: number) => count && count.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSources, dataName, title }) {
  const source = {};
  dataSources.forEach((data, index) => {
    const count = data[dataName];
    source[`count-${index}`] = count;
    source['title'] = title;
  });

  return [source];
}

export const DashboardGrowthSignupUsers = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dataSourceDaily, setDataSourceDaily] = useState<Array<DashboardUserCount>>([]);
  const [dataSourceMonthly, setDataSourceMonthly] = useState<Array<DashboardUserCount>>([]);
  const [dailyLoading, setDailyLoading] = useState<boolean>(false);
  const [monthlyLoading, setMonthlyLoading] = useState<boolean>(false);

  function getUserDailyCounts() {
    const { startDate, endDate } = getSearchDate({ days: 14 });

    setDailyLoading(true);
    getUserDailyCountsRequest({ startDate, endDate })
      .then((response) => {
        setDataSourceDaily(response.data.results);
        setDailyLoading(false);
      })
      .catch((error) => {
        setDailyLoading(false);
        error && console.error(error.response);
      });
  }

  function getUserMonthlyCounts() {
    const { startMonth, endMonth } = getSearchMonth();

    setMonthlyLoading(true);
    getUserMonthlyCountsRequest({ startMonth, endMonth })
      .then((response) => {
        setDataSourceMonthly(response.data.results);
        setMonthlyLoading(false);
      })
      .catch((error) => {
        setMonthlyLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getUserDailyCounts();
    getUserMonthlyCounts();
  }, []);

  const dailyColumns = createColumns({
    dataSources: dataSourceDaily,
  });

  const dailyNewCounts = convertDataSource({
    dataSources: dataSourceDaily,
    dataName: 'new',
    title: 'New',
  });

  const dailyCumulativeCounts = convertDataSource({
    dataSources: dataSourceDaily,
    dataName: 'cumulative',
    title: 'Cumulative',
  });

  const monthlyColumns = createColumns({
    dataSources: dataSourceMonthly,
  });

  const monthlyNewCounts = convertDataSource({
    dataSources: dataSourceMonthly,
    dataName: 'new',
    title: 'New',
  });

  const monthlyCumulativeCounts = convertDataSource({
    dataSources: dataSourceMonthly,
    dataName: 'cumulative',
    title: 'Cumulative',
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card
          title="Signup user statistics"
          extra={<Link to={`/user_counts`}>Daily signup users</Link>}
          bodyStyle={{ background: '#f4f4f4' }}
        >
          <Row gutter={16}>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New signup users - Daily</Title>
                <LineChartComponent
                  title="New signup users - Daily"
                  unit="day"
                  labels={dataSourceDaily.map((data) => data.date)}
                  data1={dataSourceDaily.map((data) => data.new)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dailyLoading}
                  columns={dailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={dailyNewCounts}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>Cumulative signup users - Daily</Title>
                <LineChartComponent
                  title="Cumulative signup users - Daily"
                  unit="day"
                  labels={dataSourceDaily.map((data) => data.date)}
                  data1={dataSourceDaily.map((data) => data.cumulative)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dailyLoading}
                  columns={dailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1570 }}
                  dataSource={dailyCumulativeCounts}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New signup users - Monthly</Title>
                <LineChartComponent
                  title="New signup users - Monthly"
                  unit="month"
                  labels={dataSourceMonthly.map((data) => data.date)}
                  data1={dataSourceMonthly.map((data) => data.new)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={monthlyLoading}
                  columns={monthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1000 }}
                  dataSource={monthlyNewCounts}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>Cumulative signup users - Monthly</Title>
                <LineChartComponent
                  title="Cumulative signup users - Monthly"
                  unit="month"
                  labels={dataSourceMonthly.map((data) => data.date)}
                  data1={dataSourceMonthly.map((data) => data.cumulative)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={monthlyLoading}
                  columns={monthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1050 }}
                  dataSource={monthlyCumulativeCounts}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
