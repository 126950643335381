import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getUsersRequest({ limit, offset, q = '', location='', department='', state='' }) {
  return insightAxios.get(`${API_DOMAIN}/api/users/?limit=${limit}&offset=${offset}&q=${q}&location=${location}&department=${department}&state=${state}`,
    {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getUserRequest({ userId }) {
  return insightAxios.get(`${API_DOMAIN}/api/users/${userId}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getUserProfileRequest() {
  return insightAxios.get(`${API_DOMAIN}/api/users/profile/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getFiltersRequest() {
  return insightAxios.get(`${API_DOMAIN}/api/users/filters/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}


export function getUserStarredCrmApplicationsRequest({
  userId,
  offset,
  limit,
  query,
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/users/${userId}/starred_crm_applications/?offset=${offset}&limit=${limit}&query=${query}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getUserPermissionsRequest({ userId, offset, limit }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/users/${userId}/user_permissions/?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateUserRequest({ user, values }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/users/${user.id}/`,
    {
      state: values.state,
      department: values.department,
      teamType: values.teamType,
      companyLeftDate: values.companyLeftDate,
      location: values.location
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function createUserRequest({
  email,
  firstName,
  lastName,
  eid,
  teamType,
  department,
  state,
  location,
  companyJoinedDate,
}) {
  return insightAxios.post(
    `${API_DOMAIN}/api/users/`,
    {
      email,
      firstName,
      lastName,
      eid,
      teamType,
      department,
      state,
      location,
      companyJoinedDate,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function createUserPermissionRequest({ user_id, permission_name }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/user_permissions/`,
    {
      user_id: user_id,
      permission_name: permission_name,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function deleteUserPermissionRequest({ user_id, permission_name }) {
  return insightAxios.delete(
    `${API_DOMAIN}/api/user_permissions/`, {
      headers: {
        Authorization: getAuthToken(),
      },
      data: {
        user_id: user_id,
        permission_name: permission_name,
      },
    }
  );
}

export function getFavoriteAppRequest({ appId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_starred_crm_applications/is_starred/?appId=${appId}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function addFavoriteAppRequest({ appId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/user_starred_crm_applications/`,
    {
      appId,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function removeFavoriteAppRequest({ appId }) {
  return insightAxios.delete(
    `${API_DOMAIN}/api/user_starred_crm_applications/unstar/`,
    {
      data: {
        appId,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getUserDailyCountsRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_users/daily_counts/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getUserMonthlyCountsRequest({ startMonth, endMonth }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_users/monthly_counts/`, {
    params: {
      start_month: startMonth,
      end_month: endMonth,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getUserEventLogsRequest({ userId, offset, limit }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/users/${userId}/event_logs/?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getNewEmployeeListRequest({ location, startDate, endDate, limit, offset }) {
  return insightAxios.get(`${API_DOMAIN}/api/users/new_employee_list/?location=${location}&start_date=${startDate}&end_date=${endDate}&limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getUserAllPermissionsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/users/permissions/`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}
