import * as React from 'react';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Col, Row, Select, Layout } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { DatabaseOtpGenerateDialog } from '../../components/DatabaseOtpGenerateDialog';
import { DatabaseOtpCreateDialog } from '../../components/DatabaseOtpCreateDialog';
import { DatabaseOtpApproveDialog } from '../../components/DatabaseOtpApproveDialog';
import { EmergencyTag, OtpStatusTag } from '../../components/ui/Tags';

import { getDatabaseNamesRequest, getDatabaseOtpsRequest } from '../../api/otp';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import queryString from 'query-string';
import { PageTitle } from '../../components/ui/PageTitle';
import { DatabaseOtpPostApproveDialog } from '../../components/DatabaseOtpPostApproveDialog';

const { Option } = Select;

function renderTimestamp(timestamp) {
  if (timestamp)
    return (
      <>
        <span style={{ whiteSpace: 'nowrap' }}>{timestamp.split('T')[0]}</span>
        <br />
        {timestamp.split('T')[1]}
      </>
    );
}

const columns = ({ dialogSuccessCallback }: ColumnsProps) => [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: DatabaseOtp['id']) => <Link to={`/otp/database_otps/${id}`}>{id}</Link>,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'requester',
    render: (requester: DatabaseOtp['requester']) => (
      <span>
        {requester.username}
        {requester.eid ? `(${requester.eid})` : ''}
      </span>
    ),
  },
  {
    title: 'DB Name',
    dataIndex: 'dbName',
    key: 'dbName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: DatabaseOtp['status']) => <OtpStatusTag status={status} />,
  },
  {
    title: 'Is Emergency',
    dataIndex: 'isEmergency',
    key: 'isEmergency',
    render: (isEmergency: DatabaseOtp['isEmergency']) => <EmergencyTag isEmergency={isEmergency} />,
  },
  {
    title: 'Link',
    dataIndex: 'infoLink',
    key: 'infoLink',
    render: (link: DatabaseOtp['infoLink']) => {
      if (link && link !== '') {
        return (
          <a target="_blank" href={`${link}`}>
            Link
          </a>
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderTimestamp,
  },
  {
    title: (
      <span>
        Approved At(By) <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    key: 'approve',
    render: (databaseOtp: DatabaseOtp) =>
      (
        <>
        {databaseOtp.approvedAt && (
          <>
            <span style={{ whiteSpace: 'nowrap' }}>{databaseOtp.approvedAt.split('T')[0]}</span>
            <br />
            {databaseOtp.approvedAt.split('T')[1]}
            <br />
            </>
        )}
          {databaseOtp.approver ?
            (databaseOtp.approver.username) :
            (databaseOtp.status === 'OTP_GENERATED'
              || databaseOtp.status === 'APPROVAL_PASSED'
              || databaseOtp.status === 'OTP_USED') && (moment(databaseOtp.createdAt).utc().isAfter('2021-11-10 23:59:59Z'))
              ? <DatabaseOtpPostApproveDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />
              : '-'}
        </>
      ),
  },
  {
    title: (
      <span>
        Otp Generated At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'otpGeneratedAt',
    key: 'otpGeneratedAt',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (databaseOtp: DatabaseOtp) =>
      databaseOtp.status === 'APPROVAL_PASSED' ? (
        <DatabaseOtpGenerateDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />
      ) : databaseOtp.status === 'CREATED' ? (
        <DatabaseOtpApproveDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />
      ) : (
        '-'
      ),
  },
];

interface ColumnsProps {
  dialogSuccessCallback: () => void;
}

type Props = RouteComponentProps;

export const DatabaseOtps = withRouter<Props, any>(({ history }) => {
  const [databaseOtps, setDatabaseOtps] = useState<Array<DashboardOtp>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [datbaseNames, setDatabaseNames] = useState([]);
  const defaultPageLimit = 30;
  const [limit, setLimit] = useState(defaultPageLimit);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('');
  const statusNames = [
    { key: 'All', value: '' },
    { key: 'Created', value: 'CREATED' },
    { key: 'Approval Passed', value: 'APPROVAL_PASSED' },
    { key: 'Approval Denied', value: 'APPROVAL_DENIED' },
    { key: 'OTP Generated', value: 'OTP_GENERATED' },
  ];

  function getDatabaseOtps(limit = defaultPageLimit, offset = 0, status = '') {
    setLoading(true);
    getDatabaseOtpsRequest(limit, offset, status)
      .then((response) => {
        setDatabaseOtps(response.data.results);
        getDatabaseNames();
        setLoading(false);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function searchString(limit, offset, status) {
    return `?limit=${limit}&offset=${offset}&status=${status}`;
  }

  function getDatabaseNames() {
    getDatabaseNamesRequest()
      .then((response) => {
        setDatabaseNames(response.data.results);
      })
      .catch((error) => {});
  }

  function dialogSuccessCallback() {
    setStatus('');
    getDatabaseOtps(limit, offset, '');
  }

  const onStatusSelect = (status) => {
    setStatus(status);
    history.replace({
      pathname: '/otp/database_otps/',
      search: searchString(30, 0, status),
    });
    getDatabaseOtps(limit, offset, status);
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setLimit(limit);
    setOffset(offset);
    history.replace({
      pathname: '/otp/database_otps/',
      search: searchString(defaultPageLimit, 0, status),
    });
    getDatabaseOtps(limit, offset, status);
  };

  useEffect(function () {
    const qs = queryString.parse(window.location.search);
    const limit = parseInt(qs.limit ? (qs.limit as string) : '30');
    const offset = parseInt(qs.offset ? (qs.offset as string) : '0');
    const status = qs.status ? (qs.status as string) : '';

    setLimit(limit);
    setOffset(offset);
    setStatus(status);

    getDatabaseOtps(limit, offset, status);
  }, []);

  return (
    <Layout>
      <PageTitle
        title="Database OTPs"
        subTitle={`${count} results`}
        extra={[
          <DatabaseOtpCreateDialog
            key="databaseOTP"
            dbHostNames={datbaseNames}
            successCallback={dialogSuccessCallback}
          />,
        ]}
      />
      <Layout.Content>
        <Row>
          <Col span={12} md={{ span: 4 }} lg={{ span: 5 }}>
            <label className="SelectContainer__select-wrapper">
              <div className="SelectContainer__label">Status</div>
              <Select
                value={status as any}
                onSelect={onStatusSelect}
                defaultValue={statusNames[0]}
                placeholder="status"
                style={{ width: '100%' }}
              >
                {statusNames.map((status) => (
                  <Option key={status.key} value={status.value}>
                    {status.key}
                  </Option>
                ))}
              </Select>
            </label>
          </Col>
        </Row>
        <ResponsiveTable
          loading={loading}
          columns={columns({ dialogSuccessCallback })}
          dataSource={databaseOtps}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(offset / limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
