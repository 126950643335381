import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  RouteComponentProps,
  withRouter,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import { Typography, Tag } from 'antd';

import { getDailyRecordsRequest } from '../api/application';
import { PeakConnections } from '../components/PeakConnections';
import { TableDetail } from './ui/TableDetail';
import { ColumnProps } from 'antd/lib/table';
import { TimeunitEnum } from '../utils/constants';

const { Paragraph } = Typography;
const alignRight = 'right' as ColumnProps<any>['align'];
const columns = (appId: string) => [
  {
    title: 'Record Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'DAU',
    dataIndex: 'dau',
    align: alignRight,
    key: 'dau',
    render: (dau: CrmApplicationRecord['dau']) => dau.toLocaleString(),
  },
  {
    title: 'MAU',
    dataIndex: 'mau',
    align: alignRight,
    key: 'mau',
    render: (mau: CrmApplicationRecord['mau']) => mau.toLocaleString(),
  },
  {
    title: 'Daily Peak Connection',
    dataIndex: 'dailyPeakConnection',
    key: 'dailyPeakConnection',
    align: alignRight,
    render: (
      peakConnection: CrmApplicationRecord['dailyPeakConnection'],
      record: CrmApplicationRecord,
    ) => {
      const url = `/applications/${appId}/daily_records/ccu_counts/?startDate=${record.recordDate}&endDate=${record.recordDate}&timeunit=${TimeunitEnum.MINUTE}`;
      return <Link to={url}>{peakConnection.toLocaleString()}</Link>;
    },
  },
  {
    title: 'Daily Message Count',
    dataIndex: 'dailyMessageCount',
    key: 'dailyMessageCount',
    align: alignRight,
    render: (messageCount: CrmApplicationRecord['dailyMessageCount']) =>
      messageCount.toLocaleString(),
  },
  {
    title: 'Messaged User Count',
    dataIndex: 'messagedUserCount',
    key: 'messagedUserCount',
    align: alignRight,
    render: (messagedUserCount: CrmApplicationRecord['messagedUserCount']) =>
      messagedUserCount.toLocaleString(),
  },
  {
    title: 'Messaged Group Channel Count',
    dataIndex: 'messagedGroupChannelCount',
    key: 'messagedGroupChannelCount',
    align: alignRight,
    render: (channelCount: CrmApplicationRecord['messagedGroupChannelCount']) =>
      channelCount.toLocaleString(),
  },
  {
    title: 'Total Group Channel Message Count',
    dataIndex: 'totalGroupChannelMessageCount',
    key: 'totalGroupChannelMessageCount',
    align: alignRight,
    render: (
      messageCount: CrmApplicationRecord['totalGroupChannelMessageCount'],
    ) => messageCount.toLocaleString(),
  },
  {
    title: 'Messaged Open Channel Count',
    dataIndex: 'messagedOpenChannelCount',
    key: 'messagedOpenChannelCount',
    align: alignRight,
    render: (channelCount: CrmApplicationRecord['messagedOpenChannelCount']) =>
      channelCount.toLocaleString(),
  },
  {
    title: 'Total Open Channel Message Count',
    dataIndex: 'totalOpenChannelMessageCount',
    key: 'totalOpenChannelMessageCount',
    align: alignRight,
    render: (
      messageCount: CrmApplicationRecord['totalOpenChannelMessageCount'],
    ) => messageCount.toLocaleString(),
  },
];

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const DailyRecords = withRouter<Props, any>(({ match, history }) => {
  const { appId } = match.params;
  const [dataSource, setDatasource] = useState<Array<ApplicationPlan>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  });

  function getDailyRecords({ offset, limit }) {
    setLoading(true);
    getDailyRecordsRequest({ appId, offset, limit })
      .then((response) => {
        setDatasource(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handlePageChange({ offset, limit }) {
    setPagination({ offset, limit });
    getDailyRecords({ offset, limit });
  }

  useEffect(function () {
    getDailyRecords({ offset: pagination.offset, limit: pagination.limit });
  }, []);
  return (
    <Switch>
      <Route
        path={`/applications/:appId/daily_records/ccu_counts/`}
        component={PeakConnections}
      />
      <TableDetail
        title="DAU/MAU records"
        count={count}
        loading={loading}
        columns={columns(appId)}
        dataSource={dataSource}
        onPageChange={handlePageChange}
        defaultPageSize={20}
        pageSizeOptions={['10', '20', '50', '100']}
      >
        <Paragraph>
          <ul>
            <li>
              For the engineering team: This table shows the{' '}
              <Tag style={{ margin: 0 }}>CrmApplicationDailyRecord</Tag> data.
            </li>
            <li>
              Numbers might vary from real-time data from{' '}
              <Tag style={{ margin: 0 }}>soda</Tag> database.
            </li>
          </ul>
        </Paragraph>
      </TableDetail>
    </Switch>
  );
});
