import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { PageTitle } from '../../components/ui/PageTitle';
import { getUserMigrationRequestRequest } from '../../api/userMigrationRequest';
import { GeneralDetail } from '../../components/ui/GeneralDetail';
import { Layout, Tag } from 'antd';
import { Spinner } from '../../components/ui/Spinner';
import { UserMigrationRequestStatusTag } from '../../components/ui/Tags';

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

export const UserMigrationRequest = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { id } = match.params;
    const [userMigrationRequest, setUserMigrationRequest] = useState({} as UserMigrationRequest);

    function getUserMigrationRequest() {
      getUserMigrationRequestRequest({ id })
        .then((response) => {
          setUserMigrationRequest(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getUserMigrationRequest();
    }, []);

    if (!userMigrationRequest) {
      return <Spinner />;
    }

    const leftData = [
      {
        key: 'ID',
        value: userMigrationRequest.id,
      },
      {
        key: 'Status',
        value: <UserMigrationRequestStatusTag status={userMigrationRequest.status} />,
      },
      {
        key: 'Requester',
        value: userMigrationRequest.requester
          ? `${userMigrationRequest.requester.email} (${userMigrationRequest.requester.department})`
          : '',
      },
      {
        key: 'Reviewer',
        value: userMigrationRequest.reviewer
          ? `${userMigrationRequest.reviewer.email} (${userMigrationRequest.reviewer.department})`
          : '',
      },
      {
        key: (
          <span>
            Created Date <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: userMigrationRequest ? userMigrationRequest.createdAt : '-',
      },
      {
        key: (
          <span>
            Reviewed Date <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: userMigrationRequest ? userMigrationRequest.reviewedAt : '-',
      },
    ];
    const rightData = [
      { key: 'Reason', value: userMigrationRequest.reason },
      {
        key: 'Role',
        value: <Tag>{userMigrationRequest.role}</Tag>,
      },
      {
        key: (
          <span>
            From <small style={{ opacity: 0.5 }}>(ORG)</small>
          </span>
        ),
        value: userMigrationRequest.fromOrganization ? (
          <Link to={`/crm_organizations/${userMigrationRequest.fromOrganization.id}`}>
            {userMigrationRequest.fromOrganization.name}
          </Link>
        ) : (
          '-'
        ),
      },
      {
        key: (
          <span>
            To <small style={{ opacity: 0.5 }}>(ORG)</small>
          </span>
        ),
        value: userMigrationRequest.toOrganization ? (
          <Link to={`/crm_organizations/${userMigrationRequest.toOrganization.id}`}>
            {userMigrationRequest.toOrganization.name}
          </Link>
        ) : (
          '-'
        ),
      },
    ];

    return (
      <Layout>
        <PageTitle
          title="User Migration Request"
          subTitle={`#${(userMigrationRequest.id && userMigrationRequest.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <GeneralDetail leftData={leftData} rightData={rightData} />
        </Layout.Content>
      </Layout>
    );
  }),
);
