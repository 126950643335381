import {getUserAllPermissionsRequest} from '../api/user';

export const setPermission = () => {
  if (localStorage.getItem('username')) {
    getUserAllPermissionsRequest()
      .then((response) => {
        localStorage.removeItem('serializedPermissions');
        localStorage.setItem('serializedPermissions', JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log('Failed to load list of all user permissions (for front rendering purposes)');
      });
  }
};

export const hasPermission = (permission_name) => {
  try {
    const permissions = JSON.parse(localStorage.getItem('serializedPermissions') as string);
    return permissions.filter((element) => element['name'] === permission_name).length > 0;
  } catch (e) {
    return false;
  }
};

// keep as constants to make sure these are in sync with server and for easier refactoring
export const Permissions = {
  ACCESS_METRICS_FINANCE_SERVER_COST: 'ACCESS_METRICS_FINANCE_SERVER_COST',
  ACCESS_METRICS_FINANCE_CARR_TO_REVENUE_RATIO: 'ACCESS_METRICS_FINANCE_CARR_TO_REVENUE_RATIO',
  ACCESS_METRICS_FINANCE_GROSS_MARGIN: 'ACCESS_METRICS_FINANCE_GROSS_MARGIN',
  ACCESS_METRICS_PEOPLE_EMPLOYEE_RETENTION: 'ACCESS_METRICS_PEOPLE_EMPLOYEE_RETENTION',
  ACCESS_METRICS_SALES: 'ACCESS_METRICS_SALES',
  ACCESS_METRICS_REVENUE_OPEN_PIPELINE: 'ACCESS_METRICS_REVENUE_OPEN_PIPELINE',
  MANAGE_PAYMENT: 'MANAGE_PAYMENT',
  MANAGE_SUBSCRIPTION: 'MANAGE_SUBSCRIPTION',
  MANAGE_INSIGHT_USER: 'MANAGE_INSIGHT_USER',
  REVIEW_ORGANIZATION_PLAN_REQUEST: 'REVIEW_ORGANIZATION_PLAN_REQUEST',
  CREATE_ORGANIZATION_PLAN_REQUEST: 'CREATE_ORGANIZATION_PLAN_REQUEST',
  REVIEW_USER_MIGRATION_REQUEST: 'REVIEW_USER_MIGRATION_REQUEST',
  CREATE_USER_MIGRATION_REQUEST: 'CREATE_USER_MIGRATION_REQUEST',
  MANAGE_BILLING_SUBSCRIPTION: 'MANAGE_BILLING_SUBSCRIPTION',
  MANAGE_BILLING_SUBSCRIPTION_CREATION: 'MANAGE_BILLING_SUBSCRIPTION_CREATION',
  MANAGE_ORGANIZATION_VOUCHER_TYPE: 'MANAGE_ORGANIZATION_VOUCHER_TYPE',
  MANAGE_FORGOTTEN_REQUEST: 'MANAGE_FORGOTTEN_REQUEST',
  MANAGE_ORGANIZATION_ATTRIBUTES: 'MANAGE_ORGANIZATION_ATTRIBUTES',
};
