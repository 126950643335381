import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getDashboardOtpRequest } from '../../api/otp';

import { DashboardOtpDetailInfo } from '../../components/DashboardOtpDetailInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { Layout } from 'antd';

interface MatchParams {
  dashboardOtpId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const DashboardOtpDetail = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { dashboardOtpId } = match.params;

    const [dashboardOtp, setDashboardOtp] = useState({} as DashboardOtp);

    function getDashboardOtp() {
      getDashboardOtpRequest({ dashboardOtpId })
        .then((response) => {
          setDashboardOtp(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getDashboardOtp();
    }, []);

    return (
      <Layout>
        <PageTitle
          title="Dashboard OTP Detail"
          subTitle={`#${(dashboardOtp.id && dashboardOtp.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <DashboardOtpDetailInfo dashboardOtp={dashboardOtp} />
        </Layout.Content>
      </Layout>
    );
  }),
);
