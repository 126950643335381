import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { getCrmOrganizationMembersRequest } from '../../api/organization';
import { TableDetail } from '../ui/TableDetail';

const membersColumns = [
  {
    title: 'CrmUser ID',
    dataIndex: 'crmUser',
    key: 'crmUser',
    render: (crmUser: CrmUser) => (crmUser ? <Link to={`/crm_users/${crmUser.id}/`}>{crmUser.id}</Link> : '-'),
  },
  {
    title: 'Email',
    dataIndex: 'crmUser',
    key: 'crmUser',
    render: (crmUser: CrmUser) => (crmUser ? crmUser.email : '-'),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmOrganizationMember['status']) => (
      <Tag color={status === 'ACTIVE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {status}
      </Tag>
    ),
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

export const OrganizationMembers = ({ crmOrganizationId }) => {
  const [crmOrganizationMembers, setCrmOrganizationMembers] = useState([] as Array<CrmOrganizationMember>);

  const [membersCount, setMembersCount] = useState(0);
  const [loadingMembers, setLoadingMembers] = useState(false);

  function getCrmOrganizationMembers({ offset = 0, limit = 0 }) {
    setLoadingMembers(true);
    getCrmOrganizationMembersRequest({ limit, offset, crmOrganizationId })
      .then((response) => {
        setCrmOrganizationMembers(response.data.results);
        setLoadingMembers(false);
        setMembersCount(response.data.count);
      })
      .catch((error) => {
        console.error(error);
        setLoadingMembers(false);
      });
  }
  useEffect(() => {
    getCrmOrganizationMembers({
      offset: 0,
      limit: 10,
    });
  }, []);
  return (
    <TableDetail
      title="Members"
      count={membersCount}
      loading={loadingMembers}
      columns={membersColumns}
      dataSource={crmOrganizationMembers}
      onPageChange={getCrmOrganizationMembers}
    />
  );
};
