import React, { useState, useEffect } from 'react';
import { startCase, upperFirst, lowerCase } from 'lodash';
import styled from 'styled-components';

import { TableDetail } from '../ui/TableDetail';
import { RoleTypeTag } from '../ui/Tags';
import { getRolesByOrganizationRequest } from '../../api/organization';

const DetailTable = styled.table`
  border-collapse: collapse;
  min-width: 400px;
  thead {
    th {
      font-size: 14px;
      font-weight: 500;
      height: 20px;
      line-height: 20px;
      letter-spacing: -0.1px;
      color: rgba(0, 0, 0, 0.65);
      text-align: left;
      :nth-child(2) {
        width: 104px;
      }
    }
  }
  tbody {
    td {
      height: 28px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.1px;
      color: rgba(0, 0, 0, 0.5);
      :nth-child(2) {
        width: 104px;
      }
    }

    tr {
      :first-child {
        td {
          height: 32px;
          padding-top: 8px;
        }
      }
    }
  }
`;

type PermissionLevel = 'admin' | 'chat' | 'view' | 'tech' | 'sales';

type PermissionKey =
  | 'organization.general.all'
  | 'organization.general.view'
  | 'organization.security.all'
  | 'organization.security.view'
  | 'organization.members.all'
  | 'organization.members.view'
  | 'organization.roles.all'
  | 'organization.roles.view'
  | 'organization.applications.all'
  | 'organization.applications.view'
  | 'organization.organization.all'
  | 'organization.organization.view'
  | 'application.overview.view'
  | 'application.channels.openChannel.all'
  | 'application.channels.openChannel.chat'
  | 'application.channels.openChannel.view'
  | 'application.channels.groupChannel.all'
  | 'application.channels.groupChannel.chat'
  | 'application.channels.groupChannel.view'
  | 'application.announcements.all'
  | 'application.announcements.view'
  | 'application.dataExport.all'
  | 'application.dataExport.view'
  | 'application.messageSearch.all'
  | 'application.messageSearch.view'
  | 'application.users.all'
  | 'application.users.view'
  | 'application.analytics.view'
  | 'application.settings.all'
  | 'application.settings.view'
  | 'support.sales'
  | 'support.technical';

const getPermissionLevel = (permission): PermissionLevel => {
  return permission.split('.').slice(permission.split('.').length - 1, permission.split('.').length)[0];
};

const getPermissionPageName = (permission: PermissionKey) => {
  const parsed = upperFirst(
    lowerCase(permission.split('.').slice(permission.split('.').length - 2, permission.split('.').length - 1)[0]),
  );
  return parsed.includes('channel') ? `${parsed}s` : parsed;
};

export const OrganizationRoles = ({ crmOrganizationId }) => {
  const [organizationRoles, setOrganizationRoles] = useState([] as Array<OrganizationRoles>);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [rolesCount, setRolesCount] = useState(0);

  const rolesColumns = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (name: string) => <span style={{ fontWeight: 500 }}>{name === '' || !name ? '-' : name}</span>,
    },
    {
      title: 'RoleType',
      dataIndex: 'isPredefined',
      key: 'isPredefined',
      width: 100,
      render: (isPredefined: boolean) => <RoleTypeTag isPredefined={isPredefined} />,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: (description: string) => <span>{description === '' || !description ? '-' : description}</span>,
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (permissions: PermissionKey[]) => {
        return (
          <DetailTable>
            <thead>
              <tr>
                <th>Menu</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((permission) => {
                const category = permission.split('.')[0];
                return (
                  <tr key={permission}>
                    <td>
                      {startCase(category)}
                      {' > '}
                      {getPermissionPageName(permission)}
                    </td>
                    <td>{startCase(getPermissionLevel(permission))}</td>
                  </tr>
                );
              })}
            </tbody>
          </DetailTable>
        );
      },
    },
  ];

  const getRolesByOrganization = ({ offset = 0, limit = 10 }) => {
    setLoadingRoles(true);
    getRolesByOrganizationRequest({ crmOrganizationId, offset, limit })
      .then((response) => {
        setOrganizationRoles(response.data.results);
        setRolesCount(response.data.count);
        setLoadingRoles(false);
      })
      .catch((error) => {
        setLoadingRoles(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getRolesByOrganization({
      offset: 0,
      limit: 10,
    });
  }, []);
  return (
    <TableDetail
      title="Roles"
      count={rolesCount}
      loading={loadingRoles}
      columns={rolesColumns}
      dataSource={organizationRoles}
      onPageChange={getRolesByOrganization}
      pageSizeOptions={['5', '10', '15']}
    />
  );
};
