import axios from 'axios';
import { pickBy } from 'lodash';
import { API_DOMAIN } from '../../App';
import { SortKey } from './sortKeys';
import { FetchRankingResponse } from './types';

type APIParams = {
  order:
    | 'dau'
    | '-dau'
    | 'mau'
    | '-mau'
    | 'daily_message_count'
    | '-daily_message_count'
    | 'daily_peak_connection'
    | '-daily_peak_connection'
    | 'messaged_user_count'
    | '-messaged_user_count'
    | 'diff_dau'
    | '-diff_dau'
    | 'diff_mau'
    | '-diff_mau'
    | 'diff_daily_message_count'
    | '-diff_daily_message_count'
    | 'diff_daily_peak_connection'
    | '-diff_daily_peak_connection'
    | 'diff_messaged_user_count'
    | '-diff_messaged_user_count'
    | 'fluctuation_dau'
    | '-fluctuation_dau'
    | 'fluctuation_mau'
    | '-fluctuation_mau'
    | 'fluctuation_daily_message_count'
    | '-fluctuation_daily_message_count'
    | 'fluctuation_daily_peak_connection'
    | '-fluctuation_daily_peak_connection'
    | 'fluctuation_messaged_user_count'
    | '-fluctuation_messaged_user_count';
  region: string;
  date: string;
  hard_limit?: number;
  limit: number;
  offset: number;
  starred: boolean;
};

const fetchDailyRanking = async (params: APIParams) => {
  const { data } = await axios.get<FetchRankingResponse>(
    `${API_DOMAIN}/api/crm_application_trending_records/daily_ranking/`,
    {
      headers: { Authorization: `Token ${localStorage.getItem('token')}` },
      params: pickBy(params),
    },
  );
  return data;
};

const fetchWeeklyRanking = async (params: APIParams) => {
  const { data } = await axios.get<FetchRankingResponse>(
    `${API_DOMAIN}/api/crm_application_trending_records/weekly_ranking/`,
    {
      headers: { Authorization: `Token ${localStorage.getItem('token')}` },
      params: pickBy(params),
    },
  );
  return data;
};

export const fetchRanking = ({ sort, ...apiParams }: { sort: SortKey } & Omit<APIParams, 'order'>) => {
  if (sort.match(/^\-?week_/)) {
    const order = sort.replace('week_', '') as any;
    return fetchWeeklyRanking({ order, ...apiParams });
  }
  return fetchDailyRanking({ order: sort as any, ...apiParams });
};
