import * as React from 'react';
import { useState, useRef } from 'react';
import Chart, { TimeUnit, ChartTitleOptions, ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import styled from 'styled-components';
import { media } from '../utils/media';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ChartAnnotation from '@giliweb/chartjs-plugin-annotation';
import { Align } from 'chartjs-plugin-datalabels/types/options';

type Props = {
  title?: ChartTitleOptions['text'];
  unit?: TimeUnit;
  labels?: ChartData['labels'];
  data1?: ChartDataSets['data'];
  data2?: ChartDataSets['data'];
  annotationValue?: number;
  annotationName?: string;
  maintainAspectRatio?: ChartOptions['maintainAspectRatio'];
  height?: number;
  mobileHeight?: number;
  gradient?: boolean;
  callbacksTooltipLabel?: any;
  callBackYAxesTicks?: any;
  labelFormatter?: any;
};

const Canvas = styled.canvas<{ height?: number; mobileHeight?: number }>`
  min-height: ${(props) => (props.height ? props.height : 300)}px;
  max-height: ${(props) => (props.height ? props.height : 300)}px;

  ${media.MOBILE_LARGE`
    min-height: ${(props) => (props.mobileHeight ? props.mobileHeight : 220)}px;
    max-height: ${(props) => (props.mobileHeight ? props.mobileHeight : 220)}px;
  `}
`;

export const LineChartComponent: React.FC<Props> = React.memo(
  ({
    title,
    unit,
    labels,
    data1,
    data2,
    annotationValue,
    annotationName,
    maintainAspectRatio = false,
    height,
    mobileHeight,
    gradient = true,
    callbacksTooltipLabel = null,
    callBackYAxesTicks = null,
    labelFormatter,
  }) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart>();

    useDeepCompareEffect(
      function () {
        if (chart && chart.destroy) {
          chart.destroy();
        }
        drawChart();
      },
      [title, unit, labels, data1, data2],
    );

    function drawChart() {
      const context = (chartRef.current && chartRef.current.getContext('2d')) || undefined;

      if (context) {
        const gradientFill1 = context.createLinearGradient(0, 500, 0, 100);
        gradientFill1.addColorStop(0, 'rgba(0,80,191, 0.6)');
        gradientFill1.addColorStop(1, 'rgba(77,41,166, 0.6)');

        const gradientFill2 = context.createLinearGradient(0, 500, 0, 100);
        gradientFill2.addColorStop(0, 'rgba(0,80,191, 0.6)');
        gradientFill2.addColorStop(1, 'rgba(31,204,161, 0.6)');

        let chartData = {
          type: 'line',
          plugins: [] as any,

          data: {
            labels: labels,
            datasets: [
              {
                lineTension: 0.15,
                data: data1,
                backgroundColor: gradient ? gradientFill1 : 'rgba(130, 94, 235, 0.24)',
                borderColor: 'rgba(100,64,196,0.8)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(100,64,196,1)',
              },
              {
                lineTension: 0.15,
                data: data2,
                backgroundColor: gradient ? gradientFill2 : 'rgba(130, 94, 235, 0.24)',
                borderColor: 'rgba(31,204,161, 0.8)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(31,204,161, 1)',
              },
            ],
          },
          options: {
            animation: {
              easing: 'easeInOutBack' as any,
            },
            responsive: true,
            maintainAspectRatio,
            title: {
              display: true,
              text: title ? title : '',
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  id: 'y-axis-1',
                  ticks: {
                    beginAtZero: true,
                    callback: (label) => (typeof label === 'number' ? label.toLocaleString() : label),
                  },
                  type: 'linear',
                },
              ],
              xAxes:
                unit !== 'quarter'
                  ? [
                      {
                        type: 'time',
                        time: {
                          unit: unit ? unit : 'day',
                        },
                      },
                    ]
                  : [],
            },
            annotation: {
              annotations: [
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-1',
                  value: annotationValue,
                  borderColor: 'rgba(255, 0, 0, 0.5)',
                  borderWidth: 4,
                  label: {
                    yAdjust: 10,
                    enabled: true,
                    content: annotationName,
                    position: 'bottom',
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                formatter: function (value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
                display: function (context) {
                  return context.dataset.data[context.dataIndex] !== 0;
                },
                font: {
                  weight: 500,
                },
                align: function (context) {
                  return 'top' as Align;
                },
              },
            },
          },
        };

        if (callBackYAxesTicks) {
          chartData.options.scales.yAxes[0].ticks.callback = callBackYAxesTicks;
        }

        if (labelFormatter && Array.isArray(labels) && labels.length < 25) {
          chartData.plugins.push(ChartDataLabels);
          chartData.options.plugins.datalabels.formatter = labelFormatter;
        }

        if (callbacksTooltipLabel) {
          chartData.options['tooltips'] = {
            callbacks: {
              label: callbacksTooltipLabel,
            },
          };
        }

        if (annotationValue) {
          chartData.plugins.push(ChartAnnotation);
        }

        const chart = new Chart(context, chartData);
        setChart(chart);
      }
    }

    return <Canvas ref={chartRef} height={height} mobileHeight={mobileHeight} />;
  },
);
