import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import upperfirst from 'lodash.upperfirst';

import { Select, Row, Col, Tag, Layout } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { REGIONS } from '../../App';
import { getPayingApplicationsRequest, getLeadMetricRequest } from '../../api/billing';
import { ActiveTag } from '../../components/ui/Tags';
import { PageTitle } from '../../components/ui/PageTitle';

const { Option } = Select;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    key: 'appId',
    render: (appId: CrmApplication['appId']) => (
      <Link to={`/applications/${appId}/detail/`}>{appId.split('-')[0]}...</Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'appName',
    key: 'appName',
  },
  {
    title: 'Account Classification',
    dataIndex: 'noteAccountClassification',
    key: 'noteAccountClassification',
    render: (noteAccountClassification: CrmApplication['noteAccountClassification']) =>
      noteAccountClassification === '' || !noteAccountClassification ? '-' : <Tag>{noteAccountClassification}</Tag>,
  },
  {
    title: 'Account Lead',
    dataIndex: 'noteAccountLead',
    key: 'noteAccountLead',
    render: (noteAccountLead: CrmApplication['noteAccountLead']) => (noteAccountLead ? noteAccountLead.username : '-'),
  },
  {
    title: 'Subscription',
    dataIndex: 'hasActiveSubscription',
    key: 'hasActiveSubscription',
    render: (hasActiveSubscription: PayingApplication['hasActiveSubscription']) => (
      <ActiveTag isActive={hasActiveSubscription} />
    ),
  },
  {
    title: 'Organization',
    dataIndex: 'crmOrganization',
    key: 'crmOrganization',
    render: (crmOrganization: CrmApplication['crmOrganization']) =>
      crmOrganization ? (
        <Link to={`/crm_organizations/${crmOrganization.id}/`}>
          {crmOrganization.id} ({crmOrganization.name})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    render: (region: CrmApplication['region']) => (
      <Tag>
        {REGIONS.hasOwnProperty('name') ? REGIONS[region]['name'] : 'Invalid Region'} ({region})
      </Tag>
    ),
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (plan: CrmApplication['plan']) => (
      <Tag color={plan === 'enterprise' ? 'purple' : 'blue'}>{upperfirst(plan)}</Tag>
    ),
  },
];

type Props = RouteComponentProps;

export const PayingApplications = withRouter<Props, any>(({ location }) => {
  const [applications, setApplications] = useState<Array<PayingApplication>>([]);
  const [leads, setLeads] = useState<Array<LeadMetric>>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [leadLoading, setLeadLoading] = useState<boolean>(false);
  const [leadId, setLeadId] = useState(0);

  useEffect(() => {
    const q = parseInt(location.search.split('leadId=')[1]) || 0;
    setLeadId(q);
    getPayingApplications(q);
    getLeadMetric();
  }, [location]);

  function getPayingApplications(leadId) {
    setLoading(true);
    getPayingApplicationsRequest({ page: 1, leadId })
      .then((response) => {
        if (leadId === 0) {
          setCount(response.data.length);
        }
        setApplications(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function getLeadMetric() {
    setLeadLoading(true);
    getLeadMetricRequest()
      .then((response) => {
        setLeads(response.data);
        setLeadLoading(false);
      })
      .catch((error) => {
        setLeadLoading(false);
        console.error(error);
      });
  }

  function handleLeadChange(leadId) {
    setLeadId(leadId);
    getPayingApplications(leadId);
  }

  function handleOnRow(crmApplication: CrmApplication) {
    return {
      style: {
        textDecoration: crmApplication.removed ? 'line-through' : '',
        opacity: crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  const defaultLead = [{ count, leadId: 0, username: 'All' }];

  return (
    <Layout>
      <PageTitle
        title="Paying applications"
        subTitle={
          <ul>
            <li>Free and sandbox plan applications excluded.</li>
            <li>Removed applications excluded.</li>
            <li>
              <Tag>@sendbird.com</Tag>email owner applications excluded.
            </li>
            <li>Ordered by owner email.</li>
          </ul>
        }
      />
      <Layout.Content>
        <Row gutter={16} style={{ marginBottom: '8px' }}>
          <Col span={24} md={{ span: 5 }}>
            <Select
              defaultValue={0}
              value={leadId}
              loading={leadLoading}
              onChange={handleLeadChange}
              style={{ width: '100%' }}
            >
              {defaultLead.concat(leads).map(({ count, username, leadId: leadOptionId }) => (
                <Option key={leadId} value={leadOptionId}>
                  {username}({count})
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={applications}
          pagination={false}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
