import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getUserMigrationRequestsRequest({ limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_migration_requests/`,
    {
      params: { limit, offset, },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getUserMigrationRequestRequest({ id }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_migration_requests/${id}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function approveUserMigrationRequestRequest({ id }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/user_migration_requests/${id}/approve/`,
    {},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function denyUserMigrationRequestRequest({ id }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/user_migration_requests/${id}/deny/`,
    {},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function createUserMigrationRequestRequest({ userToMigrateEmail, fromOrganizationId, toOrganizationId, role, reason, }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/user_migration_requests/`,
    {
      userToMigrateEmail, fromOrganizationId, toOrganizationId, role, reason,
    },
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
