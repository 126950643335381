import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { getUserRoleRequest } from '../../api/roles';

import { PageTitle } from '../../components/ui/PageTitle';
import { GeneralDetail } from '../../components/ui/GeneralDetail';
import styled from 'styled-components';

const UserRoleContainer = styled.div<{ state: string }>`
  ${(props) =>
    props.state === 'EXPIRED' ? '* { text-decoration: line-through; }' : ''}
`;

interface MatchParams {
  userRoleId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const UserRole = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { userRoleId } = match.params;
    const [userRole, setUserRole] = useState<UserRole>({} as UserRole);

    function getUserRole() {
      getUserRoleRequest({ userRoleId })
        .then((response) => {
          setUserRole(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getUserRole();
    }, []);

    const leftData = [
      {
        key: 'ID',
        value: userRole.id,
      },
      {
        key: 'User ID',
        value: userRole.user ? (
          <Link to={`/users/${userRole.user.id}`}>{userRole.user.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Username',
        value: userRole.user ? userRole.user.username : '-',
      },
      {
        key: 'Role ID',
        value: userRole.role ? (
          <Link to={`/roles/${userRole.role.id}`}>{userRole.role.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Role Name',
        value: userRole.role ? userRole.role.name : '-',
      },
    ];

    const rightData = [
      {
        key: 'Granted At',
        value: userRole.grantedAt,
      },
      {
        key: 'Expired At',
        value: userRole.expiredAt,
      },
      {
        key: 'Status',
        value: userRole.status,
      },
    ];

    return (
      <UserRoleContainer state={userRole.status}>
        <PageTitle
          title="Granted Role Detail"
          subTitle={`#${(userRole.id && userRole.id.toString()) || ''}`}
          showBack={true}
        />
        <GeneralDetail leftData={leftData} rightData={rightData} />
      </UserRoleContainer>
    );
  }),
);
