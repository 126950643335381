import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash';

import { ProjectDetailInfo } from '../../components/deskMetrics/ProjectDetailInfo';

import { PageTitle } from '../../components/ui/PageTitle';
import { Spinner } from '../../components/ui/Spinner';
import { getProjectDetailRequest } from '../../api/deskMetrics';
import queryString from 'query-string';
import { DeskMetricsProjectDetailTicketList } from '../../components/DeskMetricsProjectDetailTicketList';
import { DeskMetricsProjectDetailAgentList } from '../../components/DeskMetricsProjectDetailAgentList';

interface MatchParams {
  projectPid: string;
}

type Props = RouteComponentProps<MatchParams>;

export const DeskMetricsProjectDetail = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { projectPid } = match.params;
    const [loadingProject, setLoadingProject] = useState(false);
    const [project, setProject] = useState({} as Project);
    const qs = queryString.parse(location.search);
    const region = qs.region ? qs.region : '';

    const getProject = () => {
      setLoadingProject(true);
      getProjectDetailRequest({ pid: projectPid, region: region })
        .then((response) => {
          setProject(response.data);
          setLoadingProject(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    useEffect(() => {
      getProject();
    }, []);

    return isEmpty(project) || loadingProject ? (
      <Spinner />
    ) : (
      <>
        <PageTitle
          title="Project Detail"
          subTitle={`#${(project.id && project.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <ProjectDetailInfo
            project={project}
          />
          <DeskMetricsProjectDetailTicketList region={region} projectPid={projectPid}/>
          <DeskMetricsProjectDetailAgentList region={region} projectPid={projectPid}/>
        </Layout.Content>
      </>
    );
  }),
);
