import React, { useState, useRef, useMemo, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Select, Typography, Layout } from 'antd';

import { PageTitle } from '../../components/ui/PageTitle';
import { ChatSubscriptionForm } from '../../components/organizationBilling/ChatSubscriptionForm';
import { TitleProps } from 'antd/lib/typography/Title';
import { getBrowserTodayMoment } from '../../utils/date';
import { SupportSubscriptionForm } from '../../components/organizationBilling/SupportSubscriptionForm';
import { DynamicForm } from '../../components/ui/DynamicForm';
import { FormInstance } from 'antd/lib/form';
import { fetchOrganizationBillingSubscriptionRequest } from '../../api/billing';
import { getSyncCrmOrganizationRequest } from '../../api/organization';

const { Option } = Select;
const { Title } = Typography;

export const today = getBrowserTodayMoment();
export const FormTitle = ({
  title,
  level = 3,
  style,
  isRequired = false,
}: {
  title: string;
  level?: TitleProps['level'];
  style?: any;
  isRequired?: boolean;
}) => (
  <Title
    level={level}
    style={{
      margin: 0,
      marginTop: '0px',
      marginBottom: '20px',
      paddingBottom: '6px',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      ...style,
    }}
  >
    {isRequired ? <span style={{ color: '#f5222d' }}>* </span> : ''}
    {title}
  </Title>
);

interface MatchParams {
  crmOrganizationId: string;
}

type Props = RouteComponentProps<MatchParams>;

enum SubscriptionProductEnum {
  CHAT = 'CHAT',
  SUPPORT = 'SUPPORT',
}

const subscriptionProducts = [
  { key: 'Chat', value: 'CHAT' },
  { key: 'Support', value: 'SUPPORT' },
];

const subscriptionPlans = {
  CHAT: [
    { key: 'Enterprise', value: 'enterprise' },
    { key: 'AWS Marketplace', value: 'aws_marketplace' },
    { key: 'Starter 5K', value: 'plan_a_5k' },
    { key: 'Starter 10K', value: 'plan_a_10k' },
    { key: 'Starter 25K', value: 'plan_a_25k' },
    { key: 'Starter 50K', value: 'plan_a_50k' },
    { key: 'Starter 100K', value: 'plan_a_100k' },
    { key: 'Pro 5K', value: 'plan_b_5k' },
    { key: 'Pro 10K', value: 'plan_b_10k' },
    { key: 'Pro 25K', value: 'plan_b_25k' },
    { key: 'Pro 50K', value: 'plan_b_50k' },
    { key: 'Pro 100K', value: 'plan_b_100k' },
    { key: 'AI Chatbot Starter', value: 'ai_chatbot_a' },
    { key: 'AI Chatbot Pro', value: 'ai_chatbot_b' },
  ],
  SUPPORT: [
    { key: 'Base', value: 'support_l0' },
    { key: 'L1', value: 'support_l1' },
    { key: 'L2', value: 'support_l2' },
    { key: 'L3', value: 'support_l3' },
    { key: 'L4', value: 'support_l4' },
    { key: 'Silver', value: 'support_silver' },
    { key: 'Gold', value: 'support_gold' },
    { key: 'Platinum', value: 'support_platinum' },
  ],
};

export const OrganizationBillingSubscriptionCreation = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { crmOrganizationId } = match.params;
    const subscriptionRequestId = new URLSearchParams(location.search).get('subscription_request_id');

    const formRef = useRef<FormInstance>();
    const [subscriptionProduct, setSubscriptionProduct] = useState(SubscriptionProductEnum.CHAT);
    const [subscriptionPlan, setSubscriptionPlan] = useState(subscriptionPlans.CHAT[0].value);
    const [subscriptionRequest, setSubscriptionRequest] = useState<SubscriptionRequest>();
    const [crmOrganization, setCrmOrganization] = useState<CrmOrganization>();

    useEffect(() => {
      getSyncCrmOrganizationRequest({ crmOrganizationId })
        .then((response) => {
          setCrmOrganization(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      if (subscriptionRequestId) {
        fetchOrganizationBillingSubscriptionRequest({ id: subscriptionRequestId })
          .then((response) => {
            setSubscriptionRequest(response.data);
            setSubscriptionProduct(JSON.parse(response.data.requestBody).product.toUpperCase());
            setSubscriptionPlan(JSON.parse(response.data.requestBody).subscription_name);
            formRef.current?.setFieldsValue({
              subscripion_product: JSON.parse(response.data.requestBody).product.toUpperCase(),
              subscription_plan: JSON.parse(response.data.requestBody).subscription_name,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, [subscriptionRequestId]);

    const handleSubscriptionProductChange = (value) => {
      setSubscriptionProduct(value);
    };

    const handleSubscriptionPlanChange = (value) => {
      setSubscriptionPlan(value);
    };

    const subscriptionTypeFields: Field[] = [
      {
        name: 'Subscription',
        label: 'Subscription',
        component: {},
        formItemProps: {},
        fields: [
          {
            name: 'subscripion_product',
            label: 'Product',
            formItemProps: {
              rules: [
                () => ({
                  validator(_, value, callback) {
                    if (value === SubscriptionProductEnum.CHAT) {
                      formRef.current?.setFieldsValue({
                        subscription_plan: subscriptionPlans.CHAT[0].value,
                      });
                      setSubscriptionPlan(subscriptionPlans.CHAT[0].value);
                    } else {
                      formRef.current?.setFieldsValue({
                        subscription_plan: subscriptionPlans.SUPPORT[0].value,
                      });
                      setSubscriptionPlan(subscriptionPlans.SUPPORT[0].value);
                    }
                    callback();
                  },
                }),
              ],
            },
            span: 6,
            component: (
              <Select
                defaultValue={SubscriptionProductEnum.CHAT}
                size="large"
                style={{ width: '100%', marginTop: '10px' }}
                onChange={handleSubscriptionProductChange}
                disabled={subscriptionRequestId ? true : false}
              >
                {subscriptionProducts.map((opt) => (
                  <Option key={opt.key} value={opt.value}>
                    {opt.key}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            name: 'subscription_plan',
            label: 'Plan',
            formItemProps: {},
            span: 6,
            component: (
              <Select
                defaultValue={subscriptionPlans.CHAT[0].value}
                size="large"
                style={{ width: '100%', marginTop: '10px' }}
                onChange={handleSubscriptionPlanChange}
                disabled={subscriptionRequestId ? true : false}
              >
                {subscriptionPlans[subscriptionProduct].map((opt) => (
                  <Option key={opt.key} value={opt.value}>
                    {opt.key}
                  </Option>
                ))}
              </Select>
            ),
          },
        ],
      },
    ];

    const setForm = (ref) => {
      formRef.current = ref;
    };

    const selectedPlan = useMemo(
      () => subscriptionPlans[subscriptionProduct].filter((item) => item.value === subscriptionPlan)[0],
      [subscriptionProduct, subscriptionPlan],
    );

    return (
      <Layout>
        <PageTitle
          title="Organization Billing Subscription Creation"
          subTitle={`#${(crmOrganizationId && crmOrganizationId.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <DynamicForm fields={subscriptionTypeFields} setForm={setForm} />
          {subscriptionProduct === SubscriptionProductEnum.CHAT && (
            <ChatSubscriptionForm
              crmOrganization={crmOrganization}
              product={subscriptionProduct}
              plan={selectedPlan}
              subscriptionRequest={subscriptionRequest}
            />
          )}
          {subscriptionProduct === SubscriptionProductEnum.SUPPORT && (
            <SupportSubscriptionForm
              crmOrganization={crmOrganization}
              product={subscriptionProduct}
              plan={selectedPlan}
              subscriptionRequest={subscriptionRequest}
            />
          )}
        </Layout.Content>
      </Layout>
    );
  }),
);
