import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

import {Layout} from 'antd';
import './App.css';
import 'antd/dist/antd.css';

import {BrowserRouter as Router, Switch} from 'react-router-dom';

import {AuthLink} from './components/AuthLinks';
import {signout} from './utils/auth';
import {useCheckMobile} from './utils/screen';
import {Routes} from './routes/Routes';
import {setPermission} from './utils/permission';
import {GlobalStyles} from './GlobalStyles';
import {AuthenticationProvider} from './containers/AuthenticationProvider';

const NavigationMobile = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  height: 60px;
`;

export const API_DOMAIN =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://insight.sendbird.com'
    : process.env.REACT_APP_BUILD_MODE === 'staging' || process.env.REACT_APP_ENV === 'staging'
    ? 'https://insight-staging.sendbird.com'
    : 'http://localhost:8000';

export const WEB_DOMAIN =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://insight.sendbird.com'
    : process.env.REACT_APP_BUILD_MODE === 'staging' || process.env.REACT_APP_ENV === 'staging'
    ? 'https://insight-staging.sendbird.com'
    : 'http://localhost:3000';

export const REGIONS =
  process.env.REACT_APP_ENV === 'production'
    ? {
        'ap-1': {
          dapi_url: 'https://dapi-ap-1.sendbird.com',
          api_url: 'https://api.sendbird.com',
          name: 'Tokyo',
          continent: 'Asia/Pacific',
          city: 'Tokyo',
          type: 'SHARED',
        },
        'ap-2': {
          dapi_url: 'https://dapi-ap-2.sendbird.com',
          api_url: 'https://api-ap-2.sendbird.com',
          name: 'Seoul',
          continent: 'Asia/Pacific',
          city: 'Seoul',
          type: 'SHARED',
        },
        'ap-3': {
          dapi_url: 'https://dapi-ap-3.sendbird.com',
          api_url: 'https://api-ap-3.sendbird.com',
          name: 'Singapore GT',
          continent: 'Asia/Pacific',
          city: 'Singapore',
          type: 'DEDICATED',
        },
        'ap-4': {
          dapi_url: 'https://dapi-ap-4.sendbird.com',
          api_url: 'https://api-ap-4.sendbird.com',
          name: 'Carousell',
          continent: 'Asia/Pacific',
          city: 'Singapore',
          type: 'DEDICATED',
        },
        'ap-5': {
          dapi_url: 'https://dapi-ap-5.sendbird.com',
          api_url: 'https://api-ap-5.sendbird.com',
          name: 'Singapore',
          continent: 'Asia/Pacific',
          city: 'Singapore',
          type: 'SHARED',
        },
        'ap-6': {
          dapi_url: 'https://dapi-ap-6.sendbird.com',
          api_url: 'https://api-ap-6.sendbird.com',
          name: 'Ola (Removed)',
          continent: 'Asia/Pacific',
          city: 'Mumbai',
          type: 'DEDICATED',
        },
        'ap-8': {
          dapi_url: 'https://dapi-ap-8.sendbird.com',
          api_url: 'https://api-ap-8.sendbird.com',
          name: 'Mumbai',
          continent: 'Asia/Pacific',
          city: 'Mumbai',
          type: 'DEDICATED',
        },
        'ap-9': {
          dapi_url: 'https://dapi-ap-9.sendbird.com',
          api_url: 'https://api-ap-9.sendbird.com',
          name: 'Sydney',
          continent: 'Asia/Pacific',
          city: 'Sydney',
          type: 'SHARED',
        },
        'us-1': {
          dapi_url: 'https://dapi-us-1.sendbird.com',
          api_url: 'https://api-us-1.sendbird.com',
          name: 'Oregon',
          continent: 'United States',
          city: 'Oregon',
          type: 'SHARED',
        },
        'us-2': {
          dapi_url: 'https://dapi-us-2.sendbird.com',
          api_url: 'https://api-us-2.sendbird.com',
          name: 'North Virginia',
          continent: 'United States',
          city: 'North Virginia',
          type: 'SHARED',
        },
        'us-3': {
          dapi_url: 'https://dapi-us-3.sendbird.com',
          api_url: 'https://api-us-3.sendbird.com',
          name: 'North Virginia',
          continent: 'United States',
          city: 'North Virginia',
          type: 'SHARED',
        },
        'eu-1': {
          dapi_url: 'https://dapi-eu-1.sendbird.com',
          api_url: 'https://api-eu-1.sendbird.com',
          name: 'Frankfurt',
          continent: 'Europe',
          city: 'Frankfurt',
          type: 'SHARED',
        },
        'ca-1': {
          dapi_url: 'https://dapi-ca-1.sendbird.com',
          api_url: 'https://api-ca-1.sendbird.com',
          name: 'Central',
          continent: 'Canada',
          city: 'Central',
          type: 'SHARED',
        },
        vmuae: {
          dapi_url: 'https://dapi-vmuae.sendbird.com',
          api_url: 'https://api-vmuae.sendbird.com',
          name: 'VMUAE',
          continent: 'Asia/Pacific',
          city: 'Singapore',
          type: 'DEDICATED',
        },
        accolade: {
          dapi_url: 'https://dapi-accolade.sendbird.com',
          api_url: 'https://api-accolade.sendbird.com',
          name: 'ACCOLADE',
          continent: 'United States',
          city: 'North Virginia',
          type: 'DEDICATED',
        },
        yahoo: {
          dapi_url: 'https://dapi-yahoo.sendbird.com',
          api_url: 'https://api-yahoo.sendbird.com',
          name: 'YAHOO',
          continent: 'United States',
          city: 'North Virginia',
          type: 'DEDICATED',
        },
        dream11: {
          dapi_url: 'https://dapi-dream11.sendbird.com',
          api_url: 'https://api-dream11.sendbird.com',
          name: 'DREAM11',
          continent: 'Asia/Pacific',
          city: 'Mumbai',
          type: 'DEDICATED',
        },
        paytm: {
          dapi_url: 'https://dapi-paytm.sendbird.com',
          api_url: 'https://api-paytm.sendbird.com',
          name: 'PAYTM',
          continent: 'Asia/Pacific',
          city: 'Mumbai',
          type: 'DEDICATED',
        },
        schibsted: {
          dapi_url: 'https://dapi-schibsted.sendbird.com',
          api_url: 'https://api-schibsted.sendbird.com',
          name: 'SCHIBSTED',
          continent: 'Europe',
          city: 'Frankfurt',
          type: 'DEDICATED',
        },
        grandrounds: {
          dapi_url: 'https://dapi-grandrounds.sendbird.com',
          api_url: 'https://api-grandrounds.sendbird.com',
          name: 'GRANDROUNDS',
          continent: 'United States',
          city: 'North Virginia',
          type: 'DEDICATED',
        },
        livongo: {
          dapi_url: 'https://dapi-livongo.sendbird.com',
          api_url: 'https://api-livongo.sendbird.com',
          name: 'LIVONGO',
          continent: 'United States',
          city: 'North Virginia',
          type: 'DEDICATED',
        },
        nexon: {
          dapi_url: 'https://dapi-nexon.sendbird.com',
          api_url: 'https://api-nexon.sendbird.com',
          name: 'Nexon',
          continent: 'Asia/Pacific',
          city: 'Seoul',
          type: 'DEDICATED',
        },
        woowa: {
          dapi_url: 'https://dapi-woowa.sendbird.com',
          api_url: 'https://api-woowa.sendbird.com',
          name: 'Woowa',
          continent: 'Asia/Pacific',
          city: 'Seoul',
          type: 'DEDICATED',
        },
        woowatest: {
          dapi_url: 'https://dapi-woowatest.sendbird.com',
          api_url: 'https://api-woowatest.sendbird.com',
          name: 'WoowaTest',
          continent: 'Asia/Pacific',
          city: 'Seoul',
          type: 'DEDICATED',
        },
        paypay: {
          dapi_url: 'https://dapi-paypay.sendbird.com',
          api_url: 'https://api-paypay.sendbird.com',
          name: 'Paypay',
          continent: 'Asia/Pacific',
          city: 'Tokyo',
          type: 'DEDICATED',
        },
        rakuten: {
          dapi_url: 'https://dapi-rakuten.sendbird.com',
          api_url: 'https://api-rakuten.sendbird.com',
          name: 'Rakuten',
          continent: 'Asia/Pacific',
          city: 'Tokyo',
          type: 'DEDICATED',
        },
        doordash: {
          dapi_url: 'https://dapi-doordash.sendbird.com',
          api_url: 'https://api-doordash.sendbird.com',
          name: 'Doordash',
          continent: 'United States',
          city: 'Oregon',
          type: 'DEDICATED',
        },
        picpay: {
          dapi_url: 'https://dapi-picpay.sendbird.com',
          api_url: 'https://api-picpay.sendbird.com',
          name: 'PicPay',
          continent: 'South America',
          city: 'Sao Paulo',
          type: 'DEDICATED',
        },
        hinge: {
          dapi_url: 'https://dapi-hinge.sendbird.com',
          api_url: 'https://api-hinge.sendbird.com',
          name: 'Hinge',
          continent: 'United States',
          city: 'North Virginia',
          type: 'DEDICATED',
        },
        pubg: {
          dapi_url: 'https://dapi-pubg.sendbird.com',
          api_url: 'https://api-pubg.sendbird.com',
          name: 'PUBG New State',
          continent: 'Asia Pacific',
          city: 'Singapore',
          type: 'DEDICATED',
        },
      }
    : {
        staging: {
          dapi_url: 'https://dapi-staging.sendbird.com',
          api_url: 'https://api-staging.sendbird.com',
          name: 'Staging (Tokyo)',
          continent: 'Asia/Pacific',
          city: 'Tokyo',
          type: 'SHARED',
        },
        preprod: {
          dapi_url: 'https://dapi-staging.sendbird.com',
          api_url: 'https://api-staging.sendbird.com',
          name: 'Preprod (Tokyo)',
          continent: 'Asia/Pacific',
          city: 'Tokyo',
          type: 'SHARED',
        },
      };

function initSentry() {
  if (window.Sentry && process.env.REACT_APP_ENV === 'production') {
    window.Sentry &&
      window.Sentry.init({
        dsn: 'https://f0d07e559d1241e98025fe61b8918ff4@sentry.io/216995',
      });
  }
}

export const App = () => {
  const isMobile = useCheckMobile();
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(function () {
    initSentry();
    setPermission();
  }, []);
  return (
    <AuthenticationProvider>
      <div className="wrapper" ref={wrapperRef}>
        <Layout>
          <Router>
            <Switch>
              <>
                <Layout hasSider>
                  {!isMobile ? (
                    <AuthLink signout={signout} isMobile={isMobile} />
                  ) : (
                    <React.Fragment>
                      <NavigationMobile>
                        <AuthLink signout={signout} isMobile={isMobile} />
                      </NavigationMobile>
                    </React.Fragment>
                  )}
                  <Layout className="site-layout" style={{ marginLeft: 210 }}>
                    <Routes />
                  </Layout>
                </Layout>
              </>
            </Switch>
          </Router>
        </Layout>
        <GlobalStyles />
      </div>
    </AuthenticationProvider>
  );
};
