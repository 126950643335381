import * as React from 'react';
import { useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { updateApplicationPauseRequest, updateApplicationUnpauseRequest } from '../api/application';

type Props = {
  appId: CrmApplication['appId'];
  pausedDate: CrmApplication['pausedDate'];
  successCallback: PrivateRouteProps['getCrmApplicationRequest'];
};

export const ApplicationPausedDateEditDialog: React.FC<Props> = ({ appId, pausedDate, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);

    let func = updateApplicationPauseRequest;
    if (pausedDate) {
      func = updateApplicationUnpauseRequest;
    }

    func({ appId })
      .then((response) => {
        console.log('response', response);
        successCallback && successCallback();
        handleCancel();
      })
      .catch((error) => {
        console.error('error', error);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        icon={<SwapOutlined />}
        size="small"
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          verticalAlign: 'middle',
          background: pausedDate ? '' : '#FF4C4C',
          borderColor: pausedDate ? '' : '#FF4C4C',
        }}
      >
        {pausedDate ? 'Unpause' : 'Pause'}
      </Button>
      <Modal
        open={visible}
        title={`${pausedDate ? 'Unpause' : 'Pause'} Application`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            style={{
              background: pausedDate ? '' : '#FF4C4C',
              borderColor: pausedDate ? '' : '#FF4C4C',
            }}
          >
            Submit
          </Button>,
        ]}
      >
        Are you sure to <b>{pausedDate ? 'Unpause' : 'Pause'}</b>?
        <br />
        {pausedDate
          ? "It will release the application usage restriction as 'enterprise' plan"
          : "It will restrict the application usage as 'free' plan"}
      </Modal>
    </>
  );
};
