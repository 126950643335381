import _ from 'lodash';

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function NumberToShortString(num, fractionDigits = 0) {
  if (typeof num !== 'number') {
    return num;
  }
  let prefix = '';
  if (num < 0) {
    prefix = '-';
    num = -num;
  }
  if (num >= Math.pow(10, 11)) {
    return prefix + (num / Math.pow(10, 9)).toFixed(0) + 'B';
  } else if (num >= Math.pow(10, 9)) {
    return prefix + (num / Math.pow(10, 9)).toFixed(1) + 'B';
  } else if (num >= Math.pow(10, 8)) {
    return prefix + (num / Math.pow(10, 6)).toFixed(0) + 'M';
  } else if (num >= Math.pow(10, 6)) {
    return prefix + (num / Math.pow(10, 6)).toFixed(1) + 'M';
  } else if (num >= Math.pow(10, 5)) {
    return prefix + (num / Math.pow(10, 3)).toFixed(0) + 'K';
  } else if (num >= Math.pow(10, 3)) {
    return prefix + (num / Math.pow(10, 3)).toFixed(1) + 'K';
  } else {
    return prefix + num.toFixed(fractionDigits);
  }
}

export function LabelFormatter({ prefix = '', suffix = '', fractionDigits = 0 }) {
  return function (value, context) {
    return prefix + NumberToShortString(value, fractionDigits) + suffix;
  };
}

export const percentLabelFormatter = LabelFormatter({ suffix: '%', fractionDigits: 2 });
export const threeDigitsPercentLabelFormatter = LabelFormatter({ suffix: '%', fractionDigits: 3 });
export const dollarLabelFormatter = LabelFormatter({ prefix: '$' });
export const numberLabelFormatter = LabelFormatter({});
export const floatLabelFormatter = LabelFormatter({ fractionDigits: 2 });

export const okrColor = function (progress) {
  if (progress <= 0.4) return 'red';
  else if (progress <= 0.7) return 'yellow';
  else if (progress <= 1.0) return 'green';
  else return 'blue';
};

export const keysToSnake = (obj) => {
  let convertingObject = _.cloneDeep(obj);

  // Convert keys to snake case
  convertingObject = _.mapKeys(convertingObject, (value, key) => {
    return _.snakeCase(key);
  });

  // Recursively apply throughout object
  return _.mapValues(convertingObject, (value) => {
    if (_.isPlainObject(value)) {
      return keysToSnake(value);
    } else if (_.isArray(value)) {
      return _.map(value, keysToSnake);
    } else {
      return value;
    }
  });
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};