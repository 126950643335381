import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { Input, Select, Layout, Checkbox } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCrmApplicationsRequest } from '../../api/application';
import { RegionTag, PlanTag, CrmOrganizationVersionTypeTag } from '../../components/ui/Tags';
import { ColumnProps } from 'antd/lib/table';
import { TableFilter } from '../../components/ui/TableFilter';
import { PrettyDateTime } from '../../components/ui/DataText';
import { PageTitle } from '../../components/ui/PageTitle';
import { regionNames } from '../../utils/constants';

const { Search } = Input;
const { Option } = Select;

const alignRight = 'right' as ColumnProps<any>['align'];

const plans = [
  { key: 'All Plans', value: '' },
  { key: 'Free', value: 'free' },
  { key: 'Enterprise', value: 'enterprise' },
];

const removedFilters = [
  { key: 'No', value: 'false' },
  { key: 'Yes', value: 'true' },
  { key: 'All', value: 'all' },
];

const deskEnabledFilters = [
  { key: 'All', value: '' },
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' },
];

const callsEnabledFilters = [
  { key: 'All', value: '' },
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' },
];

const Paused = [
  { key: 'All', value: '' },
  { key: 'Paused', value: 'paused' },
];

const orders = [
  { key: 'Default', value: '' },
  { key: 'AVG7 DAU', value: '-last_seven_days_average_dau' },
  { key: 'AVG7 Peak Connection', value: '-last_seven_days_peak_connection' },
  { key: 'AVG7 Msg Count', value: '-last_seven_days_average_message_count' },
];

type Props = RouteComponentProps;

export const CrmApplications = withRouter<Props, any>(({ location, history }) => {
  const [crmApplications, setCrmApplication] = useState<Array<CrmApplication>>([]);

  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 30,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    searchQuery: qs.query ? (qs.query as string) : '',
    region: qs.region ? (qs.region as string) : '',
    plan: qs.plan ? (qs.plan as string) : '',
    removed: qs.removed ? (qs.removed as string) : 'false',
    order: qs.order ? (qs.order as string) : '',
    deskEnabled: qs.deskEnabled ? (qs.deskEnabled as string) : '',
    callsEnabled: qs.callsEnabled ? (qs.callsEnabled as string) : '',
    paused: qs.paused ? (qs.paused as string) : '',
    is_test_organization: qs.is_test_organization ? (qs.is_test_organization as string) : 'false',
  });
  const [searchQuery, setSearchQuery] = useState(qs.searchQuery ? (qs.searchQuery as string) : '');

  const [loading, setLoading] = useState(false);
  const [crmApplicationsCount, setCrmApplicationsCount] = useState(0);

  let columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'App ID',
      dataIndex: 'appId',
      key: 'appId',
      render: (appId: CrmApplication['appId']) => (
        <Link to={`/applications/${appId}/detail/`}>{appId.split('-')[0]}...</Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'appName',
      key: 'appName',
    },
    {
      title: 'Organization',
      dataIndex: 'crmOrganization',
      key: 'crmOrganization',
      render: (crmOrganization: CrmOrganization) => (
        <Link to={crmOrganization === null ? '/crm_organizations/' : `/crm_organizations/${crmOrganization.id}/`}>
          {crmOrganization === null ? 'None' : `${crmOrganization.id} (${crmOrganization.name})`}
        </Link>
      ),
    },
    {
      title: 'Organization Type',
      render: (_, crmApplication) => {
        if (crmApplication.crmOrganization) {
          return <CrmOrganizationVersionTypeTag isSelfServe={crmApplication.crmOrganization.isSelfServe} />;
        } else {
          return <>None</>;
        }
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      render: (region: CrmApplication['region']) => <RegionTag region={region} />,
    },
    {
      title: (
        <span>
          Average DAU <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysAverageDau',
      key: 'lastSevenDaysAverageDau',
      align: alignRight,
      render: (lastSevenDaysAverageDau: number) => lastSevenDaysAverageDau.toLocaleString(),
    },
    {
      title: (
        <span>
          Peak Connection <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysPeakConnection',
      key: 'lastSevenDaysPeakConnection',
      align: alignRight,
      render: (lastSevenDaysPeakConnection: number) => lastSevenDaysPeakConnection.toLocaleString(),
    },
    {
      title: (
        <span>
          Average Messages <small style={{ display: 'inline-block' }}>(7 days)</small>
        </span>
      ),
      dataIndex: 'lastSevenDaysAverageMessageCount',
      key: 'lastSevenDaysAverageMessageCount',
      align: alignRight,
      render: (lastSevenDaysAverageMessageCount: number) => lastSevenDaysAverageMessageCount.toLocaleString(),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan, crmApplication) => {
        if (crmApplication.crmOrganization && crmApplication.crmOrganization.isSelfServe) {
          return '-';
        } else if (crmApplication.crmOrganization) {
          return <PlanTag plan={plan} />;
        } else {
          return '-';
        }
      },
    },
    {
      title: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (datetime: CrmApplication['createdAt']) => <PrettyDateTime datetime={datetime} />,
    },
  ];

  if (query.paused === 'paused') {
    columns = columns.concat({
      title: 'Paused Date',
      dataIndex: 'pausedDate',
      key: 'pausedDate',
    });
  }

  useEffect(() => {
    history.replace({
      pathname: '/applications/',
      search: queryString.stringify(query),
    });
    getCrmApplications();
  }, [query]);

  const getCrmApplications = () => {
    setLoading(true);
    getCrmApplicationsRequest({ ...query })
      .then((response) => {
        if (response.data != null && !response.data.hasOwnProperty('results')) {
          setCrmApplication([response.data]);
        } else {
          setCrmApplication(response.data.results);
        }
        setLoading(false);
        setCrmApplicationsCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        if (error.response.data.detail == 'Unknown crm_application.') {
          setCrmApplication([]);
        }
      });
  };

  const onSearch = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, searchQuery: value });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onRegionSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, region: value });
  };

  const onPlanSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, plan: value });
  };

  const onOrderSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, order: value });
  };

  const onRemovedSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, removed: value });
  };

  const onDeskEnabledSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, deskEnabled: value });
  };

  const onCallsEnabledSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, callsEnabled: value });
  };

  const onPausedSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, paused: value });
  };

  const onTestOrganizationClick = (event) => {
    if (event.target.checked) {
      setQuery({ ...query, limit: 30, offset: 0, is_test_organization: 'true' });
    } else {
      setQuery({ ...query, limit: 30, offset: 0, is_test_organization: 'false' });
    }
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  function handleOnRow(crmApplication: CrmApplication) {
    return {
      style: {
        textDecoration: crmApplication.removed ? 'line-through' : '',
        opacity: crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  let tableFilter = [
    {
      label: 'Region',
      key: 'region',
      node: (
        <Select value={query.region} onSelect={onRegionSelect} style={{ width: '100%' }}>
          {regionNames.map((region) => (
            <Option key={region.key} value={region.value}>
              {region.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Plan',
      key: 'plan',
      node: (
        <Select value={query.plan} onSelect={onPlanSelect} style={{ width: '100%' }}>
          {plans.map((plan) => (
            <Option key={plan.key} value={plan.value}>
              {plan.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Removed',
      key: 'removed',
      node: (
        <Select value={query.removed} onSelect={onRemovedSelect} style={{ width: '100%' }}>
          {removedFilters.map((removed) => (
            <Option key={removed.key} value={removed.value}>
              {removed.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Order by',
      key: 'order-by',
      node: (
        <Select value={query.order} onSelect={onOrderSelect} style={{ width: '100%' }}>
          {orders.map((order) => (
            <Option key={order.key} value={order.value}>
              {order.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Desk Enabled',
      key: 'desk-enabled',
      node: (
        <Select value={query.deskEnabled} onSelect={onDeskEnabledSelect} style={{ width: '100%' }}>
          {deskEnabledFilters.map((deskEnabled) => (
            <Option key={deskEnabled.key} value={deskEnabled.value}>
              {deskEnabled.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Calls Enabled',
      key: 'calls-enabled',
      node: (
        <Select value={query.callsEnabled} onSelect={onCallsEnabledSelect} style={{ width: '100%' }}>
          {callsEnabledFilters.map((callsEnabled) => (
            <Option key={callsEnabled.key} value={callsEnabled.value}>
              {callsEnabled.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Paused',
      key: 'paused',
      node: (
        <Select value={query.paused} onSelect={onPausedSelect} style={{ width: '100%' }}>
          {Paused.map((p) => (
            <Option key={p.key} value={p.value}>
              {p.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Test Organization',
      key: 'is_test_organization',
      node: (
        <Checkbox
          checked={query.is_test_organization === 'true'}
          value="true"
          onClick={onTestOrganizationClick}
          style={{ width: '100%' }}
        >
          Show Test Organization
        </Checkbox>
      ),
    },
    {
      label: 'Search',
      key: 'search',
      node: (
        <Search
          value={searchQuery}
          placeholder="Search applications"
          enterButton={true}
          onSearch={onSearch}
          onChange={onSearchChange}
        />
      ),
    },
  ];

  return (
    <Layout>
      <PageTitle title="Applications" subTitle={`${crmApplicationsCount} search results`} />
      <Layout.Content>
        <TableFilter selects={tableFilter} />
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmApplications}
          pagination={{
            total: crmApplicationsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
