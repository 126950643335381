import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';

import { PageTitle } from '../../components/ui/PageTitle';
import { StatusTag } from '../../components/ui/Tags';
import { GeneralDetail } from '../../components/ui/GeneralDetail';

import { getUserPermissionRequest } from '../../api/permissions';
import { Layout, message } from 'antd';
import { hasPermission, Permissions } from '../../utils/permission';
import { UserPermissionDeleteButton } from '../../components/UserPermissionDeleteButton';

const UserPermissionContainer = styled.div<{ state: string }>`
  ${(props) => (props.state === 'EXPIRED' ? '* { text-decoration: line-through; }' : '')}
`;

interface MatchParams {
  userPermissionId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const UserPermission = withRouter<Props, any>(
  React.memo<Props>(({ history, match }) => {
    const { userPermissionId } = match.params;
    const [userPermission, setUserPermission] = useState<UserPermission>({} as UserPermission);

    function getUserPermission() {
      getUserPermissionRequest({ userPermissionId })
        .then((response) => {
          setUserPermission(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getUserPermission();
    }, []);

    const leftData = [
      {
        key: 'ID',
        value: userPermission.id,
      },
      {
        key: 'User ID',
        value: userPermission.user ? (
          <Link to={`/users/${userPermission.user.id}`}>{userPermission.user.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Username',
        value: userPermission.user ? userPermission.user.username : '-',
      },
      {
        key: 'Permission ID',
        value: userPermission.permission ? (
          <Link to={`/permissions/${userPermission.id}`}>{userPermission.permission.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Permission',
        value: userPermission.permission ? userPermission.permission.name : '-',
      },
    ];
    const rightData = [
      {
        key: 'Authorizer',
        value: userPermission.authorizer ? (
          <Link to={`/users/${userPermission.authorizer}`}>{userPermission.authorizer}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Granted At',
        value: userPermission.grantedAt,
      },
      {
        key: 'Expired At',
        value: userPermission.expiredAt,
      },
      {
        key: 'Status',
        value: userPermission.status ? <StatusTag status={userPermission.status} /> : '-',
      },
      {
        key: 'Delete',
        value: hasPermission(Permissions.MANAGE_INSIGHT_USER) ? (
          <UserPermissionDeleteButton
            userId={`${userPermission.user?.id}`}
            permissionName={userPermission.permission?.name}
            successCallback={() => {
              message.success('Successfully deleted');
              history.goBack();
            }}
          />
        ) : (
          '-'
        ),
      },
    ];

    return (
      <UserPermissionContainer state={userPermission.status}>
        <Layout>
          <PageTitle
            title="Granted Permission Detail"
            subTitle={`#${(userPermission.id && userPermission.id.toString()) || ''}`}
            showBack={true}
          />
          <Layout.Content>
            <GeneralDetail leftData={leftData} rightData={rightData} />
          </Layout.Content>
        </Layout>
      </UserPermissionContainer>
    );
  }),
);
