import * as React from 'react';
import { useState } from 'react';
import { Button, Modal, Typography } from 'antd';

import { activateCallVoucherRequest } from '../../api/billing';

const { Title, Text } = Typography;

type Props = {
  voucher: any;
  relatedInvoicesCount: number;
};

export const VoucherActivateDialog: React.FC<Props> = ({ voucher, relatedInvoicesCount }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    activateCallVoucherRequest({
      voucherUid: voucher.uid,
    })
      .then((_) => {
        setLoading(false);
        setVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button type="primary" onClick={showModal}>
        {relatedInvoicesCount
          ? 'Activate Voucher'
          : voucher.paidAmount > 0
          ? 'Activate Voucher with Payment'
          : 'Activate Voucher'}
      </Button>
      <Modal
        open={visible}
        title={`Activate #${voucher.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Activate
          </Button>,
        ]}
      >
        <Title level={3}>Do you want to activate this Voucher?</Title>
        <Title level={4}>Voucher ID</Title>
        <Text copyable={true}>{voucher.id}</Text>
      </Modal>
    </React.Fragment>
  );
};
