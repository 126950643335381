import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, DatePicker, Select } from 'antd';
import queryString from 'query-string';
import moment from 'moment-timezone';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { PageTitle } from '../../components/ui/PageTitle';
import { dateTimePattern } from '../../utils/date';
import { getProjectList, getRegionListRequest } from '../../api/deskMetrics';
import { TableFilter } from '../../components/ui/TableFilter';

type Props = RouteComponentProps;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const DeskMetricsProjectList = withRouter<Props, any>(({ location, history }) => {
  const [projects, setProjects] = useState([] as Array<Project>);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [projectsCount, setProjectsCount] = useState(0);
  const [regionList, setRegionList] = useState([] as Array<RegionList>);

  const isValidMomentValue = (value) => moment(value).format(dateTimePattern.date) !== 'Invalid date';

  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 20,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    region: qs.region ? (qs.region as string) : '',
    createdAtGte:
      qs.createdAtGte && isValidMomentValue(qs.createdAtGte)
        ? (qs.createdAtGte as string)
        : '',
    createdAtLte:
      qs.createdAtLte && isValidMomentValue(qs.createdAtLte)
        ? (qs.createdAtLte as string)
        : '',
  });

  const getRegionList = (cb) => {
    getRegionListRequest()
      .then((response) => {
        setRegionList(response.data.results);
        cb && cb(response.data.results[0]['name']);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const projectsColumns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (id ? id : '-'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => (name ? name : '-'),
    },
    {
      title: 'PID',
      dataIndex: 'pid',
      key: 'pid',
      render: (pid: string) => pid ? <Link to={`/desk_metrics/projects/${pid}/?region=${query.region}`}>{pid}</Link> : '-',
    },
    {
      title: 'App Id',
      dataIndex: 'sendbirdAppId',
      key: 'sendbirdAppId',
      render: (sendbirdAppId: string) =>
        sendbirdAppId ? (
          <Link to={`/applications/${sendbirdAppId}/detail/`}>{sendbirdAppId.split('-')[0]}...</Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Live ticket limit',
      dataIndex: 'liveTicketLimit',
      key: 'liveTicketLimit',
      render: (liveTicketLimit: string) => (liveTicketLimit ? liveTicketLimit : '-'),
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => <>{moment(createdAt).utc().format(dateTimePattern.default)}</>,
    },
  ];

  const onChangeCreatedAt = (data) => {
    setQuery({
      ...query,
      createdAtGte:
        moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '',
      createdAtLte:
        moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
          ? data[1].format(`${dateTimePattern.date}`)
          : '',
    });
  };

  const getProjectListFunction = (_region) => {
    setLoadingProjects(true);
    let _query = query;
    if (_query.region === '') {
      _query.region = _region;
      setQuery({...query, region: _region})
    }

    getProjectList({ ..._query })
      .then((response) => {
        setProjects(response.data.results);
        setProjectsCount(response.data.count);
        setLoadingProjects(false);
      })
      .catch((error) => {
        setLoadingProjects(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getRegionList(getProjectListFunction);
    history.replace({
      pathname: '/desk_metrics/projects/',
      search: queryString.stringify(query),
    });
  }, [query]);

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  const onFilterSelect = (key) => (value) => {
    setQuery({ ...query, limit: 20, offset: 0, [key]: value });
  };

  let tableFilter = [
    {
      label: 'Region',
      key: 'region',
      node: (
        <Select value={query.region} onSelect={onFilterSelect('region')} style={{ width: '100%' }}>
          {regionList.map((item) => (
            <Option key={item.name} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          defaultValue={
            isValidMomentValue(query.createdAtGte) && isValidMomentValue(query.createdAtLte)
              ? [moment(query.createdAtGte), moment(query.createdAtLte)]
              : null
          }
          onChange={onChangeCreatedAt}
          format={dateTimePattern.date}
        />
      ),
    },
  ];

  return (
    <Layout>
      <PageTitle title="Projects" />
      <Layout.Content>
        <TableFilter selects={tableFilter} />
        <ResponsiveTable
          loading={loadingProjects}
          columns={projectsColumns}
          dataSource={projects}
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: ['20', ],
            total: projectsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
