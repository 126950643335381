import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Layout, PageHeader} from 'antd';
import {PageHeaderProps} from 'antd/lib/page-header';
import styled from 'styled-components';

type Props = {
  className?: string;
  title: PageHeaderProps['title'];
  subTitle?: PageHeaderProps['subTitle'];
  showBack?: boolean;
  style?: React.CSSProperties;
  onBack?: (payload?: any) => any;
  extra?: Array<any>;
  extraStyle?: React.CSSProperties;
} & RouteComponentProps;

const StyledPageHeader = styled(PageHeader)`
  padding: 16px 0;

  .ant-page-header-heading-extra {
    min-width: 450px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const PageTitle = withRouter<Props, any>(
  ({
    history,
    title,
    subTitle,
    showBack,
    style,
    children,
    onBack,
    extra,
    extraStyle,
  }) => {
    const handleBack = () => {
      onBack && onBack();
      history.goBack();
    };

    return (
      <Layout.Header>
        <StyledPageHeader
          title={title}
          subTitle={subTitle}
          onBack={showBack ? handleBack : undefined}
          style={style}
          extra={extraStyle ? [<div style={extraStyle}>{extra}</div>] : extra}
        >
          {children}
        </StyledPageHeader>
      </Layout.Header>
    );
  },
);
