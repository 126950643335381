import React, { useState, useRef } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Input, message, Modal, Select, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { DynamicForm } from '../components/ui/DynamicForm';

import { createDatabaseOtpRequest } from '../api/otp';

const { Option } = Select;

const getFields: any = (dbHostNames) => {
  return [
    {
      name: 'dbName',
      label: 'DB Name',
      formItemProps: {
        rules: [{ required: true, message: 'Please select Database.' }],
      },
      component: (
        <Select size="large">
          {dbHostNames.map((value) => (
            <Option key={`department-${value}}`} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'reason',
      label: 'Reason',
      formItemProps: {},
      span: 24,
      component: <Input size="large" />,
    },
    {
      name: 'isEmergency',
      label: 'Is Emergency',
      formItemProps: {
        valuePropName: 'checked',
        initialValue: false,
      },
      span: 12,
      component: <Switch />,
    },
    {
      name: 'infoLink',
      label: 'Please provide your justification, such as Jira Ticket Link or Salesforce support case link.',
      formItemProps: {
        rules: [
          {
            required: true,
            message: 'Please enter your justification, such as Jira Ticket Link or Salesforce support case link.',
          },
        ],
      },
      component: <Input size="large" placeholder="https://sendbird.com" />,
    },
  ];
};

type Props = {
  dbHostNames: Array<string>;
  successCallback: any;
};

export const DatabaseOtpCreateDialog: React.FC<Props> = ({ dbHostNames, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);

    if (formRef.current) {
      const infoLink = formRef.current.getFieldValue('infoLink');
      if (infoLink === undefined || infoLink === '') {
        message.warning('Info link is required.', 5);
        setLoading(false);
        return;
      }

      formRef.current
        .validateFields()
        .then((values) => {
          const { dbName, reason, isEmergency, infoLink } = values;

          createDatabaseOtpRequest({
            dbName: dbName,
            reason: reason,
            isEmergency: isEmergency,
            infoLink: infoLink,
          })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback();
            })
            .catch((error) => {
              console.error('error', error);
              message.error(error.response.data.detail, 5);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const fields = getFields(dbHostNames);
  const modeText = 'Create';
  const iconName = 'plus';

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<LegacyIcon type={iconName} />}
        onClick={showModal}
        style={{
          margin: '0.25em',
          marginLeft: 0,
          verticalAlign: 'middle',
        }}
      >
        {modeText}
      </Button>
      <Modal
        open={visible}
        title={`${modeText} a Database OTP `}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            {modeText}
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
