import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageTitle } from '../../components/ui/PageTitle';
import { getUserRequestActivityLogRequest } from '../../api/userRequestActivityLog';
import { UserRequestActivityLogInfo } from '../../components/UserRequestActivityLogInfo';
import { useEffect } from 'react';

interface MatchParams {
  userRequestActivityLogId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const UserRequestActivityLog = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { userRequestActivityLogId } = match.params;
    const [userRequestActivityLog, setUserRequestActivityLogRequest] = useState({} as UserRequestActivityLog);

    const getUserRequestActivityLog = () => {
      getUserRequestActivityLogRequest({ userRequestActivityLogId })
        .then((response) => {
          setUserRequestActivityLogRequest(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    useEffect(() => {
      getUserRequestActivityLog();
    }, [location]);

    return (
      <React.Fragment>
        <PageTitle
          title="User Request Activity Log Detail"
          subTitle={`#${(userRequestActivityLog.id && userRequestActivityLog.id.toString()) || ''}`}
          showBack={true}
        />
        <UserRequestActivityLogInfo userRequestActivityLog={userRequestActivityLog} />
      </React.Fragment>
    );
  }),
);
