import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { TableDetail } from '../components/ui/TableDetail';
import {
  // ActiveTag,
  BillingCycleTag,
  AutoRenewalTag,
  PaymentMethodTag,
} from '../components/ui/Tags';
import { SubscriptionCreateDialog } from './SubscriptionCreateDialog';
import { SubscriptionUnsubscribeDialog } from '../components/SubscriptionUnsubscribeDialog';
import { SubscriptionUpdateDialog } from './SubscriptionUpdateDialog';

import { getApplicationSubscriptionsRequest } from '../api/subscription';

const getColumns = ({ crmApplication, dialogSuccessCallback }: ColumnsProps) => [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: CrmSubscription['id']) => <Link to={`/crm_subscriptions/${id}/`}>{id}</Link>,
  },
  // {
  //   title: 'Active',
  //   dataIndex: 'isActive',
  //   key: 'isActive',
  //   render: (isActive: CrmSubscription['isActive']) => (
  //     <ActiveTag isActive={isActive} />
  //   ),
  // },
  {
    title: 'Billing	Cycle',
    dataIndex: 'billingCycle',
    key: 'billingCycle',
    render: (billingCycle: CrmSubscription['billingCycle']) => <BillingCycleTag billingCycle={billingCycle} />,
  },
  {
    title: 'Auto Renewal',
    dataIndex: 'autoRenewal',
    key: 'autoRenewal',
    render: (autoRenewal: CrmSubscription['autoRenewal'], subscription: CrmSubscription) => (
      <AutoRenewalTag autoRenewal={autoRenewal} autoRenewalPeriod={subscription.autoRenewalPeriod} />
    ),
  },
  {
    title: 'Fee (Product/Service)',
    dataIndex: 'productFee',
    key: 'productFee',
    render: (productFee: CrmSubscription['productFee'], subscription: CrmSubscription) => (
      <span>
        ${productFee.toLocaleString()} / ${subscription.serviceFee.toLocaleString()}
      </span>
    ),
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (paymentMethod: CrmSubscription['paymentMethod']) => <PaymentMethodTag paymentMethod={paymentMethod} />,
  },
  {
    title: 'Date',
    dataIndex: 'subscriptionStartDt',
    key: 'subscriptionStartDt',
    render: (subscriptionStartDt: CrmSubscription['subscriptionStartDt'], subscription: CrmSubscription) => (
      <>
        <span>{subscriptionStartDt}</span> <br />~ <span>{subscription.subscriptionEndDt}</span>
      </>
    ),
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    key: 'memo',
  },
  {
    title: (
      <span>
        Info <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createUser',
    key: 'createUser',
    render: (createUser: CrmSubscription['createUser'], subscription: CrmSubscription) =>
      createUser ? (
        <span>
          created by: <b>{createUser.username}</b>
          <br />({subscription.createdDt.split('.')[0]})
        </span>
      ) : (
        ''
      ),
  },
  {
    title: 'Action',
    dataIndex: 'isActive',
    key: 'action',
    render: (isActive: CrmSubscription['isActive'], subscription: CrmSubscription) => {
      return (
        <>
          {isActive ? (
            <SubscriptionUnsubscribeDialog
              crmApplication={crmApplication}
              crmSubscription={subscription}
              successCallback={dialogSuccessCallback}
            />
          ) : null}
          <SubscriptionUpdateDialog crmSubscription={subscription} successCallback={dialogSuccessCallback} />
        </>
      );
    },
  },
];

interface ColumnsProps {
  crmApplication: CrmApplication;
  dialogSuccessCallback: () => void;
}

type Props = {
  appId: CrmApplication['appId'];
  crmApplication: CrmApplication;
};

export const ApplicationDetailSubscriptions: React.FC<Props> = ({ appId, crmApplication }) => {
  const [dataSource, setDatasource] = useState<Array<CrmSubscription>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 8,
  });

  function getApplicationSubscriptions({ offset = 0, limit = 8 }) {
    setLoading(true);
    getApplicationSubscriptionsRequest({
      appId,
      limit,
      offset,
    })
      .then((response) => {
        setDatasource(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handlePageChange({ offset, limit }) {
    setPagination({ offset, limit });
    getApplicationSubscriptions({ offset, limit });
  }

  function dialogSuccessCallback() {
    getApplicationSubscriptions({
      offset: pagination.offset,
      limit: pagination.limit,
    });
  }

  function handleOnRow(subscritpion: CrmSubscription) {
    const { isActive } = subscritpion;
    return {
      style: {
        textDecoration: !isActive ? 'line-through' : '',
        opacity: !isActive ? 0.5 : 1,
      },
    };
  }

  useEffect(function () {
    getApplicationSubscriptions({ offset: 0, limit: 8 });
  }, []);

  return (
    <TableDetail
      title="Subscriptions"
      count={count}
      loading={loading}
      columns={getColumns({ crmApplication, dialogSuccessCallback })}
      dataSource={dataSource}
      rightColumnComponent={(() => {
        return crmApplication.crmOrganization && !crmApplication.crmOrganization.isSelfServe ? (
          <SubscriptionCreateDialog crmApplication={crmApplication} successCallback={dialogSuccessCallback} />
        ) : undefined;
      })()}
      onPageChange={handlePageChange}
      onRow={handleOnRow}
      pageSizeOptions={['8', '15', '30']}
    />
  );
};
