import * as React from 'react';
import { Card, Col, List, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  ActiveTag,
  PaymentMethodTag,
  PaymentStatusTag,
  PaymentTypeTag,
} from './ui/Tags';

type Props = {
  crmPayment: CrmPayment;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const CrmPaymentInfo: React.FC<Props> = ({ crmPayment }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'Id',
      value: crmPayment.id,
    },
    {
      key: 'User (Crm)',
      value: crmPayment.crmUser ? (
        <Link to={`/crm_users/${crmPayment.crmUser.id}`}>
          {crmPayment.crmUser.id} ({crmPayment.crmUser.email})
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'App ID',
      value: crmPayment.appId ? (
        <Link to={`/applications/${crmPayment.appId}/detail/`}>
          {crmPayment.crmApplication.appId}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'isActive',
      value: <ActiveTag isActive={crmPayment.isActive} />,
    },
    {
      key: 'Type',
      value: crmPayment.type ? <PaymentTypeTag type={crmPayment.type} /> : '-',
    },
    {
      key: 'Payment Method',
      value: crmPayment.paymentMethod ? (
        <PaymentMethodTag paymentMethod={crmPayment.paymentMethod} />
      ) : (
        '-'
      ),
    },
    {
      key: 'Status',
      value: crmPayment.status ? (
        <PaymentStatusTag status={crmPayment.status} />
      ) : (
        '-'
      ),
    },
    {
      key: 'Memo',
      value: crmPayment.memo ? crmPayment.memo : '-',
    },
  ];
  const detailAdvanced = [
    {
      key: 'Product Fee',
      value: crmPayment.productFee ? (
        <Tag key={crmPayment.productFee} style={{ margin: 0 }}>
          $ {crmPayment.productFee.toLocaleString()}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Service Fee',
      value: crmPayment.serviceFee ? (
        <Tag key={crmPayment.serviceFee} style={{ margin: 0 }}>
          $ {crmPayment.serviceFee.toLocaleString()}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Start Date',
      value: crmPayment.serviceStartDt ? crmPayment.serviceStartDt : '-',
    },
    {
      key: 'End Date',
      value: crmPayment.serviceEndDt ? crmPayment.serviceEndDt : '-',
    },
    {
      key: (
        <span>
          LastPayment RequestDt <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmPayment.lastPaymentRequestDt
        ? crmPayment.lastPaymentRequestDt
        : '-',
    },
    {
      key: (
        <span>
          UpdatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      value: crmPayment.updatedDt ? crmPayment.updatedDt : '-',
    },
    {
      key: 'Subscription ID',
      value: crmPayment.crmSubscription ? (
        <Link to={`/crm_subscriptions/${crmPayment.crmSubscription}/`}>
          {crmPayment.crmSubscription}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'Stripe Invoice ID',
      value: (
        <a
          href={`https://dashboard.stripe.com/invoices/${crmPayment.stripeInvoiceId}`}
          target="_blank"
        >
          {crmPayment.stripeInvoiceId}
        </a>
      ),
    },
    {
      key: 'Creator',
      value: crmPayment.creator ? crmPayment.creator.username : '-',
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col
          key={`-info-${index}`}
          span={24}
          md={{ span: 12 }}
          style={{ paddingBottom: '16px' }}
        >
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '410px',
            }}
          >
            <List
              size="small"
              dataSource={dataSource}
              renderItem={renderInfoItem}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
