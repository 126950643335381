import * as React from 'react';
import { Card, Col, List, Row, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  organizationSubscriptionInvoice: OrganizationSubscriptionInvoice;
  organizationSubscription: OrganizationSubscription;
};

const { Text } = Typography

const fullOrProrated = (date: string) => {
  const dateObject = new Date(date);
  if (dateObject.getDate() === 1) {
    return 'Full';
  } else {
    return 'Prorated';
  }
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const OrganizationSubscriptionInvoiceInfo: React.FC<Props> = ({
  organizationSubscriptionInvoice,
  organizationSubscription,
}) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'UID',
      value: <Text copyable={true}>{organizationSubscriptionInvoice.uid}</Text>,
    },
    {
      key: 'Stripe Dashboard',
      value: (
        <a href={`https://dashboard.stripe.com/invoices`} target="_blank">
          Stripe Dashboard
        </a>
      ),
    },
    {
      key: 'Stripe Invoice PDF',
      value: organizationSubscriptionInvoice.stripeInvoicePdfUrl ? (
        <a href={`${organizationSubscriptionInvoice.stripeInvoicePdfUrl}`} target="_blank">
          PDF
        </a>
      ) : (
        '-'
      ),
    },
    {
      key: 'Stripe Invoice Info',
      value: organizationSubscriptionInvoice.stripeInvoiceId ? (
        <a
          href={`https://dashboard.stripe.com/invoices/${organizationSubscriptionInvoice.stripeInvoiceId}`}
          target="_blank"
        >
          {organizationSubscriptionInvoice.stripeInvoiceId}
        </a>
      ) : (
        '-'
      ),
    },
    {
      key: 'Stripe Invoice Status',
      value: organizationSubscriptionInvoice.stripeInvoiceId ? (
        <Tag key="stripe-invoice-status" style={{ margin: 0 }}>
          {organizationSubscriptionInvoice.stripeInvoiceStatus}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Start Date',
      value: organizationSubscriptionInvoice.startDate ? organizationSubscriptionInvoice.startDate : '-',
    },
    {
      key: 'End Date',
      value: organizationSubscriptionInvoice.endDate ? organizationSubscriptionInvoice.endDate : '-',
    },
    {
      key: 'Due Date',
      value: organizationSubscriptionInvoice.dueDt ? organizationSubscriptionInvoice.dueDt : '-',
    },
    {
      key: 'Full / Prorated',
      value: organizationSubscriptionInvoice.startDate
        ? fullOrProrated(organizationSubscriptionInvoice.startDate)
        : '-',
    },
    {
      key: 'Total Amount',
      value:
        organizationSubscriptionInvoice.totalAmount === 0 ? (
          <Tag key="total-amount" style={{ margin: 0 }}>
            Free
          </Tag>
        ) : (
          <Tag key="total-amount" style={{ margin: 0 }}>
            {`$${(organizationSubscriptionInvoice.totalAmount / 100).toLocaleString()}`}
          </Tag>
        ),
    },
    {
      key: 'Type',
      value: organizationSubscriptionInvoice.invoiceType ? (
        <Tag key="invoice-type" style={{ margin: 0 }}>
          {organizationSubscriptionInvoice.invoiceType}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Payment Method',
      value: organizationSubscriptionInvoice.paymentMethod ? (
        <Tag key="payment-method" style={{ margin: 0 }}>
          {organizationSubscriptionInvoice.paymentMethod}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Payment Status',
      value: organizationSubscriptionInvoice.status ? (
        <Tag key="payment-status" style={{ margin: 0 }}>
          {organizationSubscriptionInvoice.status}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Memo',
      value: organizationSubscriptionInvoice.memo ?
        organizationSubscriptionInvoice.memo.split('\n').map( text => {
            return (<Text >{text}<br/></Text>)
        }) : '-',
    },
  ];
  const detailAdvanced = [
    {
      key: 'Subscription',
      value: organizationSubscription ? (
        <Link to={`/organization_subscription/${organizationSubscription.uid}/`}>{organizationSubscription.uid}</Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'Subscription Type',
      value: organizationSubscription && organizationSubscription.product ? (
        <Tag key="payment-type" style={{ margin: 0 }}>
          {organizationSubscription.product}
        </Tag>
      ) : (
        '-'
      ),
    },
    {
      key: 'Subscription Period',
      value:
        organizationSubscription && organizationSubscription.startDate && organizationSubscription.endDate
          ? `${organizationSubscription.startDate ? organizationSubscription.startDate : ''} ~
      ${organizationSubscription.endDate ? organizationSubscription.endDate : ''}`
          : '-',
    },
    {
      key: 'Subscription Fee',
      value: (() => {
        if (!organizationSubscription) {
          return '-';
        }else if (!organizationSubscription.planValue) {
          return '-';
        } else if (organizationSubscription.planValue === 0) {
          return (
            <Tag key="plan-value" style={{ margin: 0 }}>
              Free
            </Tag>
          );
        } else {
          return (
            <Tag key="plan-value" style={{ margin: 0 }}>
              {`$${(organizationSubscription.planValue / 100).toLocaleString()}`}
            </Tag>
          );
        }
      })(),
    },
    {
      key: 'Organization',
      value: organizationSubscription && organizationSubscription.organization ? (
        <Link to={`/crm_organizations/${organizationSubscription.organization.uid}/`}>
          {organizationSubscription.organization.name}
        </Link>
      ) : (
        '-'
      ),
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
        marginBottom: '24px',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col key={`-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '370px',
            }}
          >
            <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
