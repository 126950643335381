import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Card, Col, DatePicker, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { useCheckMobile } from '../../utils/screen';
import { dateTimePattern } from '../../utils/date';
import { getNewChatMessageCount, getNewProjectCount, getNewTicketCount } from '../../api/deskMetrics';
import { Link } from 'react-router-dom';
import { TableFilter } from '../../components/ui/TableFilter';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const alignRight = 'right' as ColumnProps<any>['align'];

const isValidMomentValue = (value) => moment(value).format(dateTimePattern.date) !== 'Invalid date';

function convertDataSourceDeskMetrics({ dataSources }) {
  return dataSources.map((regionData) => {
    let source = {};

    regionData.data.forEach((data, index) => {
      source[`count-${index}`] = data['value'];
      source['title'] = regionData.region;
    });

    if (Object.keys(source).length !== 0) {
      source[`count-${regionData.data.length}`] = regionData.data.reduce((x, y) => x + y.value, 0);
      source['title'] = regionData.region;
    }

    return source;
  });
}

export const DeskMetricsSummary = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dataSourceTicketCountDaily, setDataSourceTicketCountDaily] = useState<Array<DeskMetric>>([]);
  const [ticketCountDailyLoading, setTicketCountDailyLoading] = useState<boolean>(false);

  const [dataSourceTicketCountMonthly, setDataSourceTicketCountMonthly] = useState<Array<DeskMetric>>([]);
  const [ticketCountMonthlyLoading, setTicketCountMonthlyLoading] = useState<boolean>(false);

  const [dataSourceProjectCountDaily, setDataSourceProjectCountDaily] = useState<Array<DeskMetric>>([]);
  const [projectCountDailyLoading, setProjectCountDailyLoading] = useState<boolean>(false);

  const [dataSourceProjectCountMonthly, setDataSourceProjectCountMonthly] = useState<Array<DeskMetric>>([]);
  const [projectCountMonthlyLoading, setProjectCountMonthlyLoading] = useState<boolean>(false);

  const [dataSourceChatMessageCountDaily, setDataSourceChatMessageCountDaily] = useState<Array<DeskMetric>>([]);
  const [chatMessageCountDailyLoading, setChatMessageCountDailyLoading] = useState<boolean>(false);

  const [dataSourceChatMessageCountMonthly, setDataSourceChatMessageCountMonthly] = useState<Array<DeskMetric>>([]);
  const [chatMessageCountMonthlyLoading, setChatMessageCountMonthlyLoading] = useState<boolean>(false);

  const [ticketDailyRangeStartDate, setTicketDailyRangeStartDate] = useState<string>(
    moment().subtract(6, 'days').format(dateTimePattern.date),
  );
  const [ticketDailyRangeEndDate, setTicketDailyRangeEndDate] = useState<string>(moment().format(dateTimePattern.date));
  const [ticketDailyRangeHackValue, setTicketDailyRangeHackValue] = useState();

  const [ticketMonthlyRangeStartDate, setTicketMonthlyRangeStartDate] = useState<string>(
    moment().subtract(1, 'month').format(dateTimePattern.month),
  );
  const [ticketMonthlyRangeEndDate, setTicketMonthlyRangeEndDate] = useState<string>(moment().format(dateTimePattern.month));
  const [ticketMonthlyRangeHackValue, setTicketMonthlyRangeHackValue] = useState();

  const [projectDailyRangeStartDate, setProjectDailyRangeStartDate] = useState<string>(
    moment().subtract(6, 'days').format(dateTimePattern.date),
  );
  const [projectDailyRangeEndDate, setProjectDailyRangeEndDate] = useState<string>(moment().format(dateTimePattern.date));
  const [projectDailyRangeHackValue, setProjectDailyRangeHackValue] = useState();

  const [projectMonthlyRangeStartDate, setProjectMonthlyRangeStartDate] = useState<string>(
    moment().subtract(3, 'month').format(dateTimePattern.month),
  );
  const [projectMonthlyRangeEndDate, setProjectMonthlyRangeEndDate] = useState<string>(moment().format(dateTimePattern.month));
  const [projectMonthlyRangeHackValue, setProjectMonthlyRangeHackValue] = useState();

  const [chatMessageDailyRangeStartDate, setChatMessageDailyRangeStartDate] = useState<string>(
    moment().subtract(6, 'days').format(dateTimePattern.date),
  );
  const [chatMessageDailyRangeEndDate, setChatMessageDailyRangeEndDate] = useState<string>(moment().format(dateTimePattern.date));
  const [chatMessageDailyRangeHackValue, setChatMessageDailyRangeHackValue] = useState();

  const [chatMessageMonthlyRangeStartDate, setChatMessageMonthlyRangeStartDate] = useState<string>(
    moment().subtract(1, 'month').format(dateTimePattern.month),
  );
  const [chatMessageMonthlyRangeEndDate, setChatMessageMonthlyRangeEndDate] = useState<string>(moment().format(dateTimePattern.month));
  const [chatMessageMonthlyRangeHackValue, setChatMessageMonthlyRangeHackValue] = useState();

  const createColumnsDeskMetricsTicket = ({ dataSources, dateUnit }) => {
    // dateUnit will be used to link
    let columns: Array<ColumnProps<any>> = [
      {
        title: 'Region',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    if (dataSources.length >= 1) {
      dataSources[0].data.forEach((item, columnIndex) => {
        columns.push({
          title: item.name,
          dataIndex: `count-${columnIndex}`,
          key: `users-${columnIndex}`,
          align: alignRight,
          render: (count: number, rowData) => {
            let region = rowData.title; // It is assigned in convertDataSourceDeskMetrics
            let queryParamCreatedAt = '';

            if (dateUnit === 'day') {
              queryParamCreatedAt = item.name ? `createdAtGte=${item.name}&createdAtLte=${item.name}` : '';
            } else if (dateUnit === 'month') {
              queryParamCreatedAt = item.name
                ? `createdAtGte=${item.name}-01&createdAtLte=${moment(item.name)
                    .add(1, 'months')
                    .subtract(1, 'days')
                    .format(dateTimePattern.date)}`
                : '';
            } else {
              console.error('available dateUnit: day or month');
            }

            return (
              <Link to={`/desk_metrics/tickets/?limit=20&offset=0&region=${region}&${queryParamCreatedAt}`}>
                {count && count.toLocaleString()}
              </Link>
            );
          },
        });
      });

      columns.push({
        title: 'Total',
        dataIndex: `count-${dataSources[0].data.length}`,
        key: `users-${dataSources[0].data.length}`,
        align: alignRight,
        render: (count: number, rowData) => {
          let region = rowData.title; // It is assigned in convertDataSourceDeskMetrics
          let queryParamCreatedAt = '';

          const startDate = dataSources[0].data[0].name;
          const endDate = dataSources[0].data[dataSources[0].data.length - 1].name;

          if (dateUnit === 'day') {
            queryParamCreatedAt = startDate ? `createdAtGte=${startDate}&createdAtLte=${endDate}` : '';
            return (
              <Link to={`/desk_metrics/tickets/?limit=20&offset=0&region=${region}&${queryParamCreatedAt}`}>
                {count && count.toLocaleString()}
              </Link>
            );
          } else if (dateUnit === 'month') {
            return count && count.toLocaleString();  // Currently, Desk link limit 31 days. So total link is commented.
          } else {
            console.error('available dateUnit: day or month');
          }
        },
      });
    }

    return columns;
  };

  const createColumnsDeskMetricsProject = ({ dataSources, dateUnit }) => {
    // dateUnit will be used to link
    let columns: Array<ColumnProps<any>> = [
      {
        title: 'Region',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    if (dataSources.length >= 1) {
      dataSources[0].data.forEach((item, columnIndex) => {
        columns.push({
          title: item.name,
          dataIndex: `count-${columnIndex}`,
          key: `users-${columnIndex}`,
          align: alignRight,
          render: (count: number, rowData) => {
            let region = rowData.title; // It is assigned in convertDataSourceDeskMetrics
            let queryParamCreatedAt = '';

            if (dateUnit === 'day') {
              queryParamCreatedAt = item.name ? `createdAtGte=${item.name}&createdAtLte=${item.name}` : '';
            } else if (dateUnit === 'month') {
              queryParamCreatedAt = item.name
                ? `createdAtGte=${item.name}-01&createdAtLte=${moment(item.name)
                    .add(1, 'months')
                    .subtract(1, 'days')
                    .format(dateTimePattern.date)}`
                : '';
            }

            return (
              <Link to={`/desk_metrics/projects/?limit=20&offset=0&region=${region}&${queryParamCreatedAt}`}>
                {count && count.toLocaleString()}
              </Link>
            );
          },
        });
      });
    }

    return columns;
  };

  const createColumnsDeskMetricsChatMessage = ({ dataSources, dateUnit }) => {
    // dateUnit will be used to link
    let columns: Array<ColumnProps<any>> = [
      {
        title: 'Region',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    ];

    if (dataSources.length >= 1) {
      dataSources[0].data.forEach((item, columnIndex) => {
        columns.push({
          title: item.name,
          dataIndex: `count-${columnIndex}`,
          key: `users-${columnIndex}`,
          align: alignRight,
          render: (count: number) => count && count.toLocaleString(),
        });
      });
    }

    return columns;
  };

  const getNewTicketCountDailyFunction = (rangeStartDate, rangeEndDate) => {
    setTicketCountDailyLoading(true);
    getNewTicketCount({
      date_unit: 'day',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewTicketCountDailyFunction: ', response.data);
        setDataSourceTicketCountDaily(response.data.results);
        setTicketCountDailyLoading(false);
      })
      .catch((error) => {
        setTicketCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewTicketCountMonthlyFunction = (rangeStartDate, rangeEndDate) => {
    setTicketCountMonthlyLoading(true);
    getNewTicketCount({
      date_unit: 'month',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewTicketCountMonthlyFunction: ', response.data);
        setDataSourceTicketCountMonthly(response.data.results);
        setTicketCountMonthlyLoading(false);
      })
      .catch((error) => {
        setTicketCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewProjectCountDailyFunction = (rangeStartDate, rangeEndDate) => {
    setProjectCountDailyLoading(true);
    getNewProjectCount({
      date_unit: 'day',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewProjectCountDailyFunction: ', response.data);
        setDataSourceProjectCountDaily(response.data.results);
        setProjectCountDailyLoading(false);
      })
      .catch((error) => {
        setProjectCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewProjectCountMonthlyFunction = (rangeStartDate, rangeEndDate) => {
    setProjectCountMonthlyLoading(true);
    getNewProjectCount({
      date_unit: 'month',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewProjectCountMonthlyFunction: ', response.data);
        setDataSourceProjectCountMonthly(response.data.results);
        setProjectCountMonthlyLoading(false);
      })
      .catch((error) => {
        setProjectCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewChatMessageCountDailyFunction = (rangeStartDate, rangeEndDate) => {
    setChatMessageCountDailyLoading(true);
    getNewChatMessageCount({
      date_unit: 'day',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewChatMessageCountDailyFunction: ', response.data);
        setDataSourceChatMessageCountDaily(response.data.results);
        setChatMessageCountDailyLoading(false);
      })
      .catch((error) => {
        setChatMessageCountDailyLoading(false);
        error && console.error(error.response);
      });
  };

  const getNewChatMessageCountMonthlyFunction = (rangeStartDate, rangeEndDate) => {
    setChatMessageCountMonthlyLoading(true);
    getNewChatMessageCount({
      date_unit: 'month',
      is_self_serve: true,
      range_start_date: rangeStartDate,
      range_end_date: rangeEndDate,
    })
      .then((response) => {
        console.log('getNewChatMessageCountMonthlyFunction: ', response.data);
        setDataSourceChatMessageCountMonthly(response.data.results);
        setChatMessageCountMonthlyLoading(false);
      })
      .catch((error) => {
        setChatMessageCountMonthlyLoading(false);
        error && console.error(error.response);
      });
  };

  useEffect(function () {
    getNewTicketCountDailyFunction(ticketDailyRangeStartDate, ticketDailyRangeEndDate);
    getNewTicketCountMonthlyFunction(ticketMonthlyRangeStartDate, ticketMonthlyRangeEndDate);
    getNewProjectCountDailyFunction(projectDailyRangeStartDate, projectDailyRangeEndDate);
    getNewProjectCountMonthlyFunction(projectMonthlyRangeStartDate, projectMonthlyRangeEndDate);
    getNewChatMessageCountDailyFunction(chatMessageDailyRangeStartDate, chatMessageDailyRangeEndDate);
    getNewChatMessageCountMonthlyFunction(chatMessageMonthlyRangeStartDate, chatMessageMonthlyRangeEndDate);
  }, []);

  const ticketCountDailyColumns = createColumnsDeskMetricsTicket({
    dataSources: dataSourceTicketCountDaily,
    dateUnit: 'day',
  });

  const ticketCountMonthlyColumns = createColumnsDeskMetricsTicket({
    dataSources: dataSourceTicketCountMonthly,
    dateUnit: 'month',
  });

  const projectCountDailyColumns = createColumnsDeskMetricsProject({
    dataSources: dataSourceProjectCountDaily,
    dateUnit: 'day',
  });

  const projectCountMonthlyColumns = createColumnsDeskMetricsProject({
    dataSources: dataSourceProjectCountMonthly,
    dateUnit: 'month',
  });

  const chatMessageCountDailyColumns = createColumnsDeskMetricsChatMessage({
    dataSources: dataSourceChatMessageCountDaily,
    dateUnit: 'day',
  });

  const chatMessageCountMonthlyColumns = createColumnsDeskMetricsChatMessage({
    dataSources: dataSourceChatMessageCountMonthly,
    dateUnit: 'month',
  });

  const convertedDataSourceTicketCountDaily = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceTicketCountDaily,
    });

  const convertedDataSourceTicketCountMonthly = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceTicketCountMonthly,
    });

  const convertedDataSourceProjectCountDaily = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceProjectCountDaily,
    });

  const convertedDataSourceProjectCountMonthly = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceProjectCountMonthly,
    });

  const convertedDataSourceChatMessageCountDaily = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceChatMessageCountDaily,
    });

  const convertedDataSourceChatMessageCountMonthly = () =>
    convertDataSourceDeskMetrics({
      dataSources: dataSourceChatMessageCountMonthly,
    });

  const disabledDateTicketDaily = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = ticketDailyRangeStartDate && current.diff(ticketDailyRangeStartDate, 'days') > 31;
    const tooEarly = ticketDailyRangeEndDate && moment(ticketDailyRangeEndDate).diff(current, 'days') > 31;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const disabledDateTicketMonthly = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = ticketMonthlyRangeStartDate && current.diff(ticketMonthlyRangeStartDate, 'months') > 6;
    const tooEarly = ticketMonthlyRangeEndDate &&
      moment(ticketMonthlyRangeEndDate).diff(current, 'months') > 6;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const disabledDateProjectDaily = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = projectDailyRangeStartDate && current.diff(projectDailyRangeStartDate, 'days') > 31;
    const tooEarly = projectDailyRangeEndDate && moment(projectDailyRangeEndDate).diff(current, 'days') > 31;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const disabledDateProjectMonthly = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = projectMonthlyRangeStartDate && current.diff(projectMonthlyRangeStartDate, 'months') > 23;
    const tooEarly = projectMonthlyRangeEndDate &&
      moment(projectMonthlyRangeEndDate).diff(current, 'months') > 23;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const disabledDateChatMessageDaily = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = chatMessageDailyRangeStartDate && current.diff(chatMessageDailyRangeStartDate, 'days') > 31;
    const tooEarly = chatMessageDailyRangeEndDate && moment(chatMessageDailyRangeEndDate).diff(current, 'days') > 31;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const disabledDateChatMessageMonthly = (current) => {
    if (!current) {
      return true;
    }

    const isFuture = current > moment();
    const tooLate = chatMessageMonthlyRangeStartDate && current.diff(chatMessageMonthlyRangeStartDate, 'months') > 6;
    const tooEarly = chatMessageMonthlyRangeEndDate &&
      moment(chatMessageMonthlyRangeEndDate).diff(current, 'months') > 6;

    return Boolean(isFuture || tooLate || tooEarly);
  };

  const onChangeTicketDailyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setTicketDailyRangeStartDate(rangeStartDate);
    setTicketDailyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewTicketCountDailyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onChangeTicketMonthlyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
      ? data[0].format(`${dateTimePattern.month}`)
      : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setTicketMonthlyRangeStartDate(rangeStartDate);
    setTicketMonthlyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewTicketCountMonthlyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onChangeProjectDailyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
      ? data[0].format(`${dateTimePattern.date}`)
      : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setProjectDailyRangeStartDate(rangeStartDate);
    setProjectDailyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewProjectCountDailyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onChangeProjectMonthlyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
      ? data[0].format(`${dateTimePattern.month}`)
      : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setProjectMonthlyRangeStartDate(rangeStartDate);
    setProjectMonthlyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewProjectCountMonthlyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onChangeChatMessageDailyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
      ? data[0].format(`${dateTimePattern.date}`)
      : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setChatMessageDailyRangeStartDate(rangeStartDate);
    setChatMessageDailyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewChatMessageCountDailyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onChangeChatMessageMonthlyCreatedAt = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
      ? data[0].format(`${dateTimePattern.month}`)
      : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setChatMessageMonthlyRangeStartDate(rangeStartDate);
    setChatMessageMonthlyRangeEndDate(rangeEndDate);

    if (rangeStartDate !== '' && rangeEndDate !== '') {
      getNewChatMessageCountMonthlyFunction(rangeStartDate, rangeEndDate);
    }
  };

  const onOpenChangeDateTicketDaily = (open) => {
    if (open) {
      setTicketDailyRangeHackValue([]);
      setTicketDailyRangeStartDate('');
      setTicketDailyRangeEndDate('');
    } else {
      setTicketDailyRangeHackValue(undefined);
    }
  };

  const onOpenChangeTicketMonthly = (open) => {
    if (open) {
      setTicketMonthlyRangeHackValue([]);
      setTicketMonthlyRangeStartDate('');
      setTicketMonthlyRangeEndDate('');
    } else {
      setTicketMonthlyRangeHackValue(undefined);
    }
  };

  const onOpenChangeProjectDaily = (open) => {
    if (open) {
      setProjectDailyRangeHackValue([]);
      setProjectDailyRangeStartDate('');
      setProjectDailyRangeEndDate('');
    } else {
      setProjectDailyRangeHackValue(undefined);
    }
  };

  const onOpenChangeProjectMonthly = (open) => {
    if (open) {
      setProjectMonthlyRangeHackValue([]);
      setProjectMonthlyRangeStartDate('');
      setProjectMonthlyRangeEndDate('');
    } else {
      setProjectMonthlyRangeHackValue(undefined);
    }
  };

  const onOpenChangeChatMessageDaily = (open) => {
    if (open) {
      setChatMessageDailyRangeHackValue([]);
      setChatMessageDailyRangeStartDate('');
      setChatMessageDailyRangeEndDate('');
    } else {
      setChatMessageDailyRangeHackValue(undefined);
    }
  };

  const onOpenChangeChatMessageMonthly = (open) => {
    if (open) {
      setChatMessageMonthlyRangeHackValue([]);
      setChatMessageMonthlyRangeStartDate('');
      setChatMessageMonthlyRangeEndDate('');
    } else {
      setChatMessageMonthlyRangeHackValue(undefined);
    }
  };

  const onCalendarChangeDateTicketDaily = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setTicketDailyRangeStartDate(rangeStartDate);
    setTicketDailyRangeEndDate(rangeEndDate);
  };

  const onCalendarChangeTicketMonthly = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.month}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setTicketMonthlyRangeStartDate(rangeStartDate);
    setTicketMonthlyRangeEndDate(rangeEndDate);
  };

  const onCalendarChangeProjectDaily = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setProjectDailyRangeStartDate(rangeStartDate);
    setProjectDailyRangeEndDate(rangeEndDate);
  };

  const onCalendarChangeProjectMonthly = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.month}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setProjectMonthlyRangeStartDate(rangeStartDate);
    setProjectMonthlyRangeEndDate(rangeEndDate);
  };

  const onCalendarChangeChatMessageDaily = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.date}`)
      : '';

    setChatMessageDailyRangeStartDate(rangeStartDate);
    setChatMessageDailyRangeEndDate(rangeEndDate);
  };

  const onCalendarChangeChatMessageMonthly = (data) => {
    const rangeStartDate = moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.month}`)
          : '';
    const rangeEndDate = moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
      ? data[1].format(`${dateTimePattern.month}`)
      : '';

    setChatMessageMonthlyRangeStartDate(rangeStartDate);
    setChatMessageMonthlyRangeEndDate(rangeEndDate);
  };

  let tableFilterTicketDaily = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={ticketDailyRangeHackValue ||
            (isValidMomentValue(ticketDailyRangeStartDate) && isValidMomentValue(ticketDailyRangeEndDate)
              ? [moment(ticketDailyRangeStartDate), moment(ticketDailyRangeEndDate)]
              : null
            )}
          defaultValue={
            isValidMomentValue(ticketDailyRangeStartDate) && isValidMomentValue(ticketDailyRangeEndDate)
              ? [moment(ticketDailyRangeStartDate), moment(ticketDailyRangeEndDate)]
              : null
          }
          disabledDate={disabledDateTicketDaily}
          onCalendarChange={onCalendarChangeDateTicketDaily}
          onChange={onChangeTicketDailyCreatedAt}
          onOpenChange={onOpenChangeDateTicketDaily}
          format={dateTimePattern.date}
        />
      ),
    },
  ];

  let tableFilterTicketMonthly = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={ticketMonthlyRangeHackValue || (
            isValidMomentValue(ticketMonthlyRangeStartDate) && isValidMomentValue(ticketMonthlyRangeEndDate)
              ? [moment(ticketMonthlyRangeStartDate), moment(ticketMonthlyRangeEndDate)]
              : null
          )}
          defaultValue={
            isValidMomentValue(ticketMonthlyRangeStartDate) && isValidMomentValue(ticketMonthlyRangeEndDate)
              ? [moment(ticketMonthlyRangeStartDate), moment(ticketMonthlyRangeEndDate)]
              : null
          }
          onCalendarChange={onCalendarChangeTicketMonthly}
          onChange={onChangeTicketMonthlyCreatedAt}
          onOpenChange={onOpenChangeTicketMonthly}
          disabledDate={disabledDateTicketMonthly}
          format={dateTimePattern.month}
          picker="month"
        />
      ),
    },
  ];

  let tableFilterProjectDaily = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={projectDailyRangeHackValue ||
            (isValidMomentValue(projectDailyRangeStartDate) && isValidMomentValue(projectDailyRangeEndDate)
              ? [moment(projectDailyRangeStartDate), moment(projectDailyRangeEndDate)]
              : null)
          }
          defaultValue={
            isValidMomentValue(projectDailyRangeStartDate) && isValidMomentValue(projectDailyRangeEndDate)
              ? [moment(projectDailyRangeStartDate), moment(projectDailyRangeEndDate)]
              : null
          }
          disabledDate={disabledDateProjectDaily}
          onCalendarChange={onCalendarChangeProjectDaily}
          onChange={onChangeProjectDailyCreatedAt}
          onOpenChange={onOpenChangeProjectDaily}
          format={dateTimePattern.date}
        />
      ),
    },
  ];

  let tableFilterProjectMonthly = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={projectMonthlyRangeHackValue || (
            isValidMomentValue(projectMonthlyRangeStartDate) && isValidMomentValue(projectMonthlyRangeEndDate)
              ? [moment(projectMonthlyRangeStartDate), moment(projectMonthlyRangeEndDate)]
              : null
          )}
          defaultValue={
            isValidMomentValue(projectMonthlyRangeStartDate) && isValidMomentValue(projectMonthlyRangeEndDate)
              ? [moment(projectMonthlyRangeStartDate), moment(projectMonthlyRangeEndDate)]
              : null
          }
          onCalendarChange={onCalendarChangeProjectMonthly}
          onChange={onChangeProjectMonthlyCreatedAt}
          onOpenChange={onOpenChangeProjectMonthly}
          disabledDate={disabledDateProjectMonthly}
          format={dateTimePattern.month}
          picker="month"
        />
      ),
    },
  ];

  let tableFilterChatMessageDaily = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={chatMessageDailyRangeHackValue || (
            isValidMomentValue(chatMessageDailyRangeStartDate) && isValidMomentValue(chatMessageDailyRangeEndDate)
              ? [moment(chatMessageDailyRangeStartDate), moment(chatMessageDailyRangeEndDate)]
              : null
          )}
          defaultValue={
            isValidMomentValue(chatMessageDailyRangeStartDate) && isValidMomentValue(chatMessageDailyRangeEndDate)
              ? [moment(chatMessageDailyRangeStartDate), moment(chatMessageDailyRangeEndDate)]
              : null
          }
          disabledDate={disabledDateChatMessageDaily}
          onCalendarChange={onCalendarChangeChatMessageDaily}
          onChange={onChangeChatMessageDailyCreatedAt}
          onOpenChange={onOpenChangeChatMessageDaily}
          format={dateTimePattern.date}
        />
      ),
    },
  ];

  let tableFilterChatMessageMonthly = [
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          value={chatMessageMonthlyRangeHackValue || (
            isValidMomentValue(chatMessageMonthlyRangeStartDate) && isValidMomentValue(chatMessageMonthlyRangeEndDate)
              ? [moment(chatMessageMonthlyRangeStartDate), moment(chatMessageMonthlyRangeEndDate)]
              : null
          )}
          defaultValue={
            isValidMomentValue(chatMessageMonthlyRangeStartDate) && isValidMomentValue(chatMessageMonthlyRangeEndDate)
              ? [moment(chatMessageMonthlyRangeStartDate), moment(chatMessageMonthlyRangeEndDate)]
              : null
          }
          onCalendarChange={onCalendarChangeChatMessageMonthly}
          onChange={onChangeChatMessageMonthlyCreatedAt}
          onOpenChange={onOpenChangeChatMessageMonthly}
          disabledDate={disabledDateChatMessageMonthly}
          format={dateTimePattern.month}
          picker="month"
        />
      ),
    },
  ];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Desk metric > Summary" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New Ticket count (daily)</Title>
                <ul>
                  <li>except for 'INITIALIZED'</li>
                </ul>
                <TableFilter selects={tableFilterTicketDaily} />
                {dataSourceTicketCountDaily ? (
                  <ResponsiveTable
                    responsive={false}
                    bordered={true}
                    loading={ticketCountDailyLoading}
                    columns={ticketCountDailyColumns}
                    scroll={{ x: isMobile ? '100%' : 1550 }}
                    dataSource={convertedDataSourceTicketCountDaily()}
                    pagination={false}
                    style={{ marginTop: '16px' }}
                  />
                ) : (
                  ''
                )}
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>New Ticket count (monthly)</Title>
                <ul>
                  <li>except for 'INITIALIZED'</li>
                </ul>
                <TableFilter selects={tableFilterTicketMonthly} />
                {dataSourceTicketCountMonthly ? (
                  <ResponsiveTable
                    responsive={false}
                    bordered={true}
                    loading={ticketCountMonthlyLoading}
                    columns={ticketCountMonthlyColumns}
                    scroll={{ x: isMobile ? '100%' : 1550 }}
                    dataSource={convertedDataSourceTicketCountMonthly()}
                    pagination={false}
                    style={{ marginTop: '16px' }}
                  />
                ) : (
                  ''
                )}
              </Card>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <Card>
                  <Title level={4}>New Project count (daily)</Title>
                  <TableFilter selects={tableFilterProjectDaily} />
                  {dataSourceProjectCountDaily ? (
                    <ResponsiveTable
                      responsive={false}
                      bordered={true}
                      loading={projectCountDailyLoading}
                      columns={projectCountDailyColumns}
                      scroll={{ x: isMobile ? '100%' : 1550 }}
                      dataSource={convertedDataSourceProjectCountDaily()}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  ) : (
                    ''
                  )}
                </Card>
              </Col>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <Card>
                  <Title level={4}>New Project count (monthly)</Title>
                  <TableFilter selects={tableFilterProjectMonthly} />
                  {dataSourceProjectCountMonthly ? (
                    <ResponsiveTable
                      responsive={false}
                      bordered={true}
                      loading={projectCountMonthlyLoading}
                      columns={projectCountMonthlyColumns}
                      scroll={{ x: isMobile ? '100%' : 1550 }}
                      dataSource={convertedDataSourceProjectCountMonthly()}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  ) : (
                    ''
                  )}
                </Card>
              </Col>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <Card>
                  <Title level={4}>New ChatMessage count (daily)</Title>
                  <TableFilter selects={tableFilterChatMessageDaily} />
                  {dataSourceChatMessageCountDaily ? (
                    <ResponsiveTable
                      responsive={false}
                      bordered={true}
                      loading={chatMessageCountDailyLoading}
                      columns={chatMessageCountDailyColumns}
                      scroll={{ x: isMobile ? '100%' : 1550 }}
                      dataSource={convertedDataSourceChatMessageCountDaily()}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  ) : (
                    ''
                  )}
                </Card>
              </Col>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <Card>
                  <Title level={4}>New ChatMessage count (monthly)</Title>
                  <TableFilter selects={tableFilterChatMessageMonthly} />
                  {dataSourceChatMessageCountMonthly ? (
                    <ResponsiveTable
                      responsive={false}
                      bordered={true}
                      loading={chatMessageCountMonthlyLoading}
                      columns={chatMessageCountMonthlyColumns}
                      scroll={{ x: isMobile ? '100%' : 1550 }}
                      dataSource={convertedDataSourceChatMessageCountMonthly()}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  ) : (
                    ''
                  )}
                </Card>
              </Col>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
