import * as React from 'react';
import moment from 'moment';
import { Card, Col, List, Row, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BillingVoucherStatusTag } from '../ui/Tags';
import { dateTimePattern } from '../../utils/date';
import {VoucherExpireDateChangeDialog} from "./VoucherExpireDateChangeDialog";
import {hasPermission, Permissions} from "../../utils/permission";

const { Text } = Typography;
const Wrapper = styled.div`
  margin-bottom: 24px;
`;

type Props = {
  voucher: any;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const VoucherDetailInfo: React.FC<Props> = ({ voucher }) => {
  const detailBasic = [
    {
      key: 'ID',
      value: voucher.id,
    },
    {
      key: 'UID',
      value: <Text copyable={true}>{voucher.uid}</Text>,
    },
    {
      key: 'Organization',
      value: voucher.organization.uid ? (
        <Link to={`/crm_organizations/${voucher.organization.uid}/`}>{voucher.organization.name}</Link>
      ) : ('-')
    },
    {
      key: 'Status',
      value: <BillingVoucherStatusTag status={voucher.status}/>,
    },
    {
      key: 'Duration days',
      value: voucher.durationDays,
    },
    {
      key: 'Start date',
      value: `${voucher.startDt ? moment(voucher.startDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      key: 'Expire date',
      value: (
        <>
          {voucher.expireDt ? moment(voucher.expireDt).utc().format(dateTimePattern.default) : '-'}
          {hasPermission(Permissions.MANAGE_ORGANIZATION_VOUCHER_TYPE)
            ? (<VoucherExpireDateChangeDialog voucher={voucher} />)
          : <></>}
        </>
      )
    },
    {
      key: 'Expiration reason',
      value: voucher.expireReason
    },
    {
      key: 'Expiration status updated by',
      value: voucher.expireStatusUpdatedBy
    },
    {
      key: 'End date',
      value: `${voucher.endDt ? moment(voucher.endDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      key: 'Fee($)',
      value: <Tag>${voucher.paidAmount/100}</Tag>,
    },
    {
      key: 'Credit',
      value: voucher.credit,
    },
    {
      key: 'Used credit',
      value: voucher.usedCredit,
    },
    {
      key: 'Used credit updated date',
      value: `${voucher.usedCreditUpdatedDt ?
        moment(voucher.usedCreditUpdatedDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      key: 'Created date',
      value: `${voucher.createdAt ?
        moment(voucher.createdAt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      key: 'Updated date',
      value: `${voucher.updatedAt ?
        moment(voucher.updatedAt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      key: 'Deleted date',
      value: `${voucher.deletedAt ?
        moment(voucher.deletedAt).utc().format(dateTimePattern.default) : '-'}`,
    },
  ];

  const dataSources = [detailBasic];

  return (
    <Wrapper>
      <Row
        gutter={16}
        style={{
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '16px 8px 0',
          background: '#f0f2f5',
        }}
      >
        {dataSources.map((dataSource, index) => (
          <Col key={`-info-${index}`} span={24} style={{ paddingBottom: '16px' }}>
            <Card
              size="small"
              style={{
                minHeight: '370px',
              }}
            >
              <List size="small" dataSource={dataSource} renderItem={renderInfoItem} style={{ overflow: 'auto' }} />
            </Card>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};
