import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row, Typography, Input } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getStatusPageComponentGroupsRequest } from '../../api/statusPageComponentGroup';
import queryString from 'query-string';
import { TableFilter } from '../../components/ui/TableFilter';

const { Title, Paragraph } = Typography;
const { Search } = Input;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
    render: (id: StatusPageComponentGroup['id']) => (
      <Link to={`/status_page_component_groups/${id}/`}>{id}</Link>
    ),
  },
  {
    title: 'Group Id',
    dataIndex: 'groupId',
    key: 'groupId',
    width: 90,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 100,
  },
];

type Props = RouteComponentProps;

export const StatusPageComponentGroups = withRouter<Props, any>(
  ({ location, history }) => {
    const [componentGroups, setComponentGroups] = useState<
      Array<StatusPageComponentGroup>
    >([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const qs = queryString.parse(location.search);
    let limit = parseInt(qs.limit ? (qs.limit as string) : '30');
    let offset = parseInt(qs.offset ? (qs.offset as string) : '0');
    let q = qs.q ? (qs.q as string) : '';

    useEffect(() => {
      getStatusPageComponentGroups();
    }, [location]);

    const getStatusPageComponentGroups = () => {
      setLoading(true);
      getStatusPageComponentGroupsRequest({ offset, limit, q })
        .then((response) => {
          setComponentGroups(response.data.results);
          setLoading(false);
          setCount(response.data.count);
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const onPageChange = (page, pageSize) => {
      limit = pageSize;
      offset = (page - 1) * pageSize;
      getStatusPageComponentGroups();
    };

    const onSearch = (value) => {
      q = value;
      offset = 0;
      replacePath();
    };

    const onSearchChange = (e) => {
      q = e.target.value;
    };

    function replacePath() {
      history.replace({
        pathname: '/status_page_group_components/',
        search: `?q=${q}&limit=${limit}&offset=${offset}`,
      });
    }

    return (
      <React.Fragment>
        <Row gutter={16} style={{ marginBottom: '8px' }}>
          <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
            <Title level={3}>StatusPage Component Group List</Title>
            <Paragraph>
              <ul>
                <li>Data source: StatusPage</li>
              </ul>
            </Paragraph>
          </Col>
        </Row>

        <TableFilter
          selects={[
            {
              label: 'Search',
              key: 'search',
              node: (
                <Search
                  placeholder="Search by name or description"
                  enterButton={true}
                  onSearch={onSearch}
                  onChange={onSearchChange}
                />
              ),
            },
          ]}
        />

        <ResponsiveTable
          scroll={{ x: 1770 }}
          loading={loading}
          columns={columns}
          dataSource={componentGroups}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
        />
      </React.Fragment>
    );
  },
);
