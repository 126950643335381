// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getApplicationKeyMetricsRequest,
  getApplicationDailyPeakConnectionRequest,
  getApplicationDailyMauRequest,
  getApplicationMonthlyMauRequest,
  getApplicationDailyMessageCountRequest,
  getApplicationDailyMessageCountPerUserRequest,
  getApplicationMonthlyDauOverMauRequest,
  getApplicationDailyMessagedGroupChannelCountRequest,
  getApplicationDailyTotalGroupChannelMessageCountRequest,
  getApplicationDailyMessageCountPerGroupChannelRequest,
  getApplicationDailyMessagedOpenChannelCountRequest,
  getApplicationDailyTotalOpenChannelMessageCountRequest,
  getApplicationDailyMessageCountPerOpenChannelRequest,
  getApplicationDailyMessagedUserCountRequest,
  getApplicationDailyMessagedUserCountOverDauRequest,
  getApplicationMonthlyPeakConnectionRequest,
} from '../../api/metrics/applications';
import { MetricPageLayout } from '../../components/ui/MetricPageLayout';
import { PlanTag, RegionTag } from '../../components/ui/Tags';
import styled from 'styled-components';
import { getCrmApplicationRequest, getCrmApplicationsRequest } from '../../api/application';
import moment from 'moment-timezone';
import { LineChartComponent } from '../../components/LineChartComponent';
import { floatLabelFormatter, numberLabelFormatter } from '../../utils/data';
import { Table, AutoComplete, Tag, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCheckMobile } from '../../utils/screen';
import { dateTimePattern } from '../../utils/date';

const { Option } = AutoComplete;
const { RangePicker } = DatePicker;

const DetailInfo = styled.div`
  position: relative;
  top: -12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

interface MatchParams {
  appId: string;
}

type SubtitleProps = {
  appId: CrmApplication['appId'];
  appName: CrmApplication['appName'];
  region: CrmApplication['region'];
  plan: CrmApplication['plan'];
  crmOrganization: CrmOrganization;
};

const TableTitle =
  ({ title }) =>
  () => {
    return <strong>{title}</strong>;
  };

const Subtitle: React.FC<SubtitleProps> = ({ appId, appName, region, plan, crmOrganization }) => (
  <DetailInfo>
    <h2>Application Information</h2>
    <span>
      App ID: <Link to={`/applications/${appId}/detail/`}>{appId}</Link> ({appName}){' '}
    </span>
    <span>
      / Oranization name:{' '}
      {crmOrganization && <Link to={`/crm_organizations/${crmOrganization.id}/`}>{crmOrganization.name}</Link>}{' '}
    </span>
    / {region ? <RegionTag region={region} /> : '-'} / {plan === '' || !plan ? '-' : <PlanTag plan={plan} />}
  </DetailInfo>
);

function reverse(array) {
  return array.map((item, idx) => array[array.length - 1 - idx]);
}

const monthlyMauColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'MAU',
    dataIndex: 'mau',
    key: 'mau',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const monthlyPeakConnectionColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Peak Connection',
    dataIndex: 'monthlyPeakConnection',
    key: 'monthlyPeakConnection',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const mauColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'MAU',
    dataIndex: 'mau',
    key: 'mau',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'MAU Limit',
    dataIndex: 'limit',
    key: 'limit',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Reach Rate (%)',
    dataIndex: 'reachRate',
    key: 'reachRate',
    align: 'right',
    render: (rate: number) => rate.toFixed(1),
  },
];

const MessagedGroupChannelCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Group Channel Count',
    dataIndex: 'messagedGroupChannelCount',
    key: 'messagedGroupChannelCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const MessageCountPerGroupChannelColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Group Channel Count',
    dataIndex: 'messagedGroupChannelCount',
    key: 'messagedGroupChannelCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Total Group Channel Message Count',
    dataIndex: 'totalGroupChannelMessageCount',
    key: 'totalGroupChannelMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Message Count Per Group Channel',
    dataIndex: 'messageCountPerGroupChannel',
    key: 'messageCountPerGroupChannel',
    align: 'right',
    render: (count) => count.toFixed(2).toLocaleString(),
  },
];

const GroupChannelMessageCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Total Group Channel Message Count',
    dataIndex: 'totalGroupChannelMessageCount',
    key: 'totalGroupChannelMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const MessagedOpenChannelCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Open Channel Count',
    dataIndex: 'messagedOpenChannelCount',
    key: 'messagedOpenChannelCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const MessageCountPerOpenChannelColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Open Channel Count',
    dataIndex: 'messagedOpenChannelCount',
    key: 'messagedOpenChannelCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Total Open Channel Message Count',
    dataIndex: 'totalOpenChannelMessageCount',
    key: 'totalOpenChannelMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Message Count Per Open Channel',
    dataIndex: 'messageCountPerOpenChannel',
    key: 'messageCountPerOpenChannel',
    align: 'right',
    render: (count) => count.toFixed(2).toLocaleString(),
  },
];

const OpenChannelMessageCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Total Open Channel Message Count',
    dataIndex: 'totalOpenChannelMessageCount',
    key: 'totalOpenChannelMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const dailyPeakConnectionColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Daily Peak Connection',
    dataIndex: 'dailyPeakConnection',
    key: 'dailyPeakConnection',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Connection Limit',
    dataIndex: 'limit',
    key: 'limit',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Reach Rate (%)',
    dataIndex: 'reachRate',
    key: 'reachRate',
    align: 'right',
    render: (rate: number) => rate.toFixed(1),
  },
];

const dailyMessagedUserCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Daily Messaged User Count',
    dataIndex: 'messagedUserCount',
    key: 'messagedUserCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const dailyMessagedUserCountAndDauColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Daily Messaged User Count',
    dataIndex: 'messagedUserCount',
    key: 'messagedUserCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'DAU',
    dataIndex: 'dau',
    key: 'dau',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    align: 'right',
    render: (count) => count.toFixed(2),
  },
];

const dailyMessageCountPerUserColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Daily Message Count',
    dataIndex: 'dailyMessageCount',
    key: 'dailyMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Messaged User Count',
    dataIndex: 'messagedUserCount',
    key: 'messagedUserCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'Sent Message Count Per User',
    dataIndex: 'messageCountPerUser',
    key: 'messageCountPerUser',
    align: 'right',
    render: (count: number) => count.toFixed(2).toLocaleString(),
  },
];

const dailyMessageCountColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'Daily Message Count',
    dataIndex: 'dailyMessageCount',
    key: 'dailyMessageCount',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
];

const monthlyDauOverMauColumns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Average DAU',
    dataIndex: 'dau',
    key: 'dau',
    align: 'right',
    render: (count: number) => count.toFixed(0).toLocaleString(),
  },
  {
    title: 'MAU',
    dataIndex: 'mau',
    key: 'mau',
    align: 'right',
    render: (count) => count.toLocaleString(),
  },
  {
    title: 'DAU Over MAU',
    dataIndex: 'dauOverMau',
    key: 'dauOverMau',
    align: 'right',
    render: (count: number) => count.toFixed(2),
  },
];

type Props = RouteComponentProps<MatchParams>;

export const ApplicationMetrics = withRouter<Props, any>(
  React.memo<Props>(({ match, location, history }) => {
    const isMobile = useCheckMobile();

    const { appId } = match.params;
    const [defaultStartDate, defaultEndDate] = [moment().subtract(13, 'day'), moment()];
    const [defaultStartMonth, defaultEndMonth] = [moment().subtract(11, 'month'), moment()];

    const [crmApplication, setCrmApplication] = useState<CrmApplication>({} as CrmApplication);
    const [applicationKeyMetricsResponse, setApplicationKeyMetricsResponse] = useState<MetricResponse>({
      data: {
        date: '',
        dau: 0,
        mau: 0,
        mauUsageRate: 0,
        averageDau: 0,
        averageDailyPeakConnection: 0,
        averageDailyMessageCount: 0,
      },
    } as MetricResponse);

    const [applicationMonthlyMauResponse, setApplicationMonthlyMauResponse] = useState<MetricResponse>({
      data: [] as Array<{ date: string; mau: number }>,
    } as MetricResponse);

    const [applicationMonthlyPeakConnectionResponse, setApplicationMonthlyPeakConnectionResponse] =
      useState<MetricResponse>({
        data: [] as Array<{ date: string; mau: number }>,
      } as MetricResponse);

    const [applicationDailyMauResponse, setApplicationDailyMauResponse] = useState<MetricResponse>({
      data: [] as Array<{
        date: string;
        limit: number;
        mau: number;
        reachRate: number;
      }>,
    } as MetricResponse);

    const [applicationDailyPeakConnectionResponse, setApplicationDailyPeakConnectionResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          date: string;
          dailyPeakConnection: number;
          limit: number;
          reachRate: number;
        }>,
      } as MetricResponse);

    const [applicationDailyMessageCountResponse, setApplicationDailyMessageCountResponse] = useState<MetricResponse>({
      data: [] as Array<{ recordDate: string; dailyMessageCount: number }>,
    } as MetricResponse);

    const [applicationDailyMessagedUserCountResponse, setApplicationDailyMessagedUserCountResponse] =
      useState<MetricResponse>({
        data: [] as Array<{ recordDate: string; messagedUserCount: number }>,
      } as MetricResponse);

    const [applicationDailyMessagedUserCountAndDauResponse, setApplicationDailyMessagedUserCountAndDauResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          recordDate: string;
          messagedUserCount: number;
          dau: number;
        }>,
      } as MetricResponse);

    const [applicationDailyMessageCountPerUserResponse, setApplicationDailyMessageCountPerUserResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          recordDate: string;
          dailyMessageCount: number;
          messagedUserCount: number;
          messageCountPerUser: number;
        }>,
      } as MetricResponse);

    const [applicationMonthlyDauOverMauResponse, setApplicationMonthlyDauOverMauResponse] = useState<MetricResponse>({
      data: [] as Array<{
        date: string;
        dau: number;
        mau: number;
        dauOverMau: number;
      }>,
    } as MetricResponse);

    const [applicationDailyMessagedGroupChannelCountResponse, setApplicationDailyMessagedGroupChannelCountResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          recordDate: string;
          messagedGroupChannelCount: number;
        }>,
      } as MetricResponse);

    const [
      applicationDailyTotalGroupChannelMessageCountResponse,
      setApplicationDailyTotalGroupChannelMessageCountResponse,
    ] = useState<MetricResponse>({
      data: [] as Array<{
        recordDate: string;
        totalGroupChannelMessageCount: number;
      }>,
    } as MetricResponse);

    const [
      applicationDailyMessageCountPerGroupChannelResponse,
      setApplicationDailyMessageCountPerGroupChannelResponse,
    ] = useState<MetricResponse>({
      data: [] as Array<{
        recordDate: string;
        messagedGroupChannelCount: number;
        totalGroupChannelMessageCount: number;
        messageCountPerGroupChannel: number;
      }>,
    } as MetricResponse);

    const [applicationDailyMessagedOpenChannelCountResponse, setApplicationDailyMessagedOpenChannelCountResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          recordDate: string;
          messagedOpenChannelCount: number;
        }>,
      } as MetricResponse);

    const [
      applicationDailyTotalOpenChannelMessageCountResponse,
      setApplicationDailyTotalOpenChannelMessageCountResponse,
    ] = useState<MetricResponse>({
      data: [] as Array<{
        recordDate: string;
        totalOpenChannelMessageCount: number;
      }>,
    } as MetricResponse);

    const [applicationDailyMessageCountPerOpenChannelResponse, setApplicationDailyMessageCountPerOpenChannelResponse] =
      useState<MetricResponse>({
        data: [] as Array<{
          recordDate: string;
          messagedOpenChannelCount: number;
          totalOpenChannelMessageCount: number;
          messageCountPerOpenChannel: number;
        }>,
      } as MetricResponse);

    const [monthlyMauLoading, setMonthlyMauLoading] = useState<boolean>(false);
    const [monthlyPeakConnectionLoading, setMonthlyPeakConnectionLoading] = useState<boolean>(false);
    const [dailyPeakConnectionLoading, setDailyPeakConnectionLoading] = useState<boolean>(false);
    const [dailyMauLoading, setDailyMauLoading] = useState<boolean>(false);
    const [dailyMessageCountLoading, setDailyMessageCountLoading] = useState<boolean>(false);
    const [dailyMessageCountPerUserLoading, setDailyMessageCountPerUserLoading] = useState<boolean>(false);
    const [dailyMessagedUserCountLoading, setDailyMessagedUserCountLoading] = useState<boolean>(false);
    const [dailyMessagedUserCountAndDauLoading, setDailyMessagedUserCountAndDauLoading] = useState<boolean>(false);
    const [monthlyDauOverMauLoading, setMonthlyDauOverMauLoading] = useState<boolean>(false);
    const [dailyMessagedGroupChannelCountLoading, setDailyMessagedGroupChannelCountLoading] = useState<boolean>(false);
    const [dailyTotalGroupChannelMessageCountLoading, setDailyTotalGroupChannelMessageCountLoading] =
      useState<boolean>(false);
    const [dailyMessageCountPerGroupChannelLoading, setDailyMessageCountPerGroupChannelLoading] =
      useState<boolean>(false);
    const [dailyMessagedOpenChannelCountLoading, setDailyMessagedOpenChannelCountLoading] = useState<boolean>(false);
    const [dailyTotalOpenChannelMessageCountLoading, setDailyTotalOpenChannelMessageCountLoading] =
      useState<boolean>(false);
    const [dailyMessageCountPerOpenChannelLoading, setDailyMessageCountPerOpenChannelLoading] =
      useState<boolean>(false);

    const [dataSource, setDataSource] = useState<CrmApplication[]>([]);
    const rangePresets = {
      'Last 7 Days': [moment().subtract(6, 'day'), moment()],
      'Last 14 Days': [moment().subtract(13, 'day'), moment()],
      'Last 30 Days': [moment().subtract(29, 'day'), moment()],
      'Last 60 Days': [moment().subtract(59, 'day'), moment()],
      'Last 90 Days': [moment().subtract(89, 'day'), moment()],
    };

    const monthRangePresets = {
      'Last 6 Months': [moment().subtract(5, 'month'), moment()],
      'Last 12 Months': [moment().subtract(11, 'month'), moment()],
      'Last 18 Months': [moment().subtract(17, 'month'), moment()],
      'Last 24 Months': [moment().subtract(23, 'month'), moment()],
      'Last 36 Months': [moment().subtract(35, 'month'), moment()],
    };

    function getCrmApplication() {
      getCrmApplicationRequest({ appId })
        .then((response) => {
          setCrmApplication(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const getCrmApplications = (
      offset = 0,
      limit = 50,
      searchQuery,
      region = '',
      plan = '',
      removed = '',
      order = '',
    ) => {
      getCrmApplicationsRequest({
        offset,
        limit,
        searchQuery,
        region,
        plan,
        removed,
        order,
      })
        .then((response) => {
          if (response.data != null && !response.data.hasOwnProperty('results')) {
            setDataSource([response.data]);
          } else {
            setDataSource(response.data.results);
          }
        })
        .catch((error) => {});
    };

    function getApplicationKeyMetrics() {
      getApplicationKeyMetricsRequest(appId)
        .then((response) => {
          setApplicationKeyMetricsResponse(response.data);
        })
        .catch((error) => {});
    }

    function getApplicationMonthlyMau(
      start_month = defaultStartMonth.format('YYYY-MM'),
      end_month = defaultEndMonth.format('YYYY-MM'),
    ) {
      setMonthlyMauLoading(true);

      getApplicationMonthlyMauRequest(appId, start_month, end_month)
        .then((response) => {
          setApplicationMonthlyMauResponse(response.data);
          setMonthlyMauLoading(false);
        })
        .catch((error) => {
          setMonthlyMauLoading(false);
        });
    }

    function getApplicationMonthlyPeakConnection(
      start_month = defaultStartMonth.format('YYYY-MM'),
      end_month = defaultEndMonth.format('YYYY-MM'),
    ) {
      setMonthlyPeakConnectionLoading(true);

      getApplicationMonthlyPeakConnectionRequest(appId, start_month, end_month)
        .then((response) => {
          setApplicationMonthlyPeakConnectionResponse(response.data);
          setMonthlyPeakConnectionLoading(false);
        })
        .catch((error) => {
          setMonthlyMauLoading(false);
        });
    }

    function getApplicationDailyMau(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyPeakConnectionLoading(true);

      getApplicationDailyMauRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMauResponse(response.data);
          setDailyPeakConnectionLoading(false);
        })
        .catch((error) => {
          setDailyPeakConnectionLoading(false);
        });
    }

    function getApplicationDailyPeakConnection(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMauLoading(true);

      getApplicationDailyPeakConnectionRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyPeakConnectionResponse(response.data);
          setDailyMauLoading(false);
        })
        .catch((error) => {
          setDailyMauLoading(false);
        });
    }

    function getApplicationDailyMessageCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessageCountLoading(true);

      getApplicationDailyMessageCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessageCountResponse(response.data);
          setDailyMessageCountLoading(false);
        })
        .catch((error) => {
          setDailyMessageCountLoading(false);
        });
    }

    function getApplicationDailyMessagedGroupChannelCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessagedGroupChannelCountLoading(true);

      getApplicationDailyMessagedGroupChannelCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessagedGroupChannelCountResponse(response.data);
          setDailyMessagedGroupChannelCountLoading(false);
        })
        .catch((error) => {
          setDailyMessagedGroupChannelCountLoading(false);
        });
    }

    function getApplicationDailyTotalGroupChannelMessageCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyTotalGroupChannelMessageCountLoading(true);

      getApplicationDailyTotalGroupChannelMessageCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyTotalGroupChannelMessageCountResponse(response.data);
          setDailyTotalGroupChannelMessageCountLoading(false);
        })
        .catch((error) => {
          setDailyTotalGroupChannelMessageCountLoading(false);
        });
    }

    function getApplicationDailyMessageCountPerGroupChannel(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessageCountPerGroupChannelLoading(true);

      getApplicationDailyMessageCountPerGroupChannelRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessageCountPerGroupChannelResponse(response.data);
          setDailyMessageCountPerGroupChannelLoading(false);
        })
        .catch((error) => {
          setDailyMessageCountPerGroupChannelLoading(false);
        });
    }

    function getApplicationDailyMessagedOpenChannelCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessagedOpenChannelCountLoading(true);

      getApplicationDailyMessagedOpenChannelCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessagedOpenChannelCountResponse(response.data);
          setDailyMessagedOpenChannelCountLoading(false);
        })
        .catch((error) => {
          setDailyMessagedOpenChannelCountLoading(false);
        });
    }

    function getApplicationDailyTotalOpenChannelMessageCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyTotalOpenChannelMessageCountLoading(true);

      getApplicationDailyTotalOpenChannelMessageCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyTotalOpenChannelMessageCountResponse(response.data);
          setDailyTotalOpenChannelMessageCountLoading(false);
        })
        .catch((error) => {
          setDailyTotalOpenChannelMessageCountLoading(false);
        });
    }

    function getApplicationDailyMessageCountPerOpenChannel(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessageCountPerOpenChannelLoading(true);

      getApplicationDailyMessageCountPerOpenChannelRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessageCountPerOpenChannelResponse(response.data);
          setDailyMessageCountPerOpenChannelLoading(false);
        })
        .catch((error) => {
          setDailyMessageCountPerOpenChannelLoading(false);
        });
    }

    function getApplicationDailyMessageCountPerUser(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessageCountPerUserLoading(true);

      getApplicationDailyMessageCountPerUserRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessageCountPerUserResponse(response.data);
          setDailyMessageCountPerUserLoading(false);
        })
        .catch((error) => {
          setDailyMessageCountPerUserLoading(false);
        });
    }

    function getApplicationDailyMessagedUserCount(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessagedUserCountLoading(true);

      getApplicationDailyMessagedUserCountRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessagedUserCountResponse(response.data);
          setDailyMessagedUserCountLoading(false);
        })
        .catch((error) => {
          setDailyMessagedUserCountLoading(false);
        });
    }

    function getApplicationDailyMessagedUserCountOverDau(
      start_date = defaultStartDate.format('YYYY-MM-DD'),
      end_date = defaultEndDate.format('YYYY-MM-DD'),
    ) {
      setDailyMessagedUserCountAndDauLoading(true);

      getApplicationDailyMessagedUserCountOverDauRequest(appId, start_date, end_date)
        .then((response) => {
          setApplicationDailyMessagedUserCountAndDauResponse(response.data);
          setDailyMessagedUserCountAndDauLoading(false);
        })
        .catch((error) => {
          setDailyMessagedUserCountAndDauLoading(false);
        });
    }
    function getApplicationMonthlyDauOverMau() {
      setMonthlyDauOverMauLoading(true);
      let start_month = moment().subtract(11, 'month').format('YYYY-MM');
      let end_month = moment().format('YYYY-MM');

      getApplicationMonthlyDauOverMauRequest(appId, start_month, end_month)
        .then((response) => {
          setApplicationMonthlyDauOverMauResponse(response.data);
          setMonthlyDauOverMauLoading(false);
        })
        .catch((error) => {
          setMonthlyDauOverMauLoading(false);
        });
    }

    const options = dataSource.map((opt) => {
      console.log(opt);
      return (
        <Option key={opt.appId} value={opt.appId}>
          <span>
            <Tag>App ID</Tag>: {opt.appId.slice(0, 7)}...{' '}
          </span>
          <span>
            <Tag>App Name</Tag>: {opt.appName}{' '}
          </span>
          <span>
            <Tag>Org Name</Tag>: {opt.crmOrganization && opt.crmOrganization.name}{' '}
          </span>
          <span>
            <RegionTag region={opt.region} />{' '}
          </span>
          <span>
            <PlanTag plan={opt.plan} />
          </span>
        </Option>
      );
    });

    useEffect(() => {
      getCrmApplication();
      getApplicationKeyMetrics();
      getApplicationMonthlyMau();
      getApplicationMonthlyPeakConnection();
      getApplicationDailyMau();
      getApplicationDailyPeakConnection();
      getApplicationDailyMessageCount();
      getApplicationDailyMessagedUserCount();
      getApplicationDailyMessagedUserCountOverDau();
      getApplicationDailyMessageCountPerUser();
      getApplicationMonthlyDauOverMau();
      getApplicationDailyMessagedGroupChannelCount();
      getApplicationDailyTotalGroupChannelMessageCount();
      getApplicationDailyMessageCountPerGroupChannel();
      getApplicationDailyMessagedOpenChannelCount();
      getApplicationDailyTotalOpenChannelMessageCount();
      getApplicationDailyMessageCountPerOpenChannel();
    }, [location]);

    function callbacksTooltipLabel(tooltipItem, data) {
      return tooltipItem.yLabel.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      });
    }

    function onSelect(value) {
      history.replace({
        pathname: `/metrics/applications/${value}`,
      });
    }

    function handleSearch(value) {
      getCrmApplications(0, 50, value);
    }

    function handleMonthlyMauRangeChange(_, dateStrings) {
      getApplicationMonthlyMau(dateStrings[0], dateStrings[1]);
    }

    function handleMonthlyPeakConnectionRangeChange(_, dateStrings) {
      getApplicationMonthlyPeakConnection(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessagedGroupChannelCountChange(_, dateStrings) {
      getApplicationDailyMessagedGroupChannelCount(dateStrings[0], dateStrings[1]);
    }

    function handleDailyTotalGroupChannelMessageCountChange(_, dateStrings) {
      getApplicationDailyTotalGroupChannelMessageCount(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessageCountPerGroupChannelChange(_, dateStrings) {
      getApplicationDailyMessageCountPerGroupChannel(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessagedOpenChannelCountChange(_, dateStrings) {
      getApplicationDailyMessagedOpenChannelCount(dateStrings[0], dateStrings[1]);
    }

    function handleDailyTotalOpenChannelMessageCountChange(_, dateStrings) {
      getApplicationDailyTotalOpenChannelMessageCount(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessageCountPerOpenChannelChange(_, dateStrings) {
      getApplicationDailyMessageCountPerOpenChannel(dateStrings[0], dateStrings[1]);
    }

    function handleDailyPeakConnectionRangeChange(_, dateStrings) {
      getApplicationDailyPeakConnection(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessageCountRangeChange(_, dateStrings) {
      getApplicationDailyMessageCount(dateStrings[0], dateStrings[1]);
    }

    function handleMessagedUserCountRangeChange(_, dateStrings) {
      getApplicationDailyMessagedUserCount(dateStrings[0], dateStrings[1]);
    }

    function handleMessagedUserCountAndDauRangeChange(_, dateStrings) {
      getApplicationDailyMessagedUserCountOverDau(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMessageCountPerUserRangeChange(_, dateStrings) {
      getApplicationDailyMessageCountPerUser(dateStrings[0], dateStrings[1]);
    }

    function handleDailyMauRangeChange(_, dateStrings) {
      getApplicationDailyMau(dateStrings[0], dateStrings[1]);
    }

    function callBackYAxesTicksNumber(value, index, values) {
      return value.toLocaleString();
    }

    return (
      <MetricPageLayout.Container title="Application Metrics">
        <MetricPageLayout.Section>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: '40px',
            }}
          >
            <AutoComplete
              className="global-search"
              size="large"
              style={{
                width: '80%',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search App name or App ID"
            >
              {options}
            </AutoComplete>
          </div>
          <Subtitle
            appId={crmApplication.appId}
            region={crmApplication.region}
            appName={crmApplication.appName}
            plan={crmApplication.plan}
            crmOrganization={crmApplication.crmOrganization}
          />
          <MetricPageLayout.Information description="This page shows Application Metrics. This metric data sync every 4~6 hour." />
        </MetricPageLayout.Section>

        <MetricPageLayout.Section>
          <MetricPageLayout.KeyMetrics
            metricPermissionInfo={applicationKeyMetricsResponse.permissionInfo}
            statistics={[
              {
                title: `DAU (${applicationKeyMetricsResponse.data.date})`,
                value: applicationKeyMetricsResponse.data.dau,
                precision: 0,
              },
              {
                title: `MAU (${applicationKeyMetricsResponse.data.date})`,
                value: applicationKeyMetricsResponse.data.mau,
                precision: 0,
              },
              {
                title: `MAU Usage Rate (mau / mau limit)`,
                value: applicationKeyMetricsResponse.data.mauUsageRate,
                valueStyle: {
                  color: applicationKeyMetricsResponse.data.mauUsageRate > 90 ? '#cf1322' : '#3f8600',
                },
                suffix: '%',
                precision: 0,
              },
              {
                title: `Average DAU (Last 7 days)`,
                value: applicationKeyMetricsResponse.data.averageDau,
                precision: 0,
              },
              {
                title: `Average Daily Peak Connection (Last 7 days)`,
                value: applicationKeyMetricsResponse.data.averageDailyPeakConnection,
                precision: 0,
              },
              {
                title: `Average Daily Message Count (Last 7 days)`,
                value: applicationKeyMetricsResponse.data.averageDailyMessageCount,
                precision: 0,
              },
            ]}
          />
        </MetricPageLayout.Section>

        <MetricPageLayout.Section>
          <MetricPageLayout.Outline
            items={[
              { targetID: 'monthly-mau', title: 'Monthly MAU' },
              {
                targetID: 'monthly-peak-connection',
                title: 'Monthly Peak Connection',
              },
              { targetID: 'daily-mau', title: 'Daily MAU' },
              {
                targetID: 'daily-peak-connection',
                title: 'Daily Peak Connection',
              },
              { targetID: 'daily-message-count', title: 'Daily Message Count' },
              {
                targetID: 'daily-messaged-user-count',
                title: 'Daily Messaged User Count',
              },
              {
                targetID: 'daily-messaged-user-count-over-dau',
                title: 'Daily Messaged User Count Over DAU',
              },
              {
                targetID: 'daily-message-count-per-user',
                title: 'Daily Sent Message Count Per User',
              },
              {
                targetID: 'monthly-dau-over-mau',
                title: 'Monthly DAU Over MAU',
              },
              {
                targetID: 'daily-messaged-group-channel-count',
                title: 'Daily Messaged Group Channel Count',
              },
              {
                targetID: 'daily-total-group-channel-message-count',
                title: 'Daily Total Message Count in Group channel',
              },
              {
                targetID: 'daily-channel-count-per-group-channel',
                title: 'Daily Message Count Per Group Channel',
              },
              {
                targetID: 'daily-messaged-open-channel-count',
                title: 'Daily Messaged Open Channel Count',
              },
              {
                targetID: 'daily-total-open-channel-message-count',
                title: 'Daily Total Message Count in Open channel',
              },
              {
                targetID: 'daily-channel-count-per-open-channel',
                title: 'Daily Message Count Per Open Channel',
              },
            ]}
          />
        </MetricPageLayout.Section>

        <MetricPageLayout.MetricSection
          id="monthly-mau"
          title="Monthly MAU"
          metricPermissionInfo={applicationMonthlyMauResponse.permissionInfo}
        >
          <MetricPageLayout.Information description="Monthly Mau" dataSource="Insight - CrmApplicationDailyRecord" />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartMonth, defaultEndMonth]}
                    ranges={monthRangePresets as any}
                    allowClear={false}
                    format={dateTimePattern.month}
                    onChange={handleMonthlyMauRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Monthly Mau"
                unit="month"
                labels={applicationMonthlyMauResponse.data.map((d) => d.date)}
                data1={applicationMonthlyMauResponse.data.map((d) => d.mau)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={numberLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Monthly MAU' })}
                bordered={true}
                loading={monthlyMauLoading}
                columns={monthlyMauColumns}
                dataSource={reverse(applicationMonthlyMauResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="monthly-peak-connection"
          title="Monthly Peak Connection"
          metricPermissionInfo={applicationMonthlyPeakConnectionResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Monthly Peak Connection"
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartMonth, defaultEndMonth]}
                    ranges={monthRangePresets}
                    allowClear={false}
                    format={dateTimePattern.month}
                    onChange={handleMonthlyPeakConnectionRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Monthly Peak Connection"
                unit="month"
                labels={applicationMonthlyPeakConnectionResponse.data.map((d) => d.date)}
                data1={applicationMonthlyPeakConnectionResponse.data.map((d) => d.monthlyPeakConnection)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={numberLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Monthly Peak Connection' })}
                bordered={true}
                loading={monthlyPeakConnectionLoading}
                columns={monthlyPeakConnectionColumns}
                dataSource={reverse(applicationMonthlyPeakConnectionResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-mau"
          title="Daily MAU"
          metricPermissionInfo={applicationDailyMauResponse.permissionInfo}
        >
          <MetricPageLayout.Information description="Daily Mau" dataSource="Insight - CrmApplicationDailyRecord" />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMauRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Mau"
                unit="day"
                labels={applicationDailyMauResponse.data.map((d) => d.date)}
                data1={applicationDailyMauResponse.data.map((d) => d.mau)}
                annotationValue={applicationDailyMauResponse.data[0] && applicationDailyMauResponse.data[0].limit}
                annotationName={'MAU Limit'}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={numberLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Daily MAU' })}
                bordered={true}
                loading={dailyMauLoading}
                columns={mauColumns}
                dataSource={reverse(applicationDailyMauResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-peak-connection"
          title="Daily Peak Connection"
          metricPermissionInfo={applicationDailyPeakConnectionResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Peak Connection"
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyPeakConnectionRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Peak Connection"
                unit="day"
                labels={applicationDailyPeakConnectionResponse.data.map((d) => d.date)}
                data1={applicationDailyPeakConnectionResponse.data.map((d) => d.dailyPeakConnection)}
                annotationValue={
                  applicationDailyPeakConnectionResponse.data[0] && applicationDailyPeakConnectionResponse.data[0].limit
                }
                annotationName={'Connection Limit'}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={numberLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Daily Peak Connection' })}
                bordered={true}
                loading={dailyPeakConnectionLoading}
                columns={dailyPeakConnectionColumns}
                dataSource={reverse(applicationDailyPeakConnectionResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-message-count"
          title="Daily Message Count"
          metricPermissionInfo={applicationDailyMessageCountResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Message Count"
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessageCountRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Message Count"
                unit="day"
                labels={applicationDailyMessageCountResponse.data.map((mau) => mau.recordDate)}
                data1={applicationDailyMessageCountResponse.data.map((mau) => mau.dailyMessageCount)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={numberLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Daily Message Count' })}
                bordered={true}
                loading={dailyMessageCountLoading}
                columns={dailyMessageCountColumns}
                dataSource={reverse(applicationDailyMessageCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-messaged-user-count"
          title="Daily Messaged User Count"
          metricPermissionInfo={applicationDailyMessageCountPerUserResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Messaged User Count"
            formulas={['Messaged User count = sent one message at least']}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleMessagedUserCountRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Messaged User Count"
                unit="day"
                labels={applicationDailyMessagedUserCountResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyMessagedUserCountResponse.data.map((data) => data.messagedUserCount)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={floatLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Daily Messaged User Count' })}
                bordered={true}
                loading={dailyMessagedUserCountLoading}
                columns={dailyMessagedUserCountColumns}
                dataSource={reverse(applicationDailyMessagedUserCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-messaged-user-count-over-dau"
          title="Daily Messaged User Count Over DAU"
          metricPermissionInfo={applicationDailyMessagedUserCountAndDauResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Messaged User Count Over DAU"
            formulas={['Daily Messaged User Count Over DAU = Messaged User count(sent one message at least) / DAU']}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleMessagedUserCountAndDauRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Messaged User Count Over DAU"
                unit="day"
                labels={applicationDailyMessagedUserCountAndDauResponse.data.map((mau) => mau.recordDate)}
                data1={applicationDailyMessagedUserCountAndDauResponse.data.map((mau) => mau.rate)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={floatLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({
                  title: 'Daily Messaged User Count Over DAU',
                })}
                bordered={true}
                loading={dailyMessagedUserCountAndDauLoading}
                columns={dailyMessagedUserCountAndDauColumns}
                dataSource={reverse(applicationDailyMessagedUserCountAndDauResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-message-count-per-user"
          title="Daily Sent Message Count Per User"
          metricPermissionInfo={applicationDailyMessageCountPerUserResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Sent Message Count Per User"
            formulas={[
              'sent message count per user = Daily Message count / Messaged User count(sent one message at least) ',
            ]}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessageCountPerUserRangeChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Sent Message Count Per User"
                unit="day"
                labels={applicationDailyMessageCountPerUserResponse.data.map((mau) => mau.recordDate)}
                data1={applicationDailyMessageCountPerUserResponse.data.map((mau) => mau.messageCountPerUser)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={floatLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({
                  title: 'Daily Sent Message Count Per User',
                })}
                bordered={true}
                loading={dailyMessageCountPerUserLoading}
                columns={dailyMessageCountPerUserColumns}
                dataSource={reverse(applicationDailyMessageCountPerUserResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="monthly-dau-over-mau"
          title="Monthly DAU Over MAU"
          metricPermissionInfo={applicationMonthlyDauOverMauResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Monthly DAU Over MAU"
            formulas={['DAU over MAU = Average DAU / MAU']}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Monthly DAU Over MAU"
                unit="month"
                labels={applicationMonthlyDauOverMauResponse.data.map((d) => d.date)}
                data1={applicationMonthlyDauOverMauResponse.data.map((d) => d.dauOverMau)}
                gradient={false}
                height={600}
                callbacksTooltipLabel={callbacksTooltipLabel}
                labelFormatter={floatLabelFormatter}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Monthly DAU Over MAU' })}
                bordered={true}
                loading={monthlyDauOverMauLoading}
                columns={monthlyDauOverMauColumns}
                dataSource={reverse(applicationMonthlyDauOverMauResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-messaged-group-channel-count"
          title="Daily Messaged Group Channel Count"
          metricPermissionInfo={applicationDailyMessagedGroupChannelCountResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Group Messaged Channel Count (After 2019-01-01, Except Go-jek, Dream11 (Can't calculate because of DB Load)"
            formulas={['Messaged Group Channel = Group Channel with sent one message at least ']}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessagedGroupChannelCountChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Messaged Group Channel Count"
                labels={applicationDailyMessagedGroupChannelCountResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyMessagedGroupChannelCountResponse.data.map(
                  (data) => data.messagedGroupChannelCount,
                )}
                gradient={false}
                height={600}
                labelFormatter={numberLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Group Messaged Channel Count' })}
                bordered={true}
                loading={dailyMessagedGroupChannelCountLoading}
                columns={MessagedGroupChannelCountColumns}
                dataSource={reverse(applicationDailyMessagedGroupChannelCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-total-group-channel-message-count"
          title="Daily Total Message Count in Group channel"
          metricPermissionInfo={applicationDailyTotalGroupChannelMessageCountResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Total Group Channel Message Count (After 2019-01-01, Except Go-jek (2019-07-17~) )"
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyTotalGroupChannelMessageCountChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Total Message Count in Group channel"
                labels={applicationDailyTotalGroupChannelMessageCountResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyTotalGroupChannelMessageCountResponse.data.map(
                  (data) => data.totalGroupChannelMessageCount,
                )}
                gradient={false}
                height={600}
                labelFormatter={numberLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({
                  title: 'Total Group Channel Message Count',
                })}
                bordered={true}
                loading={dailyTotalGroupChannelMessageCountLoading}
                columns={GroupChannelMessageCountColumns}
                dataSource={reverse(applicationDailyTotalGroupChannelMessageCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-channel-count-per-group-channel"
          title="Daily Message Count Per Group Channel"
          metricPermissionInfo={applicationDailyMessageCountPerGroupChannelResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Message Count Per Group Channel Count (After 2019-01-01, Except Go-jek (2019-07-17~) )"
            formulas={[
              'Message Count Per Group Channel =  Total Group Channel Message Count / Messaged Group Channel Count ',
            ]}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessageCountPerGroupChannelChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Message Count Per Group Channel"
                labels={applicationDailyMessageCountPerGroupChannelResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyMessageCountPerGroupChannelResponse.data.map(
                  (data) => data.messageCountPerGroupChannel,
                )}
                gradient={false}
                height={600}
                labelFormatter={floatLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Message Count Per Group Channel' })}
                bordered={true}
                loading={dailyMessageCountPerGroupChannelLoading}
                columns={MessageCountPerGroupChannelColumns}
                dataSource={reverse(applicationDailyMessageCountPerGroupChannelResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-messaged-open-channel-count"
          title="Daily Messaged Open Channel Count"
          metricPermissionInfo={applicationDailyMessagedOpenChannelCountResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Open Messaged Channel Count (After 2019-01-01, Except Go-jek (2019-07-17~) )"
            formulas={['Messaged Open Channel = Open Channel with sent one message at least ']}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessagedOpenChannelCountChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Messaged Open Channel Count"
                labels={applicationDailyMessagedOpenChannelCountResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyMessagedOpenChannelCountResponse.data.map(
                  (data) => data.messagedOpenChannelCount,
                )}
                gradient={false}
                height={600}
                labelFormatter={numberLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Messaged Open Channel Count' })}
                bordered={true}
                loading={dailyMessagedOpenChannelCountLoading}
                columns={MessagedOpenChannelCountColumns}
                dataSource={reverse(applicationDailyMessagedOpenChannelCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-total-open-channel-message-count"
          title="Daily Total Message Count in Open Channel"
          metricPermissionInfo={applicationDailyTotalOpenChannelMessageCountResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Total Open Channel Message Count (After 2019-01-01, Except Go-jek (2019-07-17~) )"
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyTotalOpenChannelMessageCountChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Total Message Count in Open Channel"
                labels={applicationDailyTotalOpenChannelMessageCountResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyTotalOpenChannelMessageCountResponse.data.map(
                  (data) => data.totalOpenChannelMessageCount,
                )}
                gradient={false}
                height={600}
                labelFormatter={numberLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({
                  title: 'Total Open Channel Message Count',
                })}
                bordered={true}
                loading={dailyTotalOpenChannelMessageCountLoading}
                columns={OpenChannelMessageCountColumns}
                dataSource={reverse(applicationDailyTotalOpenChannelMessageCountResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>

        <MetricPageLayout.MetricSection
          id="daily-channel-count-per-open-channel"
          title="Daily Message Count Per Open Channel"
          metricPermissionInfo={applicationDailyMessageCountPerOpenChannelResponse.permissionInfo}
        >
          <MetricPageLayout.Information
            description="Daily Message Count Per Open Channel (After 2019-01-01, Except Go-jek (2019-07-17~) )"
            formulas={[
              'Message Count Per Open Channel =  Total Open Channel Message Count / Messaged Open Channel Count ',
            ]}
            dataSource="Insight - CrmApplicationDailyRecord"
          />
          <MetricPageLayout.ChartFilter
            selects={[
              {
                label: 'Range',
                node: (
                  <RangePicker
                    defaultValue={[defaultStartDate, defaultEndDate]}
                    ranges={rangePresets}
                    allowClear={false}
                    format={dateTimePattern.date}
                    onChange={handleDailyMessageCountPerOpenChannelChange}
                  />
                ),
              },
            ]}
          />
          <MetricPageLayout.ChartRow
            layout="horizontal"
            chart={
              <LineChartComponent
                title="Daily Message Count Per Open Channel"
                labels={applicationDailyMessageCountPerOpenChannelResponse.data.map((data) => data.recordDate)}
                data1={applicationDailyMessageCountPerOpenChannelResponse.data.map(
                  (data) => data.messageCountPerOpenChannel,
                )}
                gradient={false}
                height={600}
                labelFormatter={floatLabelFormatter}
                callBackYAxesTicks={callBackYAxesTicksNumber}
              />
            }
            table={
              <Table
                title={TableTitle({ title: 'Message Count Per Open Channel' })}
                bordered={true}
                loading={dailyMessageCountPerOpenChannelLoading}
                columns={MessageCountPerOpenChannelColumns}
                dataSource={reverse(applicationDailyMessageCountPerOpenChannelResponse.data)}
                pagination={false}
                style={{
                  maxHeight: isMobile ? 'none' : 600,
                  overflowY: 'auto',
                }}
              />
            }
          />
        </MetricPageLayout.MetricSection>
      </MetricPageLayout.Container>
    );
  }),
);
