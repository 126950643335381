import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getNewTicketCount(httpBodyData) {
  return insightAxios.post(`${API_DOMAIN}/api/desk_metrics/new_ticket_count/`, httpBodyData, {
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json',
    },
  });
}

export function getNewProjectCount(httpBodyData) {
  return insightAxios.post(`${API_DOMAIN}/api/desk_metrics/new_project_count/`, httpBodyData, {
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json',
    },
  });
}

export function getTicketList({
  projectPid,
  region,
  createdAtGte,
  createdAtLte,
  limit,
  offset,
  status2,
  channelType,
}: {
  projectPid?;
  region?;
  createdAtGte?;
  createdAtLte?;
  limit?;
  offset?;
  status2?;
  channelType?;
}) {
  let params = {
    project__pid: projectPid,
    region,
    created_at__gte: createdAtGte,
    created_at__lte: createdAtLte,
    limit,
    offset,
  };

  if (status2 !== '') {
    params['status2'] = status2;
  }

  if (channelType !== '') {
    params['channel_type'] = channelType;
  }

  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/ticket_list/`, {
    params: params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getRegionListRequest() {
  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/region_list/`, {
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json',
    },
  });
}

export function getProjectList({
  region,
  createdAtGte,
  createdAtLte,
  limit,
  offset,
}: {
  region?;
  createdAtGte?;
  createdAtLte?;
  limit?;
  offset?;
}) {
  let params = {
    region,
    limit,
    offset,
  };

  if (createdAtGte !== '') {
    params['created_at__gte'] = createdAtGte;
  }

  if (createdAtLte !== '') {
    params['created_at__lte'] = createdAtLte;
  }

  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/project_list/`, {
    params: params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getTicketDetailRequest({ id, region }: { id?; region? }) {
  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/ticket_detail/`, {
    params: { id, region },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getNewChatMessageCount(httpBodyData) {
  return insightAxios.post(`${API_DOMAIN}/api/desk_metrics/new_chat_message_count/`, httpBodyData, {
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json',
    },
  });
}

export function getProjectDetailRequest({ pid, region }: { pid?; region? }) {
  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/project_detail/`, {
    params: { pid, region },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getAgentList({
  projectPid,
  region,
  limit,
  offset,
}: {
  projectPid?;
  region?;
  limit?;
  offset?;
}) {
  let params = {
    project__pid: projectPid,
    region,
    limit,
    offset,
  };

  return insightAxios.get(`${API_DOMAIN}/api/desk_metrics/agent_list/`, {
    params: params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
}
