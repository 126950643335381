import * as React from 'react';
import { Row, Col, Card, List } from 'antd';
import { IsJsonString } from '../utils/data';
import { Link } from 'react-router-dom';

import ReactJson from 'react-json-view';

type Props = {
  billingEvent: EventLog;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col
          span={15}
          style={{
            fontWeight: 500,
            textAlign: item.align ? item.align : 'right',
          }}
        >
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const BillingEventLogInfo: React.FC<Props> = ({ billingEvent }) => {
  const detailBasic = [
    {
      key: '#ID',
      value: billingEvent.id,
    },
    {
      key: 'Type',
      value: billingEvent.eventType,
    },
    {
      key: 'Object',
      value: billingEvent.eventType && (
        <Link
          to={`/crm_${billingEvent.eventType.split('.')[0].toLowerCase()}s/${
            billingEvent.object.id
          }/`}
        >
          {billingEvent.object.id}{' '}
        </Link>
      ),
    },
    {
      key: 'Created At',
      value: billingEvent.createdAt,
    },
    {
      key: 'User Name',
      value: billingEvent.requester && (
        <span>{billingEvent.requester.username}</span>
      ),
    },
  ];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      <Col lg={24} xl={12}>
        <Row>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Detail" size="small">
              <List
                size="small"
                dataSource={detailBasic}
                renderItem={renderInfoItem}
              />
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Previous Attributes" size="small">
              {IsJsonString(billingEvent.objectPreviousAttributes) && (
                <ReactJson
                  style={{ overflowX: 'auto' }}
                  src={JSON.parse(billingEvent.objectPreviousAttributes)}
                />
              )}
            </Card>
          </Col>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Updated Attributes" size="small">
              {IsJsonString(billingEvent.objectPreviousAttributes) && (
                <ReactJson
                  style={{ overflowX: 'auto' }}
                  src={Object.keys(
                    JSON.parse(billingEvent.objectPreviousAttributes),
                  ).reduce((obj, k, v) => {
                    obj[k] = JSON.parse(billingEvent.objectData)[k];
                    return obj;
                  }, {})}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Col>

      <Col lg={24} xl={12}>
        <Row>
          <Col span={24} style={{ paddingBottom: '16px' }}>
            <Card title="Object Data" size="small">
              {billingEvent &&
                billingEvent.objectData &&
                IsJsonString(billingEvent.objectData) && (
                  <ReactJson
                    style={{ overflowX: 'auto' }}
                    src={JSON.parse(billingEvent.objectData)}
                  />
                )}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
