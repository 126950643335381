import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

// Deprecated 2024-01-24 @mike.oh
// export function signInRequest({ email, password }) {
//   return insightAxios.post(`${API_DOMAIN}/api-token-auth/`, {
//     email,
//     password,
//   });
// }

export const verify = () => {
  return insightAxios.get(`${API_DOMAIN}/api/authentications/verify/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
};
