import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { TaskStatusTag } from '../../components/ui/Tags';

import { Col, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCeleryTasksRequest } from '../../api/celeryTask';
import { CeleryTaskBackfillDialog } from '../../components/CeleryTaskBackfillDialog';
import Button from 'antd/lib/button';

const { Title } = Typography;

const columns = ({ dialogSuccessCallback }: ColumnsProps) => [
  {
    title: '#Task Id',
    dataIndex: 'taskId',
    key: 'taskId',
    render: (id: CeleryTask['taskId']) => (
      <Link to={`/celery/tasks/${id}/`}>{id}</Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CeleryTask['status']) => <TaskStatusTag status={status} />,
  },
  {
    title: 'Args',
    dataIndex: 'args',
    key: 'args',
  },
  {
    title: 'Task Start Date',
    dataIndex: 'dateStart',
    key: 'dateStart',
  },
  {
    title: 'Task End Date',
    dataIndex: 'dateDone',
    key: 'dateDone',
  },
  {
    title: 'Execution Date',
    dataIndex: 'executionTime',
    render: (executionTime: CeleryTask['executionTime']) =>
      executionTime && `${executionTime} s`,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (celeryTask: CeleryTask) =>
      celeryTask.status === 'FAILURE' ? (
        <CeleryTaskBackfillDialog
          celeryTask={celeryTask}
          successCallback={dialogSuccessCallback}
        />
      ) : (
        '-'
      ),
  },
];

interface ColumnsProps {
  dialogSuccessCallback: () => void;
}

type Props = RouteComponentProps;

export const CeleryTasks = withRouter<Props, any>(({ location, history }) => {
  const [CeleryTasks, setCeleryTask] = useState<Array<CeleryTask>>([]);
  const [loading, setLoading] = useState(false);
  const [CeleryTasksCount, serCeleryTasksCount] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);

  useEffect(() => {
    getCeleryTasks(currentOffset, 30);
  }, [location]);

  const getCeleryTasks = (offset = 0, limit = 0) => {
    setLoading(true);
    getCeleryTasksRequest({ offset, limit })
      .then((response) => {
        setCeleryTask(response.data.results);
        setLoading(false);
        serCeleryTasksCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function dialogSuccessCallback() {
    getCeleryTasks(currentOffset, 30);
  }

  const onPageChange = (page, pageSize) => {
    setCurrentOffset((page - 1) * pageSize);
    getCeleryTasks((page - 1) * pageSize, pageSize);
  };

  return (
    <React.Fragment>
      <Row style={{ marginBottom: '8px' }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
          <Title level={3}>Feature Usage Data Pipeline</Title>
        </Col>
        <Col span={4} md={{ span: 4 }} lg={{ span: 4 }}>
          <Button
            type="primary"
            shape="round"
            icon="sync"
            size="middle"
            href="/laboratory/record_sync"
          >
            Backfill Page Link
          </Button>
        </Col>
      </Row>
      <ResponsiveTable
        loading={loading}
        columns={columns({ dialogSuccessCallback })}
        dataSource={CeleryTasks}
        pagination={{
          total: CeleryTasksCount,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
        }}
      />
    </React.Fragment>
  );
});
