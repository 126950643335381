import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MetricPageLayout } from '../../components/ui/MetricPageLayout';
import { PlanTag, RegionTag } from '../../components/ui/Tags';
import { getCrmApplicationsRequest } from '../../api/application';
import { AutoComplete, Tag } from 'antd';

const { Option } = AutoComplete;

type Props = RouteComponentProps;

export const ApplicationMetricsIndex = withRouter<Props, any>(({ history }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [dataSource, setDataSource] = useState<CrmApplication[]>([]);

  const getCrmApplications = (offset = 0, limit = 15, searchQuery) => {
    getCrmApplicationsRequest({ offset, limit, searchQuery })
      .then((response) => {
        if (response.data != null && !response.data.hasOwnProperty('results')) {
          setDataSource([response.data]);
        } else {
          setDataSource(response.data.results);
        }
      })
      .catch(() => {});
  };

  const options = dataSource.map((opt) => (
    <Option key={opt.appId} value={opt.appId}>
      <span>
        <Tag>App ID</Tag>: {opt.appId.slice(0, 7)}...{' '}
      </span>
      <span>
        <Tag>App Name</Tag>: {opt.appName}{' '}
      </span>
      <span>
        <Tag>Org Name</Tag>: {opt.crmOrganization && opt.crmOrganization.name}{' '}
      </span>
      <span>
        <RegionTag region={opt.region} />{' '}
      </span>
      <span>
        <PlanTag plan={opt.plan} />
      </span>
    </Option>
  ));

  function onSelect(value) {
    console.log(value);
    history.replace({
      pathname: `/metrics/applications/${value}`,
    });
    setSearchValue('');
  }

  function handleSearch(value) {
    setSearchValue(value);
    getCrmApplications(0, 50, value);
  }

  return (
    <MetricPageLayout.Container title="Application Metrics">
      <MetricPageLayout.Section>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '40px',
          }}
        >
          <AutoComplete
            className="global-search"
            size="large"
            style={{
              width: '80%',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onSelect={onSelect}
            onSearch={handleSearch}
            value={searchValue}
            placeholder="Search App name or App ID"
            // optionLabelProp="text"
          >
            {options}
          </AutoComplete>
        </div>
      </MetricPageLayout.Section>
    </MetricPageLayout.Container>
  );
});
