import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Input, Select, Typography, Layout, DatePicker, Checkbox } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCrmOrganizationsRequest } from '../../api/organization';
import { TableFilter } from '../../components/ui/TableFilter';
import queryString from 'query-string';
import { CrmOrganizationVersionTypeTag } from '../../components/ui/Tags';
import { PageTitle } from '../../components/ui/PageTitle';
import moment from 'moment-timezone';
import { dateTimePattern } from '../../utils/date';

const { RangePicker } = DatePicker;

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const types = [
  { key: 'All', value: '' },
  { key: 'Version 1', value: 'basic' },
  { key: 'Version 2', value: 'self-service' },
];

const conversions = [
  { key: 'All', value: '' },
  { key: 'Self-service', value: 'self-service' },
  { key: 'Sales-driven', value: 'sales-driven' },
];

const isValidMomentValue = (value) => moment(value).format(dateTimePattern.date) !== 'Invalid date';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => <Link to={`/crm_organizations/${id}/`}>{id}</Link>,
  },
  {
    title: 'UID',
    dataIndex: 'uid',
    key: 'uid',
    render: (uid: CrmOrganization['uid']) => (
      <Text copyable={true} style={{ fontWeight: 500, wordBreak: 'break-word' }}>
        {uid}
      </Text>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'isSelfServe',
    key: 'isSelfServe',
    render: (isSelfServe: boolean) => <CrmOrganizationVersionTypeTag isSelfServe={isSelfServe} />,
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => <>{moment(createdAt).utc().format(dateTimePattern.default)}</>,
  },
];

type Props = RouteComponentProps;

export const CrmOrganizations = withRouter<Props, any>(({ location, history }) => {
  const [crmOrganizations, setCrmOrganization] = useState<Array<CrmOrganization>>([]);
  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 30,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    type: qs.type ? (qs.type as string) : '',
    first_conversion: qs.first_conversion ? (qs.first_conversion as string) : '',
    searchQuery: qs.query ? (qs.query as string) : '',
    createdAtGte: qs.createdAtGte && isValidMomentValue(qs.createdAtGte) ? (qs.createdAtGte as string) : '',
    createdAtLte: qs.createdAtLte && isValidMomentValue(qs.createdAtLte) ? (qs.createdAtLte as string) : '',
    is_test_organization: qs.is_test_organization ? (qs.is_test_organization as string) : 'false',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [crmOrganizationsCount, setCrmOrganizationsCount] = useState(0);

  const onChangeCreatedAt = (data) => {
    setQuery({
      ...query,
      createdAtGte:
        moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '',
      createdAtLte:
        moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
          ? data[1].format(`${dateTimePattern.date}`)
          : '',
    });
  };

  useEffect(() => {
    history.replace({
      pathname: '/crm_organizations/',
      search: queryString.stringify(query),
    });
    getCrmOrganizations();
  }, [query]);

  const getCrmOrganizations = (offset = 0, limit = 0, q = '') => {
    setLoading(true);
    getCrmOrganizationsRequest({ ...query })
      .then((response) => {
        setCrmOrganization(response.data.results);
        setLoading(false);
        setCrmOrganizationsCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSearch = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, searchQuery: value });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  function handleOnRow(crmOrganization: CrmOrganization) {
    return {
      style: {
        textDecoration: (crmOrganization.status === 'INACTIVE' || crmOrganization.status === 'DELETED') ? 'line-through' : '',
        opacity: (crmOrganization.status === 'INACTIVE' || crmOrganization.status === 'DELETED') ? 0.5 : 1,
      },
    };
  }

  const onTypeSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, type: value });
  };

  const onConversionSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, first_conversion: value });
  };

  const onTestOrganizationClick = (event) => {
    if (event.target.checked) {
      setQuery({...query, limit:30, offset:0, is_test_organization: 'true'})
    } else {
      setQuery({...query, limit:30, offset:0, is_test_organization: 'false'})
    }
  }

  let tableFilter = [
    {
      label: 'Type',
      key: 'type',
      node: (
        <Select value={query.type} onSelect={onTypeSelect} style={{ width: '100%' }}>
          {types.map((type) => (
            <Option key={type.key} value={type.value}>
              {type.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Conversion',
      key: 'conversion',
      node: (
        <Select value={query.first_conversion} onSelect={onConversionSelect} style={{ width: '100%' }}>
          {conversions.map((conversion) => (
            <Option key={conversion.key} value={conversion.value}>
              {conversion.key}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          defaultValue={
            isValidMomentValue(query.createdAtGte) && isValidMomentValue(query.createdAtLte)
              ? [moment(query.createdAtGte), moment(query.createdAtLte)]
              : null
          }
          onChange={onChangeCreatedAt}
          format={dateTimePattern.date}
        />
      ),
    },
    {
      label: 'Test Organization',
      key: 'is_test_organization',
      node: (
        <Checkbox
          checked={query.is_test_organization==='true'} value="true" onClick={onTestOrganizationClick} style={{ width: '100%' }}
        >
          Show Test Organization
        </Checkbox>
      ),
    },
  ];
  return (
    <Layout>
      <PageTitle
        title="Organizations"
        extra={[
          <Search
            key="organizations_search"
            value={searchQuery}
            placeholder="Search organizations"
            enterButton={true}
            onSearch={onSearch}
            onChange={onSearchChange}
          />,
        ]}
      />
      <Layout.Content>
        <TableFilter selects={tableFilter} />
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmOrganizations}
          pagination={{
            total: crmOrganizationsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
