import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import { activateOrganizationRequest } from '../../api/organization';

type Props = {
  crmOrganizationUid: string;
  successCallback: any;
};

export const ActivateOrganizationDialog: React.FC<Props> = ({ crmOrganizationUid, successCallback }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    activateOrganizationRequest({
      crmOrganizationUid,
    })
      .then((response) => {
        handleCancel();

        successCallback &&
          successCallback({
            callback: console.log('ActivateOrganizationRequest', response.data),
          });
      })
      .catch((error) => {
        console.error('Error in : ActivateOrganizationRequest', error);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        size="small"
        style={{
          marginLeft: '0.5em',
        }}
      >
        <span style={{ textDecorationLine: 'none' }}>Activate</span>
      </Button>
      <Modal
        open={visible}
        title={'Activate organization'}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        Are you sure you want to activate this organization?
      </Modal>
    </>
  );
};
