import * as React from 'react';
import moment from 'moment-timezone';
import { Row, Col } from 'antd';
import { InfoCards } from './InfoCard';
import { EmergencyTag } from './ui/Tags';
import { DashboardOtpGenerateDialog } from './DashboardOtpGenerateDialog';
import { DashboardOtpApproveDialog } from './DashboardOtpApproveDialog';
import {DashboardOtpPostApproveDialog} from './DashboardOtpPostApproveDialog';

type Props = {
  dashboardOtp: DashboardOtp;
};

export const DashboardOtpDetailInfo: React.FC<Props> = ({ dashboardOtp }) => {
  const titles = ['Detail', ' '];
  const {
    id,
    teamAccount,
    targetUser,
    status,
    memo,
    reason,
    infoLink,
    isEmergency,
    approvalTeamAccount,
    createdAt,
    approvedAt,
    otpGeneratedAt,
    otpUsedAt,
  } = dashboardOtp;

  const detailBasic = [
    {
      key: 'ID',
      value: id,
    },
    {
      key: 'requester',
      value: teamAccount ? teamAccount.name + ' (' + teamAccount.eid + ')' : '-',
    },
    {
      key: 'Target User',
      value: targetUser ? targetUser.email : '-',
    },
    {
      key: 'status',
      value: status,
    },
    {
      key: 'memo',
      value: memo,
    },
    {
      key: 'reason',
      value: reason,
    },
    {
      key: 'Link',
      value: infoLink ? (
        <a target="_blank" href={`${infoLink}`}>
          Link
        </a>
      ) : (
        '-'
      ),
    },
  ];
  const detailAdvanced = [
    {
      key: 'isEmergency',
      value: <EmergencyTag isEmergency={isEmergency} />,
    },
    {
      key: 'approver',
      value: approvalTeamAccount
        ? approvalTeamAccount.name + ' (' + approvalTeamAccount.eid + ')'
        : (dashboardOtp.status === 'OTP_GENERATED'
          || dashboardOtp.status === 'APPROVAL_PASSED'
          || dashboardOtp.status === 'OTP_USED') && (moment(dashboardOtp.createdAt).utc().isAfter('2021-11-10 23:59:59Z'))
          ? (<DashboardOtpPostApproveDialog dashboardOtp={dashboardOtp} successCallback={dialogSuccessCallback} />)
          : '-',
    },
    {
      key: 'createdAt',
      value: createdAt,
    },
    {
      key: 'approvedAt',
      value: approvedAt ? (
        approvedAt
      ) : (
        <DashboardOtpApproveDialog dashboardOtp={dashboardOtp} successCallback={dialogSuccessCallback} />
      ),
    },
    {
      key: 'otpGeneratedAt',
      value: approvedAt ? (
        otpGeneratedAt ? (
          otpGeneratedAt
        ) : (
          <DashboardOtpGenerateDialog dashboardOtp={dashboardOtp} successCallback={dialogSuccessCallback} />
        )
      ) : (
        '-'
      ),
    },
    {
      key: 'otpUsedAt',
      value: otpUsedAt,
    },
  ];

  function dialogSuccessCallback() {
    window.location.reload();
  }

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 16px 0',
        background: '#f0f2f5',
      }}
    >
      <Col span={24}>
        <InfoCards titles={titles} dataSources={dataSources} minHeight="280px" />
      </Col>
    </Row>
  );
};
