import {insightAxios} from "../index";
import {API_DOMAIN} from "../../App";
import {getAuthToken} from "../../utils/auth";


export function getApplicationKeyMetricsRequest(appId) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/key_metrics/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationMonthlyMauRequest(appId, start_month, end_month) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/monthly_mau/`,
    {
      params: {
        start_month: start_month,
        end_month: end_month
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationMonthlyPeakConnectionRequest(appId, start_month, end_month) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/monthly_peak_connection/`,
    {
      params: {
        start_month: start_month,
        end_month: end_month
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMauRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_mau/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyPeakConnectionRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_peak_connection/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessageCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_message_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessagedUserCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_messaged_user_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessagedUserCountOverDauRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/application_daily_messaged_user_count_over_dau/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessageCountPerUserRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_message_count_per_user/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationMonthlyDauOverMauRequest(appId, start_month, end_month) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/monthly_dau_over_mau/`,
    {
      params: {
        start_month,
        end_month
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessagedGroupChannelCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_messaged_group_channel_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyTotalGroupChannelMessageCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_total_group_channel_message_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessageCountPerGroupChannelRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_message_count_per_group_channel/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessagedOpenChannelCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_messaged_open_channel_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyTotalOpenChannelMessageCountRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_total_open_channel_message_count/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}

export function getApplicationDailyMessageCountPerOpenChannelRequest(appId, start_date, end_date) {
  return insightAxios.get(
    `${API_DOMAIN}/api/metrics/applications/${appId}/daily_message_count_per_open_channel/`,
    {
      params: {
        start_date,
        end_date
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  )
}
