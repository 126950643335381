import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, DatePicker, Select } from 'antd';
import queryString from 'query-string';
import moment from 'moment-timezone';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { PageTitle } from '../../components/ui/PageTitle';
import { dateTimePattern } from '../../utils/date';
import { getTicketList, getRegionListRequest } from '../../api/deskMetrics';
import { TableFilter } from '../../components/ui/TableFilter';

type Props = RouteComponentProps;
const { RangePicker } = DatePicker;
const { Option } = Select;

const statusList = [
  { name: 'PENDING', displayName: 'Pending' },
  { name: 'ACTIVE', displayName: 'Active' },
  { name: 'IDLE', displayName: 'Idle' },
  { name: 'CLOSED', displayName: 'Closed' },
  { name: 'PROACTIVE', displayName: 'Proactive' },
];

const channelTypeList = [
  { name: 'SENDBIRD', displayName: 'Sendbird' },
  { name: 'SENDBIRD_IOS', displayName: 'Sendbird iOS' },
  { name: 'SENDBIRD_ANDROID', displayName: 'Sendbird android' },
  { name: 'SENDBIRD_JAVASCRIPT', displayName: 'Sendbird javascript' },
  { name: 'FACEBOOK_CONVERSATION', displayName: 'Facebook conversation' },
  { name: 'FACEBOOK_FEED', displayName: 'Facebook feed' },
  { name: 'TWITTER_DIRECT_MESSAGE_EVENT', displayName: 'Twitter direct message event' },
  { name: 'TWITTER_STATUS', displayName: 'Twitter status' },
  { name: 'INSTAGRAM_COMMENT', displayName: 'Instagram comment' },
];

export const DeskMetricsTicketList = withRouter<Props, any>(({ location, history }) => {
  const [tickets, setTickets] = useState([] as Array<Ticket>);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [ticketCount, setTicketCount] = useState(0);
  const [regionList, setRegionList] = useState([] as Array<RegionList>);

  const isValidMomentValue = (value) => moment(value).format(dateTimePattern.date) !== 'Invalid date';

  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 20,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    region: qs.region ? (qs.region as string) : '',
    projectPid: qs.projectPid ? (qs.projectPid as string) : '',
    status2: qs.status2 ? (qs.status2 as string) : '',
    channelType: qs.channelType ? (qs.channelType as string) : '',
    createdAtGte:
      qs.createdAtGte && isValidMomentValue(qs.createdAtGte)
        ? (qs.createdAtGte as string)
        : moment().subtract(20, 'days').format(dateTimePattern.date),
    createdAtLte:
      qs.createdAtLte && isValidMomentValue(qs.createdAtLte)
        ? (qs.createdAtLte as string)
        : moment().format(dateTimePattern.date),
  });

  const getRegionList = (cb) => {
    getRegionListRequest()
      .then((response) => {
        setRegionList(response.data.results);
        cb && cb(response.data.results[0]['name']);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ticketsColumns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (id ? <Link to={`/desk_metrics/tickets/${id}/?region=${query.region}`}>{id}</Link> : '-'),
    },
    {
      title: 'Project',
      dataIndex: 'pid',
      key: 'pid',
      render: (projectPid: string, ticket: Ticket) => {
        return (
          <Link to={`/desk_metrics/projects/${ticket.project.pid}/?region=${query.region}`}>{ticket.project.name}</Link>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status2',
      key: 'status2',
      render: (status2: string) => (status2 ? status2 : '-'),
    },
    {
      title: 'Message count',
      dataIndex: 'messageCount',
      key: 'messageCount',
      render: (messageCount: string) => (messageCount ? messageCount : 0),
    },
    {
      title: 'Channel type',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (channelType: string) => (channelType ? channelType : '-'),
    },
    {
      title: 'Ticket type',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType: string) => (ticketType ? ticketType : '-'),
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => <>{moment(createdAt).utc().format(dateTimePattern.default)}</>,
    },
    {
      title: (
        <span>
          Issued at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'issuedAt',
      key: 'issuedAt',
      render: (issuedAt: string) => (issuedAt ? <>{moment(issuedAt).utc().format(dateTimePattern.default)}</> : '-'),
    },
    {
      title: (
        <span>
          Closed at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'closedAt',
      key: 'closedAt',
      render: (closedAt: string) => (closedAt ? <>{moment(closedAt).utc().format(dateTimePattern.default)}</> : '-'),
    },
    {
      title: (
        <span>
          Updated at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string) => (updatedAt ? <>{moment(updatedAt).utc().format(dateTimePattern.default)}</> : '-'),
    },
  ];

  const onChangeCreatedAt = (data) => {
    setQuery({
      ...query,
      createdAtGte:
        moment.isMoment(data && data[0]) && isValidMomentValue(data[0])
          ? data[0].format(`${dateTimePattern.date}`)
          : '',
      createdAtLte:
        moment.isMoment(data && data[1]) && isValidMomentValue(data[1])
          ? data[1].format(`${dateTimePattern.date}`)
          : '',
    });
  };

  const getTicketListFunction = (_region) => {
    setLoadingTickets(true);
    let _query = query;
    if (_query.region === '') {
      _query.region = _region;
      setQuery({...query, region: _region})
    }

    getTicketList({ ..._query })
      .then((response) => {
        setTickets(response.data.results);
        setTicketCount(response.data.count);
        setLoadingTickets(false);
      })
      .catch((error) => {
        setLoadingTickets(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getRegionList(getTicketListFunction);
    history.replace({
      pathname: '/desk_metrics/tickets/',
      search: queryString.stringify(query),
    });
  }, [query]);

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  const onFilterSelect = (key) => (value) => {
    setQuery({ ...query, limit: 20, offset: 0, [key]: value });
  };

  let tableFilter = [
    {
      label: 'Region',
      key: 'region',
      node: (
        <Select value={query.region} onSelect={onFilterSelect('region')} style={{ width: '100%' }}>
          {regionList.map((item) => (
            <Option key={item.name} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Status',
      key: 'status2',
      node: (
        <Select value={query.status2} onSelect={onFilterSelect('status2')} style={{ width: '100%' }}>
          <Option key="Total" value="">
            Total
          </Option>
          {statusList.map((item) => (
            <Option key={item.name} value={item.name}>
              {item.displayName}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Channel type',
      key: 'channelType',
      node: (
        <Select value={query.channelType} onSelect={onFilterSelect('channelType')} style={{ width: '100%' }}>
          <Option key="Total" value="">
            Total
          </Option>
          {channelTypeList.map((item) => (
            <Option key={item.name} value={item.name}>
              {item.displayName}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: 'Created at',
      key: 'createdAt',
      node: (
        <RangePicker
          defaultValue={
            isValidMomentValue(query.createdAtGte) && isValidMomentValue(query.createdAtLte)
              ? [moment(query.createdAtGte), moment(query.createdAtLte)]
              : null
          }
          onChange={onChangeCreatedAt}
          format={dateTimePattern.date}
        />
      ),
    },
  ];

  return (
    <Layout>
      <PageTitle title="Tickets" />
      <Layout.Content>
        <TableFilter selects={tableFilter} />
        <ResponsiveTable
          loading={loadingTickets}
          columns={ticketsColumns}
          dataSource={tickets}
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: ['20', ],
            total: ticketCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
