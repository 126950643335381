import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Row, Layout } from 'antd';
import { dateTimePattern, disabledDate } from '../../utils/date';
import { getDownloadDatabaseOtpRequest, getDownloadDashboardOtpRequest } from '../../api/export';
import { DynamicForm } from '../../components/ui/DynamicForm';
import { FormInstance } from 'antd/lib/form';
import { PageTitle } from '../../components/ui/PageTitle';

const { RangePicker } = DatePicker;

enum ExportEnum {
  DATABASE_OTPS = 'DATABASE_OTPS',
  DASHBOARD_OTPS = 'DASHBOARD_OTPS',
}

const initStart = moment().subtract(1, 'month').add(1, 'day');
const initEnd = moment();

const fields = {
  DATABASE_OTPS: [
    {
      name: 'dates',
      label: 'Period',
      formItemProps: {
        initialValue: [initStart, initEnd],
        rules: [
          {
            type: 'array',
            required: true,
            message: 'Please enter the valid period start date',
          },
        ],
      },
      component: <RangePicker size="large" format={dateTimePattern.date} disabledDate={disabledDate} />,
    },
  ],
  DASHBOARD_OTPS: [
    {
      name: 'dates',
      label: 'Period',
      formItemProps: {
        initialValue: [initStart, initEnd],
        rules: [
          {
            type: 'array',
            required: true,
            message: 'Please enter the valid period start date',
          },
        ],
      },
      component: <RangePicker size="large" format={dateTimePattern.date} disabledDate={disabledDate} />,
    },
  ],
};

const getDateSources = ({ setForms }) => [
  {
    key: ExportEnum.DATABASE_OTPS,
    title: <>Database OTPs</>,
    content: (
      <>
        <DynamicForm
          fields={fields[ExportEnum.DATABASE_OTPS] as any}
          setForm={setForms({ key: ExportEnum.DATABASE_OTPS })}
        />
      </>
    ),
  },
  {
    key: ExportEnum.DASHBOARD_OTPS,
    title: <>Dashboard OTPs</>,
    content: (
      <>
        <DynamicForm
          fields={fields[ExportEnum.DASHBOARD_OTPS] as any}
          setForm={setForms({ key: ExportEnum.DASHBOARD_OTPS })}
        />
      </>
    ),
  },
];

type CardBoxProps = {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
  loading: boolean;
  handleDownload: () => void;
};

type Props = {};

const CardBox: React.FC<CardBoxProps> = React.memo(({ id, title, content, loading, handleDownload }) => (
  <Col span={24} md={12} lg={12}>
    <Card
      title={title}
      actions={[
        <Button
          key={`${id}-download`}
          type="primary"
          icon={<DownloadOutlined />}
          loading={loading}
          onClick={handleDownload}
        >
          Download
        </Button>,
      ]}
      bodyStyle={{ minHeight: '252px' }}
      style={{ marginBottom: '16px' }}
    >
      {content}
    </Card>
  </Col>
));

export const OTPDataExports: React.FC<Props> = React.memo(() => {
  const forms = {
    DATABASE_OTPS: {} as FormInstance,
  };

  const [loadings, setLoadings] = useState({
    DATABASE_OTPS: false,
  });

  function setLoading({ key, value }) {
    const nextLoading = loadings;
    nextLoading[`${key}`] = value;
    setLoadings(nextLoading);
  }

  const setForms = ({ key }) => (ref) => {
    forms[key] = ref;
  };

  function download({ response, filename, dates = [] as Array<Moment> }) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset="utf-8"' }));
    const link = document.createElement('a');
    const dateRange =
      dates.length > 0 ? `_${dates[0].format(dateTimePattern.date)}_${dates[1].format(dateTimePattern.date)}` : '';
    link.href = url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${filename}${dateRange}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const handleDownloads = {
    DATABASE_OTPS: function () {
      setLoading({ key: ExportEnum.DATABASE_OTPS, value: true });
      forms[ExportEnum.DATABASE_OTPS]
        .validateFields()
        .then(async (values) => {
          try {
            const { dates } = values;
            const response = await getDownloadDatabaseOtpRequest({
              startDate: dates[0].format(dateTimePattern.date),
              endDate: dates[1].format(dateTimePattern.date),
            });
            download({ response, filename: 'database_otps', dates });
            setLoading({ key: ExportEnum.DATABASE_OTPS, value: false });
          } catch (error) {
            setLoading({ key: ExportEnum.DATABASE_OTPS, value: false });
            error && error.response && console.error(error.response);
          }
        })
        .catch(() => {
          setLoading({ key: ExportEnum.DATABASE_OTPS, value: false });
        });
    },
    DASHBOARD_OTPS: function () {
      setLoading({ key: ExportEnum.DASHBOARD_OTPS, value: true });
      forms[ExportEnum.DASHBOARD_OTPS]
        .validateFields()
        .then(async (values) => {
          try {
            const { dates } = values;
            const response = await getDownloadDashboardOtpRequest({
              startDate: dates[0].format(dateTimePattern.date),
              endDate: dates[1].format(dateTimePattern.date),
            });
            download({ response, filename: 'dashboard_otps', dates });
            setLoading({ key: ExportEnum.DASHBOARD_OTPS, value: false });
          } catch (error) {
            setLoading({ key: ExportEnum.DASHBOARD_OTPS, value: false });
            error && error.response && console.error(error.response);
          }
        })
        .catch(() => {
          setLoading({ key: ExportEnum.DASHBOARD_OTPS, value: false });
        });
    },
  };

  return (
    <Layout>
      <PageTitle title="OTP Data exports" subTitle="Requires EXPORT_OTP_DATA permission." />
      <Layout.Content>
        <Row gutter={16}>
          {getDateSources({ setForms }).map(({ key, title, content }, index) => (
            <CardBox
              key={`${key}=${index}`}
              id={key}
              title={title}
              content={content}
              loading={loadings[key]}
              handleDownload={handleDownloads[key]}
            />
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
});
