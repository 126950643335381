import * as React from 'react';
import { Card, Col, List, Row } from 'antd';
import styled from 'styled-components';
import { ActiveTag, EnabledTag } from '../ui/Tags';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

type Props = {
  project: Project;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const ProjectDetailInfo: React.FC<Props> = ({ project }) => {
  const detailBasic = [
    {
      key: 'id',
      value: project.id,
    },
    {
      key: 'pid',
      value: project.pid,
    },
    {
      key: 'name',
      value: project.name,
    },
    {
      key: 'sendbirdAppId',
      value: project.sendbirdAppId ? (
        <Link to={`/applications/${project.sendbirdAppId}/detail/`}>{project.sendbirdAppId}</Link>
      ) : (
        ''
      ),
    },
    {
      key: 'liveTicketLimit',
      value: project.liveTicketLimit,
    },
    {
      key: 'activeToIdleDuration',
      value: project.activeToIdleDuration,
    },
    {
      key: 'awayMessage',
      value: project.awayMessage,
    },
    {
      key: 'closeMessage',
      value: project.closeMessage,
    },
    {
      key: 'customerSatisfactionMessage',
      value: project.customerSatisfactionMessage,
    },
    {
      key: 'delayMessage',
      value: project.delayMessage,
    },
    {
      key: 'delayMessageTime',
      value: project.delayMessageTime,
    },
    {
      key: 'downtimeMessage',
      value: project.downtimeMessage,
    },
    {
      key: 'idleTicketAutomaticCloseMessage',
      value: project.idleTicketAutomaticCloseMessage,
    },
    {
      key: 'idleToCloseDuration',
      value: project.idleToCloseDuration,
    },
    {
      key: 'inquireCloseConfirmedMessage',
      value: project.inquireCloseConfirmedMessage,
    },
    {
      key: 'inquireCloseDeclinedMessage',
      value: project.inquireCloseDeclinedMessage,
    },
    {
      key: 'inquireCloseMessage',
      value: project.inquireCloseMessage,
    },
    {
      key: 'leaveMessage',
      value: project.leaveMessage,
    },
    {
      key: 'markAsReadType',
      value: project.markAsReadType,
    },
    {
      key: 'openChannelUrl',
      value: project.openChannelUrl,
    },
    {
      key: 'operationHourDay',
      value: (
        <>
          {Object.entries(JSON.parse(project.operationHourDay)).map((item: Array<any>) => {
            return (
              <div>
                {item[0]}: <EnabledTag isEnabled={item[1]['enabled']} /> From {item[1]['operationTimes'][0]['from']} To{' '}
                {item[1]['operationTimes'][0]['to']}
              </div>
            );
          })}
        </>
      ),
    },
    {
      key: 'operationHourType',
      value: project.operationHourType,
    },
    {
      key: 'operationHourWeek',
      value: (
        <>
          {Object.entries(JSON.parse(project.operationHourWeek)).map((item: Array<any>) => {
            return (
              <div>
                {item[0]}: <EnabledTag isEnabled={item[1]['enabled']} /> From {item[1]['operationTimes'][0]['from']} To{' '}
                {item[1]['operationTimes'][0]['to']}
              </div>
            );
          })}
        </>
      ),
    },
    {
      key: 'projectType',
      value: project.projectType,
    },
    {
      key: 'sendbirdApiDomain',
      value: project.sendbirdApiDomain,
    },
    {
      key: 'timezone',
      value: project.timezone,
    },
    {
      key: 'welcomeMessage',
      value: project.welcomeMessage,
    },
    {
      key: 'wipToPendingDuration',
      value: project.wipToPendingDuration,
    },
  ];
  const detailAdvanced = [
    // {  # Uncomment after desk-production deploy
    //   key: 'createdAt',
    //   value: project.createdAt,
    // },
    {
      key: 'operationHourEnabled',
      value: <ActiveTag isActive={project.operationHourEnabled} />,
    },
    {
      key: 'transferEnabled',
      value: <ActiveTag isActive={project.transferEnabled} />,
    },
    {
      key: 'bulkTransferEnabled',
      value: <ActiveTag isActive={project.bulkTransferEnabled} />,
    },
    {
      key: 'activeToIdleEnabled',
      value: <ActiveTag isActive={project.activeToIdleEnabled} />,
    },
    {
      key: 'automaticCloseAfterInquireEnabled',
      value: <ActiveTag isActive={project.automaticCloseAfterInquireEnabled} />,
    },
    {
      key: 'automaticWipToPendingEnabled',
      value: <ActiveTag isActive={project.automaticWipToPendingEnabled} />,
    },
    {
      key: 'customerSatisfactionEnabled',
      value: <ActiveTag isActive={project.customerSatisfactionEnabled} />,
    },
    {
      key: 'downtimeMessageEnabled',
      value: <ActiveTag isActive={project.downtimeMessageEnabled} />,
    },
    {
      key: 'isAutomaticCloseIdleEnabled',
      value: <ActiveTag isActive={project.isAutomaticCloseIdleEnabled} />,
    },
    {
      key: 'proactiveChatEnabled',
      value: <ActiveTag isActive={project.proactiveChatEnabled} />,
    },
    {
      key: 'ticketTransferToMaxedOutAgentEnabled',
      value: <ActiveTag isActive={project.ticketTransferToMaxedOutAgentEnabled} />,
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Wrapper>
      <Row
        gutter={16}
        style={{
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '16px 8px 0',
          background: '#f0f2f5',
        }}
      >
        {dataSources.map((dataSource, index) => (
          <Col key={`-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
            <Card
              size="small"
              style={{
                minHeight: '370px',
              }}
            >
              <List size="small" dataSource={dataSource} renderItem={renderInfoItem} style={{ overflow: 'auto' }} />
            </Card>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};
