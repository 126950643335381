// from production settings.
export const regionNames =
  process.env.REACT_APP_ENV === 'production'
    ?
    [
      { key: 'All Regions', value: '' },
      { key: 'Tokyo (ap-1)', value: 'ap-1' },
      { key: 'Seoul (ap-2)', value: 'ap-2' },
      { key: 'Maya, Traveloka (ap-3)', value: 'ap-3' },
      { key: 'Carousell (ap-4)', value: 'ap-4' },
      { key: 'Singapore (ap-5)', value: 'ap-5' },
      { key: 'Ola (ap-6) (Removed)', value: 'ap-6' },
      { key: 'Mumbai (ap-8)', value: 'ap-8' },
      { key: 'Sydney (ap-9)', value: 'ap-9' },
      { key: 'USA West (us-1)', value: 'us-1' },
      { key: 'USA East (us-2)', value: 'us-2' },
      { key: 'USA East (us-3)', value: 'us-3' },
      { key: 'Reddit (Removed)', value: 'reddit-east' },
      { key: 'Frankfurt (eu-1)', value: 'eu-1' },
      { key: 'Canada Central (ca-1)', value: 'ca-1' },
      { key: 'VMUAE', value: 'vmuae' },
      { key: 'ACCOLADE', value: 'accolade' },
      { key: 'ACCOLADE-GOV (Removed)', value: 'accolade-gov' },
      { key: 'ZEPETTO (Removed)', value: 'zepetto' },
      { key: 'YAHOO', value: 'yahoo' },
      { key: 'DREAM11', value: 'dream11' },
      { key: 'PAYTM', value: 'paytm' },
      { key: 'PAYTMCS (Removed)', value: 'paytmcs' },
      { key: 'SCHIBSTED', value: 'schibsted' },
      { key: 'SERTIS (Removed)', value: 'sertis' },
      { key: 'GRANDROUNDS', value: 'grandrounds' },
      { key: 'LIVONGO', value: 'livongo' },
      // WA-6583 load test region
      // { key: 'GOJEK-TEST', value: 'gojek-test' },
      { key: 'NEXON', value: 'nexon' },
      { key: 'PAYPAY', value: 'paypay' },
      { key: 'WOOWA', value: 'woowa' },
      { key: 'WOOWATEST', value: 'woowatest' },
      { key: 'RAKUTEN', value: 'rakuten' },
      { key: 'Doordash', value: 'doordash' },
      { key: 'PicPay', value: 'picpay' },
      { key: 'Hinge', value: 'hinge' },
      { key: 'PUBG New State', value: 'pubg' },
      { key: 'Pocket Gems (Removed)', value: 'pocketgems' },
      { key: 'AirAsia (Removed)', value: 'airasia'},
      { key: 'ProvHealth (Removed)', value: 'provhealth'},
      { key: 'PayPal (Removed)', value: 'paypal'}
    ]
    :
    [
      { key: 'All Regions', value: '' },
      { key: 'Staging - Tokyo (ap-1)', value: 'staging' },
      { key: 'Preprod - Tokyo (ap-1)', value: 'preprod' },
    ]
