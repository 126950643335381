import React, { useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';

import { updateUserRequest } from '../../api/user';
import { userLocationOptions } from '../../utils/constants/users';
import { values } from 'lodash';

type Props = {
  user: User;
  successCallback: any;
};

export const UserLocationChangeDialog: React.FC<Props> = ({ user, successCallback }) => {
  const [visible, setVisible] = useState(false);
  const [location, setLocation] = useState(user.location);

  function onClick() {
    setVisible(true);
  }

  function onOk() {
    console.log('onOk');
    updateUserRequest({
      user,
      values: { location: location },
    })
      .then((response) => {
        setVisible(false);
        successCallback &&
          successCallback({
            callback: console.log('updateUserRequestPayload', response.data),
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onCancel() {
    setVisible(false);
  }

  function onChange(value) {
    setLocation(value);
  }

  const buttonStyle = {
    marginLeft: '0.5em',
    verticalAlign: 'middle',
  };

  return (
    <>
      <Button type="primary" size="small" onClick={onClick} style={buttonStyle}>
        Change
      </Button>
      <Modal title="Change User Location" open={visible} onOk={onOk} onCancel={onCancel}>
        <Form.Item label="Location">
          <Select defaultValue={user.location} onChange={onChange}>
            {userLocationOptions.map(({ value, label }) => (
              <Select.Option value={value}>{label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
    </>
  );
};
