import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getCeleryChildTasksRequest,
  getCeleryTaskRequest,
} from '../../api/celeryTask';

import { CeleryTaskInfo } from '../../components/CeleryTaskInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { TaskStatusTag } from '../../components/ui/Tags';
import { PieChartComponent } from '../../components/PieChartComponent';
import randomColor from 'randomcolor/randomColor';
import { numberLabelFormatter } from '../../utils/data';

const columns = [
  {
    title: '#Task Id',
    dataIndex: 'taskId',
    key: 'taskId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CeleryTask['status']) => <TaskStatusTag status={status} />,
  },
  {
    title: 'Task Start Date',
    dataIndex: 'dateStart',
    key: 'dateStart',
  },
  {
    title: 'Task End Date',
    dataIndex: 'dateDone',
    key: 'dateDone',
  },
  {
    title: 'Execution Date',
    dataIndex: 'executionTime',
    key: 'executionTime',
    render: (executionTime: CeleryTask['executionTime']) =>
      executionTime && `${executionTime} s`,
  },
];

interface MatchParams {
  celeryTaskId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const CeleryTask = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { celeryTaskId } = match.params;
    const [celeryTask, setCeleryTask] = useState<CeleryTask>({} as CeleryTask);

    const [childTasks, setChildTasks] = useState<Array<CeleryTask>>([]);

    const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
    const [membersCount, setMembersCount] = useState<number>(0);
    const [chartColor, setChartColor] = useState([]);

    function getCeleryTask({ callback }) {
      getCeleryTaskRequest({ celeryTaskId })
        .then((response) => {
          setCeleryTask(response.data);
          callback && callback();
        })
        .catch((error) => {});
    }

    function getCeleryChildTasks({ offset = 0, limit = 0 }) {
      setLoadingMembers(true);
      getCeleryChildTasksRequest({ celeryTaskId, limit, offset })
        .then((response) => {
          setChildTasks(response.data.results);
          setLoadingMembers(false);
          setMembersCount(response.data.count);
          setChartColor(
            randomColor({
              luminosity: 'bright',
              seed: response.data.count,
              count: response.data.count,
            }),
          );
        })
        .catch((error) => {
          setLoadingMembers(false);
        });
    }

    useEffect(() => {
      getCeleryTask({
        callback: function () {
          getCeleryChildTasks({
            offset: 0,
            limit: 10,
          });
        },
      });
    }, []);

    return (
      <React.Fragment>
        <PageTitle
          title="Task Detail"
          subTitle={`#${(celeryTask.id && celeryTask.id.toString()) || ''}`}
          showBack={true}
        />
        <CeleryTaskInfo celeryTask={celeryTask} />

        <PieChartComponent
          title="Execution TIme"
          labels={childTasks.map((o) => o.name.split('.')[1])}
          data={childTasks.map((o) => parseInt(o.executionTime))}
          backgroundColor={chartColor}
          labelFormatter={numberLabelFormatter}
          height={400}
        />
        <TableDetail
          title="Child Tasks"
          count={membersCount}
          loading={loadingMembers}
          columns={columns}
          dataSource={childTasks}
          onPageChange={getCeleryChildTasks}
        />
      </React.Fragment>
    );
  }),
);
