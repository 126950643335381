import * as React from 'react';
import { useState } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { RunBackfillCeleryTaskRequest } from '../api/celeryTask';
import Tag from 'antd/lib/tag';

const { Title, Text } = Typography;

type Props = {
  celeryTask: CeleryTask;
  successCallback: (payload?: any) => void;
};

export const CeleryTaskBackfillDialog: React.FC<Props> = React.memo(({ celeryTask, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [results, setResults] = useState(null);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    successCallback();
  }

  function handleSubmit() {
    setLoading(true);
    console.log('sumit');
    RunBackfillCeleryTaskRequest({ celeryTaskId: celeryTask.taskId })
      .then((response) => {
        setResults(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button type="primary" icon={<SyncOutlined />} onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
        Run Backfill
      </Button>
      <Modal
        open={visible}
        title={`Backfill #${celeryTask.taskId}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          !results ? (
            <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
              Run
            </Button>
          ) : (
            ''
          ),
        ]}
      >
        <Title level={4}>Task ID</Title>
        <Text>{celeryTask.taskId}</Text>

        <Title level={4}>Target Date</Title>
        <Text>{celeryTask.args}</Text>

        <Title level={4}>Warning</Title>
        <Text type="danger">
          When you run backfill, you had better check server status (accumulate celery job count) And you can see the
          result of backfill, when it's finished (taskes about 5~10 minutes)
        </Text>

        <Title level={4}>Warning</Title>
        <Text style={{ marginLeft: 32 }}>{results ? <Tag color="blue">{results}</Tag> : ''}</Text>
      </Modal>
    </React.Fragment>
  );
});
