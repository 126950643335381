import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmApplicationDailyRecordRankingRequest({
  order,
  limit,
  offset,
  date
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/ranking/?order=${order}&limit=${limit}&offset=${offset}&date=${date}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
