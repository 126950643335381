import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { PageTitle } from '../../components/ui/PageTitle';

import { getRolesRequest } from '../../api/roles';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/roles/${id}`}>{id}</Link>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

type Props = RouteComponentProps;

export const Roles = withRouter<Props, any>(() => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rolesCount, setRolesCount] = useState(0);

  async function getRoles(limit, offset) {
    setLoading(true);
    try {
      const response = await getRolesRequest({ limit, offset });
      setRoles(response.data.results);
      setLoading(false);
      setRolesCount(response.data.count);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPageChange(page, perPage) {
    const limit = perPage;
    const offset = (page - 1) * perPage;
    getRoles(limit, offset);
  }

  useEffect(() => {
    getRoles(30, 0);
  }, []);

  return (
    <Layout>
      <PageTitle title="Roles" />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={roles}
          pagination={{
            total: rolesCount,
            pageSizeOptions: ['30', '60', '90'],
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
