import React, { useState } from 'react';
import { Layout, Form, Input, Button, Modal, Switch } from 'antd';

import { PageTitle } from './ui/PageTitle';
import { forceChangeApplicationDeskToggleRequest } from '../api/application';

export const ApplicationInternalUpdate = () => {
  const [deskToggleModalVisible, setDeskToggleModalVisible] = useState(false);
  const [deskToggleAppId, setDeskToggleAppId] = useState('');
  const [deskToggleAppRegion, setDeskToggleAppRegion] = useState('');
  const [deskToggleAppName, setDeskToggleAppName] = useState('');
  const [deskToggleEnabledValue, setToggleEnabledValue] = useState(true);

  function showDeskToggleModal() {
    setDeskToggleModalVisible(true);
  }

  function onDeskToggleCancel() {
    setDeskToggleModalVisible(false);
  }

  function onDeskToggleAppIdChange(value) {
    setDeskToggleAppId(value.target.value);
  }

  function onDeskToggleAppRegionChange(value) {
    setDeskToggleAppRegion(value.target.value);
  }

  function onDeskToggleAppNameChange(value) {
    setDeskToggleAppName(value.target.value);
  }

  function onDeskToggleValueChange(value) {
    setToggleEnabledValue(value);
  }

  function onDeskToggleModalSubmit() {
    forceChangeApplicationDeskToggleRequest({
      appId: deskToggleAppId,
      region: deskToggleAppRegion,
      name: deskToggleAppName,
      enabled: deskToggleEnabledValue,
    })
      .then(() => {
        setDeskToggleModalVisible(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const buttonStyle = {
    margin: '2em',
    padding: '0.5em',
    height: '3em',
    verticalAlign: 'middle',
  };

  return (
    <Layout>
      <PageTitle title="Internal Application Update" />
      <Button type="primary" size="small" onClick={showDeskToggleModal} style={buttonStyle}>
        Force Update Desk Feature
      </Button>
      <Modal
        title="Change Application Desk Feature (Internal Only)"
        open={deskToggleModalVisible}
        onOk={onDeskToggleModalSubmit}
        onCancel={onDeskToggleCancel}
      >
        <Layout.Content>
          <Form.Item>
            <Input addonBefore="App-ID" onChange={onDeskToggleAppIdChange} />
            <div style={{ margin: '24px 0' }} />
            <Input addonBefore="Region" onChange={onDeskToggleAppRegionChange} />
            <div style={{ margin: '24px 0' }} />
            <Input addonBefore="Name" onChange={onDeskToggleAppNameChange} />
            <div style={{ margin: '24px 0' }} />
            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
              <span style={{ fontWeight: 500 }}>Desk Enable &nbsp;&nbsp;&nbsp;</span>
              <Switch defaultChecked onChange={onDeskToggleValueChange} />
            </div>
          </Form.Item>
        </Layout.Content>
      </Modal>
    </Layout>
  );
};
