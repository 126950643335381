import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { TableDetail } from '../ui/TableDetail';
import { getBillingVouchersRequest } from '../../api/billing';
import { Tag } from 'antd';
import moment from 'moment-timezone';
import { dateTimePattern } from '../../utils/date';
import { BillingVoucherStatusTag } from '../ui/Tags';

export const OrganizationCallVouchers = ({ crmOrganizationUid }) => {
  const [loadingOrganizationCallVouchers, setLoadingOrganizationCallVouchers] = useState(false);
  const [organizationCallVouchersCount, setOrganizationCallVouchersCount] = useState(0);
  const [organizationCallVouchers, setOrganizationCallVouchers] = useState([] as Array<any>);
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 20, offset: 0 });

  const getOrganizationCallVouchers = useCallback(
    (callback) => {
      setLoadingOrganizationCallVouchers(true);
      getBillingVouchersRequest({
        organizationUid: crmOrganizationUid,
        ...pagination,
      })
        .then((response) => {
          setOrganizationCallVouchers(response.data.results);
          setOrganizationCallVouchersCount(response.data.count);
          setLoadingOrganizationCallVouchers(false);
          callback && callback();
        })
        .catch((err) => {
          setLoadingOrganizationCallVouchers(false);
          console.error(err);
        });
    },
    [crmOrganizationUid, pagination],
  );

  const organizationCallVouchersColumns = [
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
      render: (uid: string) => (uid ? <Link to={`/billing_vouchers/${uid}/`}>{uid}</Link> : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <BillingVoucherStatusTag status={status}/>;
      },
    },
    {
      title: 'Fee($)',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      render: (paidAmount) => { return <Tag>${paidAmount/100}</Tag> }
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit'
    },
    {
      title: 'Used Credit',
      dataIndex: 'usedCredit',
      key: 'usedCredit'
    },
    {
      title: 'Start date',
      dataIndex: 'startDt',
      key: 'startDt',
      render: (startDt) => `${startDt ? moment(startDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      title: 'Expire date',
      dataIndex: 'expireDt',
      key: 'expireDt',
      render: (expireDt) => `${expireDt ? moment(expireDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      title: (
        <span>
          Expire reason
        </span>
      ),
      dataIndex: 'expireReason',
      key: 'expireReason',
      render: (expireReason: string) => <>{expireReason}</>,
    },
    {
      title: (
        <span>
          Expire reason updated by
        </span>
      ),
      dataIndex: 'expireStatusUpdatedBy',
      key: 'expireStatusUpdatedBy',
      render: (expireStatusUpdatedBy: string) => <>{expireStatusUpdatedBy}</>,
    },
  ];

  useEffect(() => {
    getOrganizationCallVouchers(() => {});
  }, [getOrganizationCallVouchers]);

  return (
    <TableDetail
      title={'Call Vouchers'}
      count={organizationCallVouchersCount}
      loading={loadingOrganizationCallVouchers}
      columns={organizationCallVouchersColumns}
      dataSource={organizationCallVouchers}
      onPageChange={setPagination}
      pageSizeOptions={['20', '40', '60']}
    />
  );
};
