import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getStatusPageComponentGroupRequest } from '../../api/statusPageComponentGroup';

import { StatusPageComponentGroupInfo } from '../../components/StatusPageComponentGroupInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { Typography } from 'antd';

const { Paragraph } = Typography;

interface MatchParams {
  groupId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const StatusPageComponentGroup = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { groupId } = match.params;

    const [statusPageComponentGroup, setStatusPageComponentGroup] = useState(
      {} as StatusPageComponentGroup,
    );

    function getStatusPageComponentGroup() {
      getStatusPageComponentGroupRequest({ groupId })
        .then((response) => {
          setStatusPageComponentGroup(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getStatusPageComponentGroup();
    }, []);

    return (
      <React.Fragment>
        <PageTitle
          title="StatusPage Component Group Detail"
          subTitle={`#${
            (statusPageComponentGroup.id &&
              statusPageComponentGroup.id.toString()) ||
            ''
          }`}
          showBack={true}
        />
        <Paragraph> Data source: StatusPage</Paragraph>
        <StatusPageComponentGroupInfo
          statusPageComponentGroup={statusPageComponentGroup}
        />
      </React.Fragment>
    );
  }),
);
