import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, List, Modal, Row, Typography } from 'antd';
import { useCheckMobile } from '../../utils/screen';
import { approveUserMigrationRequestRequest, denyUserMigrationRequestRequest } from '../../api/userMigrationRequest';
import { ApplicationPlanRequestStatusTag } from '../../components/ui/Tags';
import { hasPermission, Permissions } from '../../utils/permission';

const { Title } = Typography;

type Props = {
  userMigrationRequest: UserMigrationRequest;
  successCallback: (payload?: any) => void;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {item[0]}
        </Col>
        <Col span={12} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item[1] ? item[1] : '-'}
        </Col>
      </Row>
    </List.Item>
  );
};

export const UserMigrationRequestViewDialog: React.FC<Props> = React.memo(
  ({ userMigrationRequest, successCallback }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    function showModal() {
      setVisible(true);
    }

    function handleCancel() {
      setLoading(false);
      setVisible(false);
    }

    function handleApprove() {
      setLoading(true);
      approveUserMigrationRequestRequest({ id: userMigrationRequest.id })
        .then((response) => {
          setLoading(false);
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handleDeny() {
      setLoading(true);
      denyUserMigrationRequestRequest({ id: userMigrationRequest.id })
        .then((response) => {
          setLoading(false);
          handleCancel();
          successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function convertDataSource(_userMigrationRequest) {
      return [
        ['#ID', _userMigrationRequest ? _userMigrationRequest.id : '-'],
        [
          'User',
          _userMigrationRequest && _userMigrationRequest.userToMigrate ? (
            <Link to={`/crm_users/${_userMigrationRequest.userToMigrate.id}/`}>
              {_userMigrationRequest.userToMigrate.email}
            </Link>
          ) : (
            '-'
          ),
        ],
        [
          'From Organization',
          _userMigrationRequest && _userMigrationRequest.fromOrganization ? (
            <Link to={`/crm_organizations/${_userMigrationRequest.fromOrganization.id}/`}>
              {_userMigrationRequest.fromOrganization.id}
            </Link>
          ) : (
            '-'
          ),
        ],
        [
          'To Organization',
          _userMigrationRequest ? (
            <Link to={`/crm_organizations/${_userMigrationRequest.toOrganization.id}/`}>
              {_userMigrationRequest.toOrganization.id}
            </Link>
          ) : (
            '-'
          ),
        ],
        ['Role', <span>{_userMigrationRequest ? _userMigrationRequest.role : '-'}</span>],
        [
          <span>
            Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          _userMigrationRequest ? _userMigrationRequest.createdAt : '-',
        ],
        [
          'Requester',
          <span>
            {_userMigrationRequest && _userMigrationRequest.requester && _userMigrationRequest.requester.username}
          </span>,
        ],
        [
          'Status',
          _userMigrationRequest ? <ApplicationPlanRequestStatusTag status={_userMigrationRequest.status} /> : '-',
        ],
        [
          'Reviewer',
          <span>
            {_userMigrationRequest && _userMigrationRequest.reviewer && _userMigrationRequest.reviewer.username}
          </span>,
        ],
        [
          <span>
            Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>,
          _userMigrationRequest && _userMigrationRequest.reviewedAt ? _userMigrationRequest.reviewedAt : '-',
        ],
        [
          'Reason',
          <span>{_userMigrationRequest && _userMigrationRequest.reason ? _userMigrationRequest.reason : '-'}</span>,
        ],
      ];
    }

    type FormTitleProps = {
      title: string;
    };

    const FormTitle: React.FC<FormTitleProps> = ({ title }) => (
      <Title
        level={4}
        style={{
          margin: 0,
          marginTop: '16px',
          marginBottom: '8px',
          paddingBottom: '6px',
          fontSize: '16px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
        }}
      >
        {title}
      </Title>
    );

    return (
      <React.Fragment>
        <Button type="default" onClick={showModal} style={{ margin: '0.25em', marginLeft: 0 }}>
          View
        </Button>
        <Modal
          open={visible}
          title={`User Migration Request View #${userMigrationRequest ? userMigrationRequest.id : ''}`}
          width={useCheckMobile() ? '' : '650px'}
          onOk={handleApprove}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            hasPermission(Permissions.REVIEW_USER_MIGRATION_REQUEST) &&
            userMigrationRequest &&
            userMigrationRequest.status === 'REQUESTED' ? (
              <Button
                key="submitDeny"
                danger={true}
                disabled={userMigrationRequest.status !== 'REQUESTED'}
                onClick={handleDeny}
              >
                Deny
              </Button>
            ) : (
              ''
            ),
            hasPermission(Permissions.REVIEW_USER_MIGRATION_REQUEST) &&
            userMigrationRequest &&
            userMigrationRequest.status === 'REQUESTED' ? (
              <Button
                key="submitApprove"
                type="primary"
                disabled={userMigrationRequest.status !== 'REQUESTED'}
                loading={loading}
                onClick={handleApprove}
              >
                Approve
              </Button>
            ) : (
              ''
            ),
          ]}
        >
          <FormTitle title="Detail" />
          <List size="small" dataSource={convertDataSource(userMigrationRequest)} renderItem={renderInfoItem} />
        </Modal>
      </React.Fragment>
    );
  },
);
