import React, { useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';

import { updateOrganizationPaymentMethodRequest } from '../api/organization';

type Props = {
  crmOrganization: CrmOrganization;
  successCallback: any;
};

export const OrganizationPaymentMethodChangeDialog: React.FC<Props> = ({ crmOrganization, successCallback }) => {
  const [visible, setVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(crmOrganization.paymentMethod);

  function onClick() {
    setVisible(true);
  }

  function onOk() {
    console.log('onOk');
    updateOrganizationPaymentMethodRequest({
      crmOrganizationId: crmOrganization.id,
      paymentMethod: paymentMethod,
    })
      .then((response) => {
        setVisible(false);
        successCallback &&
          successCallback({
            callback: console.log('updateOrganizationPaymentMethodRequest', response.data),
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onCancel() {
    setVisible(false);
  }

  function onChange(value) {
    setPaymentMethod(value);
  }

  const buttonStyle = {
    marginLeft: '0.5em',
    verticalAlign: 'middle',
  };

  return (
    <>
      <Button type="primary" size="small" onClick={onClick} style={buttonStyle}>
        Change
      </Button>
      <Modal title="Change Payment Method" open={visible} onOk={onOk} onCancel={onCancel}>
        <p>Changing payment method will affect invoicing moving forward only.</p>
        <p>It will not update any invoices that are already created.</p>
        <Form.Item label="Payment Method">
          <Select defaultValue={crmOrganization.paymentMethod} onChange={onChange}>
            <Select.Option value="CARD">CARD</Select.Option>
            <Select.Option value="WIRE">WIRE</Select.Option>
            <Select.Option value="MANUAL">MANUAL</Select.Option>
          </Select>
        </Form.Item>
      </Modal>
    </>
  );
};
