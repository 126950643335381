import {useEffect, useState} from 'react';
import {MEDIA_SIZES} from './media';

export const useCheckMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  function updateIsMobile() {
    if (isMobile && window.innerWidth >= MEDIA_SIZES.MOBILE_LARGE) {
      setIsMobile(false);
    }

    if (!isMobile && window.innerWidth < MEDIA_SIZES.MOBILE_LARGE) {
      setIsMobile(true);
    }
  }

  useEffect(function () {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return function () {
      window.removeEventListener('resize', updateIsMobile);
    };
  });

  return isMobile;
};
