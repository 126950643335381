import React, {FC, useEffect} from 'react';
import '@ant-design/compatible/assets/index.css';
import {Alert, Card, Col, Collapse, Form, Row} from 'antd';
import {FormLayout} from 'antd/lib/form/Form';
import styled, {css} from 'styled-components';

type Props = DynamicFormProps;

const { Panel } = Collapse;
const NestedCard = styled(Card)`
  margin-bottom: 16px;
  ${({ type }) =>
    type === 'inner'
      ? css`
          margin: 8px 0;
        `
      : ''}
`;

const DescriptionAlert = styled(Alert)`
  margin-bottom: 16px;
  ul {
    padding-inline-start: 20px;
    margin-bottom: 0;
  }
`;

const renderNestedFields: (
  name: string,
  description: Field['description'],
  fields: Field[],
  hasParent: boolean,
  collapsible: boolean
) => React.ReactNode = (name, description, fields, hasParent = false, collapsible = false) => {
  return (
    <Col span={24}>
      <NestedCard title={name} {...(hasParent ? { type: 'inner' } : {})}>
        {description && <DescriptionAlert message="" type="info" description={description} />}
        <Row gutter={16}>
          {fields.map((field) => {
            if (field.fields) {
              const description = field?.description || '';
              return renderNestedFields(field.name, description, field.fields, true, false);
            }
            return (
              <Col span={24 / fields.length} key={field.name}>
                <Form.Item name={field.name} label={field.label} style={field.style} {...field.formItemProps}>
                  {field.component}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </NestedCard>
    </Col>
  )
};

const renderCollapsibleNestedFields: (
  name: string,
  description: Field['description'],
  fields: Field[],
  hasParent: boolean,
) => React.ReactNode = (name, description, fields, hasParent = false) => {
  return (
    <Panel header={name} key={name}>
      <NestedCard title={name} {...(hasParent ? { type: 'inner' } : {})}>
        {description && <DescriptionAlert message="" type="info" description={description} />}
        <Row gutter={16}>
          {fields.map((field) => {
            if (field.fields) {
              const description = field?.description || '';
              return renderNestedFields(field.name, description, field.fields, true, false);
            }
            return (
              <Col span={24 / fields.length} key={field.name}>
                <Form.Item name={field.name} label={field.label} style={field.style} {...field.formItemProps}>
                  {field.component}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </NestedCard>
    </Panel>
  )
};

export const DynamicForm: FC<Props> = React.memo(
  ({ formProps, layout = 'vertical' as FormLayout, fields, setForm }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      setForm(form);
    }, [form]);

    return (
      <Form form={form} layout={layout} {...formProps}>
        <Row gutter={16}>
          {fields.map(
            ({ name, description = '', label, span = 24, formItemProps, component, style, fields: nestedFields, collapsible, isCollapsed }) => {
              if (nestedFields) {
                if (collapsible === true){
                  let defaultActiveKeys = [""];
                  if (!isCollapsed) {
                    defaultActiveKeys = [...defaultActiveKeys, name];
                  }
                  return (
                    <Col span={24}>
                      <Collapse defaultActiveKey={defaultActiveKeys}>
                        {renderCollapsibleNestedFields(name, description, nestedFields, false)}
                      </Collapse>
                    </Col>
                  )
                } else {
                  return renderNestedFields(name, description, nestedFields, false, false);
                }

              }
              if (name.indexOf('CUSTOM_TITLE') > -1) {
                return (
                  <Col key={name} span={24}>
                    {component}
                  </Col>
                );
              } else {
                return (
                  <>
                    {description && <DescriptionAlert message="" type="info" description={description} />}
                    <Col key={name} span={span}>
                      <Form.Item name={name} label={label} style={style} {...formItemProps}>
                        {component}
                      </Form.Item>
                    </Col>
                  </>
                );
              }
            },
          )}
        </Row>
      </Form>
    );
  },
);
