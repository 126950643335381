import React, {useContext} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AuthenticationContext} from '../containers/AuthenticationProvider';

export const PrivateRoute = (
  props: PrivateRouteProps &
    RouteProps & {
      Provider?: ({ children }: { children: any }) => JSX.Element;
    },
) => {
  const { component, Provider, ...rest } = props;
  const Component = component;

  const { authenticated } = useContext(AuthenticationContext);

  const ConditionalRender = (props: any) => {
    if (authenticated) {
      if (Provider) {
        return (
          <Provider>
            <Component {...props} {...rest} />
          </Provider>
        );
      }
      return <Component {...props} {...rest} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/auth/signin',
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={ConditionalRender} />;
};
