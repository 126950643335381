export const billingCycleOptions = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'ANNUALLY', label: 'Annually' },
  { value: 'CUSTOM', label: 'Custom' },
];

export const paymentMethodOptions = [
  { value: 'CARD', label: 'Card' },
  { value: 'WIRE', label: 'Wire' },
];

export const paymentTypeOptions = [
  { value: 'SUBSCRIPTION', label: 'Subscription' },
  { value: 'ONETIME', label: 'One Time' },
  { value: 'EXTRA', label: 'Extra' },
];

export const _paymentTypeOptions = {
  PAYMENT_ITEM_TYPE_SUBSCRIPTION: 'SUBSCRIPTION',
  PAYMENT_ITEM_TYPE_ONETIME: 'ONETIME',
  PAYMENT_ITEM_TYPE_EXTRA: 'EXTRA',
};

export const _paymentMethodOptions = {
  PAYMENT_ITEM_PAYMENT_METHOD_WIRE: 'WIRE',
  PAYMENT_ITEM_PAYMENT_METHOD_CARD: 'CARD',
};

export const paymentStatusOptions = [
  { value: 'QUEUED', label: 'Queued' },
  { value: 'CHARGED', label: 'Charged' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'DISABLED', label: 'Disabled' },
];

export const _paymentStatusOptions = {
  PAYMENT_ITEM_STATUS_QUEUED: 'QUEUED',
  PAYMENT_ITEM_STATUS_CHARGED: 'CHARGED',
  PAYMENT_ITEM_STATUS_FAILED: 'FAILED',
  PAYMENT_ITEM_STATUS_DISABLED: 'DISABLED',
};

export const _subscriptionPaymentMethodOptions = {
  CUSTOM_PLAN_SUBSCRIPTION_PAYMENT_METHOD_CARD: 'CARD',
  CUSTOM_PLAN_SUBSCRIPTION_PAYMENT_METHOD_WIRE: 'WIRE',
};

/**
 * Legacy Version 1
 */
export const SupportLevelMarks = {
  0: 'Community',
  1: 'Base',
  2: 'L1',
  3: 'L2',
  4: 'L3',
  // 5: 'L4',
};

export const supportLevelFormatter = (value) => {
  switch (value) {
    case 0:
      return SupportLevelMarks[0];
    case 1:
      return SupportLevelMarks[1];
    case 2:
      return SupportLevelMarks[2];
    case 3:
      return SupportLevelMarks[3];
    case 4:
      return SupportLevelMarks[4];
    case 5:
      return 'L4'; // SupportLevelMarks[5];
    default:
      return 0;
  }
};
