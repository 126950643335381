import React, {Component} from 'react';

export class MetricPageSection extends Component {
  render() {
    return (
      <section style={{marginBottom: 100}}>
        {this.props.children}
      </section>
    )
  }
};

export class HeaderPageSection extends Component {
  render() {
    return (
      <section style={{marginBottom: 50}}>
        {this.props.children}
      </section>
    )
  }
};

export class KeyMetricPageSection extends Component {
  render() {
    return (
      <section style={{marginBottom: 50}}>
        {this.props.children}
      </section>
    )
  }
};


