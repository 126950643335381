import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash';

import { TicketDetailInfo } from '../../components/deskMetrics/TicketDetailInfo';

import { PageTitle } from '../../components/ui/PageTitle';
import { Spinner } from '../../components/ui/Spinner';
import { getTicketDetailRequest } from '../../api/deskMetrics';
import queryString from 'query-string';

interface MatchParams {
  ticketId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const DeskMetricsTicketDetail = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { ticketId } = match.params;
    const [loadingTicket, setLoadingTicket] = useState(false);
    const [ticket, setTicket] = useState({} as Ticket);
    const qs = queryString.parse(location.search);
    const region = qs.region ? qs.region : '';

    const getTicket = () => {
      setLoadingTicket(true);
      getTicketDetailRequest({ id: ticketId, region: region })
        .then((response) => {
          setTicket(response.data);
          setLoadingTicket(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    useEffect(() => {
      getTicket();
    }, []);

    return isEmpty(ticket) || loadingTicket ? (
      <Spinner />
    ) : (
      <>
        <PageTitle
          title="Ticket Detail"
          subTitle={`#${(ticket.id && ticket.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <TicketDetailInfo
            ticket={ticket}
            region={region}
          />
        </Layout.Content>
      </>
    );
  }),
);
