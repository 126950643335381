import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

// NOTE: Billing Statistics uses underscore case for HTTP body variables

export function getTotalSubscriptionCountListBySubscriptionName(httpBodyData) {
  // HTTP body variable list: See main/api/billing_metrics.py

  return insightAxios.post(
    `${API_DOMAIN}/api/billing_metrics/total_subscription_count_list_by_subscription_name/`,
    httpBodyData,
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getNewOrganizationCount(httpBodyData) {
  // HTTP body variable list: See main/api/billing_metrics.py

  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/new_organization_count/`,
    httpBodyData,
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getNewSubscriptionCount(httpBodyData) {
  // HTTP body variable list: See main/api/billing_metrics.py

  return insightAxios.post(
    `${API_DOMAIN}/api/billing_metrics/new_subscription_count/`,
    httpBodyData,
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getNewInvoiceCount(httpBodyData) {
  // HTTP body variable list: See main/api/billing_metrics.py

  return insightAxios.post(
    `${API_DOMAIN}/api/billing_metrics/new_invoice_count/`,
    httpBodyData,
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getSubscriptionNameListRequest() {
  // HTTP body variable list: See main/api/billing_metrics.py

  return insightAxios.get(
    `${API_DOMAIN}/api/billing_metrics/subscription_name_list/`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}
