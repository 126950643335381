import React, { useState, useEffect } from 'react';
import { verify } from '../api/auth';

export const AuthenticationContext = React.createContext<{
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  authenticated: false,
  setAuthenticated: (prevState: boolean) => false,
});

export const AuthenticationProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!authenticated) {
      if (token) {
        try {
          verify();
          setAuthenticated(true);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [authenticated, token]);
  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        setAuthenticated,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
