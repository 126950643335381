import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';
import {TimeunitEnum} from '../utils/constants/application';

export function getCrmApplicationRequest({ appId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/${appId}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmApplicationsRequest({
  limit = 30,
  offset = 0,
  searchQuery = '',
  region = '',
  plan = '',
  removed = '',
  order = '',
  deskEnabled = '',
  callsEnabled = '',
  paused = '',
  is_test_organization='',
}) {
  // javascript regex for checking if searchQuery matchs "88A8592D-63E2-4C00-AE48-F6F27FA43341" format
  const uuidRegex = new RegExp(
    '^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-' +
      '[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$',
  );
  searchQuery = searchQuery.trim();
  if (searchQuery !== '' && uuidRegex.test(searchQuery)) {
    return insightAxios.get(`${API_DOMAIN}/api/crm_applications/${searchQuery}/`, {
      headers: {
        Authorization: getAuthToken(),
      },
    });
  } else{
    return insightAxios.get(`${API_DOMAIN}/api/crm_applications/`, {
      params: {
        limit,
        offset,
        query: searchQuery,
        region,
        plan,
        removed,
        order,
        deskEnabled,
        callsEnabled,
        paused,
        is_test_organization,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    });
  }
}

export function getCrmApplicationsRegionsRequest() {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/regions/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getExpiringApplicationPlansRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/expiring_application_plans/`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationDauRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/daily_dau_counts/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationMauRequest({ startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/monthly_mau_counts/`,
    {
      params: {
        start_month: startDate,
        end_month: endDate,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationDailyRecordSyncRequest({
  start_date,
  end_date,
  records,
  regions,
  is_async,
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/sync/`,
    {
      params: {
        start_date,
        end_date,
        records: JSON.stringify(records),
        regions: JSON.stringify(regions),
        is_async,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateApplicationNoteRequest({
  appId,
  noteCompanyName,
  noteServiceName,
  noteAccountClassification,
  noteHubspotLink,
  noteChurn,
  noteAccountLead,
  noteMemo,
  noteChurnDate,
}) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_applications/${appId}/`,
    {
      noteCompanyName,
      noteServiceName,
      noteAccountClassification,
      noteHubspotLink,
      noteChurn,
      noteAccountLead,
      noteMemo,
      noteChurnDate,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateApplicationPlanRequest({ appId, plan }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_applications/${appId}/`,
    {
      plan,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationApplicationPlansRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/application_plans/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationApplicationPlanRequest(appId, planId) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/application_plans/${planId}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

// Deprecated 2024-01-14 @mike.oh
// export function getRemainApplicationPlanRequest(appId) {
//   return insightAxios.get(
//     `${API_DOMAIN}/api/crm_applications/${appId}/remain_application_plans_count/`,
//     {
//       headers: {
//         Authorization: getAuthToken(),
//       },
//     },
//   );
// }

export function getCrmApplicationFiltersUnpaidRequest({ counts }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/filters_unpaid/`,
    {
      params: {
        counts,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

// Deprecated 2024-01-24 @mike.oh
// export function getActiveRecordsRequest({ appId, limit, offset }) {
//   return insightAxios.get(
//     `${API_DOMAIN}/api/crm_applications/${appId}/active_user_records/`,
//     {
//       params: {
//         limit,
//         offset,
//       },
//       headers: {
//         Authorization: getAuthToken(),
//       },
//     },
//   );
// }

// Deprecated 2024-01-24 @mike.oh
// export function getActiveRecordPricingsRequest({ appId, limit, offset }) {
//   return insightAxios.get(
//     `${API_DOMAIN}/api/crm_applications/${appId}/active_user_record_pricings/`,
//     {
//       params: {
//         limit,
//         offset,
//       },
//       headers: {
//         Authorization: getAuthToken(),
//       },
//     },
//   );
// }

export function getDailyRecordsRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/daily_records_paginated/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationChannelsRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/channels/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getChannelRequest({ appId, channelId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/channels/${channelId}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationModeratorsRequest({ appId, limit, offset }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/${appId}/moderators/`, {
    params: {
      limit,
      offset,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function updateApplicationModeratorRequest({ id, appId, allowedChannelCustomTypes }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_applications/${appId}/moderators/`,
    {
      id,
      allowedChannelCustomTypes,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationAnnouncementsRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/announcements/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationDailyCountsRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/daily_counts/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmApplicationMonthlyCountsRequest({
  startMonth,
  endMonth,
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/monthly_counts/`,
    {
      params: {
        start_month: startMonth,
        end_month: endMonth,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCcuCountsRequest({
  appId,
  startDate,
  endDate,
  timeunit,
}: {
  appId: string;
  startDate: string;
  endDate: string;
  timeunit: TimeunitEnum;
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/ccu_counts/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        timeunit,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationDailyRecordRegionDauCountsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/region_dau_counts/`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmApplicationDailyRecordRegionMauCountsRequest(
  target_month,
) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_application_daily_records/region_mau_counts/`,
    {
      params: {
        target_month,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getApplicationCountsRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/daily_counts/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getApplicationAttributesRequest({ appId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/application_attributes/`,
    {
      params: {},
      headers: { Authorization: getAuthToken() },
    },
  );
}

export function getSyncCrmApplicationRequest(appId) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/${appId}/sync/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function updateApplicationPauseRequest({ appId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_applications/${appId}/pause/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateApplicationUnpauseRequest({ appId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_applications/${appId}/unpause/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function changeApplicationCallsEnabledRequest({ appId, enabled }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_applications/${appId}/enable_calls/`,
    { calls_enabled: enabled },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function changeApplicationDeskEnabledRequest({ appId, enabled }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_applications/${appId}/switch_desk/`,
    { desk_enabled: enabled },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function forceChangeApplicationDeskToggleRequest({ appId, region, name, enabled }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_applications/force_switch_desk/`,
    { desk_enabled: enabled, crm_application_app_id: appId, crm_application_app_name: name, crm_application_region: region },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getApplicationDailyFeatureUsageRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/daily_feature_usages/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getApplicationMonthlyFeatureUsageRequest({ appId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/monthly_feature_usages/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}
