import * as React from 'react';
import { useState, useEffect } from 'react';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { Card, Row, Col, Typography } from 'antd';

import { getExpiringSubscriptionsRequest } from '../../api/subscription';
import { getExpiringApplicationPlansRequest } from '../../api/application';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import {
  RegionTag,
  PlanTag,
  BillingCycleTag,
  AutoRenewalTag,
  PaymentMethodTag,
} from '../../components/ui/Tags';

const { Paragraph } = Typography;

type Props = RouteComponentProps;

const expiringSubscriptionColumns = [
  {
    title: '#CrmSubscriptionId',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) =>
      subscription.id,
  },
  {
    title: 'App ID',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) => (
      <Link to={`/applications/${subscription.crmApplication.appId}/detail/`}>
        {subscription.crmApplication.appId.split('-')[0]}...
      </Link>
    ),
  },
  {
    title: 'Subscription Date(D-day)',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (
      subscription: ExpiringSubscription['subscription'],
      expiringSubscription: ExpiringSubscription,
    ) => (
      <React.Fragment>
        <span>
          {subscription.subscriptionStartDt} ~ {subscription.subscriptionEndDt}
        </span>{' '}
        (D-{expiringSubscription.dDay})
      </React.Fragment>
    ),
  },
  {
    title: 'Auto Renewal',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) => (
      <AutoRenewalTag
        autoRenewal={subscription.autoRenewal}
        autoRenewalPeriod={subscription.autoRenewalPeriod}
      />
    ),
  },
  {
    title: 'AE',
    dataIndex: 'leadName',
    key: 'leadName',
  },
  {
    title: 'Organization',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) =>
      subscription.crmApplication.crmOrganization ? (
        <Link
          to={`/crm_organizations/${subscription.crmApplication.crmOrganization.id}/`}
        >
          {subscription.crmApplication.crmOrganization.id} (
          {subscription.crmApplication.crmOrganization.name})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Fee (Product/Service)',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) => (
      <span>
        ${subscription.productFee.toLocaleString()} / $
        {subscription.serviceFee.toLocaleString()}
      </span>
    ),
  },
  {
    title: 'Method',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) => {
      return <PaymentMethodTag paymentMethod={subscription.paymentMethod} />;
    },
  },
  {
    title: 'Cycle',
    dataIndex: 'subscription',
    key: 'subscription',
    render: (subscription: ExpiringSubscription['subscription']) => {
      return subscription && subscription.billingCycle ? (
        <BillingCycleTag billingCycle={subscription.billingCycle} />
      ) : (
        '-'
      );
    },
  },
];

const expiringApplicaitonColumn = [
  {
    title: '#CrmAppId',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) =>
      crmApplication.id,
  },
  {
    title: 'App ID',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) => (
      <Link to={`/applications/${crmApplication.appId}/detail/`}>
        {crmApplication.appId.split('-')[0]}...
      </Link>
    ),
  },
  {
    title: 'Plan End Date(D-day)',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (
      endDate: ExpiringApplicationPlan['endDate'],
      expiringAppPlan: ExpiringApplicationPlan,
    ) => (
      <span>
        {endDate} (D-{expiringAppPlan.dDay})
      </span>
    ),
  },
  {
    title: 'AE',
    dataIndex: 'leadName',
    key: 'leadName',
  },
  {
    title: 'Name',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) =>
      crmApplication.appName,
  },
  {
    title: 'Organization',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) => (
      <Link to={`/crm_organizations/${crmApplication.crmOrganization.id}/`}>
        {crmApplication.crmOrganization.id} (
        {crmApplication.crmOrganization.name})
      </Link>
    ),
  },
  {
    title: 'Region',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) => {
      return <RegionTag region={crmApplication.region} />;
    },
  },
  {
    title: 'Plan',
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) => {
      const plan = crmApplication.plan;
      return plan === '' || !plan ? '-' : <PlanTag plan={plan} />;
    },
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'crmApplication',
    key: 'crmApplication',
    render: (crmApplication: ExpiringApplicationPlan['crmApplication']) =>
      crmApplication.createdAt,
  },
];

export const DashboardDailyInsight = withRouter<Props, any>(({ history }) => {
  const [expiringSubscriptions, setExpiringSubscriptions] = useState([]);
  const [expiringAppPlans, setExpiringAppPlans] = useState([]);
  const [
    expiringSubscriptionsLoading,
    setExpiringSubscriptionsLoading,
  ] = useState(false);
  const [expiringAppPlansLoading, setExpiringAppPlansLoading] = useState(false);

  useEffect(function () {
    getExpiringSubscriptions();
    getExpiringApplicationPlans();
  }, []);

  function getExpiringSubscriptions() {
    setExpiringSubscriptionsLoading(true);
    getExpiringSubscriptionsRequest()
      .then((response) => {
        setExpiringSubscriptionsLoading(false);
        setExpiringSubscriptions(response.data.results);
      })
      .catch((error) => {
        setExpiringSubscriptionsLoading(false);
        console.error(error);
      });
  }

  function getExpiringApplicationPlans() {
    setExpiringAppPlansLoading(true);
    getExpiringApplicationPlansRequest()
      .then((response) => {
        setExpiringAppPlans(response.data.results);
        setExpiringAppPlansLoading(false);
      })
      .catch((error) => {
        setExpiringAppPlansLoading(false);
        console.error(error);
      });
  }

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col style={{ marginBottom: '16px' }}>
        <Card title="Expiring Subscriptions">
          <Paragraph>
            The subscription list with an expiring subscription within 30 days.
          </Paragraph>
          <ResponsiveTable
            bordered={true}
            loading={expiringSubscriptionsLoading}
            columns={expiringSubscriptionColumns}
            dataSource={expiringSubscriptions}
            pagination={false}
          />
        </Card>
      </Col>
      <Col>
        <Card title="Applications with an Expiring Plan">
          <Paragraph>
            The application list with an expiring plan within 20 days.
          </Paragraph>
          <ResponsiveTable
            bordered={true}
            loading={expiringAppPlansLoading}
            columns={expiringApplicaitonColumn}
            dataSource={expiringAppPlans}
            pagination={false}
          />
        </Card>
      </Col>
    </Row>
  );
});
