import * as React from 'react';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { unsubscribeRequest } from '../../api/subscription';
const { Title, Text } = Typography;

type Props = {
  crmOrganization: CrmOrganization;
  crmSubscription: CrmSubscription;
  successCallback: (payload?: any) => void;
};

export const SubscriptionUnsubscribeDialog: React.FC<Props> = React.memo(
  ({ crmOrganization, crmSubscription, successCallback }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    function showModal() {
      setVisible(true);
    }

    function handleCancel() {
      setLoading(false);
      setVisible(false);
    }

    function handleSubmit() {
      setLoading(true);
      unsubscribeRequest({ subscriptionId: crmSubscription.id })
        .then((_) => {
          setLoading(false);
          setVisible(false);
          successCallback && successCallback();
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    return (
      <React.Fragment>
        <Button
          type="default"
          icon={<DeleteOutlined />}
          onClick={showModal}
          style={{ margin: '0.25em', marginLeft: 0 }}
        >
          Unsubscribe
        </Button>
        <Modal
          open={visible}
          title={`Unsubscribe #${crmSubscription.id}`}
          onOk={handleSubmit}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
              Unsubscribe
            </Button>,
          ]}
        >
          <Title level={4}>CrmOrganization UID</Title>
          <Text copyable={true}>{crmOrganization.uid}</Text>
          <Title level={4}>Subscription ID</Title>
          <Text copyable={true}>{crmSubscription.id}</Text>
        </Modal>
      </React.Fragment>
    );
  },
);
