import * as React from 'react';
import { useState } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ResponsiveTable } from './ui/ResponsiveTable';

const { Title } = Typography;

type Props = {
  metricPermissionInfo: MetricPermissionInfo;
};

const userColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => <Link to={`/users/${id}/`}>{id}</Link>,
    width: 90,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 260,
  },
];

const roleColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => <Link to={`/roles/${id}/`}>{id}</Link>,
    width: 90,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 260,
  },
];

export const MetricPermissionInfoComponent: React.FC<Props> = React.memo(({ metricPermissionInfo }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setLoading(false);
    setVisible(true);
  }

  function handleCancel() {
    setVisible(false);
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} shape="circle" icon={<TeamOutlined />} />
      <Modal
        open={visible}
        title={`Permission: ${
          metricPermissionInfo && metricPermissionInfo.permission && metricPermissionInfo.permission.name
        }`}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Title level={4}>Roles who can access</Title>
        <ResponsiveTable
          scroll={{ x: 600 }}
          loading={loading}
          columns={roleColumns}
          dataSource={metricPermissionInfo.authRoles}
        />

        <Title level={4}>Users who can access</Title>
        <ResponsiveTable
          scroll={{ x: 600 }}
          loading={loading}
          columns={userColumns}
          dataSource={metricPermissionInfo.authUsers}
        />
      </Modal>
    </React.Fragment>
  );
});
