import * as React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Typography,
  DatePicker,
  Checkbox,
  Button,
  Switch,
  Popconfirm,
  Card
} from 'antd';
import { MetricPageLayout } from '../../components/ui/MetricPageLayout';
import { dateTimePattern } from '../../utils/date';
import moment from 'moment-timezone';
import { useState } from 'react';
import {
  getCrmApplicationsRegionsRequest,
  getCrmApplicationDailyRecordSyncRequest,
} from '../../api/application';
import { runDataPipelineRequest, getCeleryTaskQueueStatusRequest } from '../../api/celeryTask';
import { useEffect } from 'react';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const recordPlainOptions = [
  'MAU/DAU',
  'PeakConnection',
  'DailyMessageCount',
  'MessagedUserCount',
  'ChannelData',
];
const defaultCheckedList = ['MAU/DAU', 'PeakConnection'];
type Props = {};

export const RecordSync: React.FC<Props> = React.memo(() => {
  const [defaultStartDate, defaultEndDate] = [
    moment().subtract(0, 'day'),
    moment(),
  ];
  const [startDate, setStartDate] = useState(
    defaultStartDate.format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(defaultEndDate.format('YYYY-MM-DD'));
  const [isAsync, setIsAsync] = useState<boolean>(true);
  const [taskCount, setTaskCount] = useState<number>(0);

  const [regionSet, setRegionSet] = useState<Array<string>>([]);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [targetDate, setTargetDate] = useState(
    defaultStartDate.format('YYYY-MM-DD'),
  );

  const rangePresets = {
    'Last 1 Days': [moment().subtract(0, 'day'), moment()],
    'Last 2 Days': [moment().subtract(1, 'day'), moment()],
    'Last 3 Days': [moment().subtract(2, 'day'), moment()],
  };

  function runDataPipeline(target_date_str) {
    runDataPipelineRequest(target_date_str)
      .then((response) => {})
      .catch((error) => {});
  }

  function getCeleryTaskQueueStatus() {
    getCeleryTaskQueueStatusRequest()
      .then((response) => {
        setTaskCount(response.data.results)
      })
      .catch((error) => {});
  }

  function getCrmApplicationsRegions() {
    getCrmApplicationsRegionsRequest()
      .then((response) => {
        setRegionSet(response.data.regions);
      })
      .catch((error) => {});
  }

  function getCrmApplicationDailyRecordSync(
    start_date,
    end_date,
    records,
    regions,
    is_async = 'False',
  ) {
    setSyncLoading(true);
    getCrmApplicationDailyRecordSyncRequest({
      start_date,
      end_date,
      records,
      regions,
      is_async,
    })
      .then((response) => {
        setSyncLoading(false);
      })
      .catch((error) => {
        setSyncLoading(false);
      });
  }

  useEffect(() => {
    getCrmApplicationsRegions();
  }, []);

  const [recordState, setRecordState] = useState<any>({
    checkedList: defaultCheckedList,
    indeterminate: true,
    checkAll: false,
  });

  const onRecordChange = (checkedList) => {
    setRecordState({
      checkedList: checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < recordPlainOptions.length,
      checkAll: checkedList.length === recordPlainOptions.length,
    });
  };

  const onRecordCheckAllChange = (e) => {
    setRecordState({
      checkedList: e.target.checked ? recordPlainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  const [regionState, setRegionState] = useState<any>({
    checkedList: regionSet,
    indeterminate: true,
    checkAll: false,
  });

  const onRegionChange = (checkedList) => {
    setRegionState({
      checkedList: checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < regionSet.length,
      checkAll: checkedList.length === regionSet.length,
    });
  };

  const onRegionCheckAllChange = (e) => {
    setRegionState({
      checkedList: e.target.checked ? regionSet : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  function onSyncSwitchChange(checked) {
    setIsAsync(checked);
  }

  function getApplicationDailyTotalGroupChannelMessageCount(
    start_date = defaultStartDate.format('YYYY-MM-DD'),
    end_date = defaultEndDate.format('YYYY-MM-DD'),
  ) {
    setStartDate(start_date);
    setEndDate(end_date);
  }

  function handleDailyTotalGroupChannelMessageCountChange(_, dateStrings) {
    getApplicationDailyTotalGroupChannelMessageCount(
      dateStrings[0],
      dateStrings[1],
    );
  }

  function sync() {
    getCrmApplicationDailyRecordSync(
      startDate,
      endDate,
      recordState.checkedList,
      regionState.checkedList,
      isAsync ? 'True' : 'False',
    );
  }

  function dataPipelineSync() {
    runDataPipeline(targetDate);
  }

  function onChange(_, dateString) {
    setTargetDate(dateString);
  }

  useEffect(function () {
    getCeleryTaskQueueStatus()
  }, []);

  const text = 'Are you sure to run data pipeline';

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Title level={2}>Run Data pipeline</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={2}>Current RabbitMQ Task Status</Title>
        </Col>
        <div className="site-card-border-less-wrapper">
          <Card title="RabbitMQ Task" bordered={false} style={{ width: 300 }}>
            <p>Celery: {taskCount}</p>
          </Card>
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={2}>Run Feature Usage Pipeline</Title>
        </Col>
        <p>
          You can choose target_date 1. You can run only 1 day pipeline for
          stability. 2. It takes 5~10 minutes. and you can see the result after
          task is finished.
        </p>
      </Row>
      <DatePicker defaultValue={defaultStartDate} onChange={onChange} />
      <br />
      <br />
      <br />
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={dataPipelineSync}
        okText="Yes"
        cancelText="No"
      >
        <Button>Run</Button>
      </Popconfirm>
      <br />
      <br />
      <br />
      <Row>
        <Col span={24}>
          <Title level={2}>CRM Daily Record Sync</Title>
        </Col>
        <p>
          You can choose date range under the following conditions to keep
          stability. 1. async: within 3 days 2. sync: only 1 day.
        </p>
        <MetricPageLayout.ChartFilter
          selects={[
            {
              label: 'Date Range',
              node: (
                <RangePicker
                  defaultValue={[defaultStartDate, defaultEndDate]}
                  ranges={rangePresets as any}
                  allowClear={false}
                  format={dateTimePattern.date}
                  onChange={handleDailyTotalGroupChannelMessageCountChange}
                />
              ),
            },
          ]}
        />
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Title level={4}>Choose Record</Title>
          <div>
            <div style={{ borderBottom: '1px solid #E9E9E9' }}>
              <Checkbox
                indeterminate={recordState}
                onChange={onRecordCheckAllChange}
                checked={recordState.checkAll}
              >
                Check all
              </Checkbox>
            </div>
            <br />
            <CheckboxGroup
              options={recordPlainOptions}
              value={recordState.checkedList}
              onChange={onRecordChange}
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Title level={4}>Choose Region</Title>
          <div>
            <div style={{ borderBottom: '1px solid #E9E9E9' }}>
              <Checkbox
                indeterminate={regionState}
                onChange={onRegionCheckAllChange}
                checked={regionState.checkAll}
              >
                Check all
              </Checkbox>
            </div>
            <br />
            <CheckboxGroup
              options={regionSet}
              value={regionState.checkedList}
              onChange={onRegionChange}
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Title level={4}>Choose Sync/Async</Title>
          <Switch
            defaultChecked
            checkedChildren="async"
            unCheckedChildren="sync"
            onChange={onSyncSwitchChange}
          />
        </Col>
      </Row>
      <br />
      <Button
        type="primary"
        icon={<SyncOutlined />}
        loading={syncLoading}
        onClick={sync}
      >
        Sync
      </Button>
    </div>
  );
});
