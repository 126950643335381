import React, { useEffect, useState, useCallback } from 'react';
import { Tag } from 'antd';
import numbro from 'numbro';

import { TableDetail } from '../ui/TableDetail';
import { fetchOrganizationInvoiceItems } from '../../api/billing';

const useInvoiceItemsAPI = ({ invoiceUid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    limit: 30,
    offset: 0,
  });
  const [response, setResponse] = useState<any>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    try {
      const { limit, offset } = query;
      const response = await fetchOrganizationInvoiceItems({
        invoice_uid: invoiceUid,
        limit,
        offset,
      });
      setResponse(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [invoiceUid, query]);

  useEffect(() => {
    request();
  }, [request]);

  return {
    isLoading,
    count: response ? response.count : 0,
    items: response ? response.results : [],
    query,
    setQuery,
  };
};

export const InvoiceItems = ({ invoiceUid }) => {
  const { isLoading, items, count, setQuery } = useInvoiceItemsAPI({ invoiceUid });
  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Item type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (itemType) => {
        return <Tag>{itemType}</Tag>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: (
        <>
          Cost <small style={{ opacity: 0.5 }}>(unitAmountDecimal)</small>
        </>
      ),
      dataIndex: 'unitAmountDecimal',
      key: 'unitAmountDecimal',
      render: (unitAmountDecimal) =>
        `$${numbro(unitAmountDecimal / 100).format({
          thousandSeparated: true,
        })}`,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Metadata',
      dataIndex: 'metadata',
      key: 'metadata',
    },
    {
      title: 'Stripe invoice item',
      dataIndex: 'stripeInvoiceItemId',
      key: 'stripeInvoiceItemId',
      render: (stripeInvoiceItemId) => {
        return (
          <a href={`https://dashboard.stripe.com/invoice_items/${stripeInvoiceItemId}`} target="_blank">
            {stripeInvoiceItemId}
          </a>
        );
      },
    },
  ];
  return (
    <TableDetail
      title="Invoice items"
      count={count}
      loading={isLoading}
      columns={columns}
      dataSource={items}
      onPageChange={setQuery}
      pageSizeOptions={['20', '40', '60']}
    />
  );
};
