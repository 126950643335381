import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Select, Tag, Typography, Input, Layout } from 'antd';
import queryString from 'query-string';

import { UserCreateDialog } from '../../components/UserCreateDialog';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { TableFilter } from '../../components/ui/TableFilter';
import { PageTitle } from '../../components/ui/PageTitle';
import { hasPermission, Permissions } from '../../utils/permission';
import { getUsersRequest } from '../../api/user';

const { Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const locationNames = [
  { key: 'ALL', value: '' },
  { key: 'KR', value: 'KR' },
  { key: 'US', value: 'US' },
  { key: 'UK', value: 'UK' },
  { key: 'SG', value: 'SG' },
  { key: 'IN', value: 'IN' },
  { key: 'DE', value: 'DE' },
];

const departmentNames = [
  { key: 'ALL', value: '' },
  { key: 'Sales', value: 'SALES' },
  { key: 'Engineering', value: 'ENGINEERING' },
  { key: 'Design', value: 'DESIGN' },
  { key: 'Foundations', value: 'FOUNDATIONS' },
  { key: 'Marketing', value: 'MARKETING' },
];

const stateNames = [
  { key: 'ALL', value: '' },
  { key: 'Active', value: 'ACTIVE' },
  { key: 'Pending', value: 'PENDING' },
  { key: 'Inactive', value: 'INACTIVE' },
];

export const Users = withRouter(({ location, history }) => {
  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 30,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    q: qs.query ? (qs.query as string) : '',
    location: qs.location ? (qs.location as string) : '',
    department: qs.department ? (qs.department as string) : '',
    state: qs.state ? (qs.state as string) : '',
  });
  const [users, setUsers] = useState<Array<InsightMember>>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(qs.searchQuery ? (qs.searchQuery as string) : '');

  useEffect(
    function () {
      history.replace({
        pathname: '/users/',
        search: queryString.stringify(query),
      });
      getUsers();
    },
    [query],
  );

  const getUsers = () => {
    setLoading(true);
    getUsersRequest({ ...query })
      .then((response) => {
        setUsers(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onLocationSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, location: value });
  };

  const onDepartmentSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, department: value });
  };

  const onStateSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, state: value });
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  function handleOnRow(member: InsightMember) {
    const isActive = member.state !== 'INACTIVE';
    return {
      style: {
        textDecoration: !isActive ? 'line-through' : '',
        opacity: !isActive ? 0.5 : 1,
      },
    };
  }

  const onSearch = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, q: value });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <Link to={`/users/${id}/`}>{id}</Link>,
    },
    {
      title: 'EID',
      dataIndex: 'eid',
      key: 'eid',
      render: (eid: InsightMember['eid']) => (eid ? eid : '-'),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username: InsightMember['username']) => <Text style={{ fontWeight: 500 }}>{username}</Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: InsightMember['email']) => <Text copyable={true}>{email}</Text>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (location: InsightMember['location']) => (location ? location : '-'),
    },
    {
      title: 'Team Type',
      dataIndex: 'teamType',
      key: 'teamType',
      render: (teamType: InsightMember['teamType']) => (teamType ? teamType : '-'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      render: (department: InsightMember['department']) => (department ? department : '-'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state: InsightMember['state']) => (state ? state : '-'),
    },
    {
      title: 'Company Joined Date',
      dataIndex: 'companyJoinedDate',
      key: 'companyJoinedDate',
    },
    {
      title: 'Last Login At (UTC)',
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (lastLoginAt: InsightMember['lastLoginAt']) => (lastLoginAt ? lastLoginAt.split('.')[0] : '-'),
    },
  ];

  return (
    <Layout>
      <PageTitle
        title="Members"
        extra={[hasPermission(Permissions.MANAGE_INSIGHT_USER) ? <UserCreateDialog successCallback={getUsers} /> : '']}
      >
        <ul>
          <li>List of Sendbird team members.</li>
          <li>
            After the admin confirms their identity, their state will change to <Tag color="green">ACTIVE</Tag>and they
            will be able to login.
          </li>
          <li>
            If a team member quits or leaves the company, their state should be changed from{' '}
            <Tag color="green">ACTIVE</Tag>to <Tag>INACTIVE</Tag>.
          </li>
        </ul>
        <TableFilter
          selects={[
            {
              label: 'Location',
              key: 'location',
              node: (
                <Select value={query.location} onSelect={onLocationSelect} style={{ width: '100%' }}>
                  {locationNames.map((location) => (
                    <Option key={location.key} value={location.value}>
                      {location.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              label: 'Department',
              key: 'department',
              node: (
                <Select value={query.department} onSelect={onDepartmentSelect} style={{ width: '100%' }}>
                  {departmentNames.map((department) => (
                    <Option key={department.key} value={department.value}>
                      {department.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              label: 'State',
              key: 'state',
              node: (
                <Select value={query.state} onSelect={onStateSelect} style={{ width: '100%' }}>
                  {stateNames.map((state) => (
                    <Option key={state.key} value={state.value}>
                      {state.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              label: 'Search',
              key: 'search',
              node: (
                <Search
                  value={searchQuery}
                  placeholder="Search email or username"
                  enterButton={true}
                  onSearch={onSearch}
                  onChange={onSearchChange}
                />
              ),
            },
          ]}
        />
      </PageTitle>
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={users}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
