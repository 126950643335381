import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {Input, Layout} from 'antd';
import moment from 'moment';
import { PageTitle } from '../../components/ui/PageTitle';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { InvoiceStatusTag } from '../../components/ui/Tags';
import { fetchOrganizationInvoices } from '../../api/billing';
import { dateTimePattern } from '../../utils/date';

const { Search } = Input;

const useInvoicesAPI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    limit: 30,
    offset: 0,
    searchQuery: '',
  });
  const [response, setResponse] = useState<any>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchOrganizationInvoices({
        ...query
      });
      setResponse(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [query]);

  useEffect(() => {
    request();
  }, [request]);

  return {
    isLoading,
    count: response ? response.count : 0,
    items: response ? response.results : [],
    query,
    setQuery,
  };
};

export const OrganizationInvoiceList = () => {
  const { isLoading, count, items, query, setQuery } = useInvoicesAPI();
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id, invoice) => <Link to={`/organization_invoices/${invoice.uid}/`}>{id}</Link>,
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
      render: (_, invoice) => {
        if (invoice.subscription) {
          return (
            <Link to={`/organization_subscription/${invoice.subscription.uid}/`}>
              {`${invoice.subscription.displayName}`}
            </Link>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, invoice) => {
        return <Link to={`/crm_organizations/${invoice.organization.uid}/`}>{invoice.organization.name}</Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <InvoiceStatusTag status={status} />,
    },
    {
      title: 'Invoice type',
      dataIndex: 'invoiceType',
      key: 'invoiceType',
    },
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => `$${Math.floor(totalAmount) / 100}`,
    },
    {
      title: (
        <span>
          Period <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, invoice) =>
        `${moment(invoice.startDate).format(dateTimePattern.date)} - ${moment(invoice.endDate).format(
          dateTimePattern.date,
        )}`,
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => `${moment(createdAt).format(dateTimePattern.default)}`,
    },
  ];

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  const onSearch = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, searchQuery: value });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const searchInputBoxCSS: React.CSSProperties = {
    padding: '16px 0',
    width: '490px',
    display: 'flex',
    justifyContent: 'flex-end'
  }

  return (
    <Layout>
      <PageTitle title="Invoices"
        extra={[
          <Search
            key="invoices_search"
            value={searchQuery}
            placeholder="Search invoices #ID (format 'id:number') or Organization Name"
            style={searchInputBoxCSS}
            enterButton={true}
            onSearch={onSearch}
            onChange={onSearchChange}
          />,
        ]}
      />
      <Layout.Content>
        <ResponsiveTable
          loading={isLoading}
          columns={columns}
          dataSource={items}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
