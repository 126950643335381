import React, { useState } from 'react';

import ApexCharts from 'react-apexcharts';

const CompactNumberFormat = new Intl.NumberFormat('en', { notation: 'compact' } as any);

export const LineChart = ({
  title,
  data,
  axisTitle,
}: {
  title: string;
  axisTitle: string;
  data: { x: string; y: number }[];
}) => {
  const [state] = useState({
    series: [{ name: title, data }],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: { type: 'x', enabled: true, autoScaleYaxis: true },
        toolbar: { autoSelected: 'zoom' },
        animations: { dynamicAnimation: { enabled: false } },
      },
      dataLabels: { enabled: false },
      markers: { size: 0 },
      title: { text: title, align: 'left' },
      fill: {
        type: 'gradient',
        gradient: { shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100] },
      },
      yaxis: {
        labels: { formatter: (value: number) => CompactNumberFormat.format(value) },
        title: { text: axisTitle },
      },
      xaxis: { type: 'datetime' },
      tooltip: { shared: false, y: { formatter: (value: number) => value.toLocaleString('en') } },
    },
  });
  return <ApexCharts options={state.options} series={state.series} type="area" width={450} />;
};
