import React, { useRef, useState } from 'react';
import { Button, Modal, Input } from 'antd';

import { DynamicForm } from '../ui/DynamicForm';
import { updateOrganizationGateMaxAppCountRequest } from '../../api/organization';
import { FormInstance } from 'antd/lib/form';

type Props = {
  crmOrganization: CrmOrganization;
  successCallback: any;
};

interface GetField {
  ({ maxAppCount }: { maxAppCount: number }): Array<any>;
}

const getFields: GetField = ({ maxAppCount }) => {
  return [
    {
      name: 'maxApplicationCount',
      label: ' ',
      formItemProps: {
        initialValue: maxAppCount,
      },
      component: (
        <Input
          size="large"
          style={{
            textAlign: 'right',
          }}
          autoFocus={true}
        />
      ),
    },
  ];
};

export const SetGateMaxAppCountDialog: React.FC<Props> = ({ crmOrganization, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    formRef.current?.resetFields();
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { maxApplicationCount } = values;

          updateOrganizationGateMaxAppCountRequest({
            crmOrganizationId: crmOrganization.id,
            maxApplicationCount: maxApplicationCount,
          })
            .then((response) => {
              handleCancel();
              successCallback &&
                successCallback({
                  callback: console.log('updateOrganizationGateMaxAppCountRequest', response.data),
                });
            })
            .catch((error) => {
              console.error('Error in : updateOrganizationGateMaxAppCountRequest', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }
  const maxAppCount = crmOrganization.maxApplicationCount;
  const fields = getFields({ maxAppCount });
  return (
    <React.Fragment>
      <Button
        type="primary"
        size="small"
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Change
      </Button>
      <Modal
        open={visible}
        title="Update max application count"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        {crmOrganization && <DynamicForm fields={fields} setForm={setForm} />}
      </Modal>
    </React.Fragment>
  );
};
