export enum UserStateEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE'
}

export enum UserDepartmentEnum {
  SALES = 'SALES',
  ENGINEERING = 'ENGINEERING',
  DESIGN = 'DESIGN',
  FOUNDATIONS = 'FOUNDATIONS',
  MARKETING = 'MARKETING'
}

export enum UserTeamTypeEnum {
  RESEARCH_AND_DEVELOPMENT = 'R&D',
  SALES_AND_MARKETING = 'S&M',
  GENERAL_AND_ADMINISTRATIVE = 'G&A'
}

export enum UserLocationEnum {
  US = 'US',
  KR = 'KR',
  UK = 'UK',
  SG = 'SG',
  IN = 'IN',
  DE = 'DE'
}

export const userDepartmentOptions = [
  { value: UserDepartmentEnum.SALES, label: 'Sales' },
  { value: UserDepartmentEnum.ENGINEERING, label: 'Engineering' },
  { value: UserDepartmentEnum.DESIGN, label: 'Design' },
  { value: UserDepartmentEnum.FOUNDATIONS, label: 'Foundations' },
  { value: UserDepartmentEnum.MARKETING, label: 'Marketing' },
];

export const userTeamTypeOptions = [
  { value: UserTeamTypeEnum.SALES_AND_MARKETING, label: 'S&M' },
  { value: UserTeamTypeEnum.RESEARCH_AND_DEVELOPMENT, label: 'R&D' },
  { value: UserTeamTypeEnum.GENERAL_AND_ADMINISTRATIVE, label: 'G&A' },
];

export const userStateOptions = [
  { value: UserStateEnum.ACTIVE, label: 'Active' },
  { value: UserStateEnum.PENDING, label: 'Pending' },
  { value: UserStateEnum.INACTIVE, label: 'Inactive' }
];

export const userLocationOptions = [
  { value: UserLocationEnum.US, label: 'US' },
  { value: UserLocationEnum.KR, label: 'KR' },
  { value: UserLocationEnum.UK, label: 'UK' },
  { value: UserLocationEnum.SG, label: 'SG' },
  { value: UserLocationEnum.IN, label: 'IN' },
  { value: UserLocationEnum.DE, label: 'DE' },
];

export function getDepartmentIcon({ department }) {
  switch (department) {
    case UserDepartmentEnum.SALES:
      return 'team';

    case UserDepartmentEnum.MARKETING:
      return 'rocket';

    case UserDepartmentEnum.ENGINEERING:
      return 'code';

    case UserDepartmentEnum.FOUNDATIONS:
      return 'compass';

    case UserDepartmentEnum.DESIGN:
      return 'highlight';

    default:
      return '';
  }
}

export function getUserStateColor({ state }) {
  switch (state) {
    case UserStateEnum.ACTIVE:
      return 'green';

    case UserStateEnum.PENDING:
      return 'orange';

    case UserStateEnum.INACTIVE:
      return '';

    default:
      return '';
  }
}
