import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';
import JSONPretify from 'react-json-pretty';
import { message } from 'antd';

import { getUserRequest, getUserPermissionsRequest, getUserEventLogsRequest } from '../../api/user';
import { getUserRolesRequest } from '../../api/roles';

import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { StatusTag } from '../../components/ui/Tags';
import { GeneralDetail } from '../../components/ui/GeneralDetail';
import { MemberEditDialog } from '../../components/MemberEditDialog';

import { UserPermissionDialog } from '../../components/UserPermissionDialog';
import { IsJsonString } from '../../utils/data';
import { hasPermission, Permissions } from '../../utils/permission';
import { Layout } from 'antd';
import { UserPermissionDeleteButton } from '../../components/UserPermissionDeleteButton';
import { UserLocationChangeDialog } from './UserLocationChangeDialog';

const UserContainer = styled.div<{ state: string }>`
  ${(props) => (props.state === 'INACTIVE' ? '* {\n    text-decoration: line-through;\n  }' : '')}
`;

const eventLogsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/event_logs/${id}/`}> {id} </Link>,
  },
  {
    title: 'Type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (eventType) => <span>{eventType}</span>,
  },
  {
    title: 'Previous Attributes',
    dataIndex: 'objectPreviousAttributes',
    key: 'objectPreviousAttributes',
    render: (objectPreviousAttributes) =>
      IsJsonString(objectPreviousAttributes) ? (
        <JSONPretify style={{ textAlign: 'left', overflowX: 'auto' }} json={JSON.parse(objectPreviousAttributes)} />
      ) : (
        ''
      ),
  },
  {
    title: 'Updated Attributes',
    dataIndex: 'objectData',
    key: 'objectData',
    render: (objectData, eventLog) =>
      IsJsonString(eventLog.objectPreviousAttributes) && (
        <JSONPretify
          style={{ textAlign: 'left', overflowX: 'auto' }}
          json={Object.keys(JSON.parse(eventLog.objectPreviousAttributes)).reduce((obj, k, v) => {
            obj[k] = JSON.parse(objectData)[k];
            return obj;
          }, {})}
        />
      ),
  },
  {
    title: 'Object',
    dataIndex: 'object',
    key: 'id',
    render: (obj, eventLog) =>
      obj ? <Link to={`/crm_${eventLog.eventType.split('.')[0].toLowerCase()}s/${obj.id}/`}> {obj.id} </Link> : '',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <span>{createdAt}</span>,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'userName',
    render: (requester) => (requester ? <span>{requester.username}</span> : ''),
  },
];

const userRolesColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/user_roles/${id}`}>{id}</Link>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role) => <Link to={`/roles/${role.id}`}>{role.name}</Link>,
  },
  {
    title: 'Granted At',
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: 'Expired At',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserRole['status']) => <StatusTag status={status} />,
  },
];

interface MatchParams {
  userId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const User = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { userId } = match.params;
    const [user, setUser] = useState<InsightMember>({} as InsightMember);

    const [userPermissions, setUserPermissions] = useState<Array<UserPermission>>([]);

    const [loadingUserPermissions, setLoadingUserPermissions] = useState<boolean>(false);
    const [permissionsCount, setUserPermissionsCount] = useState<number>(0);

    const [userEventLogs, setUserEventLogs] = useState([]);
    const [loadingUserEventLogs, setLoadingUserEventLogs] = useState(false);
    const [userEventLogsCount, setUserEventLogsCount] = useState(0);

    const [userRoles, setUserRoles] = useState<Array<UserRole>>([]);
    const [loadingUserRoles, setLoadingUserRoles] = useState<boolean>(false);
    const [userRolesCount, setUserRolesCount] = useState<number>(0);

    const userPermissionsColumns = [
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
        render: (id) => <Link to={`/user_permissions/${id}`}>{id}</Link>,
      },
      {
        title: 'Permission',
        dataIndex: 'permission',
        key: 'permission',
        render: (permission) => <Link to={`/permissions/${permission.id}`}>{permission.name}</Link>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Granted At',
        dataIndex: 'grantedAt',
        key: 'grantedAt',
      },
      {
        title: 'Expired At',
        dataIndex: 'expiredAt',
        key: 'expiredAt',
      },
      {
        title: 'Delete',
        dataIndex: 'permission',
        key: 'delete',
        render: (permission) => (
          <UserPermissionDeleteButton
            userId={userId}
            permissionName={permission.name}
            successCallback={() => {
              message.success('Successfully deleted');
              getUserPermissions({ offset: 0, limit: 10 });
            }}
          />
        ),
      }
    ];

    function getUser() {
      getUserRequest({ userId })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getUserPermissions({ offset = 0, limit = 10 }) {
      setLoadingUserPermissions(true);
      getUserPermissionsRequest({ limit, offset, userId })
        .then((response) => {
          setUserPermissions(response.data.results);
          setLoadingUserPermissions(false);
          setUserPermissionsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingUserPermissions(false);
        });
    }

    function getUserRoles({ offset = 0, limit = 10 }) {
      setLoadingUserRoles(true);
      getUserRolesRequest({ offset, limit, user_id: userId, role_id: '' })
        .then((response) => {
          setUserRoles(response.data.results);
          setLoadingUserRoles(false);
          setUserRolesCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingUserRoles(false);
        });
    }

    function getUserEventLogs({ offset = 0, limit = 10 }) {
      setLoadingUserEventLogs(true);
      getUserEventLogsRequest({ limit, offset, userId })
        .then((response) => {
          setUserEventLogs(response.data.results);
          setLoadingUserEventLogs(false);
          setUserEventLogsCount(response.data.count);
        })
        .catch((error) => {
          setLoadingUserEventLogs(false);
        });
    }

    useEffect(() => {
      getUser();
      getUserPermissions({});
      getUserEventLogs({});
      getUserRoles({});
    }, []);


    const leftData = [
      {
        key: 'ID',
        value: user.id,
      },
      {
        key: 'EID',
        value: user.eid,
      },
      {
        key: 'Name',
        value: user.firstName + ' ' + user.lastName,
      },
      {
        key: 'Username',
        value: user.username,
      },
      {
        key: 'Email',
        value: user.email,
      },
      {
        key: 'Team Type',
        value: user.teamType,
      },
      {
        key: 'Department',
        value: user.department,
      },
    ];

    const rightData = [
      {
        key: 'Location',
        value: (<>{user.location}
          {hasPermission(Permissions.MANAGE_INSIGHT_USER) || Number(localStorage.getItem('userId')) === user.id
            ? (<UserLocationChangeDialog user={user} successCallback={getUser}/>) : ''}</>)
      },
      {
        key: (
          <span>
            Company Joined Date <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: user.companyJoinedDate,
      },
      {
        key: (
          <span>
            Company Left Date <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: user.companyLeftDate,
      },
      {
        key: (
          <span>
            Date Joined <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: user.dateJoined,
      },
      {
        key: 'State',
        value: user.state,
      },
      {
        key: 'DB Username',
        value: user.dbUsername,
      },
      {
        key: (
          <span>
            Last Login At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        value: user.lastLoginAt ? user.lastLoginAt.split('.')[0] : '-',
      },
    ];

    return (
      <UserContainer state={user.state}>
        <Layout>
          <PageTitle
            title="Member Detail"
            subTitle={
              <>
                #{(user.id && user.id.toString()) || ''}
                {hasPermission(Permissions.MANAGE_INSIGHT_USER) ? (
                  <MemberEditDialog user={user} successCallback={getUser} />
                ) : (
                  ''
                )}
              </>
            }
            showBack={true}
          />
          <Layout.Content>
            <GeneralDetail leftData={leftData} rightData={rightData} />
            <TableDetail
              title={
                <>
                  Permissions of this member{' '}
                  {hasPermission(Permissions.MANAGE_INSIGHT_USER) ? (
                    <UserPermissionDialog user={user} successCallback={getUserPermissions} />
                  ) : ('')}
                </>
              }
              count={permissionsCount}
              loading={loadingUserPermissions}
              columns={userPermissionsColumns}
              dataSource={userPermissions}
              onPageChange={getUserPermissions}
            />
            <TableDetail
              title="Roles of member"
              count={userRolesCount}
              loading={loadingUserRoles}
              columns={userRolesColumns}
              dataSource={userRoles}
              onPageChange={getUserRoles}
            />
            <TableDetail
              title="Event Logs"
              count={userEventLogsCount}
              loading={loadingUserEventLogs}
              columns={eventLogsColumns}
              dataSource={userEventLogs}
              onPageChange={getUserEventLogs}
            />
          </Layout.Content>
        </Layout>
      </UserContainer>
    );
  }),
);
