import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LineChartComponent } from './LineChartComponent';
import moment from 'moment-timezone';

import { TableDetail } from './ui/TableDetail';

import { getCcuCountsRequest } from '../api/application';
import { TimeunitEnum } from '../utils/constants/application';

const columns = [
  {
    title: (
      <span>
        Date <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'date',
    key: 'date',
    render: (date: number) => moment.unix(date).utc().format("YYYY-MM-DDTHH:mm:ss")
  },
  {
    title: 'Peak Connections',
    dataIndex: 'maxCcu',
    key: 'maxCcu',
    render: (maxCcu: number) => maxCcu.toLocaleString(),
  },
];

interface Record {
  date: string;
  maxCcu: number;
}

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const PeakConnections = withRouter<Props, any>(({ match, location }) => {
  const [records, setRecords] = useState<Array<Record>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maxCcu, setMaxCcu] = useState(0);
  const { appId } = match.params;

  useEffect(() => {
    const startDateString = location.search
      .split('startDate=')[1]
      .split('&')[0];
    const endDateString = location.search.split('endDate=')[1].split('&')[0];
    getCcuCounts(startDateString, endDateString, TimeunitEnum.MINUTE);
  }, []);

  async function getCcuCounts(
    startDate: string,
    endDate: string,
    timeunit: TimeunitEnum,
  ) {
    setIsLoading(true);
    try {
      const response = await getCcuCountsRequest({
        appId,
        startDate,
        endDate,
        timeunit,
      });
      setRecords(response.data.results);
      setIsLoading(false);
      setMaxCcu(0);
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <TableDetail
      title="Peak Connection"
      count={maxCcu}
      loading={isLoading}
      columns={columns}
      dataSource={records}
      defaultPageSize={20}
      pageSizeOptions={['10', '20', '50', '100']}
    >
      <LineChartComponent
        unit="minute"
        labels={records.map((o) => moment(o.date).tz('UTC').format())}
        data1={records.map((o) => o.maxCcu)}
      />
    </TableDetail>
  );
});
