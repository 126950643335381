import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getBillingEventLogRequest } from '../../api/event_logs';

import { BillingEventLogInfo } from '../../components/EventLogInfo';
import { PageTitle } from '../../components/ui/PageTitle';

interface MatchParams {
  billingEventLogId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const EventLog = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { billingEventLogId } = match.params;

    const [billingEventLog, setBillingEventLog] = useState({} as EventLog);

    function getBillingEventLog() {
      getBillingEventLogRequest({ billingEventLogId })
        .then((response) => {
          setBillingEventLog(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getBillingEventLog();
    }, []);

    return (
      <React.Fragment>
        <PageTitle
          title="Billing Event Log Detail"
          subTitle={`#${
            (billingEventLog.id && billingEventLog.id.toString()) || ''
          }`}
          showBack={true}
        />
        <BillingEventLogInfo billingEvent={billingEventLog} />
      </React.Fragment>
    );
  }),
);
