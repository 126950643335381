import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import JSONPretify from 'react-json-pretty';

import {
  getCrmPaymentRequest,
  getCrmPaymentRequestsRequest,
  getCrmPaymentRequestRefundsRequest,
  getEventLogsRequest,
} from '../../api/payment';
import { CrmPaymentInfo } from '../../components/CrmPaymentInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { PaymentStatusTag } from '../../components/ui/Tags';
import { IsJsonString } from '../../utils/data';
import { Layout } from 'antd';

const paymentRequestsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Charge',
    dataIndex: 'stripeChargeId',
    render: (stripeChargeId: CrmPaymentRequest['stripeChargeId']) => (
      <a
        href={`https://dashboard.stripe.com/charges/${stripeChargeId}`}
        target="_blank"
      >
        {stripeChargeId}
      </a>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmPaymentRequest['status']) => (
      <PaymentStatusTag status={status} />
    ),
  },
  {
    title: (
      <span>
        CreatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdDt',
    key: 'createdDt',
    render: (createdDt: CrmPaymentRequest['createdDt']) =>
      !createdDt ? '-' : createdDt,
  },
  {
    title: (
      <span>
        RequestDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'paymentRequestDt',
    key: 'paymentRequestDt',
    render: (paymentRequestDt: CrmPaymentRequest['paymentRequestDt']) =>
      !paymentRequestDt ? '-' : paymentRequestDt,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'requester',
    render: (requester: CrmPaymentRequest['requester']) =>
      !requester ? '' : <span>{requester.username}</span>,
  },
];

const paymentRequestRefundsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Refund',
    dataIndex: 'stripeRefundId',
    render: (stripeRefundId: CrmPaymentRequestRefund['stripeRefundId']) => (
      <a
        href={`https://dashboard.stripe.com/refunds/${stripeRefundId}`}
        target="_blank"
      >
        {stripeRefundId}
      </a>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'refundAmount',
    key: 'refundAmount',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmPaymentRequest['status']) => (
      <PaymentStatusTag status={status} />
    ),
  },
  {
    title: (
      <span>
        CreatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdDt',
    key: 'createdDt',
    render: (createdDt: CrmPaymentRequest['createdDt']) =>
      !createdDt ? '-' : createdDt,
  },
  {
    title: (
      <span>
        RequestDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'paymentRequestDt',
    key: 'paymentRequestDt',
    render: (paymentRequestDt: CrmPaymentRequest['paymentRequestDt']) =>
      !paymentRequestDt ? '-' : paymentRequestDt,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'requester',
    render: (requester: CrmPaymentRequest['requester']) =>
      !requester ? '' : <span>{requester.username}</span>,
  },
];

const eventLogsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/event_logs/${id}/`}> {id} </Link>,
  },
  {
    title: 'Type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (eventType) => <span>{eventType}</span>,
  },
  {
    title: 'Previous Attributes',
    dataIndex: 'objectPreviousAttributes',
    key: 'objectPreviousAttributes',
    render: (objectPreviousAttributes) =>
      IsJsonString(objectPreviousAttributes) ? (
        <JSONPretify
          style={{ textAlign: 'left', overflowX: 'auto' }}
          json={JSON.parse(objectPreviousAttributes)}
        />
      ) : (
        ''
      ),
  },
  {
    title: 'Updated Attributes',
    dataIndex: 'objectData',
    key: 'objectData',
    render: (objectData, eventLog) =>
      IsJsonString(eventLog.objectPreviousAttributes) && (
        <JSONPretify
          style={{ textAlign: 'left', overflowX: 'auto' }}
          json={Object.keys(
            JSON.parse(eventLog.objectPreviousAttributes),
          ).reduce((obj, k, v) => {
            obj[k] = JSON.parse(objectData)[k];
            return obj;
          }, {})}
        />
      ),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <span>{createdAt}</span>,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'userName',
    render: (requester) => (requester ? <span>{requester.username}</span> : ''),
  },
];

interface MatchParams {
  crmPaymentId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const CrmPayment = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { crmPaymentId } = match.params;
    const [crmPayment, setCrmPayment] = useState({} as CrmPayment);

    const [crmPaymentRequests, setCrmPaymentRequests] = useState(
      [] as Array<CrmPaymentRequest>,
    );
    const [loadingPaymentRequests, setPaymentRequests] = useState(false);
    const [paymentRequestsCount, setPaymentRequestsCount] = useState(0);

    const [crmPaymentRequestRefunds, setcrmPaymentRequestRefunds] = useState(
      [] as Array<CrmPaymentRequestRefund>,
    );
    const [
      loadingPaymentRequestRefunds,
      setLoadingPaymentRequestRefunds,
    ] = useState(false);
    const [
      paymentRequestRefundsCount,
      setPaymentRequestRefundsCount,
    ] = useState(0);

    const [eventLogs, setEventLogs] = useState([]);
    const [loadingEventLogs, setLoadingEventLogs] = useState(false);
    const [eventLogsCount, setEventLogsCount] = useState(0);

    function getCrmPayment({ callback }) {
      getCrmPaymentRequest({ crmPaymentId })
        .then((response) => {
          setCrmPayment(response.data);
          callback && callback();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getCrmPaymentRequests({ offset = 0, limit = 0 }) {
      setPaymentRequests(true);
      getCrmPaymentRequestsRequest({ limit, offset, crmPaymentId })
        .then((response) => {
          setCrmPaymentRequests(response.data.results);
          setPaymentRequests(false);
          setPaymentRequestsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setPaymentRequests(false);
        });
    }

    function getCrmPaymentRequestRefunds({ offset = 0, limit = 0 }) {
      setLoadingPaymentRequestRefunds(true);
      getCrmPaymentRequestRefundsRequest({ limit, offset, crmPaymentId })
        .then((response) => {
          setcrmPaymentRequestRefunds(response.data.results);
          setLoadingPaymentRequestRefunds(false);
          setPaymentRequestRefundsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingPaymentRequestRefunds(false);
        });
    }

    function getEventLogs({ offset = 0, limit = 0 }) {
      setLoadingEventLogs(true);
      getEventLogsRequest({ limit, offset, crmPaymentId })
        .then((response) => {
          setEventLogs(response.data.results);
          setLoadingEventLogs(false);
          setEventLogsCount(response.data.count);
        })
        .catch((error) => {
          setLoadingEventLogs(false);
        });
    }

    useEffect(() => {
      getCrmPayment({
        callback: function () {
          getCrmPaymentRequests({
            offset: 0,
            limit: 10,
          });
          getCrmPaymentRequestRefunds({
            offset: 0,
            limit: 10,
          });
          getEventLogs({
            offset: 0,
            limit: 10,
          });
        },
      });
    }, []);

    return (
      <Layout>
        <PageTitle
          title="Payment Detail"
          subTitle={`#${(crmPayment.id && crmPayment.id.toString()) || ''}`}
          showBack={true}
        />
        <Layout.Content>
          <CrmPaymentInfo crmPayment={crmPayment} />
          <TableDetail
            title="Payment Requests"
            count={paymentRequestsCount}
            loading={loadingPaymentRequests}
            columns={paymentRequestsColumns}
            dataSource={crmPaymentRequests}
            onPageChange={getCrmPaymentRequests}
          />
          <TableDetail
            title="Payment Request Refunds"
            count={paymentRequestRefundsCount}
            loading={loadingPaymentRequestRefunds}
            columns={paymentRequestRefundsColumns}
            dataSource={crmPaymentRequestRefunds}
            onPageChange={getCrmPaymentRequestRefunds}
          />
          <TableDetail
            title="Event Logs"
            count={eventLogsCount}
            loading={loadingEventLogs}
            columns={eventLogsColumns}
            dataSource={eventLogs}
            onPageChange={getEventLogs}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
