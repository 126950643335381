import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import { hasPermission, Permissions } from '../../utils/permission';

const { Title } = Typography;
type Props = {}

export const RawMetricsData: React.FC<Props> = React.memo(() => {

  return (<div>
    <Row gutter={16}>
      <Col span={24}>
        <Title level={3}>Raw Metrics Data</Title>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Title level={4}>Insight</Title>
        <ul>
          <li>
            <Link to="/aws_usage_with_cost/">AWS Usage with Cost</Link>
          </li>
          <li>
            <Link to="/status_page_incidents/">StatusPage Incidents</Link>
          </li>
        </ul>
      </Col>
    </Row>
  </div>)
});
