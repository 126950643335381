import * as React from 'react';
import {useEffect} from 'react';
import {Layout} from 'antd';
import {Redirect, Route, Switch} from 'react-router-dom';

import {useCheckMobile} from '../utils/screen';
import {PrivateRoute} from './PrivateRoute';

import {ApplicationPlanRequests} from '../containers/application/ApplicationPlanRequests';
import {ApplicationPlanRequest} from '../containers/application/ApplicationPlanRequest';
import {ApplicationPlan} from '../containers/application/ApplicationPlan';
import {CrmApplications} from '../containers/application/CrmApplications';
import {ApplicationContainer} from '../containers/application/ApplicationContainer';
import {Channel} from '../containers/application/Channel';

import {CrmPayments} from '../containers/billing/CrmPayments';
import {CrmPayment} from '../containers/billing/CrmPayment';
import {CrmSubscriptions} from '../containers/billing/CrmSubscriptions';
import {CrmSubscription} from '../containers/billing/CrmSubscription';
import {PayingApplications} from '../containers/billing/PayingApplications';
import {ChurnApplications} from '../containers/billing/ChurnApplications';
import {CrmPaymentRequests} from '../containers/billing/CrmPaymentRequests';
import {BillingMetricsSelfService} from '../containers/billing/BillingMetricsSelfService';

import {DashboardContainer} from '../containers/dashboard/DashboardContainer';
import {DashBoardRegionInsight} from '../containers/dashboard/DashBoardRegionInsight';
import {DashboardBilling} from '../containers/dashboard/DashboardBilling';
import {DashboardSubscribed} from '../containers/dashboard/DashboardSubscribed';
import {DashboardDailyInsight} from '../containers/dashboard/DashboardDailyInsight';

import {BillingDataExports} from '../containers/dataExports/BillingDataExports';
import {UsageDataExports} from '../containers/dataExports/UsageDataExports';
import {CorporateDashboardDataExports} from '../containers/dataExports/CorporateDashboardDataExports';
import {UserRequestActivityLogDataExports} from '../containers/dataExports/UserRequestActivityLogDataExports';
import {OTPDataExports} from '../containers/dataExports/OTPDataExports';

import {DeskMetricsSummary} from '../containers/deskMetrics/DeskMetricsSummary';
import {DeskMetricsTicketList} from '../containers/deskMetrics/DeskMetricsTicketList';
import {DeskMetricsProjectList} from '../containers/deskMetrics/DeskMetricsProjectList';
import {DeskMetricsTicketDetail} from '../containers/deskMetrics/DeskMetricsTicketDetail';
import {DeskMetricsProjectDetail} from '../containers/deskMetrics/DeskMetricsProjectDetail';

import {VoucherList} from '../containers/billingV2/VoucherList';
import {VoucherDetail} from '../containers/billingV2/VoucherDetail';
import {InvoiceDetail} from '../containers/billingV2/InvoiceDetail';
import {OrganizationInvoiceList} from '../containers/billingV2/InvoiceList';
import {OrganizationSubscriptions} from '../containers/billingV2/OrganizationSubscriptions';
import {OrganizationSubscription} from '../containers/billingV2/OrganizationSubscription';
import {OrganizationSubscriptionRequestList} from '../containers/billingV2/OrganizationSubscriptionRequestList';
import {OrganizationBillingSubscriptionCreation} from '../containers/billingV2/OrganizationBillingSubscriptionCreation';

// Deprecated 2024-04-15 @mike.oh
// import { FivetranSalesforceAccounts } from '../containers/fivetran/FivetranSalesforceAccounts';
// import { FivetranSalesforceLeads } from '../containers/fivetran/FivetranSalesforceLeads';
// import { FivetranSalesforceLead } from '../containers/fivetran/FivetranSalesforceLead';
// import { MonthlySalesforceLeadCounts } from '../containers/fivetran/FivetranSalesforceLeadsMonthlyCounts';
// import { FivetranSalesforceOpportunities } from '../containers/fivetran/FivetranSalesforceOpportunities';
// import { FivetranSalesforceContracts } from '../containers/fivetran/FivetranSalesforceContracts';
// import { MonthlySalesforceContractCounts } from '../containers/fivetran/FivetranSalesforceContractsMonthlyCounts';
// import { FivetranSalesforceUsers } from '../containers/fivetran/FivetranSalesforceUsers';
// import { FivetranSalesforceUser } from '../containers/fivetran/FivetranSalesforceUser';
// import { FivetranSalesforceContacts } from '../containers/fivetran/FivetranSalesforceContacts';
// import { FivetranSalesforceContact } from '../containers/fivetran/FivetranSalesforceContact';
// import { MonthlySalesforceContactCounts } from '../containers/fivetran/FivetranSalesforceContactsMonthlyCounts';
// import { FivetranSalesforceAccount } from '../containers/fivetran/FivetranSalesforceAccount';
// import { MonthlySalesforceAccountCounts } from '../containers/fivetran/FivetranSalesforceAccountsMonthlyCounts';
// import { FivetranSalesforceOpportunity } from '../containers/fivetran/FivetranSalesforceOpportunity';
// import { MonthlySalesforceOpportunityCounts } from '../containers/fivetran/FivetranSalesforceOpportunityMonthlyCounts';
// import { FivetranSalesforceContract } from '../containers/fivetran/FivetranSalesforceContract';
// import { FivetranHubspotContacts } from '../containers/fivetran/FivetranHubspotContacts';
// import { FivetranHubspotContact } from '../containers/fivetran/FivetranHubspotContact';
// import { MonthlyHubspotContactCounts } from '../containers/fivetran/FivetranHubspotContactsMonthlyCounts';
import {PaymentStatusByApplication} from '../containers/laboratory/PaymentStatusByApplicaton';
import {UserRequestActivityLogs} from '../containers/laboratory/UserRequestActivityLogs';
import {UserRequestActivityLog} from '../containers/laboratory/UserRequestActivityLog';
import {RecordSync} from '../containers/laboratory/RecordSync';
import {DashboardOtps} from '../containers/laboratory/DashboardOtps';
import {DatabaseOtps} from '../containers/laboratory/DatabaseOtps';
import {DeficitApps} from '../containers/laboratory/DeficitApplications';
import {Laboratory} from '../containers/laboratory/Laboratory';
import {DashboardOtpDetail} from '../containers/laboratory/DashboardOtpDetail';
import {DatabaseOtpDetail} from '../containers/laboratory/DatabaseOtpDetail';
import {RawMetricsData} from '../containers/laboratory/RawMetricData';
import {OrganizationInvitations} from '../containers/laboratory/OrganizationInvitations';

import {UserPermissions} from '../containers/hidden/UserPermissions';
import {UserPermission} from '../containers/hidden/UserPermission';
import {RolePermissions} from '../containers/hidden/RolePermissions';
import {RolePermission} from '../containers/hidden/RolePermission';
import {UserRoles} from '../containers/hidden/UserRoles';
import {UserRole} from '../containers/hidden/UserRole';

import {StatusPageComponentGroups} from '../containers/StatusPage/StatusPageComponentGroups';
import {StatusPageComponentGroup} from '../containers/StatusPage/StatusPageComponentGroup';
import {StatusPageIncidents} from '../containers/StatusPage/StatusPageIncidents';
import {StatusPageIncident} from '../containers/StatusPage/StatusPageIncident';

import {CrmOrganizations} from '../containers/organization/CrmOrganizations';
import {OrganizationContainer} from '../containers/organization/OrganizationContainer';
import {OrganizationContextProvider} from '../containers/organization/OrganizationProvider';

// small container groups
import {UserMigrationRequests} from '../containers/userMigrationRequests/UserMigrationRequests';
import {UserMigrationRequest} from '../containers/userMigrationRequests/UserMigrationRequest';
import {CrmUsers} from '../containers/crmUsers/CrmUsers';
import {CrmUser} from '../containers/crmUsers/CrmUser';
import {CeleryTasks} from '../containers/celeryTask/CeleryTasks';
import {CeleryTask} from '../containers/celeryTask/CeleryTask';
import {EventLogs} from '../containers/eventLogs/EventLogs';
import {EventLog} from '../containers/eventLogs/EventLog';
import {ApplicationMetrics} from '../containers/metrics/ApplicationMetrics';
import {ApplicationMetricsIndex} from '../containers/metrics/ApplicationMetricsIndex';
import {Permissions} from '../containers/permissions/Permissions';
import {Permission} from '../containers/permissions/Permission';
import {Roles} from '../containers/roles/Roles';
import {Role} from '../containers/roles/Role';
import {Users} from '../containers/users/Users';
import {User} from '../containers/users/User';

// single page
import {AwsUsageWithCosts} from '../containers/awsUsageWithCost/AwsUsageWithCosts';
import {AccountProfile} from '../containers/insightMember/AccountProfile';
import {RankingCrmApplicationRecords} from '../containers/ranking/CrmApplicationRanking';

// ETC
import Oauth2Callback from '../components/Oauth2Callback';
import Oauth2CallbackFB from '../components/Oauth2CallbackFB';
import AuthByOauth2FB from '../components/AuthByOauth2FB';
import Oauth2CallbackOkta from '../components/Oauth2CallbackOkta';
import AuthByOauth2 from '../components/AuthByOauth2';
import AuthByOauth2Okta from '../components/AuthByOauth2Okta';
import {SignIn} from '../components/SignIn';
import Sync from '../components/Sync';
import {ApplicationCounts} from '../components/ApplicationCounts';
import {UserCounts} from '../components/UserCounts';
import GateAuthUsers from '../components/GateAuthUsers';
import GateAuthUser from '../components/GateAuthUser';
import {Trends} from '../containers/trends';
import {ApplicationInternalUpdate} from '../components/ApplicationInternalUpdate'

export const Routes = () => {
  const isMobile = useCheckMobile();

  useEffect(() => {
    // setTimeout with zero delay prevents browser from scrolling too early.
    setTimeout(() => window.scrollTo(0, 0), 0);

    // Layout node can be a scrolling element.
    const routesLayout = document.getElementById('Routes__Layout');
    if (routesLayout) {
      routesLayout.scrollTo(0, 0);
    }
  }, []);

  return (
    <Layout
      id="Routes__Layout"
      style={{
        padding: isMobile ? '82px 16px 0' : '',
        height: '100vh',
      }}
    >
      <Switch>
        <Redirect exact={true} from="/" to="/applications" />
        <PrivateRoute path="/dashboard" component={DashboardContainer} />
        <PrivateRoute exact={true} path="/applications" component={CrmApplications} />
        <PrivateRoute exact={true} path="/paying_applications" component={PayingApplications} />
        <PrivateRoute exact={true} path="/churn_applications" component={ChurnApplications} />
        <PrivateRoute
          exact={true}
          path="/applications/:appId/application_plans/:applicationPlanId"
          component={ApplicationPlan} />
        <PrivateRoute exact={true} path="/applications/:appId/channels/:channelId" component={Channel} />
        <PrivateRoute path="/applications/:appId" component={ApplicationContainer} />
        <PrivateRoute path="/rankings" component={RankingCrmApplicationRecords} />
        <PrivateRoute path="/trends" component={Trends} />
        <PrivateRoute exact={true} path="/users" component={Users} />
        <PrivateRoute exact={true} path="/users/:userId" component={User} />
        <PrivateRoute exact={true} path="/account/profile/" component={AccountProfile} />
        <PrivateRoute exact={true} path="/pipeline/feature_usage/monitoring" component={CeleryTasks} />
        <PrivateRoute exact={true} path="/celery/tasks/:celeryTaskId" component={CeleryTask} />
        <PrivateRoute exact={true} path="/crm_users" component={CrmUsers} />
        <PrivateRoute exact={true} path="/crm_users/:crmUserId" component={CrmUser} />
        <PrivateRoute exact={true} path="/gate_authusers" component={GateAuthUsers} />
        <PrivateRoute path="/gate_authusers/:userId" component={GateAuthUser} />
        <PrivateRoute exact={true} path="/application_counts" component={ApplicationCounts} />
        <PrivateRoute exact={true} path="/user_counts" component={UserCounts} />
        <PrivateRoute exact={true} path="/crm_payments" component={CrmPayments} />
        <PrivateRoute path="/crm_payments/:crmPaymentId" component={CrmPayment} />
        <PrivateRoute exact={true} path="/crm_payment_requests" component={CrmPaymentRequests} />
        <PrivateRoute exact={true} path="/crm_organizations" component={CrmOrganizations} />
        <PrivateRoute
          exact={true}
          path="/crm_organizations/:crmOrganizationId/create_billing_subscription"
          component={OrganizationBillingSubscriptionCreation}
        />
        <PrivateRoute
          path="/crm_organizations/:crmOrganizationId"
          component={OrganizationContainer}
          Provider={OrganizationContextProvider}
        />
        <PrivateRoute exact={true} path="/organization_invitations/" component={OrganizationInvitations} />
        <PrivateRoute exact={true} path="/crm_subscriptions" component={CrmSubscriptions} />
        <PrivateRoute path="/crm_subscriptions/:crmSubscriptionId" component={CrmSubscription} />
        <PrivateRoute
          path="/organization_subscription/:organizationSubscriptionUid"
          component={OrganizationSubscription}
        />
        <PrivateRoute exact={true} path="/organization_subscriptions" component={OrganizationSubscriptions} />
        <PrivateRoute
          exact={true}
          path="/organization_subscription_requests"
          component={OrganizationSubscriptionRequestList}
        />
        <PrivateRoute exact={true} path="/organization_invoices/:invoiceUid" component={InvoiceDetail} />
        <PrivateRoute exact={true} path="/organization_invoices" component={OrganizationInvoiceList} />
        <PrivateRoute exact={true} path="/billing_vouchers" component={VoucherList} />
        <PrivateRoute exact={true} path="/billing_vouchers/:voucher_uid" component={VoucherDetail} />
        <PrivateRoute exact={true} path="/otp/dashboard_otps" component={DashboardOtps} />
        <PrivateRoute exact={true} path="/otp/database_otps" component={DatabaseOtps} />
        <PrivateRoute exact={true} path="/otp/dashboard_otps/:dashboardOtpId" component={DashboardOtpDetail} />
        <PrivateRoute exact={true} path="/otp/database_otps/:databaseOtpId" component={DatabaseOtpDetail} />
        <PrivateRoute exact={true} path="/permissions" component={Permissions} />
        <PrivateRoute path="/permissions/:permissionId" component={Permission} />
        <PrivateRoute exact={true} path="/user_permissions" component={UserPermissions} />
        <PrivateRoute path="/user_permissions/:userPermissionId" component={UserPermission} />
        <PrivateRoute exact={true} path="/sync" component={Sync} />
        <PrivateRoute exact={true} path="/application_internal_update/" component={ApplicationInternalUpdate} />
        <PrivateRoute exact={true} path="/billing_data_exports/" component={BillingDataExports} />
        <PrivateRoute exact={true} path="/usage_data_exports/" component={UsageDataExports} />
        <PrivateRoute
          exact={true}
          path="/corporate_dashboard_data_exports/"
          component={CorporateDashboardDataExports}
        />
        <PrivateRoute
          exact={true}
          path="/user_request_activity_log_data_exports/"
          component={UserRequestActivityLogDataExports}
        />
        <PrivateRoute exact={true} path="/otp_data_exports/" component={OTPDataExports} />

        <Route path="/auth/signin" component={SignIn} />
        {/*<Route path="/auth/signup" component={SignUp} />*/}
        <Route path="/auth/oauth2/authenticate" component={AuthByOauth2} />
        <Route path="/auth/oauth2/callback" component={Oauth2Callback} />

        <Route path="/auth/oauth2_okta/authenticate" component={AuthByOauth2Okta} />
        <Route path="/auth/oauth2_okta/callback" component={Oauth2CallbackOkta} />

        {/*NOTE - Facebook test is unused in production. It will be deleted.*/}
        <Route path="/auth/oauth2_fb/authenticate" component={AuthByOauth2FB} />
        <Route path="/auth/oauth2_fb/callback" component={Oauth2CallbackFB} />
        <Route path="/laboratory/" component={Laboratory} exact={true} />
        <PrivateRoute exact={true} path="/aws_usage_with_cost/" component={AwsUsageWithCosts} />
        <PrivateRoute exact={true} path="/status_page_component_groups/" component={StatusPageComponentGroups} />
        <PrivateRoute exact={true} path="/status_page_component_groups/:groupId" component={StatusPageComponentGroup} />
        <PrivateRoute exact={true} path="/status_page_incidents/" component={StatusPageIncidents} />
        <PrivateRoute exact={true} path="/status_page_incidents/:incidentId" component={StatusPageIncident} />
        <Route path="/laboratory/region_insight/" component={DashBoardRegionInsight} />
        <Route path="/laboratory/deficit_apps/" component={DeficitApps} />
        <PrivateRoute
          exact={true}
          path="/user_request_activity_logs/:userRequestActivityLogId"
          component={UserRequestActivityLog}
        />
        <PrivateRoute exact={true} path="/user_request_activity_logs/" component={UserRequestActivityLogs} />
        {/*Deprecated 2024-04-15 @mike.oh*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/accounts/" component={FivetranSalesforceAccounts} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/accounts/monthly_account_counts/"*/}
        {/*  component={MonthlySalesforceAccountCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/accounts/:accountId"*/}
        {/*  component={FivetranSalesforceAccount}*/}
        {/*/>*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/leads/" component={FivetranSalesforceLeads} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/leads/monthly_lead_counts/"*/}
        {/*  component={MonthlySalesforceLeadCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/leads/:leadId" component={FivetranSalesforceLead} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/opportunities/"*/}
        {/*  component={FivetranSalesforceOpportunities}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/opportunities/monthly_opportunity_counts"*/}
        {/*  component={MonthlySalesforceOpportunityCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/opportunities/:opportunityId"*/}
        {/*  component={FivetranSalesforceOpportunity}*/}
        {/*/>*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/contracts/" component={FivetranSalesforceContracts} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/contracts/monthly_contract_counts"*/}
        {/*  component={MonthlySalesforceContractCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute path="/fivetran/salesforce/contracts/:contractId" component={FivetranSalesforceContract} />*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/users/" component={FivetranSalesforceUsers} />*/}
        {/*<PrivateRoute path="/fivetran/salesforce/users/:userId" component={FivetranSalesforceUser} />*/}
        {/*<PrivateRoute exact={true} path="/fivetran/salesforce/contacts/" component={FivetranSalesforceContacts} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/contacts/monthly_contact_counts/"*/}
        {/*  component={MonthlySalesforceContactCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/salesforce/contacts/:contactId"*/}
        {/*  component={FivetranSalesforceContact}*/}
        {/*/>*/}
        {/*<PrivateRoute exact={true} path="/fivetran/hubspot/contacts/" component={FivetranHubspotContacts} />*/}
        {/*<PrivateRoute*/}
        {/*  exact={true}*/}
        {/*  path="/fivetran/hubspot/contacts/monthly_contact_counts/"*/}
        {/*  component={MonthlyHubspotContactCounts}*/}
        {/*/>*/}
        {/*<PrivateRoute exact={true} path="/fivetran/hubspot/contacts/:contactId/" component={FivetranHubspotContact} />*/}
        <PrivateRoute exact={true} path="/event_logs/" component={EventLogs} />
        <PrivateRoute exact={true} path="/event_logs/:billingEventLogId" component={EventLog} />
        <PrivateRoute exact={true} path="/application_plan_requests" component={ApplicationPlanRequests} />
        <PrivateRoute
          exact={true}
          path="/application_plan_requests/:applicationPlanRequestId"
          component={ApplicationPlanRequest}
        />
        <PrivateRoute exact={true} path="/metrics/applications/:appId" component={ApplicationMetrics} />
        <PrivateRoute exact={true} path="/metrics/applications/" component={ApplicationMetricsIndex} />
        <PrivateRoute exact={true} path="/roles/" component={Roles} />
        <PrivateRoute exact={true} path="/roles/:roleId" component={Role} />
        <PrivateRoute exact={true} path="/user_roles/" component={UserRoles} />
        <PrivateRoute exact={true} path="/user_roles/:userRoleId" component={UserRole} />
        <PrivateRoute exact={true} path="/role_permissions/" component={RolePermissions} />
        <PrivateRoute exact={true} path="/role_permissions/:rolePermissionId" component={RolePermission} />
        <PrivateRoute exact={true} path="/daily_insight" component={DashboardDailyInsight} />
        <PrivateRoute exact={true} path="/billing" component={DashboardBilling} />
        <PrivateRoute exact={true} path="/subscribed_applications" component={DashboardSubscribed} />
        <PrivateRoute exact={true} path="/laboratory/record_sync/" component={RecordSync} />
        <Route path="/laboratory/raw_metrics_data/" component={RawMetricsData} />
        <Route exact={true} path="/user_migration_requests/:id" component={UserMigrationRequest} />
        <Route exact={true} path="/user_migration_requests/" component={UserMigrationRequests} />
        <Route
          exact={true}
          path="/laboratory/crm_payment_status_by_application/"
          component={PaymentStatusByApplication}
        />
        <PrivateRoute exact={true} path="/billing_metrics/self_service/" component={BillingMetricsSelfService} />

        {/* Desk metrics */}
        <PrivateRoute exact={true} path="/desk_metrics/summary" component={DeskMetricsSummary} />
        <PrivateRoute exact={true} path="/desk_metrics/tickets" component={DeskMetricsTicketList} />
        <PrivateRoute exact={true} path="/desk_metrics/tickets/:ticketId" component={DeskMetricsTicketDetail} />
        <PrivateRoute exact={true} path="/desk_metrics/projects" component={DeskMetricsProjectList} />
        <PrivateRoute exact={true} path="/desk_metrics/projects/:projectPid" component={DeskMetricsProjectDetail} />
      </Switch>
    </Layout>
  );
};
