import React, { useEffect, useState } from 'react';
import { Tag, Typography } from 'antd';

import { getOrganizationDailyFeatureUsageRequest } from '../../api/organization';
import { TableDetail } from '../ui/TableDetail';
import { formatNumber } from '../../utils/data';

const { Paragraph } = Typography;

const columns = () => [
  {
    title: 'Record date',
    dataIndex: 'recordDate',
    key: 'recordDate',
    width: 100,
    sorter: (a, b) => new Date(a.recordDate) - new Date(b.recordDate),
  },
  {
    title: 'mau',
    dataIndex: 'mau',
    key: 'mau',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.mau - b.mau,
  },
  {
    title: 'pc',
    dataIndex: 'pc',
    key: 'pc',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.pc - b.pc,
  },
  {
    title: 'file storage',
    dataIndex: 'fileStorage',
    key: 'fileStorage',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.fileStorage - b.fileStorage,
  },
  {
    title: 'upload traffic',
    dataIndex: 'uploadTraffic',
    key: 'uploadTraffic',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.uploadTraffic - b.uploadTraffic,
  },
  {
    title: 'msg count',
    dataIndex: 'messageCount',
    key: 'messageCount',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.messageCount - b.messageCount,
  },
  {
    title: 'announce-ment',
    dataIndex: 'announcement',
    key: 'announcement',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.announcement - b.announcement,
  },
  {
    title: 'announcement sent user count',
    dataIndex: 'announcementSentUserCount',
    key: 'announcementSentUserCount',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.announcementSentUserCount - b.announcementSentUserCount,
  },
  {
    title: 'message search index total',
    dataIndex: 'messageSearchIndexTotal',
    key: 'messageSearchIndexTotal',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.messageSearchIndexTotal - b.messageSearchIndexTotal,
  },
  {
    title: 'message search query total',
    dataIndex: 'messageSearchQueryTotal',
    key: 'messageSearchQueryTotal',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.messageSearchQueryTotal - b.messageSearchQueryTotal,
  },
  {
    title: 'auto-thumbnail',
    dataIndex: 'autoThumbnail',
    key: 'autoThumbnail',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.autoThumbnail - b.autoThumbnail,
  },
  {
    title: 'auto-translation',
    dataIndex: 'autoTranslation',
    key: 'autoTranslation',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.autoTranslation - b.autoTranslation,
  },
  {
    title: 'image moderation',
    dataIndex: 'imageModeration',
    key: 'imageModeration',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.imageModeration - b.imageModeration,
  },
  {
    title: 'bot interface',
    dataIndex: 'botInterface',
    key: 'botInterface',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.botInterface - b.botInterface,
  },
  {
    title: 'domain filter',
    dataIndex: 'domainFilter',
    key: 'domainFilter',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.domainFilter - b.domainFilter,
  },
  {
    title: 'profanity filter',
    dataIndex: 'profanityFilter',
    key: 'profanityFilter',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.profanityFilter - b.profanityFilter,
  },
  {
    title: 'message credit',
    dataIndex: 'aiEngineOpenai',
    key: 'aiEngineOpenai',
    render: (value) => formatNumber(value),
    width: 100,
    sorter: (a, b) => a.aiEngineOpenai - b.aiEngineOpenai,
  },
  {
    title: 'updated',
    dataIndex: 'updatedDt',
    key: 'updatedDt',
    width: 100,
  },
];

type Props = {
  crmOrganizationId: CrmOrganization['id'];
  crmOrganization: CrmOrganization;
};

export const OrganizationDailyFeatureUsages: React.FC<Props> = ({ crmOrganizationId, crmOrganization }) => {
  const [dataSource, setDatasource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  function getOrganizationDailyFeatureUsages({ offset = 0, limit = 35 }) {
    setLoading(true);
    getOrganizationDailyFeatureUsageRequest({
      crmOrganizationId,
      offset,
      limit,
    })
      .then((response) => {
        setDatasource(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handlePageChange({ offset, limit }) {
    getOrganizationDailyFeatureUsages({ offset, limit });
  }

  useEffect(function () {
    getOrganizationDailyFeatureUsages({ offset: 0, limit: 35 });
  }, []);

  return (
    <TableDetail
      title="Organization Daily Feature Usage"
      count={count}
      loading={loading}
      columns={columns({ crmOrganization })}
      dataSource={dataSource}
      onPageChange={handlePageChange}
      pageSizeOptions={['35', '65', '125', '185']}
    >
      <Paragraph>
        <ul>
          <li>
            For the engineering team: This table shows the <Tag style={{ margin: 0 }}>CrmOrgDailyFeatureUsage</Tag>{' '}
            data.
          </li>
          <li>
            Numbers might vary from real-time data from <Tag style={{ margin: 0 }}>BigQuery</Tag> database.
          </li>
        </ul>
      </Paragraph>
    </TableDetail>
  );
};
