import React, { useState, useRef } from 'react';
import { Button, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

import { DynamicForm } from '../ui/DynamicForm';
import { createBillingCallVoucherRequestRequest } from '../../api/billing';
import { hasPermission, Permissions } from '../../utils/permission';

const fields: Field[] = [
  {
    name: 'duration_days',
    label: 'Duration Days',
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
    },
    span: 24,
    component: <Input size="large" placeholder="0" />,
  },
  {
    name: 'paid_amount',
    label: 'Fee($)',
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
    },
    span: 24,
    component: <Input size="large" placeholder="0" addonBefore="$" />,
  },
  {
    name: 'credit',
    label: 'Credit',
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
    },
    span: 24,
    component: <Input size="large" placeholder="0" addonBefore="$" />,
  },
  {
    name: 'reason',
    label: 'Request Reason',
    formItemProps: {},
    component: <TextArea />,
  },
];

type Props = {
  crmOrganizationId: CrmOrganization['id'];
  successCallback: any;
};

export const VoucherCreationRequestDialog: React.FC<Props> = ({ crmOrganizationId, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleCreateVoucherRequestRequest() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          createBillingCallVoucherRequestRequest({
            crmOrganizationId,
            values,
          })
            .then((response) => {
              console.log('response', response);
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      {hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION_CREATION) ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal}
          style={{
            margin: '0.25em',
            marginLeft: 0,
            verticalAlign: 'middle',
          }}
        >
          Create
        </Button>
      ) : (
        <></>
      )}
      <Modal
        open={visible}
        title={`Voucher Creation Request`}
        onOk={handleCreateVoucherRequestRequest}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleCreateVoucherRequestRequest}>
            {'Request'}
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
