import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { TableDetail } from '../ui/TableDetail';
import { getCrmOrganizationOrganizationInvitationsRequest } from '../../api/organization';

export const OrganizationInvitations = ({ crmOrganizationId }) => {
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [invitationsCount, setInvitationsCount] = useState(0);
  const [organizationInvitations, setOrganizationInvitations] = useState([] as Array<OrganizationInvitation>);
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 20, offset: 0 });

  const invitationsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
    },
    {
      title: (
        <span>
          Updated At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
    },
    {
      title: (
        <span>
          Expire At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'expireAt',
      key: 'expireAt',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
    },
  ];

  const getCrmOrganizationOrganizationInvitations = useCallback(() => {
    setLoadingInvitations(true);
    getCrmOrganizationOrganizationInvitationsRequest({
      crmOrganizationId,
      ...pagination,
    })
      .then((response) => {
        setOrganizationInvitations(response.data.results);
        setInvitationsCount(response.data.count);
        setLoadingInvitations(false);
      })
      .catch((error) => {
        setLoadingInvitations(false);
        console.log(error);
      });
  }, [crmOrganizationId, pagination]);

  useEffect(() => {
    getCrmOrganizationOrganizationInvitations();
  }, [getCrmOrganizationOrganizationInvitations]);
  return (
    <TableDetail
      title="Invitations"
      count={invitationsCount}
      loading={loadingInvitations}
      columns={invitationsColumns}
      dataSource={organizationInvitations}
      onPageChange={setPagination}
      pageSizeOptions={['20', '40', '60']}
    />
  );
};
