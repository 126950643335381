import React, { useContext, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  AppstoreOutlined,
  BarsOutlined,
  CaretLeftFilled,
  CaretRightFilled,
  ClusterOutlined,
  ContainerOutlined,
  DesktopOutlined,
  DollarOutlined,
  LineChartOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Button, Layout, List, Menu, MenuProps, Popover, Space } from 'antd';
import { AuthenticationContext } from '../containers/AuthenticationProvider';
import logo from '../images/Sendbird_Logo_White.svg';
import { MEDIA_SIZES, media } from '../utils/media';

const { Sider } = Layout;

const MenuContainer = styled.div<{ menuCollapsed: boolean }>`
  width: ${(props) => (props.menuCollapsed ? 80 : 220)}px;
  height: 100vh;
  overflow-y: auto;
  transition: 0.2s width;

  ${media.MOBILE_LARGE`
    height: auto;
  `}
`;

const MenuContent = styled.div`
  position: relative;
  max-width: 210px;
  width: 100%;
  overflow-x: hidden;

  ${media.MOBILE_LARGE`
    max-width: auti;
  `}
`;

const Header = styled.div`
  margin: 0 22px;
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: #001529;

  ${media.MOBILE_LARGE`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0 12px 0 22px;
    height: 60px;
  `}
`;

const Navigation = styled.div<{ show: boolean }>`
  padding-bottom: 50px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;

  ${media.MOBILE_LARGE`
    opacity: ${(props) => (props.show ? 1 : 0)};
    position: fixed;
    z-index: 200;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding-bottom: 0;
    background: rgba(0,0,0,0.5);
    transform: translateY(${(props) => (props.show ? 0 : '-100vh')});
  `}
`;

const ButtonToggleMenu = styled(Button)`
  position: absolute;
  right: 0;
  top: 24px;

  ${media.MOBILE_LARGE`
    display: none;
  `}
`;

const Logo = styled.div`
  ${media.MOBILE_LARGE`
    flex: 1;
  `}
`;

const MobileMenuButton = styled.button`
  display: none;
  outline: none;

  ${media.MOBILE_LARGE`
    display: block;
    padding: 9px 9px 7px;
    background: transparent;
    border: none;
    border-radius: 100px;
    transition: background 0.2s ease-in-out;

    &:hover{
      background: rgba(255, 255, 255, 0.2);
    }

    i{
      font-size: 24px;
    }
  `}
`;

const UserName = styled.span`
  flex: 1;
  color: white;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background: #000c17;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  transition: background 0.2s;

  &:hover {
    background: #001b35;
  }

  ${media.MOBILE_LARGE`
    padding: 12px;
    background: transparent;
    border: none;
    border-radius: 100px;
    transition: background 0.2s;

    i{
      font-size: 18px;
    }
  `}
`;

const User = styled.div<{ menuCollapsed: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: ${(props) => (props.menuCollapsed ? 80 : 210)}px;
  transition: width 0.2s;

  ${UserContainer} {
    padding-left: ${(props) => (props.menuCollapsed ? '30px' : null)};
    font-size: ${(props) => (props.menuCollapsed ? '16px' : null)};
  }

  ${UserName} {
    display: ${(props) => (props.menuCollapsed ? 'none' : 'inline-block')};

    ${media.MOBILE_LARGE`
      display: none;
    `};
  }

  ${media.MOBILE_LARGE`
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    width: 42px;
  `}
`;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const MENU_ITEMS: MenuItem[] = [
  getItem('Applications', 'Applications', <AppstoreOutlined />, [
    getItem(<Link to="/applications">List</Link>, 'Applications_List', <DesktopOutlined />),
    getItem(<Link to="/metrics/applications/">Metrics</Link>, 'Metrics', <ContainerOutlined />),
  ]),
  getItem(<Link to="/crm_organizations">Organizations</Link>, 'Organizations', <ClusterOutlined />),
  getItem(<Link to="/crm_users/?startDate=2000-01-01&endDate=2222-01-01">Users</Link>, 'Users', <TeamOutlined />),

  getItem('Billing - Version 2', 'Billing - Version 2', <DollarOutlined />, [
    getItem(<Link to="/organization_subscriptions">Subscriptions</Link>, 'Subscriptions'),
    getItem(<Link to="/organization_invoices">Invoices</Link>, 'Invoices'),
  ]),

  getItem('Billing - Version 1', 'Billing - Version 1', <DollarOutlined />, [
    getItem(<Link to="/crm_payments/">All Payments</Link>, 'All Payments'),
    getItem(<Link to="/crm_payment_requests/">Payment Requests</Link>, 'Payment Requests'),
    getItem(<Link to="/crm_subscriptions">App Subscriptions</Link>, 'App Subscriptions'),
    getItem(<Link to="/churn_applications/">Churn Apps</Link>, 'Churn Apps'),
    getItem(<Link to="/paying_applications/">Paying Apps</Link>, 'Paying Apps'),
  ]),

  getItem(<Link to="/rankings/?order=-mau">Ranking</Link>, 'Ranking', <TrophyOutlined />),
  getItem(<Link to="/trends">Trends</Link>, 'Trends', <LineChartOutlined />),
  getItem(<Link to="/laboratory">Miscellaneous</Link>, 'Miscellaneous', <ReconciliationOutlined />),
];

const USER_ITEMS: Array<UserItems> = [
  {
    title: 'Profile',
    to: '/account/profile/',
  },
  {
    title: 'Members',
    to: '/users/',
  },
  {
    title: 'Sign out',
  },
];

type UserItems = {
  title: string;
  to?: string;
};

type Props = {
  isMobile: boolean;
  signout: () => void;
} & RouteComponentProps;

export const AuthLink = withRouter<Props, any>(({ location, history, isMobile, signout }) => {
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];

  const { authenticated } = useContext(AuthenticationContext);

  const [userPopVisible, setUserPopVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState(['Applications']);
  const [currentKeys, setCurrentKeys] = useState(['Applications_List']);
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const hideMobileMenu = () => {
    if (window.innerWidth < MEDIA_SIZES.MOBILE_LARGE) {
      setMobileMenuVisible(false);
    }
  };

  function handlePopVisibleChange(visible: boolean) {
    setUserPopVisible(visible);
    hideMobileMenu();
  }

  function handleOpenChange(currentOpenKeys: string[]) {
    const latestOpenKey = currentOpenKeys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  function handleUserPopClick() {
    setUserPopVisible(false);
  }

  function handleSignout() {
    setUserPopVisible(false);
    signout();
  }

  function handleLogoClick() {
    setOpenKeys(['Applications']);
    setCurrentKeys(['Applications_List']);
  }

  function handleMenuClick(e: ClickParam) {
    setCurrentKeys([e.key]);
    hideMobileMenu();
  }

  function handleMobileMenuClick() {
    setMobileMenuVisible(!mobileMenuVisible);
  }

  function handleToggleMenuCollapse() {
    setMenuCollapsed(!menuCollapsed);
  }

  const renderUserItem = (item: UserItems) => {
    if (!item.to) {
      return (
        <List.Item onClick={handleSignout}>
          <List.Item.Meta title={item.title} style={{ cursor: 'pointer' }} />
        </List.Item>
      );
    } else {
      return (
        <List.Item onClick={handleUserPopClick}>
          <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
        </List.Item>
      );
    }
  };

  return authenticated ? (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      collapsible
      collapsed={menuCollapsed}
      onCollapse={(value) => setMenuCollapsed(value)}
      width={210}
    >
      <MenuContainer menuCollapsed={menuCollapsed}>
        <MenuContent>
          <Header>
            <Logo>
              <Link to="/" onClick={handleLogoClick}>
                <img src={logo} style={{ width: '140px' }} />
              </Link>
            </Logo>
            <ButtonToggleMenu
              icon={menuCollapsed ? <CaretRightFilled /> : <CaretLeftFilled />}
              type="primary"
              onClick={handleToggleMenuCollapse}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: 0,
              }}
            />
            <User menuCollapsed={menuCollapsed}>
              <Popover
                content={
                  <List
                    style={{ width: 110 }}
                    itemLayout="horizontal"
                    dataSource={USER_ITEMS}
                    renderItem={renderUserItem}
                  />
                }
                placement={isMobile ? 'bottomRight' : menuCollapsed ? 'topLeft' : 'topRight'}
                align={isMobile ? { offset: [-2, -8] } : menuCollapsed ? { offset: [18, 20] } : { offset: [-11, 20] }}
                trigger="click"
                open={userPopVisible}
                onOpenChange={handlePopVisibleChange}
                style={{ padding: 0 }}
              >
                <UserContainer>
                  <UserName>{localStorage.getItem('username')}</UserName>
                  {isMobile ? (
                    <UserOutlined style={{ color: 'white' }} />
                  ) : (
                    <SettingOutlined style={{ color: 'white' }} />
                  )}
                </UserContainer>
              </Popover>
            </User>
            <MobileMenuButton onClick={handleMobileMenuClick}>
              <BarsOutlined style={{ color: 'white' }} />
            </MobileMenuButton>
          </Header>
          <Navigation show={mobileMenuVisible}>
            <Menu
              defaultSelectedKeys={currentKeys}
              selectedKeys={currentKeys}
              defaultOpenKeys={openKeys}
              mode="inline"
              theme="dark"
              onClick={handleMenuClick}
              onOpenChange={handleOpenChange}
              items={MENU_ITEMS}
            />
          </Navigation>
        </MenuContent>
      </MenuContainer>
    </Sider>
  ) : (
    <></>
  );
});
