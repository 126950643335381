import React from 'react';
import { Redirect } from 'react-router-dom';

class AuthByOauth2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToReferrer: false,
    }

    this.domain = process.env.REACT_APP_ENV === 'production'
      ? 'https://insight.sendbird.com' : process.env.REACT_APP_ENV === 'staging'
        ? 'https://insight-staging.sendbird.com' : 'http://localhost:3000';

    this.oauth2AuthUrl = 'https://accounts.google.com/o/oauth2/auth?' +
      'redirect_uri=' + this.domain + '/auth/oauth2/callback' +
      '&client_id=45411407729-fbl74lc8l00amlfngnm99ka3hsa6jatg.apps.googleusercontent.com' +
      '&scope=email' +
      '&response_type=token'
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/users' } }

    if (localStorage.getItem('token')) {
      return (
        <Redirect to={from}/>
      )
    } else {
      window.location.href = this.oauth2AuthUrl
    }

    return null
  }
}

export default AuthByOauth2
