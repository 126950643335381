import axiosLib from 'axios';
import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getStripeCustomerRequest({
  crmOrganizationId,
  crmOrganizationUid,
}: {
  crmOrganizationId?: any;
  crmOrganizationUid?: any;
}) {
  // Since there is so many missing stripe_customer, user will see lot of `Unknown stripe_customer` alerts.
  // Because we made insightAxios for handling requests and It will automatically shows error message alerts.
  // To prevent that inconvenience and confusion, We will replace this single response with plain axios.
  return axiosLib
    .create()
    .get(
      `${API_DOMAIN}/api/crm_organizations/${
        crmOrganizationId ? crmOrganizationId : crmOrganizationUid
      }/stripe_customer/`,
      {
        headers: {
          Authorization: getAuthToken(),
        },
      },
    );
}

export function getPayingApplicationsRequest({ page, leadId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/paying/?page=${page}&leadId=${leadId}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getChurnApplicationsRequest({ offset, limit }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/churn/?offset=${offset}&limit=${limit}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getLeadMetricRequest() {
  return insightAxios.get(`${API_DOMAIN}/api/users/paying_applications/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmPaymentsRequest({ appId, offset, limit }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_applications/${appId}/crm_payments/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getOrganizationCrmPaymentsRequest({ organizationId, offset, limit }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${organizationId}/crm_payments/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function resendReceiptRequest({ crmPaymentId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/resend_receipt/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function refundPaymentRequest({ crmPaymentId, amount = null }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/refund/`,
    {
      amount,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function payNowRequest({ crmPaymentId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/paynow/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updatePaymentRequest({
  crmPaymentId,
  status,
  invoiceDt,
  chargedDt,
  isActive,
  type,
  subscriptionId,
  paymentMethod,
  productFee,
  serviceFee,
  productFeeDescription,
  serviceFeeDescription,
  serviceStartDt,
  serviceEndDt,
}) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/`,
    {
      status,
      invoiceDt,
      chargedDt,
      isActive,
      type,
      subscriptionId,
      paymentMethod,
      productFee,
      serviceFee,
      productFeeDescription,
      serviceFeeDescription,
      serviceStartDt,
      serviceEndDt,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function disablePaymentRequest({ crmPaymentId, status, invoiceDt, chargedDt }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/`,
    {
      status,
      invoiceDt,
      chargedDt,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function createPaymentRequest({
  appId,
  organizationId,
  productFee,
  serviceFee,
  paymentMethod,
  productFeeDescription,
  serviceFeeDescription,
  memo,
  paymentType,
  subscriptionId,
  serviceStartDt,
  serviceEndDt,
}) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_payments/`,
    {
      appId,
      organizationId,
      productFee,
      serviceFee,
      paymentMethod,
      productFeeDescription,
      serviceFeeDescription,
      memo,
      paymentType,
      subscriptionId,
      serviceStartDt,
      serviceEndDt,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmPaymentsDailyChargedRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_payments/daily_charged/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmPaymentsMonthlyChargedRequest({ startMonth, endMonth }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/monthly_charged/?start_month=${startMonth}&end_month=${endMonth}`,
    {
      params: {
        start_month: startMonth,
        end_month: endMonth,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getOrganizationSubscriptionsRequest({
  id, // if exists, ignores all other parameters
  uid,
  organizationUid,
  product,
  current,
  displayName,
  subscriptionName,
  subscriptionType,
  createdAtGte,
  createdAtLte,
  limit,
  offset,
  is_test_organization,
}: {
  id?;
  uid?;
  organizationUid?;
  product?;
  current?;
  displayName?;
  subscriptionName?;
  subscriptionType?;
  createdAtGte?;
  createdAtLte?;
  limit?;
  offset?;
  is_test_organization?;
}) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_subscriptions/organization_subscriptions/`, {
    params: {
      id,
      uid,
      organization_uid: organizationUid,
      product,
      current,
      display_name: displayName,
      subscription_name: subscriptionName,
      subscription_type: subscriptionType,
      created_at__gte: createdAtGte,
      created_at__lte: createdAtLte,
      limit,
      offset,
      is_test_organization,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getOrganizationSubscriptionInvoicesRequest({
  uid,
  organizationUid,
  subscriptionUid,
  status,
  invoice_type,
  payment_method,
  limit,
  offset,
}: {
  uid?;
  organizationUid?;
  subscriptionUid?;
  status?;
  invoice_type?;
  payment_method?;
  limit?: number;
  offset?: number;
}) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_subscriptions/organization_invoices/`, {
    params: {
      uid,
      organization_uid: organizationUid,
      subscription_uid: subscriptionUid,
      status,
      invoice_type,
      payment_method,
      limit,
      offset,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getOrganizationSubscriptionInvoiceRequest({
  uid,
}: {
  uid: string
}) {
  return insightAxios.get(`${API_DOMAIN}/api/invoices/${uid}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getBillingVouchersRequest({
  organizationUid,
  limit,
  offset,
}: {
  organizationUid?: string;
  limit?: number;
  offset?: number;
}) {
  return insightAxios.get(`${API_DOMAIN}/api/billing_vouchers/`, {
    params: {
      organization__uid: organizationUid,
      limit,
      offset,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getBillingOrganizationRequest({
  uid,
}: {
  uid: string
}) {
  return insightAxios.get(`${API_DOMAIN}/api/billing_organizations/${uid}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function updateBillingOrganizationRequest({
  uid,
  isSendInvoice
}: {
  uid: string,
  isSendInvoice: boolean,
}) {

  return insightAxios.patch(
    `${API_DOMAIN}/api/billing_organizations/${uid}/`,
    {
      is_send_invoice: isSendInvoice,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}


export function getBillingVoucherRequest({ voucher_uid }: { voucher_uid?: string }) {
  return insightAxios.get(`${API_DOMAIN}/api/billing_vouchers/${voucher_uid}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getBillingCallVoucherRequestRequest({ limit, offset, crmOrganizationId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/billing_voucher_requests/?limit=${limit}&offset=${offset}&crmOrganizationId=${crmOrganizationId}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function createBillingCallVoucherRequestRequest({ crmOrganizationId, values, requestedAction = 'CREATE' }) {
  const voucherRequestBody = {
    duration_days: parseInt(values.duration_days),
    paid_amount: parseFloat(values.paid_amount) * 100,
    credit: parseFloat(values.credit),
  };

  return insightAxios.post(
    `${API_DOMAIN}/api/billing_voucher_requests/`,
    {
      crmOrganizationId,
      requestBody: JSON.stringify(voucherRequestBody),
      requestedAction,
      reason: values.reason ? values.reason : '',
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateBillingCallVoucherApproveRequest(billingVoucherId: number, isPayNow: boolean) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/billing_voucher_requests/approve/`,
    {
      billingVoucherId,
      isPayNow
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateBillingCallVoucherDenyRequest(billingVoucherId: number) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/billing_voucher_requests/deny/`,
    {
      billingVoucherId,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getBillingVoucherSubscriptionRequest({ organization_uid }: { organization_uid?: string }) {
  return insightAxios.get(`${API_DOMAIN}/api/billing_vouchers/subscription/?organization_uid=${organization_uid}`, {
    params: {
      organization_uid,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCallVoucherRelatedInvoiceRequest({ voucher_uid }: { voucher_uid: string }) {
  return insightAxios.get(`${API_DOMAIN}/api/billing_vouchers/voucher_related_invoice/?voucher_uid=${voucher_uid}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function payNowCallVoucherRequest({ voucherUid, organizationUid }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/billing_vouchers/pay_now/`,
    {
      voucherUid,
      organizationUid,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function activateCallVoucherRequest({ voucherUid }) {
  return insightAxios.put(
    `${API_DOMAIN}/api/billing_vouchers/activate/`,
    {
      voucherUid,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateCallVoucherExpireDtRequest({ voucherUid, expireDt, expireReason }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/billing_vouchers/${voucherUid}/`,
    {
      expireDt,
      expireReason
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getOrganizationSubscriptionPaymentsRequest({ invoiceUid }: { invoiceUid? }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_subscriptions/organization_payments/`, {
    params: {
      invoice_uid: invoiceUid,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export const fetchOrganizationInvoices = (params: {
  limit?: number;
  offset?: number;
  status?;
  invoice_type?;
  payment_method?;
  searchQuery?: string;
}) => {
  return insightAxios.get(`${API_DOMAIN}/api/invoices/`, {
    params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
};

export const fetchOrganizationInvoiceItems = (params: {
  limit?: number;
  offset?: number;
  organization_uid?: string;
  subscription_uid?: string;
  invoice_uid?: string;
}) => {
  return insightAxios.get(`${API_DOMAIN}/api/invoice_items/`, {
    params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
};

export function getBillingSubscriptionRequestRequest({ limit, offset, crmOrganizationId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/billing_subscription_requests/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function createBillingSubscriptionRequestRequest({
  crmOrganizationId,
  values,
  requester,
  requestedAction = 'CREATE',
}) {
  let plan = {};
  let subscription = {};
  let ai_chatbot_plan = {};

  if (values.subscription_type === 'CHAT') {
    plan = {
      base_fee: {
        internal_key: 'base_fee',
        display_name: 'Base fee',
        strategy: 'FLAT',
        cost_per_unit: parseFloat(values.plan_value) * 100,
        purchased_units: 1,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      mau: {
        internal_key: 'mau',
        display_name: 'Monthly active users',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.mau_cost_per_unit) * 100,
        purchased_units: parseInt(values.mau_purchased_units),
        hard_limit: parseInt(values.mau_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      pc: {
        internal_key: 'pc',
        display_name: 'Peak concurrent connections',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.pc_cost_per_unit) * 100,
        purchased_units: parseInt(values.pc_purchased_units),
        hard_limit: parseInt(values.pc_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      file_storage: {
        internal_key: 'file_storage',
        display_name: 'File storage',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.file_storage_cost_per_unit) * 100,
        purchased_units: parseInt(values.file_storage_purchased_units),
        hard_limit: parseInt(values.file_storage_hard_limit),
        bracket: 10,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      upload_traffic: {
        internal_key: 'upload_traffic',
        display_name: 'File upload traffic',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.upload_traffic_cost_per_unit) * 100,
        purchased_units: parseInt(values.upload_traffic_purchased_units),
        hard_limit: parseInt(values.upload_traffic_hard_limit),
        bracket: 10,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      file_upload_size_limit: {
        internal_key: 'file_upload_size_limit',
        display_name: 'File upload size limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.file_upload_size_limit_purchased_units),
        hard_limit: parseInt(values.file_upload_size_limit_hard_limit),
        bracket: null,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      bot_interface: {
        internal_key: 'bot_interface',
        display_name: 'Chatbot interface',
        strategy: 'PROPORTIONAL',
        cost_per_unit: 0,
        purchased_units: parseInt(values.bot_interface_purchased_units),
        hard_limit: parseInt(values.bot_interface_hard_limit),
        bracket: 1,
        proratable: true,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      message_retrieval: {
        internal_key: 'message_retrieval',
        display_name: 'Message retrieval',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      auto_thumbnail: {
        internal_key: 'auto_thumbnail',
        display_name: 'Auto-thumbnail generator',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.auto_thumbnail_cost_per_unit) * 100,
        purchased_units: parseInt(values.auto_thumbnail_purchased_units),
        hard_limit: parseInt(values.auto_thumbnail_hard_limit),
        bracket: 10000,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.auto_thumbnail_enabled,
        default_on: true,
      },
      image_moderation: {
        internal_key: 'image_moderation',
        display_name: 'Image moderation',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.image_moderation_cost_per_unit) * 100,
        purchased_units: parseInt(values.image_moderation_purchased_units),
        hard_limit: parseInt(values.image_moderation_hard_limit),
        bracket: 1000,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.image_moderation_enabled,
        default_on: true,
      },
      domain_filter: {
        internal_key: 'domain_filter',
        display_name: 'Domain filter',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 1,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.domain_filter_enabled,
        default_on: true,
      },
      profanity_filter: {
        internal_key: 'profanity_filter',
        display_name: 'Profanity filter',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 1,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.profanity_filter_enabled,
        default_on: true,
      },
      translation_tools: {
        internal_key: 'auto_trans',
        display_name: 'Translation tools',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.translation_tools_cost_per_unit) * 100,
        purchased_units: parseInt(values.translation_tools_purchased_units),
        hard_limit: parseInt(values.translation_tools_hard_limit),
        bracket: 1000,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.translation_tools_enabled,
        default_on: true,
      },
      webhook: {
        internal_key: 'webhook',
        display_name: 'Webhooks',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      moderation_tools: {
        internal_key: 'moderation_tools',
        display_name: 'Moderation tool',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.moderation_tools_enabled,
        default_on: false,
      },
      data_export: {
        internal_key: 'data_export',
        display_name: 'Data export',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.data_export_enabled,
        default_on: false,
      },
      message_retention_period: {
        internal_key: 'message_retention_period',
        display_name: 'Message Retention Period',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: values.message_retention_period_purchased_units,
        hard_limit: 120,
        bracket: 1,
        proratable: true,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
      },
      desk: {
        internal_key: 'desk',
        display_name: 'Desk',
        strategy: 'FLAT',
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.desk_enabled,
        default_on: false,
      },
      delivery_receipt: {
        internal_key: 'delivery_receipt',
        display_name: 'delivery_receipt',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: values.delivery_receipt_enabled,
        default_on: false,
      },
      announcement: {
        internal_key: 'announcement',
        display_name: 'Announcement',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.announcement_cost_per_unit) * 100,
        purchased_units: parseInt(values.announcement_purchased_units),
        hard_limit: parseInt(values.announcement_hard_limit),
        bracket: 100,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.announcement_enabled,
        default_on: false,
      },
      speed_limit_tier: {
        internal_key: 'speed_limit_tier',
        display_name: 'Speed Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 0,
        proratable: false,
        plan_item_type: 'CORE',
        enabled: true,
        default_on: true,
        tier: values.speed_limit_tier_tier,
      },
      supergroup: {
        internal_key: 'supergroup',
        display_name: 'Supergroup',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 0,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.supergroup_enabled,
        default_on: false,
        tier: parseInt(values.supergroup_tier),
      },
      poll: {
        internal_key: 'poll',
        display_name: 'Poll',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 0,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.poll_enabled,
        default_on: false,
        tier: values.poll_tier,
      },
      advanced_analytics: {
        internal_key: 'advanced_analytics',
        display_name: 'Advanced Analytics',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.advanced_analytics_enabled,
        default_on: false,
      },
      reactions: {
        internal_key: 'reactions',
        display_name: 'Reactions',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 0,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.reactions_enabled,
        default_on: true,
      },
      message_search_index: {
        internal_key: 'message_search_index',
        display_name: 'Message Search Index',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.message_search_index_cost_per_unit) * 100,
        purchased_units: parseInt(values.message_search_index_purchased_units),
        hard_limit: parseInt(values.message_search_index_hard_limit),
        bracket: 100000,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.message_search_index_enabled,
        default_on: false,
      },
      message_search_query: {
        internal_key: 'message_search_query',
        display_name: 'Message Search Query',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.message_search_query_cost_per_unit) * 100,
        purchased_units: parseInt(values.message_search_query_purchased_units),
        hard_limit: parseInt(values.message_search_query_hard_limit),
        bracket: 25000,
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.message_search_query_enabled,
        default_on: false,
      },
      phi_certification: {
        internal_key: 'phi_certification',
        display_name: 'PHI Certification',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: null,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.phi_certification_enabled,
        default_on: false,
      },
      advanced_moderator_seat: {
        internal_key: 'advanced_moderator_seat',
        display_name: 'Advanced Moderator Seat',
        strategy: null,
        cost_per_unit: parseFloat(values.advanced_moderator_seat_cost_per_unit) * 100,
        purchased_units: parseInt(values.advanced_moderator_seat_purchased_units),
        hard_limit: parseInt(values.advanced_moderator_seat_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.advanced_moderator_seat_enabled,
        default_on: false,
      },
      moderation_rule_triggered_event: {
        internal_key: 'moderation_rule_triggered_event',
        display_name: 'Moderation Rule Triggered Event',
        strategy: 'PROPORTIONAL',
        cost_per_unit: parseFloat(values.moderation_rule_triggered_event_cost_per_unit) * 100,
        purchased_units: parseInt(values.moderation_rule_triggered_event_purchased_units),
        hard_limit: parseInt(values.moderation_rule_triggered_event_hard_limit),
        bracket: 1000, // match with enterprise plan
        proratable: true,
        plan_item_type: 'PREMIUM',
        enabled: values.moderation_rule_triggered_event_enabled,
        default_on: false,
      },
      hive_integration: {
        internal_key: 'hive_integration',
        display_name: 'Hive Integration',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: null,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.hive_integration_enabled,
        default_on: false,
      },
      moderator_messages: {
        internal_key: 'moderator_messages',
        display_name: 'Moderator Messages',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: null,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.moderator_messages_enabled,
        default_on: false,
      },
      moderation_rule_creation_limit: {
        internal_key: 'moderation_rule_creation_limit',
        display_name: 'Moderation Rule Creation Limit',
        strategy: null,
        cost_per_unit: parseFloat(values.moderation_rule_creation_limit_cost_per_unit) * 100,
        purchased_units: parseInt(values.moderation_rule_creation_limit_purchased_units),
        hard_limit: parseInt(values.moderation_rule_creation_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.moderation_rule_creation_limit_enabled,
        default_on: false,
      }
    };

    ai_chatbot_plan = {
      ai_chatbot: {
        internal_key: "ai_chatbot",
        display_name: "Message Credit for AI Chatbot",
        strategy: values.subscription_name.startsWith('ai') ? 'PROPORTIONAL' : null,
        cost_per_unit: parseFloat(values.ai_chatbot_cost_per_unit) * 100,
        purchased_units: parseInt(values.ai_chatbot_purchased_units),
        hard_limit: parseInt(values.ai_chatbot_hard_limit),
        bracket: 1000,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      ai_chatbot_limit: {
        internal_key: 'ai_chatbot_limit',
        display_name: 'AI Chatbots Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.ai_chatbot_limit_purchased_units),
        hard_limit: parseInt(values.ai_chatbot_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      knowledge_ingestion_limit: {
        internal_key: 'knowledge_ingestion_limit',
        display_name: 'Knowledge ingestion Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.knowledge_ingestion_limit_purchased_units),
        hard_limit: parseInt(values.knowledge_ingestion_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      knowledge_uptodate: {
        internal_key: 'knowledge_uptodate',
        display_name: 'Knowledge Up-to-date',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.knowledge_uptodate_enabled,
        default_on: true,
      },
      ai_chatbot_data_export: {
        internal_key: "ai_chatbot_data_export",
        display_name: "AI Chatbot Data Export",
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 0,
        proratable: false,
        plan_item_type: "PREMIUM",
        enabled: values.ai_chatbot_data_export_enabled,
        default_on: true,
      },
      remove_powered_by: {
        internal_key: 'remove_powered_by',
        display_name: "Remove 'Powered by Sendbird'",
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.remove_powered_by_enabled,
        default_on: true,
      },
      chat_uikit_sdk_access: {
        internal_key: 'chat_uikit_sdk_access',
        display_name: "UIKit/SDK access",
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.chat_uikit_sdk_access_enabled,
        default_on: true,
      },
      workflow_builder_limit: {
        internal_key: 'workflow_builder_limit',
        display_name: 'Workflow builder Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.workflow_builder_limit_purchased_units),
        hard_limit: parseInt(values.workflow_builder_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      function_call_limit: {
        internal_key: 'function_call_limit',
        display_name: 'Function Call Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.function_call_limit_purchased_units),
        hard_limit: parseInt(values.function_call_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      ai_chatbot_model: {
        internal_key: 'ai_chatbot_model',
        display_name: 'Selectable AI models',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
        tier: values.ai_chatbot_model_tier,
      },
      widget_custom_icon: {
        internal_key: 'widget_custom_icon',
        display_name: 'Widget Custom Icon',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.widget_custom_icon_enabled,
        default_on: true,
      },
      knowledge_ingestion_additional_source: {
        internal_key: 'knowledge_ingestion_additional_source',
        display_name: 'Knowledge ingestion additional source',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: 0,
        hard_limit: 0,
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: values.knowledge_ingestion_additional_source_enabled,
        default_on: true,
      },
      crawling_page_limit: {
        internal_key: 'crawling_page_limit',
        display_name: 'Crawling page limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.crawling_page_limit_purchased_units),
        hard_limit: parseInt(values.crawling_page_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
      },
      bot_first_message_multi_locale_limit: {
        internal_key: 'bot_first_message_multi_locale_limit',
        display_name: 'Bot First Message Multi-locale Limit',
        strategy: null,
        cost_per_unit: 0,
        purchased_units: parseInt(values.bot_first_message_multi_locale_limit_purchased_units),
        hard_limit: parseInt(values.bot_first_message_multi_locale_limit_hard_limit),
        bracket: 1,
        proratable: false,
        plan_item_type: 'PREMIUM',
        enabled: true,
        default_on: true,
    },
    };

    subscription = {
      subscription_name: values.subscription_name,
      display_name: values.display_name,
      subscription_type: 'SALES_CUSTOM',
      product: 'CHAT',
      plan: plan,
      ai_chatbot_plan: ai_chatbot_plan,
      plan_value: parseFloat(values.plan_value) * 100,
      current: false,
      start_date: values.subscription_start_date,
      end_date: values.subscription_end_date,
      auto_renewal: values.subscription_auto_renewal,
      auto_renewal_months: values.subscription_auto_renewal_months
        ? parseInt(values.subscription_auto_renewal_months)
        : undefined,
      billing_cycle_months: values.subscription_billing_cycle_months
        ? parseInt(values.subscription_billing_cycle_months)
        : undefined,
      next_billing_date: null,
      requested_by: requester,
      apply_date: null,
      applied: false,
    };

  } else {
    plan = {
      support: {
        internal_key: 'support',
        display_name: 'Support',
        strategy: 'FLAT',
        cost_per_unit: parseFloat(values.plan_value) * 100,
        purchased_units: 1,
        hard_limit: 1,
        bracket: 1,
        proratable: true,
        plan_item_type: 'SUPPORT',
        enabled: true,
        default_on: true,
      },
    };

    subscription = {
      subscription_name: values.subscription_name,
      display_name: values.display_name,
      subscription_type: 'SALES_CUSTOM',
      product: 'SUPPORT',
      plan: plan,
      plan_value: parseFloat(values.plan_value) * 100,
      current: false,
      start_date: values.subscription_start_date,
      end_date: values.subscription_end_date,
      auto_renewal: values.subscription_auto_renewal,
      auto_renewal_months: values.subscription_auto_renewal_months
        ? parseInt(values.subscription_auto_renewal_months)
        : undefined,
      billing_cycle_months: values.subscription_billing_cycle_months
        ? parseInt(values.subscription_billing_cycle_months)
        : undefined,
      next_billing_date: null,
      requested_by: requester,
      apply_date: null,
      applied: false,
    };
  }

  return insightAxios.post(
    `${API_DOMAIN}/api/crm_subscriptions/billing_subscription_requests/`,
    {
      crmOrganizationId,
      requestBody: JSON.stringify(subscription),
      requestedAction,
      reason: values.reason ? values.reason : '',
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getBillingSubscriptionPredefinedPlans() {
  return insightAxios.get(`${API_DOMAIN}/api/billing_subscriptions/get_predefined_plans/`, {
    headers: {
      Authorization: getAuthToken(),
      'Content-Type': 'application/json',
    },
  });
}

export function updateBillingSubscriptionRequestDenyRequest(billingSubscriptionId: number) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_subscriptions/deny_billing_subscription_request/`,
    {
      billingSubscriptionId,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateBillingSubscriptionRequestApproveRequest(billingSubscriptionId: number) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_subscriptions/approve_billing_subscription_request/`,
    {
      billingSubscriptionId,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

// TODO: Is the code location suitable? (because of resource)
// NOTE: Same meaning different naming -> (1) UI logic side: organizationSubscription, (2)API side: billingSubscription]
export function updateBillingSubscriptionEndDateRequest({ billingSubscriptionUid, endDate, description }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/billing_subscriptions/${billingSubscriptionUid}/update_end_date/`,
    {
      endDate,
      description,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateBillingSubscriptionRequestApplyedRequest({ crmOrganizationId }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_subscriptions/apply_paid_billing_subscription/`,
    {
      crmOrganizationId,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function createBillingSubscriptionFreeTrialRequest({ organizationUid, startDate }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${organizationUid}/billing_subscription_start_free_trial/`,
    {
      startDate,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function refundBillingSubscriptionPaymentRequest({ subscriptionPaymentId, memo }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_subscriptions/refund_billing_subscription_payment/`,
    {
      subscriptionPaymentId,
      memo
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function payNowBillingSubscriptionInvoiceRequest({ subscriptionInvoiceUid }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_subscriptions/pay_now_billing_subscription_invoice/`,
    {
      subscriptionInvoiceUid,
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export const fetchOrganizationBillingSubscriptionRequests = (params: { limit?: number; offset?: number }) => {
  return insightAxios.get(`${API_DOMAIN}/api/billing_subscription_requests/`, {
    params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
};

export const fetchOrganizationBillingSubscriptionRequest = ({ id }) => {
  return insightAxios.get(`${API_DOMAIN}/api/billing_subscription_requests/${id}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
};

export function createOnetimePaymentRequest({ crmOrganizationId, values }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/invoices/create_onetime_payment/`,
    {
      crmOrganizationId,
      ...values,
      amount: (parseFloat((values.amount * 100).toFixed(10))),
    },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function updateInvoiceStatusRequest({ invoiceUid, status, memo }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/invoices/${invoiceUid}/status/`,
    { status, memo },
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}
