import * as React from 'react';
import { useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Typography } from 'antd';

import { refundBillingSubscriptionPaymentRequest } from '../../api/billing';

const { Title, Text } = Typography;

type Props = {
  organizationSubscriptionPayment: OrganizationSubscriptionPayment;
};

export const OrganizationSubscriptionPaymentRefundDialog: React.FC<Props> = ({ organizationSubscriptionPayment }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [memo, setMemo] = useState('');

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleMemoChange(e) {
    setMemo(e.target.value);
  }

  function handleSubmit() {
    if (!memo) {
      return;
    }

    setLoading(true);
    refundBillingSubscriptionPaymentRequest({
      subscriptionPaymentId: organizationSubscriptionPayment.id,
      memo,
    })
      .then((_) => {
        setLoading(false);
        setVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button danger={true} icon={<MinusCircleOutlined />} onClick={showModal}>
        Refund Payment
      </Button>
      <Modal
        open={visible}
        title={`Refund #${organizationSubscriptionPayment.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Refund
          </Button>,
        ]}
      >
        <Title level={3}>Do you want to refund this payment?</Title>
        <Title level={4}>Payment ID</Title>
        <Text copyable={true}>{organizationSubscriptionPayment.id}</Text>
        <Title level={4}>Reason</Title>
        <Input size="large" onChange={handleMemoChange} placeholder="Please input refund reason or jira link" />
      </Modal>
    </React.Fragment>
  );
};
