import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getStatusPageIncidentRequest } from '../../api/statusPageIncidents';

import { StatusPageIncidentInfo } from '../../components/StatusPageIncidentInfo';
import { PageTitle } from '../../components/ui/PageTitle';
import { Typography } from 'antd';

const { Paragraph } = Typography;

interface MatchParams {
  incidentId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const StatusPageIncident = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { incidentId } = match.params;

    const [statusPageIncident, setStatusPageIncident] = useState(
      {} as StatusPageIncident,
    );

    function getStatusPageIncident() {
      getStatusPageIncidentRequest({ incidentId })
        .then((response) => {
          setStatusPageIncident(response.data);
        })
        .catch((error) => {});
    }

    useEffect(() => {
      getStatusPageIncident();
    }, []);

    return (
      <React.Fragment>
        <PageTitle
          title="StatusPage Incident Detail"
          subTitle={`#${
            (statusPageIncident.id && statusPageIncident.id.toString()) || ''
          }`}
          showBack={true}
        />
        <Paragraph> Data source: StatusPage</Paragraph>
        <StatusPageIncidentInfo statusPageIncident={statusPageIncident} />
      </React.Fragment>
    );
  }),
);
