import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';

import { getApplicationChannelsRequest } from '../api/application';
import { TableDetail } from './ui/TableDetail';
import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

const { Paragraph } = Typography;

const WordBreak = styled.p`
  word-break: break-word;
`;

function getChannelTypeString(channelTypeNumber) {
  switch (channelTypeNumber) {
    case 0:
      return 'Public';
    case 1:
      return 'Unlisted';
    case 2:
      return 'Secret';
    case 3:
      return 'App only';
    case 4:
      return 'Closed';
    case 5:
      return 'Messaging';
    case 6:
      return 'Group Messaging';
    case 7:
      return 'Closed or Secret';
    default:
      return '';
  }
}

const getColumns: GetColumns = ({ appId }) => {
  return [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: ApplicationChannel['id']) => (
        <Link to={`/applications/${appId}/channels/${id}/`}>{id}</Link>
      ),
    },
    {
      title: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Channel Type',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (channelType: ApplicationChannel['channelType']) =>
        getChannelTypeString(channelType),
    },
    {
      title: 'Custom Type',
      dataIndex: 'customType',
      key: 'customType',
      render: (customType: ApplicationChannel['customType']) => (
        <WordBreak>{customType}</WordBreak>
      ),
    },
    {
      title: 'Removed',
      dataIndex: 'removed',
      key: 'removed',
      render: (removed: ApplicationChannel['removed']) => (
        <Tag color={removed ? 'volcano' : ''}>
          {removed ? 'Removed' : 'Active'}
        </Tag>
      ),
    },
  ];
};

interface GetColumns {
  ({ appId }: { appId: CrmApplication['appId'] }): Array<ColumnProps<any>>;
}

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const Channels = withRouter<Props, any>(
  ({ match, location, history }) => {
    const { appId } = match.params;
    const [dataSource, setDatasource] = useState<Array<ApplicationChannel>>([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
      offset: 0,
      limit: 50,
    });

    function getChannels({ offset, limit }) {
      setLoading(true);
      getApplicationChannelsRequest({ appId, offset, limit })
        .then((response) => {
          setDatasource(response.data.results);
          setCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handlePageChange({ offset, limit }) {
      setPagination({ offset, limit });
      getChannels({ offset, limit });
    }

    function handleOnRow(channel: ApplicationChannel) {
      const { removed } = channel;
      return {
        style: {
          textDecoration: !removed ? '' : 'line-through',
          opacity: !removed ? 1 : 0.5,
        },
      };
    }

    useEffect(
      function () {
        getChannels({ offset: pagination.offset, limit: pagination.limit });
      },
      [location.pathname],
    );

    return (
      <>
        <TableDetail
          title="Channels"
          count={count}
          loading={loading}
          columns={getColumns({ appId })}
          dataSource={dataSource}
          onPageChange={handlePageChange}
          onRow={handleOnRow}
          pageSizeOptions={['50', '100', '200', '300']}
        >
          <Paragraph>
            <ul>
              <li>
                Only show recent 1,000 channels. If you need more, please contact the message server team.
              </li>
            </ul>
          </Paragraph>
        </TableDetail>
      </>
    );
  },
);
