import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TableDetail } from './ui/TableDetail';
import { ColumnProps } from 'antd/lib/table';
import { AnnouncementStatusTag } from './ui/Tags';

import { getApplicationAnnouncementsRequest } from '../api/application';

const alignRight = 'right' as ColumnProps<any>['align'];

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Message Text',
    dataIndex: 'messageText',
    key: 'messageText',
    render: (messageText: ApplicationAnnouncement['messageText']) =>
      messageText ? messageText.slice(0, 20) : '',
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdDt',
    key: 'createdDt',
    render: (createdDt: ApplicationAnnouncement['createdDt']) =>
      createdDt ? createdDt : '-',
  },
  {
    title: (
      <span>
        Scheduled At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'scheduledDt',
    key: 'scheduledDt',
    render: (scheduledDt: ApplicationAnnouncement['scheduledDt']) =>
      scheduledDt ? scheduledDt : '-',
  },
  {
    title: (
      <span>
        Done At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'doneDt',
    key: 'doneDt',
    render: (doneDt: ApplicationAnnouncement['doneDt']) =>
      doneDt ? doneDt : '-',
  },
  {
    title: 'Target User Count',
    dataIndex: 'targetUserCount',
    key: 'targetUserCount',
    align: alignRight,
    render: (targetUserCount: ApplicationAnnouncement['targetUserCount']) =>
      targetUserCount.toLocaleString(),
  },
  {
    title: 'Sent User Count',
    dataIndex: 'sentUserCount',
    key: 'sentUserCount',
    align: alignRight,
    render: (sentUserCount: ApplicationAnnouncement['sentUserCount']) =>
      sentUserCount.toLocaleString(),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: ApplicationAnnouncement['status']) => (
      <AnnouncementStatusTag status={status} />
    ),
  },
];

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const ApplicationAnnouncements = withRouter<Props, any>(({ match }) => {
  const { appId } = match.params;
  const [dataSource, setDatasource] = useState<Array<ApplicationChannel>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
  });

  function getApplicationAnnouncements({ offset, limit }) {
    setLoading(true);
    getApplicationAnnouncementsRequest({ appId, offset, limit })
      .then((response) => {
        setDatasource(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handlePageChange({ offset, limit }) {
    setPagination({ offset, limit });
    getApplicationAnnouncements({ offset, limit });
  }

  useEffect(function () {
    getApplicationAnnouncements({
      offset: pagination.offset,
      limit: pagination.limit,
    });
  }, []);

  return (
    <TableDetail
      title="Announcements"
      count={count}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onPageChange={handlePageChange}
      pageSizeOptions={['50', '100', '200', '300']}
    />
  );
});
