import React from 'react';
import { Collapse, List } from 'antd';

const Panel = Collapse.Panel;

export const StripeCardPanels: React.FC<{ stripeCustomer: StripeCustomer, paymentMethod: CrmOrganization['paymentMethod']}> = ({ stripeCustomer, paymentMethod }) => {
  if (paymentMethod === 'WIRE' && stripeCustomer.sources && stripeCustomer.sources.data.length > 0) {
    return <StripeCustomerSources stripeCustomer={stripeCustomer} />
  }

  if (stripeCustomer.paymentMethods && stripeCustomer.paymentMethods.length > 0) {
    return (
      <Collapse style={{ textAlign: 'left' }}>
        {stripeCustomer.paymentMethods.map((source) => {
          if (source.type === 'card') {
            const stripeInfo = `Card owner ${source.billingDetails.name} (Issued in ${source.card.country})`;
            const data = [stripeInfo];

            const renderItem = (item) => <List.Item>{item}</List.Item>;
            const header = `${source.card.brand} (${source.card.last4}) - exp ${source.card.expMonth}/${source.card.expYear}`;
            // TODO: ${stripeCustomer.defaultSource === source.id ? '(Default)' : ''}`;

            return (
              <Panel key={source.id} header={header}>
                <List size="small" dataSource={data} renderItem={renderItem} />
              </Panel>
            );
          }
        })}
      </Collapse>
    );
  }

  if (stripeCustomer.sources && stripeCustomer.sources.data.length > 0) {
    return <StripeCustomerSources stripeCustomer={stripeCustomer} />
  }

  return <div>No payment information is registered.</div>;
};

const StripeCustomerSources: React.FC<{ stripeCustomer: StripeCustomer}> = ({ stripeCustomer })=> {
  return (
    <Collapse style={{ textAlign: 'left' }}>
      {stripeCustomer.sources.data.map((source) => {
        if (source.object === 'card') {
          const stripeInfo = `Card owner ${source.name} (Issued in ${source.country})`;
          const data = [stripeInfo];

          if (source.addressZip) {
            const address = `${source.addressLine1}, ${source.addressCity}, ${source.addressCountry}, ${source.addressZip}`;
            data.push(address);
          }

          const renderItem = (item) => <List.Item>{item}</List.Item>;
          const header = `${source.brand} (${source.last4}) - exp ${source.expMonth}/${source.expYear}
            ${stripeCustomer.defaultSource === source.id ? ' (Default)' : ''} `;

          return (
            <Panel key={source.id} header={header}>
              <List size="small" dataSource={data} renderItem={renderItem} />
            </Panel>
          );
        } else if (source.type === 'card') {
          const stripeInfo = `Card owner ${source.card!.name} (Issued in ${source.card!.country})`;
          const data = [stripeInfo];

          const renderItem = (item) => <List.Item>{item}</List.Item>;
          const header = `${source.card!.brand} (${source.card!.last4}) - exp ${source.card!.expMonth}/${
            source.card!.expYear
          }${stripeCustomer.defaultSource === source.id ? ' (Default)' : ''}`;

          return (
            <Panel key={source.id} header={header}>
              <List size="small" dataSource={data} renderItem={renderItem} />
            </Panel>
          );
        } else {
          const source_status = source.status ? source.status : ''
          const data = [
            `Status: ${source_status}`,
            `Charged: $${(source.receiver ? source.receiver.amountCharged : 0)/100}`,
            `Balance: $${(source.receiver ? source.receiver.amountReceived : 0)/100}`,
            `Account No: ${source.achCreditTransfer ? source.achCreditTransfer.accountNumber : ''}`,
            `Routing No: ${source.achCreditTransfer ? source.achCreditTransfer.routingNumber : ''}`,
            `Swift Code: ${source.achCreditTransfer ? source.achCreditTransfer.swiftCode : ''}`,
            `Bank Name: ${source.achCreditTransfer ? source.achCreditTransfer.bankName : ''}`,
          ];

          const renderItem = (item) => <List.Item>{item}</List.Item>;
          const header = `ACH Account (${source_status}) ${source.id}${stripeCustomer.defaultSource === source.id ? ' (Default)' : ''}`;

          return (
            <Panel key={source.id} header={header}>
              <List size="small" dataSource={data} renderItem={renderItem} />
            </Panel>
          );
        }
      })}
    </Collapse>
  );
}
