import * as React from 'react';
import { Card, Col, List, Row, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { dateTimePattern } from '../utils/date';
import { SetOrganizationSubscriptionEndDateDialog } from './organizationBilling/SetOrganizationSuscriptionEndDateDialog';
import { StripeCardPanels } from './StripeCardPanels';

const { Text } = Typography
const Wrapper = styled.div`
  margin-bottom: 24px;
`;

type Props = {
  organizationSubscription: OrganizationSubscription;
  stripeCustomer: StripeCustomer;
  successCallback: any;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const OrganizationSubscriptionInfo: React.FC<Props> = ({
  organizationSubscription,
  stripeCustomer,
  successCallback,
}) => {
  function isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  }

  const detailBasic = [
    {
      key: 'UID',
      value: <Text copyable={true}>{organizationSubscription.uid}</Text>,
    },
    {
      key: 'Display Name',
      value: organizationSubscription.displayName,
    },
    {
      key: 'Organization',
      value: organizationSubscription.organization ? (
        <Link to={`/crm_organizations/${organizationSubscription.organization.uid}/`}>
          {organizationSubscription.organization.name}
        </Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'Plan Value',
      value:
        organizationSubscription.planValue === 0 ? (
          <Tag key="plan-value" style={{ margin: 0 }}>
            Free
          </Tag>
        ) : (
          <Tag key="plan-value" style={{ margin: 0 }}>
            {`$${(organizationSubscription.planValue / 100).toLocaleString()}`}
          </Tag>
        ),
    },
    {
      key: 'Billing Cycle',
      value: organizationSubscription.billingCycleMonths ? (
        <Tag color="green" key="plan-value" style={{ margin: 0 }}>
          {`${organizationSubscription.billingCycleMonths}Month`}
        </Tag>
      ) : (
        <>-</>
      ),
    },
    {
      key: 'Payment Method',
      value: organizationSubscription.organization.paymentMethod,
    },
    {
      key: 'Stripe Customer Status',
      value: !isEmptyObject(stripeCustomer)
        ? `${stripeCustomer.livemode ? 'Live ' : 'Test'} / ${stripeCustomer.delinquent ? 'Delinquent' : 'Clean'}`
        : '-',
    },
    {
      key: 'Stripe Cards',
      value: !isEmptyObject(stripeCustomer) ? <StripeCardPanels stripeCustomer={stripeCustomer} paymentMethod={organizationSubscription.organization.paymentMethod}/> : '-',
    },
  ];
  const detailAdvanced = [
    {
      key: 'Subscription Type',
      value: (() => {
        if (organizationSubscription.subscriptionType) {
          return <>{organizationSubscription.subscriptionType}</>;
        } else {
          return '-';
        }
      })(),
    },
    {
      key: 'Period',
      value: (
        <React.Fragment>
          {
            <>
              {organizationSubscription && organizationSubscription.startDate ? organizationSubscription.startDate : ''}
              {' ~ '}
              {organizationSubscription && organizationSubscription.endDate ? organizationSubscription.endDate : ''}
              {organizationSubscription.current &&
              organizationSubscription.product === 'CHAT' &&
              organizationSubscription.subscriptionName === 'free_trial' &&
              organizationSubscription.uid ? (
                <SetOrganizationSubscriptionEndDateDialog
                  organizationSubscription={organizationSubscription}
                  successCallback={successCallback}
                />
              ) : (
                ''
              )}
            </>
          }
        </React.Fragment>
      ),
    },
    {
      key: 'Next billing date',
      value: (() => {
        if (organizationSubscription.nextBillingDate) {
          return <>{organizationSubscription.nextBillingDate}</>;
        } else {
          return '-';
        }
      })(),
    },
    {
      key: 'Created At',
      value: (() => {
        if (organizationSubscription.createdAt) {
          return <>{moment(organizationSubscription.createdAt).utc().format(dateTimePattern.default)}</>;
        } else {
          return '-';
        }
      })(),
    },
    {
      key: 'Deleted At',
      value: (() => {
        if (organizationSubscription.deletedAt) {
          return <>{moment(organizationSubscription.deletedAt).utc().format(dateTimePattern.default)}</>;
        } else {
          return '-';
        }
      })(),
    },
    {
      key: 'Current',
      value: (
        <Tag color={organizationSubscription.current ? 'green' : 'red'} style={{ margin: 0 }}>
          {organizationSubscription.current ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      key: 'Auto Renewal',
      value: organizationSubscription.autoRenewal ? (
        <Tag color="green" style={{ margin: 0 }}>
          Enabled
        </Tag>
      ) : (
        <Tag color="red" style={{ margin: 0 }}>
          Disabled
        </Tag>
      ),
    },
    {
      key: 'Auto Renewal Months',
      value:
        organizationSubscription.autoRenewalMonths && parseInt(organizationSubscription.autoRenewalMonths) !== 0 ? (
          <Tag style={{ margin: 0 }}>{`${organizationSubscription.autoRenewalMonths}Month`}</Tag>
        ) : (
          '-'
        ),
    },
    {
      key: 'Description',
      value:
        organizationSubscription.description || '-',
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Wrapper>
      <Row
        gutter={16}
        style={{
          marginLeft: '-16px',
          marginRight: '-16px',
          padding: '16px 8px 0',
          background: '#f0f2f5',
        }}
      >
        {dataSources.map((dataSource, index) => (
          <Col key={`-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
            <Card
              size="small"
              style={{
                minHeight: '370px',
              }}
            >
              <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
            </Card>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};
