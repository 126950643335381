import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { PageTitle } from '../../components/ui/PageTitle';
import { TableDetail } from '../../components/ui/TableDetail';
import { StatusTag } from '../../components/ui/Tags';
import { GeneralDetail } from '../../components/ui/GeneralDetail';
import { getRoleRequest, getUserRolesRequest, getRolePermissionsRequest } from '../../api/roles';
import { Layout } from 'antd';

const userRolesColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/user_roles/${id}`}>{id}</Link>,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user) => <Link to={`/users/${user.id}`}>{user.username}</Link>,
  },
  {
    title: 'Granted At',
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: 'Expired At',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserRole['status']) => <StatusTag status={status} />,
  },
];

const rolePermissionsColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/role_permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    key: 'permission',
    render: (permission) => <Link to={`/permissions/${permission.id}`}>{permission.name}</Link>,
  },
  {
    title: 'Granted At',
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: 'Expired At',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserRole['status']) => <StatusTag status={status} />,
  },
];

interface MatchParams {
  roleId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const Role = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { roleId } = match.params;
    const [role, setRole] = useState<Role>({} as Role);

    const [userRoles, setUserRoles] = useState<Array<UserRole>>([]);
    const [loadingUserRoles, setLoadingUserRoles] = useState<boolean>(false);
    const [userRolesCount, setUserRolesCount] = useState<number>(0);

    const [rolePermissions, setRolePermissions] = useState<Array<UserRole>>([]);
    const [loadingRolePermissions, setLoadingRolePermissions] = useState<boolean>(false);
    const [rolePermissionsCount, setRolePermissionsCount] = useState<number>(0);

    function getRole() {
      getRoleRequest({ roleId })
        .then((response) => {
          setRole(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getUserRoles({ offset = 0, limit = 10 }) {
      setLoadingUserRoles(true);
      getUserRolesRequest({ offset, limit, user_id: '', role_id: roleId })
        .then((response) => {
          setUserRoles(response.data.results);
          setLoadingUserRoles(false);
          setUserRolesCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingUserRoles(false);
        });
    }

    function getRolePermissions({ offset = 0, limit = 10 }) {
      setLoadingRolePermissions(true);
      getRolePermissionsRequest({
        offset,
        limit,
        role_id: roleId,
        permission_id: '',
      })
        .then((response) => {
          setRolePermissions(response.data.results);
          setLoadingRolePermissions(false);
          setRolePermissionsCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingRolePermissions(false);
        });
    }

    useEffect(() => {
      getRole();
      getUserRoles({});
      getRolePermissions({});
    }, []);

    const leftData = [
      {
        key: 'Name',
        value: role ? role.name : '-',
      },
    ];
    const rightData = [
      {
        key: 'Description',
        value: role ? role.description : '-',
      },
    ];

    return (
      <Layout>
        <PageTitle title="Role" subTitle={`#${role.id || ''}`} showBack={true} />
        <Layout.Content>
          <GeneralDetail leftData={leftData} rightData={rightData} />
          <TableDetail
            title="Users with this role"
            count={userRolesCount}
            loading={loadingUserRoles}
            columns={userRolesColumns}
            dataSource={userRoles}
            onPageChange={getUserRoles}
          />
          <TableDetail
            title="Permissions in this role"
            count={rolePermissionsCount}
            loading={loadingRolePermissions}
            columns={rolePermissionsColumns}
            dataSource={rolePermissions}
            onPageChange={getRolePermissions}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
