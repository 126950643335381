import * as React from 'react';
import styled from 'styled-components';
import {Card, Statistic} from 'antd';
import {StatisticProps} from 'antd/lib/statistic/Statistic';
import {media} from '../../utils/media';
import {MetricPermissionInfoComponent} from "../MetricPermissionInfo";

type Props = {
  className?: string;
  statistics: ReadonlyArray<StatisticProps>;
  metricPermissionInfo?: MetricPermissionInfo;
};

const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: [start] repeat(3, 1fr) [end];
  grid-gap: 16px;
  justify-content: center;

  ${media.MOBILE_LARGE`
    grid-template-columns: [start] repeat(2, 1fr) [end];
  `}

  ${media.MOBILE_SMALL`
    grid-template-columns: [start] repeat(1, 1fr) [end];
  `}

  .permission-info {
    grid-column: start / end;

    .label {
      margin-left: 8px;
      font-size: .875em;
    }
  }
`;

export const MetricPageKeyMetrics: React.FC<Props> = ({
  className,
  statistics,
  metricPermissionInfo
}) => {
  return (
    <MetricsContainer className={className}>
      {statistics.map(item => (
        <Card>
          <Statistic {...item} />
        </Card>
      ))}
      {
        metricPermissionInfo &&
          <div className="permission-info">
            <MetricPermissionInfoComponent
              metricPermissionInfo={
                {
                  permission: metricPermissionInfo && metricPermissionInfo.permission,
                  authUsers: metricPermissionInfo && metricPermissionInfo.authUsers,
                  authRoles: metricPermissionInfo && metricPermissionInfo.authRoles
                }
              }
            />
            <span className="label">Who can see the metrics above?</span>
          </div>
      }
    </MetricsContainer>
  );
};
