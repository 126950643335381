import * as React from 'react';
import {FlagIcon, FlagIconCode} from 'react-flag-kit';
import styled from 'styled-components';

import {getBrowserTimezone} from '../../utils/date';
import {COUNTRY_CODE} from '../../CountryCode';
import {Tooltip} from 'antd';

const CountryName = styled.span`
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
`;

type BrowserTimeZoneTitleProps = {
  title: string;
};

type CountryWithFlagProps = {
  countryCode: string;
};

export const BrowserTimeZoneText: React.FC = () => (
  <small style={{ opacity: 0.6 }}>{getBrowserTimezone()}</small>
);

export const BrowserTimeZoneTitle: React.FC<BrowserTimeZoneTitleProps> = ({
  title,
}) => (
  <span>
    {title} <BrowserTimeZoneText />
  </span>
);

export const CountryWithFlag: React.FC<CountryWithFlagProps> = ({
  countryCode,
}) => {
  const code = countryCode.toUpperCase();
  return (
    <>
      <FlagIcon code={code as FlagIconCode} size={24} />
      <CountryName>{COUNTRY_CODE[countryCode.toLowerCase()].name}</CountryName>
    </>
  );
};

type PrettyDateTimeProps = { datetime: string }

export const PrettyDateTime: React.FC<PrettyDateTimeProps> = ({ datetime }) => {
  return (
    <Tooltip title={datetime}>
      <span style={{ whiteSpace: 'nowrap' }}>{datetime.split('T')[0]}</span>
    </Tooltip>
  )
};
