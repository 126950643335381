import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Input } from 'antd';

import { updateOrganizationVoucherSubscriptionUpdateRequest } from '../api/organization';

type Props = {
  crmOrganization: CrmOrganization;
  voucherSubscription: any;
  successCallback: any;
};

export const OrganizationVoucherSubscriptionChangeDialog: React.FC<Props> = ({
  crmOrganization,
  voucherSubscription,
  successCallback,
}) => {
  const [visible, setVisible] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [durationDays, setDurationDays] = useState(0);

  useEffect(() => {
    setPaymentAmount(voucherSubscription ? voucherSubscription.voucherSubscriptionPaymentAmount / 100 : 0);
    setCredit(voucherSubscription ? voucherSubscription.voucherSubscriptionCredit : 0);
    setDurationDays(voucherSubscription ? voucherSubscription.voucherSubscriptionDurationDays : 0);
  }, [voucherSubscription]);

  function onClick() {
    setVisible(true);
  }

  function onOk() {
    updateOrganizationVoucherSubscriptionUpdateRequest({
      crmOrganizationUid: crmOrganization.uid,
      paymentAmount,
      credit,
      durationDays,
    })
      .then((response) => {
        setVisible(false);
        successCallback &&
          successCallback({
            callback: console.log('updateOrganizationVoucherSubscriptionRequest', response.data),
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function onCancel() {
    setVisible(false);
  }

  function onPaymentChange(value) {
    setPaymentAmount(parseInt(value.target.value));
  }

  function onCreditChange(value) {
    setCredit(parseInt(value.target.value));
  }

  function onDurationDaysChange(value) {
    setDurationDays(parseInt(value.target.value));
  }

  const buttonStyle = {
    marginLeft: '0.5em',
    verticalAlign: 'middle',
  };

  return (
    <>
      <Button type="primary" size="small" onClick={onClick} style={buttonStyle}>
        Change
      </Button>
      <Modal title="Change Voucher Subscription" open={visible} onOk={onOk} onCancel={onCancel}>
        <Form.Item>
          <Input addonBefore="Payment Amount ($)" defaultValue={paymentAmount} onChange={onPaymentChange} />
          <div style={{ margin: '24px 0' }} />
          <Input addonBefore="Credit" defaultValue={credit} onChange={onCreditChange} />
          <div style={{ margin: '24px 0' }} />
          <Input addonBefore="DurationDays" defaultValue={durationDays} onChange={onDurationDaysChange} />
        </Form.Item>
      </Modal>
    </>
  );
};
