import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getGateTeamAccountOtpsRequest(limit, offset, status) {
  return insightAxios.get(
    `${API_DOMAIN}/api/gate_team_account_otps/?limit=${limit}&offset=${offset}&status=${status}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function createGateTeamAccountOtpRequest({
  email,
  reason,
  isEmergency,
  infoLink,
}) {
  return insightAxios.post(
    `${API_DOMAIN}/api/gate_team_account_otps/`,
    {
      email,
      reason,
      isEmergency,
      infoLink,
    },
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function generateGateTeamAccountOtpRequest({ dashboardOtpId }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/gate_team_account_otps/${dashboardOtpId}/generate/`,
    {},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function approveGateTeamAccountOtpRequest({ dashboardOtpId, status }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/gate_team_account_otps/${dashboardOtpId}/approve/`,
    {status},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getDatabaseOtpsRequest(limit, offset, status) {
  return insightAxios.get(
    `${API_DOMAIN}/api/database_otps/?limit=${limit}&offset=${offset}&status=${status}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getDatabaseNamesRequest(){
  return insightAxios.get(
    `${API_DOMAIN}/api/database_otps/database_names/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function createDatabaseOtpRequest({
  dbName,
  reason,
  isEmergency,
  infoLink,
}) {
  return insightAxios.post(
    `${API_DOMAIN}/api/database_otps/`,
    {
      dbName,
      reason,
      isEmergency,
      infoLink,
    },
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function generateDatabaseOtp({ databaseOtpId }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/database_otps/${databaseOtpId}/generate/`,
    {},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function approveDatabaseOtp({ databaseOtpId, status }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/database_otps/${databaseOtpId}/approve/`,
    {status},
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getDatabaseOtpRequest({ databaseOtpId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/database_otps/${databaseOtpId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getDashboardOtpRequest({ dashboardOtpId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/gate_team_account_otps/${dashboardOtpId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

