import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { Layout } from 'antd';
import { TableDetail } from '../../components/ui/TableDetail';
import { PageTitle } from '../../components/ui/PageTitle';
import { UserMigrationRequestStatusTag } from '../../components/ui/Tags';

import { UserMigrationRequestCreateDialog } from './userMigrationRequestCreateDialog';
import { UserMigrationRequestViewDialog } from './userMigrationRequestViewDialog';

import { getUserMigrationRequestsRequest } from '../../api/userMigrationRequest';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: UserMigrationRequest['id']) => (id ? <Link to={`/user_migration_requests/${id}/`}>{id}</Link> : '-'),
  },
  {
    title: 'User to Migrate',
    dataIndex: 'userToMigrate',
    key: 'userToMigrate',
    render: (user: UserMigrationRequest['userToMigrate']) =>
      user ? <Link to={`/crm_users/${user.id}/`}>{user.id}</Link> : '-',
  },
  {
    title: 'From Organization',
    dataIndex: 'fromOrganization',
    key: 'fromOrganization',
    render: (organization: UserMigrationRequest['fromOrganization']) =>
      organization ? <Link to={`/crm_organizations/${organization.id}/`}>{organization.id}</Link> : '-',
  },
  {
    title: 'To Organization',
    dataIndex: 'toOrganization',
    key: 'toOrganization',
    render: (organization: UserMigrationRequest['toOrganization']) =>
      organization ? <Link to={`/crm_organizations/${organization.id}/`}>{organization.id}</Link> : '-',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role) => (role ? role : '-'),
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'requester',
    render: (requester: UserMigrationRequest['requester']) => (
      <span>
        {requester && requester.username}
        {requester && requester.eid ? `(${requester.eid})` : '-'}
      </span>
    ),
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    render: (reason) => (reason ? reason : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserMigrationRequest['status']) =>
      status ? <UserMigrationRequestStatusTag status={status} /> : '-',
  },
  {
    title: 'Reviewer',
    dataIndex: 'reviewer',
    key: 'reviewer',
    render: (reviewer: UserMigrationRequest['reviewer']) => (
      <span>
        {reviewer && reviewer.username}
        {reviewer && reviewer.eid ? `(${reviewer.eid})` : '-'}
      </span>
    ),
  },
  {
    title: (
      <span>
        Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'reviewedAt',
    key: 'reviewedAt',
    render: (reviewedAt: UserMigrationRequest['reviewedAt']) => (reviewedAt ? reviewedAt : '-'),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_, userMigrationRequest: UserMigrationRequest) => (
      <UserMigrationRequestViewDialog userMigrationRequest={userMigrationRequest} successCallback={refresh} />
    ),
  },
];

function refresh() {
  window.location.reload();
}

type Props = RouteComponentProps;

export const UserMigrationRequests = withRouter<Props, any>(({ location }) => {
  const [userMigrationRequests, setUserMigrationRequests] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getUserMigrationsRequests(0, 30);
  }, [location]);

  const getUserMigrationsRequests = (offset = 0, limit = 30) => {
    setLoading(true);
    getUserMigrationRequestsRequest({ offset, limit })
      .then((response) => {
        setUserMigrationRequests(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onPageChange = ({ offset, limit, page }) => {
    getUserMigrationsRequests(offset, limit);
  };

  function onRow(userMigrationRequest: UserMigrationRequest) {
    // console.log(userMigrationRequest);
  }

  function refresh() {
    window.location.reload();
  }

  return (
    <Layout>
      <PageTitle
        title="User Migration Requests"
        extra={[<UserMigrationRequestCreateDialog key="userMigrationRequestDialog" successCallback={refresh} />]}
      >
        <p>This feature allows moving user from one organization to another.</p>
        <p>However, this does not change anything else (ex. it does not assign new OWNER)</p>
        <p>and hence does not guarantee safe operation of the organization user has left.</p>
        <p>
          <strong> Please double-check the user and destination organization.</strong>
        </p>
      </PageTitle>
      <Layout.Content>
        <TableDetail
          title="Requests"
          loading={loading}
          columns={columns}
          dataSource={userMigrationRequests}
          count={count}
          onRow={onRow as any}
          onPageChange={onPageChange}
          defaultPageSize={30}
        />
      </Layout.Content>
    </Layout>
  );
});
