import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { Select, DatePicker, Layout } from 'antd';
import queryString from 'query-string';
import { ColumnProps } from 'antd/lib/table';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { PlanTag, RegionTag } from '../../components/ui/Tags';

import { getCrmApplicationDailyRecordRankingRequest } from '../../api/ranking';
import { dateTimePattern } from '../../utils/date';
import { PageTitle } from '../../components/ui/PageTitle';
import styled from 'styled-components';

const FilterWrapper = styled.div`
  display: flex;
  > div + div {
    margin-left: 16px;
  }
`;

const statusTypes = [
  { name: 'MAU', status: '-mau' },
  { name: 'DAU', status: '-dau' },
  { name: 'Daily Message Count', status: '-daily_message_count' },
  { name: 'Daily Peak Connection', status: '-daily_peak_connection' },
];

const alignRight = 'right' as ColumnProps<any>['align'];
const columns = [
  {
    title: '#',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: 'Record Date',
    dataIndex: 'recordDate',
    key: 'recordDate',
  },
  {
    title: 'DAU',
    dataIndex: 'dau',
    key: 'dau',
    align: alignRight,
    render: (dau: CrmApplicationRecord['dau']) =>
      dau ? dau.toLocaleString() : '0',
  },
  {
    title: 'MAU',
    dataIndex: 'mau',
    key: 'mau',
    align: alignRight,
    render: (mau: CrmApplicationRecord['mau']) =>
      mau ? mau.toLocaleString() : '0',
  },
  {
    title: 'Daily Message Count',
    dataIndex: 'dailyMessageCount',
    key: 'dailyMessageCount',
    align: alignRight,
    render: (dailyMessageCount: CrmApplicationRecord['dailyMessageCount']) =>
      dailyMessageCount ? dailyMessageCount.toLocaleString() : '0',
  },
  {
    title: 'Daily Peak Connection',
    dataIndex: 'dailyPeakConnection',
    key: 'dailyPeakConnection',
    align: alignRight,
    render: (
      dailyPeakConnection: CrmApplicationRecord['dailyPeakConnection'],
    ) => (dailyPeakConnection ? dailyPeakConnection.toLocaleString() : '0'),
  },
  {
    title: 'Plan',
    dataIndex: 'crmApplication',
    key: 'plan',
    render: (crmApplication: CrmApplicationRecord['crmApplication']) => (
      <PlanTag plan={crmApplication.plan} />
    ),
  },
  {
    title: 'App ID',
    dataIndex: 'crmApplication',
    key: 'appId',
    render: (crmApplication: CrmApplicationRecord['crmApplication']) => (
      <Link to={`/applications/${crmApplication.appId}/detail/`}>
        {crmApplication.appId.split('-')[0]}...
      </Link>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'crmApplication',
    key: 'name',
    render: (crmApplication: CrmApplicationRecord['crmApplication']) =>
      crmApplication.appName,
  },
  {
    title: 'Region',
    dataIndex: 'crmApplication',
    key: 'region',
    render: (crmApplication: CrmApplicationRecord['crmApplication']) => (
      <RegionTag region={crmApplication.region} />
    ),
  },
  {
    title: 'Organization',
    dataIndex: 'crmApplication',
    key: 'crmOrganization',
    render: (crmApplication: CrmApplicationRecord['crmApplication']) =>
      crmApplication.crmOrganization ? (
        <Link to={`/crm_organizations/${crmApplication.crmOrganization.id}/`}>
          {crmApplication.crmOrganization.id} (
          {crmApplication.crmOrganization.name})
        </Link>
      ) : (
        '-'
      ),
  },
];

type Props = RouteComponentProps;

export const RankingCrmApplicationRecords = withRouter<Props, any>(
  ({ location, history }) => {
    const [crmApplicationRecord, setCrmApplicationRecord] = useState<
      Array<CrmApplicationRecord>
    >([]);
    const today = moment().format('YYYY-MM-DD');
    const [filteredOrder, setFilteredOrder] = useState('-mau');
    const [loading, setLoading] = useState(false);
    const [
      crmApplicationRecordsCount,
      setCrmApplicationRecordsCount,
    ] = useState(0);
    const [recordDate, setRecordDate] = useState(today);

    useEffect(() => {
      const qs = queryString.parse(location.search);
      const order = qs.order ? (qs.order as string) : '-mau';
      const date = qs.date ? (qs.date as string) : today;
      const limit = parseInt(qs.limit ? (qs.limit as string) : '30');
      const offset = parseInt(qs.offset ? (qs.offset as string) : '0');

      getCrmApplicationDailyRecordRanking(offset, limit, order, date);
    }, [location]);

    const getCrmApplicationDailyRecordRanking = (
      offset,
      limit,
      order = filteredOrder,
      date = recordDate,
    ) => {
      setLoading(true);
      getCrmApplicationDailyRecordRankingRequest({ order, limit, offset, date })
        .then((response) => {
          response.data.results.forEach((result, index) => {
            result.rank = offset + index + 1;
          });
          setCrmApplicationRecord(response.data.results);
          setLoading(false);
          setCrmApplicationRecordsCount(response.data.count);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    function handleOrderChange(order) {
      setFilteredOrder(order);
      history.replace({
        pathname: '/rankings/',
        search: searchString(30, 0, order, recordDate),
      });
    }

    function handleDateChange(date) {
      const datestr = date ? date.format('YYYY-MM-DD') : today;
      setRecordDate(datestr);
      history.replace({
        pathname: '/rankings/',
        search: searchString(30, 0, filteredOrder, datestr),
      });
    }

    const onPageChange = (page, pageSize) => {
      const limit = pageSize;
      const offset = (page - 1) * pageSize;
      history.replace({
        pathname: '/rankings/',
        search: searchString(limit, offset, filteredOrder, recordDate),
      });
      getCrmApplicationDailyRecordRanking((page - 1) * pageSize, pageSize);
    };

    function disabledDate(current) {
      // Can not select days before today and today
      return current && current.valueOf() > Date.now();
    }

    function handleOnRow(crmApplicationRecord: CrmApplicationRecord) {
      return {
        style: {
          textDecoration: !crmApplicationRecord.crmApplication.removed
            ? ''
            : 'line-through',
          opacity: !crmApplicationRecord.crmApplication.removed ? 1 : 0.5,
        },
      };
    }

    function searchString(limit, offset, order, date) {
      return `?limit=${limit}&offset=${offset}&order=${order}&date=${date}`;
    }

    return (
      <Layout>
        <PageTitle title="Ranking">
          <FilterWrapper>
            <Select
              defaultValue={statusTypes[0].name}
              onChange={handleOrderChange}
              style={{ width: '100%' }}
            >
              {statusTypes.map((value) => (
                <option key={value.name} value={value.status}>
                  {value.name}
                </option>
              ))}
            </Select>
            <DatePicker
              onChange={handleDateChange}
              defaultValue={moment()}
              disabledDate={disabledDate}
              format={dateTimePattern.date}
              style={{ width: '100%' }}
            />
          </FilterWrapper>
        </PageTitle>
        <Layout.Content>
          <ResponsiveTable
            loading={loading}
            columns={columns}
            dataSource={crmApplicationRecord}
            onRow={handleOnRow}
            pagination={{
              total: crmApplicationRecordsCount,
              onChange: onPageChange,
              onShowSizeChange: onPageChange,
            }}
          />
        </Layout.Content>
      </Layout>
    );
  },
);
