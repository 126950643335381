import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { getApplicationModeratorsRequest, getCrmApplicationRequest } from '../api/application';
import { TableDetail } from './ui/TableDetail';
import { EditAllowedChannelCustomTypesDialog } from './EditAllowedChannelCustomTypesDialog';

const { Paragraph } = Typography;

const getColumns: GetColumns = ({ orgId, appId }) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Soda User ID',
      dataIndex: 'sdkUserId',
      key: 'sdkUserId',
    },
    {
      title: 'Dashboard login email',
      dataIndex: 'user',
      key: 'user',
      render: (user) =>
        user.email
    },
    {
      title: 'Allowed Channel Custom Types(comma separated)',
      dataIndex: 'allowedChannelCustomTypes',
      key: 'allowedChannelCustomTypes',
      render: (allowedChannelCustomTypes: string[], sdkUser) =>
        <> <p>{allowedChannelCustomTypes.join(', ')}</p>
          {
            shouldShowAllowedChannelCustomTypesEditButton(orgId) ?
              (<EditAllowedChannelCustomTypesDialog
                id={sdkUser.id}
                appId={appId}
                previous={allowedChannelCustomTypes.join(', ')}
                successCallback={refresh}
              />) : ''
          }
        </>
    },
    {
      title: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];
};

// DP-461 Show allowed channel custom type edit button only on NC Universe org (61260) or testing
// Airasia (51434) uses this feature, too. 2021-12-16
const shouldShowAllowedChannelCustomTypesEditButton = (orgId: number) => {
  if (process.env.REACT_APP_ENV === 'production') {
    if (orgId === 61260 || orgId === 51434) {
      return true;
    }
    return false;
  }
  return true;
}

const refresh = () => {
  window.location.reload();
}

interface GetColumns {
  ({ orgId, appId }: { orgId: CrmOrganization['id'], appId: CrmApplication['appId'] }): Array<ColumnProps<any>>;
}

interface MatchParams {
  appId: CrmApplication['appId'];
}

type Props = RouteComponentProps<MatchParams>;

export const ApplicationModerators = withRouter<Props, any>(
  ({ match, location, history }) => {
    const { appId } = match.params;
    const [crmApplication, setCrmApplication] = useState<CrmApplication>({} as CrmApplication);
    const [dataSource, setDatasource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
      offset: 0,
      limit: 50,
    });

    function getModerators({ offset, limit }) {
      setLoading(true);
      getApplicationModeratorsRequest({ appId, offset, limit })
        .then((response) => {
          setDatasource(response.data.results);
          setCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handlePageChange({ offset, limit }) {
      setPagination({ offset, limit });
      getModerators({ offset, limit });
    }

    useEffect(
      function () {
        getCrmApplicationRequest({ appId }).then((response) => {
            setCrmApplication(response.data);
            getModerators({ offset: pagination.offset, limit: pagination.limit })
          }
        );
      },
      [location.pathname],
    );

    return (
      <TableDetail
        title="Moderators"
        count={count}
        loading={loading}
        columns={getColumns({ orgId: crmApplication?.crmOrganization?.id, appId })}
        dataSource={dataSource}
        onPageChange={handlePageChange}
        pageSizeOptions={['50', '100', '200', '300']}
      >
        <Paragraph>
          <ul>
            <li>
              For the engineering team: This table shows the `SDKUser` table on `gate`.
            </li>
          </ul>
        </Paragraph>
      </TableDetail>
    );
  },
);
