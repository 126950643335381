import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCrmApplicationFiltersUnpaidRequest } from '../../api/application';
import { PlanTag } from '../../components/ui/Tags';

const { Title } = Typography;

const columns = [
  {
    title: '#id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    key: 'appId',
    render: (appId) =>
      !appId ? (
        ''
      ) : (
        <Link to={`/applications/${appId}/detail/`}>
          {appId.split('-')[0]}...
        </Link>
      ),
  },
  {
    title: 'App Name',
    dataIndex: 'appName',
    key: 'appName',
    render: (appName) => (!appName ? '' : <span>{appName}</span>),
  },
  {
    title: 'Organization',
    dataIndex: 'crmOrganization',
    key: 'id',
    render: (organization) =>
      !organization ? (
        ''
      ) : (
        <Link to={`/crm_organizations/${organization.id}/`}>
          {organization.id}
        </Link>
      ),
  },
  {
    title: 'Stripe Customer',
    dataIndex: 'crmOrganization',
    key: 'stripeCustomerId',
    render: (organization) =>
      !organization.stripeCustomerId ? (
        ''
      ) : (
        <span>{organization.stripeCustomerId}</span>
      ),
  },
  {
    title: 'Queued Payments',
    dataIndex: 'queuedPaymentsCount',
    key: 'queuedPaymentsCount',
    render: (queuedPaymentsCount) =>
      !queuedPaymentsCount ? '0' : <span>{queuedPaymentsCount}</span>,
  },
  {
    title: 'Failed Payments',
    dataIndex: 'failedPaymentsCount',
    key: 'failedPaymentsCount',
    render: (failedPaymentsCount) =>
      !failedPaymentsCount ? '0' : <span>{failedPaymentsCount}</span>,
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (plan: CrmApplication['plan']) =>
      !plan ? '-' : <PlanTag plan={plan} />,
  },
];

type Props = RouteComponentProps;

export const DeficitApps = withRouter<Props, any>(({ location, history }) => {
  const [deficitApplications, setDeficitApplications] = useState<
    Array<CrmApplication>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(function () {
    getCrmApplicationFiltersUnpaid();
  }, []);

  function getCrmApplicationFiltersUnpaid() {
    setLoading(true);
    getCrmApplicationFiltersUnpaidRequest({ counts: 1 })
      .then((response) => {
        setDeficitApplications(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function handleOnRow(crmApplication: CrmApplication) {
    return {
      style: {
        textDecoration: crmApplication.removed ? 'line-through' : '',
        opacity: crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  return (
    <React.Fragment>
      <Row gutter={16} style={{ marginBottom: '8px' }}>
        <Col span={24}>
          <Title level={3}>Deficit Applications</Title>
        </Col>
      </Row>
      <ResponsiveTable
        bordered={true}
        loading={loading}
        columns={columns}
        dataSource={deficitApplications}
        pagination={false}
        onRow={handleOnRow}
      />
    </React.Fragment>
  );
});
