import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Layout } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { getApplicationPlanRequestsRequest, getIsReviewerRequest } from '../../api/applicationPlanRequest';
import { ApplicationPlanRequestRequestedActionTag, ApplicationPlanRequestStatusTag } from '../../components/ui/Tags';
import { PageTitle } from '../../components/ui/PageTitle';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { ApplicationPlanRequestViewDialog } from '../../components/ApplicationPlanRequestViewDialog';

const { Option } = Select;

interface ColumnsProps {
  dialogSuccessCallback: () => void;
}

type Props = RouteComponentProps;

export const ApplicationPlanRequests = withRouter<Props, any>(({ location, history }) => {
  const [applicationPlanRequests, setApplicationPlanRequests] = useState<Array<ApplicationPlanRequest>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const defaultPageLimit = 30;
  const [limit, setLimit] = useState(defaultPageLimit);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState('');
  const statusNames = [
    { key: 'All', value: '' },
    { key: 'Requested', value: 'REQUESTED' },
    { key: 'Approved', value: 'APPROVAL_PASSED' },
    { key: 'Denied', value: 'APPROVAL_DENIED' },
  ];
  const [isReviewer, setIsReviewer] = useState(false);

  function searchString(limit, offset, status) {
    return `?limit=${limit}&offset=${offset}&status=${status}`;
  }

  function getApplicationPlanRequests(limit = 20, offset = 0, status = '') {
    setLoading(true);

    getApplicationPlanRequestsRequest(limit, offset, '', status)
      .then((response) => {
        setApplicationPlanRequests(response.data.results);
        setLoading(false);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setLimit(limit);
    setOffset(offset);
    history.replace({
      pathname: '/application_plan_requests/',
      search: searchString(30, 0, status),
    });
    getApplicationPlanRequests(limit, offset, status);
  };

  const onStatusSelect = (value) => {
    setStatus(value);
    history.replace({
      pathname: '/application_plan_requests/',
      search: searchString(30, 0, value),
    });
    getApplicationPlanRequests(limit, offset, value);
  };

  function dialogSuccessCallback() {
    setStatus('');
    getApplicationPlanRequests(limit, offset, '');
  }

  function getIsReviewer() {
    getIsReviewerRequest()
      .then((response) => {
        setIsReviewer(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columns = ({ dialogSuccessCallback }: ColumnsProps) => [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: ApplicationPlanRequest['id']) => <Link to={`/application_plan_requests/${id}/`}>{id}</Link>,
    },
    {
      title: 'Plan ID',
      dataIndex: 'sodaApplicationPlanId',
      key: 'sodaApplicationPlanId',
      render: (
        sodaApplicationPlanId: ApplicationPlanRequest['sodaApplicationPlanId'],
        applicationPlanRequest: ApplicationPlanRequest,
      ) =>
        sodaApplicationPlanId ? (
          <Link
            to={`/crm_applications/${applicationPlanRequest.crmApplication.appId}/application_plans/${sodaApplicationPlanId}/`}
          >
            {sodaApplicationPlanId}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
      render: (requester: ApplicationPlanRequest['requester']) => (
        <span>
          {requester && requester.username}
          {requester && requester.eid ? `(${requester.eid})` : '-'}
        </span>
      ),
    },
    {
      title: (
        <span>
          Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'App ID',
      dataIndex: 'crmApplication',
      key: 'crmApplication',
      render: (crmApplication: CrmApplication) =>
        crmApplication ? (
          <Link to={`/applications/${crmApplication['appId']}/detail/`}>
            {crmApplication['appId'].split('-')[0]}...
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'App Name',
      dataIndex: 'crmApplication',
      key: 'crmApplication',
      render: (crmApplication: CrmApplication) => crmApplication['appName'],
    },
    {
      title: 'Requested Action',
      dataIndex: 'requestedAction',
      key: 'requestedAction',
      render: (requestedAction: ApplicationPlanRequest['requestedAction']) => (
        <ApplicationPlanRequestRequestedActionTag requestedAction={requestedAction} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: ApplicationPlanRequest['status']) => <ApplicationPlanRequestStatusTag status={status} />,
    },
    {
      title: 'Reviewer',
      dataIndex: 'reviewer',
      key: 'reviewer',
      render: (reviewer: ApplicationPlanRequest['reviewer']) => (
        <span>
          {reviewer && reviewer.username}
          {reviewer && reviewer.eid ? `(${reviewer.eid})` : '-'}
        </span>
      ),
    },
    {
      title: (
        <span>
          Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'reviewedAt',
      key: 'reviewedAt',
      render: (reviewedAt: ApplicationPlanRequest['reviewedAt']) => (reviewedAt ? reviewedAt : '-'),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => (reason ? reason : '-'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (applicationPlanRequest: ApplicationPlanRequest) => (
        <ApplicationPlanRequestViewDialog
          applicationPlanRequest={applicationPlanRequest}
          isReviewer={isReviewer}
          successCallback={dialogSuccessCallback}
        />
      ),
    },
  ];

  useEffect(
    function () {
      const qs = queryString.parse(location.search);

      const limit = parseInt(qs.limit ? (qs.limit as string) : '30');
      const offset = parseInt(qs.offset ? (qs.offset as string) : '0');
      const status = qs.status ? (qs.status as string) : '';

      setLimit(limit);
      setOffset(offset);
      setStatus(status);

      getApplicationPlanRequests(limit, offset, status);
      getIsReviewer();
    },
    [location],
  );

  return (
    <Layout>
      <PageTitle title="Application Plan Requests" subTitle={`${count} results`} />
      <Layout.Content>
        <Row gutter={16} style={{ marginBottom: '8px' }}>
          <Col span={12} md={{ span: 4 }} lg={{ span: 5 }}>
            <label className="SelectContainer__select-wrapper">
              <div className="SelectContainer__label">Status</div>
              <Select
                value={status as any}
                onSelect={onStatusSelect}
                defaultValue={statusNames[0]}
                placeholder="status"
                style={{ width: '100%' }}
              >
                {statusNames.map((status) => (
                  <Option key={status.key} value={status.value}>
                    {status.key}
                  </Option>
                ))}
              </Select>
            </label>
          </Col>
        </Row>
        <ResponsiveTable
          scroll={{ x: 'auto' }}
          loading={loading}
          columns={columns({ dialogSuccessCallback })}
          dataSource={applicationPlanRequests}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(offset / limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
