import React from 'react';
import { Card, Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import { BooleanTag } from './ui/Tags';

type Props = {
  applicationPlan: ApplicationPlanDetail;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={8}>{item.key}</Col>
        <Col span={16} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

function convertDataSourcePlan(_applicationPlan) {
  const renderInfoItem = (item) => {
    return (
      <List.Item>
        <Row style={{ width: '100%' }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            {item[0]}
          </Col>
          <Col span={12} style={{ fontWeight: 500, textAlign: 'right' }}>
            {item[1] ? item[1] : '-'}
          </Col>
        </Row>
      </List.Item>
    );
  };

  const renderPremiumFeatureItem = (item) => {
    return (
      <List.Item>
        <Row style={{ width: '100%' }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            {item[0]}
          </Col>
          <Col span={12} style={{ fontWeight: 500, textAlign: 'right' }}>
            {typeof item[1] === 'boolean' ? (item[1] ? 'ON' : 'OFF') : item[1]}
          </Col>
        </Row>
      </List.Item>
    );
  };

  let _dataSource: any = [];
  const period = (
    <>
      {_applicationPlan['validPeriodStartDate']}
      &nbsp;~&nbsp;
      {_applicationPlan['validPeriodEndDate']}
    </>
  );
  const supportLevel = _applicationPlan['supportLevel'];
  const coreFeatures = (
    <>
      <List size="small" dataSource={_applicationPlan.humanizedCoreFeatures} renderItem={renderInfoItem} />
    </>
  );

  let premiumFeaturesDataSource: any = [];
  let jsonPremiumFeatures = _applicationPlan['premiumFeatures']
    ? Object.entries(_applicationPlan['premiumFeatures'])
    : [];
  jsonPremiumFeatures.forEach((item) => {
    if (typeof item[1] === 'boolean' && item[1] === true) {
      premiumFeaturesDataSource.push(item);
      if (item[0] === 'auto_trans') {
        const _autoTransBasic = jsonPremiumFeatures.find((o) => o[0] === 'auto_trans_basic');
        if (_autoTransBasic) {
          premiumFeaturesDataSource.push(['auto_trans_basic', '$' + _autoTransBasic[1]]);
        }
      }
    }
  });

  const premiumFeatures = (
    <>
      <List size="small" dataSource={premiumFeaturesDataSource} renderItem={renderPremiumFeatureItem} />
    </>
  );

  _dataSource = _dataSource.concat([
    {
      key: 'Period',
      value: period,
    },
    {
      key: 'Support Level',
      value: supportLevel,
    },
    {
      key: 'Core Features',
      value: coreFeatures,
    },
    {
      key: 'Premium Features',
      value: premiumFeatures,
    },
  ]);

  return _dataSource;
}

export const ApplicationPlanInfo: React.FC<Props> = ({ applicationPlan }) => {
  const titles = ['Detail', 'Application Plan'];
  const detailBasic = [
    {
      key: '#ID',
      value: applicationPlan.id,
    },
    {
      key: 'Created At',
      value: applicationPlan.createdAt,
    },
    {
      key: 'Is Removed',
      value: <BooleanTag booleanValue={applicationPlan.isRemoved} />,
    },
    // {
    //   key: 'Soda Application ID',
    //   value: applicationPlan.application.id,
    // },
    // {
    //   key: 'APP ID',
    //   value: (
    //     <Link to={`/applications/${applicationPlan.application.appId}/detail/`}>
    //       {applicationPlan.application.appId}
    //     </Link>
    //   ),
    // },
    // {
    //   key: 'APP Name',
    //   value: applicationPlan.application.appName,
    // },
  ];

  const detailPlan = convertDataSourcePlan(applicationPlan);

  const dataSources = [detailBasic, detailPlan];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col key={`-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
          <Card
            title={titles[index]}
            size="small"
            style={{
              minHeight: '410px',
            }}
          >
            <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
