import axiosLib from 'axios';
import {message} from 'antd';
import {gateAuth} from '../utils/auth';

const insightAxios = axiosLib.create();

function goHome() {
  window.location.href = '/';
}

insightAxios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    const errorResponse = (error && error.response) || null;
    const errorStatus = (errorResponse && errorResponse.status) || null;
    const errorMessage =
      (errorResponse && errorResponse.data && errorResponse.data.detail) ||
      null;

    if (errorStatus === 401) {
      gateAuth.signout(goHome);
    }

    if (errorMessage) {
      const errorType = errorStatus.toString().charAt(0);
      if (errorType === '4') {
        message.warning(errorMessage, 5);
      } else if (errorType === '5') {
        message.error(errorMessage, 5);
      }
    } else {
      console.warn('ERROR', errorResponse);
    }

    return Promise.reject(error);
  }
);

export { insightAxios };
