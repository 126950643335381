import React from 'react';
import { Button } from 'antd';
import { deleteUserPermissionRequest } from '../api/user';

type Props = {
  userId: string;
  permissionName: string;
  successCallback: (payload?: any) => void;
};

export const UserPermissionDeleteButton: React.FC<Props> = ({ userId, permissionName, successCallback }) => {
  function onClickDelete() {
    deleteUserPermissionRequest({
      user_id: userId,
      permission_name: permissionName,
    })
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <React.Fragment>
      <Button danger={true} onClick={onClickDelete}>
        Delete
      </Button>
    </React.Fragment>
  );
};
