import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Tag, Input, Layout } from 'antd';

import { TableDetail } from '../../components/ui/TableDetail';
import { PageTitle } from '../../components/ui/PageTitle';
import { PlanTag, RegionTag } from '../../components/ui/Tags';
import { AccountProfileInfo } from '../../components/AccountProfileInfo';

import { getUserStarredCrmApplicationsRequest, getUserPermissionsRequest, getUserProfileRequest } from '../../api/user';

const { Search } = Input;

const starredApplicationColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'App ID',
    dataIndex: 'appId',
    key: 'appId',
    render: (appId: CrmApplication['appId']) => <Link to={`/applications/${appId}/detail/`}>{appId}</Link>,
  },
  {
    title: 'Name',
    dataIndex: 'appName',
    key: 'appName',
    render: (appName: string) => <span style={{ fontWeight: 500 }}>{appName === '' || !appName ? '-' : appName}</span>,
  },
  {
    title: 'Organization',
    dataIndex: 'crmOrganization',
    key: 'crmOrganization',
    render: (crmOrganization: CrmOrganization) =>
      crmOrganization === null ? (
        '-'
      ) : (
        <Link to={`/crm_organizations/${crmOrganization.id}/`}>
          {crmOrganization.id} ({crmOrganization.name})
        </Link>
      ),
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    render: (region: CrmApplication['region']) => <RegionTag region={region} />,
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (plan: CrmApplication['plan']) => (plan ? <PlanTag plan={plan} /> : '-'),
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

const userPermissionColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    key: 'permission',
    render: (permission: Permission) => <Tag>{permission.name}</Tag>,
  },
  {
    title: (
      <span>
        Granted At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: (
      <span>
        Expired At<small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'expiredAt',
    key: 'expiredAt',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserPermission['status']) => (
      <Tag color={status === 'ACTIVE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {status}
      </Tag>
    ),
  },
];

type Props = RouteComponentProps;

export const AccountProfile = withRouter<Props, any>(() => {
  const [user, setUser] = useState({} as InsightMember);

  const [starredApplications, setStarredApplications] = useState([] as Array<CrmApplication>);

  const [loadingStarredApplications, setLoadingStarredApplications] = useState(false);
  const [starredApplicationsCount, setStarredApplicationsCount] = useState(0);

  const [permissions, setPermissions] = useState([] as Array<UserPermission>);

  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [permissionsCount, setPermissionsCount] = useState(0);

  const [favoriteSearchQuery, setFavoriteSearchQuery] = useState('');

  function getUser({ callback }) {
    getUserProfileRequest()
      .then((response) => {
        setUser(response.data);
        callback && callback(response.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserStarredApplications({ userId, offset = 0, limit = 10, query = '' }) {
    setLoadingStarredApplications(true);
    getUserStarredCrmApplicationsRequest({ userId, offset, limit, query })
      .then((response) => {
        setStarredApplications(response.data.results);
        setLoadingStarredApplications(false);
        setStarredApplicationsCount(response.data.count);
      })
      .catch((error) => {
        setLoadingStarredApplications(false);
      });
  }

  function getUserPermissions({ userId, offset = 0, limit = 10 }) {
    setLoadingPermissions(true);
    getUserPermissionsRequest({ userId, offset, limit })
      .then((response) => {
        setPermissions(response.data.results);
        setLoadingPermissions(false);
        setPermissionsCount(response.data.count);
      })
      .catch((error) => {
        setLoadingStarredApplications(false);
      });
  }

  useEffect(() => {
    getUser({
      callback: function (userId) {
        getUserStarredApplications({ userId, offset: 0, limit: 10 });
        getUserPermissions({ userId, offset: 0, limit: 10 });
      },
    });
  }, []);

  function onFavoritePageChange({ offset, limit }) {
    getUserStarredApplications({ userId: user.id, offset, limit });
  }

  function onPermissionPageChange({ offset, limit }) {
    getUserPermissions({ userId: user.id, offset, limit });
  }

  const onFavoriteSearch = (value) => {
    setFavoriteSearchQuery(value);
    getUserStarredApplications({
      userId: user.id,
      offset: 0,
      limit: 10,
      query: value,
    });
  };

  const onFavoriteSearchChange = (e) => {
    setFavoriteSearchQuery(e.target.value);
  };

  function handleOnRow(crmApplication: CrmApplication) {
    return {
      style: {
        textDecoration: crmApplication.removed ? 'line-through' : '',
        opacity: crmApplication.removed ? 0.5 : 1,
      },
    };
  }

  return (
    <Layout>
      <PageTitle title="Profile" subTitle={`#${(user.id && user.username) || ''}`} showBack={true} />
      <Layout.Content>
        <AccountProfileInfo user={user} />
        <TableDetail
          title="Starred Applications"
          count={starredApplicationsCount}
          loading={loadingStarredApplications}
          columns={starredApplicationColumns}
          dataSource={starredApplications}
          onPageChange={onFavoritePageChange}
          onRow={handleOnRow}
          rightColumnComponent={
            <Search
              value={favoriteSearchQuery}
              placeholder="Search applications"
              enterButton={true}
              onSearch={onFavoriteSearch}
              onChange={onFavoriteSearchChange}
            />
          }
        />
        <TableDetail
          title="User Permissions"
          count={permissionsCount}
          loading={loadingPermissions}
          columns={userPermissionColumns}
          dataSource={permissions}
          onPageChange={onPermissionPageChange}
        />
      </Layout.Content>
    </Layout>
  );
});
