import * as React from 'react';
import { Row, Col } from 'antd';
import { InfoCards } from './InfoCard';

type Props = {
  statusPageComponentGroup: StatusPageComponentGroup;
};

export const StatusPageComponentGroupInfo: React.FC<Props> = ({
  statusPageComponentGroup
}) => {
  const titles = ['Detail'];
  const detailBasic = [
    {
      key: 'ID',
      value: statusPageComponentGroup.id
    },
    {
      key: 'StatusPage Id',
      value: statusPageComponentGroup.groupId
    },
    {
      key: 'Name',
      value: statusPageComponentGroup.name,
    },
    {
      key: 'Description',
      value: statusPageComponentGroup.description ? statusPageComponentGroup.description : '-',
    },
    {
      key: 'Created At',
      value: statusPageComponentGroup.createdAt ? statusPageComponentGroup.createdAt : '-',
    },
    {
      key: 'Updated At',
      value: statusPageComponentGroup.updatedAt ? statusPageComponentGroup.updatedAt : '-',
    },
  ];

  const dataSources = [detailBasic];

  return (
    <Row
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 16px 0',
        background: '#f0f2f5',
      }}
    >
      <Col span={24}>
        <InfoCards
          titles={titles}
          dataSources={dataSources}
          minHeight="280px"
        />
      </Col>
    </Row>
  );
};
