import * as React from 'react';
import { useState, useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Select, Button, Modal, DatePicker } from 'antd';
import moment from 'moment-timezone';

import { DynamicForm } from './ui/DynamicForm';
import {
  userDepartmentOptions,
  userTeamTypeOptions,
  userStateOptions,
  userLocationOptions,
  UserStateEnum,
  UserTeamTypeEnum,
  UserDepartmentEnum,
  UserLocationEnum,
} from '../utils/constants/users';
import { dateTimePattern, getBrowserTimeZoneString } from '../utils/date';
import { updateUserRequest } from '../api/user';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;

const fields = (user: InsightMember): any => [
  {
    name: 'teamType',
    label: 'Team Type',
    formItemProps: {
      initialValue: user.teamType ? user.teamType : UserTeamTypeEnum.RESEARCH_AND_DEVELOPMENT,
      rules: [{ required: true, message: 'Please select a team type' }],
    },
    span: 12,
    component: (
      <Select size="large">
        {userTeamTypeOptions.map(({ value, label }) => (
          <Option key={`teamType-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'department',
    label: 'Department',
    formItemProps: {
      initialValue: user.department ? user.department : UserDepartmentEnum.DESIGN,
      rules: [{ required: true, message: 'Please select a department' }],
    },
    span: 12,
    component: (
      <Select size="large">
        {userDepartmentOptions.map(({ value, label }) => (
          <Option key={`department-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'state',
    label: 'State',
    formItemProps: {
      initialValue: user.state ? user.state : UserStateEnum.ACTIVE,
      rules: [{ required: true, message: 'Please select the user state' }],
    },
    span: 12,
    component: (
      <Select size="large">
        {userStateOptions.map(({ value, label }) => (
          <Option key={`state-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'companyLeftDate',
    label: 'Company Left Date',
    formItemProps: {
      initialValue: user.companyLeftDate ? moment.tz(user.companyLeftDate, getBrowserTimeZoneString()) : null,
      rules: [
        {
          validator: (_, value, callback) => {
            callback();
          },
        },
      ],
    },
    span: 12,
    component: <DatePicker size="large" />,
  },
  {
    name: 'location',
    label: 'Location',
    formItemProps: {
      initialValue: user.location ? user.location : UserLocationEnum.US,
      rules: [{ required: true, message: 'Please select a Location' }],
    },
    span: 12,
    component: (
      <Select size="large">
        {userLocationOptions.map(({ value, label }) => (
          <Option key={`department-${value}}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    ),
  },
];

type Props = {
  user: InsightMember;
  successCallback: (payload?: any) => void;
};

export const MemberEditDialog: React.FC<Props> = ({ user, successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    formRef.current?.resetFields();
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          if (values['companyLeftDate']) {
            values['companyLeftDate'] = values['companyLeftDate'].format(dateTimePattern.date);
          }

          updateUserRequest({ user, values })
            .then((response) => {
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });

          console.log(values);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button type="primary" icon={<EditOutlined />} onClick={showModal} style={{ marginLeft: '10px' }}>
        Edit
      </Button>
      <Modal
        open={visible}
        title="Edit a member"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Edit
          </Button>,
        ]}
      >
        <DynamicForm fields={fields(user)} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
