import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import randomColor from 'randomcolor/randomColor';
import moment from 'moment-timezone';

import { PieChartComponent } from './PieChartComponent';
import { TwoBarChartComponent } from './TwoBarChartComponent';
import { BarChartComponent } from './BarChartComponent';
import { ResponsiveTable } from './ui/ResponsiveTable';

import { useCheckMobile } from '../utils/screen';
import {
  getCrmApplicationDailyRecordRegionDauCountsRequest,
  getCrmApplicationDailyRecordRegionMauCountsRequest,
} from '../api/application';

const { Title, Paragraph } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function createColumns({ dataSource, isMobile }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: isMobile ? false : 'left',
      width: '100px',
    },
  ];

  dataSource.forEach((data, columnIndex) => {
    columns.push({
      title: data.region,
      dataIndex: `count-${columnIndex}`,
      key: `count-${columnIndex}`,
      align: alignRight,
      render: (value: number) => value && value.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSource, title }) {
  const keys = dataSource.length > 0 ? Object.keys(dataSource[0]) : [];
  let source = [] as any;

  keys.map((key) => {
    const row = {};
    if (key !== 'region') {
      row['key'] = key;
      row['title'] = title;

      dataSource.forEach((data, index) => {
        row[`count-${index}`] = data[key];
      });
      source.push(row);
    }
  });

  return source;
}

const TitlesDau = {
  lastMonthYesterday: 'Last Month DAU',
  yesterday: 'Yesterday DAU',
  growthRate: 'Growth Rate(%)',
};

export const DashboardRegionUsers = React.memo(() => {
  const isMobile = useCheckMobile();
  const now = new Date();
  const defaultDate = new Date();
  defaultDate.setMonth(now.getUTCMonth());

  const [dauPieChartColor, setDauPieChartColor] = useState([]);
  const [dau, setDau] = useState<ApplicationDauByRegion>({
    lastMonthYesterday: [],
    yesterday: [],
    growthRate: [],
  });
  const [mau, setMau] = useState<Array<ApplicationDayStatByRegion>>([]);

  const [dauLoading, setDauLoading] = useState<boolean>(false);
  const [mauLoading, setMauLoading] = useState<boolean>(false);

  function getCrmApplicationDailyRecordRegionDauCounts() {
    setDauLoading(true);
    getCrmApplicationDailyRecordRegionDauCountsRequest()
      .then((response) => {
        setDau(response.data);
        setDauPieChartColor(
          randomColor({
            luminosity: 'light',
            seed: response.data.yesterday.length,
            count: response.data.yesterday.length,
          }),
        );
        setDauLoading(false);
      })
      .catch((error) => {
        setDauLoading(false);
        error && console.error(error.response);
      });
  }

  function getCrmApplicationDailyRecordRegionMauCounts(target_month = moment().format('YYYY-MM')) {
    setMauLoading(true);
    getCrmApplicationDailyRecordRegionMauCountsRequest(target_month)
      .then((response) => {
        setMau(response.data);
        setMauLoading(false);
      })
      .catch((error) => {
        setMauLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getCrmApplicationDailyRecordRegionDauCounts();
    getCrmApplicationDailyRecordRegionMauCounts();
  }, []);

  const dauColumns = createColumns({
    dataSource: dau.yesterday,
    isMobile,
  });

  const lastMonthDauSources = convertDataSource({
    dataSource: dau.lastMonthYesterday,
    title: TitlesDau.lastMonthYesterday,
  });

  const yesterdayDauSources = convertDataSource({
    dataSource: dau.yesterday,
    title: TitlesDau.yesterday,
  });

  const growthRateSources = convertDataSource({
    dataSource: dau.growthRate,
    title: TitlesDau.growthRate,
  });

  const dauSource = [...lastMonthDauSources, ...yesterdayDauSources, ...growthRateSources];

  const mauColumns = createColumns({
    dataSource: mau,
    isMobile,
  });

  const mauSource = convertDataSource({
    dataSource: mau,
    title: 'Count',
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Active user statistics" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Card>
                <Title level={4}>Active users - Daily</Title>
                <Paragraph>The Yesterday DAU for Sendbird's Regions.</Paragraph>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <PieChartComponent
                      title="DAU - Yesterday"
                      labels={dau.yesterday.map((o) => o.region)}
                      data={dau.yesterday.map((o) => o.count)}
                      backgroundColor={dauPieChartColor}
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <TwoBarChartComponent
                      title="DAU - Comparison"
                      labels={dau.lastMonthYesterday.map((o) => o.region)}
                      label1="Last Month Yesterday DAU"
                      label2={'This Month Yesterday DAU'}
                      data1={dau.lastMonthYesterday.map((o) => o.count)}
                      data2={dau.yesterday.map((o) => o.count)}
                    />
                  </Col>
                </Row>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dauLoading}
                  columns={dauColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={dauSource}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
                <Title level={4} style={{ marginTop: 48 }}>
                  Active users - Monthly
                </Title>
                <Paragraph>The overall MAU for Sendbird's applications.</Paragraph>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <PieChartComponent
                      title="MAU - Yesterday"
                      labels={mau.map((o) => o.region)}
                      data={mau.map((o) => o.count)}
                      backgroundColor={dauPieChartColor}
                    />
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <BarChartComponent
                      title="MAU"
                      labels={mau.map((o) => o.region)}
                      label="MAU"
                      data={mau.map((o) => o.count)}
                    />
                  </Col>
                </Row>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={mauLoading}
                  columns={mauColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={mauSource}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
