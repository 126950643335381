import * as React from 'react';
import moment from 'moment-timezone'
import { Link } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import { InfoCards } from './InfoCard';
import { EmergencyTag } from './ui/Tags';
import { DatabaseOtpGenerateDialog } from './DatabaseOtpGenerateDialog';
import { DatabaseOtpApproveDialog } from './DatabaseOtpApproveDialog';
import { DatabaseOtpPostApproveDialog } from './DatabaseOtpPostApproveDialog';

const { Text } = Typography;

type Props = {
  databaseOtp: DatabaseOtp;
};

export const DatabaseOtpDetailInfo: React.FC<Props> = ({ databaseOtp }) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'ID',
      value: databaseOtp.id,
    },
    {
      key: 'requester',
      value: databaseOtp.requester ? (
        <Link to={`/users/${databaseOtp.requester.id}`}>{databaseOtp.requester.username}</Link>
      ) : (
        '-'
      ),
    },
    {
      key: 'approver',
      value: databaseOtp.approver ? (
        <Link to={`/users/${databaseOtp.approver.id}`}>{databaseOtp.approver.username}</Link>
      ) : (databaseOtp.status === 'OTP_GENERATED'
          || databaseOtp.status === 'APPROVAL_PASSED'
          || databaseOtp.status === 'OTP_USED') && (moment(databaseOtp.createdAt).utc().isAfter('2021-11-10 23:59:59Z'))
          ? (<DatabaseOtpPostApproveDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />)
          : '-',
    },
    {
      key: 'status',
      value: databaseOtp.status,
    },
    {
      key: 'memo',
      value: databaseOtp.memo,
    },
    {
      key: 'reason',
      value: databaseOtp.reason,
    },
    {
      key: 'Link',
      value: databaseOtp.infoLink ? (
        <a target="_blank" href={`${databaseOtp.infoLink}`}>
          Link
        </a>
      ) : (
        '-'
      ),
    },
  ];
  const detailAdvanced = [
    {
      key: 'isEmergency',
      value: <EmergencyTag isEmergency={databaseOtp.isEmergency} />,
    },
    {
      key: 'createdAt',
      value: databaseOtp.createdAt,
    },
    {
      key: 'approvedAt',
      value: databaseOtp.approvedAt ? (
        databaseOtp.approvedAt
      ) : (
        <DatabaseOtpApproveDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />
      ),
    },
    {
      key: 'otpGeneratedAt',
      value: databaseOtp.approvedAt ? (
        databaseOtp.otpGeneratedAt ? (
          databaseOtp.otpGeneratedAt
        ) : (
          <DatabaseOtpGenerateDialog databaseOtp={databaseOtp} successCallback={dialogSuccessCallback} />
        )
      ) : (
        '-'
      ),
    },
    {
      key: 'otpUsedAt',
      value: databaseOtp.otpUsedAt,
    },
    {
      key: 'dbName',
      value: (
        <Text
          copyable={true}
          style={{
            margin: 0,
            wordBreak: 'break-word',
          }}
        >
          {databaseOtp.dbName}
        </Text>
      ),
    },
    {
      key: 'dbHostName',
      value: (
        <Text
          copyable={true}
          style={{
            margin: 0,
            wordBreak: 'break-word',
          }}
        >
          {databaseOtp.dbHostName}
        </Text>
      ),
    },
    {
      key: 'dbUserName',
      value: (
        <Text
          copyable={true}
          style={{
            margin: 0,
            wordBreak: 'break-word',
          }}
        >
          {databaseOtp.dbUserName}
        </Text>
      ),
    },
    {
      key: 'password',
      value: (
        <Text
          copyable={true}
          style={{
            margin: 0,
            wordBreak: 'break-word',
          }}
        >
          {databaseOtp.password}
        </Text>
      ),
    },
  ];

  function dialogSuccessCallback() {
    window.location.reload();
  }

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 16px 0',
        background: '#f0f2f5',
      }}
    >
      <Col span={24}>
        <InfoCards titles={titles} dataSources={dataSources} minHeight="280px" />
      </Col>
    </Row>
  );
};
