import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Col, Row, Typography, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ResponsiveTable } from '../components/ui/ResponsiveTable';

import { dateTimePattern, getSearchDate } from '../utils/date';
import { getUserDailyCountsRequest } from '../api/user';
import { LineChartComponent } from './LineChartComponent';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const alignRight = 'right' as ColumnProps<any>['align'];
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'New',
    dataIndex: 'new',
    key: 'new',
    align: alignRight,
    render: (
      newCount: DashboardUserCount['new'],
      userCount: DashboardUserCount,
    ) => (
      <Link
        to={`/crm_users/?startDate=${userCount.date}&endDate=${userCount.date}`}
      >
        {newCount.toLocaleString()}
      </Link>
    ),
  },
  {
    title: 'Cumulative',
    dataIndex: 'cumulative',
    key: 'cumulative',
    align: alignRight,
    render: (culumative: DashboardUserCount['cumulative']) =>
      culumative ? culumative.toLocaleString() : '0',
  },
];

type Props = RouteComponentProps;

export const UserCounts: React.FC<Props> = () => {
  const searchDate = getSearchDate({ days: 14 });
  const [userCounts, setUserCounts] = useState<Array<DashboardUserCount>>([]);
  const [loading, setLoading] = useState(false);

  function getUserDailyCounts({ startDate, endDate }) {
    setLoading(true);
    getUserDailyCountsRequest({ startDate, endDate })
      .then(response => {
        setUserCounts(response.data.results);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        error && error.response && console.error(error.response);
      });
  }

  useEffect(function() {
    getUserDailyCounts({
      startDate: searchDate.startDate,
      endDate: searchDate.endDate,
    });
  }, []);

  function handleCalendarChange(_, dateStrings) {
    const [startDateString, endDateString] = dateStrings;
    getUserDailyCounts({
      startDate: startDateString,
      endDate: endDateString,
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  return (
    <React.Fragment>
      <Row gutter={8} style={{ marginBottom: '8px' }}>
        <Col span={24}>
          <Title level={3}>Signup users</Title>
        </Col>
        <Col span={24} style={{ marginBottom: 8 }}>
          <RangePicker
            onChange={handleCalendarChange}
            defaultValue={[
              moment(searchDate.startDate),
              moment(searchDate.endDate),
            ]}
            disabledDate={disabledDate}
            format={dateTimePattern.date}
            style={{ maxWidth: 300, width: '100%' }}
          />
        </Col>
        <Col span={24} md={12}>
          <LineChartComponent
            title="Signup New Users - Daily"
            unit="day"
            labels={userCounts.map(user => user.date)}
            data1={userCounts.map(user => user.new)}
          />
        </Col>
        <Col span={24} md={12}>
          <LineChartComponent
            title="Signup Cumulative Users - Daily"
            unit="day"
            labels={userCounts.map(user => user.date)}
            data1={userCounts.map(user => user.cumulative)}
          />
        </Col>
      </Row>

      <ResponsiveTable
        loading={loading}
        columns={columns}
        dataSource={userCounts}
        pagination={{
          total: userCounts.length,
          pageSizeOptions: ['30', '50', '100'],
        }}
      />
    </React.Fragment>
  );
};
