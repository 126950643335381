import React from 'react';
import axios from 'axios'
import {API_DOMAIN} from "../App"

class Oauth2CallbackFB extends React.Component {
  constructor(props) {
    super(props)
    const accessToken = window.location.hash.split('#access_token=')[1].split('&')[0]
    console.log('access_token : ', accessToken)

    this.url = `${API_DOMAIN}/api/authentications/oauth2_facebook/?` +
      "&access_token=" + accessToken +
      "&grant_type=fb_exchange_token" +
      "&client_id=682361665451451" +
      "&client_secret=9f13bf03bc64fcd10ff808505fd355ab" +
      "&fb_exchange_token=" + accessToken;
  }

  render() {
    console.log(this.url)
    axios.post(this.url, {
    }).then((response) => {
      console.log('Result: ', response.data)
    }).catch((error) => {
      console.log(error)
    })

    return ('Callback short lived')
  }
}

export default Oauth2CallbackFB
