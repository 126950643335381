import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row, Typography, Input, Select } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getStatusPageIncidentsRequest, getStatusPageIncidentsFiltersRequest } from '../../api/statusPageIncidents';
import queryString from 'query-string';
import { TableFilter } from '../../components/ui/TableFilter';

const { Title, Paragraph } = Typography;
const { Option } = Select;
const { Search } = Input;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
    render: (id: StatusPageIncident['id']) => <Link to={`/status_page_incidents/${id}/`}>{id}</Link>,
  },
  {
    title: 'Incident Id',
    dataIndex: 'incidentId',
    key: 'incidentId',
    width: 90,
  },
  {
    title: 'Component Group(Region)',
    dataIndex: 'componentGroup',
    key: 'componentGroup',
    width: 50,
    render: (group: StatusPageIncident['componentGroup']) =>
      group ? <Link to={`/status_page_component_groups/${group.id}/`}>{group.name}</Link> : '-',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
  },
  {
    title: 'Impact',
    dataIndex: 'impact',
    key: 'impact',
    width: 100,
  },
  {
    title: 'Short Link',
    dataIndex: 'shortLink',
    key: 'shortLink',
    width: 130,
    render: (shortLink: StatusPageIncident['shortLink']) => (
      <a href={shortLink} target="new">
        {shortLink}
      </a>
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
  },
  {
    title: 'Resolved At',
    dataIndex: 'resolvedAt',
    key: 'resolvedAt',
    width: 100,
  },
  {
    title: 'Downtime',
    dataIndex: 'downtime',
    key: 'downtime',
    width: 100,
    render: (downtime: StatusPageIncident['downtime']) => (downtime ? `${downtime} sec` : '-'),
  },
];

type Props = RouteComponentProps;

export const StatusPageIncidents = withRouter<Props, any>(({ location, history }) => {
  const [componentGroups, setComponentGroups] = useState<Array<StatusPageIncident>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [regions, setRegions] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>([]);
  const [impacts, setImpacts] = useState<any>([]);

  const qs = queryString.parse(location.search);
  let region = qs.region ? (qs.region as string) : '';
  let status = qs.status ? (qs.status as string) : '';
  let impact = qs.impact ? (qs.impact as string) : '';
  let limit = parseInt(qs.limit ? (qs.limit as string) : '30');
  let offset = parseInt(qs.offset ? (qs.offset as string) : '0');
  let q = qs.q ? (qs.q as string) : '';

  useEffect(() => {
    getStatusPageIncidentsFilters();
    getStatusPageIncidents();
  }, [location]);

  const getStatusPageIncidents = () => {
    setLoading(true);
    getStatusPageIncidentsRequest({
      offset,
      limit,
      q,
      region,
      status,
      impact,
    })
      .then((response) => {
        setComponentGroups(response.data.results);
        setLoading(false);
        setCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getStatusPageIncidentsFilters = () => {
    getStatusPageIncidentsFiltersRequest()
      .then((response) => {
        setRegions(['All'].concat(response.data['regions']));
        setStatuses(['All'].concat(response.data['statuses']));
        setImpacts(['All'].concat(response.data['impacts']));
      })
      .catch((error) => {});
  };

  const onPageChange = (page, pageSize) => {
    limit = pageSize;
    offset = (page - 1) * pageSize;
    getStatusPageIncidents();
  };

  const onSearch = (value) => {
    q = value;
    offset = 0;
    replacePath();
  };

  const onRegionSelect = (value) => {
    if (value === 'All') value = '';
    region = value;
    offset = 0;
    replacePath();
  };

  const onStatusSelect = (value) => {
    if (value === 'All') value = '';
    status = value;
    offset = 0;
    replacePath();
  };

  const onImpactSelect = (value) => {
    if (value === 'All') value = '';
    impact = value;
    offset = 0;
    replacePath();
  };

  const onSearchChange = (e) => {
    q = e.target.value;
  };

  function replacePath() {
    history.replace({
      pathname: '/status_page_incidents/',
      search: `?q=${q}&limit=${limit}&offset=${offset}&region=${region}&status=${status}&impact=${impact}`,
    });
  }

  return (
    <React.Fragment>
      <Row gutter={16} style={{ marginBottom: '8px' }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
          <Title level={3}>StatusPage Incident List</Title>
          <Paragraph>
            <ul>
              <li>Data source: StatusPage</li>
              <li>Incident which have Maintenance as name is for test.(so, it's not real incident.)</li>
            </ul>
          </Paragraph>
        </Col>
      </Row>

      <TableFilter
        selects={[
          {
            label: 'Region',
            key: 'region',
            node: (
              <Select defaultValue={'All'} onSelect={onRegionSelect} placeholder="AWS Region" style={{ width: '100%' }}>
                {regions.map((region) => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            label: 'Status',
            key: 'status',
            node: (
              <Select
                defaultValue={'All'}
                onSelect={onStatusSelect}
                placeholder="Sendbird Region"
                style={{ width: '100%' }}
              >
                {statuses.map((status) => (
                  <Option key={status} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            label: 'Impact',
            key: 'status',
            node: (
              <Select
                defaultValue={'All'}
                onSelect={onImpactSelect}
                placeholder="Purchase Type"
                style={{ width: '100%' }}
              >
                {impacts.map((impact) => (
                  <Option key={impact} value={impact}>
                    {impact}
                  </Option>
                ))}
              </Select>
            ),
          },
          {
            label: 'Search',
            key: 'search',
            node: (
              <Search
                placeholder="Search by name or region name"
                enterButton={true}
                onSearch={onSearch}
                onChange={onSearchChange}
              />
            ),
          },
        ]}
      />

      <ResponsiveTable
        scroll={{ x: 1770 }}
        loading={loading}
        columns={columns}
        dataSource={componentGroups}
        pagination={{
          total: count,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
        }}
      />
    </React.Fragment>
  );
});
