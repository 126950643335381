import * as React from 'react';
import styled from 'styled-components';
import {FilterOutlined} from '@ant-design/icons';

type Props = {
  selects: {
    label: string
    key: string
    node: React.ReactNode
  }[];
};

const Container = styled.div`
  margin-bottom: 10px;
`;

const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  flex: 1;

  .SelectContainer__select-wrapper {
    > * {
      width: 100%;
    }
  }

  .SelectContainer__label {
    margin-bottom: 8px;
  }
`;

export const TableFilter: React.FC<Props> = ({
  selects,
}) => {
  return (
    <Container>
      <FilterOutlined style={{ marginRight: 8, marginTop: 4 }} />
      <SelectContainer>
        {selects.map(({ label, node, key }) => (
          <label className="SelectContainer__select-wrapper" key={key}>
            <div className="SelectContainer__label">{label}</div>
            {node}
          </label>
        ))}
      </SelectContainer>
    </Container>
  );
};
