import React, { useState, useEffect } from 'react';
import { TableDetail } from '../ui/TableDetail';
// Deprecated 2024-04-15 @mike.oh
// import { OrganizationPlanRequestCreateDialog } from './OrganizationPlanRequestCreateDialog';
// import { OrganizationPlanRequestViewDialog } from './OrganizationPlanRequestViewDialog';

import { CrmOrganizationSubscriptions as Subscriptions } from './CrmOrganizationSubscriptions';
import { CrmOrganizationPayments as Payments } from './CrmOrganizationPayments';
import { getOrganizationPlanRequestRequest } from '../../api/organization';

export const OrganizationPlans = ({ crmOrganization, crmOrganizationId }) => {
  const [loadingOrganizationPlanRequests, setLoadingOrganizationPlanRequests] = useState(false);
  const [organizationPlanRequestsCount, setOrganizationPlanRequestsCount] = useState(0);
  const [organizationPlanRequests, setOrganizationPlanRequests] = useState([]);

  const refresh = () => {
    window.location.reload();
  };

  const organizationPlanRequestsColumns = [
    {
      title: 'Request ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Created At',
      dataIndex: 'createdDt',
      key: 'createdDt',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedDt',
      key: 'updatedDt',
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
    },
    {
      title: 'Reviewer',
      dataIndex: 'reviewer',
      key: 'reviewer',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => {
        return <div style={{ wordBreak: 'break-all' }}>{reason}</div>;
      },
    },
    // Deprecated 2024-04-15 @mike.oh
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: '',
    //   render: (organizationPlanRequest: OrganizationPlanRequest) => (
    //     <OrganizationPlanRequestViewDialog
    //       crmOrganizationId={crmOrganizationId}
    //       organizationPlanRequest={organizationPlanRequest}
    //       successCallback={refresh}
    //     />
    //   ),
    // },
  ];

  const getOrganizationPlanRequests = ({ offset = 0, limit = 0 }) => {
    setLoadingOrganizationPlanRequests(true);
    getOrganizationPlanRequestRequest({ limit, offset, crmOrganizationId })
      .then((response) => {
        setOrganizationPlanRequests(response.data);
        setLoadingOrganizationPlanRequests(false);
        setOrganizationPlanRequestsCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
        setLoadingOrganizationPlanRequests(false);
      });
  };

  useEffect(() => {
    getOrganizationPlanRequests({ offset: 0, limit: 5 });
  }, []);

  return (
    <>
      <TableDetail
        title="Organization Plan Request"
        count={organizationPlanRequestsCount}
        loading={loadingOrganizationPlanRequests}
        columns={organizationPlanRequestsColumns}
        dataSource={organizationPlanRequests}
        onPageChange={getOrganizationPlanRequests}
        pageSizeOptions={['5']}
        // Deprecated 2024-04-15 @mike.oh
        // rightColumnComponent={
        //   <OrganizationPlanRequestCreateDialog crmOrganization={crmOrganization} successCallback={refresh} />
        // }
      />
      <Subscriptions organizationId={parseInt(crmOrganizationId)} crmOrganization={crmOrganization} />
      <Payments organizationId={parseInt(crmOrganizationId)} crmOrganization={crmOrganization} />
    </>
  );
};
