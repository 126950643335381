import { DownOutlined } from '@ant-design/icons';
import { Select, Button, Popover, Radio } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SortKey } from '../sortKeys';
import { WithLabel } from './WithLabel';

interface SortByOptions {
  order: 'ascending' | 'descending';
  column: 'mau';
}

const orderLabels = {
  ascending: 'Ascending',
  descending: 'Descending',
};

const sortKeyLabels: Record<SortKey, string> = {
  mau: 'MAU',
  dau: 'DAU',
  diff_dau: 'DAU Change',
  fluctuation_dau: 'DAU % Change',
  week_diff_dau: 'DAU Change over week',
  week_fluctuation_dau: 'DAU % Change over week',
  daily_message_count: '# Messages',
  diff_daily_message_count: '# Messages Change',
  fluctuation_daily_message_count: '# Messages % Change',
  week_diff_daily_message_count: '# Messages Change over week',
  week_fluctuation_daily_message_count: '# Messages % Change over week',
  daily_peak_connection: 'Peak Connections',
  diff_daily_peak_connection: 'Peak Connection Change',
  fluctuation_daily_peak_connection: 'Peak Connection % Change',
  week_diff_daily_peak_connection: 'Peak Connection Change over week',
  week_fluctuation_daily_peak_connection: 'Peak Connection % Change over week',
  messaged_user_count: '# Messaged Users',
  diff_messaged_user_count: '# Messaged Users Change',
  fluctuation_messaged_user_count: '# Messaged Users % Change',
  week_diff_messaged_user_count: '# Messaged Users Change over week',
  week_fluctuation_messaged_user_count: '# Messaged Users % Change over week',
};

const getOptionGroupItemLabel = (value: string) => {
  if (value.startsWith('diff_')) {
    return 'Change';
  }
  if (value.startsWith('fluctuation_')) {
    return '% Change';
  }
  if (value.startsWith('week_diff_')) {
    return 'Change over week';
  }
  if (value.startsWith('week_fluctuation_')) {
    return '% Change over week';
  }
  return 'Last';
};

const { Option, OptGroup } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .sort-column-row {
    width: 100%;

    .ant-space-item > * {
      width: 100%;
    }
  }
`;

export const SortByButton = ({
  order: defaultOrder,
  column: defaultColumn,
  onApply,
}: {
  order: 'ascending' | 'descending';
  column: 'mau';
  onApply: (options: SortByOptions) => void;
}) => {
  const [column, setColumn] = useState(defaultColumn);
  const [order, setOrder] = useState(defaultOrder);
  const [isOpen, setIsOpen] = useState(false);
  const label = `${defaultOrder === 'ascending' ? 'Ascending' : 'Descending'} ${
    sortKeyLabels[defaultColumn] || defaultColumn
  }`;

  return (
    <Popover
      content={
        <Container>
          <WithLabel label="Column" htmlFor="sort-column" className="sort-column-row">
            <Select
              id="sort-column"
              value={column}
              onChange={(value) => {
                setColumn(value);
              }}
              optionLabelProp="label"
            >
              <Option value="mau" label={sortKeyLabels.mau}>
                MAU
              </Option>

              <OptGroup label="DAU">
                {['dau', 'diff_dau', 'fluctuation_dau', 'week_diff_dau', 'week_fluctuation_dau'].map((value) => (
                  <Option value={value} label={sortKeyLabels[value]}>
                    {getOptionGroupItemLabel(value)}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label="Messages">
                {[
                  'daily_message_count',
                  'diff_daily_message_count',
                  'fluctuation_daily_message_count',
                  'week_diff_daily_message_count',
                  'week_fluctuation_daily_message_count',
                ].map((value) => (
                  <Option value={value} label={sortKeyLabels[value]}>
                    {getOptionGroupItemLabel(value)}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label="Peak connections">
                {[
                  'daily_peak_connection',
                  'diff_daily_peak_connection',
                  'fluctuation_daily_peak_connection',
                  'week_diff_daily_peak_connection',
                  'week_fluctuation_daily_peak_connection',
                ].map((value) => (
                  <Option value={value} label={sortKeyLabels[value]}>
                    {getOptionGroupItemLabel(value)}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label="Messaged users">
                {[
                  'messaged_user_count',
                  'diff_messaged_user_count',
                  'fluctuation_messaged_user_count',
                  'week_diff_messaged_user_count',
                  'week_fluctuation_messaged_user_count',
                ].map((value) => (
                  <Option value={value} label={sortKeyLabels[value]}>
                    {getOptionGroupItemLabel(value)}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </WithLabel>
          <Radio.Group
            value={order}
            onChange={(event) => {
              setOrder(event.target.value);
            }}
            style={{ width: '100%' }}
          >
            {Object.keys(orderLabels).map((value) => (
              <Radio.Button key={value} value={value}>
                {orderLabels[value]}
              </Radio.Button>
            ))}
          </Radio.Group>
          <Button
            type="primary"
            style={{ alignSelf: 'flex-end', marginTop: 4 }}
            onClick={() => {
              onApply({ column, order });
            }}
          >
            Apply
          </Button>
        </Container>
      }
      trigger="click"
      placement="bottomRight"
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Button type="default">
        {label}
        <DownOutlined />
      </Button>
    </Popover>
  );
};
