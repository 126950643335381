import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getChannelRequest } from '../../api/application';
import { ChannelInfo } from '../../components/ChannelInfo';

import { PageTitle } from '../../components/ui/PageTitle';

interface MatchParams {
  appId: string;
  channelId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const Channel = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { appId, channelId } = match.params;
    const [channel, setChannel] = useState({} as ApplicationChannel);

    function getChannel() {
      getChannelRequest({ appId, channelId })
        .then((response) => {
          setChannel(response.data);
        })
        .catch(() => {});
    }

    useEffect(() => {
      getChannel();
    }, [location]);

    return (
      <React.Fragment>
        <PageTitle
          title="Channel Detail"
          subTitle={`#${(channel.id && channel.id.toString()) || ''}`}
          showBack={true}
        />
        <ChannelInfo channel={channel} />
      </React.Fragment>
    );
  }),
);
