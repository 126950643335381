import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { grey } from '@ant-design/colors';

import Chart, { ChartConfiguration } from 'chart.js';

const options: ChartConfiguration = {
  type: 'line',
  options: {
    tooltips: { enabled: false },
    scales: { xAxes: [{ display: false, type: 'time' }], yAxes: [{ display: false }] },
    legend: { display: false },
    responsive: false,
    aspectRatio: 1,
    elements: {
      line: { tension: 0, backgroundColor: 'transparent', borderColor: grey[2], borderWidth: 1 },
      point: { radius: 0 },
    },
    animation: { duration: 0 },
    hover: { animationDuration: 0 },
    responsiveAnimationDuration: 0,
  },
};

export const RowChart = ({
  data,
  id,
  ...props
}: { data: { x: string; y: number }[]; id: string } & HTMLAttributes<HTMLCanvasElement>) => {
  const containerRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    new Chart(containerRef.current!, { ...options, data: { datasets: [{ data }] } });
  }, []);
  return <canvas id={id} ref={containerRef} width="32" height="32" {...props} />;
};
