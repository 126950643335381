import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Select, Layout } from 'antd';
import queryString from 'query-string';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getCrmPaymentsRequest } from '../../api/payment';
import {
  PaymentTypeTag,
  PaymentMethodTag,
  PaymentStatusTag,
} from '../../components/ui/Tags';
import { TableFilter } from '../../components/ui/TableFilter';
import { PageTitle } from '../../components/ui/PageTitle';

const { Option } = Select;

const paymentTypes = [
  { key: 'ALL', value: '' },
  { key: 'SUBSCRIPTION', value: 'PAYMENT_ITEM_TYPE_SUBSCRIPTION' },
  { key: 'EXTRA', value: 'PAYMENT_ITEM_TYPE_EXTRA' },
  { key: 'ONETIME', value: 'PAYMENT_ITEM_TYPE_ONETIME' },
];

const methods = [
  { key: 'ALL', value: '' },
  { key: 'CARD', value: 'PAYMENT_ITEM_PAYMENT_METHOD_CARD' },
  { key: 'WIRE', value: 'PAYMENT_ITEM_PAYMENT_METHOD_WIRE' },
];

const statuses = [
  { key: 'ALL', value: '' },
  { key: 'CHARGED', value: 'PAYMENT_ITEM_STATUS_CHARGED' },
  { key: 'QUEUED', value: 'PAYMENT_ITEM_STATUS_QUEUED' },
  { key: 'FAILED', value: 'PAYMENT_ITEM_STATUS_FAILED' },
  { key: 'DISABLED', value: 'PAYMENT_ITEM_STATUS_DISABLED' },
];

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: CrmPayment['id']) => (
      <Link to={`/crm_payments/${id}/`}>{id}</Link>
    ),
  },
  {
    title: 'App ID',
    dataIndex: 'crmApplication',
    key: 'appId',
    render: (application: CrmApplication) =>
      !application ? (
        ''
      ) : (
        <Link to={`/applications/${application.appId}/detail/`}>
          {application.appId.split('-')[0]}...
        </Link>
      ),
  },
  {
    title: 'Organization',
    dataIndex: 'crmApplication',
    key: 'Organization',
    render: (application: CrmApplication) =>
      !application ? (
        ''
      ) : !application.crmOrganization ? (
        ''
      ) : (
        <Link to={`/crm_organizations/${application.crmOrganization.id}/`}>
          {application.crmOrganization.id} ({application.crmOrganization.name})
        </Link>
      ),
  },
  {
    title: 'AE',
    dataIndex: 'crmApplication',
    key: 'AE',
    render: (application: CrmApplication) =>
      !(application && application.noteAccountLead) ? (
        ''
      ) : (
        <span>{application.noteAccountLead.username}</span>
      ),
  },
  {
    title: 'Subscription',
    dataIndex: 'crmSubscription',
    key: 'crmSubscription',
    render: (crmSubscription: CrmPayment['crmSubscription']) => (
      <Link to={`/crm_subscriptions/${crmSubscription}/`}>
        {crmSubscription}
      </Link>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: CrmPayment['type']) => <PaymentTypeTag type={type} />,
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (paymentMethod: CrmPayment['paymentMethod']) => (
      <PaymentMethodTag paymentMethod={paymentMethod} />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmPayment['status']) => (
      <PaymentStatusTag status={status} />
    ),
  },
  {
    title: 'Fee (Product/Service)',
    key: 'plan',
    render: (crmPayment: CrmPayment) => (
      <span>{`$${crmPayment.productFee.toLocaleString()} / $${crmPayment.serviceFee.toLocaleString()}`}</span>
    ),
  },
  {
    title: 'Start Date',
    dataIndex: 'serviceStartDt',
    key: 'serviceStartDt',
  },
  {
    title: 'End Date',
    dataIndex: 'serviceEndDt',
    key: 'serviceEndDt',
  },
  {
    title: 'Charged Date',
    dataIndex: 'chargedDt',
    key: 'chargedDt',
    render: (chargedDt: CrmPayment['chargedDt']) =>
      !chargedDt ? '' : <span>{chargedDt.split('T')[0]}</span>,
  },
];

type Props = RouteComponentProps;

export const CrmPayments = withRouter<Props, any>(({ location, history }) => {
  const [crmPayments, setCrmPayment] = useState<Array<CrmPayment>>([]);
  const [loading, setLoading] = useState(false);
  const [crmPaymentsCount, setCrmPaymentsCount] = useState(0);
  const qs = queryString.parse(location.search);
  const [query, setQuery] = useState({
    limit: qs.limit ? parseInt(qs.limit as string) : 30,
    offset: qs.offset ? parseInt(qs.offset as string) : 0,
    type: qs.query ? (qs.type as string) : '',
    method: qs.region ? (qs.method as string) : '',
    status: qs.plan ? (qs.status as string) : '',
  });

  useEffect(() => {
    history.replace({
      pathname: '/crm_payments/',
      search: queryString.stringify(query),
    });
    getCrmPayments();
  }, [query]);

  const getCrmPayments = () => {
    setLoading(true);
    getCrmPaymentsRequest({ ...query })
      .then((response) => {
        setCrmPayment(response.data.results);
        setLoading(false);
        setCrmPaymentsCount(response.data.count);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onTypeSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, type: value });
  };

  const onMethodSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, method: value });
  };

  const onStatusSelect = (value) => {
    setQuery({ ...query, limit: 30, offset: 0, status: value });
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };
  function handleOnRow(crmPayment: CrmPayment) {
    return {
      style: {
        textDecoration: crmPayment.isActive ? '' : 'line-through',
        opacity: crmPayment.isActive ? 1 : 0.5,
      },
    };
  }

  return (
    <Layout>
      <PageTitle title="Payments" />
      <Layout.Content>
        <TableFilter
          selects={[
            {
              label: 'Type',
              key: 'type',
              node: (
                <Select
                  value={query.type}
                  onSelect={onTypeSelect}
                  style={{ width: '100%' }}
                >
                  {paymentTypes.map((type) => (
                    <Option key={type.key} value={type.value}>
                      {type.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              label: 'Method',
              key: 'method',
              node: (
                <Select
                  value={query.method}
                  onSelect={onMethodSelect}
                  style={{ width: '100%' }}
                >
                  {methods.map((method) => (
                    <Option key={method.key} value={method.value}>
                      {method.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              label: 'Status',
              key: 'status',
              node: (
                <Select
                  value={query.status}
                  onSelect={onStatusSelect}
                  style={{ width: '100%' }}
                >
                  {statuses.map((status) => (
                    <Option key={status.key} value={status.value}>
                      {status.key}
                    </Option>
                  ))}
                </Select>
              ),
            },
          ]}
        />
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmPayments}
          pagination={{
            total: crmPaymentsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
