import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

// Deprecated 2024-01-24 @mike.oh
// import { ApplicationPlanCreationRequestDialog } from './ApplicationPlanCreationRequestDialog';
// import { ApplicationPlanDeletionRequestDialog } from './ApplicationPlanDeletionRequestDialog';

import { getApplicationApplicationPlansRequest } from '../api/application';
import {
  getApplicationPlanRequestsRequest,
  getIsReviewerRequest,
} from '../api/applicationPlanRequest';
import {
  ApplicationPlanRequestRequestedActionTag,
  ApplicationPlanRequestStatusTag,
} from '../components/ui/Tags';
import { Link } from 'react-router-dom';
import { ApplicationPlanRequestViewDialog } from './ApplicationPlanRequestViewDialog';
import { TableDetail } from './ui';

// App Plan
export const renderInfoItem = (item) => {
  return (
    <List.Item style={{ minWidth: 200, maxWidth: 500 }}>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item[0]}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item[1] ? item[1] : '-'}
        </Col>
      </Row>
    </List.Item>
  );
};

export const renderPremiumFeatureItem = (item) => {
  return (
    <List.Item
      style={{
        margin: '8px 0',
        minWidth: 220,
        maxWidth: 400,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
      }}
    >
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item[0]}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {typeof item[1] === 'boolean' ? (item[1] ? 'ON' : 'OFF') : item[1]}
        </Col>
      </Row>
    </List.Item>
  );
};

const columns: GetComlums = ({ crmApplication, dialogSuccessCallback }) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: ApplicationPlan['id']) => (
      <Link
        to={`/applications/${crmApplication.appId}/application_plans/${id}/`}
      >
        {id}
      </Link>
    ),
  },
  {
    title: 'Period',
    dataIndex: 'validPeriodStartDate',
    key: 'validPeriodStartDate',
    render: (
      validPeriodStartDate: ApplicationPlan['validPeriodStartDate'],
      plan: ApplicationPlan,
    ) => (
      <>
        {validPeriodStartDate}
        <br />~<br />
        {plan.validPeriodEndDate}
      </>
    ),
  },
  {
    title: 'Support Level',
    dataIndex: 'supportLevelString',
    key: 'supportLevelString',
  },
  {
    title: 'Core Features',
    dataIndex: 'humanizedCoreFeatures',
    key: 'humanizedCoreFeatures',
    render: (
      humanizedCoreFeatures: ApplicationPlan['humanizedCoreFeatures'],
    ) => (
      <List
        size="small"
        dataSource={humanizedCoreFeatures}
        renderItem={renderInfoItem}
      />
    ),
  },
  {
    title: 'Premium Features',
    dataIndex: 'premiumFeatures',
    key: 'premiumFeatures',
    width: 200,
    render: (premiumFeatures: ApplicationPlan['premiumFeatures']) => {
      let premiumFeaturesDataSource: any = [];
      let jsonPremiumFeatures = Object.entries(premiumFeatures);
      jsonPremiumFeatures.forEach((item) => {
        if (typeof item[1] === 'boolean' && item[1] === true) {
          premiumFeaturesDataSource.push(item);
          if (item[0] === 'auto_trans') {
            const _autoTransBasic = jsonPremiumFeatures.find(
              (o) => o[0] === 'auto_trans_basic',
            );
            if (_autoTransBasic) {
              premiumFeaturesDataSource.push([
                'auto_trans_basic',
                '$' + _autoTransBasic[1],
              ]);
            }
          }
        }
      });

      return (
        <List
          grid={{ gutter: 8 }}
          size="small"
          dataSource={premiumFeaturesDataSource}
          renderItem={renderPremiumFeatureItem}
        />
      );
    },
  },
  // Deprecated 2024-01-24 @mike.oh
  // {
  //   title: 'Action',
  //   dataIndex: 'isRemoved',
  //   key: 'isRemoved',
  //   render: (
  //     isRemoved: ApplicationPlan['isRemoved'],
  //     plan: ApplicationPlan,
  //   ) => (
  //     <>
  //       {!isRemoved ? (
  //         <ApplicationPlanDeletionRequestDialog
  //           crmApplication={crmApplication}
  //           plan={plan}
  //           successCallback={dialogSuccessCallback}
  //         />
  //       ) : (
  //         ''
  //       )}
  //       {crmApplication && crmApplication.appId && plan && plan.id ? (
  //         <ApplicationPlanCreationRequestDialog
  //           crmApplication={crmApplication}
  //           plan={plan}
  //           successCallback={dialogSuccessCallback}
  //         />
  //       ) : (
  //         ''
  //       )}
  //     </>
  //   ),
  // },
];

interface GetComlums {
  ({
    crmApplication,
    dialogSuccessCallback,
  }: {
    crmApplication: CrmApplication;
    dialogSuccessCallback: () => void;
  }): Array<ColumnProps<ApplicationPlan>>;
}

type Props = {
  appId: CrmApplication['appId'];
  crmApplication: CrmApplication;
};

// App Plan Requests
interface ColumnsPropsAppPlanRequest {
  dialogSuccessCallbackAppPlanRequest: () => void;
}

export const ApplicationDetailPlans: React.FC<Props> = React.memo(
  ({ appId, crmApplication }) => {
    // App Plan
    const [dataSource, setDatasource] = useState<Array<ApplicationPlan>>([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
      offset: 0,
      limit: 3,
    });

    // App Plan Requests
    const [applicationPlanRequests, setApplicationPlanRequests] = useState<
      Array<ApplicationPlanRequest>
    >([]);
    const [loadingAppPlanRequest, setLoadingAppPlanRequest] = useState(false);
    const [countAppPlanRequest, setCountAppPlanRequest] = useState(0);
    const [paginationAppPlanRequest, setPaginationAppPlanRequest] = useState({
      offset: 0,
      limit: 3,
    });
    const [isReviewer, setIsReviewer] = useState(false);

    // App Plan
    function getSodaApplicationPlans({ offset = 0, limit = 3 }) {
      setLoading(true);
      getApplicationApplicationPlansRequest({ appId, limit, offset })
        .then((response) => {
          setDatasource(response.data.results);
          setCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    function handlePageChange({ offset, limit }) {
      setPagination({ offset, limit });
      getSodaApplicationPlans({ offset, limit });
    }

    function getIsReviewer() {
      getIsReviewerRequest()
        .then((response) => {
          setIsReviewer(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function dialogSuccessCallback() {
      getSodaApplicationPlans({
        offset: pagination.offset,
        limit: pagination.limit,
      });
      getApplicationPlanRequests({
        limit: paginationAppPlanRequest.limit,
        offset: paginationAppPlanRequest.offset,
      });
    }

    function handleOnRow(plan: ApplicationPlan) {
      const { isRemoved } = plan;
      return {
        style: {
          textDecoration: isRemoved ? 'line-through' : '',
          opacity: isRemoved ? 0.5 : 1,
        },
      };
    }

    // App Plan Requests
    function getApplicationPlanRequests({ limit = 20, offset = 0 }) {
      setLoadingAppPlanRequest(false);
      // @Todo: Fix or deprecate here mike.oh
      // setLoadingAppPlanRequest(true);
      //
      // getApplicationPlanRequestsRequest(limit, offset, crmApplication.id)
      //   .then((response) => {
      //     setApplicationPlanRequests(response.data.results);
      //     setLoadingAppPlanRequest(false);
      //     setCountAppPlanRequest(response.data.count);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

    function dialogSuccessCallbackAppPlanRequest() {
      getApplicationPlanRequests({
        limit: paginationAppPlanRequest.limit,
        offset: paginationAppPlanRequest.offset,
      });
      getSodaApplicationPlans({
        limit: pagination.limit,
        offset: pagination.offset,
      });
    }

    function handlePageChangeAppPlanRequest({ offset, limit }) {
      setPaginationAppPlanRequest({ offset, limit });
      getApplicationPlanRequests({ offset, limit });
    }

    const columnsAppPlanRequest = ({
      dialogSuccessCallbackAppPlanRequest,
    }: ColumnsPropsAppPlanRequest) => [
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
        render: (id: ApplicationPlanRequest['id']) => (
          <Link to={`/application_plan_requests/${id}/`}>{id}</Link>
        ),
      },
      {
        title: 'Plan ID',
        dataIndex: 'sodaApplicationPlanId',
        key: 'sodaApplicationPlanId',
        render: (
          sodaApplicationPlanId: ApplicationPlanRequest['sodaApplicationPlanId'],
          applicationPlanRequest: ApplicationPlanRequest,
        ) =>
          sodaApplicationPlanId ? (
            <Link
              to={`/applications/${applicationPlanRequest.crmApplication.appId}/application_plans/${sodaApplicationPlanId}/`}
            >
              {sodaApplicationPlanId}
            </Link>
          ) : (
            '-'
          ),
      },
      {
        title: 'Requester',
        dataIndex: 'requester',
        key: 'requester',
        render: (requester: ApplicationPlanRequest['requester']) => (
          <span>
            {requester && requester.username}
            {requester && requester.eid ? `(${requester.eid})` : '-'}
          </span>
        ),
      },
      {
        title: (
          <span>
            Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Requested Action',
        dataIndex: 'requestedAction',
        key: 'requestedAction',
        render: (
          requestedAction: ApplicationPlanRequest['requestedAction'],
        ) => (
          <ApplicationPlanRequestRequestedActionTag
            requestedAction={requestedAction}
          />
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: ApplicationPlanRequest['status']) => (
          <ApplicationPlanRequestStatusTag status={status} />
        ),
      },
      {
        title: 'Reviewer',
        dataIndex: 'reviewer',
        key: 'reviewer',
        render: (reviewer: ApplicationPlanRequest['reviewer']) => (
          <span>
            {reviewer && reviewer.username}
            {reviewer && reviewer.eid ? `(${reviewer.eid})` : '-'}
          </span>
        ),
      },
      {
        title: (
          <span>
            Reviewed At <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        dataIndex: 'reviewedAt',
        key: 'reviewedAt',
        render: (reviewedAt: ApplicationPlanRequest['reviewedAt']) =>
          reviewedAt ? reviewedAt : '-',
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
        render: (reason) => (reason ? reason : '-'),
      },
      {
        title: 'Action',
        key: 'action',
        render: (applicationPlanRequest: ApplicationPlanRequest) => (
          <ApplicationPlanRequestViewDialog
            applicationPlanRequest={applicationPlanRequest}
            isReviewer={isReviewer}
            successCallback={dialogSuccessCallback}
          />
        ),
      },
    ];

    useEffect(function () {
      getApplicationPlanRequests({
        limit: paginationAppPlanRequest.limit,
        offset: paginationAppPlanRequest.offset,
      });
      getSodaApplicationPlans({ limit: 3, offset: 0 });
      getIsReviewer();
    }, []);

    return (
      <React.Fragment>
        <TableDetail
          title="Application Plan Requests"
          count={countAppPlanRequest}
          loading={loadingAppPlanRequest}
          columns={columnsAppPlanRequest({
            dialogSuccessCallbackAppPlanRequest,
          })}
          dataSource={applicationPlanRequests}
          onPageChange={handlePageChangeAppPlanRequest}
          // Deprecated 2024-01-24 @mike.oh
          // rightColumnComponent={
          //   <ApplicationPlanCreationRequestDialog
          //     crmApplication={crmApplication}
          //     successCallback={dialogSuccessCallback}
          //   />
          // }
          defaultPageSize={3}
          pageSizeOptions={['3', '6', '15']}
        />
        <TableDetail
          title="Application Plans"
          count={count}
          loading={loading}
          columns={columns({ crmApplication, dialogSuccessCallback })}
          dataSource={dataSource}
          onPageChange={handlePageChange}
          onRow={handleOnRow}
          pageSizeOptions={['3', '6', '15']}
        />
      </React.Fragment>
    );
  },
);
