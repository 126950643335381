import moment from 'moment-timezone';
import {formatToTimeZone} from 'date-fns-timezone';
import {Moment} from 'moment';

export const dateTimePattern = {
  full: 'YYYY-MM-DD HH:mm:ss [GMT]Z (z)',
  default: 'YYYY-MM-DD HH:mm:ss',
  date: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  time: 'HH:mm:ss',
  timezone: '[GMT]Z (z)',
};

export function getBrowserTimeZoneString(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function utcToBrowserZonedTime({ utc, pattern = dateTimePattern.full }) {
  const timeZone = getBrowserTimeZoneString();
  return formatToTimeZone(utc, pattern, { timeZone });
}

export function getDateAndTimeZone(utc) {
  const zonedTime = utcToBrowserZonedTime({ utc }).split(' ');
  const date = `${zonedTime[0]}`;
  const time = `${zonedTime[1]}`;
  const dateTime = `${date} ${time}`;
  const timezone = `${zonedTime[2]} ${zonedTime[3]}`;
  return { date, time, dateTime, timezone };
}

export function getBrowserDateTime({ utc }) {
  // Legacy
  return utc ? getDateAndTimeZone(utc).dateTime : '-';
}

export function getBrowserDate({ utc }) {
  // Legacy
  return utc ? getDateAndTimeZone(utc).date : '-';
}

export function getBrowserTime({ utc }) {
  // Legacy
  return utc ? getDateAndTimeZone(utc).time : '-';
}

export function getBrowserTimezone() {
  return moment.tz.guess();
}

export function getBrowserTodayMoment(): Moment {
  return moment(new Date(), dateTimePattern.date).tz(
    moment.tz.guess(),
  );
}

export function getSearchDate({ days }) {
  const rawEndDate = new Date();
  const rawStartDate = new Date(
    rawEndDate.getTime() - 1000 * 60 * 60 * 24 * (days - 1),
  );
  const startDate = rawStartDate.toISOString().split('T')[0];
  const endDate = rawEndDate.toISOString().split('T')[0];

  return { startDate, endDate };
}

export function getSearchMonth() {
  const rawEndDate = new Date();
  const rawStartDate = new Date(
    rawEndDate.getFullYear() - 1,
    rawEndDate.getMonth() + 1,
    rawEndDate.getDate(),
  );
  const startMonth = rawStartDate.toISOString().split('T')[0].slice(0, -3);
  const endMonth = rawEndDate.toISOString().split('T')[0].slice(0, -3);

  return { startMonth, endMonth };
}

export function disabledDate(current) {
  // Can not select days before today and today
  return current && current.valueOf() > Date.now();
}
