import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import { DynamicForm } from '../ui/DynamicForm';
import { Button, Input, DatePicker, Switch, Alert, Typography } from 'antd';
import { createBillingSubscriptionRequestRequest } from '../../api/billing';
import moment from 'moment-timezone';
import { dateTimePattern } from '../../utils/date';
import TextArea from 'antd/lib/input/TextArea';

const { Text } = Typography;

interface Plan {
  key: string;
  value: string;
}

export const SupportSubscriptionForm = ({
  crmOrganization,
  product,
  plan,
  subscriptionRequest,
}: {
  crmOrganization: CrmOrganization | undefined;
  product: string;
  plan: Plan;
  subscriptionRequest: SubscriptionRequest | undefined;
}) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [isFormValid, setIsFormValid] = useState(true);
  const [formErrorText, setFormErrorText] = useState('');
  const [changedFields, setChangedFields] = useState([] as Array<string>);

  useEffect(() => {
    if (subscriptionRequest) {
      formRef.current?.setFieldsValue(convertSubscriptionRequestBodyToFormValues(subscriptionRequest));
    }
  }, [subscriptionRequest]);

  const convertSubscriptionRequestBodyToFormValues = (_subscriptionRequest) => {
    const formValue = {};
    const subscriptionRequest = JSON.parse(_subscriptionRequest.requestBody);

    formValue['plan_value'] = subscriptionRequest.plan_value / 100;
    formValue['subscription_billing_cycle_months'] = subscriptionRequest.billing_cycle_months;
    formValue['subscription_start_date'] = moment(subscriptionRequest.start_date);
    formValue['subscription_end_date'] = subscriptionRequest.end_date
      ? moment(subscriptionRequest.end_date)
      : undefined;
    formValue['subscription_auto_renewal'] = subscriptionRequest.auto_renewal;
    formValue['subscription_auto_renewal_months'] = subscriptionRequest.auto_renewal_months;

    return formValue;
  };

  const BillingRules: React.FC = () => {
    const subscriptionFutureText = (formValues) => {
      if (!formValues?.subscription_end_date) {
        return (
          <li>
            The subscription will run &nbsp;
            <Text code>perpetually</Text>
          </li>
        );
      } else if (
        formValues?.subscription_end_date &&
        !formValues?.subscription_auto_renewal &&
        !formValues?.subscription_auto_renewal_months
      ) {
        return (
          <li>
            The subscription will stop on &nbsp;
            <Text code>
              {formValues?.subscription_end_date
                ? `${moment(formValues?.subscription_end_date).utc().format(dateTimePattern.date)}`
                : 'N/A'}
            </Text>
          </li>
        );
      } else if (
        formValues?.subscription_end_date &&
        formValues?.subscription_auto_renewal &&
        formValues?.subscription_auto_renewal_months
      ) {
        return (
          <>
            <li>
              The subscription will stop on &nbsp;
              <Text code>
                {formValues?.subscription_end_date
                  ? `${moment(formValues?.subscription_end_date).utc().format(dateTimePattern.date)}`
                  : 'N/A'}
              </Text>
            </li>
          </>
        );
      } else {
        return <></>;
      }
    };

    const autoRenewalNotice = (formValues) => {
      if (
        formValues?.subscription_end_date &&
        formValues?.subscription_auto_renewal &&
        formValues?.subscription_auto_renewal_months
      ) {
        return (
          <>
            <li>
              Once the subscription ended, a new subscription (with exact features) will be created with a minimum
              contract period of <Text code>{`${formValues?.subscription_auto_renewal_months} Month`}</Text>
            </li>
            <li>
              Customer will have the option to stop the renewal if they informed us 15 days before the subscription end
              date
            </li>
          </>
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        <ul style={{ fontSize: '15px', lineHeight: '30px' }}>
          <li>
            The subscription will start on &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['subscription_start_date']
                ? `${moment(formRef.current?.getFieldsValue()['subscription_start_date'])
                    .utc()
                    .format(dateTimePattern.date)}`
                : 'N/A'}
            </Text>
          </li>
          {subscriptionFutureText(formRef.current?.getFieldsValue())}
          <li>
            The customer will be billed for &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['plan_value']
                ? `$${formRef.current?.getFieldsValue()['plan_value']}`
                : 'N/A'}
            </Text>
            &nbsp; every &nbsp;
            <Text code>
              {formRef.current?.getFieldsValue()['subscription_billing_cycle_months']
                ? `${formRef.current?.getFieldsValue()['subscription_billing_cycle_months']} Month`
                : 'N/A'}
            </Text>
          </li>
          <li>
            The customer will be billed via &nbsp;
            <Text code>{crmOrganization?.paymentMethod ? crmOrganization?.paymentMethod : 'N/A'}</Text>
          </li>
          {autoRenewalNotice(formRef.current?.getFieldsValue())}
        </ul>
      </>
    );
  };

  const fields: Field[] = [
    {
      name: 'Billing',
      label: 'Billing',
      formItemProps: {},
      component: {},
      fields: [
        {
          name: 'Billing Payment',
          label: 'Billing Payment',
          description: 'Billing cycle indicates how often we charge the customer',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'plan_value',
              label: 'Plan Value',
              formItemProps: {
                initialValue: 0,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: <Input size="large" placeholder="0" addonBefore="$" />,
            },
            {
              name: 'subscription_billing_cycle_months',
              label: 'Billing Cycle Months',
              formItemProps: {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 8,
              component: <Input size="large" addonAfter="Months" />,
            },
          ],
        },
        {
          name: 'Billing Period',
          label: 'Billing Period',
          description:
            'You can automatically setup a renewal when the subscription ends by enabling the "billing renewal" feature',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'subscription_start_date',
              label: 'Subscription Start Date (UTC, 00:00:00)',
              span: 8,
              formItemProps: {
                initialValue: moment().startOf('day').utc(true),
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              component: (
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  format={dateTimePattern.date}
                  onChange={(date) => {
                    if (date) {
                      const convertedDate = moment([date.year(), date.month(), date.date(), 0, 0, 0]).utc(true);
                      formRef.current?.setFieldsValue({ subscription_start_date: convertedDate });
                    }
                  }}
                />
              ),
            },
            {
              name: 'subscription_end_date',
              label: 'Subscription End Date (UTC, 00:00:00)',
              span: 8,
              formItemProps: {},
              component: (
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  format={dateTimePattern.date}
                  onChange={(date) => {
                    if (date) {
                      const convertedDate = moment([date.year(), date.month(), date.date(), 0, 0, 0]).utc(true);
                      formRef.current?.setFieldsValue({ subscription_end_date: convertedDate });
                    }
                  }}
                />
              ),
            },
          ],
        },
        {
          name: 'Auto Renewal',
          label: 'Auto Renewal',
          description: 'Auto renewal only available if the end date is stated',
          component: {},
          formItemProps: {},
          fields: [
            {
              name: 'subscription_auto_renewal',
              label: 'Enabled',
              formItemProps: {
                initialValue: false,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Switch
                  defaultChecked={false}
                  disabled={
                    !formRef.current?.getFieldsValue().hasOwnProperty('subscription_end_date') &&
                    !formRef.current?.getFieldsValue()['subscription_end_date']
                  }
                  style={{ marginTop: '10px' }}
                />
              ),
            },
            {
              name: 'subscription_auto_renewal_months',
              label: 'Months',
              formItemProps: {
                initialValue: 0,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ],
              },
              span: 6,
              component: (
                <Input
                  size="large"
                  disabled={
                    formRef.current?.getFieldsValue().hasOwnProperty('subscription_auto_renewal')
                      ? !formRef.current?.getFieldsValue()['subscription_auto_renewal']
                      : true
                  }
                  placeholder="0"
                  addonAfter="Months"
                />
              ),
            },
          ],
        },
        {
          name: 'Billing Rules',
          label: 'Billing Rules',
          description: '',
          formItemProps: {},
          component: {},
          fields: [
            {
              name: '',
              label: '',
              formItemProps: {
                rules: [],
              },
              component: <BillingRules />,
            },
          ],
        },
      ],
    },
    {
      name: 'Request Reason',
      label: 'Request Reason',
      formItemProps: {},
      component: {},
      fields: [
        {
          name: 'reason',
          label: '',
          formItemProps: {
            initialValue: '',
          },
          span: 24,
          component: <TextArea />,
        },
      ],
    },
  ];

  const setForm = (ref) => {
    formRef.current = ref;
  };

  const sendRequest = (values) => {
    values['subscription_type'] = product;
    values['subscription_name'] = plan.value;
    values['display_name'] = plan.key;

    if (crmOrganization) {
      createBillingSubscriptionRequestRequest({
        crmOrganizationId: crmOrganization.id.toString(),
        values,
        requester: `${localStorage.getItem('username')}@sendbird.com`,
        requestedAction: 'CREATE',
      }).finally(() => {
        history.push(`/crm_organizations/${crmOrganization.id}/subscription_requests/`);
      });
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current
        .validateFields(changedFields)
        .then(() => {
          sendRequest(formRef.current?.getFieldsValue());
        })
        .catch((error) => {
          // When form vaildation failed
          setIsFormValid(false);
          if (error && error.errorFields && error.errorFields.length > 0) {
            let newFormErrorText = '';
            for (const err of error.errorFields) {
              newFormErrorText += `Missing ${err.name[0]}\n`;
            }
            setFormErrorText(newFormErrorText);
          } else {
            setFormErrorText('Unknown Error');
          }
          console.log(error);
        });
    }
  };

  return (
    <>
      <Alert
        message="Invalid form values"
        description={<div style={{ whiteSpace: 'pre' }}>{formErrorText}</div>}
        type="error"
        banner
        style={{ position: 'absolute', top: '20px', zIndex: 1000, display: isFormValid ? 'none' : 'block' }}
      />
      <DynamicForm
        fields={fields}
        setForm={setForm}
        formProps={{
          onValuesChange: (changedValues) => {
            setChangedFields(changedFields.concat(Object.keys(changedValues)));
            setIsFormValid(true);
          },
        }}
      />
      <Button
        key="submit"
        type="primary"
        size="large"
        style={{ marginTop: '30px', minHeight: '40px' }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );
};
