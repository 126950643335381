import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Input, Layout } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { CountryWithFlag } from '../../components/ui/DataText';

import { COUNTRY_CODE } from '../../CountryCode';
import { getCrmUsersRequest } from '../../api/crmUser';
import { fixedEncodeURIComponent } from '../../utils/general';
import { PageTitle } from '../../components/ui/PageTitle';

const { Search } = Input;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: CrmUser['id']) => <Link to={`/crm_users/${id}/`}>{id}</Link>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Country Code',
    dataIndex: 'countryCode',
    key: 'countryCode',
    render: (countryCode: CrmUser['countryCode']) => {
      const country = COUNTRY_CODE[countryCode.toLowerCase()];
      return countryCode && country ? (
        <CountryWithFlag countryCode={countryCode} />
      ) : (
        (!country && countryCode) || '-'
      );
    },
  },
  {
    title: (
      <span>
        Date Joined <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'dateJoined',
    key: 'dateJoined',
  },
];

type Props = RouteComponentProps;

export const CrmUsers = withRouter<Props, any>(({ location, history }) => {
  const [crmUsers, setCrmUser] = useState<Array<CrmUser>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [crmUsersCount, serCrmUsersCount] = useState(0);

  useEffect(() => {
    const q = location.search.split('q=')[1]
      ? decodeURIComponent(location.search.split('q=')[1])
      : '';
    setSearchQuery(q);
    getCrmUsers(0, 30, q);
  }, [location]);

  const getCrmUsers = (offset = 0, limit = 0, q = '') => {
    const query = q ? q : searchQuery;

    setLoading(true);
    getCrmUsersRequest({
      offset,
      limit,
      searchQuery: fixedEncodeURIComponent(query),
    })
      .then((response) => {
        setCrmUser(response.data.results);
        setLoading(false);
        serCrmUsersCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
    history.replace({
      pathname: '/crm_users/',
      search: `?q=${value}`,
    });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    getCrmUsers((page - 1) * pageSize, pageSize);
  };

  function handleOnRow(crmUser: CrmUser) {
    return {
      style: {
        textDecoration: crmUser.isActive ? '' : 'line-through',
        opacity: crmUser.isActive ? 1 : 0.5,
      },
    };
  }

  return (
    <Layout>
      <PageTitle
        title="Users"
        extra={[
          <Search
            key="users_search"
            value={searchQuery}
            placeholder="Search users"
            enterButton={true}
            onSearch={onSearch}
            onChange={onSearchChange}
          />,
        ]}
      />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={crmUsers}
          pagination={{
            total: crmUsersCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
