import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { PageTitle } from '../../components/ui/PageTitle';
import { Spinner } from '../../components/ui/Spinner';
import { getBillingVoucherRequest, getCallVoucherRelatedInvoiceRequest } from '../../api/billing';
import { VoucherDetailInfo } from '../../components/organizationBilling/VoucherDetailInfo';
import { VoucherPaymentPayNowDialog } from '../../components/organizationBilling/VoucherPaymentPayNowDialog';
import { VoucherActivateDialog } from '../../components/organizationBilling/VoucherActivateDialog';
import { hasPermission, Permissions } from '../../utils/permission';
import { InvoiceStatusTag } from '../../components/ui/Tags';
import { dateTimePattern } from '../../utils/date';
import { TableDetail } from '../../components/ui/TableDetail';

interface MatchParams {
  voucher_uid: string;
}

type Props = RouteComponentProps<MatchParams>;

export const VoucherDetail = withRouter<Props, any>(
  React.memo<Props>(({ match, location }) => {
    const { voucher_uid } = match.params;
    const [loading, setLoading] = useState(false);
    const [voucher, setVoucher] = useState({} as any);
    const [relatedInvoices, setRelatedInvoices] = useState([] as Array<OrganizationSubscriptionInvoice>);
    const [relatedInvoicesCount, setRelatedInvoicesCount] = useState(0);

    const getVoucher = () => {
      setLoading(true);
      getBillingVoucherRequest({ voucher_uid: voucher_uid })
        .then((response) => {
          setVoucher(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getRelatedVoucher = () => {
      setLoading(true);
      getCallVoucherRelatedInvoiceRequest({ voucher_uid })
        .then((response) => {
          setRelatedInvoices(response.data.results);
          setRelatedInvoicesCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
      console.log(relatedInvoices);
    };

    const payNowButton = React.useMemo(() => {
      if ((relatedInvoicesCount === 0 || voucher.paidAmount > 0) && hasPermission(Permissions.MANAGE_PAYMENT)) {
        return <VoucherPaymentPayNowDialog voucher={voucher} />;
      } else {
        return;
      }
    }, [voucher, relatedInvoicesCount]);

    const activateButton = React.useMemo(() => {
      if (hasPermission(Permissions.MANAGE_PAYMENT)) {
        return <VoucherActivateDialog voucher={voucher} relatedInvoicesCount={relatedInvoicesCount} />;
      } else {
        return;
      }
    }, [voucher, relatedInvoicesCount]);

    const invoiceColumn = [
      {
        title: '#ID',
        dataIndex: 'id',
        key: 'id',
        render: (id, invoice) => <Link to={`/organization_invoices/${invoice.uid}/`}>{id}</Link>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <InvoiceStatusTag status={status} />,
      },
      {
        title: 'Invoice type',
        dataIndex: 'invoiceType',
        key: 'invoiceType',
      },
      {
        title: 'Payment method',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
      },
      {
        title: 'Total',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (totalAmount) => `$${Math.floor(totalAmount) / 100}`,
      },
      {
        title: (
          <span>
            Period <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_, invoice) =>
          `${invoice.startDate ? moment(invoice.startDate).format(dateTimePattern.date) : ''} - ${
            invoice.endDate ? moment(invoice.endDate).format(dateTimePattern.date) : ''
          }`,
      },
      {
        title: (
          <span>
            Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => `${moment(createdAt).utc().format(dateTimePattern.default)}`,
      },
    ];

    useEffect(() => {
      getVoucher();
      getRelatedVoucher();
    }, []);

    return isEmpty(voucher) || loading ? (
      <Spinner />
    ) : (
      <>
        <PageTitle
          title="Voucher Detail"
          subTitle={`#${(voucher.uid && voucher.uid.toString()) || ''}`}
          showBack={true}
          extra={[payNowButton, activateButton]}
        />
        <Layout.Content>
          <VoucherDetailInfo voucher={voucher} />
          <TableDetail
            title="Invoices"
            count={relatedInvoicesCount}
            loading={loading}
            columns={invoiceColumn}
            dataSource={relatedInvoices}
          />
        </Layout.Content>
      </>
    );
  }),
);
