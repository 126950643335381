import * as React from 'react';
import { useState, useEffect } from 'react';

import { StarOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import {
  getFavoriteAppRequest,
  removeFavoriteAppRequest,
  addFavoriteAppRequest
} from '../api/user';

type Props = {
  appId: CrmApplication['appId'];
};

export const ButtonFavorite: React.FC<Props> = React.memo(({ appId }) => {
  const [favorite, setFavorite] = useState(false);
  const [loading, setLoading] = useState(false);

  function getFavoriteApp() {
    setLoading(true);
    getFavoriteAppRequest({ appId })
      .then(response => {
        setFavorite(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setFavorite(false);
        setLoading(false);
      });
  }

  function handleUserFavorite(e) {
    e.preventDefault();

    setLoading(true);
    if (favorite) {
      removeFavoriteAppRequest({ appId })
        .then(_ => {
          setFavorite(false);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    } else {
      addFavoriteAppRequest({ appId })
        .then(_ => {
          setFavorite(true);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    }
  }

  useEffect(function() {
    getFavoriteApp();
  }, []);

  return (
    <Button
      icon={<StarOutlined />}
      size="small"
      loading={loading}
      type={favorite ? 'primary' : undefined}
      onClick={handleUserFavorite}
    >
      Favorite
    </Button>
  );
});
