import * as React from 'react';
import {Card, Col, Row} from 'antd';

type Props = {
  className?: string;
  layout?: 'horizontal' | 'vertical';
  chart?: React.ReactNode;
  table?: React.ReactNode;
};

export const MetricPageChartRow: React.FC<Props> = ({
  className,
  chart,
  layout = 'vertical',
  table,
}) => {
  switch (layout) {
    case 'horizontal':
      return (
        <Row
          className={className}
          gutter={16}
          justify="space-around"
          align="top"
        >
          <Col xs={24} md={15}>
            <Card>{chart}</Card>
          </Col>
          <Col xs={24} md={9}>
            {table}
          </Col>
        </Row>
      );
    case 'vertical':
      return (
        <div className={className}>
          {chart && <Card style={{ marginBottom: 16 }}>{chart}</Card>}
          {table}
        </div>
      );
  }
};
