import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TrendsWithQueryClient } from './TrendsWithQueryClient';

const queryClient = new QueryClient();

export const Trends = () => (
  <QueryClientProvider client={queryClient}>
    <TrendsWithQueryClient />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
