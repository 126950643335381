import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmApplicationByOrganizationRequest({ crmOrganizationId, offset, limit }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/crm_applications/`, {
    params: {
      offset,
      limit,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getRolesByOrganizationRequest({ crmOrganizationId, offset, limit }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/member_roles/`, {
    params: {
      offset,
      limit,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getSyncCrmOrganizationRequest({ crmOrganizationId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/sync/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmOrganizationRequest({ crmOrganizationId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmOrganizationsRequest({
  limit,
  offset,
  searchQuery,
  type = '',
  first_conversion = '',
  is_self_serve = '',
  createdAtGte,
  createdAtLte,
  is_test_organization='',
}: {
  limit?;
  offset?;
  searchQuery?;
  type?;
  first_conversion?;
  is_self_serve?;
  createdAtGte?;
  createdAtLte?;
  is_test_organization?;
}) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/`, {
    params: {
      limit,
      offset,
      query: searchQuery,
      type,
      first_conversion,
      is_self_serve,
      created_at__gte: createdAtGte,
      created_at__lte: createdAtLte,
      is_test_organization,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getOrganizationPlanRequestRequest({ limit, offset, crmOrganizationId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/organization_plan_requests/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmOrganizationMembersRequest({ limit = 0, offset = 0, crmOrganizationId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/crm_organization_members/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmOrganizationSalesforceAccountsRequest({ crmOrganizationId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/salesforce_accounts/`, {
    headers: { Authorization: getAuthToken() },
  });
}

export function getOrganizationInvitationsRequest({ limit = 0, offset = 0 }) {
  return insightAxios.get(`${API_DOMAIN}/api/organization_invitations/`, {
    params: { limit, offset },
    headers: { Authorization: getAuthToken() },
  });
}

export function getOrganizationInvitationCrmOrganizationIdRequest({ gateOrganizationId }) {
  return insightAxios.get(`${API_DOMAIN}/api/organization_invitations/crm_organization_id/`, {
    params: { gateOrganizationId },
    headers: { Authorization: getAuthToken() },
  });
}

export function getCrmOrganizationOrganizationInvitationsRequest({ limit = 0, offset = 0, crmOrganizationId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/organization_invitations/`, {
    params: { limit, offset },
    headers: { Authorization: getAuthToken() },
  });
}

export function setOrganizationRegionRequest({ crmOrganizationId, allowRegions }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/update_allow_regions/`,
    {
      allowRegions,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function setOrganizationPlanRequest({ crmOrganizationId, plan, reason }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/set_organization_plan_requests/`,
    {
      plan,
      reason,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function approveOrganizationPlanRequest({ crmOrganizationId, id }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/approve_organization_plan_requests/`,
    { id },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function denyOrganizationPlanRequest({ crmOrganizationId, id }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/deny_organization_plan_requests/`,
    { id },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function changeOrgToSelfServiceRequest({ crmOrganizationUid }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/change_to_self_service/`,
    { organizationUid: crmOrganizationUid },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateOrganizationGateMaxAppCountRequest({ crmOrganizationId, maxApplicationCount }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/gate_update_max_app_count/`,
    {
      maxApplicationCount,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function activateOrganizationRequest({ crmOrganizationUid }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationUid}/gate_activate_organization/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function deactivateOrganizationRequest({ crmOrganizationUid, reason }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationUid}/billing_deactivate_organization/`,
    {
      reason: reason,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateOrganizationPaymentMethodRequest({ crmOrganizationId, paymentMethod }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/payment_method/`,
    {
      paymentMethod,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateOrganizationVoucherTypeRequest({ crmOrganizationId, voucherType }) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/voucher_type/`,
    {
      voucherType,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function updateOrganizationVoucherSubscriptionUpdateRequest({
  crmOrganizationUid,
  paymentAmount,
  credit,
  durationDays,
}) {
  return insightAxios.put(
    `${API_DOMAIN}/api/billing_vouchers/subscription/`,
    {
      organization_uid: crmOrganizationUid,
      voucher_subscription_payment_amount: paymentAmount * 100, // dollars to cents
      voucher_subscription_credit: credit,
      voucher_subscription_duration_days: durationDays,
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getOrganizationDailyFeatureUsageRequest({ crmOrganizationId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/daily_feature_usages/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getOrganizationMonthlyFeatureUsageRequest({ crmOrganizationId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/monthly_feature_usages/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}

export function getOrganizationAttributesRequest({ crmOrganizationId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/attributes/`, {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  );
}

export function updateOrganizationAttributesRequest( crmOrganizationId, attributes) {
  return insightAxios.patch(
    `${API_DOMAIN}/api/crm_organizations/${crmOrganizationId}/attributes/`,
    attributes,
    {
      headers: {
        Authorization: getAuthToken(),
        'Content-Type': 'application/json',
      },
    },
  );
}
