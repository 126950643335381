import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCeleryTasksRequest({ limit, offset }) {
  return insightAxios.get(`${API_DOMAIN}/api/celery_tasks/`, {
    params: {
      limit,
      offset,
    },
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCeleryTaskRequest({ celeryTaskId }) {
  return insightAxios.get(`${API_DOMAIN}/api/celery_tasks/${celeryTaskId}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCeleryChildTasksRequest({ celeryTaskId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/celery_tasks/${celeryTaskId}/child_tasks/`,
    {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function RunBackfillCeleryTaskRequest({ celeryTaskId }) {
  return insightAxios.post(
    `${API_DOMAIN}/api/celery_tasks/${celeryTaskId}/run_backfill/`,
    {},
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function runDataPipelineRequest(target_date) {
  return insightAxios.post(
    `${API_DOMAIN}/api/celery_tasks/run_data_pipeline/`,
    {},
    {
      params: {
        target_date: target_date,
      },
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCeleryTaskQueueStatusRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/celery_tasks/task_queue_count/`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}
