import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmUserRequest({ crmUserId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_users/${crmUserId}/`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function getCrmUsersRequest({ limit, offset, searchQuery }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_users/?limit=${limit}&offset=${offset}&query=${searchQuery}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmOrganizationMembersRequest({ crmUserId, limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_users/${crmUserId}/crm_organization_members/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    },
  );
}

export function getCrmUserForForgottenRequestRequest({ crmUserId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_users/${crmUserId}/is_owner_with_has_applications_and_organization_members`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}


export function removeAllMembersFromCrmUserRequest({ crmUserId }) {
  return insightAxios.delete(`${API_DOMAIN}/api/crm_users/${crmUserId}/remove_all_members`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function removeAllApplicationsFromCrmUserRequest({ crmUserId }) {
  return insightAxios.delete(`${API_DOMAIN}/api/crm_users/${crmUserId}/remove_all_applications`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}

export function deleteCrmUser({ crmUserId }) {
  return insightAxios.delete(`${API_DOMAIN}/api/crm_users/${crmUserId}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}
