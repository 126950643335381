import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import moment from 'moment';
import { PageTitle } from '../../components/ui/PageTitle';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { fetchOrganizationBillingSubscriptionRequests } from '../../api/billing';
import { dateTimePattern } from '../../utils/date';
import {
  BillingSubscriptionRequestStatusTag,
  BillingSubscriptionRequestRequestedActionTag,
} from '../../components/ui/Tags';
import { OrganizationBillingSubscriptionRequestViewDialog } from '../../components/organizationBilling/OrganizationBillingSubscriptionRequestViewDialog';
import { hasPermission, Permissions } from '../../utils/permission';

const useBillingRequestsAPI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    limit: 30,
    offset: 0,
  });
  const [response, setResponse] = useState<any>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    try {
      const { limit, offset } = query;
      const response = await fetchOrganizationBillingSubscriptionRequests({
        limit,
        offset,
      });
      setResponse(response.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [query]);

  useEffect(() => {
    request();
  }, [request]);

  return {
    isLoading,
    count: response ? response.count : 0,
    items: response ? response.results : [],
    query,
    setQuery,
  };
};

export const OrganizationSubscriptionRequestList = () => {
  const { isLoading, count, items, query, setQuery } = useBillingRequestsAPI();
  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, request) => {
        return <Link to={`/crm_organizations/${request.crmOrganization.uid}/`}>{request.crmOrganization.name}</Link>;
      },
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => `${moment(createdAt).utc().format(dateTimePattern.default)}`,
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
      render: (_, request) => {
        return <Link to={`/users/${request.requester.id}/`}>{request.requester.email.split('@')[0]}</Link>;
      },
    },
    {
      title: 'Requested Action',
      dataIndex: 'requestedAction',
      key: 'requestedAction',
      render: (requestedAction) => <BillingSubscriptionRequestRequestedActionTag requestedAction={requestedAction} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <BillingSubscriptionRequestStatusTag status={status} />;
      },
    },
    {
      title: 'Reviewer',
      dataIndex: 'reviewer',
      key: 'reviewer',
      render: (_, request) => {
        if (request.reviewer) {
          return <Link to={`/users/${request.reviewer.id}/`}>{request.reviewer.email.split('@')[0]}</Link>;
        } else {
          return <span>N/A</span>;
        }
      },
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, request) => (
        <OrganizationBillingSubscriptionRequestViewDialog
          crmOrganization={request.crmOrganization}
          billingSubscriptionRequest={request}
          isReviewer={hasPermission(Permissions.MANAGE_BILLING_SUBSCRIPTION)}
          successCallback={() => {
            window.location.href = `/organization_subscription_requests/`;
          }}
        />
      ),
    },
  ];

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  return (
    <Layout>
      <PageTitle title="Subscription Requests" />
      <Layout.Content>
        <ResponsiveTable
          loading={isLoading}
          columns={columns}
          dataSource={items}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
