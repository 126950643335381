import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import {
  getOrganizationInvitationsRequest,
  getOrganizationInvitationCrmOrganizationIdRequest,
} from '../../api/organization';

import moment from 'moment-timezone';

const { Title } = Typography;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
  },
  {
    title: (
      <span>
        Updated At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
  },
  {
    title: (
      <span>
        Expire At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'expireAt',
    key: 'expireAt',
    render: (date) => moment(date).format('YYYY-MM-DD HH:mm:SS'),
  },
  {
    title: 'Gate Org. ID',
    dataIndex: 'organizationId',
    key: 'organizationId',
    render: (id) => <a onClick={redirectToCrmOrganization(id)}>{id}</a>,
  },
];

let redirectToCrmOrganization;

type Props = RouteComponentProps;

export const OrganizationInvitations = withRouter<Props, any>(
  ({ location, history }) => {
    const [organizationInvitations, setOrganizationInvitations] = useState<
      Array<OrganizationInvitation>
    >([]);
    const [loading, setLoading] = useState(false);
    const [
      organizationInvitationsCount,
      setOrganizationInvitationsCount,
    ] = useState(0);

    useEffect(() => {
      getCrmOrganizations(0, 30);
    }, [location]);

    const getCrmOrganizations = (offset = 0, limit = 0) => {
      setLoading(true);
      getOrganizationInvitationsRequest({ offset, limit })
        .then((response) => {
          setOrganizationInvitations(response.data.results);
          setLoading(false);
          setOrganizationInvitationsCount(response.data.count);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    };

    const onPageChange = (page, pageSize) => {
      getCrmOrganizations((page - 1) * pageSize, pageSize);
    };

    // function handleOnRow(organizationInvitation: OrganizationInvitation) {
    //   return {
    //     style: {},
    //   };
    // }

    redirectToCrmOrganization = function (gateOrganizationId) {
      return function (e) {
        getOrganizationInvitationCrmOrganizationIdRequest({
          gateOrganizationId,
        })
          .then((response) => {
            history.push(`/crm_organizations/${response.data.id}`);
          })
          .catch((error) => {
            console.error(error);
          });
      };
    };

    return (
      <React.Fragment>
        <Row style={{ marginBottom: '8px' }}>
          <Col span={24} md={{ span: 24 }} lg={{ span: 24 }}>
            <Title level={3}>Organization Invitations</Title>
          </Col>
        </Row>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={organizationInvitations}
          pagination={{
            total: organizationInvitationsCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          // onRow={handleOnRow}
        />
      </React.Fragment>
    );
  },
);
