import React, { useState } from 'react';
import { Button, Modal, Input, Typography } from 'antd';

import { deactivateOrganizationRequest } from '../../api/organization';

const { Title, Paragraph } = Typography;

type Props = {
  crmOrganizationUid: string;
  successCallback: any;
};

export const DeactivateOrganizationDialog: React.FC<Props> = ({ crmOrganizationUid, successCallback }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleReasonChange(e) {
    setReason(e.target.value);
  }

  function handleSubmit() {
    if (!reason) {
      return;
    }

    setLoading(true);
    deactivateOrganizationRequest({
      crmOrganizationUid,
      reason,
    })
      .then((response) => {
        handleCancel();

        successCallback &&
          successCallback({
            callback: console.log('DeactivateOrganizationRequest', response.data),
          });
      })
      .catch((error) => {
        console.error('Error in : DeactivateOrganizationRequest', error);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        size="small"
        style={{
          marginLeft: '0.5em',
        }}
      >
        <span style={{ textDecorationLine: 'none' }}>Deactivate</span>
      </Button>
      <Modal
        open={visible}
        title={'Deactivate organization'}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <Paragraph>Are you sure you want to deactivate this organization?</Paragraph>

        <Title level={4}>Reason</Title>
        <Input size="large" onChange={handleReasonChange} placeholder="Please input deactivation reason or jira link" />
      </Modal>
    </>
  );
};
