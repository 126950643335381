import * as React from 'react';
import styled from 'styled-components';
import {FilterOutlined} from '@ant-design/icons';

type Props = {
  className?: string;
  selects: { label: string; node: React.ReactNode }[];
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  flex: 1;

  .SelectContainer__select-wrapper {
    > * {
      width: 100%;
    }
  }

  .SelectContainer__label {
    margin-bottom: 8px;
  }
`;

export const MetricPageChartFilter: React.FC<Props> = ({
  className,
  selects,
}) => {
  return (
    <Container className={className}>
      <FilterOutlined style={{ marginRight: 8, marginTop: 4 }} />
      <SelectContainer>
        {selects.map(({ label, node }) => (
          <label className="SelectContainer__select-wrapper">
            <div className="SelectContainer__label">{label}</div>
            {node}
          </label>
        ))}
      </SelectContainer>
    </Container>
  );
};
