import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getRolesRequest({ limit, offset }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/roles/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getRoleRequest({ roleId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/roles/${roleId}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getUserRolesRequest({ limit, offset, user_id, role_id }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_roles/`,
    {
      params: { limit, offset, user_id, role_id },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getUserRoleRequest({ userRoleId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/user_roles/${userRoleId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getRolePermissionsRequest({ limit, offset, role_id, permission_id }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/role_permissions/`,
    {
      params: { limit, offset, role_id, permission_id },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getRolePermissionRequest({ rolePermissionId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/role_permissions/${rolePermissionId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
