import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Card, Row, Col, Table } from 'antd';

import { LineChartComponent } from './LineChartComponent';

import {
  getCrmApplicationDauRequest,
  getCrmApplicationMauRequest,
} from '../api/application';
import {
  getDailyMessageCountsRequest,
  getMonthlyMessageCountsRequest,
} from '../api/message';

import { ColumnProps } from 'antd/lib/table';

// const { Paragraph } = Typography;

const columns: Array<ColumnProps<any>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date: ApplicationMetrics['date']) => (
      <span style={{ fontSize: '13px' }}>{date}</span>
    ),
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    align: 'right',
    render: (count: ApplicationMetrics['count']) => (
      <strong style={{ fontSize: '13px' }}>
        {count ? count.toLocaleString() : '0'}
      </strong>
    ),
  },
];

const TableTitle = ({ title }) => () => {
  return <strong>{title}</strong>;
};

type Props = RouteComponentProps;

export const DashboardUsage = withRouter<Props, any>(({ history }) => {
  const [daus, setDaus] = useState<Array<ApplicationMetrics>>([]);
  const [maus, setMaus] = useState<Array<ApplicationMetrics>>([]);
  const [dauLoading, setDauLoading] = useState(false);
  const [mauLoading, setMauLoading] = useState(false);

  const [messageDaily, setMessageDaily] = useState<Array<ApplicationMetrics>>(
    [],
  );
  const [messageMonthly, setMessageMonthly] = useState<
    Array<ApplicationMetrics>
  >([]);
  const [messageDailyLoading, setMessageDailyLoading] = useState(false);
  const [messageMonthlyLoading, setMessageMonthlyLoading] = useState(false);

  useEffect(function () {
    getCrmApplicationDau();
    getCrmApplicationMau();

    getDailyMessageCounts();
    getMonthlyMessageCounts();
  }, []);

  function getCrmApplicationDau() {
    const endDateRaw = new Date();
    const startDateRaw = new Date(
      endDateRaw.getTime() - 1000 * 60 * 60 * 24 * (14 - 1),
    );

    const startDate = startDateRaw.toISOString().split('T')[0];
    const endDate = endDateRaw.toISOString().split('T')[0];

    setDauLoading(true);
    getCrmApplicationDauRequest({ startDate, endDate })
      .then((response) => {
        setDauLoading(false);
        setDaus(response.data.results);
      })
      .catch((error) => {
        setDauLoading(false);
        console.error(error);
      });
  }

  function getCrmApplicationMau() {
    const endDateRaw = new Date();
    let startDateRaw = new Date();
    startDateRaw.setMonth(endDateRaw.getMonth() - 11);

    const startDate = startDateRaw
      .toISOString()
      .match(/^[0-9]{4}-[0-9]{1,2}/)![0];
    const endDate = endDateRaw.toISOString().match(/^[0-9]{4}-[0-9]{1,2}/)![0];

    setMauLoading(true);
    getCrmApplicationMauRequest({ startDate, endDate })
      .then((response) => {
        console.log(response);
        setMaus(response.data.results);
        setMauLoading(false);
      })
      .catch((error) => {
        setMauLoading(false);
        console.error(error);
      });
  }

  function getDailyMessageCounts() {
    const endDateRaw = new Date();
    const startDateRaw = new Date(
      endDateRaw.getTime() - 1000 * 60 * 60 * 24 * (14 - 1),
    );

    const startDate = startDateRaw.toISOString().split('T')[0];
    const endDate = endDateRaw.toISOString().split('T')[0];

    setMessageDailyLoading(true);
    getDailyMessageCountsRequest({ startDate, endDate })
      .then((response) => {
        console.log(response);
        setMessageDaily(response.data.results);
        setMessageDailyLoading(false);
      })
      .catch((error) => {
        setMessageDailyLoading(false);
        console.error(error);
      });
  }

  function getMonthlyMessageCounts() {
    const endDateRaw = new Date();
    const startDateRaw = new Date(
      endDateRaw.getFullYear() - 1,
      endDateRaw.getMonth() + 1,
      endDateRaw.getDate(),
    );
    const startDate = startDateRaw.toISOString().split('T')[0].slice(0, -3);
    const endDate = endDateRaw.toISOString().split('T')[0].slice(0, -3);

    setMessageMonthlyLoading(true);
    getMonthlyMessageCountsRequest({ startDate, endDate })
      .then((response) => {
        setMessageMonthly(response.data.results);
        setMessageMonthlyLoading(false);
      })
      .catch((error) => {
        setMessageMonthlyLoading(false);
        console.error(error);
      });
  }

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col style={{ marginBottom: '16px' }}>
        <Card title="Active User">
          <Row gutter={16}>
            <Col span={24} lg={{ span: 14 }}>
              <Card style={{ marginBottom: '16px' }}>
                <LineChartComponent
                  title="DAU"
                  labels={daus.map((dau) => dau.date)}
                  data1={daus.map((dau) => dau.count)}
                />
              </Card>
              <Card style={{ marginBottom: '16px' }}>
                <LineChartComponent
                  title="MAU"
                  unit="month"
                  labels={maus.map((mau) => mau.date)}
                  data1={maus.map((mau) => mau.count)}
                />
              </Card>
            </Col>
            <Col span={24} lg={{ span: 5 }} style={{ marginBottom: '16px' }}>
              <Table
                title={TableTitle({ title: 'Daily Active User' })}
                bordered={true}
                loading={dauLoading}
                columns={columns}
                dataSource={daus}
                pagination={false}
              />
            </Col>
            <Col span={24} lg={{ span: 5 }}>
              <Table
                title={TableTitle({ title: 'Monthly Active User' })}
                bordered={true}
                loading={mauLoading}
                columns={columns}
                dataSource={maus}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Message counts" style={{ marginTop: '32px' }}>
          <Row gutter={16}>
            <Col span={24} lg={{ span: 14 }}>
              <Card style={{ marginBottom: '16px' }}>
                <LineChartComponent
                  title="Daily message counts"
                  labels={messageDaily.map((message) => message.date)}
                  data1={messageDaily.map((message) => message.count)}
                />
              </Card>
              <Card style={{ marginBottom: '16px' }}>
                <LineChartComponent
                  title="Monthly message counts"
                  unit="month"
                  labels={messageMonthly.map((message) => message.date)}
                  data1={messageMonthly.map((message) => message.count)}
                />
              </Card>
            </Col>
            <Col span={24} lg={{ span: 5 }} style={{ marginBottom: '16px' }}>
              <Table
                title={TableTitle({ title: 'Daily message counts' })}
                bordered={true}
                loading={messageDailyLoading}
                columns={columns}
                dataSource={messageDaily}
                pagination={false}
              />
            </Col>
            <Col span={24} lg={{ span: 5 }}>
              <Table
                title={TableTitle({ title: 'Monthly message counts' })}
                bordered={true}
                loading={messageMonthlyLoading}
                columns={columns}
                dataSource={messageMonthly}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
