// export function isSuperUser(): boolean {
//   return localStorage.getItem('isSuperuser') === 'true';
// }

export function getAuthToken(): string {
  return `Token ${localStorage.getItem('token')}`;
}

export const signout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('userId');
  localStorage.removeItem('serializedPermissions');
  window.location.href = '/';
};

export const gateAuth = {
  signout(callback: (paylaod: any) => any) {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('isSuperuser');
    localStorage.removeItem('userId');
    setTimeout(callback, 1);
  }
};
