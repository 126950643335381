import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ResponsiveTable } from './ui/ResponsiveTable';
import { TwoBarChartComponent } from './TwoBarChartComponent';

import { useCheckMobile } from '../utils/screen';
import { getRegionDailyMessageCountsRequest } from '../api/message';

const { Title, Paragraph } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function createColumns({ dataSource, isMobile }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: isMobile ? false : 'left',
      width: '100px',
    },
  ];

  dataSource.forEach((data, columnIndex) => {
    columns.push({
      title: data.region,
      dataIndex: `count-${columnIndex}`,
      key: `count-${columnIndex}`,
      align: alignRight,
      render: (value: number) => value && value.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSource, title }) {
  const keys = dataSource.length > 0 ? Object.keys(dataSource[0]) : [];
  let source = [] as any;

  keys.map((key) => {
    const row = {};
    if (key !== 'region') {
      row['key'] = key;
      row['title'] = title;

      dataSource.forEach((data, index) => {
        row[`count-${index}`] = data[key];
      });
      source.push(row);
    }
  });

  return source;
}

const TitlesDau = {
  lastMonthYesterday: 'Last Month DAU',
  yesterday: 'Yesterday DAU',
  growthRate: 'Growth Rate (%)',
};

export const DashboardRegionMessages = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dau, setDau] = useState<ApplicationDauByRegion>({
    lastMonthYesterday: [],
    yesterday: [],
    growthRate: [],
  });

  const [dauLoading, setDauLoading] = useState<boolean>(false);

  function getRegionDailyMessageCounts() {
    setDauLoading(true);
    getRegionDailyMessageCountsRequest()
      .then((response) => {
        setDau(response.data);
        setDauLoading(false);
      })
      .catch((error) => {
        setDauLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getRegionDailyMessageCounts();
  }, []);

  const dauColumns = createColumns({
    dataSource: dau.yesterday,
    isMobile,
  });

  const lastMonthDauSources = convertDataSource({
    dataSource: dau.lastMonthYesterday,
    title: TitlesDau.lastMonthYesterday,
  });

  const yesterdayDauSources = convertDataSource({
    dataSource: dau.yesterday,
    title: TitlesDau.yesterday,
  });

  const growthRateSources = convertDataSource({
    dataSource: dau.growthRate,
    title: TitlesDau.growthRate,
  });

  const dauSource = [...lastMonthDauSources, ...yesterdayDauSources, ...growthRateSources];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Message counts statistics" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Card>
                <Title level={4}>Message counts - Daily</Title>
                <Paragraph>The overview of daily message counts for Sendbird's platform.</Paragraph>
                <TwoBarChartComponent
                  title="Message counts - Comparison"
                  labels={dau.lastMonthYesterday.map((o) => o.region)}
                  label1="Last Month Yesterday Messages"
                  label2={'This Month Yesterday Messages'}
                  data1={dau.lastMonthYesterday.map((o) => o.count)}
                  data2={dau.yesterday.map((o) => o.count)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dauLoading}
                  columns={dauColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={dauSource}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
