import React, {useEffect, useMemo, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Layout, Tag} from 'antd';

import {
  getOrganizationSubscriptionInvoiceRequest,
  getOrganizationSubscriptionPaymentsRequest,
  getOrganizationSubscriptionsRequest,
} from '../../api/billing';

import {PageTitle} from '../../components/ui/PageTitle';
import {OrganizationSubscriptionInvoiceInfo} from '../../components/OrganizationSubscriptionInvoiceInfo';
import {TableDetail} from '../../components/ui/TableDetail';
import {OrganizationSubscriptionPaymentStatusTag} from '../../components/ui/Tags';
import {InvoiceItems} from '../../components/organizationBilling/InvoiceItems';
import {
  OrganizationSubscriptionPaymentRefundDialog
} from '../../components/organizationBilling/OrganizationSubscriptionPaymentRefundDialog';
import {
  OrganizationSubscriptionPaymentPayNowDialog
} from '../../components/organizationBilling/OrganizationSubscriptionPaymentPayNowDialog';
import {hasPermission, Permissions} from '../../utils/permission';
import {UpdateInvoiceStatusDialog} from '../../components/organizationBilling/UpdateInvoiceStatusDialog';

interface MatchParams {
  invoiceUid: string;
}

type Props = RouteComponentProps<MatchParams>;

export const InvoiceDetail = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { invoiceUid } = match.params;
    const [organizationSubscriptionInvoice, setOrganizationSubscriptionInvoice] = useState(
      {} as OrganizationSubscriptionInvoice,
    );

    const [organizationSubscription, setOrganizationSubscription] = useState({} as OrganizationSubscription);

    const [loadingOrganizationSubscriptionPaymentPayment, setLoadingOrganizationSubscriptionPayment] = useState(false);
    const [organizationSubscriptionPaymentCount, setOrganizationSubscriptionPaymentCount] = useState(0);
    const [organizationSubscriptionPayments, setOragnizationSubscriptionPayments] = useState(
      [] as Array<OrganizationSubscriptionPayment>,
    );

    const paymentColumn = [
      { title: '#ID', dataIndex: 'id', key: 'id' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <OrganizationSubscriptionPaymentStatusTag status={status} />,
      },
      {
        title: 'Method',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (totalAmount) =>
          totalAmount === 0 ? (
            <Tag key="totalAmount" style={{ margin: 0 }}>
              Free
            </Tag>
          ) : (
            <Tag key="totalAmount" style={{ margin: 0 }}>
              {`$${(totalAmount / 100).toLocaleString()}`}
            </Tag>
          ),
      },
      {
        title: 'Stripe Link',
        dataIndex: 'stripeChargeId',
        key: 'stripeChargeId',
        render: (stripeChargeId) => (
          <a href={`https://dashboard.stripe.com/charges/${stripeChargeId}`} target="_blank">
            {stripeChargeId}
          </a>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'status',
        key: 'action',
        render: (
          status: OrganizationSubscriptionPayment['status'],
          organizationSubscriptionPayment: OrganizationSubscriptionPayment,
        ) => {
          const paymentMethod = organizationSubscriptionPayment.method;
          return (
            <>
              {paymentMethod === 'CARD' && status === 'SUCCESS' ? (
                <OrganizationSubscriptionPaymentRefundDialog
                  organizationSubscriptionPayment={organizationSubscriptionPayment}
                />
              ) : (
                '-'
              )}
            </>
          );
        },
      },
    ];

    const getOrganizationSubscriptionInvoice = ({ callback }) => {
      getOrganizationSubscriptionInvoiceRequest({
        uid: invoiceUid,
      })
        .then((response) => {
          setOrganizationSubscriptionInvoice(response.data);
          callback && callback(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const getOrganizationSubscription = ({ uid }) => {
      getOrganizationSubscriptionsRequest({ uid })
        .then((response) => {
          setOrganizationSubscription(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getOrganizationSubscriptionPayments = ({ invoiceUid }) => {
      setLoadingOrganizationSubscriptionPayment(true);
      getOrganizationSubscriptionPaymentsRequest({ invoiceUid })
        .then((response) => {
          setOragnizationSubscriptionPayments(response.data);
          setOrganizationSubscriptionPaymentCount(response.data.length);
          setLoadingOrganizationSubscriptionPayment(false);
        })
        .catch((error) => {
          setLoadingOrganizationSubscriptionPayment(false);
          console.error(error);
        });
    };

    const payNowButton = useMemo(() => {
      const isPayNowPossible =
        (organizationSubscriptionInvoice.status === 'PENDING' ||
        organizationSubscriptionInvoice.status === 'SCHEDULER_PENDING' ||
        organizationSubscriptionInvoice.status == 'FAILED')
        && organizationSubscriptionInvoice.paymentMethod !== 'MANUAL';

      if (isPayNowPossible && hasPermission(Permissions.MANAGE_PAYMENT)) {
        return (
          <OrganizationSubscriptionPaymentPayNowDialog
            organizationSubscriptionInvoice={organizationSubscriptionInvoice}
          />
        );
      } else {
        return;
      }
    }, [organizationSubscriptionInvoice]);

    const voidButton = useMemo(() => {
      const voidable =
        organizationSubscriptionInvoice.status === 'PENDING' ||
        organizationSubscriptionInvoice.status === 'SCHEDULER_PENDING' ||
        organizationSubscriptionInvoice.status === 'OVERDUE' ||
        organizationSubscriptionInvoice.status == 'FAILED';

      if (voidable && hasPermission(Permissions.MANAGE_PAYMENT)) {
        return (
          <UpdateInvoiceStatusDialog
            invoice={organizationSubscriptionInvoice}
          />
        );
      } else {
        return;
      }
    }, [organizationSubscriptionInvoice]);

    useEffect(() => {
      getOrganizationSubscriptionInvoice({
        callback: (invoice) => {
          if (invoice.subscription) {
            getOrganizationSubscription({uid: invoice.subscription.uid});
          }
          getOrganizationSubscriptionPayments({ invoiceUid: invoice.uid });
        },
      });
    }, []);

    return (
      <Layout>
        <PageTitle
          title="Invoice Detail"
          subTitle={`(${organizationSubscriptionInvoice.id || ''})`}
          showBack={true}
          extra={[payNowButton, voidButton]}
        />
        <Layout.Content>
          <OrganizationSubscriptionInvoiceInfo
            organizationSubscription={organizationSubscription}
            organizationSubscriptionInvoice={organizationSubscriptionInvoice}
          />
          <InvoiceItems invoiceUid={invoiceUid} />
          <TableDetail
            title="Payments"
            count={organizationSubscriptionPaymentCount}
            loading={loadingOrganizationSubscriptionPaymentPayment}
            columns={paymentColumn}
            dataSource={organizationSubscriptionPayments}
            pageSizeOptions={['5']}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
