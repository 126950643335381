import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getAwsUsageWithCostsRequest({ offset, limit, aws_region, sendbird_region, start_date, end_date }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/aws_usage_with_cost/`,
    {
      params: {
        offset,
        limit,
        aws_region,
        sendbird_region,
        start_date,
        end_date,
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getAwsUsageWithCostsFilterRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/aws_usage_with_cost/filters/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
