import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Tag, Space, Typography } from 'antd';

import { DynamicForm } from '../../components/ui/DynamicForm';
import { FormInstance } from 'antd/lib/form';
import {
  getCrmUserForForgottenRequestRequest,
  removeAllMembersFromCrmUserRequest,
  removeAllApplicationsFromCrmUserRequest,
  deleteCrmUser,
} from '../../api/crmUser';

const { Text } = Typography;

type Props = {
  crmUserId: string;
  disabled: boolean;
};

export const UnregisterCrmUserByForgottenRequestDialog: React.FC<Props> = ({ crmUserId, disabled }) => {
  const formRef = useRef<FormInstance>();
  const [crmUserForForgottenRequest, setCrmUserForForgottenRequest] = useState({} as any);
  const [serverResponse, setServerResponse] = useState({} as any);
  const [membersLoading, setMembersLoading] = useState(false);
  const [applicationsLoading, setApplicationsLoading] = useState(false);
  const [unregisterUserLoading, setUnregisterUserLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [visible, setVisible] = useState(false);

  const fields = (crmUserForForgottenRequest: any, serverResponse: string): any => [
    {
      name: 'warning',
      formItemProps: {},
      component: (
        <>
          CAUTION:
          <br />
          If an user is the owner of an organization,
          <br />
          the command will remove all applications and members.
        </>
      ),
    },
    {
      name: 'isOwner',
      label: 'Is Owner',
      formItemProps: {},
      component: (
        <Tag style={{ margin: 0 }} color={crmUserForForgottenRequest.isOwner ? 'red' : 'green'}>
          {crmUserForForgottenRequest.isOwner ? 'OWNER' : 'NON-OWNER'}
        </Tag>
      ),
    },
    {
      name: 'hasOtherMembers',
      label: 'Has Other Members',
      display: false,
      formItemProps: {},
      component: crmUserForForgottenRequest.isOwner ? (
        <Space>
          <Tag style={{ margin: 0 }} color={crmUserForForgottenRequest.hasOtherMembers ? 'red' : 'green'}>
            {String(crmUserForForgottenRequest.hasOtherMembers)}
          </Tag>
          {crmUserForForgottenRequest.hasOtherMembers ? (
            <Button key="submit" size="small" type="primary" loading={membersLoading} onClick={handleMembersSubmit}>
              Delete All Members
            </Button>
          ) : undefined}
        </Space>
      ) : (
        'Not related'
      ),
    },
    {
      name: 'hasApplications',
      label: 'Has Applications',
      formItemProps: {},
      component: crmUserForForgottenRequest.isOwner ? (
        <Space>
          <Tag style={{ margin: 0 }} color={crmUserForForgottenRequest.hasApplications ? 'red' : 'green'}>
            {String(crmUserForForgottenRequest.hasApplications)}
          </Tag>
          {crmUserForForgottenRequest.hasApplications ? (
            <Button
              key="submit"
              size="small"
              type="primary"
              loading={applicationsLoading}
              onClick={handleApplicationsSubmit}
            >
              Delete All Applications
            </Button>
          ) : undefined}
        </Space>
      ) : (
        'Not related'
      ),
    },
    {
      name: 'unregisterUser',
      formItemProps: {},
      component:
        !crmUserForForgottenRequest.hasApplications && !crmUserForForgottenRequest.hasOtherMembers ? (
          <Button
            key="submit"
            size="small"
            type="primary"
            loading={unregisterUserLoading}
            onClick={handleUnregisterUserSubmit}
            disabled={finished}
          >
            Unregister User
          </Button>
        ) : undefined,
    },
    {
      name: 'serverLog',
      label: 'logs',
      formItemProps: {},
      component: <Text style={{ whiteSpace: 'pre-line' }}>{serverResponse}</Text>,
    },
  ];

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
    getCrmUserForForgottenRequest();
  }

  function handleCancel() {
    formRef.current?.resetFields();
    setVisible(false);
    window.location.reload();
  }

  function getCrmUserForForgottenRequest() {
    getCrmUserForForgottenRequestRequest({ crmUserId })
      .then((response) => {
        setCrmUserForForgottenRequest(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(function () {
    getCrmUserForForgottenRequest();
    setServerResponse('');
  }, []);

  function handleMembersSubmit() {
    setMembersLoading(true);
    removeAllMembersFromCrmUserRequest({ crmUserId })
      .then((response) => {
        console.log(response.data.messages);
        setServerResponse(response.data.messages);
        getCrmUserForForgottenRequest();
      })
      .catch((error) => {
        console.error(error);
      });
    setMembersLoading(false);
  }

  function handleApplicationsSubmit() {
    setApplicationsLoading(true);
    removeAllApplicationsFromCrmUserRequest({ crmUserId })
      .then((response) => {
        console.log(response.data.messages);
        setServerResponse(response.data.messages);
        getCrmUserForForgottenRequest();
      })
      .catch((error) => {
        console.error(error);
      });
    setApplicationsLoading(false);
  }

  function handleUnregisterUserSubmit() {
    setUnregisterUserLoading(true);
    deleteCrmUser({ crmUserId })
      .then((response) => {
        console.log(response.data.messages);
        setServerResponse(response.data.messages);
        getCrmUserForForgottenRequest();
      })
      .catch((error) => {
        console.error(error);
      });
    setUnregisterUserLoading(false);
    setFinished(true);
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={showModal}
        style={{ marginLeft: '10px' }}
        disabled={disabled}
      >
        Unregister By Forgotten Request
      </Button>
      <Modal
        open={visible}
        title="Unregister By ForgottenRequest"
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <DynamicForm
          fields={fields(crmUserForForgottenRequest, serverResponse)}
          setForm={setForm}
          layout={'horizontal'}
        />
      </Modal>
    </React.Fragment>
  );
};
