import React from 'react';
import axios from 'axios';

import { Link, withRouter } from 'react-router-dom';
import { COUNTRY_CODE } from '../CountryCode';
import { API_DOMAIN } from '../App';

import TableStatusRow from './TableStatusRow';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableFooter from '@material-ui/core/TableFooter/TableFooter';
import TablePagination from '../components/CustomTablePagination';

import { gateAuth } from '../utils/auth';

class Users extends React.Component {
  state = {
    users: [],
    page: 1,
    isPaginationNextExist: true,
    searchQuery: '',
    apiStatus: 'LOADING',
    startDate: '2000-01-01',
    endDate: '2222-01-01',
    usersLimit: 30,
    usersOffset: 0,
    usersCount: 0
  };

  componentWillMount() {
    const q =
      this.props.location.search.split('q=').length > 1
        ? this.props.location.search.split('q=')[1].split('&')[0]
        : '';
    const startDate = this.props.location.search
      .split('startDate=')[1]
      .split('&')[0];
    const endDate = this.props.location.search
      .split('endDate=')[1]
      .split('&')[0];
    this.setState({ startDate: startDate, endDate: endDate, searchQuery: q });
    this.getUsers(
      this.state.usersOffset,
      this.state.usersLimit,
      q,
      startDate,
      endDate
    );
  }

  getUsers(
    offset = 0,
    limit = 0,
    searchQuery = '',
    startDate = '',
    endDate = ''
  ) {
    if (searchQuery === '') {
      searchQuery = this.state.searchQuery;
    }
    if (startDate === '') {
      startDate = this.state.startDate;
    }
    if (endDate === '') {
      endDate = this.state.endDate;
    }
    this.setState({ apiStatus: 'LOADING' }, () => {
      axios
        .get(
          `${API_DOMAIN}/api/gate_authusers/?start_date=${startDate}&end_date=${endDate}&limit=${limit}&offset=${offset}&query=${searchQuery}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          }
        )
        .then(response => {
          this.setState({
            users: response.data.results,
            apiStatus: 'SUCCESS',
            usersCount: response.data.count,
            usersOffset: offset
          });
        })
        .catch(error => {
          this.setState({ apiStatus: 'ERROR' }, () => {
            console.error(error);
            if (error.response.status === 401) {
              gateAuth.signout(() => this.props.history.push('/'));
            }
          });
        });
    });
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.setState(
      {
        users: [],
        apiStatus: 'LOADING',
        startDate: '2000-01-01',
        endDate: '2222-01-01'
      },
      () => {
        this.props.history.replace({
          pathname: '/gate_authusers',
          search: `?startDate=${this.state.startDate}&endDate=${
            this.state.endDate
          }&q=${this.state.searchQuery}`
        });
        this.getUsers(
          0,
          this.state.usersLimit,
          this.state.searchQuery,
          '2000-01-01',
          '2222-01-01'
        );
      }
    );
  }

  render() {
    const {
      users,
      apiStatus,
      usersLimit,
      usersCount,
      usersOffset
    } = this.state;

    const userRows =
      apiStatus === 'SUCCESS' && users.length > 0 ? (
        users.map(user => {
          return (
            <TableRow key={user.id}>
              <TableCell>
                <Link to={`/gate_authusers/${user.id}/detail/`}>{user.id}</Link>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.profile &&
                COUNTRY_CODE[user.profile.countryCode.toLowerCase()]
                  ? COUNTRY_CODE[user.profile.countryCode.toLowerCase()].name
                  : ''}
                {user.profile && user.profile.countryCode ? (
                  <span>({user.profile.countryCode})</span>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>{user.dateJoined}</TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableStatusRow text="No results." />
      );

    return (
      <Grid container>
        <Grid item xs={12}>
          <h2 className="mt-3">Users</h2>
          <form
            noValidate
            autoComplete="off"
            onSubmit={this.handleSearchSubmit.bind(this)}
          >
            <TextField
              placeholder="search users"
              margin="normal"
              defaultValue={
                this.state.searchQuery ? this.state.searchQuery : ''
              }
              onChange={e => this.setState({ searchQuery: e.target.value })}
            />
            <Button
              variant="outlined"
              onClick={this.handleSearchSubmit.bind(this)}
              style={{ marginTop: '1em', marginLeft: '1em' }}
            >
              Search
            </Button>
          </form>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Country Code</TableCell>
                  <TableCell>Joined</TableCell>ㄴ{' '}
                </TableRow>
              </TableHead>
              <TableBody>
                {apiStatus === 'LOADING' ? (
                  <TableStatusRow text="Loading..." />
                ) : (
                  userRows
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={usersCount}
                    onChangePage={(e, page) =>
                      this.setState(
                        { usersOffset: page * this.state.usersLimit },
                        () => {
                          this.getUsers(
                            this.state.usersOffset,
                            this.state.usersLimit
                          );
                        }
                      )
                    }
                    onChangeRowsPerPage={e =>
                      this.setState({ usersLimit: e.target.value }, () => {
                        this.getUsers(
                          this.state.usersOffset,
                          this.state.usersLimit
                        );
                      })
                    }
                    page={usersOffset / usersLimit}
                    rowsPerPage={usersLimit}
                    rowsPerPageOptions={[30, 60, 90]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Users);
