import React, { useState } from 'react';
import { Button, Modal } from 'antd';

type Props = {};

export const ApplicationAttributesRequestDialog: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          marginBottom: '1em',
        }}
      >
        Request
      </Button>
      <Modal
        open={visible}
        title={`Application Attributes Request`}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel{' '}
          </Button>,
          <Button key="submit" type="primary" loading={loading}>
            Submit
          </Button>,
        ]}
      ></Modal>
    </React.Fragment>
  );
};
