import React from 'react';
import styled from 'styled-components';
import {Badge, Col, Row, Typography} from 'antd';
import {BadgeProps} from 'antd/lib/badge';
import {ColumnProps, TableProps} from 'antd/lib/table';
import {PaginationProps} from 'antd/lib/pagination';

import {ResponsiveTable} from './ResponsiveTable';
import {InnerSpinner} from './Spinner';

const { Title } = Typography;

type Props = {
  title?: React.ReactNode;
  count: number;
  loading: TableProps<any>['loading'];
  rowStyle?: React.CSSProperties;
  badgeOffset?: BadgeProps['offset'];
  badgeStyle?: BadgeProps['style'];
  columns: Array<ColumnProps<any>>;
  dataSource: TableProps<any>['dataSource'];
  defaultPageSize?: PaginationProps['defaultPageSize'];
  pageSizeOptions?: PaginationProps['pageSizeOptions'];
  rightColumnComponent?: React.ReactNode;
  onRow?: TableProps<any>['onRow'];
  onPageChange?: (payload: { offset: number; limit: number; page: number }) => void;
  scroll?: TableProps<any>['scroll'];
  leftColumnLg: number;
};

const StyledTableDetail = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const TableDetail: React.FC<Props> = React.memo(
  ({
    title = '',
    count = 0,
    rowStyle = {},
    badgeOffset = [20, 14] as BadgeProps['offset'],
    badgeStyle = { backgroundColor: '#52c41a' },
    loading = false,
    columns = [],
    dataSource = [],
    defaultPageSize,
    pageSizeOptions = ['10', '15', '30'],
    children,
    rightColumnComponent,
    onRow,
    onPageChange,
    scroll,
    leftColumnLg = 8
  }) => {
    const handlePageChange = (page, pageSize) => {
      onPageChange && onPageChange({ offset: (page - 1) * pageSize, limit: pageSize, page });
    };

    return (
      <StyledTableDetail>
        <Row style={rowStyle}>
          <Col span={24} lg={{ span: leftColumnLg }}>
            <Badge count={count} offset={badgeOffset} style={badgeStyle}>
              <Title level={4}>{title} </Title>
            </Badge>
          </Col>
          <Col span={24} lg={{ span: 16 }} style={{ textAlign: 'right' }}>
            {rightColumnComponent}
          </Col>
          <Col span={24}>
            {children}
            <ResponsiveTable
              loading={loading ? { indicator: <InnerSpinner /> } : false}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                total: count,
                defaultPageSize: defaultPageSize ? defaultPageSize : parseInt(pageSizeOptions[0]),
                pageSizeOptions,
                onChange: handlePageChange,
                onShowSizeChange: handlePageChange,
              }}
              onRow={onRow}
              scroll={scroll ? scroll : { x: '100%' }}
            />
          </Col>
        </Row>
      </StyledTableDetail>
    );
  },
);
