import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { LineChartComponent } from '../../components/LineChartComponent';

import { useCheckMobile } from '../../utils/screen';
import { getSearchDate, getSearchMonth } from '../../utils/date';
import { getCrmPaymentsDailyChargedRequest, getCrmPaymentsMonthlyChargedRequest } from '../../api/billing';

const { Title } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];
function createColumns({ dataSources, isMobile }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: isMobile ? false : 'left',
      width: '100px',
    },
  ];

  dataSources.forEach((data, columnIndex) => {
    columns.push({
      title: data.date,
      dataIndex: `count-${columnIndex}`,
      key: `count-${columnIndex}`,
      align: alignRight,
      render: (value: number) => value && value.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSources, titles }) {
  const keys = dataSources.length > 0 ? Object.keys(dataSources[0]) : [];
  let source = [] as any;
  keys.map((key) => {
    const row = {};
    if (key !== 'date') {
      row['key'] = key;
      row['title'] = titles[key];
      dataSources.forEach((data, index) => {
        row[`count-${index}`] = data[key];
      });
      source.push(row);
    }
  });

  return source;
}

export const DashboardBilling = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dataSourceDaily, setDataSourceDaily] = useState<Array<DashboardBilling>>([]);
  const [dataSourceMonthly, setDataSourceMonthly] = useState<Array<DashboardBilling>>([]);
  const [dailyLoading, setDailyLoading] = useState<boolean>(false);
  const [monthlyLoading, setMonthlyLoading] = useState<boolean>(false);

  function getCrmPaymentsDailyCharged() {
    const { startDate, endDate } = getSearchDate({ days: 14 });

    setDailyLoading(true);
    getCrmPaymentsDailyChargedRequest({ startDate, endDate })
      .then((response) => {
        setDataSourceDaily(response.data.results);
        setDailyLoading(false);
      })
      .catch((error) => {
        setDailyLoading(false);
        error && console.error(error.response);
      });
  }

  function getCrmPaymentsMonthlyCharged() {
    const { startMonth, endMonth } = getSearchMonth();

    setMonthlyLoading(true);
    getCrmPaymentsMonthlyChargedRequest({ startMonth, endMonth })
      .then((response) => {
        setDataSourceMonthly(response.data.results);
        setMonthlyLoading(false);
      })
      .catch((error) => {
        setMonthlyLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getCrmPaymentsDailyCharged();
    getCrmPaymentsMonthlyCharged();
  }, []);

  const titles = {
    card: 'Card',
    wire: 'Wire',
    sum: 'Total',
  };

  const dailyColumns = createColumns({
    dataSources: dataSourceDaily,
    isMobile,
  });

  const dailySources = convertDataSource({
    dataSources: dataSourceDaily,
    titles,
  });

  const monthlyColumns = createColumns({
    dataSources: dataSourceMonthly,
    isMobile,
  });

  const monthlySources = convertDataSource({
    dataSources: dataSourceMonthly,
    titles,
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Payments(Charged) statistics" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24} md={{ span: 12 }} style={{ marginBottom: 16 }}>
              <Card>
                <Title level={4}>Payments(Charged) - Daily</Title>
                {dailySources.length > 0 ? (
                  <LineChartComponent
                    title={`Payments(Charged) - Daily`}
                    unit="day"
                    labels={dataSourceDaily.map((data) => data.date)}
                    data1={dataSourceDaily.map((data) => data.sum)}
                  />
                ) : null}
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dailyLoading}
                  columns={dailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={dailySources}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Card>
                <Title level={4}>Payments(Charged) - Monthly</Title>
                {monthlySources ? (
                  <LineChartComponent
                    title={`Payments(Charged) - Monthly`}
                    unit="month"
                    labels={dataSourceMonthly.map((data) => data.date)}
                    data1={dataSourceMonthly.map((data) => data.sum)}
                  />
                ) : null}
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={monthlyLoading}
                  columns={monthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={monthlySources}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
