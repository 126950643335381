import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Col, Row, Typography, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ResponsiveTable } from '../components/ui/ResponsiveTable';

import { dateTimePattern, getSearchDate } from '../utils/date';
import { getApplicationCountsRequest } from '../api/application';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const alignRight = 'right' as ColumnProps<any>['align'];
const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Registered Apps(New)',
    dataIndex: 'new',
    key: 'new',
    align: alignRight,
    render: (newCount: ApplicationCount['new']) => newCount.toLocaleString(),
  },
  {
    title: 'Registered App(Cumulative)',
    dataIndex: 'cumulative',
    key: 'cumulative',
    align: alignRight,
    render: (culumative: DashboardUserCount['cumulative']) =>
      culumative ? culumative.toLocaleString() : '0',
  },
  {
    title: 'Installed Apps(New)',
    dataIndex: 'installedNew',
    key: 'installedNew',
    align: alignRight,
    render: (installedNew: ApplicationCount['installedNew']) =>
      installedNew.toLocaleString(),
  },
  {
    title: 'Installed Apps(New)',
    dataIndex: 'installedCumulative',
    key: 'installedCumulative',
    align: alignRight,
    render: (installedCumulative: ApplicationCount['installedCumulative']) =>
      installedCumulative.toLocaleString(),
  },
  {
    title: 'Messaged Apps',
    dataIndex: 'messaged',
    key: 'messaged',
    align: alignRight,
    render: (messaged: ApplicationCount['messaged']) =>
      messaged.toLocaleString(),
  },
  {
    title: 'Connected Apps',
    dataIndex: 'connected',
    key: 'connected',
    align: alignRight,
    render: (connected: ApplicationCount['connected']) =>
      connected.toLocaleString(),
  },
];

type Props = RouteComponentProps;

export const ApplicationCounts: React.FC<Props> = () => {
  const searchDate = getSearchDate({ days: 14 });
  const [apps, setApps] = useState<Array<ApplicationCount>>([]);
  const [loading, setLoading] = useState(false);

  function getApplicationCounts({ startDate, endDate }) {
    setLoading(true);
    getApplicationCountsRequest({ startDate, endDate })
      .then((response) => {
        setApps(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        error && error.response && console.error(error.response);
      });
  }

  useEffect(function () {
    getApplicationCounts({
      startDate: searchDate.startDate,
      endDate: searchDate.endDate,
    });
  }, []);

  function handleCalendarChange(_, dateStrings) {
    const [startDateString, endDateString] = dateStrings;
    getApplicationCounts({
      startDate: startDateString,
      endDate: endDateString,
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  return (
    <React.Fragment>
      <Row gutter={8} style={{ marginBottom: '8px' }}>
        <Col span={24}>
          <Title level={3}>Application Counts - Daily</Title>
        </Col>
        <Col span={24} style={{ marginBottom: 8 }}>
          <RangePicker
            onChange={handleCalendarChange}
            defaultValue={
              [moment(searchDate.startDate), moment(searchDate.endDate)] as any
            }
            disabledDate={disabledDate}
            format={dateTimePattern.date}
            style={{ maxWidth: 300, width: '100%' }}
          />
        </Col>
      </Row>

      <ResponsiveTable
        loading={loading}
        columns={columns}
        dataSource={apps}
        pagination={{
          total: apps.length,
          pageSizeOptions: ['30', '50', '100'],
        }}
      />
    </React.Fragment>
  );
};
