import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Tag, Layout } from 'antd';

import {
  getCrmUserRequest,
  getCrmOrganizationMembersRequest
} from '../../api/crmUser';

import { CrmUserInfo } from '../../components/CrmUserInfo';
import { PageTitle } from '../../components/ui/PageTitle';

import { TableDetail } from '../../components/ui/TableDetail';
import { hasPermission, Permissions } from '../../utils/permission';
import { UnregisterCrmUserByForgottenRequestDialog } from './UnregisterCrmUserByForgottenRequestDialog'

const organizationMembersColumns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Organization',
    dataIndex: 'crmOrganization',
    render: (crmOrganization: CrmOrganization) =>
      crmOrganization ? (
        <Link to={`/crm_organizations/${crmOrganization.id}/`}>
          {crmOrganization.id} ({crmOrganization.name})
        </Link>
      ) : (
        ''
      ),
  },
  {
    title: 'Member Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Member Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CrmOrganizationMember['status']) => (
      <Tag color={status === 'ACTIVE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {status}
      </Tag>
    ),
  },
  {
    title: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

interface MatchParams {
  crmUserId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const CrmUser = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { crmUserId } = match.params;
    const [crmUser, setCrmUser] = useState<CrmUser>({} as CrmUser);

    const [crmOrganizationMembers, setCrmOrganizationMembers] = useState<
      Array<CrmOrganizationMember>
    >([]);

    const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
    const [membersCount, setMembersCount] = useState<number>(0);

    function getCrmUser({ callback }) {
      getCrmUserRequest({ crmUserId })
        .then((response) => {
          setCrmUser(response.data);
          callback && callback();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function getCrmOrganizationMembers({ offset = 0, limit = 0 }) {
      setLoadingMembers(true);
      getCrmOrganizationMembersRequest({ limit, offset, crmUserId })
        .then((response) => {
          setCrmOrganizationMembers(response.data.results);
          setLoadingMembers(false);
          setMembersCount(response.data.count);
        })
        .catch((error) => {
          console.error(error);
          setLoadingMembers(false);
        });
    }

    useEffect(() => {
      getCrmUser({
        callback: function () {
          getCrmOrganizationMembers({
            offset: 0,
            limit: 10,
          });
        },
      });
    }, []);

    return (
      <Layout>
        <PageTitle
          title="User Detail"
          subTitle={<>
                #{(crmUser.id && crmUser.id.toString()) || ''}
                {hasPermission(Permissions.MANAGE_FORGOTTEN_REQUEST) ? (
                  <UnregisterCrmUserByForgottenRequestDialog crmUserId={crmUserId} disabled={!crmUser.isActive}/>
                ) : (
                  ''
                )}
              </>}
          showBack={true}
        />
        <Layout.Content>
          <CrmUserInfo crmUser={crmUser} />
          <TableDetail
            title="Organization Members"
            count={membersCount}
            loading={loadingMembers}
            columns={organizationMembersColumns}
            dataSource={crmOrganizationMembers}
            onPageChange={getCrmOrganizationMembers}
          />
        </Layout.Content>
      </Layout>
    );
  }),
);
