import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getDatabaseOtpRequest } from '../../api/otp';

import { DatabaseOtpDetailInfo } from '../../components/DatabaseOtpDetailInfo';
import { PageTitle } from '../../components/ui/PageTitle';

interface MatchParams {
  databaseOtpId: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export const DatabaseOtpDetail = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { databaseOtpId } = match.params;

    const [databaseOtp, setDatabaseOtp] = useState({} as DatabaseOtp);

    function getDatabaseOtp() {
      getDatabaseOtpRequest({ databaseOtpId })
        .then((response) => {
          setDatabaseOtp(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getDatabaseOtp();
    }, []);

    return (
      <React.Fragment>
        <PageTitle
          title="Database OTP Detail"
          subTitle={`#${(databaseOtp.id && databaseOtp.id.toString()) || ''}`}
          showBack={true}
        />
        <DatabaseOtpDetailInfo databaseOtp={databaseOtp} />
      </React.Fragment>
    );
  }),
);
