import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TableDetail } from '../ui/TableDetail';
import { getOrganizationSubscriptionInvoicesRequest } from '../../api/billing';
import { InvoiceStatusTag } from '../ui/Tags';
import { dateTimePattern } from '../../utils/date';
import { OnetimeInvoiceCreateDialog } from './OnetimeInvoiceCreateDialog';

export const OrganizationInvoices = ({ crmOrganizationUid = '', subscriptionUid = '' }) => {
  const [loadingOrganizationSubscriptionInvoices, setLoadingOrganizationSubscriptionInvoices] = useState(false);
  const [organizationSubscriptionInvoiceCount, setOrganizationSubscriptionInvoiceCount] = useState(0);
  const [organizationSubscriptionInvoices, setOrganizationSubscriptionInvoices] = useState(
    [] as Array<OrganizationSubscriptionInvoice>,
  );
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 20, offset: 0 });

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id, invoice) => <Link to={`/organization_invoices/${invoice.uid}/`}>{id}</Link>,
    },
    ...(crmOrganizationUid
      ? [
          {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription',
            render: (_, invoice) => {
              if (invoice.subscription) {
                return (
                  <Link to={`/organization_subscription/${invoice.subscription.uid}/`}>
                    {`${invoice.subscription.displayName}`}
                  </Link>
                );
              } else {
                return '';
              }
            },
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <InvoiceStatusTag status={status} />,
    },
    {
      title: 'Invoice type',
      dataIndex: 'invoiceType',
      key: 'invoiceType',
    },
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => `$${Math.floor(totalAmount) / 100}`,
    },
    {
      title: (
        <span>
          Period <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, invoice) =>
        `${moment(invoice.startDate).format(dateTimePattern.date)} - ${moment(invoice.endDate).format(
          dateTimePattern.date,
        )}`,
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => `${moment(createdAt).utc().format(dateTimePattern.default)}`,
    },
  ];

  const getOrganizationSubscriptionInvoices = useCallback(() => {
    const params = {
      ...(subscriptionUid ? { subscriptionUid } : {}),
      ...(crmOrganizationUid ? { organizationUid: crmOrganizationUid } : {}),
    };
    setLoadingOrganizationSubscriptionInvoices(true);
    getOrganizationSubscriptionInvoicesRequest({
      ...params,
      ...pagination,
    })
      .then((response) => {
        setOrganizationSubscriptionInvoices(response.data.results);
        setOrganizationSubscriptionInvoiceCount(response.data.count);
        setLoadingOrganizationSubscriptionInvoices(false);
      })
      .catch((err) => {
        setLoadingOrganizationSubscriptionInvoices(false);
        console.error(err);
      });
  }, [crmOrganizationUid, subscriptionUid, pagination]);

  function refresh() {
    window.location.reload();
  }

  useEffect(() => {
    getOrganizationSubscriptionInvoices();
  }, [getOrganizationSubscriptionInvoices]);
  return (
    <TableDetail
      title="Invoices"
      count={organizationSubscriptionInvoiceCount}
      loading={loadingOrganizationSubscriptionInvoices}
      columns={columns}
      dataSource={organizationSubscriptionInvoices}
      onPageChange={setPagination}
      pageSizeOptions={['20', '40', '60']}
      rightColumnComponent={
        <OnetimeInvoiceCreateDialog
          crmOrganizationId={crmOrganizationUid}
          organizationSubscriptionUid={subscriptionUid}
          successCallback={refresh}
        />
      }
    />
  );
};
