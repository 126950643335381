import * as React from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Collapse, Descriptions, List, Typography} from 'antd';

type MetricPageInformationProps = {
  className?: string;
  description?: string | string[];
  terms?: string[];
  formulas?: string[];
  dataSource?: string;
};

type DescriptionParagraphProps = {
  description?: string | string[];
};

const DescriptionParagraph = ({ description }: DescriptionParagraphProps) =>
  description && description.length ? (
    <List
      style={{ marginBottom: 8 }}
      size="small"
      dataSource={typeof description === 'string' ? [description] : description}
      split={false}
      renderItem={item => (
        <List.Item>
          <InfoCircleOutlined style={{ marginRight: 8 }} />
          {item}
        </List.Item>
      )}
    />
  ) : null;

export const MetricPageInformation = ({
  className,
  description,
  terms,
  formulas,
  dataSource,
}: MetricPageInformationProps) => {
  return (
    <div style={{ marginTop: 7 }} className={className}>
      <DescriptionParagraph description={description} />
      {(terms || formulas || dataSource) && (
        <Collapse>
          <Collapse.Panel key="1" header="Terms, formulas, data source">
            <Descriptions bordered={true} column={1}>
              {[
                terms && (
                  <Descriptions.Item label="Terms">
                    <dl style={{ marginBottom: 0 }}>
                      {terms
                        .map(item => item.split(/:(.+)/))
                        .map(([term, definition]) => {
                          return (
                            <React.Fragment key={`${term}-${definition}`}>
                              <dt>{term}</dt>
                              <dd>{definition}</dd>
                            </React.Fragment>
                          );
                        })}
                    </dl>
                  </Descriptions.Item>
                ),
                formulas && (
                  <Descriptions.Item label="Formulas">
                    <List
                      dataSource={formulas}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text code>{item}</Typography.Text>
                        </List.Item>
                      )}
                      split={false}
                    />
                  </Descriptions.Item>
                ),
                dataSource && (
                  <Descriptions.Item label="Data source">
                    <>{dataSource}</>
                  </Descriptions.Item>
                ),
              ].filter(nonNullValue => nonNullValue)}
            </Descriptions>
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};
