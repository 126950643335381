import React, { useState, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input } from 'antd';

import { DynamicForm } from '../../components/ui/DynamicForm';

import { createUserMigrationRequestRequest } from '../../api/userMigrationRequest';
import { FormInstance } from 'antd/lib/form';

const { TextArea } = Input;

type Props = {
  successCallback: any;
};

export const UserMigrationRequestCreateDialog: React.FC<Props> = ({ successCallback }) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fields: any = [
    {
      name: 'userToMigrateEmail',
      label: 'User Email',
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      component: <Input />,
    },
    {
      name: 'fromOrganizationId',
      label: (
        <span>
          From Organization Crm ID&nbsp;
          <small style={{ opacity: 0.5 }}>
            (If this is void, please ask the customer to send an invitation for this action)
          </small>
        </span>
      ),
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      component: <Input />,
    },
    {
      name: 'toOrganizationId',
      label: 'To Organization Crm ID',
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      component: <Input />,
    },
    {
      name: 'role',
      label: 'Role (case sensitive)',
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      component: <Input />,
    },
    {
      name: 'reason',
      label: 'Reason',
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      component: <TextArea rows={4} />,
    },
  ];

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { userToMigrateEmail, fromOrganizationId = '', toOrganizationId, role, reason } = values;

          createUserMigrationRequestRequest({
            userToMigrateEmail,
            fromOrganizationId,
            toOrganizationId,
            role,
            reason,
          })
            .then((response) => {
              setLoading(false);
              handleCancel();
              formRef.current?.resetFields();
              successCallback(response.data);
            })
            .catch((error) => {
              console.error('error', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Create
      </Button>
      <Modal
        open={visible}
        title="Create a User Migration Request"
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Create
          </Button>,
        ]}
      >
        <DynamicForm fields={fields} setForm={setForm} />
      </Modal>
    </React.Fragment>
  );
};
