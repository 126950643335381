import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';

import { getUserRolesRequest } from '../../api/roles';

const { Title, Paragraph } = Typography;

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/user_roles/${id}`}>{id}</Link>,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user: UserRole['user']) => (user ? <Link to={`/users/${user.id}`}>{user.username}</Link> : '-'),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role: UserRole['role']) => (role ? <Link to={`/roles/${role.id}`}>{role.name}</Link> : '-'),
  },
  {
    title: (
      <span>
        Granted At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: (
      <span>
        Expired At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt: UserPermission['expiredAt']) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

type Props = RouteComponentProps;

export const UserRoles = withRouter<Props, any>(({ location }) => {
  const [roles, setRoles] = useState<Array<UserRole>>([]);
  const [loading, setLoading] = useState(false);
  const [rolesCount, setRolesCount] = useState(0);

  async function getUserRoles(limit, offset) {
    setLoading(true);
    try {
      const response = await getUserRolesRequest({
        limit,
        offset,
        user_id: '',
        role_id: '',
      });
      setRoles(response.data.results);
      setLoading(false);
      setRolesCount(response.data.count);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPageChange(page, perPage) {
    const offset = (page - 1) * perPage;
    const limit = perPage;
    getUserRoles(offset, limit);
  }

  function handleOnRow(userRole: UserRole) {
    return {
      style: {
        textDecoration: userRole.status === 'EXPIRED' ? 'line-through' : '',
        opacity: userRole.status === 'EXPIRED' ? 0.5 : 1,
      },
    };
  }

  useEffect(() => {
    getUserRoles(30, 0);
  }, []);

  return (
    <Row>
      <Col>
        <Title level={3}>Granted Roles</Title>
        <Paragraph>List of all 1:1 entries of user to each of her or his granted role</Paragraph>
      </Col>
      <Col>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={roles}
          pagination={{
            total: rolesCount,
            pageSizeOptions: ['30', '60', '90'],
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Col>
    </Row>
  );
});
