import * as React from 'react';
import { DashboardGrowthSignupUsers } from './DashboardGrowthSignupUsers';
import { DashboardGrowthApplications } from './DashboardGrowthApplications';

export const DashboardGrowth = React.memo(() => {
  return (
    <>
      <DashboardGrowthSignupUsers />
      <DashboardGrowthApplications />
    </>
  );
});
