import React from 'react';
import styled from 'styled-components';
import {BackTop, Layout, Typography} from 'antd';
import {MetricPageInformation} from './MetricPageInformation';
import {MetricPageKeyMetrics} from './MetricPageKeyMetrics';
import {PageTitle} from './PageTitle';
import {MetricPageChartRow} from './MetricPageChartRow';
import {MetricPageChartFilter} from './MetricPageChartFilter';
import {MetricPageOutline} from './MetricPageOutline';
import {MetricPermissionInfoComponent} from '../MetricPermissionInfo';

type ContainerProps = {
  title: string;
  children?: React.ReactNode;
};

type MetricSectionProps = {
  id?: string;
  className?: string;
  title: string;
  children?: React.ReactNode;
  metricPermissionInfo?: MetricPermissionInfo;
};

const SectionTitle = styled(Typography.Title)``;

const Information = styled(MetricPageInformation)``;

const KeyMetrics = styled(MetricPageKeyMetrics)``;

const ChartFilter = styled(MetricPageChartFilter)``;

const ChartRow = styled(MetricPageChartRow)``;

const Outline = styled(MetricPageOutline)``;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;

  ${SectionTitle} {
    margin-right: 12px;
    margin-bottom: 0;
    margin-top: -2px;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
  background: white;
  padding: 16px;
`;

const MetricSection = styled(
  ({
    id,
    className,
    title,
    children,
    metricPermissionInfo,
  }: MetricSectionProps) => (
    <Section id={id} className={className}>
      <TitleWrapper>
        <SectionTitle level={3}>{title}</SectionTitle>
        {metricPermissionInfo && (
          <MetricPermissionInfoComponent
            metricPermissionInfo={{
              permission:
                metricPermissionInfo && metricPermissionInfo.permission,
              authUsers: metricPermissionInfo && metricPermissionInfo.authUsers,
              authRoles: metricPermissionInfo && metricPermissionInfo.authRoles,
            }}
          />
        )}
      </TitleWrapper>
      <div className="MetricSection__childrenWrapper">{children}</div>
    </Section>
  ),
)``;

const ContainerDiv = styled(Layout)`
  > ${Section} {
    margin-bottom: 48px;
  }
  ${MetricSection} {
    margin-bottom: 64px;
    .MetricSection__childrenWrapper {
      padding-left: 32px;
      padding-right: 32px;
      > *:not(${Information}):first-child {
        // Information has vertical padding inside but the others needs more top margin
        margin-top: 24px;
      }
      > *:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  ${ChartFilter} + ${ChartRow} {
    margin-top: 32px;
  }
  .ant-back-top {
    right: 64px;
  }
`;

const backTopTarget = () => document.getElementById('Routes__Layout') || window;

const Container = ({ title, children }: ContainerProps) => (
  <ContainerDiv>
    {title ? <PageTitle title={title} showBack={true} /> : ''}
    <Layout.Content>
      {children}
      <BackTop target={backTopTarget} />
    </Layout.Content>
  </ContainerDiv>
);

export const MetricPageLayout = {
  Container,
  Information,
  KeyMetrics,
  Section,
  MetricSection,
  ChartFilter,
  ChartRow,
  Outline,
};
