import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tag, Typography } from 'antd';

import { SetApplicationPlanTypeDialog } from './SetApplicationPlanTypeDialog';
import { InfoCards } from '../components/InfoCard';
import { MetricCard } from '../components/MetricCards';
import { PlanTag, RegionTag, CrmOrganizationVersionTypeTag } from '../components/ui/Tags';
import { ApplicationPausedDateEditDialog } from './ApplicationPausedDateEditDialog';

const { Text } = Typography;

const StripeLink: React.FC<{
  stripeCustomerId: CrmOrganization['stripeCustomerId'];
}> = ({ stripeCustomerId }) => {
  return stripeCustomerId ? (
    <a href={`https://dashboard.stripe.com/customers/${stripeCustomerId}`} target="_blank" rel="noopener noreferrer">
      stripe ({stripeCustomerId})
    </a>
  ) : null;
};

const metrics = (crmApplication: CrmApplication) => [
  {
    key: 'Average DAU',
    value: crmApplication.lastSevenDaysAverageDau && crmApplication.lastSevenDaysAverageDau.toLocaleString(),
  },
  {
    key: 'Peak Connection',
    value: crmApplication.lastSevenDaysPeakConnection && crmApplication.lastSevenDaysPeakConnection.toLocaleString(),
  },
  {
    key: 'Average Message Count',
    value:
      crmApplication.lastSevenDaysAverageMessageCount &&
      crmApplication.lastSevenDaysAverageMessageCount.toLocaleString(),
  },
];

const applicationGeneral = (
  crmApplication: CrmApplication,
  getCrmApplicationRequest: PrivateRouteProps['getCrmApplicationRequest'],
) => [
  {
    key: 'Crm ID',
    value: (
      <React.Fragment>
        {crmApplication.id} <small>(Soda ID: {crmApplication.sodaApplicationId})</small>
      </React.Fragment>
    ),
  },
  {
    key: 'App ID',
    value: <Text copyable={true}>{crmApplication.appId}</Text>,
  },
  {
    key: 'Region',
    value: crmApplication.region ? <RegionTag region={crmApplication.region} /> : '',
  },
  {
    key: 'App Name',
    value: crmApplication.appName,
  },
  {
    key: 'Plan',
    value: (() => {
      const isV2 = crmApplication.crmOrganization ? crmApplication.crmOrganization.isSelfServe : false;
      if (crmApplication.removed || crmApplication.plan === '' || isV2) {
        return '-';
      }
      return (
        <>
          <PlanTag
            plan={crmApplication.plan}
            style={!(crmApplication.crmOrganization && isV2) ? undefined : { margin: 0 }}
          />
          {!(crmApplication.crmOrganization && isV2) ? (
            <SetApplicationPlanTypeDialog
              appId={crmApplication.appId}
              plan={crmApplication.plan}
              successCallback={getCrmApplicationRequest}
            />
          ) : null}
        </>
      );
    })(),
  },
  {
    key: 'Desk Project',
    value: crmApplication.crmdeskproject ? (
      <Link to={`/crm_desk_projects/${crmApplication.crmdeskproject}`}>{crmApplication.crmdeskproject}</Link>
    ) : (
      '-'
    ),
  },
  {
    key: 'Paused Date',
    value: (
      <>
        {crmApplication.pausedDate || '-'}
        {['glen.kim', 'vincent.zhou'].includes(localStorage.getItem('username') || '') ? (
          <ApplicationPausedDateEditDialog
            appId={crmApplication.appId}
            pausedDate={crmApplication.pausedDate}
            successCallback={getCrmApplicationRequest}
          />
        ) : (
          ''
        )}
      </>
    ),
  },
];
const applicationDetail = (crmApplication: CrmApplication) => [
  {
    key: 'Organization',
    value: crmApplication.crmOrganization ? (
      <Link to={`/crm_organizations/${crmApplication.crmOrganization.id}/`}>
        {crmApplication.crmOrganization.id} ({crmApplication.crmOrganization.name})
      </Link>
    ) : (
      ''
    ),
  },
  {
    key: 'Organization Type',
    value: crmApplication.crmOrganization ? (
      <CrmOrganizationVersionTypeTag isSelfServe={crmApplication.crmOrganization.isSelfServe} />
    ) : (
      ''
    ),
  },
  {
    key: 'Organization Card',
    value: crmApplication.crmOrganization ? (
      <StripeLink stripeCustomerId={crmApplication.crmOrganization.stripeCustomerId} />
    ) : (
      ''
    ),
  },
  {
    key: (
      <span>
        Created At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    value: crmApplication.createdAt,
  },
  {
    key: (
      <span>
        Sdk Integrated At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    value: crmApplication.sdkIntegratedAt ? crmApplication.sdkIntegratedAt : '-',
  },
  {
    key: 'Calls enabled',
    value: <Tag style={{ margin: 0 }}>{crmApplication.callEnabled ? 'ENABLED' : 'DISABLED'}</Tag>,
  },
  {
    key: 'Notification enabled',
    value: <Tag style={{ margin: 0 }}>{crmApplication.pushEnabled ? 'ENABLED' : 'DISABLED'}</Tag>,
  },
  {
    key: 'Status',
    value: (
      <Tag color={crmApplication.removed ? 'red' : 'green'} style={{ margin: 0 }}>
        {crmApplication.removed ? 'INACTIVE' : 'ACTIVE'}
      </Tag>
    ),
  },
];

type Props = {
  crmApplication: CrmApplication;
  getCrmApplicationRequest: PrivateRouteProps['getCrmApplicationRequest'];
};

export const ApplicationDetailInfo: React.FC<Props> = ({ crmApplication, getCrmApplicationRequest }) => {
  const infos = [applicationGeneral(crmApplication, getCrmApplicationRequest), applicationDetail(crmApplication)];

  return (
    <Row gutter={16}>
      <Col span={24} style={{ marginBottom: '16px' }}>
        <MetricCard
          title={
            <span>
              Metrics <small style={{ fontWeight: 'normal' }}>(Last 7 days)</small>
            </span>
          }
          dataSource={metrics(crmApplication)}
          rowStyle={{ marginBottom: '16px' }}
        />
        <InfoCards dataSources={infos} minHeight="300px" />
      </Col>
    </Row>
  );
};
