// crmOrganization IDs
export const OrganizationPlanAuthorizedOrganizations = [
  '40626',
  '42719',
  '48066',
  '52324',
  '55513',
  '61462',
  '62524',
  '62966',
  '62486',
  '62486',
  '63474',
  '60838',
  '46020',
  '62154',
  '65448',
];
