import React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Layout } from 'antd';

import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import {PermissionStatusTag, UserStateTag} from '../../components/ui/Tags';
import { PageTitle } from '../../components/ui/PageTitle';

import { getUserPermissionsRequest } from '../../api/permissions';

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/user_permissions/${id}`}>{id}</Link>,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    render: (user: UserPermission['user']) => (user ? <Link to={`/users/${user.id}`}>{user.username}</Link> : '-'),
  },
  {
    title: 'User state',
    dataIndex: 'user',
    key: 'user',
    render: (user: UserPermission['user']) => <UserStateTag state={user.state} />,
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    key: 'permission',
    render: (permission: UserPermission['permission']) =>
      permission ? <Link to={`/permissions/${permission.id}`}>{permission.name}</Link> : '-',
  },
  {
    title: (
      <span>
        Granted At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'grantedAt',
    key: 'grantedAt',
  },
  {
    title: (
      <span>
        Expired At <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    render: (expiredAt: UserPermission['expiredAt']) => (expiredAt ? expiredAt : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: UserPermission['status']) => <PermissionStatusTag status={status} />,
  },
];

type Props = RouteComponentProps;

export const UserPermissions = withRouter<Props, any>(({ location }) => {
  const [userPermissions, setUserPermissions] = useState<Array<UserPermission>>([]);
  const [loading, setLoading] = useState(false);
  const [permissionsCount, setPermissionsCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 30,
  });

  async function getUserPermissions(offset: number = 0, limit: number = 0) {
    setLoading(true);
    try {
      const response = await getUserPermissionsRequest({
        offset,
        limit,
        userId: '',
        permissionId: '',
      });
      setUserPermissions(response.data.results);
      setLoading(false);
      setPermissionsCount(response.data.count);
      setPagination({
        offset,
        limit,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPageChange(page, limit) {
    const offset = (page - 1) * limit;
    setPagination({
      offset,
      limit,
    });
    getUserPermissions(offset, limit);
  }

  function handleOnRow(userPermission: UserPermission) {
    return {
      style: {
        textDecoration: userPermission.status === 'EXPIRED' ? 'line-through' : '',
        opacity: userPermission.status === 'EXPIRED' ? 0.5 : 1,
      },
    };
  }

  useEffect(() => {
    getUserPermissions(pagination.offset, pagination.limit);
  }, []);

  return (
    <Layout>
      <PageTitle
        title="Granted Permissions"
        subTitle="List of all 1:1 entries of user to each of her or his granted permission"
      />
      <Layout.Content>
        <ResponsiveTable
          loading={loading}
          columns={columns}
          dataSource={userPermissions}
          pagination={{
            total: permissionsCount,
            pageSizeOptions: ['30', '60', '90'],
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
          }}
          onRow={handleOnRow}
        />
      </Layout.Content>
    </Layout>
  );
});
