import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmPaymentRequestsRequest({ limit, offset }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_payment_requests/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
}
