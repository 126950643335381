import * as React from 'react';
import { DashboardRegionUsers } from '../../components/DashboardRegionUsers';
import { DashboardRegionMessages } from '../../components/DashboardRegionMessages';

export const DashBoardRegionInsight = React.memo(() => {
  return (
    <>
      <DashboardRegionUsers />
      <DashboardRegionMessages />
    </>
  );
});
