import React, { useState } from 'react';
import { Button, Form, Input, Modal, Select, Typography } from 'antd';

import { updateInvoiceStatusRequest } from '../../api/billing';

const { Title } = Typography;

type Props = {
  invoice: OrganizationSubscriptionInvoice;
};

export const UpdateInvoiceStatusDialog: React.FC<Props> = ({ invoice }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(invoice.status);
  const [memo, setMemo] = useState('');

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleMemoChange(e) {
    setMemo(e.target.value);
  }

  function handleSubmit() {
    if (!memo) {
      return;
    }

    setLoading(true);
    updateInvoiceStatusRequest({
      invoiceUid: invoice.uid,
      status: invoiceStatus,
      memo,
    })
      .then((_) => {
        setLoading(false);
        setVisible(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function onChange(value) {
    setInvoiceStatus(value);
  }

  return (
    <React.Fragment>
      <Button type="primary" onClick={showModal}>
        Update Invoice Status
      </Button>
      <Modal
        open={visible}
        title={`Update Invoice Status #${invoice.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Update
          </Button>,
        ]}
      >
        <p>PENDING: system will NOT attempt to charge automatically</p>
        <p>FAILED: system will attempt to charge every day</p>
        <p>PAID / VOID / REFUNDED status are final.</p>
        <Form.Item label="Invoice Status">
          <Select defaultValue={invoice.status} onChange={onChange}>
            <Select.Option value="PENDING">PENDING</Select.Option>
            <Select.Option value="FAILED">FAILED</Select.Option>
            <Select.Option value="PAID">PAID</Select.Option>
            <Select.Option value="VOID">VOID</Select.Option>
            <Select.Option value="REFUNDED">REFUNDED</Select.Option>
          </Select>
        </Form.Item>
        <Title level={4}>Reason</Title>
        <p>Please input status change reason or additional data(ex. PAID date)</p>
        <Input size="large" onChange={handleMemoChange} placeholder="Please input status change reason or jira link" />
      </Modal>
    </React.Fragment>
  );
};
