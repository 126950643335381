import React from 'react';

class AuthByOauth2FB extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirecttoreferrer: false,
    }

    this.domain = process.env.REACT_APP_ENV === 'production'
      ? 'https://insight.sendbird.com' : process.env.REACT_APP_ENV === 'staging'
        ? 'https://insight-staging.sendbird.com' : 'http://localhost:3000';

    this.oauth2AuthUrl = "https://www.facebook.com/v3.1/dialog/oauth?" +
      "client_id=682361665451451" +
      "&response_type=token" +
      "&redirect_uri=https://insight.sendbird.com/auth/oauth2_fb/callback" +
      "&scope=email,manage_pages,pages_messaging,pages_messaging_subscriptions"
  }

  render() {
    window.location.href = this.oauth2AuthUrl
    return (
      <div>Oh, it was not redirected.</div>
    )
  }
}

export default AuthByOauth2FB
