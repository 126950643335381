import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { getTicketList } from '../api/deskMetrics';
import { dateTimePattern } from '../utils/date';
import { Layout } from 'antd';
import { PageTitle } from './ui/PageTitle';
import { ResponsiveTable } from './ui/ResponsiveTable';
import { Link } from 'react-router-dom';

export const DeskMetricsProjectDetailTicketList = ({ region, projectPid }) => {
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [ticketCount, setTicketCount] = useState(0);
  const [tickets, setTickets] = useState(
    [] as Array<Ticket>,
  );
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 10, offset: 0 });

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (id ? <Link to={`/desk_metrics/tickets/${id}/?region=${region}`}>{id}</Link> : '-'),
    },
    {
      title: 'status',
      dataIndex: 'status2',
      key: 'status2',
      render: (status2) => status2,
    },
    {
      title: 'channelType',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (channelType) => channelType,
    },
    {
      title: 'ticketType',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType) => ticketType,
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => createdAt,
    },
    {
      title: 'issuedAt',
      dataIndex: 'issuedAt',
      key: 'issuedAt',
      render: (issuedAt) => issuedAt,
    },
    {
      title: 'closedAt',
      dataIndex: 'closedAt',
      key: 'closedAt',
      render: (closedAt) => closedAt,
    },
    {
      title: 'updatedAt',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => updatedAt,
    },
    {
      title: 'lastSeenAt',
      dataIndex: 'lastSeenAt',
      key: 'lastSeenAt',
      render: (lastSeenAt) => lastSeenAt,
    },
    {
      title: 'messageCount',
      dataIndex: 'messageCount',
      key: 'messageCount',
      render: (messageCount) => messageCount,
    },
  ];

  const getTicketListFunction = (limit, offset) => {
    setLoadingTickets(true);
    getTicketList({
      projectPid: projectPid,
      region: region,
      createdAtGte: moment().subtract(30, 'days').format(dateTimePattern.date),
      createdAtLte: moment().format(dateTimePattern.date),
      limit: limit,
      offset: offset,
      status2: '',
      channelType: '',
    })
      .then((response) => {
        setTickets(response.data.results);
        setTicketCount(response.data.count);
        setLoadingTickets(false);
      })
      .catch((error) => {
        setLoadingTickets(false);
        console.error(error);
      });
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setPagination({ limit: limit, offset: offset });
    getTicketListFunction(limit, offset);
  };

  useEffect(() => {
    getTicketListFunction(pagination.limit, pagination.offset);
  }, []);
  return (
    <Layout>
      <PageTitle title="Tickets (from past 30 days)" />
      <Layout.Content>
        <ResponsiveTable
          loading={loadingTickets}
          columns={columns}
          dataSource={tickets}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ['10', ],
            total: ticketCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(pagination.offset / pagination.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
