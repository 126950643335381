import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API_DOMAIN } from '../App'
import { COUNTRY_CODE } from '../CountryCode'
import TableStatusRow from './TableStatusRow'
import Grid from "@material-ui/core/Grid/Grid";
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '../components/CustomTablePagination'
import InfoTable from "./InfoTable";


class GateAuthUser extends React.Component {
  state = {
    userId: 0,
    user: {
      id: 0,
      email: '',
      dateJoined: '',
      isActive: false,
      isSuperUser: false,
      username: '',
      firstName: '',
      lastName: '',
      profile: {
        companyName: '',
        countryCode: '',
        attributes: '',
        id: 0,
        nickname: '',
        phoneNumber: '',
        twoFactorAuthentication: null,
      }
    },
    apiGetPaymentsStatus: 'LOADING',

    crmOrganizationMembers: [],
    apiGetCrmMembersStatus: 'LOADING',
    membersLimit: 10,
    membersOffset: 0,
    membersCount: 0,
  }

  componentWillMount() {
    const { userId } = this.props.match.params
    this.setState({ userId: userId })

    this.getAuthUser()
    this.getCrmOrganizationMembers(this.state.membersOffset, this.state.membersLimit)
  }

  getAuthUser() {
    const { userId } = this.props.match.params
    axios.get(`${API_DOMAIN}/api/gate_authusers/${userId}/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({user: response.data})
    }).catch((error) => {
      console.log(error)
    })
  }

  getCrmOrganizationMembers(offset=0, limit=0) {
    const { userId } = this.props.match.params
    axios.get(`${API_DOMAIN}/api/gate_authusers/${userId}/crm_organization_members/?limit=${limit}&offset=${offset}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      }
    }).then((response) => {
      this.setState({
        crmOrganizationMembers: response.data.results,
        apiGetCrmOrganizationMembersStatus: 'SUCCESS',
        membersCount: response.data.count,
        membersOffset: offset
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const {
      user,
      crmOrganizationMembers,
      apiGetCrmOrganizationMembersStatus,
      membersLimit,
      membersOffset,
      membersCount,
    } = this.state

    const crmOrganizationMemberRows = apiGetCrmOrganizationMembersStatus === 'SUCCESS' && crmOrganizationMembers.length > 0 ?
      crmOrganizationMembers.map((crmOrganizationMember) => {
        return (
          <TableRow key={crmOrganizationMember.id}>
            <TableCell>
              <Link to={`/crm_organizations/${crmOrganizationMember.crmOrganization.id}/`}>
                {crmOrganizationMember.crmOrganization.id}
              </Link>
            </TableCell>
            <TableCell>{crmOrganizationMember.crmOrganization.name}</TableCell>
            <TableCell>{crmOrganizationMember.role}</TableCell>
            <TableCell>{crmOrganizationMember.status}</TableCell>
            <TableCell>{crmOrganizationMember.crmOrganization.createdAt}</TableCell>
          </TableRow>
        )
      }) : <TableStatusRow text="No results." />

    const gateUser = [
      {
        key: 'Gate ID',
        value: this.state.userId
      },
      {
        key: 'Email',
        value: user.email
      },
      {
        key: 'Date Joined',
        value: user.dateJoined
      },
      {
        key: 'Last Login',
        value: user.lastLogin
      },
      {
        key: 'isActive',
        value: user.isActive ? 'active' : 'inactive'
      },
      {
        key: 'Nickname',
        value: user.profile ? user.profile.nickname : ''
      },
      {
        key: 'Country',
        value: user.profile ? (
          <React.Fragment>
            { COUNTRY_CODE[user.profile.countryCode.toLowerCase()] ? COUNTRY_CODE[user.profile.countryCode.toLowerCase()].name  :  '' }
            { user.profile.countryCode ? <span>({ user.profile.countryCode })</span> : '' }
          </React.Fragment>
        ) : ''
      },
      {
        key: 'Attributes',
        value: user.profile ? user.profile.attributes : ''
      },
      {
        key: 'Two Factor Authentication',
        value: user.profile.twoFactorAuthentication ? 'Enabled' : 'Disabled'
      }
    ]

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid>
            <h1 className="display-4 mt-3">User</h1>
            <h3 className="mt-5">Detail Info</h3>
            <InfoTable infos={gateUser} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid>
            <h3 className="mt-5">Organizations (total: {membersCount})</h3>
            <div style={{overflowX: 'auto'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Member Role</TableCell>
                    <TableCell>Member Status</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apiGetCrmOrganizationMembersStatus === 'LOADING' ?
                    <TableStatusRow text="Loading..." /> : crmOrganizationMemberRows
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination count={membersCount}
                       onChangePage={(e, page) => this.setState({ membersOffset: page * this.state.membersLimit }, () => {
                        this.getCrmOrganizationMembers(this.state.membersOffset, this.state.membersLimit)
                       })}
                       onChangeRowsPerPage={e => this.setState({ membersLimit: e.target.value }, () => {
                        this.getCrmOrganizationMembers(this.state.membersOffset, this.state.membersLimit)
                       })}
                       page={membersOffset/membersLimit}
                       rowsPerPage={membersLimit}
                       rowsPerPageOptions={[3, 5, 8]}/>
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default GateAuthUser
