import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
  dt: {
    float: 'left',
    width: '30%',
    textAlign: 'left',
    padding: '.5em',
    clear: 'left',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dd: {
    float: 'left',
    width: '60%',
    padding: '.5em',
    clear: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  dl: {
    width: '100%',
    '&::after': {
      content: '',
      display: 'table',
      clear: 'both',
    },
  },
});

class InfoTable extends Component {
  render() {
    const { infos } = this.props;
    const { classes } = this.props;
    return (
      <dl className={classNames(classes.dl)}>
        {infos.map((info) => {
          return (
            <React.Fragment key={info.key}>
              <dt className={classNames(classes.dt)}>{info.key}</dt>
              <dd className={classNames(classes.dd)}>{info.value}</dd>
            </React.Fragment>
          );
        })}
      </dl>
    );
  }
}

InfoTable.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(InfoTable);
