import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Input, Row, Typography } from 'antd';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import { getBillingEventLogsRequest } from '../../api/event_logs';
import { IsJsonString } from '../../utils/data';

const { Title } = Typography;
const { Search } = Input;

const extractDataFromJSONString = (data, key) => {
  let value = JSON.parse(data)[key];
  if (!value) {
    value = '';
  }
  if (typeof value === typeof {}) {
    value = value['id'];
  }
  return value;
};

const columns = [
  {
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/event_logs/${id}/`}> {id} </Link>,
  },
  {
    title: 'Type',
    dataIndex: 'eventType',
    key: 'eventType',
    render: (eventType) => <span>{eventType}</span>,
  },
  {
    title: 'Updated Attributes',
    dataIndex: 'objectData',
    key: 'objectData',
    render: (objectData, eventLog) =>
      IsJsonString(eventLog.objectPreviousAttributes) &&
      Object.keys(JSON.parse(eventLog.objectPreviousAttributes)).map((k) => (
        <p>{`${k}: "${extractDataFromJSONString(
          eventLog.objectPreviousAttributes,
          k,
        )}" => "${extractDataFromJSONString(objectData, k)}"`}</p>
      )),
  },
  {
    title: 'Object',
    dataIndex: 'object',
    key: 'object',
    render: (obj, eventLog) =>
      obj ? (
        <Link
          to={`/crm_${eventLog.eventType.split('.')[0].toLowerCase()}s/${
            obj.id
          }/`}
        >
          {' '}
          {obj.id}{' '}
        </Link>
      ) : (
        ''
      ),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <span>{createdAt}</span>,
  },
  {
    title: 'Requester',
    dataIndex: 'requester',
    key: 'userName',
    render: (requester) => (requester ? <span>{requester.username}</span> : ''),
  },
];

type Props = RouteComponentProps;

export const EventLogs = withRouter<Props, any>(({ location, history }) => {
  const [billingEventLogs, setBillingEventLogs] = useState<Array<EventLog>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [billingEventLogsCount, setBillingEventLogsCount] = useState(0);

  useEffect(() => {
    const q = location.search.split('q=')[1]
      ? location.search.split('q=')[1]
      : '';
    setSearchQuery(q);
    getBillingEventLogs(0, 30, q);
  }, [location]);

  const getBillingEventLogs = (offset = 0, limit = 30, q = '') => {
    const query = q ? q : searchQuery;

    setLoading(true);
    getBillingEventLogsRequest({ offset, limit, query })
      .then((response) => {
        setBillingEventLogs(response.data.results);
        setLoading(false);
        setBillingEventLogsCount(response.data.count);
      })
      .catch((error) => {
        setLoading(false);
        console.error('ERROR', error && error.response);
      });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
    history.replace({
      pathname: '/event_logs/',
      search: `?q=${value}`,
    });
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    getBillingEventLogs((page - 1) * pageSize, pageSize);
  };

  return (
    <React.Fragment>
      <Row style={{ marginBottom: '8px' }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
          <Title level={3}>Billing Event Logs</Title>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
          <Search
            value={searchQuery}
            placeholder="Search log"
            enterButton={true}
            onSearch={onSearch}
            onChange={onSearchChange}
          />
        </Col>
      </Row>
      <ResponsiveTable
        loading={loading}
        columns={columns}
        dataSource={billingEventLogs}
        pagination={{
          total: billingEventLogsCount,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
        }}
      />
    </React.Fragment>
  );
});
