import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';

// component props types
type Props = {
  items: { targetID: string; title: string }[];
};

// components
export const MetricPageOutline: React.FC<Props> = ({ items }) => {
  const onLinkClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();
      const { href } = e.currentTarget;
      const targetID = href.split(/#(.+)/)[1];
      const targetElement = document.getElementById(targetID);
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    },
    [],
  );

  return (
    <>
      <Typography.Title level={3}>Metrics</Typography.Title>
      <ul>
        {items.map(({ targetID, title }) => (
          <li key={`${targetID}-${title}`}>
            <Link to={`#${targetID}`} onClick={onLinkClick}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
