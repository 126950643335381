import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ResponsiveTable } from '../components/ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { LineChartComponent } from '../components/LineChartComponent';
import { useCheckMobile } from '../utils/screen';
import { getSearchDate, getSearchMonth } from '../utils/date';
import {
  getCrmSubscriptionDailyRecordsDailyCountsRequest,
  getCrmSubscriptionDailyRecordsMonthlyCountsRequest,
} from '../api/subscription';
const { Title } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function createColumns({ dataSources }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
    },
  ];

  dataSources.forEach((data, columnIndex) => {
    columns.push({
      title: data.date,
      dataIndex: `count-${columnIndex}`,
      key: `users-${columnIndex}`,
      align: alignRight,
      render: (count: number) => count && count.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSources, dataName, title }) {
  const source = {};
  dataSources.forEach((data, index) => {
    const count = data[dataName];
    source[`count-${index}`] = count;
    source['title'] = title;
  });

  return [source];
}

export const DashboardSubscribedApplications = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dataSourceDaily, setDataSourceDaily] = useState<Array<DashboardSubscriptionAppStat>>([]);
  const [dataSourceMonthly, setDataSourceMonthly] = useState<Array<DashboardSubscriptionAppStat>>([]);
  const [dailyLoading, setDailyLoading] = useState<boolean>(false);
  const [monthlyLoading, setMonthlyLoading] = useState<boolean>(false);

  function getCrmSubscriptionDailyRecordsDailyCounts() {
    const { startDate, endDate } = getSearchDate({ days: 14 });

    setDailyLoading(true);
    getCrmSubscriptionDailyRecordsDailyCountsRequest({ startDate, endDate })
      .then((response) => {
        setDataSourceDaily(response.data.results);
        setDailyLoading(false);
      })
      .catch((error) => {
        setDailyLoading(false);
        error && console.error(error.response);
      });
  }

  function getCrmSubscriptionDailyRecordsMonthlyCounts() {
    const { startMonth, endMonth } = getSearchMonth();

    setMonthlyLoading(true);
    getCrmSubscriptionDailyRecordsMonthlyCountsRequest({ startMonth, endMonth })
      .then((response) => {
        setDataSourceMonthly(response.data.results);
        setMonthlyLoading(false);
      })
      .catch((error) => {
        setMonthlyLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getCrmSubscriptionDailyRecordsDailyCounts();
    getCrmSubscriptionDailyRecordsMonthlyCounts();
  }, []);

  const dailyColumns = createColumns({
    dataSources: dataSourceDaily,
  });

  const dailyData = convertDataSource({
    dataSources: dataSourceDaily,
    dataName: 'count',
    title: 'Daily',
  });

  const monthlyColumns = createColumns({
    dataSources: dataSourceMonthly,
  });

  const monthlyData = convertDataSource({
    dataSources: dataSourceMonthly,
    dataName: 'count',
    title: 'Monthly',
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card title="Subscribed applications statistics" bodyStyle={{ background: '#f4f4f4' }}>
          <Row gutter={16}>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>Subscribed applications - Daily</Title>
                <LineChartComponent
                  title="Subscribed applications - Daily"
                  unit="day"
                  labels={dataSourceDaily.map((data) => data.date)}
                  data1={dataSourceDaily.map((data) => data.count)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dailyLoading}
                  columns={dailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1550 }}
                  dataSource={dailyData}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ marginBottom: '16px' }}>
              <Card>
                <Title level={4}>Subscribed applications - Monthly</Title>
                <LineChartComponent
                  title="Subscribed applications - Monthly"
                  unit="month"
                  labels={dataSourceMonthly.map((data) => data.date)}
                  data1={dataSourceMonthly.map((data) => data.count)}
                />
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={monthlyLoading}
                  columns={monthlyColumns}
                  scroll={{ x: isMobile ? '100%' : 1340 }}
                  dataSource={monthlyData}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
