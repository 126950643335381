import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Tag } from 'antd';
import moment from 'moment';
import { PageTitle } from '../../components/ui/PageTitle';
import { ResponsiveTable } from '../../components/ui/ResponsiveTable';
import {
  getBillingVouchersRequest,
} from '../../api/billing';
import { dateTimePattern } from '../../utils/date';
import {
  BillingVoucherStatusTag,
} from '../../components/ui/Tags';

const useVouchersAPI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    limit: 30,
    offset: 0,
  });
  const [response, setResponse] = useState<any>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    try {
      const { limit, offset } = query;
      const response = await getBillingVouchersRequest({
        limit,
        offset,
      });
      setResponse(response.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [query]);

  useEffect(() => {
    request();
  }, [request]);

  return {
    isLoading,
    count: response ? response.count : 0,
    items: response ? response.results : [],
    query,
    setQuery,
  };
};

export const VoucherList = () => {
  const { isLoading, count, items, query, setQuery } = useVouchersAPI();
  const columns = [
    {
      title: '#UID',
      dataIndex: 'uid',
      key: 'uid',
      render: (uid: string) => <Link to={`/billing_vouchers/${uid}/`}>{uid}</Link>,
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      render: (organization) => {
        return <Link to={`/crm_organizations/${organization.uid}/`}>{organization.name}</Link>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <BillingVoucherStatusTag status={status}/>;
      },
    },
    {
      title: 'Fee($)',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      render: (paidAmount) => { return <Tag>${paidAmount/100}</Tag> }
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit'
    },
    {
      title: 'Used Credit',
      dataIndex: 'usedCredit',
      key: 'usedCredit'
    },
    {
      title: 'Start date',
      dataIndex: 'startDt',
      key: 'startDt',
      render: (startDt) => `${startDt ? moment(startDt).utc().format(dateTimePattern.default) : '-'}`,
    },
    {
      title: 'Expire date',
      dataIndex: 'expireDt',
      key: 'expireDt',
      render: (expireDt) => `${expireDt ? moment(expireDt).utc().format(dateTimePattern.default) : '-'}`,
    }
  ];

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setQuery({ ...query, limit: limit, offset: offset });
  };

  return (
    <Layout>
      <PageTitle title="Voucher List" />
      <Layout.Content>
        <ResponsiveTable
          loading={isLoading}
          columns={columns}
          dataSource={items}
          pagination={{
            total: count,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(query.offset / query.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
