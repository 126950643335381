import * as React from 'react';
import { useState } from 'react';

import { Button, Modal, Checkbox, Row, Col } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { REGIONS } from '../App';
import { setOrganizationRegionRequest } from '../api/organization';

interface Regions {
  [key: string]: boolean;
}

type Props = {
  crmOrganization: CrmOrganization;
  successCallback: ({ callback }: { callback: any }) => void;
};

type RegionProps = {
  regions: Regions;
  checkedRegions: Array<string>;
  onChange: (checkedValue: Array<CheckboxValueType>) => void;
};

const getRegions = ({ regions }) => {
  let refinedRegions = {};
  const allowedRegions = regions && JSON.parse(regions);
  const GATE_REGIONS = Object.keys(REGIONS).filter((key) => {
    return REGIONS[key]['type'] === 'SHARED';
  }); //['ap-1', 'ap-2', 'ap-5', 'us-1', 'eu-1', 'us-2']

  GATE_REGIONS.forEach((region) => {
    refinedRegions[region] = allowedRegions ? allowedRegions.includes(region) : false;
  });
  return refinedRegions;
};

const RegionsCheckbox: React.FC<RegionProps> = ({ regions, checkedRegions, onChange }) => {
  return (
    <Checkbox.Group style={{ width: '100%' }} value={checkedRegions} onChange={onChange}>
      <Row>
        {Object.keys(regions).map((region) => (
          <Col span={24} key={region} style={{ padding: '8px 0' }}>
            <Checkbox value={region}>{`${REGIONS[region]['name']} (${region})`}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export const SetAllowRegionsDialog: React.FC<Props> = React.memo(({ crmOrganization, successCallback }) => {
  const regions = getRegions({
    regions: crmOrganization.allowRegions,
  });
  const checked = Object.entries(regions)
    .filter((item) => item[1] === true)
    .map((item) => item[0]);

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [checkedRegions, setCheckedRegions] = useState(checked);

  function showModal() {
    setVisible(true);
  }

  function handleChange(checkedRegions) {
    setCheckedRegions(checkedRegions);
  }

  function handleOk() {
    setOrganizationRegionRequest({
      crmOrganizationId: crmOrganization.id,
      allowRegions: checkedRegions,
    })
      .then((response) => {
        successCallback &&
          successCallback({
            callback: console.log('update_allow_regions_response', response.data),
          });
        setVisible(false);
      })
      .catch((error) => {
        console.error('Error in SetAllowRegionsDialog: ', error);
      });
  }

  const handleCancel = () => {
    setCheckedRegions(checked);
    setLoading(false);
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        size="small"
        onClick={showModal}
        style={{
          marginTop: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Change
      </Button>
      <Modal
        open={visible}
        title="Change allowed regions"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Update
          </Button>,
        ]}
      >
        <RegionsCheckbox regions={regions} checkedRegions={checkedRegions} onChange={handleChange} />
      </Modal>
    </React.Fragment>
  );
});
