import React, { useRef } from 'react';
import { useState } from 'react';
import { Button, Modal, DatePicker, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import { DynamicForm } from '../ui/DynamicForm';
import { updateBillingSubscriptionEndDateRequest } from '../../api/billing';
import { dateTimePattern } from '../../utils/date';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

/*
 * Note
 * (1) Assert New End Date >= Current Start Date
 * (2) Assert New End Date >= Today
 * (3) Assert New End Date <= Today + 75 days
 * (4) Assert New End Date <= Current End Date + 61 days
 * */

type Props = {
  organizationSubscription: OrganizationSubscription;
  successCallback: any;
};

interface GetField {
  ({ organizationSubscription }: { organizationSubscription: OrganizationSubscription }): Array<any>;
}

export const SetOrganizationSubscriptionEndDateDialog: React.FC<Props> = ({
  organizationSubscription,
  successCallback,
}) => {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [onChangeEndDateString, setOnChangeEndDateString] = useState(organizationSubscription.endDate);

  const disabledDate = (current) => {
    if (!current) {
      return true;
    }

    return (
      current < moment(organizationSubscription.startDate) ||
      current < moment().endOf('day') ||
      current > moment().add(75, 'days').endOf('day') ||
      current > moment(organizationSubscription.endDate).add(61, 'days').endOf('day')
    );
  };

  const onChangeEndDate = (_oldEndDate, _onChangeEndDateString) => {
    setOnChangeEndDateString(_onChangeEndDateString);
  };

  const getFields: GetField = ({ organizationSubscription }) => {
    return [
      {
        name: 'startDate',
        label: (
          <span>
            <span>Start date </span>
            <small style={{ opacity: 0.5 }}>(UTC)</small>
          </span>
        ),
        formItemProps: {
          initialValue: moment.tz(organizationSubscription.startDate, 'UTC'),
          rules: [
            {
              validator: (_, value, callback) => {
                callback();
              },
            },
          ],
        },
        span: 12,
        component: <DatePicker size="large" disabled={true} format={dateTimePattern.date} />,
      },
      {
        name: 'endDate',
        label: (
          <Tooltip title="The End Date can be extended to 61 days from the current End Date and to 75 days from today">
            <span>
              End Date <small style={{ opacity: 0.5 }}>(UTC)</small>
              {` `}
              <span>
                <QuestionCircleOutlined />
              </span>
            </span>
          </Tooltip>
        ),
        formItemProps: {
          initialValue: moment.tz(organizationSubscription.endDate, 'UTC'),
          rules: [
            {
              validator: (_, value, callback) => {
                const endDateMonths = formRef.current?.getFieldValue('endDateMonths');
                if (value && endDateMonths) {
                  formRef.current?.setFieldsValue({
                    endDate: moment(value).add(endDateMonths, 'months').subtract(1, 'days'),
                  });
                }
                callback();
              },
            },
          ],
        },
        span: 12,
        component: (
          <DatePicker
            size="large"
            disabledDate={disabledDate}
            showToday={false}
            onChange={onChangeEndDate}
            format={dateTimePattern.date}
          />
        ),
      },
      {
        name: 'description',
        label: 'Description (optional)',
        formItemProps: {},
        component: <TextArea rows={4} />,
      },
    ];
  };

  function setForm(ref) {
    formRef.current = ref;
  }

  function showModal() {
    setVisible(true);
    formRef.current?.resetFields();
    setOnChangeEndDateString(organizationSubscription.endDate);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
    formRef.current?.resetFields();
  }

  function handleSubmit() {
    setLoading(true);
    if (formRef.current) {
      formRef.current
        .validateFields()
        .then((values) => {
          const { endDate } = values;

          updateBillingSubscriptionEndDateRequest({
            billingSubscriptionUid: organizationSubscription.uid,
            endDate: endDate.tz('UTC').format('YYYY-MM-DD'),
            description: values.description,
          })
            .then((response) => {
              handleCancel();

              successCallback && successCallback();
            })
            .catch((error) => {
              console.error('Error in : updateOrganizationSubscriptionEndDateRequest', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }
  const fields = getFields({ organizationSubscription });
  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        size="small"
        style={{
          marginLeft: '0.5em',
          marginBottom: '0.5em',
          verticalAlign: 'middle',
        }}
      >
        Change
      </Button>
      <Modal
        open={visible}
        title={'Update billing subscription end date'}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        {organizationSubscription && <DynamicForm fields={fields} setForm={setForm} />}
        <>
          * Free trial will stop working at {`${moment(onChangeEndDateString).add(1, 'days').format('MMMM Do YYYY')}`}{' '}
          00:00:00 UTC
        </>
      </Modal>
    </>
  );
};
