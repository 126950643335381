import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { TableDetail } from '../ui/TableDetail';
import { getOrganizationSubscriptionsRequest } from '../../api/billing';
import { Tag } from 'antd';
import { SetOrganizationSubscriptionEndDateDialog } from '../organizationBilling/SetOrganizationSuscriptionEndDateDialog';
import { CreateFreeTrialSubscriptonDialog } from './CreateFreeTrialSubscriptonDialog';
import { getSubscriptionNameListRequest } from '../../api/billingMetrics';
import moment from 'moment-timezone';
import { dateTimePattern } from '../../utils/date';

export const OrganizationSubscriptions = ({ crmOrganization, crmOrganizationUid }) => {
  const [loadingOrganizationSubscriptions, setLoadingOrganizationSubscriptions] = useState(false);
  const [organizationSubscriptionsCount, setOrganizationSubscriptionsCount] = useState(0);
  const [organizationSubscriptions, setOrganizationSubscriptions] = useState([] as Array<OrganizationSubscription>);
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 20, offset: 0 });
  const [subscriptionNameList, setSubscriptionNameList] = useState([] as Array<BillingMetricSubscriptionNameList>);
  const [isDeveloperPlanEnabled, setIsDeveloperPlanEnabled] = useState(false)

  const getSubscriptionNameList = () => {
    getSubscriptionNameListRequest()
      .then((response) => {
        setSubscriptionNameList(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOrganizationSubscriptions = useCallback(
    (callback) => {
      setLoadingOrganizationSubscriptions(true);
      getOrganizationSubscriptionsRequest({
        organizationUid: crmOrganizationUid,
        ...pagination,
      })
        .then((response) => {
          setOrganizationSubscriptions(response.data.results);
          setOrganizationSubscriptionsCount(response.data.count);
          setLoadingOrganizationSubscriptions(false);

          if (response.data.results.filter(x =>x.subscriptionName === 'developer' && x.current === true).length > 0) {
            setIsDeveloperPlanEnabled(true)
          } else {
            setIsDeveloperPlanEnabled(false)
          }
          callback && callback();
        })
        .catch((err) => {
          setLoadingOrganizationSubscriptions(false);
          console.error(err);
        });
    },
    [crmOrganizationUid, pagination],
  );

  const organizationSubscriptionsColumns = [
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
      render: (uid: string) => (uid ? <Link to={`/organization_subscription/${uid}/`}>{uid}</Link> : '-'),
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Plan name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Subscription name',
      dataIndex: 'subscriptionName',
      render: (subscriptionName: OrganizationSubscription['subscriptionName']) => {
        const item = subscriptionNameList.find((s) => s.subscriptionName === subscriptionName);
        return (item && item.displayName) || subscriptionName;
      },
    },
    {
      title: 'Subscription Type',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      render: (current: OrganizationSubscription['current']) => (
        <Tag color={current ? 'green' : 'red'} style={{ margin: 0 }}>
          {current ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      title: 'Applied',
      dataIndex: 'applied',
      key: 'applied',
      render: (applied: OrganizationSubscription['applied']) => (
        <Tag color={applied ? 'green' : 'red'} style={{ margin: 0 }}>
          {applied ? 'True' : 'False'}
        </Tag>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'planValue',
      key: 'planValue',
      render: (planValue) => <Tag>{`$${Math.floor(planValue) / 100}`}</Tag>,
    },
    {
      title: 'Billing cycle',
      dataIndex: 'billingCycleMonths',
      key: 'billingCycleMonths',
      render: (billingCycleMonths) => `${billingCycleMonths} month(s)`,
    },
    {
      title: (
        <span>
          Period <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate: string, organizationSubscription: OrganizationSubscription) => (
        <>
          {startDate} ~ {organizationSubscription.endDate || ''}
          {crmOrganization.status === 'ACTIVE' &&
          organizationSubscription.current &&
          organizationSubscription.product === 'CHAT' &&
          organizationSubscription.subscriptionName === 'free_trial' &&
          organizationSubscription.uid ? (
            <SetOrganizationSubscriptionEndDateDialog
              organizationSubscription={organizationSubscription}
              successCallback={getOrganizationSubscriptions}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: (
        <span>
          Created at <small style={{ opacity: 0.5 }}>(UTC)</small>
        </span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => <>{moment(createdAt).utc().format(dateTimePattern.default)}</>,
    },
    {
      title: (
        <span>
          Inactive Reason
        </span>
      ),
      dataIndex: 'endReason',
      key: 'endReason',
      render: (endReason: string) => <>{endReason}</>,
    },
    {
      title: (
        <span>
          Inactivated by
        </span>
      ),
      dataIndex: 'endStatusUpdatedBy',
      key: 'endStatusUpdatedBy',
      render: (endStatusUpdatedBy: string) => <>{endStatusUpdatedBy}</>,
    },
  ];

  // TODO: It might be required to show or not CreateFreeTrialSubscriptonDialog
  // import moment from 'moment-timezone';
  // const hasStartDateBiggerThanTodaySubscription = () => {
  //   return organizationSubscriptions.find((s) => moment(s.startDate).diff(moment(moment().date())) > 0);
  // };

  useEffect(() => {
    getSubscriptionNameList();
    getOrganizationSubscriptions(() => {});
  }, [getOrganizationSubscriptions]);

  return (
    <TableDetail
      title={
        <>
          Subscriptions{' '}
          {crmOrganization.status === 'ACTIVE' ? (
            <CreateFreeTrialSubscriptonDialog
              organizationUid={crmOrganizationUid}
              successCallback={getOrganizationSubscriptions}
              isDeveloperPlanEnabled={isDeveloperPlanEnabled}
            />
          ) : (
            ''
          )}
        </>
      }
      count={organizationSubscriptionsCount}
      loading={loadingOrganizationSubscriptions}
      columns={organizationSubscriptionsColumns}
      dataSource={organizationSubscriptions}
      onPageChange={setPagination}
      pageSizeOptions={['20', '40', '60']}
      leftColumnLg={16}
    />
  );
};
