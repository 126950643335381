import * as React from 'react';
import { useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { approveGateTeamAccountOtpRequest } from '../api/otp';

const { Title, Text } = Typography;

type Props = {
  dashboardOtp: DashboardOtp;
  successCallback: (payload?: any) => void;
};

export const DashboardOtpPostApproveDialog: React.FC<Props> = React.memo(({ dashboardOtp, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setLoading(false);
    setVisible(false);
  }

  function handleSubmit() {
    setLoading(true);
    approveGateTeamAccountOtpRequest({
      dashboardOtpId: dashboardOtp.id,
      status: dashboardOtp.status,
    })
      .then((response) => {
        setLoading(false);
        successCallback();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button
        danger={true}
        icon={<CheckCircleOutlined />}
        onClick={showModal}
        size={'small'}
        style={{ margin: '0.25em', marginLeft: 0 }}
      >
        Post-approval
      </Button>
      <Modal
        open={visible}
        title={`Generate #${dashboardOtp.id}`}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Post-Approve
          </Button>,
        ]}
      >
        <Title level={4}>Onetime Password ID</Title>
        <Text>{dashboardOtp.id}</Text>
        <Title level={4}>Requester</Title>
        <Text>
          {dashboardOtp.teamAccount ? `${dashboardOtp.teamAccount.name} (${dashboardOtp.teamAccount.eid})` : '-'}
        </Text>
        <Title level={4}>Created At</Title>
        <Text>{dashboardOtp.createdAt}</Text>
        <Title level={4}>Target User</Title>
        <Text>{dashboardOtp.targetUser ? dashboardOtp.targetUser.email : '-'}</Text>
        <Title level={4}>Status</Title>
        <Text>{dashboardOtp.status}</Text>
        {errorMessage ? <Title level={4}>*Error Message*</Title> : ''}
        {errorMessage ? <Text>{errorMessage}</Text> : ''}
      </Modal>
    </React.Fragment>
  );
});
