import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PaymentMethodTag, PaymentTypeTag, PaymentStatusTag } from '../components/ui/Tags';
import { TableDetail } from '../components/ui/TableDetail';
import { PaymentCreateDialog } from './PaymentCreateDialog';
import { PaymentPaynowDialog } from '../components/PaymentPaynowDialog';
import { PaymentUpdateDialog } from './PaymentUpdateDialog';
import { PaymentRefundDialog } from './PaymentRefundDialog';
import { PaymentDisableDialog } from './PaymentDisableDialog';
import { PaymentResendReceiptDialog } from './PaymentResendReceiptDialog';

import { getCrmPaymentsRequest } from '../api/billing';
import { hasPermission, Permissions } from '../utils/permission';

const columns = ({ crmApplication, dialogSuccessCallback }: ColumnsProps) => [
  {
    title: '#ID',
    dataIndex: 'id',
    render: (id: CrmPayment['id']) => <Link to={`/crm_payments/${id}/`}>{id}</Link>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type: CrmPayment['type']) => <PaymentTypeTag type={type} />,
  },
  {
    title: 'Subscription',
    dataIndex: 'crmSubscription',
    render: (id: CrmPayment['crmSubscription']) => (id ? <Link to={`/crm_subscriptions/${id}/`}>{id}</Link> : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: CrmPayment['status']) => <PaymentStatusTag status={status} />,
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    render: (paymentMethod: CrmPayment['paymentMethod']) => <PaymentMethodTag paymentMethod={paymentMethod} />,
  },
  {
    title: 'Fee',
    dataIndex: 'productFee',
    render: (productFee: CrmPayment['productFee'], crmPayment: CrmPayment) => (
      <>
        ${productFee.toLocaleString()} / ${crmPayment.serviceFee.toLocaleString()}
        <br />
        <small>
          ({crmPayment.productFeeDescription} / {crmPayment.serviceFeeDescription})
        </small>
      </>
    ),
  },
  {
    title: (
      <>
        Start Date
        <br />/ End Date
      </>
    ),
    dataIndex: 'serviceStartDt',
    render: (serviceStartDt: CrmPayment['serviceStartDt'], crmPayment: CrmPayment) => (
      <>
        {serviceStartDt}
        <br />~ {crmPayment.serviceEndDt}
      </>
    ),
  },
  {
    title: (
      <span>
        CreatedDt <small style={{ opacity: 0.5 }}>(UTC)</small>
      </span>
    ),
    dataIndex: 'chargedDt',
    key: 'chargedDt',
    render: (chargedDt: CrmPayment['chargedDt']) => (chargedDt ? chargedDt.split('.')[0] : '-'),
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    key: 'memo',
  },
  {
    title: 'Action',
    dataIndex: 'isActive',
    key: 'action',
    render: (isActive: CrmPayment['isActive'], crmPayment: CrmPayment) => {
      const status = crmPayment.status.split('_').pop();
      const paymentMethod = crmPayment.paymentMethod.split('_').pop();
      return (
        <>
          {isActive && paymentMethod === 'CARD' && status && ['QUEUED', 'FAILED'].includes(status) ? (
            <React.Fragment>
              <PaymentPaynowDialog
                crmApplication={crmApplication}
                crmPaymentId={crmPayment.id}
                successCallback={dialogSuccessCallback}
              />
              <PaymentDisableDialog crmPayment={crmPayment} />
            </React.Fragment>
          ) : (
            ''
          )}
          {hasPermission(Permissions.MANAGE_PAYMENT) ? (
            <PaymentUpdateDialog crmPayment={crmPayment} successCallback={dialogSuccessCallback} />
          ) : (
            ''
          )}
          {isActive && paymentMethod === 'CARD' && status === 'CHARGED' ? (
            <PaymentResendReceiptDialog crmPayment={crmPayment} />
          ) : (
            ''
          )}
          {isActive && paymentMethod === 'CARD' && status === 'CHARGED' ? (
            <PaymentRefundDialog crmPayment={crmPayment} />
          ) : (
            ''
          )}
        </>
      );
    },
  },
];

interface ColumnsProps {
  crmApplication: CrmApplication;
  dialogSuccessCallback: () => void;
}

type Props = {
  appId: CrmApplication['appId'];
  crmApplication: CrmApplication;
};

export const ApplicationDetailPayments: React.FC<Props> = ({ appId, crmApplication }) => {
  const [dataSource, setDatasource] = useState<Array<CrmPayment>>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 25,
  });

  function getCrmPayments({ offset = 0, limit = 25 }) {
    setLoading(true);
    getCrmPaymentsRequest({
      appId,
      limit,
      offset,
    })
      .then((response) => {
        setDatasource(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handleOnRow(payment: CrmPayment) {
    const { isActive } = payment;
    return {
      style: {
        textDecoration: !isActive ? 'line-through' : '',
        opacity: !isActive ? 0.5 : 1,
      },
    };
  }

  function handlePageChange({ offset, limit }) {
    setPagination({ offset, limit });
    getCrmPayments({ offset, limit });
  }

  function dialogSuccessCallback() {
    getCrmPayments({ offset: pagination.offset, limit: pagination.limit });
  }

  useEffect(function () {
    getCrmPayments({ offset: 0, limit: 25 });
  }, []);

  return (
    <TableDetail
      title="Payments"
      count={count}
      loading={loading}
      columns={columns({ crmApplication, dialogSuccessCallback })}
      dataSource={dataSource}
      rightColumnComponent={(() => {
        return crmApplication.crmOrganization && !crmApplication.crmOrganization.isSelfServe ? (
          <PaymentCreateDialog crmApplication={crmApplication} successCallback={dialogSuccessCallback} />
        ) : undefined;
      })()}
      onPageChange={handlePageChange}
      onRow={handleOnRow}
      pageSizeOptions={['25', '50', '100']}
    />
  );
};
