import * as React from 'react';
import { Card, Col, List, Row, Tag } from 'antd';

type Props = {
  user: InsightMember;
};

const renderInfoItem = (item) => {
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col span={9}>{item.key}</Col>
        <Col span={15} style={{ fontWeight: 500, textAlign: 'right' }}>
          {item.value}
        </Col>
      </Row>
    </List.Item>
  );
};

export const AccountProfileInfo: React.FC<Props> = ({ user }) => {
  const detailBasic = [
    {
      key: 'User Name',
      value: user.username,
    },
    {
      key: 'Email',
      value: user.email,
    },
    {
      key: 'Department',
      value: user.department,
    },
  ];
  const detailAdvanced = [
    {
      key: 'IsActive',
      value: (
        <Tag color={user.state === 'ACTIVE' ? 'green' : 'red'} style={{ margin: 0 }}>
          {user.state === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE'}
        </Tag>
      ),
    },
    {
      key: 'Eid',
      value: user.eid ? user.eid : '-',
    },
    {
      key: 'Date Joined',
      value: user.dateJoined,
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: '24px',
        padding: '16px 8px 0',
        background: '#f0f2f5',
      }}
    >
      {dataSources.map((dataSource, index) => (
        <Col key={`organization-info-${index}`} span={24} md={{ span: 12 }} style={{ paddingBottom: '16px' }}>
          <Card
            size="small"
            style={{
              minHeight: '280px',
            }}
          >
            <List size="small" dataSource={dataSource} renderItem={renderInfoItem} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
