import * as React from 'react';
import { useEffect, useState } from 'react';
import { PrivateRoute } from '../../routes/PrivateRoute';

import { Row, Col, Typography } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DashboardUsage } from '../../components/DashboardUsage';
import { DashboardGrowth } from '../../components/DashboardGrowth';

const { Title } = Typography;

const urls = ['/dashboard/usage', '/dashboard/growth'];

function getPageName({ location }) {
  switch (location.pathname) {
    case '/':
    case urls[0]:
      return 'Usage';

    case urls[1]:
      return 'Growth';

    default:
      return 'Dashboard';
  }
}

type Props = RouteComponentProps;

export const DashboardContainer = withRouter<Props, any>(
  ({ location, history }) => {
    const [title, setTitle] = useState(getPageName({ location }));
    useEffect(
      function () {
        setTitle(getPageName({ location }));
        if (location.pathname === '/') {
          history.push('/dashboard/usage');
        }
      },
      [location, title],
    );

    return (
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Title level={3}>{title}</Title>
        </Col>
        <Col>
          <PrivateRoute
            exact={true}
            path="/dashboard/usage"
            component={DashboardUsage}
          />
          <PrivateRoute
            exact={true}
            path="/dashboard/growth"
            component={DashboardGrowth}
          />
        </Col>
      </Row>
    );
  },
);
