import { ColumnWidthOutlined } from '@ant-design/icons';
import { Tooltip, Button, Popover, Space, InputNumber, message } from 'antd';
import React, { useRef, useState } from 'react';
import { WithLabel } from './WithLabel';

const inputId = 'name-resize-column-width';

export const ResizeColumnButton = ({ value, onChange }: { value: number; onChange: (value: number) => void }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const isTooltipHidden = isPopoverOpen;
  return (
    <Tooltip title="Resize column" {...(isTooltipHidden ? { open: false } : undefined)}>
      <Popover
        trigger="click"
        placement="bottom"
        visible={isPopoverOpen}
        onVisibleChange={setIsPopoverOpen}
        content={
          <Space direction="vertical" align="center">
            <WithLabel label="Width" htmlFor={inputId}>
              <InputNumber id={inputId} defaultValue={value} ref={inputRef} min={210} max={600} />
            </WithLabel>
            <Button
              type="primary"
              onClick={() => {
                const newValue = +(document.getElementById(inputId) as HTMLInputElement).value;

                if (Number.isInteger(newValue)) {
                  onChange(newValue);
                } else {
                  message.error('Enter an integer value.');
                }
              }}
            >
              Apply
            </Button>
          </Space>
        }
      >
        <Button icon={<ColumnWidthOutlined />} size="small" style={{ marginLeft: 6 }} />
      </Popover>
    </Tooltip>
  );
};
