import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getCrmPaymentsRequest({ limit, offset, type, method ,status }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/?limit=${limit}&offset=${offset}&status=${status}`,
    {
      headers: {
        Authorization: getAuthToken()
      },
      params:{
        type,
        method,
        status
      }
    }
  );
}

export function getCrmPaymentRequest({ crmPaymentId }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_payments/${crmPaymentId}/`, {
    headers: {
      Authorization: getAuthToken()
    }
  });
}

export function getCrmPaymentRequestsRequest({ limit, offset, crmPaymentId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/crm_payment_requests/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmPaymentRequestRefundsRequest({ limit, offset, crmPaymentId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/crm_payment_request_refunds/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getEventLogsRequest({ limit, offset, crmPaymentId }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/${crmPaymentId}/event_logs/?limit=${limit}&offset=${offset}`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getCrmPaymentStatusByApplicationRequest({isUnpaid='false'}){
    return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/status_by_application/`,
    {
      params:{
        isUnpaid
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
