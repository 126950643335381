import * as React from 'react';
import { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';

import { PageTitle } from '../../components/ui/PageTitle';
import { GeneralDetail } from '../../components/ui/GeneralDetail';

import { getRolePermissionRequest } from '../../api/roles';
import { Layout } from 'antd';

const RolePermissionContainer = styled.div<{ state: string }>`
  ${(props) => (props.state === 'EXPIRED' ? '* { text-decoration: line-through; }' : '')}
`;

interface MatchParams {
  rolePermissionId: string;
}

type Props = RouteComponentProps<MatchParams>;

export const RolePermission = withRouter<Props, any>(
  React.memo<Props>(({ match }) => {
    const { rolePermissionId } = match.params;
    const [rolePermission, setRolePermission] = useState<RolePermission>({} as RolePermission);

    function getUserRole() {
      getRolePermissionRequest({ rolePermissionId })
        .then((response) => {
          setRolePermission(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      getUserRole();
    }, []);

    const leftData = [
      {
        key: 'ID',
        value: rolePermission.id,
      },
      {
        key: 'Role ID',
        value: rolePermission.role ? (
          <Link to={`/roles/${rolePermission.role.id}`}>{rolePermission.role.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Role Name',
        value: rolePermission.role ? rolePermission.role.name : '-',
      },
      {
        key: 'Permission ID',
        value: rolePermission.permission ? (
          <Link to={`/permissions/${rolePermission.permission.id}`}>{rolePermission.permission.id}</Link>
        ) : (
          '-'
        ),
      },
      {
        key: 'Permission Name',
        value: rolePermission.permission ? rolePermission.permission.name : '-',
      },
    ];

    const rightData = [
      {
        key: 'Granted At',
        value: rolePermission.grantedAt,
      },
      {
        key: 'Expired At',
        value: rolePermission.expiredAt,
      },
      {
        key: 'Status',
        value: rolePermission.status,
      },
    ];

    return (
      <RolePermissionContainer state={rolePermission.status}>
        <Layout>
          <PageTitle
            title="Granted Role Detail"
            subTitle={`#${(rolePermission.id && rolePermission.id.toString()) || ''}`}
            showBack={true}
          />
          <Layout.Content>
            <GeneralDetail leftData={leftData} rightData={rightData} />
          </Layout.Content>
        </Layout>
      </RolePermissionContainer>
    );
  }),
);
