import * as React from 'react';
import { Row, Col } from 'antd';
import { InfoCards } from './InfoCard';
import {Link} from "react-router-dom";

type Props = {
  statusPageIncident: StatusPageIncident;
};

export const StatusPageIncidentInfo: React.FC<Props> = ({
  statusPageIncident
}) => {
  const titles = ['Detail', ' '];
  const detailBasic = [
    {
      key: 'ID',
      value: statusPageIncident.id
    },
    {
      key: 'StatusPage Id',
      value: statusPageIncident.incidentId
    },
    {
      key: 'Group (Region)',
      value:
        statusPageIncident.componentGroup ?
        <Link to={`/status_page_component_groups/${statusPageIncident.componentGroupId}/`}>
          {statusPageIncident.componentGroup.name}
        </Link> : '-',
    },
    {
      key: 'Name',
      value: statusPageIncident.name,
    },
    {
      key: 'Status',
      value: statusPageIncident.status ? statusPageIncident.status : '-',
    },
    {
      key: 'Impact',
      value: statusPageIncident.impact ? statusPageIncident.impact : '-',
    },
  ];

  const detailAdvanced = [
    {
      key: 'Short Link',
      value: <a href={statusPageIncident.shortLink} target='blank'>{statusPageIncident.shortLink}</a>
    },
    {
      key: 'Created At',
      value: statusPageIncident.createdAt
    },
    {
      key: 'Resolved At',
      value: statusPageIncident.resolvedAt,
    },
    {
      key: 'Downtime(seconds)',
      value: statusPageIncident.downtime ? statusPageIncident.downtime : '-',
    },
  ];

  const dataSources = [detailBasic, detailAdvanced];

  return (
    <Row
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 16px 0',
        background: '#f0f2f5',
      }}
    >
      <Col span={24}>
        <InfoCards
          titles={titles}
          dataSources={dataSources}
          minHeight="280px"
        />
      </Col>
    </Row>
  );
};
