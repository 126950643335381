import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Tag } from 'antd';

import { ResponsiveTable } from './ui/ResponsiveTable';
import { ColumnProps } from 'antd/lib/table';
import { LineChartComponent } from './LineChartComponent';
import { useCheckMobile } from '../utils/screen';
import { getSearchDate, getSearchMonth } from '../utils/date';
import { getCrmApplicationDailyCountsRequest, getCrmApplicationMonthlyCountsRequest } from '../api/application';
import { Link } from 'react-router-dom';
const { Title, Paragraph } = Typography;

const alignRight = 'right' as ColumnProps<any>['align'];

function createColumns({ dataSources, isMobile }) {
  let columns: Array<ColumnProps<any>> = [
    {
      title: '#',
      dataIndex: 'title',
      key: 'title',
      fixed: isMobile ? false : 'left',
      width: '100px',
    },
  ];

  dataSources.forEach((data, columnIndex) => {
    columns.push({
      title: data.date,
      dataIndex: `count-${columnIndex}`,
      key: `count-${columnIndex}`,
      align: alignRight,
      render: (value: number) => value && value.toLocaleString(),
    });
  });

  return columns;
}

function convertDataSource({ dataSources, titles }) {
  const keys = dataSources.length > 0 ? Object.keys(dataSources[0]) : [];
  let source = [] as any;
  keys.map((key) => {
    const row = {};
    if (key !== 'date') {
      dataSources.forEach((data, index) => {
        row[`count-${index}`] = data[key];
        row['title'] = titles[key];
        row['key'] = key;
      });
      source.push(row);
    }
  });

  return source;
}

export const DashboardGrowthApplications = React.memo(() => {
  const isMobile = useCheckMobile();

  const [dataSourceDaily, setDataSourceDaily] = useState<Array<DashboardUserCount>>([]);
  const [dataSourceMonthly, setDataSourceMonthly] = useState<Array<DashboardUserCount>>([]);
  const [dailyLoading, setDailyLoading] = useState<boolean>(false);
  const [monthlyLoading, setMonthlyLoading] = useState<boolean>(false);

  function getCrmApplicationDailyCounts() {
    const { startDate, endDate } = getSearchDate({ days: 14 });

    setDailyLoading(true);
    getCrmApplicationDailyCountsRequest({ startDate, endDate })
      .then((response) => {
        setDataSourceDaily(response.data.results);
        setDailyLoading(false);
      })
      .catch((error) => {
        setDailyLoading(false);
        error && console.error(error.response);
      });
  }

  function getCrmApplicationMonthlyCounts() {
    const { startMonth, endMonth } = getSearchMonth();

    setMonthlyLoading(true);
    getCrmApplicationMonthlyCountsRequest({ startMonth, endMonth })
      .then((response) => {
        setDataSourceMonthly(response.data.results);
        setMonthlyLoading(false);
      })
      .catch((error) => {
        setMonthlyLoading(false);
        error && console.error(error.response);
      });
  }

  useEffect(function () {
    getCrmApplicationDailyCounts();
    getCrmApplicationMonthlyCounts();
  }, []);

  const dailyColumns = createColumns({
    dataSources: dataSourceDaily,
    isMobile,
  });

  const dailySources = convertDataSource({
    dataSources: dataSourceDaily,
    titles: {
      new: 'Registered apps(New)',
      cumulative: 'Registered apps(Cumulative)',
      installedNew: 'Installed apps(New)',
      installedCumulative: 'Installed apps(Cumulative)',
      messaged: 'Messaged app',
      connected: 'Connected apps',
    },
  });

  const monthlyColumns = createColumns({
    dataSources: dataSourceMonthly,
    isMobile,
  });

  const monthlySources = convertDataSource({
    dataSources: dataSourceMonthly,
    titles: {
      new: 'Registered apps(New)',
      cumulative: 'Registered apps(Cumulative)',
      installedNew: 'Installed apps(New)',
      installedCumulative: 'Installed apps(Cumulative)',
      messaged: 'Messaged app',
      connected: 'Connected apps',
    },
  });

  return (
    <Row
      gutter={16}
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '16px 8px',
        background: '#f0f2f5',
      }}
    >
      <Col>
        <Card
          title="Application statistics"
          extra={<Link to={`/application_counts`}>Daily applications list</Link>}
          bodyStyle={{ background: '#f4f4f4' }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Card>
                <Title level={4}>Application statistics - Daily</Title>
                <Paragraph>
                  <ul>
                    <li>
                      Installed Apps are the number of apps that have used our SDK and made at least one connection on
                      Sendbird’s application.
                      <br />
                      If an app uses both iOS and Android SDK, the number is still calculated as 1 app.
                      <br />
                      For engineering team: the applications are scored only if the{' '}
                      <Tag style={{ margin: 0 }}>sdk_integrated</Tag> value exceeds 0 in the Soda Database Application
                      model.
                    </li>
                    <li>
                      Installed Apps (New) are the number of SDK’s that have integrated with Sendbird’s application for
                      the first time for the given day.
                    </li>
                    <li>
                      Messaged Apps are the number of apps that have sent and received at least one message on
                      Sendbird’s application for the given day.
                    </li>
                    <li>
                      Connected Apps are the number of apps that have made at least one connection (DAU is 1 or more) on
                      Sendbird’s application for the given day.
                    </li>
                  </ul>
                </Paragraph>
                <ResponsiveTable
                  responsive={false}
                  bordered={true}
                  loading={dailyLoading}
                  columns={dailyColumns}
                  scroll={{ x: isMobile ? '100%' : 1680 }}
                  dataSource={dailySources}
                  pagination={false}
                  style={{ marginTop: '16px' }}
                />
              </Card>
            </Col>
            <Col span={24} style={{ marginTop: '32px' }}>
              <Card>
                <Title level={4} style={{ margin: 0 }}>
                  Application statistics - Monthly
                </Title>
              </Card>
            </Col>
            {monthlySources.map(({ title }, index) => {
              const monthlyData = [monthlySources[index]];
              const key = monthlyData[0].key;

              return (
                <Col key={`monthly-app-${index}`} span={24} md={12} style={{ marginTop: '16px' }}>
                  <Card>
                    <Title level={4}>{`${title} - Monthly`}</Title>
                    <LineChartComponent
                      title={`${title} - Monthly`}
                      unit="month"
                      labels={dataSourceMonthly.map((data) => data.date)}
                      data1={dataSourceMonthly.map((data) => data[key])}
                    />
                    <ResponsiveTable
                      responsive={false}
                      bordered={true}
                      loading={monthlyLoading}
                      columns={monthlyColumns}
                      scroll={{ x: isMobile ? '100%' : 1280 }}
                      dataSource={monthlyData}
                      pagination={false}
                      style={{ marginTop: '16px' }}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
