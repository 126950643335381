import React, { useState, useEffect } from 'react';
import { getAgentList } from '../api/deskMetrics';
import { Layout } from 'antd';
import { PageTitle } from './ui/PageTitle';
import { ResponsiveTable } from './ui/ResponsiveTable';

export const DeskMetricsProjectDetailAgentList = ({ region, projectPid }) => {
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [agentCount, setAgentCount] = useState(0);
  const [agents, setAgents] = useState(
    [] as Array<Agent>,
  );
  const [pagination, setPagination] = useState<{
    limit: number;
    offset: number;
  }>({ limit: 10, offset: 0 });

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => id,
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => createdAt,
    },
    {
      title: 'displayName',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (displayName) => displayName,
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => status,
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => role,
    },
    {
      title: 'connection',
      dataIndex: 'connection',
      key: 'connection',
      render: (connection) => connection,
    },
    {
      title: 'connectionUpdatedAt',
      dataIndex: 'connectionUpdatedAt',
      key: 'connectionUpdatedAt',
      render: (connectionUpdatedAt) => connectionUpdatedAt ? connectionUpdatedAt : '-',
    },
    {
      title: 'sendbirdId',
      dataIndex: 'sendbirdId',
      key: 'sendbirdId',
      render: (sendbirdId) => sendbirdId,
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      render: (user, agent) => {
        return agent && agent.user ? agent.user.email : '-' ;
      }
    },
    {
      title: 'gateUserId',
      dataIndex: 'gateUserId',
      key: 'gateUserId',
      render: (user, agent) => {
        return agent && agent.user ? agent.user.gateUserId : '-' ;
      }
    },
  ];

  const getAgentListFunction = (limit, offset) => {
    setLoadingAgents(true);
    getAgentList({
      projectPid: projectPid,
      region: region,
      limit: limit,
      offset: offset,
    })
      .then((response) => {
        setAgents(response.data.results);
        setAgentCount(response.data.count);
        setLoadingAgents(false);
      })
      .catch((error) => {
        setLoadingAgents(false);
        console.error(error);
      });
  };

  const onPageChange = (page, pageSize) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    setPagination({ limit: limit, offset: offset });
    getAgentListFunction(limit, offset);
  };

  useEffect(() => {
    getAgentListFunction(pagination.limit, pagination.offset);
  }, []);
  return (
    <Layout>
      <PageTitle title="Agents" />
      <Layout.Content>
        <ResponsiveTable
          loading={loadingAgents}
          columns={columns}
          dataSource={agents}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ['10', ],
            total: agentCount,
            onChange: onPageChange,
            onShowSizeChange: onPageChange,
            current: Math.floor(pagination.offset / pagination.limit) + 1,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};
