import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Rubik', 'Helvetica Neue', helvetica, Arial, sans-serif;
  }

  h3.ant-typography, .ant-typography h3,
  h4.ant-typography, .ant-typography h4,
  .ant-page-header-heading-title,
  .ant-page-header-title-view-title {
    font-weight: 500;
  }
  
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-right: 32px;
    padding-bottom: 32px;
  }

  .ant-layout {
    background: white;
  }
  .ant-layout-header {
    height: auto;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    padding: 0 24px;
    border-bottom: 1px solid #f0f2f5;
    line-height: inherit;
  }
  .ant-layout-content {
    display: flex;
    flex-direction: column;
    min-height: auto;
    margin-top: 16px;
    padding: 0 24px 32px;
    .ant-page-header-content {
      padding-top: 8px;
    }
    .ant-tabs {
      flex: 1;
    }
    .ant-tabs-nav {
      margin-top: -16px;
      padding-top: 8px;
      background: #f0f2f5;
      box-shadow: inset 0 1px 4px rgba(0, 21, 41, .08);
      border-right: 1px solid #f0f2f5;
    }
  }
  .ant-spin-nested-loading > div > .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
