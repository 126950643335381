import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getDownloadOrganizationsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_organizations/export_crm_organizations/`,
    {
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadOutstandingPaymentsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/export_outstanding_payments/`,
    {
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadChargedPaymentsRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/crm_payments/export_charged_payments/`, {
    params: {
      start_date: startDate,
      end_date: endDate
    },
    headers: {
      Authorization: getAuthToken()
    },
  });
}

export function getDownloadAnnouncementsRequest({ appId, startDate, endDate }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/export_announcements/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate
      },
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadCrmApplicationDailyRecordsRequest({
  appId,
  startDate,
  endDate
}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_applications/${appId}/export_crm_application_daily_records/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate
      },
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadSubscriptionsRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_subscriptions/export_subscriptions/`,
    {
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadCorporateDashboardOpportunityListRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/fivetran_salesforce_opportunities/export_opportunity_list/`,
    {
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadUserRequestActivityLogRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/user_request_activity_logs/export_user_request_activity_logs/`, {
    params: {
      start_date: startDate,
      end_date: endDate
    },
    headers: {
      Authorization: getAuthToken()
    },
  });
}

export function getDownloadDatabaseOtpRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/database_otps/export_database_otps/`, {
    params: {
      start_date: startDate,
      end_date: endDate
    },
    headers: {
      Authorization: getAuthToken()
    },
  });
}

export function getDownloadDashboardOtpRequest({ startDate, endDate }) {
  return insightAxios.get(`${API_DOMAIN}/api/gate_team_account_otps/export_gate_team_account_otps/`, {
    params: {
      start_date: startDate,
      end_date: endDate
    },
    headers: {
      Authorization: getAuthToken()
    },
  });
}

export function getDownloadCrmApplicationsWithDelinquentSubscriptionFeeInvoicesRequest() {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/export_crm_applications_with_delinquent_subscription_fee_invoices/`,
    {
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}

export function getDownloadCrmApplicationPaymentStatusRequest({isUnpaid='false'}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/crm_payments/export_payment_status/`,
    {
      params:{
        isUnpaid
      },
      headers: {
        Authorization: getAuthToken()
      },
    }
  );
}
