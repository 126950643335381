import {insightAxios} from './';
import {API_DOMAIN} from '../App';
import {getAuthToken} from '../utils/auth';

export function getStatusPageComponentGroupsRequest({ offset, limit, q }) {
  return insightAxios.get(
    `${API_DOMAIN}/api/status_page_component_groups/`,
    {
      params: {
        offset,
        limit,
        q,
      },
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}

export function getStatusPageComponentGroupRequest({ groupId}) {
  return insightAxios.get(
    `${API_DOMAIN}/api/status_page_component_groups/${groupId}/`,
    {
      headers: {
        Authorization: getAuthToken()
      }
    }
  );
}
