import * as React from 'react';
import {Table} from 'antd';
import {TableProps} from 'antd/lib/table';
import styled from 'styled-components';
import {media} from '../../utils/media';

const Responsive = styled.div<ResponsiveTableProps>`
  th,
  td {
    font-size: 14px;
  }

  ${props =>
    props.responsive
      ? media.TABLET_VERTICAL`
    tr{
      display: block !important;
      padding: 16px 0 !important;
      border-bottom: 1px solid #e8e8e8 !important;
    }

    th,
    td {
      display: block !important;
      padding: 2px 16px !important;
      border: 0 !important;
    }

    thead {
      tr {
        background: #fafafa;
      }
    }

    tbody{}

    .ant-table-bordered .ant-table-header > table,
    .ant-table-bordered .ant-table-body > table,
    .ant-table-bordered .ant-table-fixed-left table,
    .ant-table-bordered .ant-table-fixed-right table {
      border-right: 1px solid #e8e8e8;
    }
  `
      : null}
`;

interface ResponsiveTableProps {
  responsive?: boolean;
}

type Props = ResponsiveTableProps & TableProps<any>;

export const ResponsiveTable: React.FC<Props> = React.memo(
  ({ responsive = true, size, pagination, ...rest }) => {
    const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total}`;
    return (
      <Responsive responsive={responsive}>
        <Table
          size={size ? size : 'middle'}
          pagination={
            pagination
              ? Object.assign(
                  {
                    defaultPageSize: 30,
                    pageSizeOptions: ['30', '60', '90'],
                    size: 'normal',
                    showSizeChanger: true,
                    showTotal
                  },
                  pagination
                )
              : false
          }
          {...rest}
        />
      </Responsive>
    );
  }
);
