import * as React from 'react';
import {Icon as LegacyIcon} from '@ant-design/compatible';
import {Tag} from 'antd';
import upperfirst from 'lodash.upperfirst';
import {REGIONS} from '../../App';

// const blink = keyframes`
//   0% {
//     opacity: 1;
//   }

//   45% {
//     opacity: 0;
//   }

//   90% {
//     opacity: 1;
//   }

//   100% {
//     opacity: 1;
//   }
// `;

// const Blinking = styled.div<EmergencyProps>`
//   animation: ${props =>
//     props.isEmergency ? `${blink} 1s ease-in-out infinite` : null};
// `;

type PlanProps = {
  plan: CrmApplication['plan'];
  style?: React.CSSProperties;
};

type RoleTypeProps = {
  isPredefined: boolean;
};

type DollarProps = {
  dollar: string;
};

type RegionProps = {
  region: CrmApplication['region'];
};

type BooleanProps = {
  booleanValue:
    | FivetranSalesforceAccount['g_300_c']
    | FivetranSalesforceLead['isConverted']
    | FivetranSalesforceLead['g_300_c']
    | FivetranSalesforceOpportunity['isClosed']
    | FivetranSalesforceOpportunity['isWon']
    | FivetranSalesforceOpportunity['g_300_c']
    | FivetranSalesforceUser['isActive']
    | FivetranSalesforceContract['terminationC'];
};

type ActiveProps = {
  isActive: CrmSubscription['isActive'] | CrmUser['isActive'] | CrmUser['useTwoFactorAuthentication'];
};

type EnabledProps = {
  isEnabled: boolean;
};

type CycleProps = {
  billingCycle: CrmSubscription['billingCycle'];
};

type AutoRenewalProps = {
  autoRenewal: CrmSubscription['autoRenewal'];
  autoRenewalPeriod?: CrmSubscription['autoRenewalPeriod'];
};

type PaymentMethodProps = {
  paymentMethod: CrmSubscription['paymentMethod'] | CrmPayment['paymentMethod'];
};

type PaymentTypeProps = {
  type: CrmPayment['type'];
};

type PaymentStatusProps = {
  status: CrmPayment['status'] | CrmPaymentRequest['status'];
};

type AnnouncementStatusProps = {
  status: ApplicationAnnouncement['status'];
};

type PermissionStatusProps = {
  status: UserPermission['status'];
};

// type PermissionNameProps = {
//   name: UserPermission['permission']['name'];
// };

type UserStateProps = {
  state: User['state'];
};

type EmergencyProps = {
  isEmergency: DashboardOtp['isEmergency'];
};

type OtpStatusProps = {
  status: DashboardOtp['status'];
};

type ApplicationPlanRequestRequestedActionProps = {
  requestedAction: ApplicationPlanRequest['requestedAction'];
};

type ApplicationPlanRequestStatusProps = {
  status: ApplicationPlanRequest['status'];
};
type SubscriptionRequestRequestRequestedActionProps = {
  requestedAction: SubscriptionRequest['requestedAction'];
};

type SubscriptionRequestRequestStatusProps = {
  status: SubscriptionRequest['status'];
};

type BillingSubscriptionRequestStatusProps = {
  status: string;
};

type BillingCallVoucherRequestStatusProps = {
  status: string;
};

type BillingSubscriptionRequestRequestedActionProps = {
  requestedAction: string;
};

type BillingCallVoucherRequestRequestedActionProps = {
  requestedAction: string;
};

type BillingVoucherStatusProps = {
  status: string;
}

type OrganizationSubscriptionPaymentStatusProps = {
  status: OrganizationSubscriptionPayment['status'];
};

type CrmOrganizationVersionTypeProps = {
  isSelfServe: CrmOrganization['isSelfServe'];
};

type EnabledDisabledProps = {
  enabled: boolean;
};

export const DollarSpan: React.FC<DollarProps> = React.memo(({ dollar }) => {
  return dollar.startsWith('-') ? <span>-${dollar.split('-')[1]}</span> : <span>${dollar}</span>;
});

export const PlanTag: React.FC<PlanProps> = React.memo(({ plan, style }) => {
  return (
    <Tag color={plan === 'enterprise' ? 'purple' : 'blue'} style={style}>
      {upperfirst(plan)}
    </Tag>
  );
});

export const RoleTypeTag: React.FC<RoleTypeProps> = React.memo(({ isPredefined }) => {
  return <Tag color={isPredefined ? 'grey' : 'blue'}>{isPredefined ? 'System Role' : 'Custom Role'}</Tag>;
});

export const RegionTag: React.FC<RegionProps> = React.memo(({ region }) => {
  if (region in REGIONS) {
    return (
      <Tag style={{ margin: 0, borderRadius: '16px' }}>
        {REGIONS[region] ? REGIONS[region].name : region} ({region})
      </Tag>
    );
  } else {
    return (
      <Tag color="red" style={{ margin: 0, borderRadius: '16px' }}>
        Deprecated Region ({region})
      </Tag>
    );
  }
});

export const BooleanTag: React.FC<BooleanProps> = React.memo(({ booleanValue }) => {
  return (
    <Tag color={booleanValue ? 'green' : 'red'} style={{ margin: 0 }}>
      {booleanValue ? 'True' : 'False'}
    </Tag>
  );
});

export const ActiveTag: React.FC<ActiveProps> = React.memo(({ isActive }) => {
  return (
    <Tag color={isActive ? 'green' : 'red'} style={{ margin: 0 }}>
      {isActive ? 'ACTIVE' : 'INACTIVE'}
    </Tag>
  );
});

export const EnabledTag: React.FC<EnabledProps> = React.memo(({ isEnabled }) => {
  return (
    <Tag color={isEnabled ? 'green' : 'red'} style={{ margin: 0 }}>
      {isEnabled ? 'ENABLED' : 'DISABLED'}
    </Tag>
  );
});

export const BillingCycleTag: React.FC<CycleProps> = React.memo(({ billingCycle }) => {
  const cycle = billingCycle.split('_').pop();
  return <Tag color={cycle === 'MONTHLY' ? 'blue' : 'purple'}>{cycle}</Tag>;
});

export const AutoRenewalTag: React.FC<AutoRenewalProps> = React.memo(({ autoRenewal, autoRenewalPeriod }) => {
  return autoRenewal ? (
    <React.Fragment>
      <Tag color="green" style={{ margin: 0 }}>
        Enabled
      </Tag>
      {autoRenewalPeriod ? <small>({autoRenewalPeriod} months)</small> : ''}
    </React.Fragment>
  ) : (
    <Tag color="red" style={{ margin: 0 }}>
      Disabled
    </Tag>
  );
});

export const PaymentMethodTag: React.FC<PaymentMethodProps> = React.memo(({ paymentMethod }) => {
  const method = paymentMethod.split('_').pop();
  return (
    <span style={{ color: method === 'CARD' ? 'cadetblue' : 'orange' }}>
      <LegacyIcon
        type={method === 'CARD' ? 'credit-card' : 'dollar'}
        style={{ marginRight: '4px', verticalAlign: 'middle' }}
      />
      <small>{method}</small>
    </span>
  );
});

export const PaymentTypeTag: React.FC<PaymentTypeProps> = React.memo(({ type }) => {
  const refinedType = type.split('_').pop();

  function getColor(type) {
    switch (type) {
      case 'SUBSCRIPTION':
        return 'purple';

      case 'ONETIME':
        return 'blue';

      case 'EXTRA':
        return 'red';

      default:
        return '';
    }
  }

  return <Tag color={getColor(refinedType)}>{refinedType}</Tag>;
});

export const TaskStatusTag: React.FC<PaymentStatusProps> = React.memo(({ status }) => {
  function getColor(status) {
    switch (status) {
      case 'SUCCESS':
        return 'green';

      case 'FAILURE':
        return 'red';

      default:
        return 'blue';
    }
  }

  return (
    <Tag color={getColor(status)} style={{ margin: 0 }}>
      {status}
    </Tag>
  );
});

export const PaymentStatusTag: React.FC<PaymentStatusProps> = React.memo(({ status }) => {
  const refinedStatus = status.split('_').pop();

  function getColor(status) {
    switch (status) {
      case 'SUCCESS':
        return 'green';

      case 'CHARGED':
        return 'blue';

      case 'QUEUED':
        return 'orange';

      case 'FAILED':
        return 'red';

      case 'REFUNDED':
        return 'magenta';

      case 'DISABLED':
        return '';

      default:
        return '';
    }
  }

  return (
    <Tag color={getColor(refinedStatus)} style={{ margin: 0 }}>
      {refinedStatus}
    </Tag>
  );
});

export const AnnouncementStatusTag: React.FC<AnnouncementStatusProps> = React.memo(({ status }) => {
  function getColor(status) {
    switch (status) {
      case 'done':
        return 'green';

      case 'running':
        return 'orange';

      case 'canceled':
        return 'red';

      default:
        return '';
    }
  }

  return <Tag color={getColor(status)}>{status.toUpperCase()}</Tag>;
});

export const PermissionStatusTag: React.FC<PermissionStatusProps> = React.memo(({ status }) => {
  function getColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'green';

      case 'EXPIRED':
        return 'geekblue';

      default:
        return '';
    }
  }

  return <Tag color={getColor(status)}>{status.toUpperCase()}</Tag>;
});

export const UserStateTag: React.FC<UserStateProps> = React.memo(({ state }) => {
  function getColor(state) {
    switch (state) {
      case 'ACTIVE':
        return 'green';

      case 'INACTIVE':
        return 'red';

      default:
        return '';
    }
  }

  return <Tag color={getColor(state)}>{state.toUpperCase()}</Tag>;
});

export const EmergencyTag: React.FC<EmergencyProps> = React.memo(({ isEmergency }) => {
  return (
    <Tag color={isEmergency ? 'magenta' : ''} style={{ margin: 0 }}>
      {isEmergency ? 'Emergency' : 'Normal'}
    </Tag>
  );
});

export const OtpStatusTag: React.FC<OtpStatusProps> = React.memo(({ status }) => {
  function getTagContext() {
    switch (status) {
      case 'CREATED':
        return { color: 'orange', text: 'CREATED' };

      case 'APPROVAL_PASSED':
        return { color: 'purple', text: 'APPROVAL PASSED' };

      case 'APPROVAL_DENIED':
        return { color: 'volcano', text: 'APPROVAL DENIED' };

      case 'OTP_GENERATED':
        return { color: 'cyan', text: 'OTP GENERATED' };

      case 'OTP_USED':
        return { color: 'green', text: 'OTP USED' };

      default:
        return { color: '', text: '-' };
    }
  }
  return (
    <Tag color={getTagContext().color} style={{ margin: 0 }}>
      {getTagContext().text}
    </Tag>
  );
});

export const ApplicationPlanRequestStatusTag: React.FC<ApplicationPlanRequestStatusProps> = React.memo(({ status }) => {
  function getTagContext() {
    switch (status) {
      case 'REQUESTED':
        return { color: 'orange', text: 'REQUESTED' };

      case 'APPROVAL_PASSED':
        return { color: 'green', text: 'APPROVAL PASSED' };

      case 'APPROVAL_DENIED':
        return { color: 'red', text: 'APPROVAL DENIED' };

      default:
        return { color: '', text: '-' };
    }
  }
  return (
    <Tag color={getTagContext().color} style={{ margin: 0 }}>
      {getTagContext().text}
    </Tag>
  );
});

export const ApplicationPlanRequestRequestedActionTag: React.FC<ApplicationPlanRequestRequestedActionProps> = React.memo(
  ({ requestedAction }) => {
    return (
      <Tag color={requestedAction === 'CREATE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {requestedAction}
      </Tag>
    );
  },
);

export const SubscriptionRequestRequestedActionTag: React.FC<SubscriptionRequestRequestRequestedActionProps> = React.memo(
  ({ requestedAction }) => {
    return (
      <Tag color={requestedAction === 'CREATE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {requestedAction}
      </Tag>
    );
  },
);

export const SubscriptionRequestStatusTag: React.FC<SubscriptionRequestRequestStatusProps> = React.memo(
  ({ status }) => {
    function getTagContext() {
      switch (status) {
        case 'REQUESTED':
          return { color: 'orange', text: 'REQUESTED' };

        case 'APPROVAL_PASSED':
          return { color: 'green', text: 'APPROVAL PASSED' };

        case 'APPROVAL_DENIED':
          return { color: 'red', text: 'APPROVAL DENIED' };

        default:
          return { color: '', text: '-' };
      }
    }
    return (
      <Tag color={getTagContext().color} style={{ margin: 0 }}>
        {getTagContext().text}
      </Tag>
    );
  },
);

export const StatusTag: React.FC<PermissionStatusProps> = React.memo(({ status }) => {
  function getColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'green';

      case 'EXPIRED':
        return 'geekblue';

      default:
        return '';
    }
  }

  return <Tag color={getColor(status)}>{status.toUpperCase()}</Tag>;
});

export const UserMigrationRequestStatusTag: React.FC<ApplicationPlanRequestStatusProps> = React.memo(({ status }) => {
  function getTagContext() {
    switch (status) {
      case 'REQUESTED':
        return { color: 'orange', text: 'REQUESTED' };

      case 'APPROVAL_PASSED':
        return { color: 'green', text: 'APPROVAL PASSED' };

      case 'APPROVAL_DENIED':
        return { color: 'red', text: 'APPROVAL DENIED' };

      default:
        return { color: '', text: '-' };
    }
  }
  return (
    <Tag color={getTagContext().color} style={{ margin: 0 }}>
      {getTagContext().text}
    </Tag>
  );
});

export const OrganizationSubscriptionPaymentStatusTag: React.FC<OrganizationSubscriptionPaymentStatusProps> = React.memo(
  ({ status }) => {
    function getColor(status) {
      switch (status) {
        case 'SUCCESS':
          return 'green';

        case 'IN_PROGRESS':
          return 'blue';

        case 'FAILED':
          return 'red';

        case 'REFUNDED':
          return 'magenta';

        default:
          return '';
      }
    }

    return (
      <Tag color={getColor(status)} style={{ margin: 0 }}>
        {status}
      </Tag>
    );
  },
);

export const CrmOrganizationVersionTypeTag: React.FC<CrmOrganizationVersionTypeProps> = React.memo(
  ({ isSelfServe }) => {
    function getColor(isSelfServe) {
      return isSelfServe ? 'geekblue' : 'volcano';
    }

    return (
      <Tag color={getColor(isSelfServe)} style={{ margin: 0 }}>
        {isSelfServe ? 'Version 2' : 'Version 1'}
      </Tag>
    );
  },
);

export const InvoiceStatusTag: React.FC<{
  status: 'OUTSTANDING' | 'PENDING' | 'OVERDUE' | 'PAID' | 'FAILED' | 'VOID' | 'UNCOLLECTABLE';
}> = React.memo(({ status }) => {
  function getColor(status) {
    switch (status) {
      case 'PAID':
        return 'green';
      case 'OUTSTANDING':
        return 'blue';
      case 'OVERDUE':
        return 'orange';
      case 'FAILED':
        return 'red';
      case 'PENDING':
        return 'magenta';
      case 'UNCOLLECTABLE':
        return 'black';
      default:
        return 'gray';
    }
  }

  return (
    <Tag color={getColor(status)} style={{ margin: 0 }}>
      {status}
    </Tag>
  );
});

export const BillingSubscriptionRequestStatusTag: React.FC<BillingSubscriptionRequestStatusProps> = React.memo(
  ({ status }) => {
    function getTagContext() {
      switch (status) {
        case 'REQUESTED':
          return { color: 'orange', text: 'REQUESTED' };

        case 'APPROVAL_PASSED':
          return { color: 'green', text: 'APPROVAL PASSED' };

        case 'APPROVAL_DENIED':
          return { color: 'red', text: 'APPROVAL DENIED' };

        default:
          return { color: '', text: '-' };
      }
    }
    return (
      <Tag color={getTagContext().color} style={{ margin: 0 }}>
        {getTagContext().text}
      </Tag>
    );
  },
);

export const BillingSubscriptionRequestRequestedActionTag: React.FC<BillingSubscriptionRequestRequestedActionProps> = React.memo(
  ({ requestedAction }) => {
    return (
      <Tag color={requestedAction === 'CREATE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {requestedAction}
      </Tag>
    );
  },
);

export const BillingCallVoucherRequestStatusTag: React.FC<BillingCallVoucherRequestStatusProps> = React.memo(
  ({ status }) => {
    function getTagContext() {
      switch (status) {
        case 'REQUESTED':
          return { color: 'orange', text: 'REQUESTED' };

        case 'APPROVAL_PASSED':
          return { color: 'green', text: 'APPROVAL PASSED' };

        case 'APPROVAL_DENIED':
          return { color: 'red', text: 'APPROVAL DENIED' };

        default:
          return { color: '', text: '-' };
      }
    }
    return (
      <Tag color={getTagContext().color} style={{ margin: 0 }}>
        {getTagContext().text}
      </Tag>
    );
  },
);

export const BillingCallVoucherRequestRequestedActionTag: React.FC<BillingCallVoucherRequestRequestedActionProps> = React.memo(
  ({ requestedAction }) => {
    return (
      <Tag color={requestedAction === 'CREATE' ? 'green' : 'red'} style={{ margin: 0 }}>
        {requestedAction}
      </Tag>
    );
  },
);

export const BillingVoucherStatusTag: React.FC<BillingVoucherStatusProps> = React.memo(
  ({ status }) => {
    function getTagContext() {
      switch (status) {
        case 'READY':
          return { color: 'orange', text: 'READY' };

        case 'ACTIVE':
          return { color: 'green', text: 'ACTIVE' };

        case 'EXPIRED':
          return { color: 'red', text: 'EXPIRED' };

        case 'DEPLETED':
          return { color: 'red', text: 'DEPLETED' };

        default:
          return { color: '', text: status };
      }
    }
    return (
      <Tag color={getTagContext().color} style={{ margin: 0 }}>
        {getTagContext().text}
      </Tag>
    );
  }
)

export const EnabledDisabledTag: React.FC<EnabledDisabledProps> = React.memo(({ enabled }) => {
  return (
    <Tag color={enabled ? 'green' : 'red'} style={{ margin: 0 }}>
      {enabled ? 'Enabled' : 'Disabled'}
    </Tag>
  );
});
